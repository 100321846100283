<div class="notification" [ngClass]="getNotificationClass()" *ngIf="_notification" yfLayoutClass>

    <span class="notification-close"
          (click)="closeNotification()"
          aria-label="close"
          *ngIf="closable">
        x
    </span>

    <div class="row mb-0">
        <div class="col-12">
            <div class="notification-title" [ngClass]="getNotificationClass()">
                <span>{{_notification?.title}}</span>
            </div>
        </div>
    </div>

    <div class="row mb-0 mt-1">
        <div class="col-12">
            <ul class="mb-0">
                <li *ngFor="let message of _notification.messages">{{message}}</li>
            </ul>
        </div>
    </div>

    <ng-content></ng-content>
</div>