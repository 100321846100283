import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import {WebModule} from '../web/web.module';
import {USERS_COMPONENTS} from './components';
import {USERS_DIRECTIVES} from './directives';

import {USER_VIEWS} from './views';

@NgModule({
    imports: [
        // core
        FormsModule,
        // vendors
        NgxPaginationModule,
        // app
        WebModule
    ],
    providers: [],
    declarations: [
        ...USER_VIEWS,
        ...USERS_COMPONENTS,
        ...USERS_DIRECTIVES,
    ],
    schemas: [
        NO_ERRORS_SCHEMA,
        CUSTOM_ELEMENTS_SCHEMA,
    ],
    exports: [
        ...USERS_COMPONENTS,
        ...USERS_DIRECTIVES
    ]
})
export class UsersModule {

    static forRoot(configuredProviders: Array<any>): ModuleWithProviders {
        return {
            ngModule: UsersModule,
            providers: configuredProviders
        };
    }

}
