import {
    ProfileDto,
    ProfileVerificationDto,
    UserProfileDto,
    UserProvider,
    UserType,
    UserViewDto,
    VerificationStatus
} from '../models';
import {CommonUtils} from './common.util';
import {VerificationUtils} from './verification.util';

export class UserProfileUtils {

    static calculatePercentage(userView: UserViewDto): number {

        if (!userView) {
            return 0;
        }

        let ignore = ['uid', 'filled', 'birthDay', 'reviewsAverage', 'worker', 'verification'];
        let ignoreVerif = ['phoneVerified', 'profileVerified'];

        let keys = Object.keys(userView).filter(k => ignore.indexOf(k) === -1);
        let verifKeys = Object.keys(userView.verification).filter(k => ignoreVerif.indexOf(k) === -1);
        let length = keys.length + verifKeys.length;

        let count = length;

        keys.forEach(key => {
            if (!userView[key]) {
                count--;
            }
        });

        verifKeys.forEach(key => {
            if (!userView.verification[key]) {
                count--;
            }
        });


        let precision = (count / length * 100).toPrecision(2);

        return parseFloat(precision);

    }

    static hasUserType(userProfile: UserProfileDto, type: UserType): boolean {
        return userProfile && UserProfileUtils.hasProfileType(userProfile.profile, type);
    }

    static isUserProfileComplete(userProfile: UserProfileDto): boolean {
        if (!userProfile) {
            return false;
        }

        return UserProfileUtils.isProfileComplete(userProfile.profile);
    }

    static isProfileComplete(profile: ProfileDto): boolean {
        if (!profile.firstName) {
            return false;
        }

        if (!profile.lastName) {
            return false;
        }

        return true;
    }

    static isUserProfileVerified(userProfile: UserProfileDto): boolean {
        if (!userProfile) {
            return false;
        }

        let profile = userProfile.profile;

        if (!profile) {
            return false;
        }

        let verification = profile.verification;


        if (!VerificationUtils.isStatusApproved(verification.idCardStatus)) {
            return false;
        }

        if (!VerificationUtils.isStatusApproved(verification.workerCardStatus)) {
            return false;
        }

        // if (verification.phoneStatus === VerificationStatus.APPROVED) {
        //     return false;
        // }

        return VerificationUtils.isStatusApproved(verification.emailStatus);
    }

    private static isNotVerified(status: VerificationStatus): boolean {
        return status && (status === VerificationStatus.NONE || status === VerificationStatus.REJECTED);
    }

    static isProfessionalUserProfile(userProfile: UserProfileDto): boolean {
        return UserProfileUtils.hasUserType(userProfile, UserType.PROFESSIONAL);
    }

    static isBasicUserProfile(userProfile: UserProfileDto): boolean {
        return UserProfileUtils.hasUserType(userProfile, UserType.BASIC);
    }

    static hasProfileType(profile: ProfileDto, type: UserType): boolean {
        return profile && profile.type === type;
    }

    static isProfessionalProfile(profile: ProfileDto): boolean {
        return UserProfileUtils.hasProfileType(profile, UserType.PROFESSIONAL);
    }

    static isBasicProfile(profile: ProfileDto): boolean {
        return UserProfileUtils.hasProfileType(profile, UserType.BASIC);
    }

    static hasUserProvider(userProfile: UserProfileDto, userProvider: UserProvider): boolean {
        return userProfile && userProfile.userProvider === userProvider;
    }

    static isLocalUser(userProfile: UserProfileDto): boolean {
        return UserProfileUtils.hasUserProvider(userProfile, UserProvider.LOCAL);
    }

    static isSocialUser(userProfile: UserProfileDto): boolean {
        return UserProfileUtils.hasUserProvider(userProfile, UserProvider.SOCIAL);
    }

    static isEligibleForSearch(userProfile: UserProfileDto): boolean {

        if (!userProfile || !userProfile.profile) {
            return false;
        }

        let activities = userProfile.profile.activities;
        let address = userProfile.profile.address;

        return userProfile.profile.worker && CommonUtils.notEmpty(activities) && activities.length && CommonUtils.notEmpty(address);
    }

    static isWorkerValid(userProfile: UserProfileDto): boolean {
        if (!userProfile || !userProfile.profile) {
            return false;
        }

        if (!userProfile.profile.worker) {
            return true;
        }

        return CommonUtils.notEmpty(userProfile.profile.address) && CommonUtils.notEmpty(userProfile.profile.activities);
    }

    static isProfileVerified(verification: ProfileVerificationDto): boolean {
        if (!verification) {
            return false;
        }

        return UserProfileUtils.isVerificationApproved(verification.profileStatus);
    }

    static isVerificationApproved(status: VerificationStatus) {
        return UserProfileUtils.hasVerificationStatus(status, VerificationStatus.APPROVED);
    }

    static isVerificationPending(status: VerificationStatus) {
        return UserProfileUtils.hasVerificationStatus(status, VerificationStatus.PENDING);
    }

    static isVerificationRejected(status: VerificationStatus) {
        return UserProfileUtils.hasVerificationStatus(status, VerificationStatus.REJECTED);
    }

    static isVerificationNone(status: VerificationStatus) {
        return UserProfileUtils.hasVerificationStatus(status, VerificationStatus.NONE);
    }

    static hasVerificationStatus(status: VerificationStatus, expectedStatus: VerificationStatus) {
        return status === expectedStatus;
    }

}
