var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationService, MessageService } from '../../services';
import * as fromRoot from '../../../store';
import { sendMessageActions } from '../actions';
var SendMessageEffects = /** @class */ (function () {
    function SendMessageEffects(actions$, messageService, store, notificationService) {
        var _this = this;
        this.actions$ = actions$;
        this.messageService = messageService;
        this.store = store;
        this.notificationService = notificationService;
        this.sendMessage = this.actions$.pipe(ofType(sendMessageActions.SendMessageActionTypes.SendMessage), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUser))), mergeMap(function (_a) {
            var sendMessageDto = _a[0], currentUser = _a[1];
            var currentUid = currentUser.uid;
            sendMessageDto.senderUid = currentUid;
            return _this.messageService.sendMessage(currentUid, sendMessageDto).pipe(map(function () { return new sendMessageActions.SendMessageSuccessAction(); }), catchError(function (errors) { return of(new sendMessageActions.SendMessageFailAction(errors)); }));
        }));
        this.sendMessageSuccess = this.actions$.pipe(ofType(sendMessageActions.SendMessageActionTypes.SendMessageSuccess), tap(function () {
            _this.notificationService.displaySuccessKey('send-message.sent');
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SendMessageEffects.prototype, "sendMessage", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], SendMessageEffects.prototype, "sendMessageSuccess", void 0);
    return SendMessageEffects;
}());
export { SendMessageEffects };
