var Notice = /** @class */ (function () {
    function Notice() {
    }
    Notice.buildProfileIncomplete = function () {
        return Notice.buildNotif('fa-address-card', NoticeType.PROFILE, 2);
    };
    Notice.buildNewMessage = function () {
        return Notice.buildNotif('fa-envelope', NoticeType.MESSAGE, 1);
    };
    Notice.buildProfileNotVerified = function () {
        return Notice.buildNotif('fa-check-circle', NoticeType.VERIFICATIONS, 3);
    };
    Notice.buildNotif = function (icon, type, priority) {
        var notif = new Notice();
        notif.icon = icon;
        notif.type = type;
        notif.priority = priority;
        return notif;
    };
    return Notice;
}());
export { Notice };
export var NoticeType = {
    MESSAGE: 'MESSAGE',
    VERIFICATIONS: 'VERIFICATIONS',
    PROFILE: 'PROFILE',
};
