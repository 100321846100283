import {Locale} from '../models';

export class ContextUtils {

    static getOrigin(): string {
        return window.location.origin;
    }

    static getContextPath(): string {
        let origin = window.location.origin;
        let href = window.location.href;
        // return href.replace(origin + '/#/', '/');
        return href.replace(origin + '/', '/');
    }

    static isArabicContextPath(): boolean {
        return ContextUtils.isArabicUrl(ContextUtils.getContextPath());
    }

    static isArabicUrl(url: string): boolean {
        return url === '/ar' || url.startsWith('/ar?') || url.startsWith('/ar/');
    }

    static isCurrentLang(url: string, locale: Locale): boolean {
        return url === `/${locale.shortcut}` || url.startsWith(`/${locale.shortcut}?`) || url.startsWith(`/${locale.shortcut}/`);
    }

    static updateLocaleLang(url: string, locale: Locale): string {
        if (ContextUtils.isCurrentLang(url, locale)) {
            return url;
        }

        if (url === '/') {
            return `/${locale.shortcut}`;
        }

        if (!url.startsWith('/')) {
            url = '/' + url;
        }

        return `/${locale.shortcut}` + url;
    }

    static arabizeContextPath(): string {
        return ContextUtils.arabizeUrl(ContextUtils.getContextPath());
    }

    static frenchifyContextPath(): string {
        return ContextUtils.frenchifyUrl(ContextUtils.getContextPath());
    }

    static arabizeUrl(url: string): string {
        if (ContextUtils.isArabicUrl(url)) {
            return url;
        }

        if (url === '/') {
            return '/ar';
        }

        if (!url.startsWith('/')) {
            url = '/' + url;
        }

        return '/ar' + url;
    }

    static frenchifyUrl(url: string): string {
        if (!url || !ContextUtils.isArabicUrl(url)) {
            return url;
        }

        if (url === '/ar') {
            return '/'
        }

        let prefix = url.startsWith('/ar?') ? 'ar?' : 'ar/';

        return url.replace(prefix, '');
    }
}
