<div class="container-fluid search-sh">
    <div class="container">
        <div class="row mb-0 align-items-center">
            <div class="col center">
                <h1 class="white-text hidden-sm-down" translate>users-search.header</h1>
                <h1 class="white-text hidden-md-up my-1" translate>users-search.header.sm</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12 px-0 px-md-2">
                <app-search-simple [searchCriteria]="searchCriteria"
                                   (search)="search($event)">
                </app-search-simple>
            </div>
        </div>
    </div>
</div>

<div>
    <div class="ads-ctr-left">
        <div class="row">
            <div class="col-12">
                <ng-adsense></ng-adsense>
            </div>

            <div class="col-12 mt-4" *ngIf="hasResult">
                <ng-adsense></ng-adsense>
            </div>
        </div>
    </div>
    <div class="ads-ctr-right">
        <div class="row">
            <div class="col-12">
                <ng-adsense></ng-adsense>
            </div>

            <div class="col-12 mt-4" *ngIf="hasResult">
                <ng-adsense></ng-adsense>
            </div>
        </div>
    </div>
    <div class="container" yfLayoutClass>

        <div *ngIf="!(loading$ | async) as loading; else spinner">

            <div *ngIf="currentPage as page; else searchPromotion">

                <div *ngIf="page?.content?.length > 0; else noResult">

                    <div class="row align-items-center">

                        <div class="col-6 col-md-8">
                            <h3>{{titleKey | translate:{totalElements: page.totalElements} }}</h3>
                        </div>
                        <div class="col">
                            <div class="right">
                                <app-list-mode [mode]="listMode"
                                               (select)="selectListMode($event)">
                                </app-list-mode>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center mb-0">
                        <div class="col hidden-lg-up">
                            <a class='sort-btn right' (click)="doOpenFilterModal()">
                                <i class="material-icons">filter_list</i>
                                <span class="icon-label" translate>global.filter</span>
                            </a>
                        </div>
                    </div>

                    <div class="row"
                         *ngIf="page.content | paginate: { itemsPerPage: page.size, currentPage: page.number + 1, totalItems: page.totalElements }">

                        <div class="col-lg-3 hidden-md-down">
                            <app-person-searchfacets [searchCriteria]="searchCriteria"
                                                     (filter)="search($event)">
                            </app-person-searchfacets>
                        </div>

                        <!-- Cards -->
                        <div class="col-lg-9" *ngIf="listMode === ListMode.LIST">
                            <div *ngFor="let user of page.content; let i = index">
                                <div class="row mb-0" *ngIf="i === 3 || i === 7">
                                    <div class="col-12 center">
                                        <div class="hidden-md-up">
                                            <ng-adsense adFormat="horizontal" [height]="50" [width]="320"></ng-adsense>
                                        </div>
                                        <div class="hidden-sm-down">
                                            <ng-adsense adFormat="horizontal" [fullWidthResponsive]="true"></ng-adsense>
                                        </div>
                                    </div>
                                </div>

                                <div class="hoverable search-result">
                                    <worker-card [worker]="user"
                                                 (workerSelect)="navigateToWorker(user)">
                                    </worker-card>
                                </div>
                            </div>

                        </div>

                        <!-- Map -->
                        <div class="col-md-9" *ngIf="listMode === ListMode.MAP">
                            <div class="card result-map">

                                <div class="card-block" dir="ltr">

                                    <agm-map [latitude]="searchCriteria?.address?.location?.latitude"
                                             [longitude]="searchCriteria?.address?.location?.longitude"
                                             [fitBounds]="latLngBounds"
                                             [streetViewControl]="false"
                                             [zoom]="12">

                                        <agm-marker *ngFor="let user of page.content"
                                                    [latitude]="user.address.location.latitude"
                                                    [longitude]="user.address.location.longitude">

                                            <agm-snazzy-info-window [closeWhenOthersOpen]="true"
                                                                    [showCloseButton]="false"
                                                                    [maxHeight]="400"
                                                                    [wrapperClass]="'marker-width'"
                                                                    [padding]="'0px'">
                                                <ng-template>
                                                    <div class="iw-header">
                                                        <div class="row center py-2">
                                                            <div class="col-12">
                                                                    <span style="font-size: 1.3rem; font-weight: 500; color:white">
                                                                        {{user.firstName}}
                                                                        {{user.lastName}}
                                                                    </span>
                                                            </div>
                                                            <div class="col-12" style="color:white" *ngIf="user.distance">
                                                                <span>{{distanceText(user.distance)}}</span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="container-fluid">

                                                        <div class="row center">
                                                            <div class="col-12">
                                                                <div class="chip">
                                                                    {{user.activities.length}} {{'service'
                                                                    |pluralize:user.activities.length}}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row center">
                                                            <div class="col-12">
                                                                <button class="btn-floating btn-primary btn-phone waves-effect waves-light"
                                                                        *ngIf="user?.showPhone"
                                                                        [appShowPhone]="user">
                                                                    <i class="material-icons"
                                                                       [title]="'global.show-phone' | translate"
                                                                       aria-hidden="true">local_phone</i>
                                                                </button>

                                                                <button class="btn-floating btn-primary btn-phone waves-effect waves-light ml-2"
                                                                        [appSendMessage]="user">
                                                                    <i class="material-icons"
                                                                       [title]="'global.send-message' | translate"
                                                                       aria-hidden="true">mail</i>
                                                                </button>

                                                                <button class="btn-floating btn-primary btn-phone waves-effect waves-light ml-2"
                                                                        (click)="navigateToWorker(user)">
                                                                    <i class="material-icons"
                                                                       [title]="'global.see-profile' | translate"
                                                                       aria-hidden="true">remove_red_eye</i>
                                                                </button>
                                                            </div>

                                                        </div>

                                                    </div>


                                                </ng-template>
                                            </agm-snazzy-info-window>

                                        </agm-marker>

                                    </agm-map>

                                </div>
                            </div>

                        </div>

                    </div>

                    <!-- Pagination -->
                    <div class="row">
                        <div class="col-md-9 offset-lg-3 center text-center">
                            <pagination-controls class="pagination-controls hidden-md-down"
                                                 (pageChange)="loadPage($event)"
                                                 [previousLabel]="'global.previous' | translate"
                                                 [nextLabel]="'global.next' | translate">
                            </pagination-controls>

                            <app-mobile-pagination class="hidden-lg-up"
                                                   [page]="page"
                                                   (selectPage)="loadPage($event)">
                            </app-mobile-pagination>
                        </div>
                    </div>

                    <!--<div class="row">-->
                    <!--<div class="col-12 center">-->
                    <!--<ng-adsense></ng-adsense>-->
                    <!--</div>-->
                    <!--</div>-->

                </div>

                <!-- No result found -->
                <ng-template #noResult>
                    <div class="card">
                        <div class="card-block">
                            <div class="row center">
                                <div class="col-12">
                                    <h3 translate>global.no-search-result</h3>
                                </div>
                            </div>

                            <div class="row center">
                                <div class="col-12">
                                    <span translate>global.create-new-request</span>
                                </div>

                                <div class="col-12 mt-2">
                                    <button type="button" class="btn btn-primary" [routerLink]="['/requests', 'new']"
                                            translate>
                                        global.new-req
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

            </div>

            <ng-template #searchPromotion>
                <div class="card">
                    <div class="card-block center" translate>workers.perform-new-search</div>
                </div>
            </ng-template>

        </div>

        <!-- Loading spinner  -->
        <ng-template #spinner class="row search-spinner align-items-center">
            <div class="col-12 center">
                <app-spinner></app-spinner>
            </div>
        </ng-template>

    </div>
</div>


<app-modal modalId="search-filter-modal"
           [modalEventListener]="filterModalEventListener$"
           modalClass="bottom-sheet-modal-height bottom-sheet">

    <h1 class="col my-0" modal-header translate>global.filter-result</h1>

    <div class="row justify-content-center" modal-content yfLayoutClass>
        <div class="col">
            <app-person-searchfacets [searchCriteria]="searchCriteria"
                                     (filter)="search($event)">
            </app-person-searchfacets>
        </div>
    </div>


</app-modal>
