import { AddressDto } from '../models';
var _includes = require('lodash/includes');
import { Config } from '../../../config';
var AddressUtils = /** @class */ (function () {
    function AddressUtils() {
    }
    AddressUtils.buildFromGooglePlaceResult = function (place) {
        if (!place) {
            return null;
        }
        var address = new AddressDto();
        address.placeId = place.place_id;
        address.formatted = place.formatted_address;
        // FIXME
        if (Config.IS_WEB()) {
            address.location = {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
            };
        }
        else {
            address.location = {
                latitude: place.geometry.location.lat,
                longitude: place.geometry.location.lng
            };
        }
        var addressComponents = place.address_components;
        address.country = this.getAddressComponentLongName(addressComponents, 'country');
        address.streetNumber = this.getAddressComponentLongName(addressComponents, 'street_number');
        address.route = this.getAddressComponentLongName(addressComponents, 'route');
        var locality = place.vicinity;
        if (!locality) {
            locality = this.getAddressComponentLongName(addressComponents, 'locality');
        }
        address.locality = locality;
        address.postalCode = this.getAddressComponentLongName(addressComponents, 'postal_code');
        return address;
    };
    AddressUtils.getAddressComponentLongName = function (addressComponents, type) {
        var component = AddressUtils.getAddressComponent(addressComponents, type);
        if (component && component['long_name']) {
            return component['long_name'];
        }
        return null;
    };
    AddressUtils.getAddressComponent = function (addressComponents, type) {
        var fn = addressComponents.find(function (element) {
            return _includes(element.types, type);
        });
        return fn;
    };
    return AddressUtils;
}());
export { AddressUtils };
