import { Location } from '@angular/common';
var GoBackComponent = /** @class */ (function () {
    function GoBackComponent(location) {
        this.location = location;
    }
    GoBackComponent.prototype.back = function () {
        this.location.back();
    };
    return GoBackComponent;
}());
export { GoBackComponent };
