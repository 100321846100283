import {Action} from '@ngrx/store';

import {BaseUserDto, ErrorsDto, UserViewDto} from '../../../core/models';

export enum UserViewActionTypes {
    LoadUserViewProfile = '[USER_VIEW] LOAD_USER_VIEW_PROFILE',
    LoadUserViewProfileSuccess = '[USER_VIEW] LOAD_USER_VIEW_PROFILE_SUCCESS',
    LoadUserViewProfileFail = '[USER_VIEW] LOAD_USER_VIEW_PROFILE_FAIL',

    LoadUserViewProfilePhone = '[USER_VIEW] LOAD_USER_VIEW_PROFILE_PHONE',
    LoadUserViewProfilePhoneSuccess = '[USER_VIEW] LOAD_USER_VIEW_PROFILE_PHONE_SUCCESS',
    LoadUserViewProfilePhoneFail = '[USER_VIEW] LOAD_USER_VIEW_PROFILE_PHONE_FAIL',

    Reset = '[USER_VIEW] RESET',

    OpenSendMessage = '[USER_VIEW] OPEN_SEND_MESSAGE',
    ShowPhone = '[USER_VIEW] SHOW_PHONE',
    AddUserProfileView = '[USER_VIEW] ADD_USER_PROFILE_VIEW'
}

/**
 * Load profile.
 */
export class LoadUserViewProfileAction implements Action {
    readonly type = UserViewActionTypes.LoadUserViewProfile;

    constructor(public payload: string) {
    }
}

export class LoadUserViewProfileSuccessAction implements Action {
    readonly type = UserViewActionTypes.LoadUserViewProfileSuccess;

    constructor(public payload: UserViewDto) {
    }
}

export class LoadUserViewProfileFailureAction implements Action {
    readonly type = UserViewActionTypes.LoadUserViewProfileFail;

    constructor(public payload: ErrorsDto) {
    }
}

/**
 * Load profile phone.
 */
export class LoadUserProfilePhoneAction implements Action {
    readonly type = UserViewActionTypes.LoadUserViewProfilePhone;

    constructor(public payload: string) {
    }
}

export class LoadUserProfilePhoneSuccessAction implements Action {
    readonly type = UserViewActionTypes.LoadUserViewProfilePhoneSuccess;

    constructor(public payload: string) {
    }
}

export class LoadUserProfilePhoneFailureAction implements Action {
    readonly type = UserViewActionTypes.LoadUserViewProfilePhoneFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class OpenSendMessageAction implements Action {
    readonly type = UserViewActionTypes.OpenSendMessage;

    constructor(public payload: BaseUserDto) {
    }
}

export class ShowPhoneAction implements Action {
    readonly type = UserViewActionTypes.ShowPhone;

    constructor(public payload: BaseUserDto) {
    }
}

export class AddUserProfileViewAction implements Action {
    readonly type = UserViewActionTypes.AddUserProfileView;

    constructor(public payload: string) {
    }
}

export class ResetAction implements Action {
    readonly type = UserViewActionTypes.Reset;
}

export type Actions = LoadUserViewProfileAction
    | LoadUserViewProfileSuccessAction
    | LoadUserViewProfileFailureAction
    | LoadUserProfilePhoneAction
    | LoadUserProfilePhoneSuccessAction
    | LoadUserProfilePhoneFailureAction
    | OpenSendMessageAction
    | ShowPhoneAction
    | AddUserProfileViewAction
    | ResetAction
    ;
