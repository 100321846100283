<div class="card w-card hoverable" [id]="worker?.uid">

    <a [routerLink]="['/workers', worker.uid, 'profile']"
       class="card-block w-card-body row justify-content-center justify-content-md-start mb-0 py-2">

        <div class="col-sm-2 px-0 w-card-img center">
            <img [appUserImg]="worker" class="img-thumbnail rounded-circle img-small">

            <div class="info-text-italic mt-1 center" *ngIf="worker?.distance">
                <span>{{'global.in-distance-of' | translate:{distance:worker.distance} }}</span>
            </div>
        </div>

        <div class="col-sm-7 w-card-information">

            <div class="col-12 w-card-identity center-xs-down">
                <h4 class="my-0 truncate"><span class="text-capitalize mr-2">{{worker?.firstName}}</span> <span
                        class="text-uppercase">{{worker?.lastName}}</span>
                </h4>
            </div>

            <div class="col-12 info-text center-xs-down">
                <i class="fa fa-map-marker" aria-hidden="true"></i> {{ worker?.address?.formatted }}
            </div>

            <div class="col-12 mt-2 w-card-fields center-sm-down">
                <app-chips [data]="worker.activities | activityTranslator"
                           [limitDisplaySize]="2"
                           [fromString]="true"
                           [displayOverflow]="true">
                </app-chips>
            </div>
        </div>

        <div class="col-sm-3 right-align">
            <div class="col-12 center-sm-down">
                <app-user-reviews-average [average]="worker.reviewsAverage"></app-user-reviews-average>
            </div>

            <div class="col-12 mt-2 center-sm-down">
                <app-worker-verification [verification]="worker.verification"></app-worker-verification>
            </div>

            <div class="col-12 mt-2 center-sm-down">
                <span style="vertical-align: top"><i class="material-icons mr-1">photo_library</i>{{worker.mediaCount}}</span>
            </div>
        </div>
    </a>

    <div class="card-footer">
        <div class="row mb-0 w-card-actions">

            <div class="col-12">

                <button *ngIf="worker?.showPhone"
                        class="btn-floating btn-primary btn-phone waves-effect waves-light" [appShowPhone]="worker">
                    <i class="material-icons" [title]="'global.show-phone' | translate"
                       aria-hidden="true">local_phone</i>
                </button>

                <button class="btn-floating btn-primary btn-phone waves-effect waves-light ml-2"
                        [appSendMessage]="worker">
                    <i class="material-icons" [title]="'global.send-message' | translate" aria-hidden="true">mail</i>
                </button>

                <button class="btn-floating btn-primary btn-phone waves-effect waves-light ml-2"
                        [appShare]="worker">
                    <i class="material-icons" [title]="'global.share-profile' | translate" aria-hidden="true">share</i>
                </button>

                <a materialize="dropdown" class='dropdown-button right w-actions' data-constrainWidth="false"
                   data-belowOrigin="true" [attr.data-activates]="'dropdown-'+worker?.uid">
                    <i class="material-icons" title="actions">more_vert</i>
                </a>
            </div>

        </div>

    </div>

    <div class="card-reveal">
        <span class="card-title grey-text text-darken-4" translate>global.services<i
                class="material-icons right">close</i></span>
        <app-activity-list [activities]="worker.activities"
                           colClass="col-md-6">
        </app-activity-list>
    </div>

    <ul [id]="'dropdown-' + worker?.uid" class='dropdown-content'>
        <li>
            <a class="not-link" [routerLink]="['/workers', worker.uid, 'profile']" translate>global.see-profile</a>
        </li>
        <li>
            <a class="activator" translate>global.see-services</a>
        </li>
        <li>
            <a class="not-link" [appShare]="worker" translate>global.share</a>
        </li>
        <li class="divider"></li>
        <li>
            <a [appReportUser]="worker" translate>global.report</a>
        </li>
    </ul>

</div>
