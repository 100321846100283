var ProfileViewHelper = /** @class */ (function () {
    function ProfileViewHelper() {
    }
    ProfileViewHelper.prototype.hideActionBar = function () {
    };
    ProfileViewHelper.prototype.showMoreActions = function (userProfile) {
    };
    return ProfileViewHelper;
}());
export { ProfileViewHelper };
