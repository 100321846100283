import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';

import * as rootActions from '../../../core/store/actions/route.action';
import {Notice} from '../../../core/models';
import * as fromRoot from '../../../store';

@Component({
    selector: 'app-notice-list',
    templateUrl: './notice-list.component.html',
    styleUrls: ['./notice-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticeListComponent {

    _notices: Notice[];

    constructor(private store: Store<fromRoot.State>) {
    }

    @Input() set notices(notices: Notice[]) {
        if (notices) {
            notices.sort((a, b) => a.priority - b.priority);
            this._notices = notices;
        }
    }

    goToPage(notice: Notice) {
        this.store.dispatch(new rootActions.ToNoticeTargetAction(notice));
    }

}
