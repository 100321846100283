import {Injectable, ViewContainerRef} from '@angular/core';
import {Observable} from 'rxjs';
import {ModalCallback, Page, Pageable, SearchCriteriaDto, UserResultDto} from '../../../../core/models';

@Injectable()
export class UsersSearchHelper {

    workers$: any;
    args: any;

    constructor() {

    }

    initPageable(): Pageable {
        return new Pageable();
    }

    clearRoute() {
    }

    initWorkers$() {
    }

    pushPage(page: Page<UserResultDto>) {
    }

    setLoadOnDemandeToAuto() {
    }

    get dataItems() {
        return this.workers$;
    }

    lastPageLoaded(args: any) {

    }

    setArgsReturnValue() {
    }

    showServiceDialog(vcRef: ViewContainerRef): Observable<ModalCallback<SearchCriteriaDto>> {
        return new Observable();
    }

}
