<div class="reviews" *ngIf="reviews?.length; else noReview">
    <app-review *ngFor="let review of reviews; let last = last"
                [review]="review"
                [ngClass]="{'last-review': last}"
                [isOwner]="UserAccountUtils.isSameUser(currentSession$ | async, review.user)"
                (deleteReview)="deleteReview.emit($event)"
                (updateReview)="updateReview.emit($event)">
    </app-review>
</div>

<ng-template #noReview>
    <span translate>{{emptyMessage}}</span>
</ng-template>
