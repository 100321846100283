<ul *ngIf="_notices?.length; else emptyNotice">
    <li class="card  mb-2" *ngFor="let notice of _notices" (click)="goToPage(notice)">
        <div class="card-body p-1">
            <div class="row">
                <div class="col-auto align-self-center center">
                    <span class="fa fa-2x" [ngClass]="notice.icon" style="width: 70px"></span>
                </div>
                <div class="col">
                    <span class="notice-title d-block" translate>notices.{{notice.type}}.title</span>
                    <span style="white-space: normal" translate>notices.{{notice.type}}.description</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12 center">
                    <button class="btn btn-primary" translate>
                        notices.{{notice.type}}.btn-text
                    </button>
                </div>
            </div>
        </div>
    </li>
</ul>
<ng-template #emptyNotice>
    <div class="row">
        <div class="col-12 center">
            <img src="assets/images/jingles_static.png">
        </div>
        <div class="col-12 center">
            <span translate>notices.empty</span>
        </div>
    </div>
</ng-template>