import {BudgetType} from './rest-api.model';

export class UIBudgetType {
    type: BudgetType;
    text: string;
    mediumText: string;
    smallText: string;
    arText: string;

    constructor(type: BudgetType, text: string, mediumText: string, smallText?: string, arText?: string) {
        this.type = type;
        this.text = text;
        this.mediumText = mediumText;
        this.smallText = smallText;
        this.arText = arText;
    }
}
