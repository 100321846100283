import {TranslateLoader} from '@ngx-translate/core';
import * as translationAr from 'assets/i18n/ar.json';
import * as translationArMa from 'assets/i18n/ar_ma.json';
import * as translationEN from 'assets/i18n/en.json';
import * as translationFr from 'assets/i18n/fr.json';

import {Observable, of} from 'rxjs';

const TRANSLATIONS = {
    ar: translationAr,
    fr: translationFr,
    en: translationEN,
    ar_ma: translationArMa
};

export class TranslateServerLoader implements TranslateLoader {
    constructor() {
    }

    public getTranslation(lang: string): Observable<any> {
        let translation = TRANSLATIONS[lang];
        return of(translation ? translation.default : {});
    }
}
