import {Action} from '@ngrx/store';
import {Notification} from '../../../core/models';

export enum AccountDeleteActionTypes {
    Delete = '[Account] DELETE',
    DeleteSuccess = '[Account] DELETE_SUCCESS',
    DeleteFail = '[Account] DELETE_FAIL',

    Reset = '[Account] RESET_DELETE',
}

export class DeleteAction implements Action {
    readonly type = AccountDeleteActionTypes.Delete;
}

export class DeleteSuccessAction implements Action {
    readonly type = AccountDeleteActionTypes.DeleteSuccess;
}

export class DeleteFailAction implements Action {
    readonly type = AccountDeleteActionTypes.DeleteFail;

    constructor(public payload: Notification) {
    }
}

export class ResetAction implements Action {
    readonly type = AccountDeleteActionTypes.Reset;
}

export type Actions = DeleteAction
    | DeleteSuccessAction
    | DeleteFailAction
    | ResetAction
    ;
