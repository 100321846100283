import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';

import {Notification, ReviewDto, ReviewReportDto} from '../../../../../core/models';

@Component({
    selector: 'app-report-review',
    templateUrl: './review-report.component.html',
    styleUrls: ['./review-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportReviewComponent implements OnInit {

    @Input() loading$: Observable<boolean>;
    @Input() notification: Notification;
    @Input() review: ReviewDto;
    @Input() initListener$: Observable<any>;

    @Output() cancel = new EventEmitter();
    @Output() report = new EventEmitter<ReviewReportDto>();

    _reviewReport = new ReviewReportDto();

    ngOnInit() {
        this.initListener$.subscribe(() => this._reviewReport = new ReviewReportDto());
    }

    reportReview(form: FormGroup) {
        if (form.invalid) {
            return;
        }

        this._reviewReport.reviewId = this.review.id;
        this.report.emit(this._reviewReport);
    }

}
