import {resetPasswordActions} from '../actions';
import {ErrorsDto, Notification} from '../../models';

export interface State {
    loading: boolean,
    notification: Notification
    passwordReseted: boolean,
    // Token validation
    tokenValidationLoading: boolean,
    tokenValidationErrors: ErrorsDto,
    tokenValid: boolean
}

export const initialState: State = {
    loading: false,
    notification: null,
    passwordReseted: false,
    // Token validation
    tokenValidationLoading: false,
    tokenValidationErrors: null,
    tokenValid: false
};

export function reducer(state = initialState, action: resetPasswordActions.Actions): State {

    switch (action.type) {

        /**
         * RESET PASSWORD
         */
        case resetPasswordActions.PasswordResetActionTypes.ResetPassword: {
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    notification: null,
                    passwordReseted: false
                }
            )
        }

        case resetPasswordActions.PasswordResetActionTypes.ResetPasswordSuccess: {
            return Object.assign(
                {},
                state,
                {
                    passwordReseted: true,
                    loading: false,
                    notification: action.payload
                }
            )
        }

        case resetPasswordActions.PasswordResetActionTypes.ResetPasswordFail: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    notification: action.payload,
                }
            )
        }

        /**
         * RESET PASSWORD TOKEN VALIDATION
         */
        case resetPasswordActions.PasswordResetActionTypes.ResetPasswordValidateToken: {
            return Object.assign(
                {},
                state,
                {
                    tokenValidationLoading: true,
                    tokenValidationErrors: null,
                    tokenValid: false
                }
            )
        }

        case resetPasswordActions.PasswordResetActionTypes.ResetPasswordValidateTokenSuccess: {
            return Object.assign(
                {},
                state,
                {
                    tokenValidationLoading: false,
                    tokenValidationErrors: null,
                    tokenValid: true
                }
            )
        }

        case resetPasswordActions.PasswordResetActionTypes.ResetPasswordValidateTokenFail: {
            return Object.assign(
                {},
                state,
                {
                    tokenValidationLoading: false,
                    tokenValidationErrors: action.payload,
                    tokenValid: false
                }
            )
        }

        /**
         * RESET State
         */
        case resetPasswordActions.PasswordResetActionTypes.ResetState: {
            return Object.assign(
                {},
                initialState
            )
        }

        default: {
            return state;
        }

    }
}

/**
 * Reset password
 */
export const isLoading = (state: State): boolean => state.loading;
export const isReseted = (state: State): boolean => state.passwordReseted;
export const getNotification = (state: State): Notification => state.notification;

/**
 * Reset password token validation
 */
export const isTokenValidationLoading = (state: State): boolean => state.tokenValidationLoading;
export const getTokenValidationErrors = (state: State): ErrorsDto => state.tokenValidationErrors;
export const isTokenValid = (state: State): boolean => state.tokenValid;
