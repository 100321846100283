import { PipeTransform } from '@angular/core';
var PluralizePipe = /** @class */ (function () {
    function PluralizePipe() {
    }
    PluralizePipe.prototype.transform = function (value, num) {
        if (!value) {
            return null;
        }
        if (num !== 1) {
            value = value.concat('s');
        }
        return value;
    };
    return PluralizePipe;
}());
export { PluralizePipe };
