import {BaseUserDto, ErrorsDto} from '../../models';
import {sendMessageActions} from '../actions';

export interface State {
    sender: BaseUserDto,
    receiver: BaseUserDto,
    sending: boolean,
    errors: ErrorsDto,
    sent: boolean
}

export const initialState: State = {
    sender: null,
    receiver: null,
    sending: false,
    errors: null,
    sent: false
};

export function reducer(state = initialState, action: sendMessageActions.Actions): State {

    switch (action.type) {

        /**
         * SEND MESSAGE.
         */
        case sendMessageActions.SendMessageActionTypes.SendMessage: {
            return Object.assign(
                {},
                state,
                {
                    sending: true,
                    sent: false
                }
            )
        }

        case sendMessageActions.SendMessageActionTypes.SendMessageSuccess: {
            return Object.assign(
                {},
                state,
                {
                    sending: false,
                    errors: null,
                    sent: true
                }
            )
        }

        case sendMessageActions.SendMessageActionTypes.SendMessageFail: {
            return Object.assign(
                {},
                state,
                {
                    sending: false,
                    errors: action.payload,
                    sent: false
                }
            )
        }

        case sendMessageActions.SendMessageActionTypes.SetSender: {
            return Object.assign(
                {},
                state,
                {sender: action.payload}
            )
        }

        case sendMessageActions.SendMessageActionTypes.SetReceiver: {
            return Object.assign(
                {},
                state,
                {receiver: action.payload}
            )
        }

        case sendMessageActions.SendMessageActionTypes.SetSent: {
            return Object.assign(
                state,
                {sent: action.payload}
            )
        }

        case sendMessageActions.SendMessageActionTypes.Reset: {
            return Object.assign(
                {},
                initialState
            )
        }

        default:
            return state;
    }
}

// Send Message
export const isSendMessageLoading = (state: State): boolean => state.sending;
export const getSendMessageErrors = (state: State): ErrorsDto => state.errors;
export const getSender = (state: State): BaseUserDto => state.sender;
export const getReceiver = (state: State): BaseUserDto => state.receiver;
export const isSent = (state: State): boolean => state.sent;
