export var AuthenticationActionTypes;
(function (AuthenticationActionTypes) {
    AuthenticationActionTypes["Authenticate"] = "[Authentication] AUTHENTICATE";
    AuthenticationActionTypes["AuthenticateSuccess"] = "[Authentication] AUTHENTICATE_SUCCESS";
    AuthenticationActionTypes["AuthenticateFailure"] = "[Authentication] AUTHENTICATE_FAILURE";
    AuthenticationActionTypes["AuthenticationReset"] = "[Authentication] AUTHENTICATION_RESET";
    AuthenticationActionTypes["LoadUserProfile"] = "[Authentication] LoadUserProfile";
    AuthenticationActionTypes["LoadUserProfileSuccess"] = "[Authentication] LoadUserProfileSuccess";
    AuthenticationActionTypes["LoadUserProfileFail"] = "[Authentication] LoadUserProfileFail";
    AuthenticationActionTypes["SetUser"] = "[Authentication] SET_USER";
    AuthenticationActionTypes["TestUser"] = "[Authentication] TEST_USER";
    AuthenticationActionTypes["TestSessionSuccess"] = "[Authentication] TEST_USER_SUCCESS";
    AuthenticationActionTypes["SocialAuthenticate"] = "[Authentication] SOCIAL_AUTHENTICATE";
    AuthenticationActionTypes["SocialAuthenticateSuccess"] = "[Authentication] SOCIAL_AUTHENTICATE_SUCCESS";
    AuthenticationActionTypes["SocialAuthenticateFailure"] = "[Authentication] SOCIAL_AUTHENTICATE_FAILURE";
    AuthenticationActionTypes["CountUnreadConversations"] = "[Authentication] COUNT_UNREAD_CONVERSATIONS";
    AuthenticationActionTypes["CountUnreadConversationsSuccess"] = "[Authentication] COUNT_UNREAD_CONVERSATIONS_SUCCESS";
    AuthenticationActionTypes["CountUnreadConversationsFail"] = "[Authentication] COUNT_UNREAD_CONVERSATIONS_FAIL";
    AuthenticationActionTypes["SetErrors"] = "[Authentication] SET_ERRORS";
    AuthenticationActionTypes["SetLoaded"] = "[Authentication] SetLoaded";
    AuthenticationActionTypes["Logout"] = "[Authentication] LOGOUT";
    AuthenticationActionTypes["SetPicture"] = "[Authentication] SET_PICTURE";
})(AuthenticationActionTypes || (AuthenticationActionTypes = {}));
var LoadUserProfileAction = /** @class */ (function () {
    function LoadUserProfileAction() {
        this.type = AuthenticationActionTypes.LoadUserProfile;
    }
    return LoadUserProfileAction;
}());
export { LoadUserProfileAction };
var SetLoaded = /** @class */ (function () {
    function SetLoaded() {
        this.type = AuthenticationActionTypes.SetLoaded;
    }
    return SetLoaded;
}());
export { SetLoaded };
var LoadUserProfileSuccessAction = /** @class */ (function () {
    function LoadUserProfileSuccessAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.LoadUserProfileSuccess;
    }
    return LoadUserProfileSuccessAction;
}());
export { LoadUserProfileSuccessAction };
var LoadUserProfileFailAction = /** @class */ (function () {
    function LoadUserProfileFailAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.LoadUserProfileFail;
    }
    return LoadUserProfileFailAction;
}());
export { LoadUserProfileFailAction };
var SetErrorsAction = /** @class */ (function () {
    function SetErrorsAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.SetErrors;
    }
    return SetErrorsAction;
}());
export { SetErrorsAction };
var AuthenticateAction = /** @class */ (function () {
    function AuthenticateAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.Authenticate;
    }
    return AuthenticateAction;
}());
export { AuthenticateAction };
var AuthenticateSuccessAction = /** @class */ (function () {
    function AuthenticateSuccessAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.AuthenticateSuccess;
    }
    return AuthenticateSuccessAction;
}());
export { AuthenticateSuccessAction };
var AuthenticateFailureAction = /** @class */ (function () {
    function AuthenticateFailureAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.AuthenticateFailure;
    }
    return AuthenticateFailureAction;
}());
export { AuthenticateFailureAction };
var SocialAuthenticateAction = /** @class */ (function () {
    function SocialAuthenticateAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.SocialAuthenticate;
    }
    return SocialAuthenticateAction;
}());
export { SocialAuthenticateAction };
var SocialAuthenticateSuccessAction = /** @class */ (function () {
    function SocialAuthenticateSuccessAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.SocialAuthenticateSuccess;
    }
    return SocialAuthenticateSuccessAction;
}());
export { SocialAuthenticateSuccessAction };
var SocialAuthenticateFailureAction = /** @class */ (function () {
    function SocialAuthenticateFailureAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.SocialAuthenticateFailure;
    }
    return SocialAuthenticateFailureAction;
}());
export { SocialAuthenticateFailureAction };
var LogOutAction = /** @class */ (function () {
    function LogOutAction() {
        this.type = AuthenticationActionTypes.Logout;
    }
    return LogOutAction;
}());
export { LogOutAction };
var SetUserAction = /** @class */ (function () {
    function SetUserAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.SetUser;
    }
    return SetUserAction;
}());
export { SetUserAction };
var TestUserAction = /** @class */ (function () {
    function TestUserAction() {
        this.type = AuthenticationActionTypes.TestUser;
    }
    return TestUserAction;
}());
export { TestUserAction };
var TestUserSuccessAction = /** @class */ (function () {
    function TestUserSuccessAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.TestSessionSuccess;
    }
    return TestUserSuccessAction;
}());
export { TestUserSuccessAction };
var ResetAuthenticationAction = /** @class */ (function () {
    function ResetAuthenticationAction() {
        this.type = AuthenticationActionTypes.AuthenticationReset;
    }
    return ResetAuthenticationAction;
}());
export { ResetAuthenticationAction };
var SetPictureAction = /** @class */ (function () {
    function SetPictureAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.SetPicture;
    }
    return SetPictureAction;
}());
export { SetPictureAction };
/**
 * Count unread conversations
 */
var CountUnreadConversationsAction = /** @class */ (function () {
    function CountUnreadConversationsAction() {
        this.type = AuthenticationActionTypes.CountUnreadConversations;
    }
    return CountUnreadConversationsAction;
}());
export { CountUnreadConversationsAction };
var CountUnreadConversationsSuccessAction = /** @class */ (function () {
    function CountUnreadConversationsSuccessAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.CountUnreadConversationsSuccess;
    }
    return CountUnreadConversationsSuccessAction;
}());
export { CountUnreadConversationsSuccessAction };
var CountUnreadConversationsFailAction = /** @class */ (function () {
    function CountUnreadConversationsFailAction(payload) {
        this.payload = payload;
        this.type = AuthenticationActionTypes.CountUnreadConversationsFail;
    }
    return CountUnreadConversationsFailAction;
}());
export { CountUnreadConversationsFailAction };
