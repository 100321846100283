<div class="container">
    <div class="card center">
        <div class="card-block">
            <div class="row mb-0">
                <div class="col-12">
                    <h1>Oups !</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h2 translate>global.page-not-found</h2>
                </div>
            </div>
        </div>
    </div>
</div>
