<div class="container-fluid signup-header" yfLayoutClass>
    <div class="container">
        <div class="right-align member">
            <span class="white-text" translate>sign-up.is-already-member</span>
            <button type="button" class="btn btn-default" routerLink="/login" translate>global.sign-in</button>
        </div>
    </div>
</div>

<div>
    <div class="ads-ctr-left mt-2">
        <ng-adsense></ng-adsense>
    </div>
    <div class="ads-ctr-right mt-2">
        <ng-adsense></ng-adsense>
    </div>
    <div class="container" yfLayoutClass>

        <div class="row mb-0">
            <div class="col-12 center">
                <h1 translate>sign-up.register-freely</h1>
            </div>
            <div class="col-12 center" translate>
                sign-up.register-subtitle
            </div>
        </div>

        <div class="row justify-content-center">

            <div class="col-md-10 col-lg-8">

                <div class="card social-sign-up">

                    <div class="card-header center">
                        <span class="text-white" translate>sign-in.with-social-media</span>
                    </div>

                    <div class="card-block row mb-0 justify-content-center">
                        <span class="mb-1 hidden-md-up" translate>sign-up.with-facebook</span>

                        <form ngNoForm action="/signin/facebook" method="post" class="col-lg-8 center-md-up">
                            <app-notification [notification]="socialNotification"></app-notification>
                            <div class="row mb-0">

                                <div class="col-12 center">
                                    <input type="hidden" name="scope"
                                           value="email,public_profile,user_location"/>

                                    <button type="submit" class="waves-effect waves-light btn social facebook"
                                            dir="ltr">
                                        <i class="fa fa-facebook"></i> <span class="hidden-sm-down"
                                                                             translate>sign-up.with </span>Facebook
                                    </button>
                                </div>

                                <!--<span class="grey-text font-italic mt-2 col-12">Nous ne publions rien sur votre mur sans votre autorisation</span>-->

                            </div>
                        </form>
                    </div>
                </div>

                <div class="center">
                    <h2 class="h5 my-4" translate>global.or</h2>
                </div>


                <div class="card local-sign-up">

                    <div class="card-header center">
                        <span class="text-white" translate>sign-in.with-email</span>
                    </div>

                    <div class="card-block">

                        <span class="mb-1 hidden-md-up" translate>sign-up.with-login</span>

                        <div class="row justify-content-center">
                            <form materialize name="form" class="col-lg-8" (ngSubmit)="onSubmit()"
                                  [formGroup]="userRegistration">


                                <app-notification [notification]="notification$ | async">
                                    <div class="row mb-0 mt-2" *ngIf="mailAlreadyTaken">
                                        <div class="col">
                                            <a class="a-link" (click)="toPasswordForgotten()" translate>global.is-password-forgotten</a>
                                        </div>
                                    </div>
                                </app-notification>

<!--                                <div class="input-field">-->
<!--                                    <input type="text" id="email" name="email"-->
<!--                                           class="form-control" formControlName="email">-->
<!--                                    <label for="email" translate>global.login</label>-->
<!--                                </div>-->

<!--                                <app-control-messages [control]="userRegistration" controlName="email"-->
<!--                                                      forLabel="email"></app-control-messages>-->

                                <app-login-input [id]="'email'"
                                                 [labelKey]="'global.login'"
                                                 [parentFormGroup]="userRegistration"
                                                 [controlName]="'email'"
                                                 [placeholder]="'global.login-placeholder' | translate">
                                </app-login-input>

                                <app-control-messages [control]="userRegistration"
                                                      keyPrefix="errors.custom"
                                                      controlName="email" forLabel="email">
                                </app-control-messages>

                                <div class="input-field">
                                    <input type="password" id="pass" name="pass"
                                           class="form-control" formControlName="password">
                                    <label for="pass" translate>global.password</label>
                                </div>

                                <app-control-messages [control]="userRegistration" controlName="password"
                                                      forLabel="pass"></app-control-messages>

                                <div class="mt-4">
                                    <input type="checkbox" class="filled-in form-control" id="showPhone"
                                           name="showPhone"
                                           formControlName="acceptTermsOfService"/>
                                    <label for="showPhone" translate>sign-up.agree-with
                                        <a [routerLink]="['/conditions-generales-vente']" target="_blank" translate>sign-in.cgu</a>
                                    </label>
                                </div>

                                <button type="submit" class="btn btn-default col-12 mb-3 mt-4"
                                        [disabled]="userRegistration.invalid || !acceptTermsOfService || (loading$ | async)"
                                        translate>
                                    global.sign-up
                                </button>

                                <span class="font-italic" translate>
                                    sign-up.accept-terms <a
                                        [routerLink]="['/conditions-generales-vente']" translate>sign-in.cgu</a>
                            </span>

                            </form>

                        </div>
                    </div>
                </div>

            </div>


            <!--<div class="col-lg-4">-->
            <!--<div class="row">-->
            <!--<div class="col">-->
            <!--<div class="card">-->
            <!--<h5>Comment ça marche ?</h5>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->

            <!--<div class="row">-->
            <!--<div class="col">-->
            <!--<div class="card">-->
            <!--<h5>Qui sommes nous ?</h5>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->

            <!--</div>-->
        </div>

        <div class="row">
            <div class="col-12 center">
                <ng-adsense></ng-adsense>
            </div>
        </div>
    </div>
</div>