import {Directive, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';

import * as fromRoot from '../../store';
import {userViewActions} from '../../users/store/actions';
import {AbstractSendMessageDirective} from '../../shared/directives/abstract-send-message.directive';

@Directive({
    selector: '[appSendMessage]'
})
export class SendMessageDirective extends AbstractSendMessageDirective implements OnInit {

    constructor(private _store: Store<fromRoot.State>) {
        super(_store);
    }

    doSendMessageAction() {
        this._store.dispatch(new userViewActions.OpenSendMessageAction(this.appSendMessage));
    }

}
