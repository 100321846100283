import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { throwError as _throw } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Config } from '../../../config';
import { StorageKey } from '../models';
import { HttpUtil } from '../utils';
import { StorageService } from './storage.service';
import { ValidationService } from './validation.service';
import * as i0 from "@angular/core";
import * as i1 from "./storage.service";
import * as i2 from "./validation.service";
import * as i3 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(platformId, storageService, validationService, httpClient) {
        this.platformId = platformId;
        this.storageService = storageService;
        this.validationService = validationService;
        this.httpClient = httpClient;
    }
    AuthService.prototype.authenticate = function (userLoginForm) {
        var _this = this;
        var errorsDto = this.validationService.validateUserLoginForm(userLoginForm);
        if (errorsDto) {
            return _throw(errorsDto);
        }
        return this.httpClient.post('api/v1/login', userLoginForm, { withCredentials: true }).pipe(tap(function (session) { return _this.setSession(session); }));
    };
    AuthService.prototype.socialAuthentication = function (accessToken) {
        var _this = this;
        var options = HttpUtil.appendQueryParam({}, 'access_token', accessToken);
        return this.httpClient.get('m/signup', options).pipe(tap(function (session) { return _this.setSession(session); }));
    };
    AuthService.prototype.logout = function () {
        return this.httpClient.post('api/v1/logout', null);
        // this.clearSession();
    };
    AuthService.prototype.countUnreadConversations = function (userUid) {
        return this.httpClient.get(HttpUtil.buildAndExpand('api/v1/users/{userUid}/conversations/unread/count', userUid));
    };
    AuthService.prototype.getCurrent = function () {
        return this.httpClient.get('api/v1/current');
    };
    AuthService.prototype.getCurrentUserProfile = function (userUid) {
        return this.httpClient.get(HttpUtil.buildAndExpand('api/v1/users/{userUid}/profile', userUid));
    };
    AuthService.prototype.setSession = function (session) {
        this.storageService.setItem(StorageKey.TOKEN, session.token);
        this.storageService.setItem(StorageKey.USER, JSON.stringify(session.user));
        this.storageService.setItem(StorageKey.EXPIRES_AT, JSON.stringify(session.expirationDate));
    };
    AuthService.prototype.updateUserAccount = function (userAccount) {
        this.storageService.setItem(StorageKey.USER, JSON.stringify(userAccount));
    };
    AuthService.prototype.clearUser = function () {
        if (this.isMobileOrPlatformBrowser()) {
            this.storageService.removeItem(StorageKey.USER);
        }
    };
    AuthService.prototype.clearSession = function () {
        if (this.isMobileOrPlatformBrowser()) {
            this.storageService.removeItem(StorageKey.TOKEN);
            this.storageService.removeItem(StorageKey.USER);
            this.storageService.removeItem(StorageKey.EXPIRES_AT);
        }
    };
    AuthService.prototype.isAuthenticated = function () {
        var expiresAt = this.getExpiresAt();
        if (!expiresAt && !this.getToken()) {
            return false;
        }
        return Date.now() < expiresAt.getTime();
    };
    AuthService.prototype.getUser = function () {
        if (this.isMobileOrPlatformBrowser()) {
            return JSON.parse(this.storageService.getItem(StorageKey.USER));
        }
        return null;
    };
    AuthService.prototype.getToken = function () {
        if (this.isMobileOrPlatformBrowser()) {
            return this.storageService.getItem(StorageKey.TOKEN);
        }
        return null;
    };
    AuthService.prototype.setToken = function (token) {
        this.storageService.setItem(StorageKey.TOKEN, token);
    };
    AuthService.prototype.getExpiresAt = function () {
        if (this.isMobileOrPlatformBrowser()) {
            return new Date(JSON.parse(this.storageService.getItem(StorageKey.EXPIRES_AT)));
        }
        return null;
    };
    AuthService.prototype.isMobileOrPlatformBrowser = function () {
        return Config.IS_MOBILE_NATIVE() || (Config.IS_WEB() && isPlatformBrowser(this.platformId));
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i1.StorageService), i0.ɵɵinject(i2.ValidationService), i0.ɵɵinject(i3.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
