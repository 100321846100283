import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var _startsWith = require('lodash/startsWith');
export var RouteNames;
(function (RouteNames) {
    RouteNames[RouteNames["REQUESTS"] = 'requests'] = "REQUESTS";
    RouteNames[RouteNames["PASSWORD_RESET"] = 'password/forgotten'] = "PASSWORD_RESET";
    RouteNames[RouteNames["WORKER"] = '/workers'] = "WORKER";
    RouteNames[RouteNames["CONVERSATIONS"] = 'conversations'] = "CONVERSATIONS";
    RouteNames[RouteNames["LOGIN"] = 'login'] = "LOGIN";
    RouteNames[RouteNames["USER"] = 'user'] = "USER";
    RouteNames[RouteNames["HOME"] = '/'] = "HOME";
    RouteNames[RouteNames["SEARCH"] = '/workers'] = "SEARCH";
    RouteNames[RouteNames["EMAIL_CONFIRMATION"] = '/register/confirm'] = "EMAIL_CONFIRMATION";
    RouteNames[RouteNames["USER_PROFILE"] = '/user/profile'] = "USER_PROFILE";
    RouteNames[RouteNames["USER_PROFILE_VERIFICATIONS"] = '/user/profile/verifications'] = "USER_PROFILE_VERIFICATIONS";
    RouteNames[RouteNames["COMPLETE_USER_PROFILE"] = '/user/complete'] = "COMPLETE_USER_PROFILE";
    RouteNames[RouteNames["NOT_FOUND"] = '/404'] = "NOT_FOUND";
    RouteNames[RouteNames["FORBIDDEN"] = '/403'] = "FORBIDDEN";
})(RouteNames || (RouteNames = {}));
/**
 * Allows to manage applications routes
 */
var RouteService = /** @class */ (function () {
    function RouteService(router) {
        this.router = router;
    }
    RouteService.prototype.toSignInPage = function () {
        this.router.navigate([RouteNames.LOGIN]);
    };
    RouteService.prototype.toUserProfilePage = function () {
        this.router.navigate([RouteNames.USER_PROFILE]);
    };
    RouteService.prototype.toProfileVerificationsPage = function () {
        this.router.navigate([RouteNames.USER_PROFILE_VERIFICATIONS]);
    };
    RouteService.prototype.toSearchPage = function (navigationExtras) {
        this.router.navigate([RouteNames.WORKER], navigationExtras);
    };
    RouteService.prototype.toHome = function () {
        this.router.navigate([RouteNames.HOME]);
    };
    RouteService.prototype.toUrl = function (url) {
        this.router.navigateByUrl(url);
    };
    RouteService.prototype.toErrorPage = function () {
        this.router.navigate(['error.html']);
    };
    RouteService.prototype.toWorker = function (id) {
        this.router.navigate(['/', 'workers', id, 'profile']);
    };
    RouteService.prototype.toInitialLocation = function () {
        this.router.navigate([_startsWith(this.redirectUrl, RouteNames.LOGIN) ? RouteNames.HOME : this.redirectUrl]);
    };
    RouteService.prototype.toConversationPage = function (conversationId) {
        this.router.navigate([RouteNames.USER, RouteNames.CONVERSATIONS, conversationId]);
    };
    RouteService.prototype.toConversations = function () {
        this.router.navigate([RouteNames.USER, RouteNames.CONVERSATIONS]);
    };
    RouteService.prototype.toCompleteUserProfile = function () {
        this.router.navigate([RouteNames.COMPLETE_USER_PROFILE]);
    };
    RouteService.prototype.toEmailConfirmation = function (navigationExtras) {
        this.router.navigate([RouteNames.EMAIL_CONFIRMATION], navigationExtras);
    };
    RouteService.prototype.toPasswordResetPage = function (navigationExtras) {
        this.router.navigate([RouteNames.PASSWORD_RESET], navigationExtras);
    };
    RouteService.prototype.toRequest = function (id, navigationExtras) {
        this.router.navigate([RouteNames.REQUESTS, id], navigationExtras);
    };
    RouteService.prototype.toRequests = function (navigationExtras) {
        this.router.navigate([RouteNames.REQUESTS], navigationExtras);
    };
    RouteService.prototype.toUpdateRequest = function (id, navigationExtras) {
        this.router.navigate([RouteNames.USER, RouteNames.REQUESTS, id], navigationExtras);
    };
    RouteService.prototype.toNotFound = function (navigationExtras) {
        this.router.navigate([RouteNames.NOT_FOUND], navigationExtras);
    };
    RouteService.prototype.toForbidden = function (navigationExtras) {
        this.router.navigate([RouteNames.FORBIDDEN], navigationExtras);
    };
    RouteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouteService_Factory() { return new RouteService(i0.ɵɵinject(i1.Router)); }, token: RouteService, providedIn: "root" });
    return RouteService;
}());
export { RouteService };
