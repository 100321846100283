import {ErrorsDto, UserViewDto} from '../../../core/models';

import {userViewActions} from '../actions';

export interface State {
    // profile
    userProfile: UserViewDto,
    userProfileLoading: boolean,
    userProfileErrors: ErrorsDto,
    // phone
    phone: string,
    phoneLoading: boolean
    phoneErrors: ErrorsDto,
}

export const initialState: State = {
    userProfile: null,
    userProfileLoading: false,
    userProfileErrors: null,
    phone: null,
    phoneLoading: false,
    phoneErrors: null
};

export function reducer(state = initialState, action: userViewActions.Actions): State {

    switch (action.type) {

        /**
         * LOAD USER PROFILE FOR VIEW.
         */
        case userViewActions.UserViewActionTypes.LoadUserViewProfile: {
            return Object.assign(
                {},
                state,
                {
                    userProfile: null,
                    userProfileLoading: true,
                    userProfileErrors: null,
                    phone: null,
                    phoneLoading: false,
                    phoneErrors: null
                }
            )
        }

        case userViewActions.UserViewActionTypes.LoadUserViewProfileSuccess: {
            return Object.assign(
                {},
                state,
                {
                    userProfileLoading: false,
                    userProfile: action.payload,
                    userProfileErrors: null,
                    phone: null,
                    phoneLoading: false,
                    phoneErrors: null
                }
            )
        }

        case userViewActions.UserViewActionTypes.LoadUserViewProfileFail: {
            return Object.assign(
                {},
                state,
                {
                    userProfileLoading: false,
                    userProfile: null,
                    userProfileErrors: action.payload,
                    phone: null,
                    phoneLoading: false,
                    phoneErrors: null
                }
            )
        }

        /**
         * PHONE.
         */
        case userViewActions.UserViewActionTypes.LoadUserViewProfilePhone: {
            return Object.assign(
                {},
                state,
                {
                    phone: null,
                    phoneLoading: true,
                    phoneErrors: null
                }
            )
        }

        case userViewActions.UserViewActionTypes.LoadUserViewProfilePhoneSuccess: {
            return Object.assign(
                {},
                state,
                {
                    phoneLoading: false,
                    phone: action.payload,
                    phoneErrors: null
                }
            )
        }

        case userViewActions.UserViewActionTypes.LoadUserViewProfilePhoneFail: {
            return Object.assign(
                {},
                state,
                {
                    phoneLoading: false,
                    phone: null,
                    phoneErrors: action.payload
                }
            )
        }

        case userViewActions.UserViewActionTypes.Reset: {
            return Object.assign(
                {},
                state,
                initialState
            )
        }

        default:
            return state;
    }
}

// Profile.
export const getUserViewProfile = (state: State): UserViewDto => state.userProfile;
export const getUserViewProfileErrors = (state: State): ErrorsDto => state.userProfileErrors;
export const isUserViewProfileLoading = (state: State): boolean => state.userProfileLoading;

// Phone
export const getPhone = (state: State): string => state.phone;
export const isPhoneLoading = (state: State): boolean => state.phoneLoading;
export const getPhoneErrors = (state: State): ErrorsDto => state.phoneErrors;
