import {MapsAPILoader} from '@agm/core';
import {DocumentRef} from '@agm/core/utils/browser-globals';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {filter, map, tap} from 'rxjs/operators';

import {localeActions} from '../actions';
import {BrowserService} from '../../services';

declare var window: any;

@Injectable()
export class WebEffects {

    @Effect({dispatch: false})
    reloadMapsConfig = this.actions$.pipe(
        ofType(localeActions.LocaleActionsTypes.SetLocale),
        map((action: localeActions.SetLocaleAction) => action.payload),
        filter(() => this.browserService.isPlatformBrowser()),
        tap(locale => {

            let loader = <any>this.mapsAPILoader;

            if (loader && loader._config) {
                // Set loader language to locale
                loader._config.language = locale.shortcut;

                // See https://github.com/SebastianM/angular-google-maps/blob/master/packages/core/services/maps-api-loader/lazy-maps-api-loader.ts

                // delete google maps object
                if (window.google && window.google.maps) {
                    window.google.maps = null;
                }

                // delete _scriptLoadingPromise
                loader._scriptLoadingPromise = null;

                // delete maps script
                const scriptOnPage = this._documentRef.getNativeDocument().getElementById(loader._SCRIPT_ID);

                if (scriptOnPage) {
                    scriptOnPage.remove();
                }
            }

        }));

    constructor(private actions$: Actions,
                private _documentRef: DocumentRef,
                private browserService: BrowserService,
                private mapsAPILoader: MapsAPILoader) {

    }
}
