import {Action} from '@ngrx/store';
import {DisplayRequestDto, ErrorsDto, Pageable, PromotionResultDto} from '../../../core/models';

export enum RequestPromotionActionTypes {
    LoadPromotionRequests = '[REQUEST PROMOTION] LOAD_REQUESTS_AROUND',
    LoadPromotionRequestsSuccess = '[REQUEST PROMOTION] LOAD_PROMOTION_REQUESTS_SUCCESS',
    LoadPromotionRequestsFail = '[REQUEST PROMOTION] LOAD_PROMOTION_REQUESTS_FAIL',

    LoadPromotionRequestsIfNotLoaded = '[REQUEST PROMOTION] LOAD_PROMOTION_REQUESTS_IF_NOT_LOADED'
}

export class LoadPromotionRequestsIfNotLoadedAction implements Action {
    readonly type = RequestPromotionActionTypes.LoadPromotionRequestsIfNotLoaded;

    constructor(public payload: Pageable) {
    }
}

export class LoadPromotionRequestsAction implements Action {
    readonly type = RequestPromotionActionTypes.LoadPromotionRequests;

    constructor(public payload: Pageable) {
    }
}

export class LoadPromotionRequestsSuccessAction implements Action {
    readonly type = RequestPromotionActionTypes.LoadPromotionRequestsSuccess;

    constructor(public payload: PromotionResultDto<DisplayRequestDto>) {
    }
}

export class LoadPromotionRequestsFailAction implements Action {
    readonly type = RequestPromotionActionTypes.LoadPromotionRequestsFail;

    constructor(public payload: ErrorsDto) {
    }
}

export type RequestsPromotionActions = LoadPromotionRequestsAction
    | LoadPromotionRequestsSuccessAction
    | LoadPromotionRequestsFailAction
    | LoadPromotionRequestsIfNotLoadedAction
    ;
