import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Gender } from '../models';
import { BcUtilsService } from './bc-utils.service';
import * as i0 from "@angular/core";
import * as i1 from "./bc-utils.service";
import * as i2 from "@angular/platform-browser";
import * as i3 from "@ngx-translate/core";
// TODO complete open graph tags http://ogp.me/#types
var OpenGraphService = /** @class */ (function () {
    function OpenGraphService(utilsService, meta, translateService) {
        this.utilsService = utilsService;
        this.meta = meta;
        this.translateService = translateService;
    }
    OpenGraphService.prototype.setUserViewOG = function (profile) {
        var title = this.translateService.instant('seo.profile-view.title', { profile: profile });
        var profileUrl = this.utilsService.getProfileUrl(profile);
        var picture = this.utilsService.getImageFullPath(profile.picture);
        this.addTag('og:title', title);
        this.addTag('og:url', profileUrl);
        if (!picture) {
            this.addTag('og:image', picture);
        }
        this.addTag('og:type', 'profile');
        this.addTag('profile:first_name', profile.firstName);
        this.addTag('profile:last_name', profile.lastName);
        this.addTag('profile:gender', profile.gender === Gender.MAN ? 'male' : 'female');
    };
    OpenGraphService.prototype.openGraphRequest = function (request) {
        var title = this.translateService.instant('seo.request-view.title', { profile: request });
        var profileUrl = this.utilsService.getProfileUrl(request);
        var picture = this.utilsService.getImageFullPath(request.picture);
        this.addTag('og:title', title);
        this.addTag('og:url', profileUrl);
        if (!picture) {
            this.addTag('og:image', picture);
        }
        this.addTag('og:type', 'request');
        this.addTag('request:first_name', request.firstName);
        this.addTag('request:last_name', request.lastName);
        this.addTag('request:gender', request.gender === Gender.MAN ? 'male' : 'female');
    };
    OpenGraphService.prototype.addTag = function (name, content) {
        this.meta.addTag({
            name: name,
            content: content
        });
    };
    OpenGraphService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpenGraphService_Factory() { return new OpenGraphService(i0.ɵɵinject(i1.BcUtilsService), i0.ɵɵinject(i2.Meta), i0.ɵɵinject(i3.TranslateService)); }, token: OpenGraphService, providedIn: "root" });
    return OpenGraphService;
}());
export { OpenGraphService };
