import { PressArticle } from '../../../../core/models/press-article';
var PromotionPressComponent = /** @class */ (function () {
    function PromotionPressComponent() {
        this.articles = [
            new PressArticle('HuffPost Maghreb', 'huffpost.png', 'http://huffp.st/Ddl8iLr'),
            new PressArticle('Lematin', 'lematin.jpg', 'https://lematin.ma/express/2018/lancement-plateforme-bricool/299645.html'),
            new PressArticle('welovebuzz', 'welovebuzz.png', 'https://www.welovebuzz.com/besoin-dun-service-ou-dun-coup-de-main-bricool-soccupe-de-tout/')
        ];
    }
    return PromotionPressComponent;
}());
export { PromotionPressComponent };
