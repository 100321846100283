import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import * as fromRoot from '../../../store';
import {routeActions} from '../../store/actions';
import {RouteService} from '../index';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private store: Store<fromRoot.State>,
                private routeService: RouteService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.pipe(select(fromRoot.getCurrentUser),
            map(currentUser => {
                if (currentUser) {
                    return true;
                } else {
                    this.routeService.redirectUrl = state.url;
                    this.store.dispatch(new routeActions.ToSignInAction());
                    return false;
                }
            })
        );
    }

}

