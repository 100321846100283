export var PasswordResetActionTypes;
(function (PasswordResetActionTypes) {
    PasswordResetActionTypes["ResetPassword"] = "[Password] RESET_PASSWORD";
    PasswordResetActionTypes["ResetPasswordSuccess"] = "[Password] RESET_PASSWORD_SUCCESS";
    PasswordResetActionTypes["ResetPasswordFail"] = "[Password] RESET_PASSWORD_FAIL";
    PasswordResetActionTypes["ResetPasswordValidateToken"] = "[Password] RESET_PASSWORD_VALIDATE_TOKEN";
    PasswordResetActionTypes["ResetPasswordValidateTokenSuccess"] = "[Password] RESET_PASSWORD_VALIDATE_TOKEN_SUCCESS";
    PasswordResetActionTypes["ResetPasswordValidateTokenFail"] = "[Password] RESET_PASSWORD_VALIDATE_TOKEN_FAIL";
    PasswordResetActionTypes["ResetState"] = "[Password] RESET_STATE";
})(PasswordResetActionTypes || (PasswordResetActionTypes = {}));
/**
 * reset password
 */
var ResetPasswordAction = /** @class */ (function () {
    function ResetPasswordAction(payload) {
        this.payload = payload;
        this.type = PasswordResetActionTypes.ResetPassword;
    }
    return ResetPasswordAction;
}());
export { ResetPasswordAction };
var ResetPasswordSuccessAction = /** @class */ (function () {
    function ResetPasswordSuccessAction(payload) {
        this.payload = payload;
        this.type = PasswordResetActionTypes.ResetPasswordSuccess;
    }
    return ResetPasswordSuccessAction;
}());
export { ResetPasswordSuccessAction };
var ResetPasswordFailAction = /** @class */ (function () {
    function ResetPasswordFailAction(payload) {
        this.payload = payload;
        this.type = PasswordResetActionTypes.ResetPasswordFail;
    }
    return ResetPasswordFailAction;
}());
export { ResetPasswordFailAction };
/**
 * reset password token validation
 */
var ValidateResetPasswordTokenAction = /** @class */ (function () {
    function ValidateResetPasswordTokenAction(payload) {
        this.payload = payload;
        this.type = PasswordResetActionTypes.ResetPasswordValidateToken;
    }
    return ValidateResetPasswordTokenAction;
}());
export { ValidateResetPasswordTokenAction };
var ValidateResetPasswordTokenSuccessAction = /** @class */ (function () {
    function ValidateResetPasswordTokenSuccessAction() {
        this.type = PasswordResetActionTypes.ResetPasswordValidateTokenSuccess;
    }
    return ValidateResetPasswordTokenSuccessAction;
}());
export { ValidateResetPasswordTokenSuccessAction };
var ValidateResetPasswordTokenFailAction = /** @class */ (function () {
    function ValidateResetPasswordTokenFailAction(payload) {
        this.payload = payload;
        this.type = PasswordResetActionTypes.ResetPasswordValidateTokenFail;
    }
    return ValidateResetPasswordTokenFailAction;
}());
export { ValidateResetPasswordTokenFailAction };
var ResetStateAction = /** @class */ (function () {
    function ResetStateAction() {
        this.type = PasswordResetActionTypes.ResetState;
    }
    return ResetStateAction;
}());
export { ResetStateAction };
