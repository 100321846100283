import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Config } from '../../../../../config';
import { localeActions } from '../../../../core/store/actions';
import { StorageKey } from '../../../../core/models/interfaces/istorage';
import { Locale, ModalEvent } from '../../../../core/models';
import { BrowserService } from '../../../../core/services';
import { StorageService } from '../../../../core/services/storage.service';
var LocaleModalComponent = /** @class */ (function () {
    function LocaleModalComponent(store, browserService, storageService) {
        this.store = store;
        this.browserService = browserService;
        this.storageService = storageService;
        this.modalEventListener$ = new BehaviorSubject(ModalEvent.CLOSE);
        this.locales = Locale.SUPPORTED_LOCALES;
    }
    LocaleModalComponent.prototype.ngOnInit = function () {
        if (this.browserService.isPlatformBrowser()) {
            var lang = void 0;
            if (Config.IS_WEB()) {
                lang = this.browserService.getCookie(StorageKey.LANG);
            }
            else {
                lang = this.storageService.getItem(StorageKey.LOCALE);
            }
            var locale = Locale.getLocaleByShortcut(lang);
            if (!locale) {
                this.modalEventListener$.next(ModalEvent.OPEN);
            }
        }
    };
    LocaleModalComponent.prototype.selectLocale = function (loc) {
        this.modalEventListener$.next(ModalEvent.CLOSE);
        this.store.dispatch(new localeActions.UpdateLocaleAction(loc));
    };
    return LocaleModalComponent;
}());
export { LocaleModalComponent };
