import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as fromRoot from '../../store';
var DirDirective = /** @class */ (function () {
    function DirDirective(elRef, renderer, store) {
        var _this = this;
        this.elRef = elRef;
        this.renderer = renderer;
        this.store = store;
        this.dir = 'auto';
        this.store.pipe(select(fromRoot.getLocale), filter(function (loc) { return loc != null; })).subscribe(function (locale) {
            if (locale.rtl) {
                _this.dir = 'rtl';
            }
            else {
                _this.dir = 'auto';
            }
        });
    }
    DirDirective.prototype.ngOnInit = function () {
        this.renderer.setAttribute(this.elRef.nativeElement, 'dir', this.dir);
    };
    return DirDirective;
}());
export { DirDirective };
