import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ModalEvent, Notification, ReviewDto, ReviewReportDto} from '../../../../core/models';
import {ErrorUtils} from '../../../../core/utils/error.util';

import * as fromRoot from '../../../../store';
import * as reportReviewActions from '../../../../core/store/actions/review.report.action';

@Component({
    selector: 'app-report-review-modal',
    templateUrl: './review-report-modal.component.html',
    styleUrls: ['./review-report-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportReviewModalComponent implements OnInit {

    reviewToReport$: Observable<ReviewDto>;
    modalEvent$: Observable<ModalEvent>;
    loading$: Observable<boolean>;
    isReported$: Observable<boolean>;
    notification$: Observable<Notification>;

    initListener$ = new Subject();

    constructor(private store: Store<fromRoot.State>) {
        this.reviewToReport$ = this.store.pipe(select(fromRoot.getReviewToReport));
        this.modalEvent$ = this.store.pipe(select(fromRoot.getReportReviewModalEvent));
        this.loading$ = this.store.pipe(select(fromRoot.isReportReviewLoading));
        this.isReported$ = this.store.pipe(select(fromRoot.isReviewReported));
        this.notification$ = this.store.pipe(
            select(fromRoot.getReportReviewErrors),
            map(ErrorUtils.toNotification)
        );
    }

    ngOnInit() {
        this.isReported$.pipe(filter(reported => reported))
            .subscribe(() => {
                this.closeModal()
            });
    }

    cancel() {
        this.store.dispatch(new reportReviewActions.ResetAction());
        this.closeModal();
    }

    report(reviewReport: ReviewReportDto) {
        this.store.dispatch(new reportReviewActions.ReportReviewAction(reviewReport));
    }

    closeModal() {
        this.store.dispatch(new reportReviewActions.CloseModalAction());
    }

}
