var UIBudgetType = /** @class */ (function () {
    function UIBudgetType(type, text, mediumText, smallText, arText) {
        this.type = type;
        this.text = text;
        this.mediumText = mediumText;
        this.smallText = smallText;
        this.arText = arText;
    }
    return UIBudgetType;
}());
export { UIBudgetType };
