<div class="activity-chooser" [ngClass]="classes" yfLayoutClass>

    <div class="row hidden-md-up">
        <div class="col-sm-12 center">
            <a class="back-btn a-link" (click)="doGoBack()">
                <span class="text-underline" *ngIf="showActivities" translate>global.back</span>
                <span class="text-underline" *ngIf="showCategories" translate>global.close</span>
            </a>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12 col-md-6 yf-categories" *ngIf="showCategories">

            <span class="yf-section-header mb-1" translate>global.categories</span>

            <ul>
                <li *ngFor="let cat of categories"
                    (mouseover)="mouseOverCategory(cat)"
                    (click)="onSelectCategory(cat)"
                    [ngClass]="{'category row align-items-center mb-1 mx-0 p-1': true, 'selected': isCategorySelected(cat)}"
                    class="category row align-items-center mb-1 mx-0 p-1">
                    <span class="col pl-0">{{cat | categoryTranslator}}</span>
                    <i class="fa fa-chevron-right right" aria-hidden="true"></i>
                </li>
            </ul>
        </div>

        <div class="col-sm-12 col-md-6 yf-services" *ngIf="showActivities" (mouseover)="mouseOverActivities()">

            <span class="yf-section-header mb-1" *ngIf="activities.length" translate>global.services</span>

            <ul>
                <li *ngFor="let act of activities"
                    (click)="onSelectActivity(act)"
                    class="activity mb-1 p-1">
                    <i aria-hidden="true"
                       [ngClass]="['fa mr-1', isActivitySelected(act) ? 'fa-check-circle': 'fa-circle-thin']">
                    </i>
                    <span>{{act | activityTranslator}}</span>
                </li>
            </ul>
        </div>
    </div>

</div>