import {Action} from '@ngrx/store';

export enum ActivityChooserActionTypes {
    OpenActivityChooserModal = '[ACTIVITY CHOOSER MODAL] OPEN_ACTIVITY_CHOOSER_MODAL',
    CloseActivityChooserModal = '[ACTIVITY CHOOSER MODAL] CLOSE_ACTIVITY_CHOOSER_MODAL'
}

export class OpenActivityChooserModalAction implements Action {
    readonly type = ActivityChooserActionTypes.OpenActivityChooserModal;
}

export class CloseActivityChooserModalAction implements Action {
    readonly type = ActivityChooserActionTypes.CloseActivityChooserModal;
}

export type Actions = OpenActivityChooserModalAction
    | CloseActivityChooserModalAction;
