import {Directive, HostListener, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {ReviewDto} from '../../core/models';

import * as fromRoot from '../../store';
import {reportReviewActions} from '../../core/store/actions';

@Directive({
    selector: '[appReportReview]'
})
export class ReportReviewDirective {

    @Input() appReportReview: ReviewDto;

    constructor(private store: Store<fromRoot.State>) {
    }

    @HostListener('click')
    report() {
        this.store.dispatch(new reportReviewActions.OpenModalAction(this.appReportReview));
    }

}
