<div class="row categories mb-0">

    <div *ngFor="let cat of categories" [ngClass]="colClass">
        <div class="category yf-section-header">{{cat | categoryTranslator}}</div>

        <ul>
            <li *ngFor="let act of cat.activities">
                <i aria-hidden="true" class="fa fa-check-circle mr-1"></i>
                <span>{{act | activityTranslator}}</span>
        </ul>
    </div>

</div>