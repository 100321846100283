import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { emailVerificationActions, routeActions } from '../../modules/core/store/actions';
import { EmailVerificationDto } from '../../modules/core/models';
import { SeoService } from '../../modules/core/services';
import * as fromRoot from '../../modules/store';
var EmailVerificationComponent = /** @class */ (function () {
    function EmailVerificationComponent(store, route, seo) {
        this.store = store;
        this.route = route;
        this.seo = seo;
        this.subscription = new Subscription();
        this.notification$ = this.store.pipe(select(fromRoot.getEmailVerificationNotification));
        this.loading$ = this.store.pipe(select(fromRoot.isEmailVerificationLoading));
        this.emailVerified$ = this.store.pipe(select(fromRoot.isEmailVerified));
        seo.setMeta('email-verification');
        seo.setRobots(false);
    }
    EmailVerificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            var emailVerification = new EmailVerificationDto();
            emailVerification.userUid = params.uid;
            emailVerification.token = params.token;
            _this.store.dispatch(new emailVerificationActions.VerifyEmailAction(emailVerification));
        });
        var verifiedSub = this.emailVerified$.pipe(filter(function (a) { return a; }))
            .subscribe(function () { return setTimeout(function () { return _this.store.dispatch(new routeActions.ToSignInAction()); }, 3000); });
        this.subscription.add(verifiedSub);
    };
    EmailVerificationComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new emailVerificationActions.ResetAction());
        this.subscription.unsubscribe();
    };
    return EmailVerificationComponent;
}());
export { EmailVerificationComponent };
