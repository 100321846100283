import {localeActions} from '../actions';
import {Locale} from '../../models';

export interface State {
    locale: Locale;
}

export const initialState: State = {
    locale: null
};

export function reducer(state = initialState, action: localeActions.Actions): State {

    switch (action.type) {

        case localeActions.LocaleActionsTypes.SetLocale: {
            return Object.assign(
                {},
                state,
                {
                    locale: action.payload
                }
            )
        }

        default: {
            return state;
        }
    }
}

export const getLocale = (state: State): Locale => state.locale;
