import {Injectable} from '@angular/core';
import {Notification} from '../../../modules/core/models';

@Injectable()
export class SignUpHelper {

    alertNotification(notif: Notification) {
    }

}
