import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';
import {routeActions} from '../actions';
import {NoticeType} from '../../models';
import {ParamsService, RouteService} from '../../services';

@Injectable()
export class RouteEffects {

    @Effect({dispatch: false})
    toHome = this.actions$.pipe(
        ofType(routeActions.RouteActionTypes.ToHome),
        tap(() => {
            this.routeService.toHome();
        }));

    @Effect({dispatch: false})
    toUrl = this.actions$.pipe(
        ofType<routeActions.ToUrlAction>(routeActions.RouteActionTypes.ToUrl),
        map(action => action.payload),
        tap(url => {
            this.routeService.toUrl(url);
        })
    );

    @Effect({dispatch: false})
    toUserProfile = this.actions$.pipe(
        ofType(routeActions.RouteActionTypes.ToUserProfile),
        tap(() => {
            this.routeService.toUserProfilePage();
        }));

    @Effect({dispatch: false})
    toWorker = this.actions$.pipe(
        ofType<routeActions.ToWorkerAction>(routeActions.RouteActionTypes.ToWorker),
        map(action => action.payload),
        tap(uid => {
            this.routeService.toWorker(uid);
        })
    );

    @Effect({dispatch: false})
    toCompleteUserProfile = this.actions$.pipe(
        ofType(routeActions.RouteActionTypes.ToCompleteUserProfile),
        tap(() => {
            this.routeService.toCompleteUserProfile();
        }));

    @Effect({dispatch: false})
    toSignIn = this.actions$.pipe(
        ofType(routeActions.RouteActionTypes.ToSignIn),
        tap(() => {
            this.routeService.toSignInPage();
        }));

    @Effect({dispatch: false})
    toConfirmRegistration = this.actions$.pipe(
        ofType<routeActions.ToConfirmRegistrationAction>(routeActions.RouteActionTypes.ToConfirmRegistration),
        map(action => action.payload),
        tap((payload) => {
            let navigationExtras = {
                queryParams: this.paramsService.buildRegistrationConfirmationParams(payload)
            };

            this.routeService.toEmailConfirmation(navigationExtras);
        })
    );

    @Effect({dispatch: false})
    toPasswordForgotten = this.actions$.pipe(
        ofType<routeActions.ToPasswordForgotten>(routeActions.RouteActionTypes.ToPasswordForgotten),
        map(action => action.payload),
        tap((email) => {
            let navigationExtras;

            if (email) {
                navigationExtras = {
                    queryParams: this.paramsService.buildPasswordForgottenParams(email)
                };
            }

            this.routeService.toPasswordResetPage(navigationExtras);
        })
    );

    @Effect({dispatch: false})
    toRequest = this.actions$.pipe(
        ofType<routeActions.ToRequestAction>(routeActions.RouteActionTypes.ToRequest),
        map(action => action.payload),
        tap(requestId => {
            this.routeService.toRequest(requestId);
        })
    );

    @Effect({dispatch: false})
    toUpdateRequest = this.actions$.pipe(
        ofType<routeActions.ToUpdateRequestAction>(routeActions.RouteActionTypes.ToUpdateRequest),
        map(action => action.payload),
        tap(requestId => {
            this.routeService.toUpdateRequest(requestId);
        })
    );

    @Effect({dispatch: false})
    toRequests = this.actions$.pipe(
        ofType(routeActions.RouteActionTypes.ToRequests),
        tap(() => {
            this.routeService.toRequests();
        })
    );

    @Effect({dispatch: false})
    toNotFound = this.actions$.pipe(
        ofType(routeActions.RouteActionTypes.ToPageNotFound),
        tap(() => {
            this.routeService.toNotFound();
        }));

    @Effect({dispatch: false})
    toForbidden = this.actions$.pipe(
        ofType(routeActions.RouteActionTypes.ToForbidden),
        tap(() => {
            this.routeService.toForbidden();
        }));

    @Effect({dispatch: false})
    toNoticeTarget = this.actions$.pipe(
        ofType(routeActions.RouteActionTypes.ToNoticeTarget),
        map((action: routeActions.ToNoticeTargetAction) => action.payload),
        tap(notice => {
            switch (notice.type) {
                case NoticeType.MESSAGE:
                    this.routeService.toConversations();
                    break;
                case NoticeType.VERIFICATIONS:
                    this.routeService.toProfileVerificationsPage();
                    break;
                case NoticeType.PROFILE:
                    this.routeService.toUserProfilePage();
                    break;
            }

        }));

    constructor(private actions$: Actions,
                private paramsService: ParamsService,
                private routeService: RouteService) {

    }
}
