<div class="row justify-content-center" *ngIf="user" yfLayoutClass>
    <div class="col-md-8">
        <form (ngSubmit)="reportUser(reportForm)" #reportForm="ngForm" materialize>

            <app-notification [notification]="notification"></app-notification>

            <div class="row">
                <div class="col-12 center">
                    <span translate>reports.users.you-want-to-report</span>
                    <div class="chip chip-bordered">
                        <img [appUserImg]="user" alt="Utilisateur">
                        {{user.firstName}} {{user.lastName}}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12" translate>reports.please-signal-reasons</div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="input-field">
                        <textarea type="text" name="message" id="message" #messageInput
                                  [(ngModel)]="_userReport.message"
                                  [placeholder]="'reports.reason-placeholder' | translate"
                                  class="materialize-textarea"
                                  required>
                        </textarea>
                        <label for="message" translate>reports.reason</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 center">
                    <button type="button" class="btn btn-cancel mb-3 mb-md-0 mr-md-1 w-100-sm-down"
                            (click)="cancel.emit()" translate>global.cancel
                    </button>

                    <button class="btn btn-default w-100-sm-down"
                            [disabled]="loading$ | async" translate>global.report
                    </button>
                </div>
            </div>

        </form>
    </div>
</div>