/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./control-messages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./control-messages.component";
var styles_ControlMessagesComponent = [i0.styles];
var RenderType_ControlMessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ControlMessagesComponent, data: {} });
export { RenderType_ControlMessagesComponent as RenderType_ControlMessagesComponent };
function View_ControlMessagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "label", [["class", "invalid"]], [[1, "for", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.forLabel; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.context.$implicit)); _ck(_v, 1, 0, currVal_1); }); }
function View_ControlMessagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "input-error"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlMessagesComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ControlMessagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ControlMessagesComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ControlMessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-control-messages", [], null, null, null, View_ControlMessagesComponent_0, RenderType_ControlMessagesComponent)), i1.ɵdid(1, 49152, null, 0, i4.ControlMessagesComponent, [], null, null)], null, null); }
var ControlMessagesComponentNgFactory = i1.ɵccf("app-control-messages", i4.ControlMessagesComponent, View_ControlMessagesComponent_Host_0, { control: "control", controlName: "controlName", forLabel: "forLabel", keyPrefix: "keyPrefix", config: "config" }, {}, []);
export { ControlMessagesComponentNgFactory as ControlMessagesComponentNgFactory };
