export var AccountPwdChangeModalActionTypes;
(function (AccountPwdChangeModalActionTypes) {
    AccountPwdChangeModalActionTypes["OpenModal"] = "[PASSWORD CHANGE MODAL] OPEN_MODAL";
    AccountPwdChangeModalActionTypes["CloseModal"] = "[PASSWORD CHANGE MODAL] CLOSE_MODAL";
})(AccountPwdChangeModalActionTypes || (AccountPwdChangeModalActionTypes = {}));
export var OPEN_MODAL = '[PASSWORD CHANGE MODAL] OPEN_MODAL';
export var CLOSE_MODAL = '[PASSWORD CHANGE MODAL] CLOSE_MODAL';
var OpenModalAction = /** @class */ (function () {
    function OpenModalAction() {
        this.type = AccountPwdChangeModalActionTypes.OpenModal;
    }
    return OpenModalAction;
}());
export { OpenModalAction };
var CloseModalAction = /** @class */ (function () {
    function CloseModalAction() {
        this.type = AccountPwdChangeModalActionTypes.CloseModal;
    }
    return CloseModalAction;
}());
export { CloseModalAction };
