<form class="w-search center" materialize #searchForm="ngForm" yfLayoutClass>

    <div class="row justify-content-center">

        <div class="col-md-4 sbox service-chooser-box" (click)="openActivityChooser()">
            <div class="col-1">
                <i class="material-icons">build</i>
            </div>
            <div class="col-11">
                <input type="text" name="service" class="mb-0"
                       [placeholder]="'searchSimple.service.placeholder' | translate"
                       [ngModel]="selectedActivityName"
                       [class.has-error]="hasError && !searchCriteria?.activity"
                       #service="ngModel"
                       readonly
                       required>
            </div>
        </div>

        <div class="col-md-4 sbox" (click)="focusAddress()">

            <div class="col-1">
                <i class="material-icons">place</i>
            </div>
            <div class="col-11">
                <input type="text" name="address" class="address-search mb-0"
                       [placeholder]="'searchSimple.address.placeholder' | translate" #searchInput
                       [ngModel]="searchCriteria?.address?.formatted"
                       [class.has-error]="hasError && !searchCriteria?.address"
                       (selectAddress)="selectAddress($event)"
                       (focusout)="hasAddressError()"
                       (ngModelChange)="addChange($event)"
                       appAddressAutoComplete
                       required>
                <i class="clear-address material-icons" title="supprimer" (click)="selectAddress(null)" *ngIf="searchCriteria?.address?.formatted">clear</i>
            </div>
        </div>

        <div class="col-md-2 px-0 sbox-submit" (click)="submit(searchForm)">
            <input type="button" class="search-submit w-100" [value]="'searchSimple.submit' | translate"/>
        </div>
    </div>
</form>

<app-activity-chooser-manager [openEventListener$]="activityChooserListener$"
                              (activitySelected)="selectActivity($event)">
</app-activity-chooser-manager>
