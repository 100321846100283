import {Action} from '@ngrx/store';
import {BasicUserDto, ErrorsDto, UserReportDto} from '../../../core/models';

export enum UserReportActionTypes {
    ReportUser = '[REPORT USER] REPORT_USER',
    ReportUserSuccess = '[REPORT USER] REPORT_USER_SUCCESS',
    ReportUserFail = '[REPORT USER] REPORT_USER_FAIL',

    OpenModal = '[REPORT USER] OPEN_MODAL',
    CloseModal = '[REPORT USER] CLOSE_MODAL',

    SetUser = '[REPORT USER] SET_USER',
    Reset = '[REPORT USER] RESET',
}

export class ReportUserAction implements Action {
    readonly type = UserReportActionTypes.ReportUser;

    constructor(public payload: UserReportDto) {
    }
}

export class ReportUserSuccessAction implements Action {
    readonly type = UserReportActionTypes.ReportUserSuccess;
}

export class ReportUserFailAction implements Action {
    readonly type = UserReportActionTypes.ReportUserFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class OpenModalAction implements Action {
    readonly type = UserReportActionTypes.OpenModal;

    constructor(public payload: BasicUserDto) {
    }
}

export class CloseModalAction implements Action {
    readonly type = UserReportActionTypes.CloseModal;
}

export class SetUserAction implements Action {
    readonly type = UserReportActionTypes.SetUser;

    constructor(public payload: BasicUserDto) {
    }
}

export class ResetAction implements Action {
    readonly type = UserReportActionTypes.Reset;
}

export type Actions = ReportUserAction
    | ReportUserSuccessAction
    | ReportUserFailAction
    | OpenModalAction
    | CloseModalAction
    | SetUserAction
    | ResetAction
    ;
