import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import * as passwordResetActions from '../../modules/core/store/actions/password.reset.action';
import {Notification} from '../../modules/core/models/index';
import {SeoService} from '../../modules/core/services';
import {ErrorUtils} from '../../modules/core/utils/error.util';
import {BcValidators} from '../../modules/core/validators/bc.validators';
import {PasswordResetActionDto} from '../../modules/core/models/password-reset-action.dto';
import {PasswordTokenValidationDto} from '../../modules/core/models/password-token-validation.dto';
import * as fromRoot from '../../modules/store';

@Component({
    moduleId: module.id,
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {
    passwordResetLoading$: Observable<boolean>;
    passwordResetNotification$: Observable<Notification>;
    isPasswordReseted$: Observable<boolean>;

    isTokenValid$: Observable<boolean>;
    tokenValidationLoading$: Observable<boolean>;
    tokenValidationNotification$: Observable<Notification>;

    passwordReset: FormGroup;

    private email: string;
    private token: string;

    constructor(private store: Store<fromRoot.State>,
                private route: ActivatedRoute,
                private fb: FormBuilder,
                private seo: SeoService) {
        this.passwordReset = fb.group({
            newPassword: ['', Validators.compose([Validators.required, BcValidators.password])],
            newPasswordConfirmation: ['', Validators.compose([Validators.required, BcValidators.password])]
        });

        this.passwordResetLoading$ = this.store.pipe(select(fromRoot.isPasswordResetLoading));
        this.passwordResetNotification$ = this.store.pipe(select(fromRoot.getPasswordResetNotification));
        this.isPasswordReseted$ = this.store.pipe(select(fromRoot.isPasswordReseted));

        this.isTokenValid$ = this.store.pipe(select(fromRoot.isPasswordResetTokenValid));
        this.tokenValidationLoading$ = this.store.pipe(select(fromRoot.isPasswordResetTokenValidationLoading));
        this.tokenValidationNotification$ = this.store.pipe(
            select(fromRoot.getResetPasswordTokenValidationErrors),
            map(ErrorUtils.toNotification)
        );

        seo.setMeta('password-reset');
        seo.setRobots(true);
    }

    ngOnInit() {
        this.route.params.subscribe((params: { email: string, token: string }) => {
            this.email = params.email;
            this.token = params.token;

            let passwordTokenValidationDto = PasswordTokenValidationDto.build(this.email, this.token);
            this.store.dispatch(new passwordResetActions.ValidateResetPasswordTokenAction(passwordTokenValidationDto));
        });
    }

    ngOnDestroy() {
        this.store.dispatch(new passwordResetActions.ResetStateAction());
    }

    submit(form: FormGroup) {
        if (form.invalid) {
            return
        }

        this.resetPassword();
    }

    resetPassword() {
        let passwordResetActionDto = PasswordResetActionDto.build(this.email, this.token, this.passwordReset.value);
        this.store.dispatch(new passwordResetActions.ResetPasswordAction(passwordResetActionDto));
    }

    get newPassword() {
        return this.passwordReset.get('newPassword');
    }
}
