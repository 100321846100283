<div class="add-review" *ngIf="author; else noAuthenticated" yfLayoutClass>
    <form materialize (ngSubmit)="submit(reviewForm)" #reviewForm="ngForm">
        <div class="row">
            <div class="col-12" translate>reviews.how-was-experience</div>
        </div>

        <div class="row">
            <div class="col-12">
                <i [ngClass]="{'review-rate left fa fa-3x': true, 'fa-star': i <= review.rating, 'fa-star-o': i> review.rating}"
                   class="fa fa-star" aria-hidden="true" *ngFor="let i of [0,1,2,3,4]"
                   [title]="'review.' + (i+1) | translate" (click)="setReviewRating(i)"></i>
            </div>

            <div class="col-12 mt-2" *ngIf="review.rating != -1">
                <span translate>review.{{review.rating}}</span>
            </div>
        </div>

        <div class="row">
            <div class="col-12" translate>reviews.your-review-is-important
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="input-field">
                    <textarea type="text" name="review" id="review"
                              [(ngModel)]="review.text"
                              [placeholder]="'reviews.placeholder' | translate"
                              class="materialize-textarea">
                    </textarea>
                    <label for="review" translate>global.review</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col center">
                <button type="button" class="btn btn-flat" (click)="cancel.emit()" translate>global.cancel
                </button>
                <button class="btn btn-primary" translate>global.add-review</button>
            </div>
        </div>

    </form>
</div>

<ng-template #noAuthenticated>
    Vous devez vous connecter pour laisser un avis !
</ng-template>
