import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import * as signUpActions from '../../../modules/core/store/actions/signup.action';
import { BrowserService } from '../../../modules/core/services';
import { ParamsService } from '../../../modules/core/services/params.service';
import { ErrorUtils } from '../../../modules/core/utils/error.util';
import * as fromRoot from '../../../modules/store/';
var SignUpConfirmComponent = /** @class */ (function () {
    function SignUpConfirmComponent(store, paramsService, route, browserService) {
        this.store = store;
        this.paramsService = paramsService;
        this.route = route;
        this.browserService = browserService;
        this.param = {};
        this.titleKey = 'sign-up.confirm.welcome-title';
        this.notification$ = this.store.pipe(select(fromRoot.getSignUpEmailActivationResentSuccessNotification), withLatestFrom(this.store.pipe(select(fromRoot.getSignUpEmailActivationResentErrors), map(ErrorUtils.toNotification)), function (success, failure) {
            if (success) {
                return success;
            }
            return failure;
        }));
        this.loading$ = this.store.pipe(select(fromRoot.isSignUpEmailActivationResentLoading));
    }
    SignUpConfirmComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            var registrationConfirmationParams = _this.paramsService.buildRegistrationConfirmationFromParams(params);
            _this.email = registrationConfirmationParams.email;
            _this.param = { email: _this.email };
            _this.postSignUp = registrationConfirmationParams.postSignUp;
            if (_this.postSignUp) {
                _this.titleKey = 'sign-up.confirm.activation-title';
            }
        });
        this.browserService.materialize();
    };
    SignUpConfirmComponent.prototype.sendEmailConfirmation = function () {
        this.store.dispatch(new signUpActions.SignUpConfirmationResentEmailAction(this.email));
    };
    return SignUpConfirmComponent;
}());
export { SignUpConfirmComponent };
