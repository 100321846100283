var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { profileServicesChooserActions } from '../../../account/store/actions';
import { CommonUtils } from '../../utils';
import * as fromRoot from '../../../store';
import { categoryActions, searchActivityChooserActions } from '../actions';
import { CategoryService } from '../../services/category.service';
var CategoryEffects = /** @class */ (function () {
    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     return of(new categoryActions.LoadCategoriesAction());
    // });
    function CategoryEffects(actions$, categoryService, store$) {
        var _this = this;
        this.actions$ = actions$;
        this.categoryService = categoryService;
        this.store$ = store$;
        this.loadCategories = this.actions$.pipe(ofType(categoryActions.CategoryActionTypes.LoadCategories), mergeMap(function () {
            return _this.categoryService.getCategories().pipe(map(function (categories) { return new categoryActions.LoadCategoriesSuccessAction(categories); }), catchError(function (errors) { return of(new categoryActions.LoadCategoriesFailAction(errors)); }));
        }));
        this.loadCategoriesIfNull = this.actions$.pipe(ofType(categoryActions.CategoryActionTypes.LoadCategoriesIfNull), withLatestFrom(this.store$.pipe(select(fromRoot.getCategories))), mergeMap(function (_a) {
            var action = _a[0], categories = _a[1];
            if (CommonUtils.isEmpty(categories)) {
                return [new categoryActions.LoadCategoriesAction()];
            }
            return [];
        }));
        this.selectSearchCategory = this.actions$.pipe(ofType(searchActivityChooserActions.SearchActivityChooserActionTypes.SelectCategory), map(function (action) { return action.payload; }), map(function (category) { return new searchActivityChooserActions.SetActivitiesAction(category.activities); }));
        this.selectUserCategory = this.actions$.pipe(ofType(profileServicesChooserActions.ProfileServicesChooserActionTypes.SelectCategory), map(function (action) { return action.payload; }), map(function (category) { return new profileServicesChooserActions.SetActivitiesAction(category.activities); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CategoryEffects.prototype, "loadCategories", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CategoryEffects.prototype, "loadCategoriesIfNull", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CategoryEffects.prototype, "selectSearchCategory", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], CategoryEffects.prototype, "selectUserCategory", void 0);
    return CategoryEffects;
}());
export { CategoryEffects };
