import { UserProvider, UserType, VerificationStatus } from '../models';
import { CommonUtils } from './common.util';
import { VerificationUtils } from './verification.util';
var UserProfileUtils = /** @class */ (function () {
    function UserProfileUtils() {
    }
    UserProfileUtils.calculatePercentage = function (userView) {
        if (!userView) {
            return 0;
        }
        var ignore = ['uid', 'filled', 'birthDay', 'reviewsAverage', 'worker', 'verification'];
        var ignoreVerif = ['phoneVerified', 'profileVerified'];
        var keys = Object.keys(userView).filter(function (k) { return ignore.indexOf(k) === -1; });
        var verifKeys = Object.keys(userView.verification).filter(function (k) { return ignoreVerif.indexOf(k) === -1; });
        var length = keys.length + verifKeys.length;
        var count = length;
        keys.forEach(function (key) {
            if (!userView[key]) {
                count--;
            }
        });
        verifKeys.forEach(function (key) {
            if (!userView.verification[key]) {
                count--;
            }
        });
        var precision = (count / length * 100).toPrecision(2);
        return parseFloat(precision);
    };
    UserProfileUtils.hasUserType = function (userProfile, type) {
        return userProfile && UserProfileUtils.hasProfileType(userProfile.profile, type);
    };
    UserProfileUtils.isUserProfileComplete = function (userProfile) {
        if (!userProfile) {
            return false;
        }
        return UserProfileUtils.isProfileComplete(userProfile.profile);
    };
    UserProfileUtils.isProfileComplete = function (profile) {
        if (!profile.firstName) {
            return false;
        }
        if (!profile.lastName) {
            return false;
        }
        return true;
    };
    UserProfileUtils.isUserProfileVerified = function (userProfile) {
        if (!userProfile) {
            return false;
        }
        var profile = userProfile.profile;
        if (!profile) {
            return false;
        }
        var verification = profile.verification;
        if (!VerificationUtils.isStatusApproved(verification.idCardStatus)) {
            return false;
        }
        if (!VerificationUtils.isStatusApproved(verification.workerCardStatus)) {
            return false;
        }
        // if (verification.phoneStatus === VerificationStatus.APPROVED) {
        //     return false;
        // }
        return VerificationUtils.isStatusApproved(verification.emailStatus);
    };
    UserProfileUtils.isNotVerified = function (status) {
        return status && (status === VerificationStatus.NONE || status === VerificationStatus.REJECTED);
    };
    UserProfileUtils.isProfessionalUserProfile = function (userProfile) {
        return UserProfileUtils.hasUserType(userProfile, UserType.PROFESSIONAL);
    };
    UserProfileUtils.isBasicUserProfile = function (userProfile) {
        return UserProfileUtils.hasUserType(userProfile, UserType.BASIC);
    };
    UserProfileUtils.hasProfileType = function (profile, type) {
        return profile && profile.type === type;
    };
    UserProfileUtils.isProfessionalProfile = function (profile) {
        return UserProfileUtils.hasProfileType(profile, UserType.PROFESSIONAL);
    };
    UserProfileUtils.isBasicProfile = function (profile) {
        return UserProfileUtils.hasProfileType(profile, UserType.BASIC);
    };
    UserProfileUtils.hasUserProvider = function (userProfile, userProvider) {
        return userProfile && userProfile.userProvider === userProvider;
    };
    UserProfileUtils.isLocalUser = function (userProfile) {
        return UserProfileUtils.hasUserProvider(userProfile, UserProvider.LOCAL);
    };
    UserProfileUtils.isSocialUser = function (userProfile) {
        return UserProfileUtils.hasUserProvider(userProfile, UserProvider.SOCIAL);
    };
    UserProfileUtils.isEligibleForSearch = function (userProfile) {
        if (!userProfile || !userProfile.profile) {
            return false;
        }
        var activities = userProfile.profile.activities;
        var address = userProfile.profile.address;
        return userProfile.profile.worker && CommonUtils.notEmpty(activities) && activities.length && CommonUtils.notEmpty(address);
    };
    UserProfileUtils.isWorkerValid = function (userProfile) {
        if (!userProfile || !userProfile.profile) {
            return false;
        }
        if (!userProfile.profile.worker) {
            return true;
        }
        return CommonUtils.notEmpty(userProfile.profile.address) && CommonUtils.notEmpty(userProfile.profile.activities);
    };
    UserProfileUtils.isProfileVerified = function (verification) {
        if (!verification) {
            return false;
        }
        return UserProfileUtils.isVerificationApproved(verification.profileStatus);
    };
    UserProfileUtils.isVerificationApproved = function (status) {
        return UserProfileUtils.hasVerificationStatus(status, VerificationStatus.APPROVED);
    };
    UserProfileUtils.isVerificationPending = function (status) {
        return UserProfileUtils.hasVerificationStatus(status, VerificationStatus.PENDING);
    };
    UserProfileUtils.isVerificationRejected = function (status) {
        return UserProfileUtils.hasVerificationStatus(status, VerificationStatus.REJECTED);
    };
    UserProfileUtils.isVerificationNone = function (status) {
        return UserProfileUtils.hasVerificationStatus(status, VerificationStatus.NONE);
    };
    UserProfileUtils.hasVerificationStatus = function (status, expectedStatus) {
        return status === expectedStatus;
    };
    return UserProfileUtils;
}());
export { UserProfileUtils };
