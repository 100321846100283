import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

import {emailVerificationActions, routeActions} from '../../modules/core/store/actions';
import {EmailVerificationDto, Notification} from '../../modules/core/models';
import {SeoService} from '../../modules/core/services';
import * as fromRoot from '../../modules/store';

@Component({
    moduleId: module.id,
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit, OnDestroy {

    private subscription = new Subscription();

    notification$: Observable<Notification> = this.store.pipe(select(fromRoot.getEmailVerificationNotification));
    loading$: Observable<boolean> = this.store.pipe(select(fromRoot.isEmailVerificationLoading));
    emailVerified$: Observable<boolean> = this.store.pipe(select(fromRoot.isEmailVerified));

    constructor(private store: Store<fromRoot.State>,
                private route: ActivatedRoute,
                private seo: SeoService) {
        seo.setMeta('email-verification');
        seo.setRobots(false);
    }

    ngOnInit() {
        this.route.params.subscribe((params: { uid: string, token: string }) => {
            let emailVerification = new EmailVerificationDto();
            emailVerification.userUid = params.uid;
            emailVerification.token = params.token;

            this.store.dispatch(new emailVerificationActions.VerifyEmailAction(emailVerification));
        });

        let verifiedSub = this.emailVerified$.pipe(filter(a => a))
            .subscribe(() => setTimeout(() => this.store.dispatch(new routeActions.ToSignInAction()), 3000));

        this.subscription.add(verifiedSub);
    }

    ngOnDestroy() {
        this.store.dispatch(new emailVerificationActions.ResetAction());
        this.subscription.unsubscribe();
    }
}
