import {ActivityDto, CategoryDto} from '../../models/rest-api.model';
import {CategoryUtils} from '../../utils/category.util';
import {Subject} from 'rxjs';

import {activityChooserManagerActions} from '../actions';

export interface State {
    categories: CategoryDto[],
    activities: ActivityDto[],
    selectedCategories: CategoryDto[],
    selectedActivities: ActivityDto[],
    initListener: Subject<any>
}

export const initialState: State = {
    categories: [],
    activities: [],
    selectedCategories: [],
    selectedActivities: [],
    initListener: new Subject()
};

export function reducer(state = initialState, action: activityChooserManagerActions.Actions): State {

    switch (action.type) {

        case activityChooserManagerActions.ActivityChooserManagerActionTypes.SetCategories: {
            return Object.assign(
                {},
                state,
                {categories: action.payload});
        }

        case activityChooserManagerActions.ActivityChooserManagerActionTypes.SetActivities: {
            return Object.assign(
                {},
                state,
                {activities: action.payload});
        }

        case activityChooserManagerActions.ActivityChooserManagerActionTypes.SelectActivity: {
            const newState = Object.assign({}, state);
            newState.selectedActivities.push(action.payload);

            return newState;
        }

        case activityChooserManagerActions.ActivityChooserManagerActionTypes.SelectCategory: {
            const newState = Object.assign({}, state);
            newState.selectedCategories.push(action.payload);

            return newState;
        }

        case activityChooserManagerActions.ActivityChooserManagerActionTypes.SetSelectedActivities: {
            return Object.assign(
                {},
                state,
                {
                    selectedActivities: action.payload,
                    selectedCategories: CategoryUtils.getCategoriesOfActivities(action.payload)
                });
        }

        case activityChooserManagerActions.ActivityChooserManagerActionTypes.SetSelectedCategories: {
            return Object.assign(
                {},
                state,
                {selectedCategories: action.payload});
        }

        case activityChooserManagerActions.ActivityChooserManagerActionTypes.SelectOrDeselectActivity: {

            let found = state.selectedActivities.find(a => a.id === action.payload.id);

            let newSelectedActivities;
            if (found) {
                newSelectedActivities = state.selectedActivities.filter(a => a.id !== action.payload.id);
            } else {
                newSelectedActivities = [...state.selectedActivities, action.payload];
            }

            const newState = Object.assign(
                {},
                state,
                {
                    selectedActivities: newSelectedActivities,
                    selectedCategories: CategoryUtils.getCategoriesOfActivities(newSelectedActivities)
                }
            );

            return newState;
        }

        case activityChooserManagerActions.ActivityChooserManagerActionTypes.Init: {
            state.initListener.next();
            return state;
        }

        case activityChooserManagerActions.ActivityChooserManagerActionTypes.Reset: {
            return Object.assign(
                {},
                state,
                initialState);
        }

        default: {
            return state;
        }
    }
}

// Selectors
export const getCategories = (state: State): CategoryDto[] => state.categories;
export const getActivities = (state: State): ActivityDto[] => state.activities;
export const getSelectedActivities = (state: State): ActivityDto[] => state.selectedActivities;
export const getSelectedCategories = (state: State): CategoryDto[] => state.selectedCategories;
