export interface EnvConfig {
    API?: string;
    ENV?: string;
    RADIUS: number;
}

export interface IPlatforms {
    WEB: string;
    MOBILE_NATIVE: string;
    MOBILE_HYBRID: string;
    DESKTOP: string;
}

export class Config {

    static PLACES_COUNTRY_RESTRICTIONS = []; // ['ma', 'EH'];
    static ALLOWED_COUNTRIES = ['AE', 'BH', 'DZ', 'EG', 'IQ', 'IR', 'JO', 'KW', 'LB', 'LY', 'MA', 'OM', 'PK', 'PS', 'QA', 'SA', 'SD', 'SN', 'SS', 'SY', 'TN', 'YE'];
    static PREFERRED_COUNTRIES = ['MA'];
    static INITIAL_COUNTRY = 'MA';
    static INITIAL_DIAL_CODE = '+212';

    public static DEBUG = {
        LEVEL_1: false, // .info only
        LEVEL_2: false, // .warn only
        LEVEL_3: false, // .error only
        LEVEL_4: false  // .log + all the above
    };

    // supported platforms
    public static PLATFORMS: IPlatforms = {
        WEB: 'web',
        MOBILE_NATIVE: 'mobile_native',
        MOBILE_HYBRID: 'mobile_hybrid',
        DESKTOP: 'desktop'
    };

    // current target (defaults to web)
    public static PLATFORM_TARGET: string = Config.PLATFORMS.WEB;

    // convenient platform checks
    public static IS_WEB(): boolean {
        return Config.PLATFORM_TARGET === Config.PLATFORMS.WEB;
    }

    public static IS_MOBILE_NATIVE(): boolean {
        return Config.PLATFORM_TARGET === Config.PLATFORMS.MOBILE_NATIVE;
    }

    public static IS_MOBILE_HYBRID(): boolean {
        return Config.PLATFORM_TARGET === Config.PLATFORMS.MOBILE_HYBRID;
    }

    public static IS_DESKTOP(): boolean {
        return Config.PLATFORM_TARGET === Config.PLATFORMS.DESKTOP;
    }

    public static ENVIRONMENT(): EnvConfig {
        return {
            API: 'http://10.0.2.2:8081',
            RADIUS: 100
        }
    }

    // supported languages
    public static GET_SUPPORTED_LANGUAGES() {
        return [
            {code: 'en', title: 'English'},
            {code: 'es', title: 'Spanish'},
            {code: 'fr', title: 'Français'},
            {code: 'ru', title: 'Russian'},
            {code: 'bg', title: 'Bulgarian'}
        ];
    }

    public static IS_DEBUG_MODE(): boolean {
        for (let key in Config.DEBUG) {
            if (Config.DEBUG[key]) {
                // if any level is on, debug mode is on
                return true;
            }
        }
        return false;
    }

    // reset debug defaults
    public static RESET() {
        for (let key in Config.DEBUG) {
            Config.DEBUG[key] = false;
        }
    }
}
