import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';
import {SeoService} from '../../../modules/core/services';

@Component({
    moduleId: module.id,
    selector: 'yf-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent {
    appVersion: string;

    constructor(private seo: SeoService) {
        this.appVersion = environment.version;
        seo.setMeta('about');
        seo.setRobots(true);
    }
}
