<div class="row justify-content-center" *ngIf="sender; else noSender" yfLayoutClass>
    <div class="col-md-8">
        <form (ngSubmit)="doSubmit()" #messageForm="ngForm" materialize>

            <app-notification [notification]="notification"></app-notification>

            <div class="row">
                <div class="col-12 center">
                    <span translate>send-message.send-to</span>
                    <div class="chip chip-bordered">
                        <img [appUserImg]="receiver" alt="Utilisateur">
                        {{receiver.firstName}} {{receiver.lastName}}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="input-field">
                        <textarea type="text" name="message" id="message" #messageInput
                                  [(ngModel)]="sendMessage.message"
                                  [placeholder]="'send-message.placeholder' | translate"
                                  class="materialize-textarea"
                                  required>
                        </textarea>
                        <label for="message" translate>global.message</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 center">
                    <button type="button"
                            [disabled]="loading$ | async"
                            class="btn btn-cancel mb-2 mb-md-0 mr-md-1 w-100-sm-down"
                            (click)="doCancel()" translate>global.cancel
                    </button>

                    <button class="btn btn-default w-100-sm-down"
                            [disabled]="messageForm.invalid || (loading$ | async)" translate>global.send
                    </button>
                </div>
            </div>

        </form>
    </div>
</div>

<ng-template #noSender>

    <div class="row justify-content-center">

        <div class="col-8">
            <span>Connectez-vous pour envoyer un message à {{receiver?.firstName}}</span>
        </div>

        <div class="col-8">
            <button type="button" class="btn btn-default" routerLink="/login">
                Se connecter
            </button>
        </div>

    </div>

</ng-template>
