var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injector } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Config } from '../../../../config';
import { userProfileActions } from '../../../account/store/actions';
import * as fromRoot from '../../../store';
import { authenticationActions, routeActions } from '../actions';
import { UserProvider } from '../../models';
import { AuthService, BrowserService, NoticeService, RouteService } from '../../services';
import { ErrorUtils } from '../../utils';
var AuthenticationEffects = /** @class */ (function () {
    // @Effect()
    // init$ = defer(() => {
    //     if (this.authService.isAuthenticated()) {
    //         if (this.browserService.isPlatformBrowser()) {
    //             if (this.authService.isAuthenticated()) {
    //
    //                 let user = this.authService.getUser();
    //
    //                 if (!user.enabled) {
    //                     this.authService.getCurrent().subscribe(userAccount => {
    //                         this.authService.updateUserAccount(userAccount);
    //                         this.store.dispatch(new authenticationActions.SetUserAction(userAccount));
    //                     });
    //                 } else {
    //                     this.store.dispatch(new authenticationActions.SetUserAction(user));
    //                 }
    //                 if (!user.enabled) {
    //                     this.authService.getCurrent().subscribe(userAccount => {
    //                         this.authService.updateUserAccount(userAccount);
    //                         this.store.dispatch(new authenticationActions.SetUserAction(userAccount));
    //                     });
    //                 } else {
    //                     this.store.dispatch(new authenticationActions.SetUserAction(user));
    //                 }
    //
    //                 this.authService.getCurrentUserProfile(user.uid).subscribe(up => {
    //                     this.noticeService.calculateUserProfileNotices(up);
    //                     this.store.dispatch(new authenticationActions.LoadUserProfileSuccessAction(up))
    //                 });
    //                 this.authService.getCurrentUserProfile(user.uid).subscribe(up => {
    //                     this.noticeService.calculateUserProfileNotices(up);
    //                     this.store.dispatch(new authenticationActions.LoadUserProfileSuccessAction(up))
    //                 });
    //
    //             } else {
    //                 this.authService.clearSession();
    //             } else {
    //                 this.authService.clearSession();
    //             }
    //         }
    //
    //         return of({type: 'NO_ACTION'});
    //     });
    function AuthenticationEffects(actions$, translateService, store, injector, noticeService, browserService, routeService, authService) {
        var _this = this;
        this.actions$ = actions$;
        this.translateService = translateService;
        this.store = store;
        this.injector = injector;
        this.noticeService = noticeService;
        this.browserService = browserService;
        this.routeService = routeService;
        this.authService = authService;
        this.authenticate = this.actions$.pipe(ofType(authenticationActions.AuthenticationActionTypes.Authenticate), map(function (action) { return action.payload; }), mergeMap(function (userLoginForm) {
            return _this.authService.authenticate(userLoginForm).pipe(mergeMap(function (session) {
                return [new authenticationActions.AuthenticateSuccessAction(session.user)];
            }), catchError(function (errors) {
                if (!errors) {
                    errors = ErrorUtils.buildErrorsDto(_this.translateService.instant('errors.credentials.incorrect'));
                }
                return of(new authenticationActions.AuthenticateFailureAction(errors));
            }));
        }));
        this.authenticateSuccess = this.actions$.pipe(ofType(authenticationActions.AuthenticationActionTypes.AuthenticateSuccess), map(function (action) { return action.payload; }), mergeMap(function (user) {
            var postLoginAct;
            if (user.filled) {
                var redirectUrl = _this.routeService.redirectUrl;
                if (redirectUrl) {
                    postLoginAct = new routeActions.ToUrlAction(redirectUrl);
                    _this.routeService.redirectUrl = null;
                }
                else {
                    if (user.userProvider === UserProvider.SOCIAL) {
                        postLoginAct = new routeActions.ToCompleteUserProfileAction();
                    }
                    else {
                        postLoginAct = new routeActions.ToUserProfileAction();
                    }
                }
            }
            else {
                if (Config.IS_MOBILE_NATIVE()) {
                    postLoginAct = new routeActions.ToUserProfileAction();
                }
                else {
                    postLoginAct = new routeActions.ToCompleteUserProfileAction();
                }
            }
            return [postLoginAct];
        }), catchError(function (errors) {
            if (!errors) {
                errors = ErrorUtils.buildErrorsDto(_this.translateService.instant('errors.credentials.incorrect'));
            }
            return of(new authenticationActions.AuthenticateFailureAction(errors));
        }));
        this.testSession = this.actions$.pipe(ofType(authenticationActions.AuthenticationActionTypes.TestUser), mergeMap(function () {
            return _this.authService.getCurrent().pipe(map(function (userAccount) { return new authenticationActions.SetUserAction(userAccount); }), catchError(function (e) { return of(new authenticationActions.SetLoaded()); }));
        }));
        this.socialAuthenticate = this.actions$.pipe(ofType(authenticationActions.AuthenticationActionTypes.SocialAuthenticate), map(function (action) { return action.payload; }), mergeMap(function (token) {
            return _this.authService.socialAuthentication(token).pipe(mergeMap(function (session) {
                var postLoginAct = new routeActions.ToUserProfileAction();
                // let postLoginAct = session.user.filled ? new routeActions.ToUserProfileAction() : new routeActions.ToCompleteUserProfileAction();
                // TODO
                // if (Config.IS_MOBILE_NATIVE()) {
                //     postLoginAct = new routeActions.ToUserProfileAction();
                // }
                return [
                    new authenticationActions.AuthenticateSuccessAction(session.user),
                    postLoginAct
                ];
            }), catchError(function (errors) {
                if (!errors) {
                    errors = ErrorUtils.buildErrorsDto(_this.translateService.instant('errors.global'));
                }
                return of(new authenticationActions.AuthenticateFailureAction(errors));
            }));
        }));
        this.logout = this.actions$.pipe(ofType(authenticationActions.AuthenticationActionTypes.Logout), switchMap(function () {
            if (Config.IS_MOBILE_NATIVE()) {
                _this.authService.clearSession();
                return [
                    new authenticationActions.ResetAuthenticationAction(),
                    new routeActions.ToHomeAction()
                ];
            }
            else {
                return _this.authService.logout().pipe(mergeMap(function () {
                    _this.noticeService.calculateUserProfileNotices(null);
                    return [
                        new authenticationActions.ResetAuthenticationAction(),
                        new routeActions.ToHomeAction()
                    ];
                }));
            }
        }));
        this.setUserProfilePicture = this.actions$.pipe(ofType(userProfileActions.AccountProfileActionTypes.SetPicture), map(function (action) { return action.payload; }), map(function (picture) { return new authenticationActions.SetPictureAction(picture); }));
        this.setUserProfilePictureAfterPatch = this.actions$.pipe(ofType(userProfileActions.AccountProfileActionTypes.UpdateUserProfileSuccess), map(function (action) { return action.payload; }), map(function (userProfile) { return new authenticationActions.SetPictureAction(userProfile.profile.picture); }));
        this.setUserProfilePictureAfterLoadingProfile = this.actions$.pipe(ofType(userProfileActions.AccountProfileActionTypes.LoadUserProfileSuccess), map(function (action) { return action.payload; }), filter(function (userProfile) { return userProfile.profile != null; }), map(function (userProfile) { return new authenticationActions.SetPictureAction(userProfile.profile.picture); }));
        this.countUnreadConversations = this.actions$.pipe(ofType(authenticationActions.AuthenticationActionTypes.CountUnreadConversations), withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUser))), filter(function (userProfile) { return userProfile != null; }), switchMap(function (_a) {
            var action = _a[0], currentUser = _a[1];
            return _this.authService.countUnreadConversations(currentUser.uid).pipe(map(function (count) {
                _this.noticeService.calculateNewMessage(count);
                return new authenticationActions.CountUnreadConversationsSuccessAction(count);
            }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "authenticate", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "authenticateSuccess", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "testSession", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "socialAuthenticate", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "logout", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "setUserProfilePicture", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "setUserProfilePictureAfterPatch", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "setUserProfilePictureAfterLoadingProfile", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AuthenticationEffects.prototype, "countUnreadConversations", void 0);
    return AuthenticationEffects;
}());
export { AuthenticationEffects };
