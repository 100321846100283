import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-promotion-services',
    templateUrl: './promotion-services.component.html',
    styleUrls: ['./promotion-services.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionServicesComponent {

}
