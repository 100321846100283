import {Directive, ElementRef, Inject, Input, Optional} from '@angular/core';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {environment} from '../../../../environments/environment';
import {BasicUserDto, Gender} from '../../../core/models';

@Directive({
    selector: '[appUserImg]',
    host: {
        '(error)': 'updateUrl()'
    }
})
export class UserImgDirective {

    @Input() gender: Gender;
    @Input() defaultImage = 'assets/images/default-user.jpg';

    private _userImg: BasicUserDto | string;

    constructor(private el: ElementRef,
                @Optional() @Inject(REQUEST) protected request: any) {
    }

    @Input()
    set appUserImg(input: BasicUserDto | string) {

        this._userImg = input;

        let img = this.defaultImage;

        let picture;

        if (this.instanceOfBasicUserDto(input)) {
            picture = input.picture;
            this.gender = input.gender;
        } else {
            picture = input;
        }

        if (picture) {
            let apiUrl = environment.apiUrl;

            if (this.request) {
                apiUrl = environment.backendUrl;
            }

            img = apiUrl + 'medias/' + picture;
        } else {
            if (this.gender) {
                switch (this.gender) {
                    case Gender.WOMAN: {
                        img = 'assets/images/user_f.png';
                        break;
                    }
                }
            }
        }

        this.el.nativeElement.src = img;
    }

    updateUrl() {

        let img = this.defaultImage;

        if (this.instanceOfBasicUserDto(this._userImg)) {
            if (this.gender) {
                switch (this.gender) {
                    case Gender.WOMAN: {
                        img = 'assets/images/user_f.png';
                        break;
                    }
                }
            }
        }

        this.el.nativeElement.src = img;
    }

    private instanceOfBasicUserDto(object: any): object is BasicUserDto {
        return object && object.picture !== undefined;
    }

    private getRequestProtocol() {
        let protocol = 'https';

        let host = this.request.get('host');
        if (host && host.startsWith('localhost')) {
            protocol = 'http';
        }

        // if (this.request && this.request.headers) {
        //     protocol = this.request.protocol;
        //
        //     let proto = this.request.headers['X-Forwarded-Proto'];
        //
        //     if (proto) {
        //         protocol = proto;
        //     }
        // }

        return protocol;
    }
}
