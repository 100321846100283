<div *ngIf="average == 0; else hasReviews">
    <div class="col-12 px-0" [title]="'global.no-review' | translate">
        <i class="fa fa-star-o" aria-hidden="true" *ngFor="let i of [0,1,2,3,4]" style="font-size: 1.2rem; color: #bbbbbb;"></i>
    </div>
</div>
<ng-template #hasReviews>
    <div class="col-12 px-0" [title]="average + 1 + '/5'">
        <i [ngClass]="getStarClass(i)" class="fa review-star" aria-hidden="true" *ngFor="let i of [0,1,2,3,4]"></i>
    </div>
</ng-template>
