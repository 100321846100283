import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter} from 'rxjs/operators';

import * as fromRoot from '../../store';

@Directive({
    selector: '[yfDir]'
})
export class DirDirective implements OnInit {

    dir = 'auto';

    constructor(private elRef: ElementRef,
                private renderer: Renderer2,
                private store: Store<fromRoot.State>) {
        this.store.pipe(
            select(fromRoot.getLocale),
            filter(loc => loc != null)
        ).subscribe(locale => {
            if (locale.rtl) {
                this.dir = 'rtl';
            } else {
                this.dir = 'auto';
            }

        });

    }

    ngOnInit(): void {
        this.renderer.setAttribute(this.elRef.nativeElement, 'dir', this.dir);
    }

}
