<div class="container-fluid sub-header" yfLayoutClass>
    <div class="container">
        <h1 class="white-text h4 center-sm-down" translate>footer.about.title</h1>
    </div>
</div>

<div class="container" yfLayoutClass>
    <div class="card">
        <div class="card-block">

            <div class="section-header">
                <span translate>footer.about.who</span>
            </div>

            <p translate>who-are-we.body</p>

            <!--<div class="section-header">-->
            <!--<span>Nos engagements</span>-->
            <!--</div>-->

            <!--<p>-->

            <!--</p>-->
        </div>
    </div>
</div>

