import * as i0 from "@angular/core";
var ServerStorageService = /** @class */ (function () {
    function ServerStorageService() {
    }
    ServerStorageService.prototype.setItem = function (key, value) {
    };
    ServerStorageService.prototype.getItem = function (key) {
    };
    ServerStorageService.prototype.removeItem = function (key) {
    };
    ServerStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServerStorageService_Factory() { return new ServerStorageService(); }, token: ServerStorageService, providedIn: "root" });
    return ServerStorageService;
}());
export { ServerStorageService };
