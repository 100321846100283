import { Store } from '@ngrx/store';
import { BasicUserDto } from '../../core/models/rest-api.model';
import { reportUserActions } from '../store/actions';
var ReportUserDirective = /** @class */ (function () {
    function ReportUserDirective(store) {
        this.store = store;
    }
    ReportUserDirective.prototype.report = function () {
        this.store.dispatch(new reportUserActions.OpenModalAction(this.appReportUser));
    };
    return ReportUserDirective;
}());
export { ReportUserDirective };
