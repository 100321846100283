import { OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { localeActions } from '../../../core/store/actions';
import { Locale } from '../../../core/models';
import { CommonUtils } from '../../../core/utils';
import * as fromRoot from '../../../store';
var LocaleSelectorComponent = /** @class */ (function () {
    function LocaleSelectorComponent(store) {
        this.store = store;
        this.currentLocale = Locale.FRENCH;
        this.locales = Locale.SUPPORTED_LOCALES;
    }
    LocaleSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.pipe(select(fromRoot.getLocale), filter(function (lc) { return CommonUtils.notEmpty(lc); })).subscribe(function (loc) { return _this.currentLocale = loc; });
    };
    LocaleSelectorComponent.prototype.selectLocale = function (locale) {
        this.store.dispatch(new localeActions.UpdateLocaleAction(locale));
    };
    return LocaleSelectorComponent;
}());
export { LocaleSelectorComponent };
