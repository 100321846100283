export var ReviewActionTypes;
(function (ReviewActionTypes) {
    ReviewActionTypes["LoadReviewRatings"] = "[REVIEW] LOAD_REVIEW_RATINGS";
    ReviewActionTypes["LoadReviewRatingsSuccess"] = "[REVIEW] LOAD_REVIEW_RATINGS_SUCCESS";
    ReviewActionTypes["LoadReviewRatingsFail"] = "[REVIEW] LOAD_REVIEW_RATINGS_FAIL";
    ReviewActionTypes["AddReview"] = "[REVIEW] ADD_REVIEW";
    ReviewActionTypes["AddReviewSuccess"] = "[REVIEW] ADD_REVIEW_SUCCESS";
    ReviewActionTypes["AddReviewFail"] = "[REVIEW] ADD_REVIEW_FAIL";
    ReviewActionTypes["LoadUserReviews"] = "[REVIEW] LOAD_USER_REVIEWS";
    ReviewActionTypes["LoadUserReviewsSuccess"] = "[REVIEW] LOAD_USER_REVIEWS_SUCCESS";
    ReviewActionTypes["LoadUserReviewsFail"] = "[REVIEW] LOAD_USER_REVIEWS_FAIL";
    ReviewActionTypes["SetUser"] = "[REVIEW] SET_USER";
    ReviewActionTypes["ResetIsReviewAdded"] = "[REVIEW] RESET_IS_REVIEW_ADDED";
})(ReviewActionTypes || (ReviewActionTypes = {}));
var ResetIsReviewAddedAction = /** @class */ (function () {
    function ResetIsReviewAddedAction() {
        this.type = ReviewActionTypes.ResetIsReviewAdded;
    }
    return ResetIsReviewAddedAction;
}());
export { ResetIsReviewAddedAction };
var LoadReviewRatingsAction = /** @class */ (function () {
    function LoadReviewRatingsAction() {
        this.type = ReviewActionTypes.LoadReviewRatings;
    }
    return LoadReviewRatingsAction;
}());
export { LoadReviewRatingsAction };
var LoadReviewRatingsSuccessAction = /** @class */ (function () {
    function LoadReviewRatingsSuccessAction(payload) {
        this.payload = payload;
        this.type = ReviewActionTypes.LoadReviewRatingsSuccess;
    }
    return LoadReviewRatingsSuccessAction;
}());
export { LoadReviewRatingsSuccessAction };
var LoadReviewRatingsFailAction = /** @class */ (function () {
    function LoadReviewRatingsFailAction(payload) {
        this.payload = payload;
        this.type = ReviewActionTypes.LoadReviewRatingsFail;
    }
    return LoadReviewRatingsFailAction;
}());
export { LoadReviewRatingsFailAction };
var AddReviewAction = /** @class */ (function () {
    function AddReviewAction(payload) {
        this.payload = payload;
        this.type = ReviewActionTypes.AddReview;
    }
    return AddReviewAction;
}());
export { AddReviewAction };
var AddReviewSuccessAction = /** @class */ (function () {
    function AddReviewSuccessAction() {
        this.type = ReviewActionTypes.AddReviewSuccess;
    }
    return AddReviewSuccessAction;
}());
export { AddReviewSuccessAction };
var AddReviewFailAction = /** @class */ (function () {
    function AddReviewFailAction(payload) {
        this.payload = payload;
        this.type = ReviewActionTypes.AddReviewFail;
    }
    return AddReviewFailAction;
}());
export { AddReviewFailAction };
var LoadUserReviewsAction = /** @class */ (function () {
    function LoadUserReviewsAction(payload) {
        this.payload = payload;
        this.type = ReviewActionTypes.LoadUserReviews;
    }
    return LoadUserReviewsAction;
}());
export { LoadUserReviewsAction };
var LoadUserReviewsSuccessAction = /** @class */ (function () {
    function LoadUserReviewsSuccessAction(payload) {
        this.payload = payload;
        this.type = ReviewActionTypes.LoadUserReviewsSuccess;
    }
    return LoadUserReviewsSuccessAction;
}());
export { LoadUserReviewsSuccessAction };
var LoadUserReviewsFailAction = /** @class */ (function () {
    function LoadUserReviewsFailAction(payload) {
        this.payload = payload;
        this.type = ReviewActionTypes.LoadUserReviewsFail;
    }
    return LoadUserReviewsFailAction;
}());
export { LoadUserReviewsFailAction };
var SetUserAction = /** @class */ (function () {
    function SetUserAction(payload) {
        this.payload = payload;
        this.type = ReviewActionTypes.SetUser;
    }
    return SetUserAction;
}());
export { SetUserAction };
