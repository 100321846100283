import {Action} from '@ngrx/store';
import {BaseUserDto, ErrorsDto, SendMessageDto} from '../../models';

export enum SendMessageActionTypes {
    SendMessage = '[MESSAGE] SEND_MESSAGE',
    SendMessageSuccess = '[MESSAGE] SEND_MESSAGE_SUCCESS',
    SendMessageFail = '[MESSAGE] SEND_MESSAGE_FAIL',

    SetSender = '[MESSAGE] SET_SENDER',
    SetReceiver = '[MESSAGE] SET_RECEIVER',

    SetSent = '[MESSAGE] SET_SENT',
    Reset = '[MESSAGE] RESET'
}

/**
 * Send message.
 */
export class SendMessageAction implements Action {
    readonly type = SendMessageActionTypes.SendMessage;

    constructor(public payload: SendMessageDto) {
    }
}

export class SendMessageSuccessAction implements Action {
    readonly type = SendMessageActionTypes.SendMessageSuccess;
}

export class SendMessageFailAction implements Action {
    readonly type = SendMessageActionTypes.SendMessageFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class SetSenderAction implements Action {
    readonly type = SendMessageActionTypes.SetSender;

    constructor(public payload: BaseUserDto) {
    }
}

export class SetReceiverAction implements Action {
    readonly type = SendMessageActionTypes.SetReceiver;

    constructor(public payload: BaseUserDto) {
    }
}

export class SetSentAction implements Action {
    readonly type = SendMessageActionTypes.SetSent;

    constructor(public payload: boolean) {
    }
}

export class ResetAction implements Action {
    readonly type = SendMessageActionTypes.Reset;
}

export type Actions = SendMessageAction
    | SendMessageSuccessAction
    | SendMessageFailAction
    | SetSenderAction
    | SetReceiverAction
    | SetSentAction
    | ResetAction
    ;
