<div class="row align-items-center" *ngIf="(loading$ | async); else loaded" style="height: 30vh">
    <div class="col-12 center">
        <app-spinner></app-spinner>
    </div>
</div>

<ng-template #loaded>

    <div class="row" *ngIf="(notification$ | async); else noError" yfLayoutClass>
        <div class="col-12" translate>errors.global</div>
    </div>

    <ng-template #noError>

        <div *ngIf="userProfile as user">

            <div class="container-fluid worker-header" yfLayoutClass>

                <div class="ads-ctr-left mt-2">
                    <div class="row">
                        <div class="col-12">
                            <ng-adsense></ng-adsense>
                        </div>

                        <div class="col-12 mt-2">
                            <ng-adsense></ng-adsense>
                        </div>
                    </div>
                </div>
                <div class="ads-ctr-right mt-2">
                    <div class="row">
                        <div class="col-12">
                            <ng-adsense></ng-adsense>
                        </div>

                        <div class="col-12 mt-2">
                            <ng-adsense></ng-adsense>
                        </div>
                    </div>
                </div>

                <div class="container">

                    <div class="row">
                        <div class="col-10">
                            <app-go-back></app-go-back>
                        </div>

                        <div class="col-2">
                            <a materialize="dropdown" class='dropdown-button right'
                               data-constrainWidth="false" data-belowOrigin="true"
                               [attr.data-activates]="'upview-dd-'+ user?.id">
                                <i class="material-icons action" title="Actions">more_vert</i>
                            </a>

                            <ul [id]="'upview-dd-'+ user?.id" class='dropdown-content'>
                                <li>
                                    <a [appShare]="user" [title]="'global.share-profile' | translate" translate>
                                        global.share
                                    </a>
                                </li>
                                <li>
                                    <a [appReportUser]="user" [title]="'global.report-user' | translate" translate>
                                        global.report
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row align-items-center">
                        <div class="col-md-2 center">
                            <img [appUserImg]="user"
                                 class="img-thumbnail img-fluid img-responsive rounded-circle worker-picture">
                        </div>

                        <div class="col-md-10 mt-2 mt-md-0">
                            <h2 class="col-12 w-pvw-name white-text my-0 center-sm-down">
                                <span class="text-capitalize">{{ user.firstName }}</span> {{user.lastName | uppercase}}
                            </h2>

                            <!--<div class="col-12 center-sm-down" *ngIf="user.birthDay">-->
                            <!--<span class="font-italic">{{ user.birthDay | age}} ans</span>-->
                            <!--</div>-->
                            <div class="col-12 mt-4 center-sm-down">

                                <button *ngIf="user?.showPhone"
                                        class="btn-floating btn-primary btn-phone waves-effect waves-light"
                                        [appShowPhone]="user">
                                    <i class="material-icons primary-color"
                                       [title]="'global.show-phone' | translate"
                                       aria-hidden="true">local_phone</i>
                                </button>
                                <button class="btn-floating btn-primary btn-phone waves-effect waves-light ml-2"
                                        [appSendMessage]="user">
                                    <i class="material-icons primary-color" [title]="'global.send-message' | translate"
                                       aria-hidden="true">mail</i>
                                </button>
                                <button class="btn-floating btn-primary btn-phone waves-effect waves-light ml-2"
                                        [appShare]="user">
                                    <i class="material-icons primary-color" [title]="'global.share-profile' | translate"
                                       aria-hidden="true">share</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container w-pvw-cards sub-page" yfLayoutClass>
                <div class="row">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-header" translate>
                                <i class="material-icons">check</i>global.verifications
                            </div>

                            <div class="card-block">
                                <app-worker-verification [verification]="user.verification"
                                                         [detailed]="true"></app-worker-verification>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header" translate>
                                <i class="material-icons">star</i>global.note
                            </div>

                            <div class="card-block center">
                                <div *ngIf="user.reviewsAverage > 0; else noReview">
                                    <span style="vertical-align: middle"><span
                                            style="font-size: 2.5rem">{{user.reviewsAverage + 1}}</span> / 5</span>
                                    <app-user-reviews-average
                                            [average]="user.reviewsAverage"></app-user-reviews-average>
                                </div>
                                <ng-template #noReview>
                                    <span translate>global.no-review</span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-header" translate>
                                <i class="material-icons">description</i>profile-view.about-me
                            </div>

                            <div class="card-block">
                        <span *ngIf="user.description; else noDescription">
                           {{user.description}}
                         </span>
                                <ng-template #noDescription>
                                    <span translate>profile-view.description-empty</span>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header" translate>
                                <i class="material-icons">work</i>global.experience
                            </div>

                            <div class="card-block center">
                                <span *ngIf="user.experience; else noExperience">
                                   {{user.experience}} <span translate>global.years</span>
                                 </span>
                                <ng-template #noExperience>
                                    <span translate>profile-view.experience-not-filled</span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" translate>
                        <i class="material-icons">build</i>profile-view.my-services
                    </div>

                    <div class="card-block">
                        <app-activity-list [activities]="user.activities"></app-activity-list>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" translate>
                        <i class="material-icons">view_comfy</i>profile-view.my-achievements
                    </div>

                    <div class="card-block">
                        <app-gallery [medias]="user.medias"></app-gallery>
                    </div>
                </div>

                <div class="card address">
                    <div class="card-header" translate>
                        <i class="material-icons">place</i>profile-view.my-address
                    </div>

                    <div class="card-block center">

                        <h4 class="w-address">{{ user?.address?.formatted }}</h4>

                        <div class="sebm-google-map-container"
                             *ngIf="user.address?.location as location">
                            <agm-map [latitude]="location.latitude"
                                     [longitude]="location.longitude"
                                     [zoom]="13">

                                <agm-marker
                                        [latitude]="location.latitude"
                                        [longitude]="location.longitude">
                                </agm-marker>
                            </agm-map>
                        </div>
                    </div>

                </div>

                <app-review-manager [reviews]="reviews$ | async"
                                    [targetUser]="user"
                                    [loading$]="loading$"
                                    [notification]="userReviewsNotification$ | async"
                                    (loadReviews)="loadUserReviews($event)">
                </app-review-manager>

            </div>

            <div class="row">
                <div class="col-12 center">
                    <ng-adsense></ng-adsense>
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>
