import { OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ErrorUtils } from '../../../../core/utils/error.util';
import * as fromRoot from '../../../../store';
import * as reportReviewActions from '../../../../core/store/actions/review.report.action';
var ReportReviewModalComponent = /** @class */ (function () {
    function ReportReviewModalComponent(store) {
        this.store = store;
        this.initListener$ = new Subject();
        this.reviewToReport$ = this.store.pipe(select(fromRoot.getReviewToReport));
        this.modalEvent$ = this.store.pipe(select(fromRoot.getReportReviewModalEvent));
        this.loading$ = this.store.pipe(select(fromRoot.isReportReviewLoading));
        this.isReported$ = this.store.pipe(select(fromRoot.isReviewReported));
        this.notification$ = this.store.pipe(select(fromRoot.getReportReviewErrors), map(ErrorUtils.toNotification));
    }
    ReportReviewModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isReported$.pipe(filter(function (reported) { return reported; }))
            .subscribe(function () {
            _this.closeModal();
        });
    };
    ReportReviewModalComponent.prototype.cancel = function () {
        this.store.dispatch(new reportReviewActions.ResetAction());
        this.closeModal();
    };
    ReportReviewModalComponent.prototype.report = function (reviewReport) {
        this.store.dispatch(new reportReviewActions.ReportReviewAction(reviewReport));
    };
    ReportReviewModalComponent.prototype.closeModal = function () {
        this.store.dispatch(new reportReviewActions.CloseModalAction());
    };
    return ReportReviewModalComponent;
}());
export { ReportReviewModalComponent };
