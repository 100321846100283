import {activateAccountActions} from '../actions';
import {Notification} from '../../models';

export interface State {
    loading: boolean,
    notification: Notification,
    activated: boolean;
}

export const initialState: State = {
    loading: false,
    notification: null,
    activated: false
};

export function reducer(state = initialState, action: activateAccountActions.Actions): State {

    switch (action.type) {

        case activateAccountActions.AccountActivationActionTypes.Activate: {
            return {
                loading: true,
                notification: null,
                activated: false
            }
        }

        case activateAccountActions.AccountActivationActionTypes.ActivateSuccess: {
            return {
                loading: false,
                notification: null,
                activated: true
            }
        }

        case activateAccountActions.AccountActivationActionTypes.ActivateFail: {
            return {
                loading: false,
                notification: action.payload,
                activated: false
            }
        }

        case activateAccountActions.AccountActivationActionTypes.Reset: {
            return Object.assign(
                {},
                state,
                initialState
            )
        }

        default: {
            return state;
        }

    }
}

export const isLoading = (state: State): boolean => state.loading;
export const isActivated = (state: State): boolean => state.activated;
export const getNotification = (state: State): Notification => state.notification;
