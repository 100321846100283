<div class="container-fluid sub-header" yfLayoutClass>
    <div class="container">
        <h1 class="white-text h4" translate>global.email-verification</h1>
    </div>
</div>

<div class="container mt-4" yfLayoutClass>

    <div class="card">

        <div class="card-header center">
            <span class="text-white" translate>global.email-verification</span>
        </div>

        <div class="card-block center">
            <app-spinner *ngIf="loading$ | async"></app-spinner>

            <app-notification [notification]="notification$ | async"></app-notification>

            <div *ngIf="emailVerified$ | async">
                <span translate>account-activation.redirect</span>
            </div>
        </div>

    </div>

</div>

