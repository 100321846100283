import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

import {activateAccountActions, routeActions} from '../../modules/core/store/actions';
import {AccountActivationDto, Notification} from '../../modules/core/models';
import {SeoService} from '../../modules/core/services';
import * as fromRoot from '../../modules/store';

@Component({
    moduleId: module.id,
    selector: 'app-account-activation',
    templateUrl: './account-activation.component.html',
    styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit, OnDestroy {

    private subscription = new Subscription();

    notification$: Observable<Notification> = this.store.pipe(select(fromRoot.getAccountActivationNotification));
    loading$: Observable<boolean> = this.store.pipe(select(fromRoot.isAccountActivationLoading));
    accountActivated$: Observable<boolean> = this.store.pipe(select(fromRoot.isAccountActivated));

    constructor(private store: Store<fromRoot.State>,
                private route: ActivatedRoute,
                private seo: SeoService) {
        seo.setMeta('account-activation');
        seo.setRobots(false);
    }

    ngOnInit() {
        this.route.params.subscribe((params: { uid: string, token: string }) => {
            let accountActivation = new AccountActivationDto();
            accountActivation.userUid = params.uid;
            accountActivation.token = params.token;

            this.store.dispatch(new activateAccountActions.ActivateAction(accountActivation));
        });

        let activatedSub = this.accountActivated$
            .pipe(filter(a => a))
            .subscribe(() => setTimeout(() => this.store.dispatch(new routeActions.ToSignInAction()), 3000));

        this.subscription.add(activatedSub);
    }


    ngOnDestroy() {
        this.store.dispatch(new activateAccountActions.ResetAction());
        this.subscription.unsubscribe();
    }
}
