import * as i0 from "@angular/core";
var AnalyticsWebService = /** @class */ (function () {
    function AnalyticsWebService() {
    }
    AnalyticsWebService.prototype.pageTrack = function (path) {
        if (typeof ga !== 'undefined' && ga) {
            // dataLayer.push({
            //     'event': 'pageview',
            //     'url': path
            // });
            // ga('send', 'pageview', path);
            ga.getAll().forEach(function (a) { return a.send('pageview', path); });
        }
    };
    AnalyticsWebService.prototype.eventTrack = function (action, properties) {
        // dataLayer.push({
        //         'service': 'pageview',
        //         'url': path
        //     });
        // dataLayer.push(Object.assign({ event: properties.event || 'interaction', target: properties.category || 'Event', action: action, label: properties.label, value: properties.value, interactionType: properties.noninteraction }, properties.gtmCustom));
    };
    AnalyticsWebService.prototype.trackService = function (serviceName) {
        // window.dataLayer.push({'formLocation': 'footer', 'event': 'selectService'});
    };
    AnalyticsWebService.prototype.exceptionTrack = function (properties) {
    };
    AnalyticsWebService.prototype.setUsername = function (userId) {
    };
    AnalyticsWebService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsWebService_Factory() { return new AnalyticsWebService(); }, token: AnalyticsWebService, providedIn: "root" });
    return AnalyticsWebService;
}());
export { AnalyticsWebService };
