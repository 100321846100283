import { MessageReceivingStatus } from '../models';
var ConversationUtils = /** @class */ (function () {
    function ConversationUtils() {
    }
    ConversationUtils.hasConversationUnreadMessages = function (conversation) {
        return ConversationUtils.hasConversationMessagesStatus(conversation, MessageReceivingStatus.UNREAD);
    };
    ConversationUtils.hasConversationMessagesStatus = function (conversation, receivingStatus) {
        if (!conversation) {
            return false;
        }
        return ConversationUtils.hasMessagesReceivingStatus(conversation.messages, receivingStatus);
    };
    ConversationUtils.hasMessagesReceivingStatus = function (messages, receivingStatus) {
        if (!messages) {
            return false;
        }
        return messages.find(function (m) { return !m.sender && m.receivingStatus === receivingStatus; }) != null;
    };
    return ConversationUtils;
}());
export { ConversationUtils };
