// angular
import {Injectable} from '@angular/core';
// module
import {IStorage, StorageKey} from '../models';
import {BrowserService} from './browser.service';

@Injectable({
    providedIn: 'root'
})
export class StorageService implements IStorage {

    constructor(private browserService: BrowserService) {

    }

    setItem(key: StorageKey, value: any): void {
        if (this.browserService.isPlatformBrowser()) {
            localStorage.setItem('' + key, value === null ? null : JSON.stringify(value));
        }
    }

    getItem(key: StorageKey): any {
        let value = null;

        if (this.browserService.isPlatformBrowser()) {
            value = localStorage.getItem('' + key);
        }

        return value === null ? null : JSON.parse(value);
    }

    removeItem(key: StorageKey): void {
        if (this.browserService.isPlatformBrowser()) {
            localStorage.removeItem('' + key);
        }
    }

}
