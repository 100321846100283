import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {ActivityDto, Gender, ISeoService, UserViewDto} from '../models';
import {BcUtilsService} from './bc-utils.service';
import {BrowserService} from './browser.service';
import {OpenGraphService} from './open-graph.service';

@Injectable({
    providedIn: 'root'
})
export class SeoService implements ISeoService {

    constructor(private translateService: TranslateService,
                private ogService: OpenGraphService,
                private utilsService: BcUtilsService,
                private browserService: BrowserService,
                private meta: Meta,
                private title: Title) {
    }

    setMeta(id: string, titleInterpolateParams?: Object, descInterpolateParams?: Object) {
        if (this.browserService.isPlatformServer()) {
            let title = this.translateService.instant(`seo.${id}.title`, titleInterpolateParams);
            this.setTitle(title);

            let description = this.translateService.instant(`seo.${id}.description`, descInterpolateParams);
            this.setDescription(description);

            let keywords = this.translateService.instant(`seo.${id}.keywords`, descInterpolateParams);

            if (keywords !== `seo.${id}.keywords`) {
                this.setKeywords(keywords);
            }
        }
    }

    setUserViewMeta(profile: UserViewDto) {
        if (this.browserService.isPlatformServer()) {
            let title = this.translateService.instant('seo.profile-view.title', {profile: profile});
            let profileUrl = this.utilsService.getProfileUrl(profile);


            this.setTitle(title);
            this.setDescription(this.getDescription(profile.activities));

            this.addTag('og:title', title);
            this.addTag('og:url', profileUrl);

            if (profile.picture) {
                let picture = this.utilsService.getImageFullPath(profile.picture);
                this.addTag('og:image', picture);
            }

            this.addTag('og:type', 'profile');
            this.addTag('profile:first_name', profile.firstName);
            this.addTag('profile:last_name', profile.lastName);
            this.addTag('profile:gender', profile.gender === Gender.MAN ? 'male' : 'female');
        }
    }

    clearOG() {
        this.meta.removeTag('og:title');
        this.meta.removeTag('og:url');
        this.meta.removeTag('og:image');
        this.meta.removeTag('og:type');
    }

    setTitle(title: string) {
        this.title.setTitle(title);
    }

    setDescription(description: string) {
        this.addTag('description', description);
    }

    setKeywords(keywords: string) {
        this.addTag('keywords', keywords);
    }

    setRobots(follow = true) {
        let content = follow ? 'index,follow' : 'noindex,nofollow';
        this.addTag('robots', content);
    }

    private addTag(name: string, content: string) {
        this.meta.addTag({
            name: name,
            content: content
        });
    }

    private getDescription(activities: ActivityDto[]) {
        let description = '';

        if (activities && activities.length > 0) {
            let length = activities.length;

            description = activities[0].frName;
            for (let i = 1; i < 3; ++i) {
                if (i < length) {
                    description += `, ${activities[i].frName}`;
                }
            }

            if (length > 3) {
                description += length - 3 + ' autres services';
            }
        }

        return description;
    }
}
