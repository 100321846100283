import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { UserAccountUtils } from '../../../../core/utils';
var ReviewListComponent = /** @class */ (function () {
    function ReviewListComponent() {
        this.UserAccountUtils = UserAccountUtils;
        this.emptyMessage = 'global.no-review';
        this.orientation = 'horizontal';
        this.deleteReview = new EventEmitter();
        this.updateReview = new EventEmitter();
    }
    return ReviewListComponent;
}());
export { ReviewListComponent };
