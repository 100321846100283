import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Observable} from 'rxjs';

import {ModalEvent} from '../../../../core/models';

declare var $: any;

@Component({
    moduleId: module.id,
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnDestroy {


    @Input() modalId: string;
    @Input() modalClass: string;
    @Input() modalContentClass: string;
    @Input() showHeader = true;
    @Input() showFooter = false;
    @Input() dismissible = true;
    @Input() closable = true;

    @Output() ready = new EventEmitter();
    @Output() close = new EventEmitter();

    _modalEventListener: Observable<ModalEvent>;
    _modal: any;

    constructor() {
    }

    ngOnDestroy(): void {
    }

    @Input()
    set modalEventListener(listener: Observable<ModalEvent>) {
        this._modalEventListener = listener;


        $(document).ready(() => {
            let _modal = $('#' + this.modalId);
            _modal.modal({
                ready: (modal, trigger) => this.ready.emit({modal, trigger}),
                complete: () => this.close.emit(),
                dismissible: this.dismissible
            });

            if (this._modalEventListener) {
                this._modalEventListener.subscribe((event: ModalEvent) => {
                    _modal = $('#' + this.modalId);
                    if (_modal) {
                        _modal.modal(event);
                    }
                    // $('#' + this.modalId).modal(event)
                });
            }
        });
    }

    closeModal() {
        let _modal = $('#' + this.modalId);
        _modal.modal(ModalEvent.CLOSE);
        this.close.emit();
    }

}
