import { Config } from '../../../config';
import { ActivityDto, AddressDto, SearchCriteriaDto } from '../../core/models';
import { CommonUtils } from '../../core/utils';
var UserParamUtils = /** @class */ (function () {
    function UserParamUtils() {
    }
    UserParamUtils.toParams = function (searchCriteria, pageable) {
        var params = [];
        if (pageable) {
            var pageableToParams = CommonUtils.pageableToParams(pageable);
            CommonUtils.concatParams(params, pageableToParams);
        }
        if (!searchCriteria) {
            return CommonUtils.pageableToParams(pageable);
        }
        if (searchCriteria.address && searchCriteria.address.location) {
            params['lat'] = searchCriteria.address.location.latitude;
            params['lng'] = searchCriteria.address.location.longitude;
            params['address'] = searchCriteria.address.formatted;
        }
        if (searchCriteria.activity) {
            params['activityId'] = searchCriteria.activity.id;
            params['activityName'] = searchCriteria.activity.name;
        }
        if (searchCriteria.radius) {
            params['radius'] = searchCriteria.radius;
        }
        return params;
    };
    UserParamUtils.paramsToSearchCriteria = function (params) {
        if (!params) {
            return null;
        }
        var latitude = +params['lat'];
        var longitude = +params['lng'];
        var activityId = +params['activityId'];
        var sc;
        if (activityId) {
            sc = new SearchCriteriaDto();
            sc.activity = new ActivityDto();
            sc.activity.id = activityId;
            sc.activity.name = params['activityName'];
        }
        if (latitude && longitude) {
            if (!sc) {
                sc = new SearchCriteriaDto();
            }
            var address = new AddressDto();
            address.location = {
                latitude: latitude,
                longitude: longitude
            };
            address.formatted = params['address'];
            sc.address = address;
        }
        if (sc) {
            sc.radius = params['radius'] ? +params['radius'] : Config.ENVIRONMENT().RADIUS;
        }
        return sc;
    };
    return UserParamUtils;
}());
export { UserParamUtils };
