import { Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, timer } from 'rxjs';
import { filter, startWith, takeWhile, withLatestFrom } from 'rxjs/operators';
import { Config } from './config';
import { AppHelper } from './helper/app.helper';
import { Locale } from './modules/core/models';
import { BrowserService } from './modules/core/services';
import { AnalyticsService } from './modules/core/services/analytics.service';
import { authenticationActions, localeActions, signUpActions } from './modules/core/store/actions';
import { ContextUtils } from './modules/core/utils';
import * as fromRoot from './modules/store';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, store, injector, route, translateService, analyticsService, browserService, appHelper, vcRef) {
        this.router = router;
        this.store = store;
        this.injector = injector;
        this.route = route;
        this.translateService = translateService;
        this.analyticsService = analyticsService;
        this.browserService = browserService;
        this.appHelper = appHelper;
        this.vcRef = vcRef;
        this.authenticated$ = this.store.pipe(select(fromRoot.isAuthenticated));
        this.timeoutInterval = 60 * 1000;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateService.setDefaultLang(Locale.FRENCH.shortcut);
        if (Config.IS_MOBILE_NATIVE()) {
            this.appHelper.checkAuthentication();
            this.appHelper.checkIntro(this.vcRef);
            this.appHelper.checkLang();
            this.appHelper.listenToAppHealthStatus();
            this.appHelper.addLifecycleEventlisteners();
            this.appHelper.checkConnectivity();
            this.appHelper.listenOnRouteToURL();
            this.authenticated$.subscribe(function (authed) { return _this.authenticated = authed; });
            var unreadConversationsCount$ = this.store.pipe(select(fromRoot.getUnreadConversationsCount));
            var timer$ = timer(3000, this.timeoutInterval)
                .pipe(withLatestFrom(this.authenticated$, unreadConversationsCount$), takeWhile(function (_a) {
                var t = _a[0], authed = _a[1];
                if (authed) {
                    _this.authenticated = authed;
                    _this.countConversation();
                }
                return authed === true;
            }), filter(function () {
                var displayNotif = true;
                if (_this.lastUnreadMessageCountNotificationDate) {
                    var expire = new Date(_this.lastUnreadMessageCountNotificationDate.getTime() + 3 * 60 * 60 * 1000);
                    displayNotif = expire.getTime() < new Date().getTime();
                }
                return displayNotif;
            })).subscribe(function (_a) {
                var t = _a[0], authed = _a[1], count = _a[2];
                if (count) {
                    _this.appHelper.notifyNewMessage();
                    _this.lastUnreadMessageCountNotificationDate = new Date();
                }
            });
        }
        else {
            if (this.browserService.isPlatformServer()) {
                this.initServerSide();
            }
            else {
                this.initClientSide();
                var langChangeEvent = this.translateService.onLangChange.pipe(startWith({ lang: this.translateService.currentLang, translations: [] }));
                combineLatest(langChangeEvent, this.router.events)
                    .subscribe(function (_a) {
                    var langEvent = _a[0], event = _a[1];
                    if (event instanceof RoutesRecognized) {
                        var currentUrl = event.url;
                        _this.analyticsService.pageTrack(currentUrl);
                        var locale = Locale.getLocaleByShortcut(langEvent.lang);
                        var queryParams = event.state.root.queryParams;
                        if (locale) {
                            if (!ContextUtils.isCurrentLang(currentUrl, locale)) {
                                var url = ContextUtils.updateLocaleLang(currentUrl, locale);
                                _this.router.navigateByUrl(url, { queryParams: queryParams });
                            }
                            // if (Locale.isArabic(locale)) {
                            //     if (!ContextUtils.isArabicUrl(currentUrl)) {
                            //         this.router.navigateByUrl(ContextUtils.arabizeUrl(currentUrl), {queryParams: queryParams});
                            //     }
                            // } else {
                            //     if (ContextUtils.isArabicUrl(currentUrl)) {
                            //         this.router.navigateByUrl(ContextUtils.frenchifyUrl(currentUrl), {queryParams: queryParams});
                            //     }
                            // }
                        }
                    }
                });
                this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
                    _this.browserService.materializeWithDelay(10);
                    _this.browserService.scrollToTop();
                });
                // TODO intro.js
                // introJs().start();
            }
        }
    };
    AppComponent.prototype.initClientSide = function () {
        this.appHelper.initClientSide();
    };
    AppComponent.prototype.initServerSide = function () {
        var locale;
        var request = this.injector.get(REQUEST);
        if (request) {
            var lang = request.cookies.lang;
            if (lang) {
                locale = Locale.getLocaleByShortcut(lang);
            }
        }
        if (!locale) {
            locale = Locale.FRENCH;
        }
        this.translateService.use(locale.shortcut);
        this.store.dispatch(new localeActions.SetLocaleAction(locale));
    };
    AppComponent.prototype.sendEmailConfirmation = function () {
        if (this.email) {
            this.store.dispatch(new signUpActions.SignUpConfirmationResentEmailAction(this.email));
        }
    };
    AppComponent.prototype.closeEmailNotif = function () {
        this.emailNotifClosed = true;
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.appHelper.removeLifecycleEventListeners();
        this.appHelper.triggerStopMonitoring();
    };
    AppComponent.prototype.countConversation = function () {
        if (!this.authenticated) {
            return;
        }
        this.store.dispatch(new authenticationActions.CountUnreadConversationsAction());
    };
    return AppComponent;
}());
export { AppComponent };
