import { EventEmitter, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalEvent } from '../../../../core/models';
var ModalComponent = /** @class */ (function () {
    function ModalComponent() {
        this.showHeader = true;
        this.showFooter = false;
        this.dismissible = true;
        this.closable = true;
        this.ready = new EventEmitter();
        this.close = new EventEmitter();
    }
    ModalComponent.prototype.ngOnDestroy = function () {
    };
    Object.defineProperty(ModalComponent.prototype, "modalEventListener", {
        set: function (listener) {
            var _this = this;
            this._modalEventListener = listener;
            $(document).ready(function () {
                var _modal = $('#' + _this.modalId);
                _modal.modal({
                    ready: function (modal, trigger) { return _this.ready.emit({ modal: modal, trigger: trigger }); },
                    complete: function () { return _this.close.emit(); },
                    dismissible: _this.dismissible
                });
                if (_this._modalEventListener) {
                    _this._modalEventListener.subscribe(function (event) {
                        _modal = $('#' + _this.modalId);
                        if (_modal) {
                            _modal.modal(event);
                        }
                        // $('#' + this.modalId).modal(event)
                    });
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    ModalComponent.prototype.closeModal = function () {
        var _modal = $('#' + this.modalId);
        _modal.modal(ModalEvent.CLOSE);
        this.close.emit();
    };
    return ModalComponent;
}());
export { ModalComponent };
