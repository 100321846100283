import { HttpClient } from '@angular/common/http';
import { HttpUtil } from '../utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AccountActivationService = /** @class */ (function () {
    function AccountActivationService(httpClient) {
        this.httpClient = httpClient;
    }
    AccountActivationService.prototype.activateAccount = function (userActivationDto) {
        var url = HttpUtil.buildAndExpand('api/v1/account/{userUid}/activate/{token}', userActivationDto.userUid, userActivationDto.token);
        return this.httpClient.post(url, null);
    };
    AccountActivationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountActivationService_Factory() { return new AccountActivationService(i0.ɵɵinject(i1.HttpClient)); }, token: AccountActivationService, providedIn: "root" });
    return AccountActivationService;
}());
export { AccountActivationService };
