<div class="row justify-content-center" yfLayoutClass>
    <div class="col-md-8 px-0 px-md-1">

        <div class="card">

            <div class="card-header center">
                <span class="text-white" translate>sign-in.with-social-media</span>
            </div>

            <div class="card-block">
                <div class="row justify-content-center mb-0">

                    <div class="col-md-8 center white-text">
                        <form ngNoForm action="/signin/facebook" method="post">
                            <input type="hidden" name="scope"
                                   value="email,public_profile,user_about_me,user_actions.video,user_location"/>

                            <button type="submit" class="waves-effect waves-light btn social facebook" dir="ltr">
                                <i class="fa fa-facebook"></i>
                                <span class="hidden-sm-down" translate>sign-in.with</span> Facebook
                            </button>

                        </form>
                    </div>
                </div>
            </div>

        </div>

        <div class="center">
            <h2 class="h5 my-4" translate>global.or</h2>
        </div>

        <div class="card">

            <div class="card-header center">
                <span class="text-white" translate>sign-in.with-email</span>
            </div>

            <div class="card-block">

                <app-notification [notification]="notification"></app-notification>

                <form name="form" (ngSubmit)="submit(loginForm)" #loginForm="ngForm">

                    <div class="row justify-content-center">
                        <div class="col-md-8">
                            <div class="input-field">
                                <input type="text" class="form-control" id="login" name="login"
                                       [(ngModel)]="userLogin.login" required
                                       validate/>
                                <label for="login" translate>global.email</label>
                            </div>
                        </div>

                        <div class="col-md-8">
                            <div class="input-field">
                                <input type="password" class="form-control" id="password"
                                       name="password"
                                       [(ngModel)]="userLogin.password"
                                       required/>
                                <label for="password" translate>global.password</label>
                            </div>
                        </div>

                        <div class="col-md-8 mt-2">
                            <button [disabled]="loginForm.invalid || (loading$ | async)"
                                    class="btn btn-primary col-12" translate>global.sign-in
                            </button>
                        </div>

                        <div class="col-md-8 align-content-end mt-2">
                            <a [routerLink]="['/password', 'forgotten']">
                                <span translate>global.is-password-forgotten</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>