import {BaseUserDto, SessionDto, UserAccountDto, UserRole} from '../models';

export class UserAccountUtils {

    public static hasRole(userAccount: UserAccountDto, role: UserRole): boolean {

        if (!userAccount || !userAccount.roles) {
            return false;
        }

        let found = userAccount.roles.find(r => r.name === role);

        return found != null;
    }

    public static isUserAccountAdmin(userAccount: UserAccountDto): boolean {
        return UserAccountUtils.hasRole(userAccount, UserRole.ROLE_ADMIN)
    }

    public static isUserAccountManager(userAccount: UserAccountDto): boolean {
        if (UserAccountUtils.isUserAccountAdmin(userAccount)) {
            return true;
        }

        return UserAccountUtils.hasRole(userAccount, UserRole.ROLE_MANAGER)
    }

    public static isUserAccountVerifier(userAccount: UserAccountDto): boolean {
        if (UserAccountUtils.isUserAccountManager(userAccount)) {
            return true;
        }

        return UserAccountUtils.hasRole(userAccount, UserRole.ROLE_VERIFIER)
    }

    public static isSessionAdmin(session: SessionDto): boolean {
        if (!session) {
            return false;
        }

        return UserAccountUtils.isUserAccountAdmin(session.user);
    }

    public static isSameUserAccountByUid(userAccount: UserAccountDto, uid: string): boolean {
        if (!userAccount) {
            return false;
        }

        return userAccount.uid === uid;
    }

    public static isSameUser(user: UserAccountDto, otherUser: BaseUserDto): boolean {
        if (!otherUser || !user) {
            return false;
        }

        return UserAccountUtils.isSameUserAccountByUid(user, otherUser.uid);
    }
}
