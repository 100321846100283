export var AccountConversationsActionTypes;
(function (AccountConversationsActionTypes) {
    AccountConversationsActionTypes["LoadConversations"] = "[Account Conversation] LOAD_CONVERSATIONS";
    AccountConversationsActionTypes["LoadConversationsSuccess"] = "[Account Conversation] LOAD_CONVERSATIONS_SUCCESS";
    AccountConversationsActionTypes["LoadConversationsFail"] = "[Account Conversation] LOAD_CONVERSATIONS_FAIL";
    AccountConversationsActionTypes["LoadConversation"] = "[Account Conversation] LOAD_CONVERSATION";
    AccountConversationsActionTypes["LoadConversationSuccess"] = "[Account Conversation] LOAD_CONVERSATION_SUCCESS";
    AccountConversationsActionTypes["LoadConversationFail"] = "[Account Conversation] LOAD_CONVERSATION_FAIL";
    AccountConversationsActionTypes["MarkAsRead"] = "[Account Conversation] MARK_AS_READ";
    AccountConversationsActionTypes["MarkAsReadSuccess"] = "[Account Conversation] MARK_AS_READ_SUCCESS";
    AccountConversationsActionTypes["SetSelectedConversationId"] = "[Account Conversation] SET_SELECTED_CONVERSATION_ID";
    AccountConversationsActionTypes["Reset"] = "[Account Conversation] reset";
})(AccountConversationsActionTypes || (AccountConversationsActionTypes = {}));
var LoadConversations = /** @class */ (function () {
    function LoadConversations(payload) {
        this.payload = payload;
        this.type = AccountConversationsActionTypes.LoadConversations;
    }
    return LoadConversations;
}());
export { LoadConversations };
var LoadConversationsSuccess = /** @class */ (function () {
    function LoadConversationsSuccess(payload) {
        this.payload = payload;
        this.type = AccountConversationsActionTypes.LoadConversationsSuccess;
    }
    return LoadConversationsSuccess;
}());
export { LoadConversationsSuccess };
var LoadConversationsFail = /** @class */ (function () {
    function LoadConversationsFail(payload) {
        this.payload = payload;
        this.type = AccountConversationsActionTypes.LoadConversationsFail;
    }
    return LoadConversationsFail;
}());
export { LoadConversationsFail };
var LoadConversation = /** @class */ (function () {
    function LoadConversation(payload) {
        this.payload = payload;
        this.type = AccountConversationsActionTypes.LoadConversation;
    }
    return LoadConversation;
}());
export { LoadConversation };
var LoadConversationSuccess = /** @class */ (function () {
    function LoadConversationSuccess(payload) {
        this.payload = payload;
        this.type = AccountConversationsActionTypes.LoadConversationSuccess;
    }
    return LoadConversationSuccess;
}());
export { LoadConversationSuccess };
var LoadConversationFail = /** @class */ (function () {
    function LoadConversationFail(payload) {
        this.payload = payload;
        this.type = AccountConversationsActionTypes.LoadConversationFail;
    }
    return LoadConversationFail;
}());
export { LoadConversationFail };
/**
 * Read messages.
 */
var MarkAsRead = /** @class */ (function () {
    function MarkAsRead(payload) {
        this.payload = payload;
        this.type = AccountConversationsActionTypes.MarkAsRead;
    }
    return MarkAsRead;
}());
export { MarkAsRead };
var MarkAsReadSuccess = /** @class */ (function () {
    function MarkAsReadSuccess() {
        this.type = AccountConversationsActionTypes.MarkAsReadSuccess;
    }
    return MarkAsReadSuccess;
}());
export { MarkAsReadSuccess };
var SetSelectedConversationId = /** @class */ (function () {
    function SetSelectedConversationId(payload) {
        this.payload = payload;
        this.type = AccountConversationsActionTypes.SetSelectedConversationId;
    }
    return SetSelectedConversationId;
}());
export { SetSelectedConversationId };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = AccountConversationsActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
