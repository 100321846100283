<app-modal [modalId]="modalId"
           [modalEventListener]="modalEventListener"
           [modalClass]="modalClass"
           [showFooter]="showFooter"
           (close)="quit.emit($event)">

    <h1 class="col my-0" modal-header>{{title}}</h1>

    <app-activity-chooser [categories]="categories"
                          [activities]="activities"
                          [selectedCategories]="selectedCategories"
                          [selectedActivities]="selectedActivities"
                          [initListener]="initListener"
                          (selectActivity)="selectActivity.emit($event)"
                          (selectCategory)="selectCategory.emit($event)"
                          (quit)="quit.emit($event)"
                          modal-content>
    </app-activity-chooser>

    <div modal-footer *ngIf="showFooter">
        <button type="button" class="btn btn-primary right" (click)="validate.emit($event)" translate>global.validate</button>
        <button type="button" class="btn-flat right" (click)="quit.emit($event)" translate>global.close</button>
    </div>

</app-modal>