var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CommonUtils } from '../../../core/utils';
import * as fromRoot from '../../../store';
import { requestsPromotionActions, workersPromotionActions } from '../actions';
import { PromotionService } from '../../services';
var PromotionEffects = /** @class */ (function () {
    function PromotionEffects(actions$, store, promoService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.promoService = promoService;
        this.loadRequestsPromotionIfNotLoaded = this.actions$.pipe(ofType(requestsPromotionActions.RequestPromotionActionTypes.LoadPromotionRequestsIfNotLoaded), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromRoot.getRequestsPromotionLoadingDate)), this.store.pipe(select(fromRoot.getRequestsPromotionResult))), mergeMap(function (_a) {
            var pageable = _a[0], loadingDate = _a[1], promoResult = _a[2];
            if (CommonUtils.notEmpty(promoResult) && !_this.arePromotionsExpired(loadingDate)) {
                return [];
            }
            return [new requestsPromotionActions.LoadPromotionRequestsAction(pageable)];
        }));
        this.loadRequestsPromotion = this.actions$.pipe(ofType(requestsPromotionActions.RequestPromotionActionTypes.LoadPromotionRequests), map(function (action) { return action.payload; }), mergeMap(function (pageable) {
            return _this.promoService.searchRequestsAroundIPOrLast(pageable).pipe(map(function (page) { return new requestsPromotionActions.LoadPromotionRequestsSuccessAction(page); }), catchError(function (errors) { return of(new requestsPromotionActions.LoadPromotionRequestsFailAction(errors)); }));
        }));
        this.loadWorkersPromotionIfNotLoaded = this.actions$.pipe(ofType(workersPromotionActions.WorkersPromotionActionTypes.LoadPromotionWorkersIfNotLoaded), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromRoot.getWorkersPromotionLoadingDate))), mergeMap(function (_a) {
            var pageable = _a[0], loadingDate = _a[1];
            if (!_this.arePromotionsExpired(loadingDate)) {
                return [];
            }
            return [new workersPromotionActions.LoadPromotionWorkersAction(pageable)];
        }));
        this.loadWorkersPromotion = this.actions$.pipe(ofType(workersPromotionActions.WorkersPromotionActionTypes.LoadPromotionWorkers), map(function (action) { return action.payload; }), mergeMap(function (pageable) {
            return _this.promoService.searchWorkersAroundIPOrLast(pageable).pipe(map(function (page) { return new workersPromotionActions.LoadPromotionWorkersSuccessAction(page); }), catchError(function (errors) { return of(new workersPromotionActions.LoadPromotionWorkersFailAction(errors)); }));
        }));
    }
    PromotionEffects.prototype.arePromotionsExpired = function (loadingDate) {
        if (!loadingDate) {
            return true;
        }
        var MS_PER_MINUTE = 60000;
        var date = new Date(new Date().getTime() + 5 * MS_PER_MINUTE);
        return loadingDate > date;
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PromotionEffects.prototype, "loadRequestsPromotionIfNotLoaded", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PromotionEffects.prototype, "loadRequestsPromotion", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PromotionEffects.prototype, "loadWorkersPromotionIfNotLoaded", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], PromotionEffects.prototype, "loadWorkersPromotion", void 0);
    return PromotionEffects;
}());
export { PromotionEffects };
