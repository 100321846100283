var Pageable = /** @class */ (function () {
    function Pageable(size) {
        if (size === void 0) { size = 10; }
        var sort = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            sort[_i - 1] = arguments[_i];
        }
        this.page = 1;
        this.size = 10;
        this.sort = [];
        this.size = size;
        this.sort = sort;
    }
    return Pageable;
}());
export { Pageable };
export var SortDirection = {
    DESC: 'desc',
    ASC: 'asc',
};
var Sort = /** @class */ (function () {
    function Sort(direction, property) {
        this.direction = direction;
        this.property = property;
    }
    return Sort;
}());
export { Sort };
var Page = /** @class */ (function () {
    function Page() {
        this.content = [];
    }
    return Page;
}());
export { Page };
