import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as fromRoot from '../../../store';
import { authenticationActions, routeActions } from '../../store/actions';
import { AuthService, RouteService } from '../index';
import { UserAccountUtils } from '../../utils';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../auth.service";
import * as i3 from "../route.service";
var AdminGuard = /** @class */ (function () {
    function AdminGuard(store, authService, routeService) {
        this.store = store;
        this.authService = authService;
        this.routeService = routeService;
    }
    AdminGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.store.pipe(select(fromRoot.getCurrentUser), map(function (currentUser) {
            if (!currentUser && !_this.authService.isAuthenticated()) {
                _this.store.dispatch(new routeActions.ToSignInAction());
                return false;
            }
            var storeUser = false;
            var user = currentUser;
            if (!user) {
                storeUser = true;
                user = _this.authService.getUser();
            }
            if (!UserAccountUtils.isUserAccountVerifier(user)) {
                _this.routeService.toNotFound();
                return false;
            }
            if (storeUser) {
                _this.store.dispatch(new authenticationActions.SetUserAction(user));
            }
            return true;
        }));
    };
    AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.RouteService)); }, token: AdminGuard, providedIn: "root" });
    return AdminGuard;
}());
export { AdminGuard };
