import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {ActivityDto, CategoryDto, ModalEvent} from '../../../core/models';
import * as fromRoot from '../../../store';
import {activityChooserManagerActions, activityChooserModalActions} from '../../../core/store/actions';

@Component({
    selector: 'app-activity-chooser-manager',
    templateUrl: './service-chooser-manager.component.html',
    styleUrls: ['./service-chooser-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityChooserManagerComponent implements OnInit {

    @Input() multiple = false;
    @Input() resetOnInit = true;
    @Input() openEventListener$: Observable<any>;
    @Input() showFooter = true;

    @Output() activitySelected = new EventEmitter();
    @Output() categorySelected = new EventEmitter();

    activityModalEventListener$: Observable<ModalEvent>;
    activityChooserInitListener$: Observable<any>;
    categories$: Observable<CategoryDto[]>;
    activities$: Observable<ActivityDto[]>;
    selectedCategories$: Observable<CategoryDto[]>;
    selectedActivities$: Observable<ActivityDto[]>;

    constructor(private store: Store<fromRoot.State>) {
        this.categories$ = this.store.pipe(select(fromRoot.getCategories));
        this.activities$ = this.store.pipe(select(fromRoot.getActivityChooserManagerActivities));
        this.selectedCategories$ = this.store.pipe(select(fromRoot.getActivityChooserManagerSelectedCategories));
        this.selectedActivities$ = this.store.pipe(select(fromRoot.getActivityChooserManagerSelectedActivities));
        this.activityModalEventListener$ = this.store.pipe(select(fromRoot.getActivityChooserModalEvent));
    }

    ngOnInit() {
        if (this.resetOnInit) {
            this.store.dispatch(new activityChooserManagerActions.ResetAction());
        }

        this.openEventListener$.subscribe(() => this.openActivityChooser());
    }

    openActivityChooser() {
        this.store.dispatch(new activityChooserModalActions.OpenActivityChooserModalAction());
    }

    selectActivity(activity: ActivityDto) {

        if (this.multiple) {
            this.store.dispatch(new activityChooserManagerActions.SelectOrDeselectActivityAction(activity));
        } else {
            this.store.dispatch(new activityChooserManagerActions.SetSelectedActivitiesAction([activity]));
            this.closeModal();
        }

        this.activitySelected.emit(activity);
    }

    selectCategory(category: CategoryDto) {
        this.store.dispatch(new activityChooserManagerActions.SetSelectedCategoriesAction([category]));
        this.store.dispatch(new activityChooserManagerActions.SetActivitiesAction(category.activities));

        this.categorySelected.emit(category);
    }

    closeModal() {
        this.store.dispatch(new activityChooserModalActions.CloseActivityChooserModalAction());
    }

}
