import {IntegerRange} from '../models';

export class RangeUtils {

    public static buildRange(integerRange: IntegerRange): number[] {

        let min = 0;
        let max = 1000;

        if (integerRange) {
            let rMin = integerRange.min;
            if (rMin) {
                min = rMin;
            }

            let rMax = integerRange.max;
            if (rMax) {
                max = rMax;
            }
        }

        return [min, max];
    }

}


