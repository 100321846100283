import { HttpClient } from '@angular/common/http';
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { Config } from '../../../config';
var intlTelInput = require('intl-tel-input');
var utilsScript = 'assets/js/utils.js';
var IntlTelDirective = /** @class */ (function () {
    function IntlTelDirective(elRef, httpClient) {
        this.elRef = elRef;
        this.httpClient = httpClient;
        this.selectPhone = new EventEmitter();
    }
    IntlTelDirective.prototype.ngOnInit = function () {
    };
    IntlTelDirective.prototype.onKeyUp = function () {
        if (this.iti) {
            if (this.iti.isValidNumber()) {
                this.selectPhone.emit(this.iti.getNumber());
            }
            else {
                // let error = this.iti.getValidationError();
            }
        }
    };
    Object.defineProperty(IntlTelDirective.prototype, "appIntlTel", {
        set: function (enabled) {
            if (enabled) {
                this.iti = intlTelInput(this.elRef.nativeElement, {
                    utilsScript: utilsScript,
                    autoPlaceholder: 'off',
                    initialCountry: Config.INITIAL_COUNTRY,
                    preferredCountries: Config.PREFERRED_COUNTRIES,
                    onlyCountries: Config.ALLOWED_COUNTRIES
                    // initialCountry: "auto",
                    // geoIpLookup: (success, failure) => this.geoIpLookup(this.httpClient, success, failure)
                });
            }
            else {
                if (this.iti) {
                    this.iti.destroy();
                }
            }
            this.elRef.nativeElement.focus();
        },
        enumerable: true,
        configurable: true
    });
    IntlTelDirective.prototype.geoIpLookup = function (httpClient, success, failure) {
        httpClient.get('https://api.ipapi.com/api/check').subscribe(function (resp) {
            var countryCode = (resp && resp.countryCode) ? resp.countryCode : '';
            success(countryCode);
        });
    };
    return IntlTelDirective;
}());
export { IntlTelDirective };
