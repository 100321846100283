import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {BasicUserDto, ModalEvent, Notification, UserReportDto} from '../../../../core/models';
import {ErrorUtils} from '../../../../core/utils';
import * as fromRoot from '../../../../store';
import {reportUserActions} from '../../../store/actions';

@Component({
    selector: 'app-report-user-modal',
    templateUrl: './report-user-modal.component.html',
    styleUrls: ['./report-user-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportUserModalComponent implements OnInit {

    userToReport$: Observable<BasicUserDto>;
    messageModalEvent$: Observable<ModalEvent>;
    loading$: Observable<boolean>;
    isUserReported$: Observable<boolean>;
    notification$: Observable<Notification>;

    initListener$ = new Subject();

    constructor(private store: Store<fromRoot.State>) {
        this.userToReport$ = this.store.pipe(select(fromRoot.getUserToReport));
        this.messageModalEvent$ = this.store.pipe(select(fromRoot.getReportUserModalEvent));
        this.loading$ = this.store.pipe(select(fromRoot.isReportUserLoading));
        this.isUserReported$ = this.store.pipe(select(fromRoot.isUserReported));
        this.notification$ = this.store.pipe(select(fromRoot.getReportUserErrors), map(ErrorUtils.toNotification));
    }

    ngOnInit() {
        this.isUserReported$.pipe(
            filter(reported => reported))
            .subscribe(() => {
                this.closeModal()
            });
    }

    cancel() {
        this.store.dispatch(new reportUserActions.ResetAction());
        this.closeModal();
    }

    report(userReport: UserReportDto) {
        this.store.dispatch(new reportUserActions.ReportUserAction(userReport));
    }

    closeModal() {
        this.store.dispatch(new reportUserActions.CloseModalAction());
    }

}
