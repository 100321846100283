import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ActionReducer, MetaReducer, Store } from '@ngrx/store';
import { Config } from './config';
import { environment } from './environments/environment';
import { TestUserAction } from './modules/core/store/actions/authentication.action';
import * as categoryActions from './modules/core/store/actions/category.action';
import { CORE_EFFECTS } from './modules/core/store/effects';
import { WebEffects } from './modules/core/store/effects/web.effects';
import { PROMOTIONS_EFFECTS } from './modules/promotions/store/effects';
import * as fromRootStore from './modules/store';
import { USERS_EFFECTS } from './modules/users/store/effects';
import { TranslateServerLoader } from './modules/web/services/translate-server-loader.service';
var _metaReducers = [stateSetter];
export var metaReducers = _metaReducers;
export var NGRX_STATE = makeStateKey('NGRX_STATE');
var effects = PROMOTIONS_EFFECTS.concat(CORE_EFFECTS, USERS_EFFECTS, [
    WebEffects
]);
var customOptions = {
    include: ['facebook', 'whatsapp', 'twitter', 'google', 'email', 'sms', 'copy'],
    theme: 'modern-dark',
    gaTracking: true,
    autoSetMeta: false,
    twitterAccount: 'bricoolOfficiel'
};
Config.PLATFORM_TARGET = Config.PLATFORMS.WEB;
var AppModule = /** @class */ (function () {
    function AppModule(transferState, store) {
        this.transferState = transferState;
        this.store = store;
        var isBrowser = this.transferState.hasKey(NGRX_STATE);
        if (isBrowser) {
            this.onBrowser();
        }
        else {
            this.onServer();
        }
    }
    AppModule.prototype.onServer = function () {
        var _this = this;
        this.transferState.onSerialize(NGRX_STATE, function () {
            var state;
            _this.store.subscribe(function (saveState) {
                state = saveState;
            }).unsubscribe();
            return state;
        });
    };
    AppModule.prototype.onBrowser = function () {
        var state = this.transferState.get(NGRX_STATE, null);
        this.transferState.remove(NGRX_STATE);
        this.store.dispatch({ type: 'SET_ROOT_STATE', payload: state });
    };
    return AppModule;
}());
export { AppModule };
/**
 * Application initialiazer. Retrieve context from server.
 * @param store Ngrx store.
 */
export function initApplication(store, platformId) {
    var fn = function () { return new Promise(function (resolve) {
        store.dispatch(new categoryActions.LoadCategoriesAction());
        if (isPlatformServer(platformId)) {
            store.dispatch(new TestUserAction());
        }
        else if (!environment.production) {
            store.dispatch(new TestUserAction());
        }
        // if (isPlatformBrowser(platformId)) {
        //     const dom = ɵgetDOM();
        //     const styles: any[] = Array.prototype.slice.apply(dom.querySelectorAll(document, `style[ng-transition]`));
        //     styles.forEach(el => {
        //         // Remove ng-transition attribute to prevent Angular appInitializerFactory
        //         // to remove server styles before preboot complete
        //         el.removeAttribute('ng-transition');
        //     });
        //     document.addEventListener('PrebootComplete', () => {
        //         // After preboot complete, remove the server scripts
        //         setTimeout(() => styles.forEach(el => dom.remove(el)));
        //     });
        // }
        return resolve(true);
    }); };
    return fn;
}
export function exportTranslateStaticLoader(http) {
    // return new TranslateHttpLoader(http);
    return new TranslateServerLoader();
}
// Export for AoT
export function stateSetter(reducer) {
    var fn = function (state, action) {
        if (action.type === 'SET_ROOT_STATE') {
            return action.payload;
        }
        return reducer(state, action);
    };
    return fn;
}
