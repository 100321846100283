export var ServiceType;
(function (ServiceType) {
    ServiceType[ServiceType["CATEGORY"] = 0] = "CATEGORY";
    ServiceType[ServiceType["ACTIVITY"] = 1] = "ACTIVITY";
})(ServiceType || (ServiceType = {}));
var FlatServiceDto = /** @class */ (function () {
    function FlatServiceDto() {
    }
    return FlatServiceDto;
}());
export { FlatServiceDto };
