/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report-user-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../web/components/modals/modal/modal.component.ngfactory";
import * as i3 from "../../../../web/components/modals/modal/modal.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "../../report-user/report-user.component.ngfactory";
import * as i7 from "../../report-user/report-user.component";
import * as i8 from "./report-user-modal.component";
import * as i9 from "@ngrx/store";
var styles_ReportUserModalComponent = [i0.styles];
var RenderType_ReportUserModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportUserModalComponent, data: {} });
export { RenderType_ReportUserModalComponent as RenderType_ReportUserModalComponent };
export function View_ReportUserModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "app-modal", [["modalClass", "bottom-sheet-modal-height bottom-sheet"], ["modalId", "report-user-modal"]], null, [[null, "ready"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ready" === en)) {
        var pd_0 = (_co.initListener$.next() !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.closeModal() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ModalComponent_0, i2.RenderType_ModalComponent)), i1.ɵdid(1, 180224, null, 0, i3.ModalComponent, [], { modalId: [0, "modalId"], modalClass: [1, "modalClass"], modalEventListener: [2, "modalEventListener"] }, { ready: "ready", close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "h1", [["class", "col px-0 my-0"], ["modal-header", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " ", " ", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, 1, 3, "app-report-user", [["modal-content", ""]], null, [[null, "cancel"], [null, "report"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancel" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } if (("report" === en)) {
        var pd_1 = (_co.report($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ReportUserComponent_0, i6.RenderType_ReportUserComponent)), i1.ɵdid(8, 114688, null, 0, i7.ReportUserComponent, [i1.ChangeDetectorRef], { loading$: [0, "loading$"], notification: [1, "notification"], user: [2, "user"], initListener$: [3, "initListener$"] }, { cancel: "cancel", report: "report" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "report-user-modal"; var currVal_1 = "bottom-sheet-modal-height bottom-sheet"; var currVal_2 = _co.messageModalEvent$; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = _co.loading$; var currVal_7 = i1.ɵunv(_v, 8, 1, i1.ɵnov(_v, 9).transform(_co.notification$)); var currVal_8 = i1.ɵunv(_v, 8, 2, i1.ɵnov(_v, 10).transform(_co.userToReport$)); var currVal_9 = _co.initListener$; _ck(_v, 8, 0, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("global.report")); var tmp_4_0 = null; var currVal_4 = (((tmp_4_0 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 5).transform(_co.userToReport$))) == null) ? null : ((tmp_4_0.profile == null) ? null : tmp_4_0.profile.firstName)); var tmp_5_0 = null; var currVal_5 = (((tmp_5_0 = i1.ɵunv(_v, 3, 2, i1.ɵnov(_v, 6).transform(_co.userToReport$))) == null) ? null : ((tmp_5_0.profile == null) ? null : tmp_5_0.profile.lastName)); _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_ReportUserModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-report-user-modal", [], null, null, null, View_ReportUserModalComponent_0, RenderType_ReportUserModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.ReportUserModalComponent, [i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportUserModalComponentNgFactory = i1.ɵccf("app-report-user-modal", i8.ReportUserModalComponent, View_ReportUserModalComponent_Host_0, {}, {}, []);
export { ReportUserModalComponentNgFactory as ReportUserModalComponentNgFactory };
