import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ModalEvent, ProfileDto, RequestDto} from '../../../../core/models';
import {BcUtilsService} from '../../../../core/services/bc-utils.service';
import {shareActions} from '../../../../core/store/actions';
import * as fromRoot from '../../../../store';

@Component({
    selector: 'app-share-modal',
    templateUrl: './share-modal.component.html',
    styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {

    modalEvent$ = new BehaviorSubject(ModalEvent.CLOSE);
    element: ProfileDto | RequestDto;
    titleKey: string;

    constructor(private store: Store<fromRoot.State>,
                private translateService: TranslateService,
                private utilsService: BcUtilsService) {
    }

    ngOnInit() {
        this.store.pipe(select(fromRoot.getElementToShare), filter(element => element != null))
            .subscribe(element => {
                this.element = element;
                this.setModalTitle();
                this.modalEvent$.next(ModalEvent.OPEN);
            })
    }

    close() {
        this.modalEvent$.next(ModalEvent.CLOSE);
        this.store.dispatch(new shareActions.ClearAction());
    }

    get url() {
        if (this.instanceOfRequestDto(this.element)) {
            this.utilsService.getRequestUrl(this.element);
        }

        return this.utilsService.getProfileUrl(<ProfileDto>this.element);
    }

    get title() {
        if (this.instanceOfRequestDto(this.element)) {
            return this.utilsService.getRequestTitle(this.element);
        }

        return this.utilsService.getProfileTitle(this.element);
    }

    get description() {
        return this.translateService.instant('share.profile.description');
    }

    get image() {
        if (this.instanceOfRequestDto(this.element)) {
            return null;
        }

        this.utilsService.getImageFullPath(this.element.picture);
    }

    private setModalTitle() {
        if (this.instanceOfRequestDto(this.element)) {
            this.titleKey = 'share.request.modal-title';
        } else {
            this.titleKey = 'share.profile.modal-title';
        }
    }

    private instanceOfRequestDto(object: any): object is RequestDto {
        return object && object.budgetType !== undefined;
    }
}
