import { OnInit } from '@angular/core';
import { SeoService } from '../../../modules/core/services';
var PrivacyPolicyComponent = /** @class */ (function () {
    function PrivacyPolicyComponent(seo) {
        this.seo = seo;
        seo.setMeta('privacy-policy');
        seo.setRobots(true);
    }
    PrivacyPolicyComponent.prototype.ngOnInit = function () {
    };
    return PrivacyPolicyComponent;
}());
export { PrivacyPolicyComponent };
