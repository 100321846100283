import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';

import {BaseUserDto, ModalEvent, Notification, SendMessageDto} from '../../../../core/models';
import {ErrorUtils} from '../../../../core/utils';
import * as fromRoot from '../../../../store';
import * as messageModalActions from '../../../../core/store/actions/message.modal.action';
import * as sendMessageActions from '../../../../core/store/actions/send-message.action';

@Component({
    selector: 'app-send-message-modal',
    templateUrl: './send-message-modal.component.html',
    styleUrls: ['./send-message-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendMessageModalComponent implements OnInit {

    sender$: Observable<BaseUserDto>;
    receiver$: Observable<BaseUserDto>;
    messageModalEvent$: Observable<ModalEvent>;
    loading$: Observable<boolean>;
    notification$: Observable<Notification>;

    sendMessageInitListener$ = new Subject();

    sending: boolean;

    constructor(private store: Store<fromRoot.State>) {
        this.messageModalEvent$ = this.store.pipe(select(fromRoot.getMessageModalEvent));
        this.sender$ = this.store.pipe(select(fromRoot.getSendMessageSender));
        this.receiver$ = this.store.pipe(select(fromRoot.getSendMessageReceiver));
        this.loading$ = this.store.pipe(select(fromRoot.isSendMessageLoading));
        this.notification$ = this.store.pipe(select(fromRoot.getSendMessageErrors), map(ErrorUtils.toNotification));
    }

    ngOnInit(): void {

        this.loading$.pipe(withLatestFrom(this.notification$))
            .subscribe(([loading, errors]) => {

                if (this.sending && !loading && !errors) {
                    this.closeModal();
                    this.sending = false;
                }

            });
    }

    send(sendMessageDto: SendMessageDto) {
        this.sending = true;
        this.store.dispatch(new sendMessageActions.SendMessageAction(sendMessageDto));
    }

    closeModal() {
        this.dispatchCloseModalAction();
    }

    ready() {
        this.sendMessageInitListener$.next();
    }

    private dispatchCloseModalAction() {
        this.store.dispatch(new messageModalActions.CloseMessageModalAction());
    }


}
