import {Component, Input} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
    moduleId: module.id,
    selector: 'app-control-messages',
    templateUrl: './control-messages.component.html',
    styleUrls: ['./control-messages.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlMessagesComponent {

    @Input() control: FormControl | AbstractControl;
    @Input() controlName: string;
    @Input() forLabel: string;
    @Input() keyPrefix = 'errors';
    @Input() config: { error: string, key: string }[];

    get messages(): string[] {
        let keys = [];

        if (this.control.hasOwnProperty('controls')) {
            if (this.control && this.controlName && this.control.get(this.controlName)) {
                let prefix = `${this.keyPrefix}.${this.controlName}`;
                let ks = this.handleAbstractControl(this.control.get(this.controlName), prefix);

                if (ks && ks.length > 0) {
                    keys = [...ks];
                }
            }
        } else {
            keys = this.handleAbstractControl(this.control, this.keyPrefix);
        }

        return keys;
    }

    private handleAbstractControl(ac: AbstractControl, prefix: string): string[] {
        let keys = [];

        let cnErrors = ac.errors;
        for (let propertyName in cnErrors) {
            if (cnErrors.hasOwnProperty(propertyName) && ac.touched) {
                let errorKey = this.keyErrorKey(propertyName);

                if (!errorKey) {
                    errorKey = `${prefix}.${propertyName}`;
                }
                keys.push(errorKey);
            }
        }

        return keys;
    }

    private keyErrorKey(error: string): string {
        if (!this.config) {
            return null;
        }

        let conf: { error: string, key: string } = this.config.find(c => c.error === error);

        return conf ? conf.key : null;
    }
}
