import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {CookieService} from 'ngx-cookie-service';
import {Config} from '../../../config';

import {IBrowserService, StorageKey} from '../../core/models/interfaces';
import {Notification, RequestContext} from '../../core/models';

declare var Materialize: any;
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class BrowserWebService implements IBrowserService {

    cookieService: CookieService;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private injector: Injector) {
        this.cookieService = injector.get(CookieService);
    }

    materialize() {
        if (this.isPlatformBrowser()) {
            if (Config.IS_WEB()) {
                $(document).ready(() => Materialize.updateTextFields());
            }
        }
    }

    materializeWithDelay(delay: number) {
        if (Config.IS_WEB()) {
            $(document).ready(() =>
                setTimeout(() => {
                    Materialize.updateTextFields()
                }, 10)
            );
        }
    }

    scrollToTop() {
        if (Config.IS_WEB()) {
            window.scrollTo(0, 0);
        }
    }

    isPlatformBrowser() {
        return isPlatformBrowser(this.platformId);
    }

    isPlatformServer() {
        return isPlatformServer(this.platformId);
    }

    getServerRequestContext(): RequestContext {
        if (this.isPlatformServer()) {
            return this.injector.get(REQUEST)
        }

        return null;
    }

    setCookie(key: StorageKey, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean): void {
        if (this.isPlatformBrowser()) {
            this.cookieService.set(key, value, expires, path, domain, secure);
        }
    }

    getCookie(key: StorageKey): string {
        if (this.isPlatformBrowser()) {
            return this.cookieService.get(key);
        }

        return null;
    }

    alertNotification(notif: Notification) {
        alert(notif.title);
    }

    hideIndicator() {
    }

    back() {
    }
}
