<app-modal modalId="report-review"
           [modalEventListener]="modalEvent$"
           modalClass="bottom-sheet-modal-height bottom-sheet"
           (ready)="initListener$.next()"
           (close)="closeModal()">

    <h1 class="col px-0 my-0" modal-header translate>reports.reviews.title</h1>

    <app-report-review [review]="reviewToReport$ | async"
                       [loading$]="loading$"
                       [notification]="notification$ | async"
                       [initListener$]="initListener$"
                       (cancel)="cancel()"
                       (report)="report($event)"
                       modal-content>
    </app-report-review>
</app-modal>