/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../modal/modal.component.ngfactory";
import * as i3 from "../modal/modal.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../login/login.component.ngfactory";
import * as i6 from "../login/login.component";
import * as i7 from "@angular/common";
import * as i8 from "./login-modal.component";
import * as i9 from "@ngrx/store";
var styles_LoginModalComponent = [i0.styles];
var RenderType_LoginModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginModalComponent, data: {} });
export { RenderType_LoginModalComponent as RenderType_LoginModalComponent };
export function View_LoginModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "app-modal", [["modalClass", "bottom-sheet-modal-height bottom-sheet"], ["modalId", "login-modal"]], null, [[null, "close"], [null, "ready"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } if (("ready" === en)) {
        var pd_1 = (_co.init() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ModalComponent_0, i2.RenderType_ModalComponent)), i1.ɵdid(1, 180224, null, 0, i3.ModalComponent, [], { modalId: [0, "modalId"], modalClass: [1, "modalClass"], modalEventListener: [2, "modalEventListener"] }, { ready: "ready", close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "h1", [["class", "col my-0"], ["modal-header", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["global.authentication-required"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "material-icons action close-phone-modal"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(8, 0, null, 1, 2, "app-login", [["modal-content", ""]], null, [[null, "login"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("login" === en)) {
        var pd_0 = (_co.login($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_LoginComponent_0, i5.RenderType_LoginComponent)), i1.ɵdid(9, 114688, null, 0, i6.LoginComponent, [], { notification: [0, "notification"], loading$: [1, "loading$"], initListener$: [2, "initListener$"] }, { login: "login" }), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "login-modal"; var currVal_1 = "bottom-sheet-modal-height bottom-sheet"; var currVal_2 = _co.modalEventListener$; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = ""; _ck(_v, 4, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.notification$)); var currVal_5 = _co.loading$; var currVal_6 = _co.initListener$; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6); }, null); }
export function View_LoginModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-modal", [], null, null, null, View_LoginModalComponent_0, RenderType_LoginModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.LoginModalComponent, [i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginModalComponentNgFactory = i1.ɵccf("app-login-modal", i8.LoginModalComponent, View_LoginModalComponent_Host_0, {}, {}, []);
export { LoginModalComponentNgFactory as LoginModalComponentNgFactory };
