import { Store } from '@ngrx/store';
import * as rootActions from '../../../core/store/actions/route.action';
var NoticeListComponent = /** @class */ (function () {
    function NoticeListComponent(store) {
        this.store = store;
    }
    Object.defineProperty(NoticeListComponent.prototype, "notices", {
        set: function (notices) {
            if (notices) {
                notices.sort(function (a, b) { return a.priority - b.priority; });
                this._notices = notices;
            }
        },
        enumerable: true,
        configurable: true
    });
    NoticeListComponent.prototype.goToPage = function (notice) {
        this.store.dispatch(new rootActions.ToNoticeTargetAction(notice));
    };
    return NoticeListComponent;
}());
export { NoticeListComponent };
