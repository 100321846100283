import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UserResultVerificationDto} from '../../../core/models/rest-api.model';

@Component({
    moduleId: module.id,
    selector: 'app-worker-verification',
    templateUrl: './worker-verification.component.html',
    styleUrls: ['./worker-verification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkerVerificationComponent {

    @Input() verification: UserResultVerificationDto;
    @Input() detailed: false;
}
