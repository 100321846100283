import {MapsAPILoader} from '@agm/core';
import {Injectable} from '@angular/core';
import {from as observableFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
const _includes = require('lodash/includes');
import {IAddressService} from '../../core/models/interfaces';
import {AddressDto, Location} from '../../core/models';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

declare var google: any;

@Injectable({
    providedIn: 'root'
})
export class AddressWebService implements IAddressService {

    constructor(private mapsAPILoader: MapsAPILoader) {
    }

    init(): Observable<any> {
        return observableFrom(this.mapsAPILoader.load());
    }

    mapAddress(place: google.maps.places.PlaceResult): AddressDto {
        return this.mapToAddressDto(place);
    }

    autoComplete(text: string): google.maps.places.PlaceResult[] {
        return null;
    }

    calculateLatLngBounds(locations: Location[]): Observable<any> {

        return this.init().pipe(
            map(() => {
                let latLngBounds = new google.maps.LatLngBounds();
                locations.forEach(loc => latLngBounds.extend(new google.maps.LatLng(loc.latitude, loc.longitude)));

                return latLngBounds;
            })
        )
    }


    public mapToAddressDto(place: google.maps.places.PlaceResult): AddressDto {

        if (!place) {
            return null;
        }

        let address = new AddressDto();
        address.placeId = place.place_id;
        address.formatted = place.formatted_address;
        address.location = {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
        };

        const addressComponents: GeocoderAddressComponent[] = place.address_components;

        address.country = this.getAddressComponentLongName(addressComponents, 'country');
        address.streetNumber = this.getAddressComponentLongName(addressComponents, 'street_number');
        address.route = this.getAddressComponentLongName(addressComponents, 'route');
        address.locality = this.getAddressComponentLongName(addressComponents, 'locality');
        address.postalCode = this.getAddressComponentLongName(addressComponents, 'postal_code');

        return address;
    }

    public getAddressComponentLongName(addressComponents: Array<any>, type: string): string {
        let component = this.getAddressComponent(addressComponents, type);
        return component && component['long_name'];
    }

    public getAddressComponent(addressComponents: Array<any>, type: string) {

        return addressComponents.find((element) => {
            return _includes(element.types, type);
        });
    }

}
