import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
var COOKIE_EXPIRY = 30; // 1 month
/**
 * Abstract class for parsing localization
 */
// @Injectable()
var LocalizeParser = /** @class */ (function () {
    /**
     * Loader constructor
     * @param translateService
     * @param location
     * @param settings
     */
    function LocalizeParser(translateService, location) {
        this.translateService = translateService;
        this.location = location;
    }
    /**
     * Initialize language and routes
     * @param routes
     * @returns {Promise<any>}
     */
    LocalizeParser.prototype.init = function (routes) {
        var _this = this;
        this.routes = routes;
        var children = this.routes.slice();
        /** exclude certain routes */
        for (var i = children.length - 1; i >= 0; i--) {
            if (children[i].data && children[i].data['skipRouteLocalization']) {
                children.splice(i, 1);
            }
        }
        /** append children routes */
        if (children && children.length) {
            if (this.locales && this.locales.length > 1) {
                this.locales.forEach(function (l) {
                    _this.routes.unshift({ path: l.shortcut, children: children });
                });
            }
        }
        return of(this.routes).toPromise();
    };
    return LocalizeParser;
}());
export { LocalizeParser };
