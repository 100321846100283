import { Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SeoService } from '../../../core/services';
import { OpenGraphService } from '../../../core/services/open-graph.service';
import { CommonUtils, ErrorUtils, UserProfileUtils, CategoryUtils } from '../../../core/utils';
import * as reviewActions from '../../../core/store/actions/review.action';
import * as fromRoot from '../../../store';
import * as userActions from '../../store/actions/user.view';
import { ProfileViewHelper } from './helper/profile-view.helper';
var ProfileViewComponent = /** @class */ (function () {
    function ProfileViewComponent(store, injector) {
        this.store = store;
        this.injector = injector;
        this.subscription = new Subscription();
        this.titleKey = 'global.profile';
        this.param = {};
        this.UserProfileUtils = UserProfileUtils;
        this.route = this.injector.get(ActivatedRoute);
        this.translateService = this.injector.get(TranslateService);
        this.seo = this.injector.get(SeoService);
        this.ogService = this.injector.get(OpenGraphService);
        this.profileViewHelper = this.injector.get(ProfileViewHelper);
        this.currentUser$ = this.store.pipe(select(fromRoot.getCurrentUser));
        this.loading$ = this.store.pipe(select(fromRoot.isUserViewProfileLoading));
        this.notification$ = this.store.pipe(select(fromRoot.getUserViewProfileErrors), map(ErrorUtils.toNotification));
        this.userReviewsNotification$ = this.store.pipe(select(fromRoot.getReviewsErrors), map(ErrorUtils.toNotification));
        this.userProfile$ = this.store.pipe(select(fromRoot.getUserViewProfile));
        this.reviews$ = this.store.pipe(select(fromRoot.getReviews));
        this.seo.setRobots(true);
    }
    ProfileViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profileViewHelper.hideActionBar();
        this.route.params.subscribe(function (params) {
            if (params.uid) {
                _this.userUid = params.uid;
                _this.store.dispatch(new userActions.LoadUserViewProfileAction(_this.userUid));
                _this.loadUserReviews();
                _this.store.dispatch(new userActions.AddUserProfileViewAction(params.uid));
            }
        });
        var upSubscription = this.userProfile$.pipe(filter(function (up) { return CommonUtils.notEmpty(up); })).subscribe(function (up) {
            _this.userProfile = up;
            _this.titleKey = 'global.profile-of';
            _this.param = { name: up.firstName };
            _this.profileCategories = CategoryUtils.toCategoriesTree(up.activities);
            _this.seo.setUserViewMeta(up);
        });
        var cuSubscription = this.currentUser$.subscribe(function (cu) { return _this.currentUser = cu; });
        this.subscription.add(upSubscription);
        this.subscription.add(cuSubscription);
    };
    ProfileViewComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    ProfileViewComponent.prototype.showMoreActions = function () {
        this.profileViewHelper.showMoreActions(this.userProfile);
    };
    ProfileViewComponent.prototype.loadUserReviews = function () {
        this.store.dispatch(new reviewActions.LoadUserReviewsAction(this.userUid));
    };
    ProfileViewComponent.prototype.getPercentage = function () {
        return this.UserProfileUtils.calculatePercentage(this.userProfile);
    };
    ProfileViewComponent.prototype.getFullName = function () {
        return this.userProfile.firstName + ' ' + this.userProfile.lastName;
    };
    ProfileViewComponent.prototype.getUserReviews = function (user) {
        if (!user || !user.reviewsAverage) {
            return 0;
        }
        return user.reviewsAverage + 1;
    };
    return ProfileViewComponent;
}());
export { ProfileViewComponent };
