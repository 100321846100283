/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./send-message-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../modal/modal.component.ngfactory";
import * as i3 from "../modal/modal.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../shared/components/send-message/send-message.component.ngfactory";
import * as i6 from "../../../../shared/components/send-message/send-message.component";
import * as i7 from "@angular/common";
import * as i8 from "./send-message-modal.component";
import * as i9 from "@ngrx/store";
var styles_SendMessageModalComponent = [i0.styles];
var RenderType_SendMessageModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SendMessageModalComponent, data: {} });
export { RenderType_SendMessageModalComponent as RenderType_SendMessageModalComponent };
export function View_SendMessageModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "app-modal", [["modalClass", "bottom-sheet-modal-height bottom-sheet"], ["modalId", "send-message"]], null, [[null, "ready"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ready" === en)) {
        var pd_0 = (_co.ready() !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.closeModal() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ModalComponent_0, i2.RenderType_ModalComponent)), i1.ɵdid(1, 180224, null, 0, i3.ModalComponent, [], { modalId: [0, "modalId"], modalClass: [1, "modalClass"], modalEventListener: [2, "modalEventListener"] }, { ready: "ready", close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "h1", [["class", "col my-0"], ["modal-header", ""], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["send-message.title"])), (_l()(), i1.ɵeld(5, 0, null, 1, 4, "app-send-message", [["modal-content", ""]], null, [[null, "cancel"], [null, "send"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancel" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } if (("send" === en)) {
        var pd_1 = (_co.send($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_SendMessageComponent_0, i5.RenderType_SendMessageComponent)), i1.ɵdid(6, 114688, null, 0, i6.SendMessageComponent, [i1.ChangeDetectorRef], { sender: [0, "sender"], receiver: [1, "receiver"], init$: [2, "init$"], notification: [3, "notification"], loading$: [4, "loading$"] }, { cancel: "cancel", send: "send" }), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "send-message"; var currVal_1 = "bottom-sheet-modal-height bottom-sheet"; var currVal_2 = _co.messageModalEvent$; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = ""; _ck(_v, 3, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.sender$)); var currVal_5 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 8).transform(_co.receiver$)); var currVal_6 = _co.sendMessageInitListener$; var currVal_7 = i1.ɵunv(_v, 6, 3, i1.ɵnov(_v, 9).transform(_co.notification$)); var currVal_8 = _co.loading$; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_SendMessageModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-send-message-modal", [], null, null, null, View_SendMessageModalComponent_0, RenderType_SendMessageModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.SendMessageModalComponent, [i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SendMessageModalComponentNgFactory = i1.ɵccf("app-send-message-modal", i8.SendMessageModalComponent, View_SendMessageModalComponent_Host_0, {}, {}, []);
export { SendMessageModalComponentNgFactory as SendMessageModalComponentNgFactory };
