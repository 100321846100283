import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Observable} from 'rxjs';

import {Config} from '../../../../config';
import {BasicUserDto, Notification, SendMessageDto} from '../../../core/models';

@Component({
    moduleId: module.id,
    selector: 'app-send-message',
    templateUrl: './send-message.component.html',
    styleUrls: ['./send-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendMessageComponent implements OnInit {

    @ViewChild('messageInput', {static: false}) messageInput: ElementRef;

    @Input() sender: BasicUserDto;
    @Input() receiver: BasicUserDto;
    @Input() init$: Observable<any>;
    @Input() notification: Notification;
    @Input() loading$: Observable<boolean>;

    @Output() cancel = new EventEmitter();
    @Output() send = new EventEmitter<SendMessageDto>();

    sendMessage = new SendMessageDto();

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {

        if (this.init$) {
            this.init$.subscribe(() => {

                this.sendMessage.message = '';
                this.cdRef.detectChanges();

                if (this.messageInput) {

                    if (Config.IS_WEB()) {
                        this.messageInput.nativeElement.focus();
                    }

                }

            });
        }

    }

    doCancel() {
        this.cancel.emit();
    }

    doSubmit() {
        // this.sendMessage.senderUid = this.sender.uid;
        this.sendMessage.receiverUid = this.receiver.uid;

        this.send.emit(this.sendMessage);
    }

}
