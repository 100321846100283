<app-modal modalId="send-message"
           [modalEventListener]="messageModalEvent$"
           modalClass="bottom-sheet-modal-height bottom-sheet"
           (ready)="ready()"
           (close)="closeModal()">

    <h1 class="col my-0" modal-header translate>send-message.title</h1>

    <app-send-message [sender]="sender$ | async"
                      [receiver]="receiver$ | async"
                      [init$]="sendMessageInitListener$"
                      [loading$]="loading$"
                      [notification]="notification$ | async"
                      (cancel)="closeModal()"
                      (send)="send($event)"
                      modal-content>
    </app-send-message>
</app-modal>