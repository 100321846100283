import {passwordRecoverActions} from '../actions';
import {Notification} from '../../models';

export interface State {
    loading: boolean,
    notification: Notification,
    success: boolean
}

export const initialState: State = {
    loading: false,
    notification: null,
    success: false
};

export function reducer(state = initialState, action: passwordRecoverActions.Actions): State {

    switch (action.type) {

        case passwordRecoverActions.PasswordRecoverActionTypes.RecoverPassword: {
            return {
                loading: true,
                notification: null,
                success: false
            }
        }

        case passwordRecoverActions.PasswordRecoverActionTypes.RecoverPasswordSuccess: {
            return {
                loading: false,
                success: true,
                notification: action.payload
            }
        }

        case passwordRecoverActions.PasswordRecoverActionTypes.RecoverPasswordFail: {
            return {
                loading: false,
                success: false,
                notification: action.payload
            }
        }

        case passwordRecoverActions.PasswordRecoverActionTypes.Reset: {
            return Object.assign({}, initialState);
        }

        default: {
            return state;
        }
    }
}

export const isLoading = (state: State): boolean => state.loading;
export const isSuccess = (state: State): boolean => state.success;
export const getNotification = (state: State): Notification => state.notification;
