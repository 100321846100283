import {Component} from '@angular/core';
import {SeoService} from '../../../modules/core/services';

@Component({
    selector: 'yf-cookie-usage',
    templateUrl: './cookie-usage.component.html',
    styleUrls: ['./cookie-usage.component.scss']
})
export class CookieUsageComponent {

    constructor(private seo: SeoService) {
        seo.setMeta('cookies-usage');
        seo.setRobots(true);
    }

}
