export var AccountQuotationActionTypes;
(function (AccountQuotationActionTypes) {
    AccountQuotationActionTypes["LoadQuotations"] = "[Account Quotations] LoadQuotations";
    AccountQuotationActionTypes["LoadQuotationsSuccess"] = "[Account Quotations] LoadQuotationsSuccess";
    AccountQuotationActionTypes["LoadQuotationsFail"] = "[Account Quotations] LoadQuotationsFail";
})(AccountQuotationActionTypes || (AccountQuotationActionTypes = {}));
var LoadQuotations = /** @class */ (function () {
    function LoadQuotations(payload) {
        this.payload = payload;
        this.type = AccountQuotationActionTypes.LoadQuotations;
    }
    return LoadQuotations;
}());
export { LoadQuotations };
var LoadQuotationsSuccess = /** @class */ (function () {
    function LoadQuotationsSuccess(payload) {
        this.payload = payload;
        this.type = AccountQuotationActionTypes.LoadQuotationsSuccess;
    }
    return LoadQuotationsSuccess;
}());
export { LoadQuotationsSuccess };
var LoadQuotationsFail = /** @class */ (function () {
    function LoadQuotationsFail(payload) {
        this.payload = payload;
        this.type = AccountQuotationActionTypes.LoadQuotationsFail;
    }
    return LoadQuotationsFail;
}());
export { LoadQuotationsFail };
