import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Injector } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { CookieService } from 'ngx-cookie-service';
import { Config } from '../../../config';
import * as i0 from "@angular/core";
var BrowserWebService = /** @class */ (function () {
    function BrowserWebService(platformId, injector) {
        this.platformId = platformId;
        this.injector = injector;
        this.cookieService = injector.get(CookieService);
    }
    BrowserWebService.prototype.materialize = function () {
        if (this.isPlatformBrowser()) {
            if (Config.IS_WEB()) {
                $(document).ready(function () { return Materialize.updateTextFields(); });
            }
        }
    };
    BrowserWebService.prototype.materializeWithDelay = function (delay) {
        if (Config.IS_WEB()) {
            $(document).ready(function () {
                return setTimeout(function () {
                    Materialize.updateTextFields();
                }, 10);
            });
        }
    };
    BrowserWebService.prototype.scrollToTop = function () {
        if (Config.IS_WEB()) {
            window.scrollTo(0, 0);
        }
    };
    BrowserWebService.prototype.isPlatformBrowser = function () {
        return isPlatformBrowser(this.platformId);
    };
    BrowserWebService.prototype.isPlatformServer = function () {
        return isPlatformServer(this.platformId);
    };
    BrowserWebService.prototype.getServerRequestContext = function () {
        if (this.isPlatformServer()) {
            return this.injector.get(REQUEST);
        }
        return null;
    };
    BrowserWebService.prototype.setCookie = function (key, value, expires, path, domain, secure) {
        if (this.isPlatformBrowser()) {
            this.cookieService.set(key, value, expires, path, domain, secure);
        }
    };
    BrowserWebService.prototype.getCookie = function (key) {
        if (this.isPlatformBrowser()) {
            return this.cookieService.get(key);
        }
        return null;
    };
    BrowserWebService.prototype.alertNotification = function (notif) {
        alert(notif.title);
    };
    BrowserWebService.prototype.hideIndicator = function () {
    };
    BrowserWebService.prototype.back = function () {
    };
    BrowserWebService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrowserWebService_Factory() { return new BrowserWebService(i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i0.INJECTOR)); }, token: BrowserWebService, providedIn: "root" });
    return BrowserWebService;
}());
export { BrowserWebService };
