import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import * as authenticationActions from '../../modules/core/store/actions/authentication.action';
import * as routeActions from '../../modules/core/store/actions/route.action';
import { SessionDto } from '../../modules/core/models/index';
import { BrowserService } from '../../modules/core/services';
import { AuthService } from '../../modules/core/services/auth.service';
var PostSocialSignInComponent = /** @class */ (function () {
    function PostSocialSignInComponent(store, authService, router, browserService, cookieService) {
        this.store = store;
        this.authService = authService;
        this.router = router;
        this.browserService = browserService;
        this.cookieService = cookieService;
        this.subscription = new Subscription();
        this.POST_SIGN_IN_COOKIE = 'JWT-TOKEN';
        this.POST_SIGN_IN_COOKIE_EXP_DATE = 'JWT-TOKEN-EXP-DATE';
    }
    PostSocialSignInComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.browserService.isPlatformBrowser()) {
            var authSub = this.authService.getCurrent()
                .subscribe(function (userAccount) {
                var session = new SessionDto();
                session.user = userAccount;
                _this.store.dispatch(new authenticationActions.AuthenticateSuccessAction(userAccount));
                if (!userAccount.gender || !userAccount.filled) {
                    _this.store.dispatch(new routeActions.ToCompleteUserProfileAction());
                }
                else {
                    _this.store.dispatch(new routeActions.ToUserProfileAction());
                }
                _this.cookieService.delete(_this.POST_SIGN_IN_COOKIE);
                _this.cookieService.delete(_this.POST_SIGN_IN_COOKIE_EXP_DATE);
            }, function (e) {
                _this.router.navigate(['login'], { queryParams: { error: true } });
            });
            this.subscription.add(authSub);
        }
    };
    PostSocialSignInComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    return PostSocialSignInComponent;
}());
export { PostSocialSignInComponent };
