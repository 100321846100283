import {MapsAPILoader} from '@agm/core';
import {Directive, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {Config} from '../../../config';
import {AddressDto} from '../../core/models';
import {AddressUtils} from '../../core/utils';

@Directive({
    selector: '[appAddressAutoComplete]'
})
export class AddressAutoCompleteDirective implements OnInit {

    @Output() selectAddress = new EventEmitter<AddressDto>();

    constructor(private mapsAPILoader: MapsAPILoader,
                private el: ElementRef) {
    }

    ngOnInit() {

        this.mapsAPILoader.load().then(() => {

            let autoComplete = new google.maps.places.Autocomplete(this.el.nativeElement, {
                types: ['geocode'],
                componentRestrictions: {country: Config.PLACES_COUNTRY_RESTRICTIONS}
            });

            autoComplete.addListener('place_changed', () => {
                let ad = AddressUtils.buildFromGooglePlaceResult(autoComplete.getPlace());
                this.selectAddress.emit(ad);
            });
        });
    }

}
