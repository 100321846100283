import { TranslateService } from '@ngx-translate/core';
import { ErrorUtils } from '../utils';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var phonenumbers = require('google-libphonenumber');
var ValidationService = /** @class */ (function () {
    function ValidationService(translateService) {
        this.translateService = translateService;
    }
    ValidationService.isEmailValid = function (email) {
        var re = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
        return re.test(email);
    };
    ValidationService.isPasswordValid = function (password) {
        return password && password.length > 5;
    };
    ValidationService.isPhoneNumber = function (phone) {
        var phoneUtil = phonenumbers.PhoneNumberUtil.getInstance();
        if (!phone) {
            return false;
        }
        try {
            if (phone.startsWith('+') || phone.startsWith('00')) {
                if (phone.startsWith('00')) {
                    phone = '+' + phone.substring(2, phone.length);
                }
                var parsedNumber = phoneUtil.parse(phone);
                var regionCode = phoneUtil.getRegionCodeForNumber(parsedNumber);
                return phoneUtil.isValidNumberForRegion(parsedNumber, regionCode);
            }
            else {
                var parsedNumber = phoneUtil.parse(phone, 'MA');
                return phoneUtil.isValidNumber(parsedNumber);
            }
        }
        catch (e) {
            return false;
        }
    };
    ValidationService.isLoginValid = function (login) {
        if (!login) {
            return false;
        }
        login = login.replace(/\s/g, '');
        return ValidationService.isEmailValid(login) || ValidationService.isPhoneNumber(login);
    };
    ValidationService.prototype.validateEmail = function (email) {
        var err = null;
        if (!ValidationService.isEmailValid(email)) {
            err = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.email.invalid'));
        }
        return err;
    };
    ValidationService.prototype.buildValidationErrorsDto = function () {
        return ErrorUtils.buildErrorsDto(this.translateService.instant('errors.validation'));
    };
    ValidationService.prototype.buildFormValidationErrorsDto = function () {
        var errors = this.buildValidationErrorsDto();
        ErrorUtils.addError(errors, this.translateService.instant('errors.form.invalid'));
        return errors;
    };
    ValidationService.prototype.validateUserLoginForm = function (userLoginForm) {
        var err = null;
        var isEmailValid = ValidationService.isLoginValid(userLoginForm.login);
        var isPasswordValid = ValidationService.isPasswordValid(userLoginForm.password);
        if (!isEmailValid || !isPasswordValid) {
            err = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.credentials.incorrect'));
        }
        return err;
    };
    ValidationService.prototype.validateUserRegistrationForm = function (userRegistration) {
        var err = null;
        var errors = [];
        if (!ValidationService.isLoginValid(userRegistration.email)) {
            errors.push(ErrorUtils.buildErrorDto(this.translateService.instant('errors.login.invalid')));
        }
        if (!ValidationService.isPasswordValid(userRegistration.password)) {
            errors.push(ErrorUtils.buildErrorDto(this.translateService.instant('errors.password.invalid')));
        }
        if (!userRegistration.acceptTermsOfService) {
            errors.push(ErrorUtils.buildErrorDto(this.translateService.instant('errors.cgu')));
        }
        if (errors.length) {
            err = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.validation'));
            err.errors = errors;
        }
        return err;
    };
    ValidationService.prototype.validatePasswordResetDto = function (passwordReset) {
        var err;
        var errors = [];
        if (!ValidationService.isPasswordValid(passwordReset.newPassword)) {
            errors.push(ErrorUtils.buildErrorDto(this.translateService.instant('errors.password.invalid')));
        }
        if (passwordReset.newPassword !== passwordReset.newPasswordConfirmation) {
            errors.push(ErrorUtils.buildErrorDto(this.translateService.instant('errors.password.match')));
        }
        if (errors.length) {
            err = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.validation'));
            err.errors = errors;
        }
        return err;
    };
    ValidationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValidationService_Factory() { return new ValidationService(i0.ɵɵinject(i1.TranslateService)); }, token: ValidationService, providedIn: "root" });
    return ValidationService;
}());
export { ValidationService };
