import {Action} from '@ngrx/store';

export enum AccountPwdChangeModalActionTypes {
    OpenModal = '[PASSWORD CHANGE MODAL] OPEN_MODAL',
    CloseModal = '[PASSWORD CHANGE MODAL] CLOSE_MODAL'
}

export const OPEN_MODAL = '[PASSWORD CHANGE MODAL] OPEN_MODAL';
export const CLOSE_MODAL = '[PASSWORD CHANGE MODAL] CLOSE_MODAL';

export class OpenModalAction implements Action {
    readonly type = AccountPwdChangeModalActionTypes.OpenModal;
}

export class CloseModalAction implements Action {
    readonly type = AccountPwdChangeModalActionTypes.CloseModal;
}

export type Actions = OpenModalAction
    | CloseModalAction;
