export var WorkersPromotionActionTypes;
(function (WorkersPromotionActionTypes) {
    WorkersPromotionActionTypes["LoadPromotionWorkers"] = "[WORKERS PROMOTION] LOAD_WORKERS_AROUND";
    WorkersPromotionActionTypes["LoadPromotionWorkersSuccess"] = "[WORKERS PROMOTION] LOAD_PROMOTION_WORKERS_SUCCESS";
    WorkersPromotionActionTypes["LoadPromotionWorkersFail"] = "[WORKERS PROMOTION] LOAD_PROMOTION_WORKERS_FAIL";
    WorkersPromotionActionTypes["LoadPromotionWorkersIfNotLoaded"] = "[WORKERS PROMOTION] LOAD_PROMOTION_WORKERS_IF_NOT_LOADED";
})(WorkersPromotionActionTypes || (WorkersPromotionActionTypes = {}));
var LoadPromotionWorkersIfNotLoadedAction = /** @class */ (function () {
    function LoadPromotionWorkersIfNotLoadedAction(payload) {
        this.payload = payload;
        this.type = WorkersPromotionActionTypes.LoadPromotionWorkersIfNotLoaded;
    }
    return LoadPromotionWorkersIfNotLoadedAction;
}());
export { LoadPromotionWorkersIfNotLoadedAction };
var LoadPromotionWorkersAction = /** @class */ (function () {
    function LoadPromotionWorkersAction(payload) {
        this.payload = payload;
        this.type = WorkersPromotionActionTypes.LoadPromotionWorkers;
    }
    return LoadPromotionWorkersAction;
}());
export { LoadPromotionWorkersAction };
var LoadPromotionWorkersSuccessAction = /** @class */ (function () {
    function LoadPromotionWorkersSuccessAction(payload) {
        this.payload = payload;
        this.type = WorkersPromotionActionTypes.LoadPromotionWorkersSuccess;
    }
    return LoadPromotionWorkersSuccessAction;
}());
export { LoadPromotionWorkersSuccessAction };
var LoadPromotionWorkersFailAction = /** @class */ (function () {
    function LoadPromotionWorkersFailAction(payload) {
        this.payload = payload;
        this.type = WorkersPromotionActionTypes.LoadPromotionWorkersFail;
    }
    return LoadPromotionWorkersFailAction;
}());
export { LoadPromotionWorkersFailAction };
