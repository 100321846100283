import {Subject} from 'rxjs';
import {ActivityDto, CategoryDto} from '../../models/rest-api.model';

import {searchActivityChooserActions} from '../actions';
import {CategoryUtils} from '../../utils/category.util';

export interface State {
    activities: ActivityDto[],
    selectedCategories: CategoryDto[],
    selectedActivities: ActivityDto[],
    initListener: Subject<any>
}

export const initialState: State = {
    activities: [],
    selectedCategories: [],
    selectedActivities: [],
    initListener: new Subject()
};

export function reducer(state = initialState, action: searchActivityChooserActions.Actions): State {

    switch (action.type) {

        case searchActivityChooserActions.SearchActivityChooserActionTypes.SelectActivity: {
            const newState = Object.assign({}, state);
            newState.selectedActivities.push(action.payload);

            return newState;
        }

        case searchActivityChooserActions.SearchActivityChooserActionTypes.SelectCategory: {
            const newState = Object.assign({}, state);
            newState.selectedCategories.push(action.payload);

            return newState;
        }

        case searchActivityChooserActions.SearchActivityChooserActionTypes.SetActivities: {
            return Object.assign(
                {},
                state,
                {activities: action.payload});
        }

        case searchActivityChooserActions.SearchActivityChooserActionTypes.SetSelectedActivities: {
            return Object.assign(
                {},
                state,
                {
                    selectedActivities: action.payload,
                    selectedCategories: CategoryUtils.getCategoriesOfActivities(action.payload)
                });
        }

        case searchActivityChooserActions.SearchActivityChooserActionTypes.SetSelectedCategories: {
            return Object.assign(
                {},
                state,
                {selectedCategories: action.payload});
        }

        case searchActivityChooserActions.SearchActivityChooserActionTypes.SelectOrDeselectActivity: {

            let found = state.selectedActivities.find(a => a.id === action.payload.id);

            let newSelectedActivities;
            if (found) {
                newSelectedActivities = state.selectedActivities.filter(a => a.id !== action.payload.id);
            } else {
                newSelectedActivities = [...state.selectedActivities, action.payload];
            }

            return Object.assign(
                {},
                state,
                {
                    selectedActivities: newSelectedActivities,
                    selectedCategories: CategoryUtils.getCategoriesOfActivities(newSelectedActivities)
                }
            );
        }

        case searchActivityChooserActions.SearchActivityChooserActionTypes.Init: {
            state.initListener.next();
            return state;
        }

        case searchActivityChooserActions.SearchActivityChooserActionTypes.Reset: {
            return {
                activities: [],
                selectedCategories: [],
                selectedActivities: [],
                initListener: new Subject()
            }
        }

        default: {
            return state;
        }
    }
}

// Selectors
export const getActivities = (state: State): ActivityDto[] => state.activities;
export const getSelectedActivities = (state: State): ActivityDto[] => state.selectedActivities;
export const getSelectedCategories = (state: State): CategoryDto[] => state.selectedCategories;
