export var UserReportActionTypes;
(function (UserReportActionTypes) {
    UserReportActionTypes["ReportUser"] = "[REPORT USER] REPORT_USER";
    UserReportActionTypes["ReportUserSuccess"] = "[REPORT USER] REPORT_USER_SUCCESS";
    UserReportActionTypes["ReportUserFail"] = "[REPORT USER] REPORT_USER_FAIL";
    UserReportActionTypes["OpenModal"] = "[REPORT USER] OPEN_MODAL";
    UserReportActionTypes["CloseModal"] = "[REPORT USER] CLOSE_MODAL";
    UserReportActionTypes["SetUser"] = "[REPORT USER] SET_USER";
    UserReportActionTypes["Reset"] = "[REPORT USER] RESET";
})(UserReportActionTypes || (UserReportActionTypes = {}));
var ReportUserAction = /** @class */ (function () {
    function ReportUserAction(payload) {
        this.payload = payload;
        this.type = UserReportActionTypes.ReportUser;
    }
    return ReportUserAction;
}());
export { ReportUserAction };
var ReportUserSuccessAction = /** @class */ (function () {
    function ReportUserSuccessAction() {
        this.type = UserReportActionTypes.ReportUserSuccess;
    }
    return ReportUserSuccessAction;
}());
export { ReportUserSuccessAction };
var ReportUserFailAction = /** @class */ (function () {
    function ReportUserFailAction(payload) {
        this.payload = payload;
        this.type = UserReportActionTypes.ReportUserFail;
    }
    return ReportUserFailAction;
}());
export { ReportUserFailAction };
var OpenModalAction = /** @class */ (function () {
    function OpenModalAction(payload) {
        this.payload = payload;
        this.type = UserReportActionTypes.OpenModal;
    }
    return OpenModalAction;
}());
export { OpenModalAction };
var CloseModalAction = /** @class */ (function () {
    function CloseModalAction() {
        this.type = UserReportActionTypes.CloseModal;
    }
    return CloseModalAction;
}());
export { CloseModalAction };
var SetUserAction = /** @class */ (function () {
    function SetUserAction(payload) {
        this.payload = payload;
        this.type = UserReportActionTypes.SetUser;
    }
    return SetUserAction;
}());
export { SetUserAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = UserReportActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
