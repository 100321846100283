import {Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ObservableArray} from 'tns-core-modules/data/observable-array';
import {Config} from '../../../../config';
import {
    ListMode,
    Location,
    ModalCallback,
    ModalEvent,
    Notification,
    Page,
    Pageable,
    SearchCriteriaDto,
    UserResultDto
} from '../../../core/models';
import {ActivityService, AddressService, BrowserService, RouteService, SeoService} from '../../../core/services';
import {CommonUtils, ErrorUtils} from '../../../core/utils';

import * as fromRoot from '../../../store';
import * as searchActions from '../../store/actions/user.search';
import {UserParamUtils} from '../../utils';
import {UsersSearchHelper} from './helper/users-search.helper';

@Component({
    moduleId: module.id,
    templateUrl: './users-search.component.html',
    styleUrls: ['./users-search.component.scss'],
    providers: [UsersSearchHelper]
})
export class UsersSearchComponent implements OnInit, OnDestroy {

    protected subscription = new Subscription();

    listMode = ListMode.LIST;
    ListMode = ListMode;
    searchCriteria: SearchCriteriaDto;
    pageable: Pageable;
    latLngBounds: any;

    page$: Observable<Page<UserResultDto>>;
    currentPage: Page<UserResultDto>;
    loading$: Observable<boolean>;
    notification$: Observable<Notification>;
    filterModalEventListener$ = new BehaviorSubject<ModalEvent>(ModalEvent.CLOSE);
    hasResult: boolean;
    totalElements: number;
    titleKey: string;
    _loading = true;

    constructor(private store: Store<fromRoot.State>,
                private userSearchHelper: UsersSearchHelper,
                private route: ActivatedRoute,
                private router: Router,
                private addressService: AddressService,
                private activityService: ActivityService,
                private routeService: RouteService,
                private translateService: TranslateService,
                private seo: SeoService,
                private browserService: BrowserService,
                private vcRef: ViewContainerRef) {
        this.page$ = this.store.pipe(select(fromRoot.getSearchResultPage));
        this.loading$ = this.store.pipe(select(fromRoot.isSearchLoading));
        this.notification$ = this.store.pipe(
            select(fromRoot.getSearchErrors),
            map(ErrorUtils.toNotification)
        );

        this.seo.setRobots(true);

        this.pageable = userSearchHelper.initPageable();
    }

    ngOnInit() {
        this.userSearchHelper.clearRoute();
        this.userSearchHelper.initWorkers$();

        this.route.queryParams.subscribe(params => {
            this.initSearchCriteria(params);
            this.load();
        });

        let pageSub = this.page$.pipe(filter(p => CommonUtils.notEmpty(p)))
            .subscribe(page => {
                this.initPage(page);

                if (Config.IS_MOBILE_NATIVE()) {
                    this.userSearchHelper.pushPage(page);
                    this._loading = false;
                }
            });

        let notifSub = this.notification$.pipe(
            filter(CommonUtils.notEmpty))
            .subscribe(() => {
                this._loading = false;
            });

        this.subscription.add(pageSub);
        this.subscription.add(notifSub);
        this.subscription.add(this.filterModalEventListener$);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.store.dispatch(new searchActions.ResetAction());
    }

    private initSearchCriteria(params) {
        this.searchCriteria = UserParamUtils.paramsToSearchCriteria(params);
        this.pageable = CommonUtils.fillPageable(params, this.pageable);

        if (CommonUtils.notEmpty(this.searchCriteria)) {
            this.userSearchHelper.initWorkers$();
            this.userSearchHelper.setLoadOnDemandeToAuto();

            if (this.searchCriteria.activity) {
                this.activityService.getActivityById(this.searchCriteria.activity.id).subscribe(ac => this.searchCriteria.activity = ac);
            }
        }
    }

    get dataItems(): ObservableArray<any> {
        return this.userSearchHelper.dataItems;
    }

    loadMoreData(args: any) {

        if (!this.currentPage.last) {
            this.pageable.page++;
            this.load();
        } else {
            this.userSearchHelper.lastPageLoaded(args);
        }

        this.userSearchHelper.setArgsReturnValue();
    }

    showServiceDialog() {

        this.userSearchHelper.showServiceDialog(this.vcRef).subscribe((callback: ModalCallback<SearchCriteriaDto>) => {
            if (callback && callback.isClose()) {
                return;
            }

            this.searchCriteria = callback.payload;
            this.pageable = new Pageable();
            this._loading = true;
            this.userSearchHelper.initWorkers$();
            this.userSearchHelper.setLoadOnDemandeToAuto();

            if (this.searchCriteria.activity) {
                this.activityService.getActivityById(this.searchCriteria.activity.id).subscribe(ac => this.searchCriteria.activity = ac);
            }

            this.load();
        });
    }

    private initPage(page) {
        this.currentPage = page;
        this.totalElements = page.totalElements;
        this.titleKey = this.getTitleKey();

        if (Config.IS_WEB()) {
            this.setSeo(this.totalElements);
            this.setLatLngBounds();
            this.hasResult = this.currentPage.content ? this.currentPage.content.length > 0 : false;
        }
    }

    load() {
        if (this.searchCriteria) {
            this.store.dispatch(new searchActions.SearchAction({
                searchCriteria: this.searchCriteria,
                pageable: this.pageable
            }));
        } else {
            this.store.dispatch(new searchActions.SearchAroundIPOrLastAction(this.pageable));
        }
    }

    navigateToWorker(userUid: string) {
        this.routeService.toWorker(userUid);
    }

    search(searchCriteria: SearchCriteriaDto) {
        if (this.filterModalEventListener$.getValue() === ModalEvent.OPEN) {
            this.filterModalEventListener$.next(ModalEvent.CLOSE);
        }

        this.navigate(searchCriteria);
    }

    loadPage(pageNumber: number) {
        this.pageable.page = pageNumber;

        this.navigate(this.searchCriteria, this.pageable)
    }

    navigate(criteria: SearchCriteriaDto, pageable?: Pageable) {
        let navigationExtras: NavigationExtras = {
            queryParams: UserParamUtils.toParams(criteria, pageable),
            relativeTo: this.route
        };

        this.router.navigate([], navigationExtras);
    }

    selectListMode(mode: ListMode) {
        this.listMode = mode;
    }

    doOpenFilterModal() {
        this.filterModalEventListener$.next(ModalEvent.OPEN);
    }

    doCloseFilterModal() {
        this.filterModalEventListener$.next(ModalEvent.CLOSE);
    }

    private setLatLngBounds() {
        if (!Config.IS_WEB() && !this.browserService.isPlatformBrowser()) {
            return;
        }

        if (!this.currentPage || !this.currentPage.content) {
            return;
        }

        let locations: Location[] = this.currentPage.content
            .filter(user => CommonUtils.notEmpty(user.address))
            .map(user => user.address.location);

        this.addressService.calculateLatLngBounds(locations).subscribe(latLng => this.latLngBounds = latLng);
    }

    distanceText(distance: number) {
        return this.translateService.instant('global.in-distance-of', {distance: distance});
    }

    getTitleKey() {
        if (!this.currentPage) {
            return '';
        }

        if (!this.searchCriteria) {
            return 'users-search.last-title';
        }

        return this.currentPage.totalElements === 1 ? 'users-search.around-title' : 'users-search.around-title-plural';
    }

    getFullName(user: UserResultDto) {
        return user.firstName + ' ' + user.lastName;
    }

    getUserReviews(user: UserResultDto) {
        if (!user || !user.reviewsAverage) {
            return 0;
        }

        return user.reviewsAverage + 1;
    }

    // TODO add open graph tags
    private setSeo(resultCount: number) {
        if (this.searchCriteria && this.searchCriteria.activity && this.searchCriteria.address) {
            this.seo.setMeta('users-search-criteria', {
                count: resultCount,
                activity: this.searchCriteria.activity.frName,
                address: this.searchCriteria.address.formatted
            });
        } else {
            this.seo.setMeta('users-search');
        }
    }

    get loading() {
        return this._loading;
    }
}
