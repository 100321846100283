import {Location} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'app-go-back',
    templateUrl: './go-back.component.html',
    styleUrls: ['./go-back.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoBackComponent {

    constructor(private location: Location) {
    }

    back() {
        this.location.back();
    }

}
