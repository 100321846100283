import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AddressDto, IAddressService, Location} from '../models';

@Injectable({
    providedIn: 'root'
})
export abstract class AddressService implements IAddressService {

    abstract init(): Observable<any>;

    abstract mapAddress(place: any): AddressDto;

    abstract autoComplete(text: string): any[];

    abstract calculateLatLngBounds(locations: Location[]): Observable<any>;
}
