import {Directive, HostListener, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {ProfileDto, RequestDto} from '../../core/models';
import {shareActions} from '../../core/store/actions';

import * as fromRoot from '../../store';

@Directive({
    selector: '[appShare]'
})
export class ShareDirective {

    @Input() appShare: ProfileDto | RequestDto;

    constructor(private store: Store<fromRoot.State>) {
    }

    @HostListener('click')
    share() {
        this.store.dispatch(new shareActions.ShareAction(this.appShare));
    }

}
