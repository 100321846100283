export var RequestPromotionActionTypes;
(function (RequestPromotionActionTypes) {
    RequestPromotionActionTypes["LoadPromotionRequests"] = "[REQUEST PROMOTION] LOAD_REQUESTS_AROUND";
    RequestPromotionActionTypes["LoadPromotionRequestsSuccess"] = "[REQUEST PROMOTION] LOAD_PROMOTION_REQUESTS_SUCCESS";
    RequestPromotionActionTypes["LoadPromotionRequestsFail"] = "[REQUEST PROMOTION] LOAD_PROMOTION_REQUESTS_FAIL";
    RequestPromotionActionTypes["LoadPromotionRequestsIfNotLoaded"] = "[REQUEST PROMOTION] LOAD_PROMOTION_REQUESTS_IF_NOT_LOADED";
})(RequestPromotionActionTypes || (RequestPromotionActionTypes = {}));
var LoadPromotionRequestsIfNotLoadedAction = /** @class */ (function () {
    function LoadPromotionRequestsIfNotLoadedAction(payload) {
        this.payload = payload;
        this.type = RequestPromotionActionTypes.LoadPromotionRequestsIfNotLoaded;
    }
    return LoadPromotionRequestsIfNotLoadedAction;
}());
export { LoadPromotionRequestsIfNotLoadedAction };
var LoadPromotionRequestsAction = /** @class */ (function () {
    function LoadPromotionRequestsAction(payload) {
        this.payload = payload;
        this.type = RequestPromotionActionTypes.LoadPromotionRequests;
    }
    return LoadPromotionRequestsAction;
}());
export { LoadPromotionRequestsAction };
var LoadPromotionRequestsSuccessAction = /** @class */ (function () {
    function LoadPromotionRequestsSuccessAction(payload) {
        this.payload = payload;
        this.type = RequestPromotionActionTypes.LoadPromotionRequestsSuccess;
    }
    return LoadPromotionRequestsSuccessAction;
}());
export { LoadPromotionRequestsSuccessAction };
var LoadPromotionRequestsFailAction = /** @class */ (function () {
    function LoadPromotionRequestsFailAction(payload) {
        this.payload = payload;
        this.type = RequestPromotionActionTypes.LoadPromotionRequestsFail;
    }
    return LoadPromotionRequestsFailAction;
}());
export { LoadPromotionRequestsFailAction };
