import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CategoryService = /** @class */ (function () {
    function CategoryService(httpClient) {
        this.httpClient = httpClient;
    }
    CategoryService.prototype.getCategories = function () {
        return this.httpClient.get(CategoryService.CATEGORIES);
    };
    CategoryService.CATEGORIES = 'api/v1/categories';
    CategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryService_Factory() { return new CategoryService(i0.ɵɵinject(i1.HttpClient)); }, token: CategoryService, providedIn: "root" });
    return CategoryService;
}());
export { CategoryService };
