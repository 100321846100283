import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter} from 'rxjs/operators';

import * as fromRoot from '../../store';
import {ActivityDto, Locale} from '../models';

@Injectable({
    providedIn: 'root'
})
export class ActivityTranslatorService {

    static arNameGetter = ActivityTranslatorService.getArNameGetter();
    static enNameGetter = ActivityTranslatorService.getEnNameGetter();
    static maNameGetter = ActivityTranslatorService.getMaNameGetter();
    static frNameGetter = ActivityTranslatorService.getFrNameGetter();

    nameGetter = ActivityTranslatorService.frNameGetter;

    private static getArNameGetter() {
        const fn = (activity: ActivityDto) => activity.arName;
        return fn;
    }

    private static getFrNameGetter() {
        const fn = (activity: ActivityDto) => activity.name;
        return fn;
    }

    private static getEnNameGetter() {
        const fn = (activity: ActivityDto) => activity.enName;
        return fn;
    }

    private static getMaNameGetter() {
        const fn = (activity: ActivityDto) => activity.maName;
        return fn;
    }

    constructor(private store: Store<fromRoot.State>) {
        this.store.pipe(select(fromRoot.getLocale), filter(locale => locale != null))
            .subscribe(locale => {

                if (!locale) {
                    locale = Locale.ENGLISH;
                }

                switch (locale.shortcut.toLowerCase()) {
                    case Locale.FRENCH.shortcut:
                        this.nameGetter = ActivityTranslatorService.frNameGetter;
                        break;
                    case Locale.ENGLISH.shortcut:
                        this.nameGetter = ActivityTranslatorService.enNameGetter;
                        break;
                    case Locale.ARABIC.shortcut:
                        this.nameGetter = ActivityTranslatorService.arNameGetter;
                        break;
                    case Locale.MOROCCAN.shortcut:
                        this.nameGetter = ActivityTranslatorService.maNameGetter;
                        break;
                }
            })
    }

    translate(value: ActivityDto): string {
        return this.nameGetter(value);
    }

    translateArray(value: ActivityDto[]): string[] {
        return value.map(act => this.translate(act));
    }

    getDescription(activities: ActivityDto[]) {
        let description = '';

        if (activities) {
            let length = activities.length;

            description = this.getName(activities[0]);
            for (let i = 1; i < 3; ++i) {
                if (i < length) {
                    description += `, ${this.getName(activities[i])}`;
                }
            }

            if (length > 3) {
                description += length - 3 + ' autres services';
            }
        }

        return description;
    }

    private getName(act: ActivityDto): string {
        return this.nameGetter(act);
    }

}
