import {Action} from '@ngrx/store';
import {Notification} from '../../models';

export enum PasswordRecoverActionTypes {
    RecoverPassword = '[Password] RECOVER_PASSWORD',
    RecoverPasswordSuccess = '[Password] RECOVER_PASSWORD_SUCCESS',
    RecoverPasswordFail = '[Password] RECOVER_PASSWORD_FAIL',

    Reset = '[Password] RESET'
}

export class RecoverPasswordAction implements Action {
    readonly type = PasswordRecoverActionTypes.RecoverPassword;

    constructor(public payload: string) {
    }
}

export class RecoverPasswordSuccessAction implements Action {
    readonly type = PasswordRecoverActionTypes.RecoverPasswordSuccess;

    constructor(public payload: Notification) {
    }
}

export class RecoverPasswordFailAction implements Action {
    readonly type = PasswordRecoverActionTypes.RecoverPasswordFail;

    constructor(public payload: Notification) {
    }
}

export class ResetAction implements Action {
    readonly type = PasswordRecoverActionTypes.Reset;
}

export type Actions = RecoverPasswordAction
    | RecoverPasswordSuccessAction
    | RecoverPasswordFailAction
    | ResetAction
    ;
