
export class AccountActivationDto {
    userUid: string;
    token: string
}

export class EmailVerificationDto {
    userUid: string;
    token: string
}
