export var QuotationEditActionTypes;
(function (QuotationEditActionTypes) {
    QuotationEditActionTypes["Save"] = "[QuotationEdit] Save";
    QuotationEditActionTypes["SaveSuccess"] = "[QuotationEdit] SaveSuccess";
    QuotationEditActionTypes["SaveFail"] = "[QuotationEdit] SaveFail";
    QuotationEditActionTypes["Delete"] = "[QuotationEdit] Delete";
    QuotationEditActionTypes["DeleteSuccess"] = "[QuotationEdit] DeleteSuccess";
    QuotationEditActionTypes["DeleteFail"] = "[QuotationEdit] DeleteFail";
})(QuotationEditActionTypes || (QuotationEditActionTypes = {}));
var Save = /** @class */ (function () {
    function Save(payload) {
        this.payload = payload;
        this.type = QuotationEditActionTypes.Save;
    }
    return Save;
}());
export { Save };
var SaveSuccess = /** @class */ (function () {
    function SaveSuccess(payload) {
        this.payload = payload;
        this.type = QuotationEditActionTypes.SaveSuccess;
    }
    return SaveSuccess;
}());
export { SaveSuccess };
var SaveFail = /** @class */ (function () {
    function SaveFail(payload) {
        this.payload = payload;
        this.type = QuotationEditActionTypes.SaveFail;
    }
    return SaveFail;
}());
export { SaveFail };
var Delete = /** @class */ (function () {
    function Delete(payload) {
        this.payload = payload;
        this.type = QuotationEditActionTypes.Delete;
    }
    return Delete;
}());
export { Delete };
var DeleteSuccess = /** @class */ (function () {
    function DeleteSuccess() {
        this.type = QuotationEditActionTypes.DeleteSuccess;
    }
    return DeleteSuccess;
}());
export { DeleteSuccess };
var DeleteFail = /** @class */ (function () {
    function DeleteFail(payload) {
        this.payload = payload;
        this.type = QuotationEditActionTypes.DeleteFail;
    }
    return DeleteFail;
}());
export { DeleteFail };
