import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DisplayRequestDto, Pageable, PromotionResultDto, UserResultDto} from '../../core/models';
// app
import {HttpUtil} from '../../core/utils';

@Injectable({
    providedIn: 'root'
})
export class PromotionService {

    private static REQUESTS_SEARCH = 'api/v1/search/requests';
    private static WORKERS_SEARCH = 'api/v1/search/workers';

    constructor(private httpClient: HttpClient) {
    }

    searchRequestsAroundIPOrLast(pageable: Pageable): Observable<PromotionResultDto<DisplayRequestDto>> {
        let requestOptions = HttpUtil.buildPageableOptions(pageable);
        return this.httpClient.get<PromotionResultDto<DisplayRequestDto>>(PromotionService.REQUESTS_SEARCH, requestOptions);
    }

    searchWorkersAroundIPOrLast(pageable: Pageable): Observable<PromotionResultDto<UserResultDto>> {
        let requestOptions = HttpUtil.buildPageableOptions(pageable);
        return this.httpClient.get<PromotionResultDto<UserResultDto>>(PromotionService.WORKERS_SEARCH, requestOptions);
    }

}
