import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SendMessageDto} from '../models';
import {HttpUtil} from '../utils';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    constructor(private httpClient: HttpClient) {
    }

    sendMessage(userUid: string, message: SendMessageDto) {
        return this.httpClient.post(HttpUtil.buildAndExpand('api/v1/users/{userUid}/conversations', userUid), message);
    }
}
