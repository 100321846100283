<div *ngIf="!(loading$ | async) as loading; else spinner">

    <div *ngIf="workers" class="center">

        <h1 class="h3 center text-white my-4" yfDir translate>{{titleKey}}</h1>

        <div class="row">
            <div class="col-12">
                <ul class="worker-promo">
                    <li class="worker-promo-item left card" *ngFor="let worker of workers" (click)="navigateToWorker(worker)">
                        <div class="card-block py-2">
                            <div class="row center px-1 mb-0" style="height: 100%;">
                                <div class="col-12 mb-1">
                                    <img [appUserImg]="worker" class="img-thumbnail rounded-circle home-user-pic"
                                         alt="image">
                                </div>
                                <div class="col-12">
                                    <span class="r-user-name">{{worker.firstName}}</span>
                                </div>
                                <div class="col-12 r-address">
                                    {{worker.address?.formatted}}
                                </div>
                                <div class="col-12 mt-2">
                                    <app-chips [data]="worker.activities | activityTranslator"
                                               [limitDisplaySize]="2"
                                               [displayOverflow]="true"
                                               [fromString]="true">
                                    </app-chips>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </div>

</div>

<ng-template #spinner>
    <div class="valign-wrapper" style="height: 200px;">
        <div class="preloader-wrapper big active center-block">
            <div class="spinner-layer" style="border-color: white">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div>
                <div class="gap-patch">
                    <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
