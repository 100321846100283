import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter} from 'rxjs/operators';

import {localeActions} from '../../../core/store/actions';
import {Locale} from '../../../core/models';
import {CommonUtils} from '../../../core/utils';
import * as fromRoot from '../../../store';

@Component({
    selector: 'yf-locale-selector',
    templateUrl: './locale-selector.component.html',
    styleUrls: ['./locale-selector.component.scss']
})
export class LocaleSelectorComponent implements OnInit {

    currentLocale: Locale = Locale.FRENCH;

    locales = Locale.SUPPORTED_LOCALES;

    constructor(private store: Store<fromRoot.State>) {
    }

    ngOnInit() {
        this.store.pipe(
            select(fromRoot.getLocale),
            filter(lc => CommonUtils.notEmpty(lc))
        ).subscribe(loc => this.currentLocale = loc);
    }

    selectLocale(locale: Locale) {
        this.store.dispatch(new localeActions.UpdateLocaleAction(locale));
    }

}
