import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MaterializeModule} from 'angular2-materialize';
import {SHARED_COMPONENTS} from './components';
import {SHARED_DIRECTIVES} from './directives';
import {SHARED_PIPES} from './pipes';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        MaterializeModule,
        TranslateModule
    ],
    providers: [],
    declarations: [
        ...SHARED_COMPONENTS,
        ...SHARED_PIPES,
        ...SHARED_DIRECTIVES
    ],
    schemas: [
        NO_ERRORS_SCHEMA,
        CUSTOM_ELEMENTS_SCHEMA,
    ],
    exports: [
        ...SHARED_COMPONENTS,
        ...SHARED_PIPES,
        ...SHARED_DIRECTIVES,
        TranslateModule
    ]
})
export class SharedModule {

}
