export var SendMessageActionTypes;
(function (SendMessageActionTypes) {
    SendMessageActionTypes["SendMessage"] = "[MESSAGE] SEND_MESSAGE";
    SendMessageActionTypes["SendMessageSuccess"] = "[MESSAGE] SEND_MESSAGE_SUCCESS";
    SendMessageActionTypes["SendMessageFail"] = "[MESSAGE] SEND_MESSAGE_FAIL";
    SendMessageActionTypes["SetSender"] = "[MESSAGE] SET_SENDER";
    SendMessageActionTypes["SetReceiver"] = "[MESSAGE] SET_RECEIVER";
    SendMessageActionTypes["SetSent"] = "[MESSAGE] SET_SENT";
    SendMessageActionTypes["Reset"] = "[MESSAGE] RESET";
})(SendMessageActionTypes || (SendMessageActionTypes = {}));
/**
 * Send message.
 */
var SendMessageAction = /** @class */ (function () {
    function SendMessageAction(payload) {
        this.payload = payload;
        this.type = SendMessageActionTypes.SendMessage;
    }
    return SendMessageAction;
}());
export { SendMessageAction };
var SendMessageSuccessAction = /** @class */ (function () {
    function SendMessageSuccessAction() {
        this.type = SendMessageActionTypes.SendMessageSuccess;
    }
    return SendMessageSuccessAction;
}());
export { SendMessageSuccessAction };
var SendMessageFailAction = /** @class */ (function () {
    function SendMessageFailAction(payload) {
        this.payload = payload;
        this.type = SendMessageActionTypes.SendMessageFail;
    }
    return SendMessageFailAction;
}());
export { SendMessageFailAction };
var SetSenderAction = /** @class */ (function () {
    function SetSenderAction(payload) {
        this.payload = payload;
        this.type = SendMessageActionTypes.SetSender;
    }
    return SetSenderAction;
}());
export { SetSenderAction };
var SetReceiverAction = /** @class */ (function () {
    function SetReceiverAction(payload) {
        this.payload = payload;
        this.type = SendMessageActionTypes.SetReceiver;
    }
    return SetReceiverAction;
}());
export { SetReceiverAction };
var SetSentAction = /** @class */ (function () {
    function SetSentAction(payload) {
        this.payload = payload;
        this.type = SendMessageActionTypes.SetSent;
    }
    return SetSentAction;
}());
export { SetSentAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = SendMessageActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
