<div class="container-fluid sub-header" yfLayoutClass>
    <div class="container">
        <h1 class="white-text h4 center-sm-down" translate>cgu.cookies.title</h1>
    </div>
</div>

<div class="container" yfLayoutClass>
    <div class="card">
        <div class="card-block">
            <div class="section-header">
                <span translate>cgu.cookies.title</span>
            </div>
            <p translate>cgu.cookies.body</p>

            <div class="row mb-0">
                <div class="col-12">
                    <ul>
                        <li translate>cgu.cookies.list.entry1</li>
                        <li translate>cgu.cookies.list.entry2</li>
                        <li translate>cgu.cookies.list.entry3</li>
                        <li translate>cgu.cookies.list.entry4</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>