import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Chip, ChipImpl} from '../../../core/models';

@Component({
    moduleId: module.id,
    selector: 'app-chips',
    templateUrl: './chips.component.html',
    styleUrls: ['./chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipsComponent implements OnInit {

    chips: Chip[];
    originalLength: number;

    @Input() data: any | any[];
    @Input() closable = false;
    @Input() fromString = false;
    @Input() limitDisplaySize: number;
    @Input() displayOverflow: boolean;

    @Output() chipSelect = new EventEmitter<Chip>();
    @Output() chipClose = new EventEmitter<Chip>();

    hasOverflow = false;

    constructor() {
    }

    ngOnInit() {

        if (this.fromString) {
            if (this.data) {

                this.chips = [];

                if (this.data instanceof Array) {
                    this.data.forEach(d => {
                        let chip = new ChipImpl();
                        chip.id = d;
                        chip.value = d;
                        chip.tag = d;

                        this.chips.push(chip);
                    })
                } else {
                    let chip = new ChipImpl();
                    chip.id = this.data;
                    chip.value = this.data;
                    chip.tag = this.data;

                    this.chips.push(chip);
                }


            }
        } else {
            if (this.data instanceof Array) {
                this.chips = this.data;
            } else {
                this.chips = [this.data];
            }

        }

        this.originalLength = this.chips.length;

        if (this.limitDisplaySize) {

            if (this.chips.length > this.limitDisplaySize) {
                this.chips = this.chips.slice(0, this.limitDisplaySize);
                this.hasOverflow = true;
            }

            // this.chips = this.chips.slice(0, this.limitDisplaySize);
        }

    }

    onChipSelect(chip: Chip, event: any) {
        this.chipSelect.emit(chip);
        event.stopPropagation();
    }

    onChipClose(chip: Chip) {
        this.chipClose.emit(chip);
    }
}
