var PasswordTokenValidationDto = /** @class */ (function () {
    function PasswordTokenValidationDto() {
    }
    PasswordTokenValidationDto.build = function (email, token) {
        var pt = new PasswordTokenValidationDto();
        pt.email = email;
        pt.token = token;
        return pt;
    };
    return PasswordTokenValidationDto;
}());
export { PasswordTokenValidationDto };
