var PromotionWorkersHelper = /** @class */ (function () {
    function PromotionWorkersHelper() {
    }
    PromotionWorkersHelper.prototype.push = function (reqs) {
    };
    Object.defineProperty(PromotionWorkersHelper.prototype, "dataItems", {
        get: function () {
            return [];
        },
        enumerable: true,
        configurable: true
    });
    return PromotionWorkersHelper;
}());
export { PromotionWorkersHelper };
