var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { authenticationActions } from '../actions';
import { CommonUtils } from '../../utils';
export var initialState = {
    authenticated: false,
    loading: false,
    user: null,
    userProfile: null,
    errors: null,
    unreadConversationsCount: null,
    loaded: false
};
var types = authenticationActions.AuthenticationActionTypes;
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case types.Authenticate:
        case types.SocialAuthenticate: {
            return __assign({}, state, { authenticated: false, loading: true, errors: null });
        }
        case types.AuthenticateSuccess:
        case types.SocialAuthenticateSuccess: {
            return __assign({}, state, { authenticated: true, loading: false, user: action.payload, errors: null, unreadConversationsCount: null });
        }
        case types.AuthenticateFailure:
        case types.SocialAuthenticateFailure: {
            return __assign({}, state, { errors: action.payload, loading: false, authenticated: false, user: null });
        }
        case types.AuthenticationReset: {
            return Object.assign({}, initialState);
        }
        case types.SetErrors: {
            return __assign({}, state, { errors: action.payload });
        }
        case types.SetUser:
        case types.TestSessionSuccess: {
            return __assign({}, state, { authenticated: true, loading: false, user: action.payload, errors: null, loaded: true });
        }
        case types.SetLoaded: {
            return __assign({}, state, { loading: false, loaded: true });
        }
        case types.SetPicture: {
            var user = CommonUtils.cloneDeep(state.user);
            user.picture = action.payload;
            return __assign({}, state, { user: user });
        }
        case types.LoadUserProfileSuccess: {
            return __assign({}, state, { userProfile: action.payload });
        }
        /**
         * Count Unread Messages
         */
        case types.CountUnreadConversationsSuccess: {
            return __assign({}, state, { unreadConversationsCount: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var getCurrentUser = function (state) { return state.user; };
export var getCurrentUserProfile = function (state) { return state.userProfile; };
export var getErrors = function (state) { return state.errors; };
export var isLoading = function (state) { return state.loading; };
export var isAuthenticated = function (state) { return state.authenticated; };
export var getUnreadConversationsCount = function (state) { return state.unreadConversationsCount; };
