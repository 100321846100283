import {Action} from '@ngrx/store';
import {AccountActivationDto, Notification} from '../../models';

export enum AccountActivationActionTypes {
    Activate = '[Account] ACTIVATE',
    ActivateSuccess = '[Account] ACTIVATE_SUCCESS',
    ActivateFail = '[Account] ACTIVATE_FAIL',

    Reset = '[Account] RESET_ACTIVATE'
}

export class ActivateAction implements Action {
    readonly type = AccountActivationActionTypes.Activate;

    constructor(public payload: AccountActivationDto) {
    }
}

export class ActivateSuccessAction implements Action {
    readonly type = AccountActivationActionTypes.ActivateSuccess;

    constructor(public payload: Notification) {
    }
}

export class ActivateFailAction implements Action {
    readonly type = AccountActivationActionTypes.ActivateFail;

    constructor(public payload: Notification) {
    }
}

export class ResetAction implements Action {
    readonly type = AccountActivationActionTypes.Reset;
}

export type Actions = ActivateAction
    | ActivateSuccessAction
    | ActivateFailAction
    | ResetAction
    ;
