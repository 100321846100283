/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-reviews-average.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./user-reviews-average.component";
var styles_UserReviewsAverageComponent = [i0.styles];
var RenderType_UserReviewsAverageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserReviewsAverageComponent, data: {} });
export { RenderType_UserReviewsAverageComponent as RenderType_UserReviewsAverageComponent };
function View_UserReviewsAverageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-star-o"], ["style", "font-size: 1.2rem; color: #bbbbbb;"]], null, null, null, null, null))], null, null); }
function View_UserReviewsAverageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-12 px-0"]], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_UserReviewsAverageComponent_2)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(5, 5)], function (_ck, _v) { var currVal_1 = _ck(_v, 5, 0, 0, 1, 2, 3, 4); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("global.no-review")); _ck(_v, 1, 0, currVal_0); }); }
function View_UserReviewsAverageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa review-star"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fa review-star"; var currVal_1 = _co.getStarClass(_v.context.$implicit); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_UserReviewsAverageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col-12 px-0"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_UserReviewsAverageComponent_4)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(3, 5)], function (_ck, _v) { var currVal_1 = _ck(_v, 3, 0, 0, 1, 2, 3, 4); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.average + 1) + "/5"); _ck(_v, 0, 0, currVal_0); }); }
export function View_UserReviewsAverageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserReviewsAverageComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["hasReviews", 2]], null, 0, null, View_UserReviewsAverageComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.average == 0); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_UserReviewsAverageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-reviews-average", [], null, null, null, View_UserReviewsAverageComponent_0, RenderType_UserReviewsAverageComponent)), i1.ɵdid(1, 49152, null, 0, i4.UserReviewsAverageComponent, [], null, null)], null, null); }
var UserReviewsAverageComponentNgFactory = i1.ɵccf("app-user-reviews-average", i4.UserReviewsAverageComponent, View_UserReviewsAverageComponent_Host_0, { average: "average" }, {}, []);
export { UserReviewsAverageComponentNgFactory as UserReviewsAverageComponentNgFactory };
