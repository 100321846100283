var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NotificationService, RouteService } from '../../../core/services';
import { routeActions } from '../../../core/store/actions';
import { sendMessageActions } from '../../../core/store/actions';
import { messageModalActions, phoneModalActions } from '../../../core/store/actions';
import * as fromRoot from '../../../store';
import { reportUserActions, userSearchActions, userViewActions } from '../actions';
import { UserService, WorkerSearchCriteriaService } from '../../services';
var UserEffects = /** @class */ (function () {
    function UserEffects(actions$, userService, store$, workerSCService, routeService, notificationService) {
        var _this = this;
        this.actions$ = actions$;
        this.userService = userService;
        this.store$ = store$;
        this.workerSCService = workerSCService;
        this.routeService = routeService;
        this.notificationService = notificationService;
        this.searchWorkers = this.actions$.pipe(ofType(userSearchActions.UsersSearchActionTypes.Search), map(function (action) { return action.payload; }), mergeMap(function (searchCriteriaWrapper) {
            return _this.userService.searchWorkers(searchCriteriaWrapper.searchCriteria, searchCriteriaWrapper.pageable).pipe(map(function (page) { return new userSearchActions.SearchSuccessAction(page); }), catchError(function (errors) { return of(new userSearchActions.SearchFailureAction(errors)); }));
        }));
        this.searchAroundIPOrLastWorkers = this.actions$.pipe(ofType(userSearchActions.UsersSearchActionTypes.SearchAroundIpOrLast), map(function (action) { return action.payload; }), mergeMap(function (pageable) {
            return _this.userService.searchAroundIPOrLastWorkers(pageable).pipe(map(function (page) { return new userSearchActions.SearchAroundIPOrLastSuccessAction(page); }), catchError(function (errors) { return of(new userSearchActions.SearchAroundIPOrLastFailAction(errors)); }));
        }));
        this.reportUser = this.actions$.pipe(ofType(reportUserActions.UserReportActionTypes.ReportUser), map(function (action) { return action.payload; }), mergeMap(function (userReport) {
            return _this.userService.reportUser(userReport).pipe(map(function () {
                _this.notificationService.displaySuccessKey('reports.thanks');
                return new reportUserActions.ReportUserSuccessAction();
            }), catchError(function (errors) { return of(new reportUserActions.ReportUserFailAction(errors)); }));
        }));
        this.loadUserProfile = this.actions$.pipe(ofType(userViewActions.UserViewActionTypes.LoadUserViewProfile), map(function (action) { return action.payload; }), mergeMap(function (uid) {
            return _this.userService.getWorker(uid).pipe(map(function (profile) { return new userViewActions.LoadUserViewProfileSuccessAction(profile); }), catchError(function (errors) { return of(new userViewActions.LoadUserViewProfileFailureAction(errors)); }));
        }));
        this.openSendMessage = this.actions$.pipe(ofType(userViewActions.UserViewActionTypes.OpenSendMessage), map(function (action) { return action.payload; }), withLatestFrom(this.store$.select(fromRoot.getCurrentUser), function (receiver, currentUser) { return ({ receiver: receiver, currentUser: currentUser }); }), mergeMap(function (both) {
            return [
                new sendMessageActions.SetSenderAction(both.currentUser ? both.currentUser : null),
                new sendMessageActions.SetReceiverAction(both.receiver),
                new messageModalActions.OpenMessageModalAction()
            ];
        }));
        this.showPhone = this.actions$.pipe(ofType(userViewActions.UserViewActionTypes.ShowPhone), map(function (action) { return action.payload; }), mergeMap(function (user) {
            return [
                new phoneModalActions.OpenPhoneModalAction(),
                new userViewActions.LoadUserProfilePhoneAction(user.uid)
            ];
        }));
        this.loadPhone = this.actions$.pipe(ofType(userViewActions.UserViewActionTypes.LoadUserViewProfilePhone), map(function (action) { return action.payload; }), mergeMap(function (uid) {
            return _this.userService.getUserPhone(uid).pipe(map(function (phone) { return new userViewActions.LoadUserProfilePhoneSuccessAction(phone); }), catchError(function (errors) { return of(new userViewActions.LoadUserProfilePhoneFailureAction(errors)); }));
        }));
        this.addUserProfileView = this.actions$.pipe(ofType(userViewActions.UserViewActionTypes.AddUserProfileView), map(function (action) { return action.payload; }), mergeMap(function (uid) {
            return _this.userService.addView(uid).pipe(map(function () { return null; }), catchError(function (errors) { return of(); }));
        }));
        this.toSearchWorkers = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToSearchWorkers), map(function (action) { return action.payload; }), mergeMap(function (payload) {
            var queryParams = _this.workerSCService.convertToParams(payload.searchCriteria, payload.pageable);
            var navigationExtras = {
                queryParams: queryParams,
            };
            _this.routeService.toSearchPage(navigationExtras);
            return of();
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "searchWorkers", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "searchAroundIPOrLastWorkers", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "reportUser", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "loadUserProfile", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "openSendMessage", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "showPhone", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "loadPhone", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "addUserProfileView", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "toSearchWorkers", void 0);
    return UserEffects;
}());
export { UserEffects };
