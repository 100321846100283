export enum ServiceType {
    CATEGORY,
    ACTIVITY
}

export class FlatServiceDto {
    id: number;
    name: string;
    description: string;
    type: ServiceType;
}
