import { EventEmitter, OnInit } from '@angular/core';
import { AddReviewDto, BasicUserDto } from '../../../../core/models';
import { ReviewUtils } from '../../../../core/utils';
var AddReviewComponent = /** @class */ (function () {
    function AddReviewComponent() {
        this.addReview = new EventEmitter();
        this.cancel = new EventEmitter();
        this.reviewRatings = ReviewUtils.getReviewRatings();
    }
    AddReviewComponent.prototype.ngOnInit = function () {
        this.review = new AddReviewDto();
        this.review.rating = -1;
    };
    AddReviewComponent.prototype.submit = function (form) {
        if (!form.valid) {
            return;
        }
        this.emitReview();
    };
    AddReviewComponent.prototype.isRatingSelected = function (rating) {
        return this.review.rating === rating.value;
    };
    AddReviewComponent.prototype.setReviewRating = function (rating) {
        this.review.rating = rating;
    };
    AddReviewComponent.prototype.emitReview = function () {
        this.review.targetUid = this.targetUser.uid;
        this.review.authorUid = this.author.uid;
        this.addReview.emit(this.review);
    };
    return AddReviewComponent;
}());
export { AddReviewComponent };
