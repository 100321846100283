import {AgmCoreModule} from '@agm/core';
import {AgmSnazzyInfoWindowModule} from '@agm/snazzy-info-window';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, NO_ERRORS_SCHEMA, PLATFORM_ID} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ShareButtonsModule} from '@ngx-share/buttons';
import {MaterializeModule} from 'angular2-materialize';
import {NouisliderModule} from 'ng2-nouislider';

import {environment} from '../../environments/environment';
import {AdsenseModule} from '../adsense/adsense.module';
import {AddressService, BrowserService, StorageService} from '../core/services';
import {CoreModule} from '../core/core.module';
import {ServerStorageService} from '../core/services/server-storage.service';
import {SharedModule} from '../shared/shared.module';
import {WEB_COMPONENTS} from './components';
import {WEB_DIRECTIVES} from './directives';
import {AddressWebService} from './services';
import {BrowserWebService} from './services/browser-web.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterializeModule,
        NouisliderModule,
        AgmCoreModule,
        AgmSnazzyInfoWindowModule,
        CoreModule.forRoot([
            {provide: StorageService, useFactory: storageServiceFactory, deps: [PLATFORM_ID]},
            {provide: BrowserService, useFactory: browserServiceFactory, deps: [PLATFORM_ID, Injector]}
        ]),
        AdsenseModule.forRoot({
            adClient: 'ca-pub-5842786856906684',
            adSlot: 1221167974,
            adtest: 'on'
        }),
        ShareButtonsModule,
        SharedModule
    ],
    providers: [
        {provide: AddressService, useClass: AddressWebService}
    ],
    declarations: [
        ...WEB_COMPONENTS,
        ...WEB_DIRECTIVES
    ],
    schemas: [
        NO_ERRORS_SCHEMA,
        CUSTOM_ELEMENTS_SCHEMA,
    ],
    exports: [
        ...WEB_COMPONENTS,
        ...WEB_DIRECTIVES,
        CommonModule,
        FormsModule,
        CoreModule,
        SharedModule,
        NouisliderModule,
        AgmCoreModule,
        AgmSnazzyInfoWindowModule,
        MaterializeModule,
        RouterModule,
        AdsenseModule
    ]
})
export class WebModule {
}

export function storageServiceFactory(platformId) {
    if (isPlatformBrowser(platformId)) {
        return localStorage;
    }

    return new ServerStorageService();
}

export function browserServiceFactory(platformId, injector) {
    return new BrowserWebService(platformId, injector);
}
