import { EventEmitter } from '@angular/core';
import { ListMode } from '../../../core/models';
var ListModeComponent = /** @class */ (function () {
    function ListModeComponent() {
        this.ListMode = ListMode;
        this.listModes = [
            ListModeComponent.buildListMode('global.list-mode.list', ListMode.LIST, 'list'),
            ListModeComponent.buildListMode('global.list-mode.map', ListMode.MAP, 'place')
        ];
        this.mode = ListMode;
        this.showList = true;
        this.showMap = true;
        this.select = new EventEmitter();
    }
    ListModeComponent.buildListMode = function (text, mode, icon) {
        return {
            text: text,
            mode: mode,
            icon: icon
        };
    };
    return ListModeComponent;
}());
export { ListModeComponent };
