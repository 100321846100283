export type NotificationType = 'error' | 'warning' | 'info' | 'success';

export const NotificationType = {

    ERROR: <NotificationType>'error',
    WARNING: <NotificationType>'warning',
    INFO: <NotificationType>'info',
    SUCCESS: <NotificationType>'success'

};
