import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import {shareActions, shareModalActions} from '../actions';

@Injectable()
export class ShareEffects {

    @Effect()
    showPhone = this.actions$.pipe(
        ofType(shareActions.ShareActionsTypes.Share),
        map((action: shareActions.ShareAction) => action.payload),
        map(() => new shareModalActions.OpenModalAction())
    );

    constructor(private actions$: Actions) {

    }
}
