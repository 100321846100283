import {Injectable} from '@angular/core';
import {UserViewDto} from '../../../../core/models';

@Injectable()
export class ProfileViewHelper {

    constructor() {

    }

    hideActionBar() {
    }

    showMoreActions(userProfile: UserViewDto) {
    }
}
