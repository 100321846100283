import { HttpClient } from '@angular/common/http';
// app
import { HttpUtil } from '../../core/utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PromotionService = /** @class */ (function () {
    function PromotionService(httpClient) {
        this.httpClient = httpClient;
    }
    PromotionService.prototype.searchRequestsAroundIPOrLast = function (pageable) {
        var requestOptions = HttpUtil.buildPageableOptions(pageable);
        return this.httpClient.get(PromotionService.REQUESTS_SEARCH, requestOptions);
    };
    PromotionService.prototype.searchWorkersAroundIPOrLast = function (pageable) {
        var requestOptions = HttpUtil.buildPageableOptions(pageable);
        return this.httpClient.get(PromotionService.WORKERS_SEARCH, requestOptions);
    };
    PromotionService.REQUESTS_SEARCH = 'api/v1/search/requests';
    PromotionService.WORKERS_SEARCH = 'api/v1/search/workers';
    PromotionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PromotionService_Factory() { return new PromotionService(i0.ɵɵinject(i1.HttpClient)); }, token: PromotionService, providedIn: "root" });
    return PromotionService;
}());
export { PromotionService };
