import { EventEmitter } from '@angular/core';
import { Notification, NotificationType } from '../../../core/models';
import { ErrorUtils } from '../../../core/utils';
var NotificationComponent = /** @class */ (function () {
    function NotificationComponent() {
        this.closable = true;
        this.notificationType = NotificationType.ERROR;
        this.closed = new EventEmitter();
        this.NotificationType = NotificationType;
    }
    Object.defineProperty(NotificationComponent.prototype, "notification", {
        set: function (notif) {
            if (!notif) {
                return;
            }
            if (this.instanceOfErrorsDto(notif)) {
                this._notification = ErrorUtils.toNotification(notif);
            }
            else {
                this._notification = notif;
            }
        },
        enumerable: true,
        configurable: true
    });
    NotificationComponent.prototype.closeNotification = function () {
        this._notification = null;
        this.showNotification = false;
        this.closed.emit();
    };
    NotificationComponent.prototype.displayBasicNotification = function (msg, notifType) {
        var notif = new Notification();
        notif.type = notifType;
        notif.title = msg;
        this.displayNotification(notif);
    };
    NotificationComponent.prototype.displayNotification = function (notification) {
        this._notification = notification;
        this.showNotification = true;
    };
    NotificationComponent.prototype.getNotificationClass = function () {
        var type = this._notification && this._notification.type ? this._notification.type : this.notificationType;
        switch (type) {
            case NotificationType.ERROR:
                return 'error';
            case NotificationType.WARNING:
                return 'warning';
            case NotificationType.INFO:
                return 'info';
            case NotificationType.SUCCESS:
                return 'success';
        }
        return '';
    };
    NotificationComponent.prototype.displaySuccessMessage = function (message) {
        this.displayBasicNotification(message, NotificationType.SUCCESS);
    };
    NotificationComponent.prototype.displayErrorMessage = function (message) {
        this.displayBasicNotification(message, NotificationType.ERROR);
    };
    NotificationComponent.prototype.displayErrors = function (errors, notificationType) {
        if (notificationType === void 0) { notificationType = NotificationType.ERROR; }
        this.displayNotification(this.toNotification(errors, notificationType));
    };
    NotificationComponent.prototype.toNotification = function (error, notificationType) {
        if (notificationType === void 0) { notificationType = NotificationType.ERROR; }
        var notif = null;
        if (error) {
            notif = new Notification();
            notif.type = notificationType;
            notif.title = error.message;
            if (error.errors) {
                error.errors.forEach(function (err) { return notif.addMessage(err.message); });
            }
        }
        return notif;
    };
    NotificationComponent.prototype.instanceOfErrorsDto = function (object) {
        return object && object.code !== undefined;
    };
    return NotificationComponent;
}());
export { NotificationComponent };
