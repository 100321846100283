import {FormControl, ValidationErrors} from '@angular/forms';
import {ValidationService} from '../services';

export class BcValidators {

    static login(control: FormControl): ValidationErrors | any {
        let login = control.value;

        if (!ValidationService.isLoginValid(login)) {
            return {
                invalid: {
                    value: login
                }
            }
        }

        return null;
    }

    static password(control: FormControl): ValidationErrors | any {
        let pwd = control.value;

        if (!ValidationService.isPasswordValid(pwd)) {
            return {
                invalid: {
                    value: pwd
                }
            }
        }

        return null;
    }

}
