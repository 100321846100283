export var AccountRequestsActionTypes;
(function (AccountRequestsActionTypes) {
    AccountRequestsActionTypes["LoadRequests"] = "[USER REQUESTS] LOAD_REQUESTS";
    AccountRequestsActionTypes["LoadRequestsSuccess"] = "[USER REQUESTS] LOAD_REQUESTS_SUCCESS";
    AccountRequestsActionTypes["LoadRequestsFail"] = "[USER REQUESTS] LOAD_REQUESTS_FAIL";
})(AccountRequestsActionTypes || (AccountRequestsActionTypes = {}));
/**
 * Conversations.
 */
var LoadRequestsAction = /** @class */ (function () {
    function LoadRequestsAction(payload) {
        this.payload = payload;
        this.type = AccountRequestsActionTypes.LoadRequests;
    }
    return LoadRequestsAction;
}());
export { LoadRequestsAction };
var LoadRequestsSuccessAction = /** @class */ (function () {
    function LoadRequestsSuccessAction(payload) {
        this.payload = payload;
        this.type = AccountRequestsActionTypes.LoadRequestsSuccess;
    }
    return LoadRequestsSuccessAction;
}());
export { LoadRequestsSuccessAction };
var LoadRequestsFailAction = /** @class */ (function () {
    function LoadRequestsFailAction(payload) {
        this.payload = payload;
        this.type = AccountRequestsActionTypes.LoadRequestsFail;
    }
    return LoadRequestsFailAction;
}());
export { LoadRequestsFailAction };
