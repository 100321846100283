import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import {ActivityDto, CategoryDto} from '../../../core/models';
import {CategoryUtils} from '../../../core/utils/category.util';

@Component({
    selector: 'app-activity-list',
    templateUrl: './service-list.component.html',
    styleUrls: ['./service-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityListComponent implements OnInit {

    @Input()
    activities: ActivityDto[];

    @Input()
    colClass = 'col-md-4';

    categories: CategoryDto[];

    ngOnInit() {
        this.categories = CategoryUtils.toCategoriesTree(this.activities);
    }

}
