import {Action} from '@ngrx/store';
import {EmailVerificationDto, Notification} from '../../models';

export enum EmailVerificationActionTypes {
    VerifyEmail = '[EmailVerification] VerifyEmail',
    VerifyEmailSuccess = '[EmailVerification] VerifyEmailSuccess',
    VerifyEmailFail = '[EmailVerification] VerifyEmailFail',

    Reset = '[EmailVerification] Reset'
}

export class VerifyEmailAction implements Action {
    readonly type = EmailVerificationActionTypes.VerifyEmail;

    constructor(public payload: EmailVerificationDto) {
    }
}

export class VerifyEmailSuccessAction implements Action {
    readonly type = EmailVerificationActionTypes.VerifyEmailSuccess;

    constructor(public payload: Notification) {
    }
}

export class VerifyEmailFailAction implements Action {
    readonly type = EmailVerificationActionTypes.VerifyEmailFail;

    constructor(public payload: Notification) {
    }
}

export class ResetAction implements Action {
    readonly type = EmailVerificationActionTypes.Reset;
}

export type Actions = VerifyEmailAction
    | VerifyEmailSuccessAction
    | VerifyEmailFailAction
    | ResetAction
    ;
