import { ModuleWithProviders } from '@angular/core';
var UsersModule = /** @class */ (function () {
    function UsersModule() {
    }
    UsersModule.forRoot = function (configuredProviders) {
        return {
            ngModule: UsersModule,
            providers: configuredProviders
        };
    };
    return UsersModule;
}());
export { UsersModule };
