import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import * as fromRoot from '../../../store';
import {activateAccountActions, authenticationActions, routeActions} from '../actions';
import {StorageKey} from '../../models/interfaces';
import {AccountActivationService, NotificationService, StorageService} from '../../services';
import {ErrorUtils} from '../../utils';

@Injectable()
export class AccountActivationEffects {

    @Effect()
    activateAccount = this.actions$.pipe(
        ofType<activateAccountActions.ActivateAction>(activateAccountActions.AccountActivationActionTypes.Activate),
        map(action => action.payload),
        mergeMap(userActivation =>
            this.accountActivationService.activateAccount(userActivation).pipe(
                map(() => new activateAccountActions.ActivateSuccessAction(this.notificationService.buildAccountActivationSuccessNotification())),
                catchError(errors => of(new activateAccountActions.ActivateFailAction(ErrorUtils.toNotification(errors))))
            )
        )
    );

    @Effect()
    activateAccountSuccess = this.actions$.pipe(
        ofType<activateAccountActions.ActivateSuccessAction>(activateAccountActions.AccountActivationActionTypes.ActivateSuccess),
        withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUser))),
        map(([action, user]) => {

            if (isPlatformBrowser(this.platformId)) {
                let storageUser = this.storageService.getItem(StorageKey.USER);

                // TODO to ameliorate
                let enabledUser = Object.assign(storageUser, {enabled: true});
                this.storageService.setItem(StorageKey.USER, enabledUser);
            }

            if (user) {

                let redirectAction: Action = new routeActions.ToCompleteUserProfileAction();
                if (user.filled) {
                    redirectAction = new routeActions.ToUserProfileAction();
                }

                this.store.dispatch(new authenticationActions.TestUserAction());

                return redirectAction;

            } else {
                return new routeActions.ToSignInAction();
            }
        })
    );

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private accountActivationService: AccountActivationService,
                private notificationService: NotificationService,
                private actions$: Actions,
                private store: Store<fromRoot.State>,
                private storageService: StorageService) {
    }
}
