import {VerificationStatus} from '../models';

export class VerificationUtils {

    static isStatusPending(status: VerificationStatus): boolean {
        return VerificationUtils.isStatusEquals(status, VerificationStatus.PENDING);
    }

    static isStatusNone(status: VerificationStatus): boolean {
        return VerificationUtils.isStatusEquals(status, VerificationStatus.NONE);
    }

    static isStatusRejected(status: VerificationStatus): boolean {
        return VerificationUtils.isStatusEquals(status, VerificationStatus.REJECTED);
    }

    static isStatusApproved(status: VerificationStatus): boolean {
        return VerificationUtils.isStatusEquals(status, VerificationStatus.APPROVED);
    }

    static isStatusEquals(status: VerificationStatus, expected: VerificationStatus): boolean {
        return status === expected;
    }

}
