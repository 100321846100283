import { EventEmitter, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../../store';
import { activityChooserManagerActions, activityChooserModalActions } from '../../../core/store/actions';
var ActivityChooserManagerComponent = /** @class */ (function () {
    function ActivityChooserManagerComponent(store) {
        this.store = store;
        this.multiple = false;
        this.resetOnInit = true;
        this.showFooter = true;
        this.activitySelected = new EventEmitter();
        this.categorySelected = new EventEmitter();
        this.categories$ = this.store.pipe(select(fromRoot.getCategories));
        this.activities$ = this.store.pipe(select(fromRoot.getActivityChooserManagerActivities));
        this.selectedCategories$ = this.store.pipe(select(fromRoot.getActivityChooserManagerSelectedCategories));
        this.selectedActivities$ = this.store.pipe(select(fromRoot.getActivityChooserManagerSelectedActivities));
        this.activityModalEventListener$ = this.store.pipe(select(fromRoot.getActivityChooserModalEvent));
    }
    ActivityChooserManagerComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.resetOnInit) {
            this.store.dispatch(new activityChooserManagerActions.ResetAction());
        }
        this.openEventListener$.subscribe(function () { return _this.openActivityChooser(); });
    };
    ActivityChooserManagerComponent.prototype.openActivityChooser = function () {
        this.store.dispatch(new activityChooserModalActions.OpenActivityChooserModalAction());
    };
    ActivityChooserManagerComponent.prototype.selectActivity = function (activity) {
        if (this.multiple) {
            this.store.dispatch(new activityChooserManagerActions.SelectOrDeselectActivityAction(activity));
        }
        else {
            this.store.dispatch(new activityChooserManagerActions.SetSelectedActivitiesAction([activity]));
            this.closeModal();
        }
        this.activitySelected.emit(activity);
    };
    ActivityChooserManagerComponent.prototype.selectCategory = function (category) {
        this.store.dispatch(new activityChooserManagerActions.SetSelectedCategoriesAction([category]));
        this.store.dispatch(new activityChooserManagerActions.SetActivitiesAction(category.activities));
        this.categorySelected.emit(category);
    };
    ActivityChooserManagerComponent.prototype.closeModal = function () {
        this.store.dispatch(new activityChooserModalActions.CloseActivityChooserModalAction());
    };
    return ActivityChooserManagerComponent;
}());
export { ActivityChooserManagerComponent };
