var UserReviewsAverageComponent = /** @class */ (function () {
    function UserReviewsAverageComponent() {
    }
    UserReviewsAverageComponent.prototype.getStarClass = function (index) {
        var clazz;
        var num = index - this.average;
        if (num >= 1) {
            clazz = 'fa-star-o';
        }
        else if (num <= 0) {
            clazz = 'fa-star';
        }
        else {
            clazz = 'fa-star-half-o';
        }
        return clazz;
    };
    return UserReviewsAverageComponent;
}());
export { UserReviewsAverageComponent };
