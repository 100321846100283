import { ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { SearchCriteriaDto } from '../../../../core/models';
import { ActivityTranslatorService } from '../../../../core/services/activity-translator.service';
import { NotificationService, ActivityService } from '../../../../core/services';
import { activityChooserManagerActions } from '../../../../core/store/actions';
var SearchSimpleComponent = /** @class */ (function () {
    function SearchSimpleComponent(activityTranslator, store, notificationService, activityService, cdRef) {
        this.activityTranslator = activityTranslator;
        this.store = store;
        this.notificationService = notificationService;
        this.activityService = activityService;
        this.cdRef = cdRef;
        this.search = new EventEmitter();
        this.activityChooserListener$ = new Subject();
    }
    Object.defineProperty(SearchSimpleComponent.prototype, "searchCriteria", {
        get: function () {
            return this._searchCriteria;
        },
        set: function (searchCriteria) {
            this.init(searchCriteria);
        },
        enumerable: true,
        configurable: true
    });
    SearchSimpleComponent.prototype.ngOnInit = function () {
        this.init(this._searchCriteria);
    };
    SearchSimpleComponent.prototype.ngOnDestroy = function () {
    };
    SearchSimpleComponent.prototype.init = function (searchCriteria) {
        var _this = this;
        if (!searchCriteria) {
            this._searchCriteria = new SearchCriteriaDto();
        }
        else {
            this._searchCriteria = searchCriteria;
            var activity = this._searchCriteria.activity;
            if (activity) {
                this.activityService.getActivityById(activity.id).subscribe(function (act) {
                    _this.selectedActivityName = _this.activityTranslator.translate(act);
                    _this.cdRef.detectChanges();
                    _this.store.dispatch(new activityChooserManagerActions.SetSelectedActivitiesAction([act]));
                });
            }
        }
    };
    SearchSimpleComponent.prototype.submit = function (form) {
        if (form.invalid || this.hasError) {
            this.hasError = true;
            this.notificationService.displayErrorKey('errors.search-simple.form-invalid');
            return;
        }
        this.hasError = false;
        this.search.emit(this.searchCriteria);
    };
    SearchSimpleComponent.prototype.addChange = function (value) {
        this._inputText = value ? value : null;
    };
    SearchSimpleComponent.prototype.selectAddress = function (address) {
        this.searchCriteria.address = address;
        this._inputText = null;
        this.hasAddressError();
        this.cdRef.detectChanges();
    };
    SearchSimpleComponent.prototype.clearAddress = function () {
        this.searchCriteria.address = null;
    };
    SearchSimpleComponent.prototype.hasAddressError = function () {
        this.hasError = this.searchCriteria.address == null && this._inputText != null;
    };
    SearchSimpleComponent.prototype.openActivityChooser = function () {
        this.activityChooserListener$.next();
    };
    SearchSimpleComponent.prototype.selectActivity = function (activity) {
        this.searchCriteria.activity = activity;
        this.selectedActivityName = this.activityTranslator.translate(activity);
        this.focusAddress();
    };
    SearchSimpleComponent.prototype.focusAddress = function () {
        this.searchRef.nativeElement.focus();
    };
    return SearchSimpleComponent;
}());
export { SearchSimpleComponent };
