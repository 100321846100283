export var UserViewActionTypes;
(function (UserViewActionTypes) {
    UserViewActionTypes["LoadUserViewProfile"] = "[USER_VIEW] LOAD_USER_VIEW_PROFILE";
    UserViewActionTypes["LoadUserViewProfileSuccess"] = "[USER_VIEW] LOAD_USER_VIEW_PROFILE_SUCCESS";
    UserViewActionTypes["LoadUserViewProfileFail"] = "[USER_VIEW] LOAD_USER_VIEW_PROFILE_FAIL";
    UserViewActionTypes["LoadUserViewProfilePhone"] = "[USER_VIEW] LOAD_USER_VIEW_PROFILE_PHONE";
    UserViewActionTypes["LoadUserViewProfilePhoneSuccess"] = "[USER_VIEW] LOAD_USER_VIEW_PROFILE_PHONE_SUCCESS";
    UserViewActionTypes["LoadUserViewProfilePhoneFail"] = "[USER_VIEW] LOAD_USER_VIEW_PROFILE_PHONE_FAIL";
    UserViewActionTypes["Reset"] = "[USER_VIEW] RESET";
    UserViewActionTypes["OpenSendMessage"] = "[USER_VIEW] OPEN_SEND_MESSAGE";
    UserViewActionTypes["ShowPhone"] = "[USER_VIEW] SHOW_PHONE";
    UserViewActionTypes["AddUserProfileView"] = "[USER_VIEW] ADD_USER_PROFILE_VIEW";
})(UserViewActionTypes || (UserViewActionTypes = {}));
/**
 * Load profile.
 */
var LoadUserViewProfileAction = /** @class */ (function () {
    function LoadUserViewProfileAction(payload) {
        this.payload = payload;
        this.type = UserViewActionTypes.LoadUserViewProfile;
    }
    return LoadUserViewProfileAction;
}());
export { LoadUserViewProfileAction };
var LoadUserViewProfileSuccessAction = /** @class */ (function () {
    function LoadUserViewProfileSuccessAction(payload) {
        this.payload = payload;
        this.type = UserViewActionTypes.LoadUserViewProfileSuccess;
    }
    return LoadUserViewProfileSuccessAction;
}());
export { LoadUserViewProfileSuccessAction };
var LoadUserViewProfileFailureAction = /** @class */ (function () {
    function LoadUserViewProfileFailureAction(payload) {
        this.payload = payload;
        this.type = UserViewActionTypes.LoadUserViewProfileFail;
    }
    return LoadUserViewProfileFailureAction;
}());
export { LoadUserViewProfileFailureAction };
/**
 * Load profile phone.
 */
var LoadUserProfilePhoneAction = /** @class */ (function () {
    function LoadUserProfilePhoneAction(payload) {
        this.payload = payload;
        this.type = UserViewActionTypes.LoadUserViewProfilePhone;
    }
    return LoadUserProfilePhoneAction;
}());
export { LoadUserProfilePhoneAction };
var LoadUserProfilePhoneSuccessAction = /** @class */ (function () {
    function LoadUserProfilePhoneSuccessAction(payload) {
        this.payload = payload;
        this.type = UserViewActionTypes.LoadUserViewProfilePhoneSuccess;
    }
    return LoadUserProfilePhoneSuccessAction;
}());
export { LoadUserProfilePhoneSuccessAction };
var LoadUserProfilePhoneFailureAction = /** @class */ (function () {
    function LoadUserProfilePhoneFailureAction(payload) {
        this.payload = payload;
        this.type = UserViewActionTypes.LoadUserViewProfilePhoneFail;
    }
    return LoadUserProfilePhoneFailureAction;
}());
export { LoadUserProfilePhoneFailureAction };
var OpenSendMessageAction = /** @class */ (function () {
    function OpenSendMessageAction(payload) {
        this.payload = payload;
        this.type = UserViewActionTypes.OpenSendMessage;
    }
    return OpenSendMessageAction;
}());
export { OpenSendMessageAction };
var ShowPhoneAction = /** @class */ (function () {
    function ShowPhoneAction(payload) {
        this.payload = payload;
        this.type = UserViewActionTypes.ShowPhone;
    }
    return ShowPhoneAction;
}());
export { ShowPhoneAction };
var AddUserProfileViewAction = /** @class */ (function () {
    function AddUserProfileViewAction(payload) {
        this.payload = payload;
        this.type = UserViewActionTypes.AddUserProfileView;
    }
    return AddUserProfileViewAction;
}());
export { AddUserProfileViewAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = UserViewActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
