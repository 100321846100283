import {Params} from '@angular/router';
import {HttpUtil} from './http.utils';
import {Pageable} from '../models';

const _cloneDeep = require('lodash/cloneDeep');
const _isEmpty = require('lodash/isEmpty');
const _isEqual = require('lodash/isEqual');

export class CommonUtils {

    static cloneDeep(object: any): any {
        return _cloneDeep(object);
    }

    static arrayEquals(arr1: any[], arr2: any[]): boolean {

        if (arr1 == null) {
            return arr2 == null;
        }

        if (arr2 == null) {
            return false;
        }

        return arr1.length === arr2.length && _isEqual(arr1.sort(), arr2.sort());
    }

    static fillPageable(params: Params, pageable?: Pageable): Pageable {

        if (!pageable) {
            pageable = new Pageable();
        }

        if (!params) {
            return pageable;
        }

        let pageParam = params['p'];
        pageable.page = pageParam && +pageParam > 0 ? +pageParam : 1;
        const size = params['size'];

        if (CommonUtils.notEmpty(size)) {
            pageable.size = +size;
        }
        pageable.sort = HttpUtil.stringToSortArray(params['sort']);

        return pageable;
    }

    static pageableToParams(pageable: Pageable): Params {

        let params: Params = [];
        params['p'] = pageable.page;

        if (pageable.sort && pageable.sort.length) {
            params['sort'] = HttpUtil.sortArrayToString(pageable.sort);
        }

        return params;
    }

    static concatParams(par1: Params, oar2: Params): Params {

        if (!par1) {
            return oar2;
        }

        Object.keys(oar2).forEach(key => par1[key] = oar2[key]);

        return par1;
    }

    static isEmpty(value: any): boolean {
        return _isEmpty(value);
    }

    static notEmpty(object: any): boolean {
        return !CommonUtils.isEmpty(object);
    }

    static toBoolean(value: string): boolean {
        return value === 'true';
    }

    static pluralize(length: number, text: string) {
        if (length !== 1) {
            return text.concat('s');
        }

        return text;
    }

    static twoDigitNumber(num: number): string {
        return num < 10 ? '0' + num : '' + num;
    }

}
