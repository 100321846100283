import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';
import * as signUpActions from '../../../modules/core/store/actions/signup.action';
import {Notification} from '../../../modules/core/models/index';
import {BrowserService} from '../../../modules/core/services';
import {ParamsService} from '../../../modules/core/services/params.service';
import {ErrorUtils} from '../../../modules/core/utils/error.util';
import * as fromRoot from '../../../modules/store/';

@Component({
    moduleId: module.id,
    selector: 'app-signup-confirm',
    templateUrl: './signup-confirm.component.html',
    styleUrls: ['./signup-confirm.component.scss']
})
export class SignUpConfirmComponent implements OnInit {
    email: string;
    postSignUp: boolean;
    param = {};

    notification$: Observable<Notification>;
    loading$: Observable<boolean>;
    titleKey = 'sign-up.confirm.welcome-title';

    constructor(private store: Store<fromRoot.State>,
                private paramsService: ParamsService,
                private route: ActivatedRoute,
                private browserService: BrowserService) {

        this.notification$ = this.store.pipe(
            select(fromRoot.getSignUpEmailActivationResentSuccessNotification),
            withLatestFrom(
                this.store.pipe(
                    select(fromRoot.getSignUpEmailActivationResentErrors),
                    map(ErrorUtils.toNotification)
                ),
                (success, failure) => {
                    if (success) {
                        return success;
                    }

                    return failure;
                }));

        this.loading$ = this.store.pipe(select(fromRoot.isSignUpEmailActivationResentLoading));
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            let registrationConfirmationParams = this.paramsService.buildRegistrationConfirmationFromParams(params);
            this.email = registrationConfirmationParams.email;
            this.param = {email: this.email};
            this.postSignUp = registrationConfirmationParams.postSignUp;

            if (this.postSignUp) {
                this.titleKey = 'sign-up.confirm.activation-title';
            }
        });

        this.browserService.materialize();
    }

    sendEmailConfirmation() {
        this.store.dispatch(new signUpActions.SignUpConfirmationResentEmailAction(this.email));
    }
}
