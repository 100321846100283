import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Gender } from '../models';
import { BcUtilsService } from './bc-utils.service';
import { BrowserService } from './browser.service';
import { OpenGraphService } from './open-graph.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./open-graph.service";
import * as i3 from "./bc-utils.service";
import * as i4 from "./browser.service";
import * as i5 from "@angular/platform-browser";
var SeoService = /** @class */ (function () {
    function SeoService(translateService, ogService, utilsService, browserService, meta, title) {
        this.translateService = translateService;
        this.ogService = ogService;
        this.utilsService = utilsService;
        this.browserService = browserService;
        this.meta = meta;
        this.title = title;
    }
    SeoService.prototype.setMeta = function (id, titleInterpolateParams, descInterpolateParams) {
        if (this.browserService.isPlatformServer()) {
            var title = this.translateService.instant("seo." + id + ".title", titleInterpolateParams);
            this.setTitle(title);
            var description = this.translateService.instant("seo." + id + ".description", descInterpolateParams);
            this.setDescription(description);
            var keywords = this.translateService.instant("seo." + id + ".keywords", descInterpolateParams);
            if (keywords !== "seo." + id + ".keywords") {
                this.setKeywords(keywords);
            }
        }
    };
    SeoService.prototype.setUserViewMeta = function (profile) {
        if (this.browserService.isPlatformServer()) {
            var title = this.translateService.instant('seo.profile-view.title', { profile: profile });
            var profileUrl = this.utilsService.getProfileUrl(profile);
            this.setTitle(title);
            this.setDescription(this.getDescription(profile.activities));
            this.addTag('og:title', title);
            this.addTag('og:url', profileUrl);
            if (profile.picture) {
                var picture = this.utilsService.getImageFullPath(profile.picture);
                this.addTag('og:image', picture);
            }
            this.addTag('og:type', 'profile');
            this.addTag('profile:first_name', profile.firstName);
            this.addTag('profile:last_name', profile.lastName);
            this.addTag('profile:gender', profile.gender === Gender.MAN ? 'male' : 'female');
        }
    };
    SeoService.prototype.clearOG = function () {
        this.meta.removeTag('og:title');
        this.meta.removeTag('og:url');
        this.meta.removeTag('og:image');
        this.meta.removeTag('og:type');
    };
    SeoService.prototype.setTitle = function (title) {
        this.title.setTitle(title);
    };
    SeoService.prototype.setDescription = function (description) {
        this.addTag('description', description);
    };
    SeoService.prototype.setKeywords = function (keywords) {
        this.addTag('keywords', keywords);
    };
    SeoService.prototype.setRobots = function (follow) {
        if (follow === void 0) { follow = true; }
        var content = follow ? 'index,follow' : 'noindex,nofollow';
        this.addTag('robots', content);
    };
    SeoService.prototype.addTag = function (name, content) {
        this.meta.addTag({
            name: name,
            content: content
        });
    };
    SeoService.prototype.getDescription = function (activities) {
        var description = '';
        if (activities && activities.length > 0) {
            var length_1 = activities.length;
            description = activities[0].frName;
            for (var i = 1; i < 3; ++i) {
                if (i < length_1) {
                    description += ", " + activities[i].frName;
                }
            }
            if (length_1 > 3) {
                description += length_1 - 3 + ' autres services';
            }
        }
        return description;
    };
    SeoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SeoService_Factory() { return new SeoService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.OpenGraphService), i0.ɵɵinject(i3.BcUtilsService), i0.ɵɵinject(i4.BrowserService), i0.ɵɵinject(i5.Meta), i0.ɵɵinject(i5.Title)); }, token: SeoService, providedIn: "root" });
    return SeoService;
}());
export { SeoService };
