import { isPlatformBrowser } from '@angular/common';
import { ServerStorageService } from '../core/services/server-storage.service';
import { BrowserWebService } from './services/browser-web.service';
var WebModule = /** @class */ (function () {
    function WebModule() {
    }
    return WebModule;
}());
export { WebModule };
export function storageServiceFactory(platformId) {
    if (isPlatformBrowser(platformId)) {
        return localStorage;
    }
    return new ServerStorageService();
}
export function browserServiceFactory(platformId, injector) {
    return new BrowserWebService(platformId, injector);
}
