<div class="card review" yfLayoutClass>
    <div class="card-header action-header">
        <div class="row align-items-center mb-0">

            <div class="col-4 yf-section-header text-header" translate>
                <i class="material-icons">rate_review</i>global.reviews
            </div>

            <div class="col-8 mt-2 mt-sm-0">
                <button type="button" class="btn btn-primary right" *ngIf="!displayAddReviewForm"
                        (click)="showAddReviewForm()" translate>global.add-review</button>
            </div>

        </div>
    </div>

    <div class="card-block">

        <app-notification [notification]="notification"></app-notification>

        <app-add-review *ngIf="displayAddReviewForm"
                        [author]="currentUser"
                        [targetUser]="targetUser"
                        (cancel)="cancelReview($event)"
                        (addReview)="addReview($event)">
        </app-add-review>

        <app-review-list *ngIf="!displayAddReviewForm"
                         [reviews]="reviews"
                         [currentSession$]="currentUser$"
                         (updateReview)="updateReview($event)"
                         (deleteReview)="deleteReview($event)">
        </app-review-list>
    </div>

</div>