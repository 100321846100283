import {emailVerificationActions} from '../actions';
import {Notification} from '../../models';

export interface State {
    loading: boolean,
    notification: Notification,
    verified: boolean;
}

export const initialState: State = {
    loading: false,
    notification: null,
    verified: false
};

export function reducer(state = initialState, action: emailVerificationActions.Actions): State {

    switch (action.type) {

        case emailVerificationActions.EmailVerificationActionTypes.VerifyEmail: {
            return {
                loading: true,
                notification: null,
                verified: false
            }
        }

        case emailVerificationActions.EmailVerificationActionTypes.VerifyEmailSuccess: {
            return {
                loading: false,
                notification: null,
                verified: true
            }
        }

        case emailVerificationActions.EmailVerificationActionTypes.VerifyEmailFail: {
            return {
                loading: false,
                notification: action.payload,
                verified: false
            }
        }

        case emailVerificationActions.EmailVerificationActionTypes.Reset: {
            return Object.assign(
                {},
                state,
                initialState
            )
        }

        default: {
            return state;
        }

    }
}

export const isLoading = (state: State): boolean => state.loading;
export const isVerified = (state: State): boolean => state.verified;
export const getNotification = (state: State): Notification => state.notification;
