/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./locale-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular2-materialize/dist/materialize-directive";
import * as i3 from "@angular/common";
import * as i4 from "./locale-selector.component";
import * as i5 from "@ngrx/store";
var styles_LocaleSelectorComponent = [i0.styles];
var RenderType_LocaleSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocaleSelectorComponent, data: {} });
export { RenderType_LocaleSelectorComponent as RenderType_LocaleSelectorComponent };
function View_LocaleSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "p-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectLocale(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "img-lang circle d-inline-block"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "ml-1  d-inline-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = ("assets/images/flag/" + _v.context.$implicit.icon); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
export function View_LocaleSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "lang-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "dropdown-button"], ["data-activates", "locales-dd"], ["data-belowOrigin", "true"], ["data-constrainWidth", "false"], ["materialize", "dropdown"]], null, null, null, null, null)), i1.ɵdid(2, 5128192, null, 0, i2.MaterializeDirective, [i1.ElementRef], { materialize: [0, "materialize"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Langue"], ["class", "img-lang circle"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [["class", "dropdown-content"], ["id", "locales-dd"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocaleSelectorComponent_1)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dropdown"; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.locales; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ("assets/images/flag/" + ((_co.currentLocale == null) ? null : _co.currentLocale.icon)); _ck(_v, 3, 0, currVal_1); }); }
export function View_LocaleSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "yf-locale-selector", [], null, null, null, View_LocaleSelectorComponent_0, RenderType_LocaleSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i4.LocaleSelectorComponent, [i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocaleSelectorComponentNgFactory = i1.ɵccf("yf-locale-selector", i4.LocaleSelectorComponent, View_LocaleSelectorComponent_Host_0, {}, {}, []);
export { LocaleSelectorComponentNgFactory as LocaleSelectorComponentNgFactory };
