
export interface ActivableDto<ID> extends Dto<ID> {
    active: boolean;
}

export class ActivityDto implements Dto<number> {
    arDescription: string;
    arName: string;
    category: CategoryDto;
    description: string;
    enName: string;
    frName: string;
    icon: string;
    id: number;
    maName: string;
    name: string;
    position: number;
    tag: string;
}

export class ReviewDto implements Dto<number> {
    id: number;
    rating: number;
    text: string;
}

export class AddReviewDto extends ReviewDto {
    authorUid: string;
    targetUid: string;
}

export class AddressDto implements Dto<number> {
    country: string;
    formatted: string;
    id: number;
    locality: string;
    location: Location;
    placeId: string;
    postalCode: string;
    route: string;
    streetNumber: string;
}

export class AddressDtoBuilder {
}

export class ProfileDto implements Dto<number> {
    activities: ActivityDto[];
    address: AddressDto;
    applyRadius: boolean;
    birthDay: Date;
    civility: Civility;
    description: string;
    email: string;
    experience: number;
    filled: boolean;
    firstName: string;
    gender: Gender;
    hasPhone: boolean;
    id: number;
    lastName: string;
    medias: string[];
    phone: string;
    picture: string;
    reviewsAverage: number;
    searchRadius: number;
    showPhone: boolean;
    type: UserType;
    uid: string;
    verification: ProfileVerificationDto;
    worker: boolean;
}

export class AdminProfileDto extends ProfileDto {
    bonus: BonusDto;
    score: number;
}

export class RequestDto implements LongDto {
    activity: ActivityDto;
    address: AddressDto;
    budget: number;
    budgetType: BudgetType;
    date: Date;
    description: string;
    id: number;
    status: RequestStatus;
    user: BasicUserDto;
}

export class AdminRequestDto extends RequestDto {
    rejectionCause: string;
}

export class AdminRequestSearchCriteria {
    requestId: number;
}

export class DisplayReviewDto extends ReviewDto {
    date: Date;
    user: BasicUserDto;
}

export class AdminReviewDto extends DisplayReviewDto {
    rejectionCause: string;
    status: ReviewStatus;
}

export class AdminUserSearchCriteria {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    uid: string;
}

export interface AuditorUser {
    username: string;
}

export class BaseUserDto implements ActivableDto<number> {
    active: boolean;
    filled: boolean;
    id: number;
    uid: string;
    userProvider: UserProvider;
}

export class BasicUserDto extends BaseUserDto {
    firstName: string;
    gender: Gender;
    lastName: string;
    picture: string;
    showPhone: boolean;
}

export class BonusDto {
    bonus: number;
    bonusEndDate: Date;
    bonusStartDate: Date;
}

export class CategoryDto implements Dto<number> {
    activities: ActivityDto[];
    arDescription: string;
    arName: string;
    description: string;
    enName: string;
    frName: string;
    icon: string;
    id: number;
    maName: string;
    name: string;
    position: number;
    tag: string;
}

export class ChangePasswordDto {
    newPassword: string;
    newPasswordConfirmation: string;
    oldPassword: string;
}

export class ChangePasswordDtoBuilder {
}

export class Client {
    address: string;
    city: string;
    country: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    postalCode: string;
}

export class ConversationDto implements Dto<number> {
    id: number;
    interlocutor: BasicUserDto;
    lastMessageDate: Date;
    messages: MessageDto[];
    unreadMessagesCount: number;
}

export class DisplayRequestDto extends RequestDto {
    distance: string;
}

export interface Dto<ID> {
    id: ID;
}

export class Enterprise {
    address: string;
    city: string;
    country: string;
    email: string;
    logo: string;
    name: string;
    number: string;
    phone: string;
    postalCode: string;
}

export class ErrorDto {
    code: string;
    detail: string;
    message: string;
    source: string;
}

export class ErrorsDto {
    code: string;
    detail: string;
    errors: ErrorDto[];
    message: string;
}

export class IntegerRange {
    max: number;
    min: number;
}

export interface Iterable<T> {
}

export class Location {
    latitude: number;
    longitude: number;
}

export interface LongDto extends Dto<number> {
}

export class MessageDto implements Dto<number> {
    content: string;
    id: number;
    readingTime: Date;
    receivingStatus: MessageReceivingStatus;
    sender: boolean;
    sendingStatus: MessageSendingStatus;
    sendingTime: Date;
}

export class NewUserProfileDto extends BaseUserDto {
    login: string;
    password: string;
    profile: AdminProfileDto;
}

export class OfferDto implements LongDto {
    amount: number;
    date: Date;
    id: number;
    message: string;
    status: OfferStatus;
    user: BasicUserDto;
}

export class Order {
    ascending: boolean;
    descending: boolean;
    direction: Direction;
    ignoreCase: boolean;
    nullHandling: NullHandling;
    property: string;
}

export interface Page<T> extends Slice<T> {
    totalElements: number;
    totalPages: number;
}

export interface Pageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: Sort;
    unpaged: boolean;
}

export class PasswordResetDto {
    newPassword: string;
    newPasswordConfirmation: string;
}

export class ProfileVerificationStatusDto {
    emailStatus: VerificationStatus;
    idCardStatus: VerificationStatus;
    phoneStatus: VerificationStatus;
    pictureStatus: VerificationStatus;
    profileStatus: VerificationStatus;
    workerCardStatus: VerificationStatus;
}

export class ProfileVerificationDto extends ProfileVerificationStatusDto {
    idCard: string;
    idCardRejectionCause: string;
    pictureRejectionCause: string;
    profileRejectionCause: string;
    workerCard: string;
    workerCardRejectionCause: string;
}

export class PromotionDisplayRequestDto {
    city: string;
    requests: Page<DisplayRequestDto>;
}

export class PromotionResultDto<T> {
    city: string;
    page: Page<T>;
    type: PromotionResultType;
}

export class QuotationDto implements Dto<number> {
    client: Client;
    date: Date;
    enterprise: Enterprise;
    htPrice: number;
    id: number;
    number: number;
    rows: QuotationRowDto[];
    title: string;
    ttcPrice: number;
    tvaPercentage: number;
    type: QuotationType;
}

export class QuotationGroupDto implements Dto<number> {
    id: number;
    name: string;
    position: number;
    priceTotal: number;
    rows: QuotationRowDto[];
}

export class QuotationRowDto implements Dto<number> {
    id: number;
    name: string;
    position: number;
    priceTotal: number;
    quantity: number;
    type: QuotationRowType;
    unit: Unit;
    unityPrice: number;
}

export class ReportDto implements LongDto {
    id: number;
    message: string;
    status: ReportStatus;
}

export class RequestContext {
    cookies: { [index: string]: string };
    currentUser: UserAccountDto;
    headers: { [index: string]: string };
    hostUrl: string;
    lang: string;
    queryParams: { [index: string]: string };
}

export class RequestContextBuilder {
}

export class RequestReportDto extends ReportDto {
    requestId: number;
}

export class RequestSearchCriteria {
    activity: ActivityDto;
    address: AddressDto;
    budgetRange: IntegerRange;
    budgetType: BudgetType;
    radius: number;
    searchType: SearchType;
}

export class RequestVerificationReportDto {
    rejectionCause: string;
    rejectionType: RequestRejectionType;
    status: VerificationStatus;
}

export interface RestProperties {
}

export class ReviewRatingDto {
    text: string;
    value: number;
}

export class ReviewReportDto extends ReportDto {
    reviewId: number;
}

export class ReviewVerificationReportDto {
    rejectionCause: string;
    rejectionType: ReviewRejectionType;
    status: ReviewStatus;
}

export class RoleDto implements Dto<number> {
    authority: string;
    id: number;
    name: UserRole;
}

export class SearchCriteriaDto {
    activity: ActivityDto;
    address: AddressDto;
    radius: number;
    searchType: SearchType;
}

export class SendMessageDto {
    message: string;
    receiverUid: string;
    senderUid: string;
}

export class SessionDto {
    expirationDate: Date;
    token: string;
    user: UserAccountDto;
}

export interface Slice<T> extends Streamable<T> {
    content: T[];
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: Pageable;
    size: number;
    sort: Sort;
}

export class Sort implements Streamable<Order> {
    sorted: boolean;
    unsorted: boolean;
}

export interface Streamable<T> extends Iterable<T> {
}

export class UserAccountDto extends BasicUserDto implements AuditorUser {
    email: string;
    enabled: boolean;
    roles: RoleDto[];
    socialMediaProvider: SocialMediaProvider;
    username: string;
    worker: boolean;
}

export class UserLoginFormDto {
    login: string;
    password: string;
}

export class UserProfileDto extends BaseUserDto {
    profile: ProfileDto;
}

export class UserRegistrationDto implements Dto<number> {
    acceptTermsOfService: boolean;
    email: string;
    id: number;
    password: string;
}

export class UserRegistrationDtoBuilder {
}

export class UserReportDto extends ReportDto {
    userUid: string;
}

export class UserResultDto extends BasicUserDto {
    activities: ActivityDto[];
    address: AddressDto;
    distance: string;
    mediaCount: number;
    reviewsAverage: number;
    verification: UserResultVerificationDto;
}

export class UserResultVerificationDto {
    emailVerified: boolean;
    idCardVerified: boolean;
    phoneVerified: boolean;
    profileVerified: boolean;
    workerCardVerified: boolean;
}

export class UserTypeUpdateDto {
    type: UserType;
}

export class UserViewDto extends BasicUserDto {
    activities: ActivityDto[];
    address: AddressDto;
    birthDay: Date;
    description: string;
    experience: number;
    medias: string[];
    reviewsAverage: number;
    verification: UserResultVerificationDto;
    worker: boolean;
}

export class VerificationReportDto {
    rejectionCause: string;
    rejectionType: RejectionType;
    status: VerificationStatus;
    verificationType: VerificationType;
}

export class ViewDto implements Dto<number> {
    date: Date;
    id: number;
    ip: string;
    profileId: number;
}

export class WorkerDto extends UserProfileDto {
    distance: string;
}

export type BudgetType = "PER_HOUR" | "PER_DAY" | "GLOBAL" | "ON_QUOTATION";

export type Civility = "M" | "MS";

export type Direction = "ASC" | "DESC";

export type Gender = "MAN" | "WOMAN";

export type MessageReceivingStatus = "READ" | "UNREAD";

export type MessageSendingStatus = "DISTRIBUTED" | "FAILED";

export type NullHandling = "NATIVE" | "NULLS_FIRST" | "NULLS_LAST";

export type OfferStatus = "CLOSED" | "PENDING" | "ACCEPTED" | "REFUSED";

export type PromotionResultType = "LAST" | "ADDRESS";

export type QuotationRowType = "TITLE" | "CONTENT" | "SUBTOTAL";

export type QuotationType = "WITH_TVA" | "WITHOUT_TVA" | "WORKER";

export type RejectionType = "CUSTOM" | "NOT_PERSONAL_PICTURE" | "NAME_INVALID" | "PERSONAL_INFO_NOT_MATCHING_ID_CARD" | "PERSONAL_INFO_NOT_MATCHING_WORKER_CARD" | "DOCUMENT_NOT_VISIBLE" | "DOCUMENT_INVALID";

export type ReportStatus = "PENDING" | "CANCELLED" | "CLOSED" | "PROCESSED";

export type RequestRejectionType = "CUSTOM" | "DUPLICATED" | "NOT_DETAILED" | "NOT_A_REQUEST";

export type RequestStatus = "OPENED" | "PENDING" | "REJECTED" | "CLOSED";

export type ReviewRejectionType = "CUSTOM" | "DUPLICATED" | "INAPPROPRIATE";

export type ReviewStatus = "APPROVED" | "REJECTED" | "PENDING";

export type SearchType = "AROUND_POINT" | "IN_AREA" | "IN_CITY";

export type SocialMediaProvider = "FACEBOOK" | "TWITTER" | "GOOGLE" | "LINKEDIN" | "LOCAL";

export type Unit = "M2" | "M" | "g";

export type UserProvider = "LOCAL" | "SOCIAL";

export type UserRole = "ROLE_ADMIN" | "ROLE_MANAGER" | "ROLE_USER" | "ROLE_VERIFIER";

export type UserType = "BASIC" | "PROFESSIONAL";

export type VerificationStatus = "NONE" | "PENDING" | "APPROVED" | "REJECTED";

export type VerificationType = "PICTURE" | "PROFILE" | "ID_CARD" | "WORKER_CARD";


// Added by 'EnumConstantsExtension' extension

export const BudgetType = {
    PER_HOUR: <BudgetType>"PER_HOUR",
    PER_DAY: <BudgetType>"PER_DAY",
    GLOBAL: <BudgetType>"GLOBAL",
    ON_QUOTATION: <BudgetType>"ON_QUOTATION",
}

export const Civility = {
    M: <Civility>"M",
    MS: <Civility>"MS",
}

export const Direction = {
    ASC: <Direction>"ASC",
    DESC: <Direction>"DESC",
}

export const Gender = {
    MAN: <Gender>"MAN",
    WOMAN: <Gender>"WOMAN",
}

export const MessageReceivingStatus = {
    READ: <MessageReceivingStatus>"READ",
    UNREAD: <MessageReceivingStatus>"UNREAD",
}

export const MessageSendingStatus = {
    DISTRIBUTED: <MessageSendingStatus>"DISTRIBUTED",
    FAILED: <MessageSendingStatus>"FAILED",
}

export const NullHandling = {
    NATIVE: <NullHandling>"NATIVE",
    NULLS_FIRST: <NullHandling>"NULLS_FIRST",
    NULLS_LAST: <NullHandling>"NULLS_LAST",
}

export const OfferStatus = {
    CLOSED: <OfferStatus>"CLOSED",
    PENDING: <OfferStatus>"PENDING",
    ACCEPTED: <OfferStatus>"ACCEPTED",
    REFUSED: <OfferStatus>"REFUSED",
}

export const PromotionResultType = {
    LAST: <PromotionResultType>"LAST",
    ADDRESS: <PromotionResultType>"ADDRESS",
}

export const QuotationRowType = {
    TITLE: <QuotationRowType>"TITLE",
    CONTENT: <QuotationRowType>"CONTENT",
    SUBTOTAL: <QuotationRowType>"SUBTOTAL",
}

export const QuotationType = {
    WITH_TVA: <QuotationType>"WITH_TVA",
    WITHOUT_TVA: <QuotationType>"WITHOUT_TVA",
    WORKER: <QuotationType>"WORKER",
}

export const RejectionType = {
    CUSTOM: <RejectionType>"CUSTOM",
    NOT_PERSONAL_PICTURE: <RejectionType>"NOT_PERSONAL_PICTURE",
    NAME_INVALID: <RejectionType>"NAME_INVALID",
    PERSONAL_INFO_NOT_MATCHING_ID_CARD: <RejectionType>"PERSONAL_INFO_NOT_MATCHING_ID_CARD",
    PERSONAL_INFO_NOT_MATCHING_WORKER_CARD: <RejectionType>"PERSONAL_INFO_NOT_MATCHING_WORKER_CARD",
    DOCUMENT_NOT_VISIBLE: <RejectionType>"DOCUMENT_NOT_VISIBLE",
    DOCUMENT_INVALID: <RejectionType>"DOCUMENT_INVALID",
}

export const ReportStatus = {
    PENDING: <ReportStatus>"PENDING",
    CANCELLED: <ReportStatus>"CANCELLED",
    CLOSED: <ReportStatus>"CLOSED",
    PROCESSED: <ReportStatus>"PROCESSED",
}

export const RequestRejectionType = {
    CUSTOM: <RequestRejectionType>"CUSTOM",
    DUPLICATED: <RequestRejectionType>"DUPLICATED",
    NOT_DETAILED: <RequestRejectionType>"NOT_DETAILED",
    NOT_A_REQUEST: <RequestRejectionType>"NOT_A_REQUEST",
}

export const RequestStatus = {
    OPENED: <RequestStatus>"OPENED",
    PENDING: <RequestStatus>"PENDING",
    REJECTED: <RequestStatus>"REJECTED",
    CLOSED: <RequestStatus>"CLOSED",
}

export const ReviewRejectionType = {
    CUSTOM: <ReviewRejectionType>"CUSTOM",
    DUPLICATED: <ReviewRejectionType>"DUPLICATED",
    INAPPROPRIATE: <ReviewRejectionType>"INAPPROPRIATE",
}

export const ReviewStatus = {
    APPROVED: <ReviewStatus>"APPROVED",
    REJECTED: <ReviewStatus>"REJECTED",
    PENDING: <ReviewStatus>"PENDING",
}

export const SearchType = {
    AROUND_POINT: <SearchType>"AROUND_POINT",
    IN_AREA: <SearchType>"IN_AREA",
    IN_CITY: <SearchType>"IN_CITY",
}

export const SocialMediaProvider = {
    FACEBOOK: <SocialMediaProvider>"FACEBOOK",
    TWITTER: <SocialMediaProvider>"TWITTER",
    GOOGLE: <SocialMediaProvider>"GOOGLE",
    LINKEDIN: <SocialMediaProvider>"LINKEDIN",
    LOCAL: <SocialMediaProvider>"LOCAL",
}

export const Unit = {
    M2: <Unit>"M2",
    M: <Unit>"M",
    g: <Unit>"g",
}

export const UserProvider = {
    LOCAL: <UserProvider>"LOCAL",
    SOCIAL: <UserProvider>"SOCIAL",
}

export const UserRole = {
    ROLE_ADMIN: <UserRole>"ROLE_ADMIN",
    ROLE_MANAGER: <UserRole>"ROLE_MANAGER",
    ROLE_USER: <UserRole>"ROLE_USER",
    ROLE_VERIFIER: <UserRole>"ROLE_VERIFIER",
}

export const UserType = {
    BASIC: <UserType>"BASIC",
    PROFESSIONAL: <UserType>"PROFESSIONAL",
}

export const VerificationStatus = {
    NONE: <VerificationStatus>"NONE",
    PENDING: <VerificationStatus>"PENDING",
    APPROVED: <VerificationStatus>"APPROVED",
    REJECTED: <VerificationStatus>"REJECTED",
}

export const VerificationType = {
    PICTURE: <VerificationType>"PICTURE",
    PROFILE: <VerificationType>"PROFILE",
    ID_CARD: <VerificationType>"ID_CARD",
    WORKER_CARD: <VerificationType>"WORKER_CARD",
}
