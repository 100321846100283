import {Component, EventEmitter, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {authenticationActions, loginModalActions} from '../../../../core/store/actions';
import {ModalEvent, Notification, UserLoginFormDto} from '../../../../core/models';
import {ErrorUtils} from '../../../../core/utils';
import * as fromRoot from '../../../../store';

@Component({
    moduleId: module.id,
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

    loading$: Observable<boolean>;
    notification$: Observable<Notification>;
    authenticated$: Observable<boolean>;
    modalEventListener$: Observable<ModalEvent>;
    initListener$ = new EventEmitter();

    constructor(private store: Store<fromRoot.State>) {
        this.modalEventListener$ = this.store.pipe(select(fromRoot.getLoginModalEvent));
        this.loading$ = this.store.pipe(select(fromRoot.isAuthLoading));
        this.authenticated$ = this.store.pipe(select(fromRoot.isAuthenticated));
        this.notification$ = this.store.pipe(
            select(fromRoot.getAuthErrors),
            map(ErrorUtils.toNotification)
        );
    }

    ngOnInit() {
        this.authenticated$.subscribe(authenticated => {
            if (authenticated) {
                this.closeModal();
            }
        });
    }

    init() {
        this.initListener$.next();
    }

    login(userLoginDto: UserLoginFormDto) {
        this.store.dispatch(new authenticationActions.AuthenticateAction(userLoginDto));
    }

    closeModal() {
        this.store.dispatch(new loginModalActions.CloseModalAction());
    }

}
