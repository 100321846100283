import {Injectable} from '@angular/core';
import {IAnalyticsService} from '../models';

@Injectable({
    providedIn: 'root'
})
export abstract class AnalyticsService implements IAnalyticsService {
    abstract pageTrack(path: string): void;

    abstract eventTrack(action: string, properties: any): void;

    abstract exceptionTrack(properties: any): void;

    abstract setUsername(userId: string): void;

    abstract trackService(serviceName: string);
}
