export var AccountVerificationActionTypes;
(function (AccountVerificationActionTypes) {
    AccountVerificationActionTypes["SubmitPhoneVerification"] = "[ACCOUNT_VERIFICATION] SubmitPhoneVerification";
    AccountVerificationActionTypes["SubmitPhoneVerificationSuccess"] = "[ACCOUNT_VERIFICATION] SubmitPhoneVerificationSuccess";
    AccountVerificationActionTypes["SubmitPhoneVerificationFail"] = "[ACCOUNT_VERIFICATION] SubmitPhoneVerificationFail";
    AccountVerificationActionTypes["ResetPhoneVerification"] = "[ACCOUNT_VERIFICATION] ResetPhoneVerification";
    AccountVerificationActionTypes["VerifyPhoneNumber"] = "[ACCOUNT_VERIFICATION] VerifyPhoneNumber";
    AccountVerificationActionTypes["VerifyPhoneNumberSuccess"] = "[ACCOUNT_VERIFICATION] VerifyPhoneNumberSuccess";
    AccountVerificationActionTypes["VerifyPhoneNumberFail"] = "[ACCOUNT_VERIFICATION] VerifyPhoneNumberFail";
    AccountVerificationActionTypes["VerifyEmail"] = "[ACCOUNT_VERIFICATION] VerifyEmail";
    AccountVerificationActionTypes["VerifyEmailSuccess"] = "[ACCOUNT_VERIFICATION] VerifyEmailSuccess";
    AccountVerificationActionTypes["VerifyEmailFail"] = "[ACCOUNT_VERIFICATION] VerifyEmailFail";
    AccountVerificationActionTypes["ResetEmailVerification"] = "[ACCOUNT_VERIFICATION] ResetEmailVerification";
})(AccountVerificationActionTypes || (AccountVerificationActionTypes = {}));
var SubmitPhoneVerification = /** @class */ (function () {
    function SubmitPhoneVerification() {
        this.type = AccountVerificationActionTypes.SubmitPhoneVerification;
    }
    return SubmitPhoneVerification;
}());
export { SubmitPhoneVerification };
var SubmitPhoneVerificationSuccess = /** @class */ (function () {
    function SubmitPhoneVerificationSuccess() {
        this.type = AccountVerificationActionTypes.SubmitPhoneVerificationSuccess;
    }
    return SubmitPhoneVerificationSuccess;
}());
export { SubmitPhoneVerificationSuccess };
var SubmitPhoneVerificationFail = /** @class */ (function () {
    function SubmitPhoneVerificationFail(payload) {
        this.payload = payload;
        this.type = AccountVerificationActionTypes.SubmitPhoneVerificationFail;
    }
    return SubmitPhoneVerificationFail;
}());
export { SubmitPhoneVerificationFail };
var ResetPhoneVerification = /** @class */ (function () {
    function ResetPhoneVerification() {
        this.type = AccountVerificationActionTypes.ResetPhoneVerification;
    }
    return ResetPhoneVerification;
}());
export { ResetPhoneVerification };
var VerifyPhoneNumberAction = /** @class */ (function () {
    function VerifyPhoneNumberAction(payload) {
        this.payload = payload;
        this.type = AccountVerificationActionTypes.VerifyPhoneNumber;
    }
    return VerifyPhoneNumberAction;
}());
export { VerifyPhoneNumberAction };
var VerifyPhoneNumberSuccessAction = /** @class */ (function () {
    function VerifyPhoneNumberSuccessAction() {
        this.type = AccountVerificationActionTypes.VerifyPhoneNumberSuccess;
    }
    return VerifyPhoneNumberSuccessAction;
}());
export { VerifyPhoneNumberSuccessAction };
var VerifyPhoneNumberFailAction = /** @class */ (function () {
    function VerifyPhoneNumberFailAction(payload) {
        this.payload = payload;
        this.type = AccountVerificationActionTypes.VerifyPhoneNumberFail;
    }
    return VerifyPhoneNumberFailAction;
}());
export { VerifyPhoneNumberFailAction };
var VerifyEmailAction = /** @class */ (function () {
    function VerifyEmailAction() {
        this.type = AccountVerificationActionTypes.VerifyEmail;
    }
    return VerifyEmailAction;
}());
export { VerifyEmailAction };
var VerifyEmailSuccessAction = /** @class */ (function () {
    function VerifyEmailSuccessAction() {
        this.type = AccountVerificationActionTypes.VerifyEmailSuccess;
    }
    return VerifyEmailSuccessAction;
}());
export { VerifyEmailSuccessAction };
var VerifyEmailFailAction = /** @class */ (function () {
    function VerifyEmailFailAction(payload) {
        this.payload = payload;
        this.type = AccountVerificationActionTypes.VerifyEmailFail;
    }
    return VerifyEmailFailAction;
}());
export { VerifyEmailFailAction };
var ResetEmailVerification = /** @class */ (function () {
    function ResetEmailVerification() {
        this.type = AccountVerificationActionTypes.ResetEmailVerification;
    }
    return ResetEmailVerification;
}());
export { ResetEmailVerification };
