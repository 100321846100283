<div class="row" yfLayoutClass>
    <div class="col-12" *ngIf="notification; else spinner">
        <div class="card center">
            <div class="card-block">
                <div class="row">
                    <div class="col-12">
                        <app-notification [notification]="notification"></app-notification>
                    </div>
                    <div class="col-12">
                        <a routerLink="/register" routerLinkActive="active" translate>nav-bar.sign-up</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #spinner>
        <div class="col-12 center">
            <app-spinner></app-spinner>
        </div>
    </ng-template>
</div>