import {Action} from '@ngrx/store';
import {DisplayRequestDto, ErrorsDto, Page, Pageable} from '../../../core/models';

export enum AccountRequestsActionTypes {
    LoadRequests = '[USER REQUESTS] LOAD_REQUESTS',
    LoadRequestsSuccess = '[USER REQUESTS] LOAD_REQUESTS_SUCCESS',
    LoadRequestsFail = '[USER REQUESTS] LOAD_REQUESTS_FAIL'
}

/**
 * Conversations.
 */
export class LoadRequestsAction implements Action {
    readonly type = AccountRequestsActionTypes.LoadRequests;

    constructor(public payload: Pageable) {
    }
}

export class LoadRequestsSuccessAction implements Action {
    readonly type = AccountRequestsActionTypes.LoadRequestsSuccess;

    constructor(public payload: Page<DisplayRequestDto>) {
    }
}

export class LoadRequestsFailAction implements Action {
    readonly type = AccountRequestsActionTypes.LoadRequestsFail;

    constructor(public payload: ErrorsDto) {
    }
}

export type Actions = LoadRequestsAction
    | LoadRequestsSuccessAction
    | LoadRequestsFailAction;
