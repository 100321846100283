import {Injectable} from '@angular/core';
import {Params} from '@angular/router';

import {Pageable, RegistrationConfirmationParams, RequestSearchCriteria, SearchCriteriaDto} from '../models';
import {CommonUtils, HttpUtil} from '../utils';

@Injectable({
    providedIn: 'root'
})
export class ParamsService {

    static PAGE_NUM_PARAM = 'p';
    static SORT_PARAM = 'sort';
    static EMAIL_PARAM = 'email';
    static POST_SIGN_UP_PARAM = 'postSignUp';
    static LATITUDE_PARAM = 'lat';
    static LONGITUDE_PARAM = 'lng';
    static ACTIVITY_ID_PARAM = 'activityId';
    static ACTIVITY_NAME_PARAM = 'activityName';
    static ADDRESS_PARAM = 'address';
    static RADIUS_PARAM = 'radius';
    static BUDGET_TYPE_PARAM = 'budgetType';
    static BUDGET_MIN_PARAM = 'budgetMin';
    static BUDGET_MAX_PARAM = 'budgetMax';

    fillPageable(params: Params, pageable: Pageable): Pageable {
        pageable.page = +params[ParamsService.PAGE_NUM_PARAM] ? +params[ParamsService.PAGE_NUM_PARAM] - 1 : 0;
        pageable.sort = HttpUtil.stringToSortArray(params[ParamsService.SORT_PARAM]);

        return pageable;
    }

    buildPageable(params: Params): Pageable {
        return this.fillPageable(params, new Pageable());
    }

    buildRegistrationConfirmationParams(data: RegistrationConfirmationParams): Params {
        return {
            [ParamsService.EMAIL_PARAM]: data.email,
            [ParamsService.POST_SIGN_UP_PARAM]: data.postSignUp
        }
    }

    buildRegistrationConfirmationFromParams(params: Params): RegistrationConfirmationParams {
        let email = params[ParamsService.EMAIL_PARAM];
        let postSignUp = CommonUtils.toBoolean(params[ParamsService.POST_SIGN_UP_PARAM]);

        return RegistrationConfirmationParams.build(email, postSignUp);
    }

    convertPageableToParams(pageable: Pageable): Params {

        let params = [];
        params[ParamsService.PAGE_NUM_PARAM] = pageable.page;

        if (pageable.sort && pageable.sort.length) {
            params[ParamsService.SORT_PARAM] = HttpUtil.sortArrayToString(pageable.sort);
        }

        return params;
    }

    convertSearchCriteriaToParams(searchCriteria: SearchCriteriaDto): Params {

        let params = [];

        if (searchCriteria.address && searchCriteria.address.location) {
            params[ParamsService.LATITUDE_PARAM] = searchCriteria.address.location.latitude;
            params[ParamsService.LONGITUDE_PARAM] = searchCriteria.address.location.longitude;
            params[ParamsService.ADDRESS_PARAM] = searchCriteria.address.formatted;
        }

        if (searchCriteria.activity) {
            params[ParamsService.ACTIVITY_ID_PARAM] = searchCriteria.activity.id;
            params[ParamsService.ACTIVITY_NAME_PARAM] = searchCriteria.activity.name;
        }

        if (searchCriteria.radius) {
            params[ParamsService.RADIUS_PARAM] = searchCriteria.radius;
        }

        return params;
    }

    buildPasswordForgottenParams(email: string): Params {
        return {
            [ParamsService.EMAIL_PARAM]: email,
        }
    }

    buildPasswordForgottenFormParams(params: Params): string {
        return params[ParamsService.EMAIL_PARAM];
    }

    convertRequestSearchCriteriaToParams(searchCriteria: RequestSearchCriteria): Params {

        let params = [];

        if (searchCriteria.address && searchCriteria.address.location) {
            params[ParamsService.LATITUDE_PARAM] = searchCriteria.address.location.latitude;
            params[ParamsService.LONGITUDE_PARAM] = searchCriteria.address.location.longitude;
            params[ParamsService.ADDRESS_PARAM] = searchCriteria.address.formatted;
        }

        if (searchCriteria.activity) {
            params[ParamsService.ACTIVITY_ID_PARAM] = searchCriteria.activity.id;
            params[ParamsService.ACTIVITY_NAME_PARAM] = searchCriteria.activity.name;
        }

        if (searchCriteria.budgetType) {
            params[ParamsService.BUDGET_TYPE_PARAM] = searchCriteria.budgetType;
        }

        if (searchCriteria.budgetRange) {
            params[ParamsService.BUDGET_MIN_PARAM] = searchCriteria.budgetRange.min;
            params[ParamsService.BUDGET_MAX_PARAM] = searchCriteria.budgetRange.max;
        }

        if (searchCriteria.radius) {
            params[ParamsService.RADIUS_PARAM] = searchCriteria.radius;
        }

        return params;
    }

    convertPageableRequestSearchCriteriaToParams(searchCriteria: RequestSearchCriteria, pageable: Pageable) {
        let params = this.convertRequestSearchCriteriaToParams(searchCriteria);
        let pageableParams = this.convertPageableToParams(pageable);

        CommonUtils.concatParams(params, pageableParams);

        return params;
    }
}
