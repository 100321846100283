import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

import {ErrorsDto, Notification, NotificationType} from '../../../core/models';
import {ErrorUtils} from '../../../core/utils';

@Component({
    moduleId: module.id,
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {

    _notification: Notification;
    @Input() closable = true;
    @Input() notificationType = NotificationType.ERROR;

    @Output() closed = new EventEmitter<any>();

    NotificationType = NotificationType;
    showNotification: boolean;

    @Input() set notification(notif: Notification | ErrorsDto) {
        if (!notif) {
            return;
        }

        if (this.instanceOfErrorsDto(notif)) {
            this._notification = ErrorUtils.toNotification(notif);
        } else {
            this._notification = notif;
        }
    }

    closeNotification() {
        this._notification = null;
        this.showNotification = false;
        this.closed.emit();
    }

    displayBasicNotification(msg: string, notifType: NotificationType) {
        let notif = new Notification();
        notif.type = notifType;
        notif.title = msg;

        this.displayNotification(notif);
    }

    displayNotification(notification: Notification) {
        this._notification = notification;
        this.showNotification = true;
    }

    getNotificationClass() {

        let type = this._notification && this._notification.type ? this._notification.type : this.notificationType;

        switch (type) {
            case NotificationType.ERROR :
                return 'error';
            case NotificationType.WARNING :
                return 'warning';
            case NotificationType.INFO :
                return 'info';
            case NotificationType.SUCCESS :
                return 'success';
        }

        return '';
    }

    displaySuccessMessage(message: string) {
        this.displayBasicNotification(message, NotificationType.SUCCESS);
    }

    displayErrorMessage(message: string) {
        this.displayBasicNotification(message, NotificationType.ERROR);
    }

    displayErrors(errors: ErrorsDto, notificationType = NotificationType.ERROR) {
        this.displayNotification(this.toNotification(errors, notificationType));
    }

    private toNotification(error: ErrorsDto, notificationType = NotificationType.ERROR): Notification {

        let notif = null;

        if (error) {
            notif = new Notification();
            notif.type = notificationType;
            notif.title = error.message;

            if (error.errors) {
                error.errors.forEach(err => notif.addMessage(err.message));
            }

        }

        return notif;
    }

    private instanceOfErrorsDto(object: any): object is ErrorsDto {
        return object && object.code !== undefined;
    }
}
