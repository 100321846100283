import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { HttpUtil } from '../utils';
import { ValidationService } from './validation.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./validation.service";
var SignUpService = /** @class */ (function () {
    function SignUpService(httpClient, validationService) {
        this.httpClient = httpClient;
        this.validationService = validationService;
    }
    SignUpService.prototype.signUp = function (userRegistration) {
        var errorsDto = this.validationService.validateUserRegistrationForm(userRegistration);
        if (errorsDto) {
            return observableThrowError(errorsDto);
        }
        return this.httpClient.post('api/v1/users', userRegistration);
    };
    SignUpService.prototype.resendEmailConfirmation = function (email) {
        var errorsDto = this.validationService.validateEmail(email);
        if (errorsDto !== null) {
            return observableThrowError(errorsDto);
        }
        var url = HttpUtil.buildAndExpand('api/v1/account/{email}/send/activation', email);
        return this.httpClient.post(url, null);
    };
    SignUpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignUpService_Factory() { return new SignUpService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ValidationService)); }, token: SignUpService, providedIn: "root" });
    return SignUpService;
}());
export { SignUpService };
