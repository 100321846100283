export var ShareModalActionTypes;
(function (ShareModalActionTypes) {
    ShareModalActionTypes["OpenModal"] = "[SHARE_MODAL] OpenModal";
    ShareModalActionTypes["CloseModal"] = "[SHARE_MODAL] CloseModal";
})(ShareModalActionTypes || (ShareModalActionTypes = {}));
var OpenModalAction = /** @class */ (function () {
    function OpenModalAction() {
        this.type = ShareModalActionTypes.OpenModal;
    }
    return OpenModalAction;
}());
export { OpenModalAction };
var CloseModalAction = /** @class */ (function () {
    function CloseModalAction() {
        this.type = ShareModalActionTypes.CloseModal;
    }
    return CloseModalAction;
}());
export { CloseModalAction };
