import {Component} from '@angular/core';
import {PressArticle} from '../../../../core/models/press-article';

@Component({
    selector: 'app-promotion-press',
    templateUrl: './promotion-press.component.html',
    styleUrls: ['./promotion-press.component.scss']
})
export class PromotionPressComponent {

    articles: PressArticle[] = [
        new PressArticle('HuffPost Maghreb', 'huffpost.png', 'http://huffp.st/Ddl8iLr'),
        new PressArticle('Lematin', 'lematin.jpg', 'https://lematin.ma/express/2018/lancement-plateforme-bricool/299645.html'),
        new PressArticle('welovebuzz', 'welovebuzz.png', 'https://www.welovebuzz.com/besoin-dun-service-ou-dun-coup-de-main-bricool-soccupe-de-tout/')
    ];

}
