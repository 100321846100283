import {Action} from '@ngrx/store';
import {ActivityDto, CategoryDto} from '../../models';

export enum ActivityChooserManagerActionTypes {
    SelectActivity = '[ACTIVITY CHOOSER MANAGER] SELECT_ACTIVITY',
    SelectCategory = '[ACTIVITY CHOOSER MANAGER] SELECT_CATEGORY',

    SetActivities = '[ACTIVITY CHOOSER MANAGER] SET_ACTIVITIES',
    SetCategories = '[ACTIVITY CHOOSER MANAGER] SET_CATEGORIES',

    SelectOrDeselectActivity = '[ACTIVITY CHOOSER MANAGER] SELECT_OR_DESELECT_ACTIVITY',

    SetSelectedActivities = '[ACTIVITY CHOOSER MANAGER] SET_SELECTED_ACTIVITIES',
    SetSelectedCategories = '[ACTIVITY CHOOSER MANAGER] SET_SELECTED_CATEGORIES',

    Reset = '[ACTIVITY CHOOSER MANAGER] RESET',
    Init = '[ACTIVITY CHOOSER MANAGER] INIT'
}

export class SelectActivityAction implements Action {
    readonly type = ActivityChooserManagerActionTypes.SelectActivity;

    constructor(public payload: ActivityDto) {
    }
}

export class SelectCategoryAction implements Action {
    readonly type = ActivityChooserManagerActionTypes.SelectCategory;

    constructor(public payload: CategoryDto) {
    }
}

export class SelectOrDeselectActivityAction implements Action {
    readonly type = ActivityChooserManagerActionTypes.SelectOrDeselectActivity;

    constructor(public payload: ActivityDto) {
    }
}

export class SetActivitiesAction implements Action {
    readonly type = ActivityChooserManagerActionTypes.SetActivities;

    constructor(public payload: ActivityDto[]) {
    }
}

export class SetCategoriesAction implements Action {
    readonly type = ActivityChooserManagerActionTypes.SetCategories;

    constructor(public payload: CategoryDto[]) {
    }
}

export class SetSelectedActivitiesAction implements Action {
    readonly type = ActivityChooserManagerActionTypes.SetSelectedActivities;

    constructor(public payload: ActivityDto[]) {
    }
}

export class SetSelectedCategoriesAction implements Action {
    readonly type = ActivityChooserManagerActionTypes.SetSelectedCategories;

    constructor(public payload: CategoryDto[]) {
    }
}

export class InitAction implements Action {
    readonly type = ActivityChooserManagerActionTypes.Init;
}

export class ResetAction implements Action {
    readonly type = ActivityChooserManagerActionTypes.Reset;
}

export type Actions = SelectActivityAction
    | SelectCategoryAction
    | SelectOrDeselectActivityAction
    | SetCategoriesAction
    | SetActivitiesAction
    | SetSelectedActivitiesAction
    | SetSelectedCategoriesAction
    | InitAction
    | ResetAction;

