import {Component, Injector, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {combineLatest, Observable, timer} from 'rxjs';
import {filter, startWith, takeWhile, withLatestFrom} from 'rxjs/operators';
import {Config} from './config';
import {AppHelper} from './helper/app.helper';
import {Locale} from './modules/core/models';
import {AuthService, BrowserService} from './modules/core/services';
import {AnalyticsService} from './modules/core/services/analytics.service';
import {authenticationActions, localeActions, signUpActions} from './modules/core/store/actions';
import {ContextUtils} from './modules/core/utils';
import * as fromRoot from './modules/store';

// require('intro.js/intro.js');
declare var ga: any;
declare var introJs: any;

@Component({
    moduleId: module.id,
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [AppHelper]
})
export class AppComponent implements OnInit, OnDestroy {

    emailNotifClosed: boolean;
    email: string;

    authenticated: boolean;
    authenticated$: Observable<boolean>;

    timeoutInterval: number;
    lastUnreadMessageCountNotificationDate: Date;


    constructor(private router: Router,
                private store: Store<fromRoot.State>,
                private injector: Injector,
                private route: ActivatedRoute,
                private translateService: TranslateService,
                private analyticsService: AnalyticsService,
                private browserService: BrowserService,
                private appHelper: AppHelper,
                private vcRef: ViewContainerRef) {
        this.authenticated$ = this.store.pipe(select(fromRoot.isAuthenticated));
        this.timeoutInterval = 60 * 1000;
    }

    ngOnInit(): void {
        this.translateService.setDefaultLang(Locale.FRENCH.shortcut);

        if (Config.IS_MOBILE_NATIVE()) {
            this.appHelper.checkAuthentication();
            this.appHelper.checkIntro(this.vcRef);
            this.appHelper.checkLang();

            this.appHelper.listenToAppHealthStatus();

            this.appHelper.addLifecycleEventlisteners();
            this.appHelper.checkConnectivity();

            this.appHelper.listenOnRouteToURL();

            this.authenticated$.subscribe(authed => this.authenticated = authed);

            const unreadConversationsCount$ = this.store.pipe(select(fromRoot.getUnreadConversationsCount));

            const timer$ = timer(3000, this.timeoutInterval)
                .pipe(
                    withLatestFrom(this.authenticated$, unreadConversationsCount$),
                    takeWhile(([t, authed]) => {
                        if (authed) {
                            this.authenticated = authed;
                            this.countConversation();
                        }

                        return authed === true;
                    }),
                    filter(() => {
                        let displayNotif = true;
                        if (this.lastUnreadMessageCountNotificationDate) {
                            const expire = new Date(this.lastUnreadMessageCountNotificationDate.getTime() + 3 * 60 * 60 * 1000);
                            displayNotif = expire.getTime() < new Date().getTime();
                        }

                        return displayNotif;
                    })
                ).subscribe(([t, authed, count]) => {
                    if (count) {
                        this.appHelper.notifyNewMessage();
                        this.lastUnreadMessageCountNotificationDate = new Date();
                    }
                });
        } else {
            if (this.browserService.isPlatformServer()) {
                this.initServerSide();
            } else {
                this.initClientSide();

                let langChangeEvent: Observable<LangChangeEvent> = this.translateService.onLangChange.pipe(
                    startWith({lang: this.translateService.currentLang, translations: []})
                );

                combineLatest(langChangeEvent, this.router.events)
                    .subscribe(([langEvent, event]) => {

                        if (event instanceof RoutesRecognized) {
                            let currentUrl = event.url;

                            this.analyticsService.pageTrack(currentUrl);
                            let locale = Locale.getLocaleByShortcut(langEvent.lang);

                            let queryParams = event.state.root.queryParams;

                            if (locale) {
                                if (!ContextUtils.isCurrentLang(currentUrl, locale)) {
                                    let url = ContextUtils.updateLocaleLang(currentUrl, locale);
                                    this.router.navigateByUrl(url, {queryParams: queryParams});
                                }

                                // if (Locale.isArabic(locale)) {
                                //     if (!ContextUtils.isArabicUrl(currentUrl)) {
                                //         this.router.navigateByUrl(ContextUtils.arabizeUrl(currentUrl), {queryParams: queryParams});
                                //     }
                                // } else {
                                //     if (ContextUtils.isArabicUrl(currentUrl)) {
                                //         this.router.navigateByUrl(ContextUtils.frenchifyUrl(currentUrl), {queryParams: queryParams});
                                //     }
                                // }
                            }

                        }
                    });

                this.router.events.pipe(
                    filter(event => event instanceof NavigationEnd)
                ).subscribe(() => {
                    this.browserService.materializeWithDelay(10);
                    this.browserService.scrollToTop();
                });


                // TODO intro.js
                // introJs().start();
            }
        }

    }

    private initClientSide() {
        this.appHelper.initClientSide();
    }

    private initServerSide() {
        let locale: Locale;
        let request: any = this.injector.get(REQUEST);
        if (request) {
            let lang = request.cookies.lang;
            if (lang) {
                locale = Locale.getLocaleByShortcut(lang);
            }
        }

        if (!locale) {
            locale = Locale.FRENCH;
        }

        this.translateService.use(locale.shortcut);

        this.store.dispatch(new localeActions.SetLocaleAction(locale));
    }

    sendEmailConfirmation() {
        if (this.email) {
            this.store.dispatch(new signUpActions.SignUpConfirmationResentEmailAction(this.email));
        }
    }

    closeEmailNotif() {
        this.emailNotifClosed = true;
    }

    ngOnDestroy() {
        this.appHelper.removeLifecycleEventListeners();
        this.appHelper.triggerStopMonitoring();
    }

    countConversation() {
        if (!this.authenticated) {
            return;
        }

        this.store.dispatch(new authenticationActions.CountUnreadConversationsAction())
    }






}

