<div class="main-app">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
</div>

<app-footer class="footer"></app-footer>

<ng-progress [spinner]="false"></ng-progress>
<app-send-message-modal></app-send-message-modal>
<app-show-phone-modal></app-show-phone-modal>
<app-login-modal></app-login-modal>
<app-report-user-modal></app-report-user-modal>
<app-report-review-modal></app-report-review-modal>
<app-share-modal></app-share-modal>
<yf-locale-modal></yf-locale-modal>
        <!--<app-report-request-modal></app-report-request-modal>-->
