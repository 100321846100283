import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {Config} from '../../../../config';
import {userProfileActions} from '../../../account/store/actions';
import * as fromRoot from '../../../store';
import {authenticationActions, routeActions} from '../actions';
import {ErrorsDto, UserProvider} from '../../models';
import {AuthService, BrowserService, NoticeService, RouteService} from '../../services';
import {ErrorUtils} from '../../utils';

@Injectable()
export class AuthenticationEffects {

    @Effect()
    authenticate = this.actions$.pipe(
        ofType<authenticationActions.AuthenticateAction>(authenticationActions.AuthenticationActionTypes.Authenticate),
        map(action => action.payload),
        mergeMap(userLoginForm => {
                return this.authService.authenticate(userLoginForm).pipe(
                    mergeMap(session => {
                            return [new authenticationActions.AuthenticateSuccessAction(session.user)]
                        }
                    ),
                    catchError((errors: ErrorsDto) => {

                        if (!errors) {
                            errors = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.credentials.incorrect'));
                        }

                        return of(new authenticationActions.AuthenticateFailureAction(errors))
                    })
                )
            }
        )
    );

    @Effect()
    authenticateSuccess = this.actions$.pipe(
        ofType<authenticationActions.AuthenticateSuccessAction>(authenticationActions.AuthenticationActionTypes.AuthenticateSuccess),
        map(action => action.payload),
        mergeMap(user => {
            let postLoginAct: any;

            if (user.filled) {
                let redirectUrl = this.routeService.redirectUrl;
                if (redirectUrl) {
                    postLoginAct = new routeActions.ToUrlAction(redirectUrl);
                    this.routeService.redirectUrl = null;
                } else {

                    if (user.userProvider === UserProvider.SOCIAL) {
                        postLoginAct = new routeActions.ToCompleteUserProfileAction();
                    } else {
                        postLoginAct = new routeActions.ToUserProfileAction();
                    }
                }
            } else {
                if (Config.IS_MOBILE_NATIVE()) {
                    postLoginAct = new routeActions.ToUserProfileAction();
                } else {
                    postLoginAct = new routeActions.ToCompleteUserProfileAction();
                }
            }

            return [postLoginAct]
        }),
        catchError((errors: ErrorsDto) => {

            if (!errors) {
                errors = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.credentials.incorrect'));
            }

            return of(new authenticationActions.AuthenticateFailureAction(errors))
        })
    );

    @Effect()
    testSession = this.actions$.pipe(
        ofType<authenticationActions.TestUserAction>(authenticationActions.AuthenticationActionTypes.TestUser),
        mergeMap(() => {
            return this.authService.getCurrent().pipe(
                map(userAccount => new authenticationActions.SetUserAction(userAccount)),
                catchError(e => of(new authenticationActions.SetLoaded()))
            );
        })
    );

    @Effect()
    socialAuthenticate = this.actions$.pipe(
        ofType<authenticationActions.SocialAuthenticateAction>(authenticationActions.AuthenticationActionTypes.SocialAuthenticate),
        map(action => action.payload),
        mergeMap(token =>
            this.authService.socialAuthentication(token).pipe(
                mergeMap(session => {
                    let postLoginAct = new routeActions.ToUserProfileAction();
                    // let postLoginAct = session.user.filled ? new routeActions.ToUserProfileAction() : new routeActions.ToCompleteUserProfileAction();

                    // TODO
                    // if (Config.IS_MOBILE_NATIVE()) {
                    //     postLoginAct = new routeActions.ToUserProfileAction();
                    // }

                    return [
                        new authenticationActions.AuthenticateSuccessAction(session.user),
                        postLoginAct
                    ]
                }),
                catchError((errors: ErrorsDto) => {

                    if (!errors) {
                        errors = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.global'));
                    }

                    return of(new authenticationActions.AuthenticateFailureAction(errors))
                })
            )
        )
    );

    @Effect()
    logout = this.actions$.pipe(
        ofType<authenticationActions.LogOutAction>(authenticationActions.AuthenticationActionTypes.Logout),
        switchMap(() => {

            if (Config.IS_MOBILE_NATIVE()) {
                this.authService.clearSession();

                return [
                    new authenticationActions.ResetAuthenticationAction(),
                    new routeActions.ToHomeAction()
                ];
            } else {
                return this.authService.logout().pipe(
                    mergeMap(() => {
                        this.noticeService.calculateUserProfileNotices(null);

                        return [
                            new authenticationActions.ResetAuthenticationAction(),
                            new routeActions.ToHomeAction()
                        ];
                    })
                );
            }
        })
    );

    @Effect()
    setUserProfilePicture = this.actions$.pipe(
        ofType<userProfileActions.SetPictureAction>(userProfileActions.AccountProfileActionTypes.SetPicture),
        map((action: userProfileActions.SetPictureAction) => action.payload),
        map(picture => new authenticationActions.SetPictureAction(picture))
    );

    @Effect()
    setUserProfilePictureAfterPatch = this.actions$.pipe(
        ofType<userProfileActions.UpdateUserProfileSuccessAction>(userProfileActions.AccountProfileActionTypes.UpdateUserProfileSuccess),
        map((action: userProfileActions.UpdateUserProfileSuccessAction) => action.payload),
        map(userProfile => new authenticationActions.SetPictureAction(userProfile.profile.picture))
    );

    @Effect()
    setUserProfilePictureAfterLoadingProfile = this.actions$.pipe(
        ofType<userProfileActions.LoadUserProfileSuccessAction>(userProfileActions.AccountProfileActionTypes.LoadUserProfileSuccess),
        map((action: userProfileActions.LoadUserProfileSuccessAction) => action.payload),
        filter(userProfile => userProfile.profile != null),
        map(userProfile => new authenticationActions.SetPictureAction(userProfile.profile.picture))
    );

    @Effect()
    countUnreadConversations = this.actions$.pipe(
        ofType(
            authenticationActions.AuthenticationActionTypes.CountUnreadConversations,
            // authenticationActions.AuthenticationActionTypes.AuthenticateSuccess
        ),
        withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUser))),
        filter(userProfile => userProfile != null),
        switchMap(([action, currentUser]) => {
            return this.authService.countUnreadConversations(currentUser.uid).pipe(
                map(count => {
                    this.noticeService.calculateNewMessage(count);

                    return new authenticationActions.CountUnreadConversationsSuccessAction(count)
                }))
        })
    );

    // @Effect()
    // init$ = defer(() => {
    //     if (this.authService.isAuthenticated()) {
    //         if (this.browserService.isPlatformBrowser()) {
    //             if (this.authService.isAuthenticated()) {
    //
    //                 let user = this.authService.getUser();
    //
    //                 if (!user.enabled) {
    //                     this.authService.getCurrent().subscribe(userAccount => {
    //                         this.authService.updateUserAccount(userAccount);
    //                         this.store.dispatch(new authenticationActions.SetUserAction(userAccount));
    //                     });
    //                 } else {
    //                     this.store.dispatch(new authenticationActions.SetUserAction(user));
    //                 }
    //                 if (!user.enabled) {
    //                     this.authService.getCurrent().subscribe(userAccount => {
    //                         this.authService.updateUserAccount(userAccount);
    //                         this.store.dispatch(new authenticationActions.SetUserAction(userAccount));
    //                     });
    //                 } else {
    //                     this.store.dispatch(new authenticationActions.SetUserAction(user));
    //                 }
    //
    //                 this.authService.getCurrentUserProfile(user.uid).subscribe(up => {
    //                     this.noticeService.calculateUserProfileNotices(up);
    //                     this.store.dispatch(new authenticationActions.LoadUserProfileSuccessAction(up))
    //                 });
    //                 this.authService.getCurrentUserProfile(user.uid).subscribe(up => {
    //                     this.noticeService.calculateUserProfileNotices(up);
    //                     this.store.dispatch(new authenticationActions.LoadUserProfileSuccessAction(up))
    //                 });
    //
    //             } else {
    //                 this.authService.clearSession();
    //             } else {
    //                 this.authService.clearSession();
    //             }
    //         }
    //
    //         return of({type: 'NO_ACTION'});
    //     });

    constructor(private actions$: Actions,
                private translateService: TranslateService,
                private store: Store<fromRoot.State>,
                private injector: Injector,
                private noticeService: NoticeService,
                private browserService: BrowserService,
                private routeService: RouteService,
                private authService: AuthService) {

    }

}
