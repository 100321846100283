import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {INotificationService, Toast, ToastType} from '../models';
import {AbstractNotificationService} from './abstract-notification.service';
import {BrowserService} from './browser.service';

declare var Materialize: any;

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends AbstractNotificationService implements INotificationService {

    constructor(private browserService: BrowserService,
                public translateService: TranslateService) {
        super(translateService);
    }

    displayToast(toast: Toast) {

        let className: string;
        switch (toast.type) {
            case ToastType.SUCCESS :
                className = 'success';
                break;
            case ToastType.WARNING :
                className = 'warning';
                break;
            case ToastType.ERROR :
                className = 'error';
                break;
        }

        let message;
        if (toast.message) {
            message = toast.message;
        } else {
            message = this.translateService.instant(toast.key);
        }

        if (this.browserService.isPlatformBrowser()) {
            Materialize.toast(message, toast.duration, className);
        }
    }

}
