import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AddReviewDto, BaseUserDto, DisplayReviewDto, Notification, UserAccountDto} from '../../../../../core/models';
import {NotificationService} from '../../../../../core/services';
import {loginModalActions, reviewActions} from '../../../../../core/store/actions';

import * as fromRoot from '../../../../../store';

@Component({
    moduleId: module.id,
    selector: 'app-review-manager',
    templateUrl: './review-manager.component.html',
    styleUrls: ['./review-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewManagerComponent implements OnInit {

    displayAddReviewForm = false;

    @Input() loading$: Observable<boolean>;
    @Input() notification: Notification;
    @Input() reviews: DisplayReviewDto[];
    @Input() targetUser: BaseUserDto;

    @Output() loadReviews = new EventEmitter<BaseUserDto>();

    currentUser$: Observable<UserAccountDto>;
    currentUser: UserAccountDto;

    constructor(private store: Store<fromRoot.State>,
                private notificationService: NotificationService) {
        this.currentUser$ = this.store.pipe(select(fromRoot.getCurrentUser));
    }

    ngOnInit() {
        this.currentUser$.subscribe(current => {
            this.currentUser = current;
        });

        this.store.pipe(select(fromRoot.isReviewAdded))
            .subscribe((added) => {
                if (added) {
                    this.loadReviews.emit(this.targetUser);
                    this.notification = this.notificationService.buildReviewAddedSuccess();
                    this.displayAddReviewForm = false;
                }
            });
    }

    showAddReviewForm() {
        if (this.currentUser) {
            this.displayAddReviewForm = true;
        } else {
            this.store.dispatch(new loginModalActions.OpenModalAction());
        }

    }

    addReview(review: AddReviewDto) {
        this.store.dispatch(new reviewActions.AddReviewAction(review));
    }

    deleteReview(review: DisplayReviewDto) {
    }

    updateReview(review: DisplayReviewDto) {
    }

    cancelReview() {
        this.displayAddReviewForm = false;
    }


}
