export var AccountDeleteActionTypes;
(function (AccountDeleteActionTypes) {
    AccountDeleteActionTypes["Delete"] = "[Account] DELETE";
    AccountDeleteActionTypes["DeleteSuccess"] = "[Account] DELETE_SUCCESS";
    AccountDeleteActionTypes["DeleteFail"] = "[Account] DELETE_FAIL";
    AccountDeleteActionTypes["Reset"] = "[Account] RESET_DELETE";
})(AccountDeleteActionTypes || (AccountDeleteActionTypes = {}));
var DeleteAction = /** @class */ (function () {
    function DeleteAction() {
        this.type = AccountDeleteActionTypes.Delete;
    }
    return DeleteAction;
}());
export { DeleteAction };
var DeleteSuccessAction = /** @class */ (function () {
    function DeleteSuccessAction() {
        this.type = AccountDeleteActionTypes.DeleteSuccess;
    }
    return DeleteSuccessAction;
}());
export { DeleteSuccessAction };
var DeleteFailAction = /** @class */ (function () {
    function DeleteFailAction(payload) {
        this.payload = payload;
        this.type = AccountDeleteActionTypes.DeleteFail;
    }
    return DeleteFailAction;
}());
export { DeleteFailAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = AccountDeleteActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
