import { Store } from '@ngrx/store';
import { noticeActions } from '../store/actions';
import { AddNoticesAction, RemoveNoticeAction } from '../store/actions/notice.action';
import { Notice } from '../models';
import { UserProfileUtils } from '../utils';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var NoticeService = /** @class */ (function () {
    function NoticeService(store) {
        this.store = store;
    }
    NoticeService.prototype.calculateUserProfileNotices = function (up) {
        this.store.dispatch(new noticeActions.ResetAction());
        if (up) {
            var notices = [];
            if (!UserProfileUtils.isUserProfileComplete(up)) {
                notices.push(Notice.buildProfileIncomplete());
            }
            if (!UserProfileUtils.isUserProfileVerified(up)) {
                notices.push(Notice.buildProfileNotVerified());
            }
            this.store.dispatch(new AddNoticesAction(notices));
        }
    };
    NoticeService.prototype.calculateNewMessage = function (count) {
        if (count > 0) {
            this.store.dispatch(new AddNoticesAction([Notice.buildNewMessage()]));
        }
        else {
            this.store.dispatch(new RemoveNoticeAction(Notice.buildNewMessage()));
        }
    };
    NoticeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NoticeService_Factory() { return new NoticeService(i0.ɵɵinject(i1.Store)); }, token: NoticeService, providedIn: "root" });
    return NoticeService;
}());
export { NoticeService };
