import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {ErrorsDto, ModalEvent} from '../../../../core/models';
import {DeviceService} from '../../../../core/services';
import {phoneModalActions} from '../../../../core/store/actions';
import * as fromRoot from '../../../../store';

@Component({
    moduleId: module.id,
    selector: 'app-show-phone-modal',
    templateUrl: './show-phone-modal.component.html',
    styleUrls: ['./show-phone-modal.component.scss']
})
export class ShowPhoneModalComponent implements OnInit {

    modalEventListener$: Observable<ModalEvent>;
    phone$: Observable<string>;
    loading$: Observable<boolean>;
    errors$: Observable<ErrorsDto>;

    isMobileDevice = false;

    constructor(private store: Store<fromRoot.State>,
                private deviceService: DeviceService) {
        this.phone$ = this.store.pipe(select(fromRoot.getUserProfilePhone));
        this.loading$ = this.store.pipe(select(fromRoot.isPhoneLoading));
        this.errors$ = this.store.pipe(select(fromRoot.getPhoneErrors));
        this.modalEventListener$ = this.store.pipe(select(fromRoot.getPhoneModalEvent));
    }

    ngOnInit() {
        this.isMobileDevice = this.deviceService.isMobileDevice();
    }

    dispatchModalClose() {
        this.store.dispatch(new phoneModalActions.ClosePhoneModalAction());
    }
}
