import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddReviewDto, BasicUserDto, ReviewRatingDto} from '../../../../core/models';
import {ReviewUtils} from '../../../../core/utils';

@Component({
    moduleId: module.id,
    selector: 'app-add-review',
    templateUrl: './review-add.component.html',
    styleUrls: ['./review-add.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddReviewComponent implements OnInit {

    @Input() author: BasicUserDto;
    @Input() targetUser: BasicUserDto;

    @Output() addReview = new EventEmitter<AddReviewDto>();
    @Output() cancel = new EventEmitter();

    reviewRatings: ReviewRatingDto[] = ReviewUtils.getReviewRatings();
    review: AddReviewDto;


    ngOnInit(): void {
        this.review = new AddReviewDto();
        this.review.rating = -1;
    }

    submit(form: any) {

        if (!form.valid) {
            return;
        }

        this.emitReview();
    }

    isRatingSelected(rating: ReviewRatingDto) {
        return this.review.rating === rating.value;
    }

    setReviewRating(rating: number) {
        this.review.rating = rating;
    }

    emitReview() {
        this.review.targetUid = this.targetUser.uid;
        this.review.authorUid = this.author.uid;

        this.addReview.emit(this.review);
    }
}
