import {Injectable} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';

const _startsWith = require('lodash/startsWith');

export enum RouteNames {
    REQUESTS = <any>'requests',
    PASSWORD_RESET = <any>'password/forgotten',
    WORKER = <any>'/workers',
    CONVERSATIONS = <any>'conversations',
    LOGIN = <any>'login',
    USER = <any>'user',
    HOME = <any>'/',
    SEARCH = <any>'/workers',
    EMAIL_CONFIRMATION = <any>'/register/confirm',
    USER_PROFILE = <any>'/user/profile',
    USER_PROFILE_VERIFICATIONS = <any>'/user/profile/verifications',
    COMPLETE_USER_PROFILE = <any>'/user/complete',
    NOT_FOUND = <any>'/404',
    FORBIDDEN = <any>'/403',
}

/**
 * Allows to manage applications routes
 */
@Injectable({
    providedIn: 'root'
})
export class RouteService {
    redirectUrl: string;

    constructor(private router: Router) {
    }

    toSignInPage(): void {
        this.router.navigate([RouteNames.LOGIN]);
    }

    toUserProfilePage(): void {
        this.router.navigate([RouteNames.USER_PROFILE]);
    }
    
    toProfileVerificationsPage(): void {
        this.router.navigate([RouteNames.USER_PROFILE_VERIFICATIONS]);
    }

    toSearchPage(navigationExtras: NavigationExtras): void {
        this.router.navigate([RouteNames.WORKER], navigationExtras);
    }

    toHome(): void {
        this.router.navigate([RouteNames.HOME]);
    }

    toUrl(url: string): void {
        this.router.navigateByUrl(url);
    }

    toErrorPage(): void {
        this.router.navigate(['error.html']);
    }

    toWorker(id: any) {
        this.router.navigate(['/', 'workers', id, 'profile']);
    }

    toInitialLocation(): void {
        this.router.navigate([_startsWith(this.redirectUrl, <any>RouteNames.LOGIN) ? RouteNames.HOME : this.redirectUrl]);
    }

    toConversationPage(conversationId: any) {
        this.router.navigate([RouteNames.USER, RouteNames.CONVERSATIONS, conversationId]);
    }

    toConversations() {
        this.router.navigate([RouteNames.USER, RouteNames.CONVERSATIONS]);
    }

    toCompleteUserProfile() {
        this.router.navigate([RouteNames.COMPLETE_USER_PROFILE]);
    }

    toEmailConfirmation(navigationExtras?: NavigationExtras) {
        this.router.navigate([RouteNames.EMAIL_CONFIRMATION], navigationExtras);
    }

    toPasswordResetPage(navigationExtras?: NavigationExtras) {
        this.router.navigate([RouteNames.PASSWORD_RESET], navigationExtras);
    }

    toRequest(id: number, navigationExtras?: NavigationExtras) {
        this.router.navigate([RouteNames.REQUESTS, id], navigationExtras);
    }

    toRequests(navigationExtras?: NavigationExtras) {
        this.router.navigate([RouteNames.REQUESTS], navigationExtras);
    }

    toUpdateRequest(id: number, navigationExtras?: NavigationExtras) {
        this.router.navigate([RouteNames.USER, RouteNames.REQUESTS, id], navigationExtras);
    }

    toNotFound(navigationExtras?: NavigationExtras) {
        this.router.navigate([RouteNames.NOT_FOUND], navigationExtras);
    }

    toForbidden(navigationExtras?: NavigationExtras) {
        this.router.navigate([RouteNames.FORBIDDEN], navigationExtras);
    }
}
