import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Gender, UserViewDto} from '../models';
import {BcUtilsService} from './bc-utils.service';

// TODO complete open graph tags http://ogp.me/#types
@Injectable({
    providedIn: 'root'
})
export class OpenGraphService {

    constructor(private utilsService: BcUtilsService,
                private meta: Meta,
                private translateService: TranslateService) {
    }

    setUserViewOG(profile: UserViewDto) {
        let title = this.translateService.instant('seo.profile-view.title', {profile: profile});
        let profileUrl = this.utilsService.getProfileUrl(profile);
        let picture = this.utilsService.getImageFullPath(profile.picture);



        this.addTag('og:title', title);
        this.addTag('og:url', profileUrl);

        if (!picture) {
            this.addTag('og:image', picture);
        }
        this.addTag('og:type', 'profile');
        this.addTag('profile:first_name', profile.firstName);
        this.addTag('profile:last_name', profile.lastName);
        this.addTag('profile:gender', profile.gender === Gender.MAN ? 'male' : 'female');
    }

    openGraphRequest(request: UserViewDto) {
        let title = this.translateService.instant('seo.request-view.title', {profile: request});
        let profileUrl = this.utilsService.getProfileUrl(request);
        let picture = this.utilsService.getImageFullPath(request.picture);

        this.addTag('og:title', title);
        this.addTag('og:url', profileUrl);

        if (!picture) {
            this.addTag('og:image', picture);
        }
        this.addTag('og:type', 'request');
        this.addTag('request:first_name', request.firstName);
        this.addTag('request:last_name', request.lastName);
        this.addTag('request:gender', request.gender === Gender.MAN ? 'male' : 'female');
    }

    private addTag(name: string, content: string) {
        this.meta.addTag({
            name: name,
            content: content
        });
    }
}
