import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import * as fromRoot from '../../store';
import {noticeActions} from '../store/actions';
import {AddNoticesAction, RemoveNoticeAction} from '../store/actions/notice.action';
import {Notice, UserProfileDto} from '../models';
import {UserProfileUtils} from '../utils';

@Injectable({
    providedIn: 'root'
})
export class NoticeService {

    constructor(private store: Store<fromRoot.State>) {

    }

    calculateUserProfileNotices(up: UserProfileDto) {
        this.store.dispatch(new noticeActions.ResetAction());

        if (up) {
            let notices: Notice[] = [];
            if (!UserProfileUtils.isUserProfileComplete(up)) {
                notices.push(Notice.buildProfileIncomplete());
            }

            if (!UserProfileUtils.isUserProfileVerified(up)) {
                notices.push(Notice.buildProfileNotVerified());
            }

            this.store.dispatch(new AddNoticesAction(notices));
        }
    }

    calculateNewMessage(count: number) {
        if (count > 0) {
            this.store.dispatch(new AddNoticesAction([Notice.buildNewMessage()]));
        } else {
            this.store.dispatch(new RemoveNoticeAction(Notice.buildNewMessage()));
        }
    }

}
