import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {ErrorDto, ErrorsDto, PasswordResetDto, UserLoginFormDto, UserRegistrationDto} from '../models';
import {ErrorUtils} from '../utils';

let phonenumbers = require('google-libphonenumber');

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    static isEmailValid(email: string): boolean {
        let re = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

        return re.test(email);
    }

    static isPasswordValid(password: string): boolean {
        return password && password.length > 5;
    }

    static isPhoneNumber(phone: string): boolean {

        const phoneUtil = phonenumbers.PhoneNumberUtil.getInstance();

        if (!phone) {
            return false;
        }

        try {
            if (phone.startsWith('+') || phone.startsWith('00')) {
                if (phone.startsWith('00')) {
                    phone = '+' + phone.substring(2, phone.length);
                }

                let parsedNumber = phoneUtil.parse(phone);
                let regionCode = phoneUtil.getRegionCodeForNumber(parsedNumber);

                return phoneUtil.isValidNumberForRegion(parsedNumber, regionCode);
            } else {
                let parsedNumber = phoneUtil.parse(phone, 'MA');

                return phoneUtil.isValidNumber(parsedNumber);
            }
        } catch (e) {
            return false;
        }
    }

    static isLoginValid(login: string): boolean {
        if (!login) {
            return false;
        }

        login = login.replace(/\s/g, '');

        return ValidationService.isEmailValid(login) || ValidationService.isPhoneNumber(login);
    }

    constructor(private translateService: TranslateService) {
    }

    public validateEmail(email: string): ErrorsDto {
        let err: ErrorsDto = null;

        if (!ValidationService.isEmailValid(email)) {
            err = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.email.invalid'));
        }

        return err;
    }

    public buildValidationErrorsDto(): ErrorsDto {
        return ErrorUtils.buildErrorsDto(this.translateService.instant('errors.validation'));
    }

    public buildFormValidationErrorsDto(): ErrorsDto {
        let errors = this.buildValidationErrorsDto();
        ErrorUtils.addError(errors, this.translateService.instant('errors.form.invalid'));

        return errors;
    }

    public validateUserLoginForm(userLoginForm: UserLoginFormDto): ErrorsDto {
        let err: ErrorsDto = null;

        let isEmailValid = ValidationService.isLoginValid(userLoginForm.login);
        let isPasswordValid = ValidationService.isPasswordValid(userLoginForm.password);

        if (!isEmailValid || !isPasswordValid) {
            err = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.credentials.incorrect'));
        }

        return err;
    }

    public validateUserRegistrationForm(userRegistration: UserRegistrationDto): ErrorsDto {
        let err: ErrorsDto = null;
        let errors: ErrorDto[] = [];

        if (!ValidationService.isLoginValid(userRegistration.email)) {
            errors.push(ErrorUtils.buildErrorDto(this.translateService.instant('errors.login.invalid')));
        }

        if (!ValidationService.isPasswordValid(userRegistration.password)) {
            errors.push(ErrorUtils.buildErrorDto(this.translateService.instant('errors.password.invalid')));
        }

        if (!userRegistration.acceptTermsOfService) {
            errors.push(ErrorUtils.buildErrorDto(this.translateService.instant('errors.cgu')));
        }

        if (errors.length) {
            err = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.validation'));
            err.errors = errors;
        }

        return err;
    }

    public validatePasswordResetDto(passwordReset: PasswordResetDto): ErrorsDto {
        let err: ErrorsDto;
        let errors: ErrorDto[] = [];

        if (!ValidationService.isPasswordValid(passwordReset.newPassword)) {
            errors.push(ErrorUtils.buildErrorDto(this.translateService.instant('errors.password.invalid')));
        }

        if (passwordReset.newPassword !== passwordReset.newPasswordConfirmation) {
            errors.push(ErrorUtils.buildErrorDto(this.translateService.instant('errors.password.match')));
        }

        if (errors.length) {
            err = ErrorUtils.buildErrorsDto(this.translateService.instant('errors.validation'));
            err.errors = errors;
        }

        return err;
    }

}
