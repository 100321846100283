/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gallery.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/lightgallery.directive";
import * as i3 from "../../../core/services/browser.service";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./gallery.component";
var styles_GalleryComponent = [i0.styles];
var RenderType_GalleryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GalleryComponent, data: {} });
export { RenderType_GalleryComponent as RenderType_GalleryComponent };
function View_GalleryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "item"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "img-responsive"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "demo-gallery-poster"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/images/zoom.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.envUrl + _v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.envUrl + _v.context.$implicit); _ck(_v, 1, 0, currVal_1); }); }
function View_GalleryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "demo-gallery"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["appLightGallery", ""], ["class", "list-unstyled justified-gallery"]], null, null, null, null, null)), i1.ɵdid(2, 81920, [[1, 4]], 0, i2.LightGalleryDirective, [i1.ElementRef, i3.BrowserService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_2)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.medias; _ck(_v, 4, 0, currVal_0); }, null); }
function View_GalleryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "no-medias-message"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.emptyMessage; _ck(_v, 2, 0, currVal_1); }); }
export function View_GalleryComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { lightGalleryDirective: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noMedias", 2]], null, 0, null, View_GalleryComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.medias == null) ? null : _co.medias.length); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_GalleryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gallery", [], null, null, null, View_GalleryComponent_0, RenderType_GalleryComponent)), i1.ɵdid(1, 573440, null, 0, i6.GalleryComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var GalleryComponentNgFactory = i1.ɵccf("app-gallery", i6.GalleryComponent, View_GalleryComponent_Host_0, { medias: "medias", envUrl: "envUrl", emptyMessage: "emptyMessage" }, {}, []);
export { GalleryComponentNgFactory as GalleryComponentNgFactory };
