import { EventEmitter } from '@angular/core';
import { Page } from '../../../core/models';
var MobilePaginationComponent = /** @class */ (function () {
    function MobilePaginationComponent() {
        this.previousLabel = 'Précédent';
        this.selectPage = new EventEmitter();
    }
    MobilePaginationComponent.prototype.loadPage = function (pageNum) {
        this.selectPage.emit(pageNum);
    };
    return MobilePaginationComponent;
}());
export { MobilePaginationComponent };
