import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { HttpUtil } from '../utils';
import { ValidationService } from './validation.service';
import * as i0 from "@angular/core";
import * as i1 from "./validation.service";
import * as i2 from "@angular/common/http";
var PasswordService = /** @class */ (function () {
    function PasswordService(validationService, httpClient) {
        this.validationService = validationService;
        this.httpClient = httpClient;
    }
    PasswordService.prototype.resetPassword = function (email, token, passwordReset) {
        var errorsDto = this.validationService.validatePasswordResetDto(passwordReset);
        if (errorsDto) {
            return observableThrowError(errorsDto);
        }
        var url = HttpUtil.buildAndExpand('api/v1/account/{email}/password/{token}', email, token);
        return this.httpClient.post(url, passwordReset);
    };
    PasswordService.prototype.isPasswordResetTokenValid = function (email, token) {
        var url = HttpUtil.buildAndExpand('api/v1/account/{email}/password/{token}', email, token);
        return this.httpClient.get(url);
    };
    PasswordService.prototype.recoverPasswordByEmail = function (email) {
        var url = HttpUtil.buildAndExpand('api/v1/account/{email}/password/recover', email);
        return this.httpClient.post(url, null);
    };
    PasswordService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PasswordService_Factory() { return new PasswordService(i0.ɵɵinject(i1.ValidationService), i0.ɵɵinject(i2.HttpClient)); }, token: PasswordService, providedIn: "root" });
    return PasswordService;
}());
export { PasswordService };
