import {PasswordResetDto} from './index';

export class PasswordResetActionDto {
    email: string;
    token: string;
    passwordReset: PasswordResetDto;

    public static build(email: string, token: string, passwordReset: PasswordResetDto): PasswordResetActionDto {
        let pr = new PasswordResetActionDto();
        pr.email = email;
        pr.token = token;
        pr.passwordReset = passwordReset;

        return pr;
    }
}
