import { EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification, ReviewDto, ReviewReportDto } from '../../../../../core/models';
var ReportReviewComponent = /** @class */ (function () {
    function ReportReviewComponent() {
        this.cancel = new EventEmitter();
        this.report = new EventEmitter();
        this._reviewReport = new ReviewReportDto();
    }
    ReportReviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initListener$.subscribe(function () { return _this._reviewReport = new ReviewReportDto(); });
    };
    ReportReviewComponent.prototype.reportReview = function (form) {
        if (form.invalid) {
            return;
        }
        this._reviewReport.reviewId = this.review.id;
        this.report.emit(this._reviewReport);
    };
    return ReportReviewComponent;
}());
export { ReportReviewComponent };
