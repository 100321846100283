import { BudgetType, UIBudgetType } from '../models';
var BudgetTypeUtils = /** @class */ (function () {
    function BudgetTypeUtils() {
    }
    BudgetTypeUtils.getUIBudgetTypes = function () {
        return BudgetTypeUtils.budgetTypes;
    };
    BudgetTypeUtils.getBudgetTextByType = function (type) {
        var found = BudgetTypeUtils.findUIBudgetByType(type);
        if (!found) {
            throw new TypeError('BudgetType est inconnu');
        }
        return found.text;
    };
    BudgetTypeUtils.getBudgetMediumTextByType = function (type) {
        var found = BudgetTypeUtils.findUIBudgetByType(type);
        if (!found) {
            throw new TypeError('BudgetType est inconnu');
        }
        return found.mediumText;
    };
    BudgetTypeUtils.getBudgetSmTextByType = function (type) {
        var found = BudgetTypeUtils.findUIBudgetByType(type);
        if (!found) {
            throw new TypeError('BudgetType est inconnu');
        }
        return found.smallText;
    };
    BudgetTypeUtils.findUIBudgetByType = function (type) {
        var fn = BudgetTypeUtils.budgetTypes.find(function (bt) { return bt.type === type; });
        return fn;
    };
    BudgetTypeUtils.isBudgetTypeEquals = function (budgetType, type) {
        return budgetType && type === budgetType;
    };
    BudgetTypeUtils.isBudgetTypeOnQuotation = function (budgetType) {
        return BudgetTypeUtils.isBudgetTypeEquals(budgetType, BudgetType.ON_QUOTATION);
    };
    BudgetTypeUtils.budgetTypes = [
        new UIBudgetType(BudgetType.PER_HOUR, 'Budget par heure', 'par heure', '/heure', 'الميزانية للساعة'),
        new UIBudgetType(BudgetType.PER_DAY, 'Budget par jour', 'par jour', '/jour', 'الميزانية لليوم'),
        new UIBudgetType(BudgetType.GLOBAL, 'Budget total', 'total', 'total', 'الميزانية اﻹجمالية'),
        new UIBudgetType(BudgetType.ON_QUOTATION, 'Sur devis', 'sur devis', 'sur devis', 'ثمن اﻷشغال')
    ];
    return BudgetTypeUtils;
}());
export { BudgetTypeUtils };
