export var NoticeActionTypes;
(function (NoticeActionTypes) {
    NoticeActionTypes["AddNotices"] = "[Notice] AddNotices";
    NoticeActionTypes["RemoveNotice"] = "[Notice] RemoveNotice";
    NoticeActionTypes["Reset"] = "[Notice] Reset";
})(NoticeActionTypes || (NoticeActionTypes = {}));
var AddNoticesAction = /** @class */ (function () {
    function AddNoticesAction(payload) {
        this.payload = payload;
        this.type = NoticeActionTypes.AddNotices;
    }
    return AddNoticesAction;
}());
export { AddNoticesAction };
var RemoveNoticeAction = /** @class */ (function () {
    function RemoveNoticeAction(payload) {
        this.payload = payload;
        this.type = NoticeActionTypes.RemoveNotice;
    }
    return RemoveNoticeAction;
}());
export { RemoveNoticeAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = NoticeActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
