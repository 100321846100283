import {BasicUserDto, ErrorsDto, ModalEvent} from '../../../core/models';
import {reportUserActions} from '../actions';

export interface State {
    loading: boolean,
    errors: ErrorsDto,
    user: BasicUserDto,
    modalEvent: ModalEvent,
    reported: boolean;
}

export const initialState: State = {
    loading: false,
    errors: null,
    user: null,
    modalEvent: ModalEvent.CLOSE,
    reported: false
};

export function reducer(state = initialState, action: reportUserActions.Actions): State {

    switch (action.type) {

        case reportUserActions.UserReportActionTypes.ReportUser: {
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    errors: null,
                    reported: false
                }
            )
        }

        case reportUserActions.UserReportActionTypes.ReportUserSuccess: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    reported: true,
                    errors: null
                }
            )
        }

        case reportUserActions.UserReportActionTypes.ReportUserFail: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: action.payload,
                    reported: false
                }
            )
        }

        case reportUserActions.UserReportActionTypes.OpenModal: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    reported: false,
                    errors: null,
                    modalEvent: ModalEvent.OPEN,
                    user: action.payload
                }
            )
        }

        case reportUserActions.UserReportActionTypes.SetUser: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    reported: false,
                    errors: null,
                    user: action.payload
                }
            )
        }

        case reportUserActions.UserReportActionTypes.CloseModal: {
            return Object.assign(
                {},
                state,
                {
                    modalEvent: ModalEvent.CLOSE,
                }
            )
        }

        case reportUserActions.UserReportActionTypes.Reset: {
            return Object.assign(
                {},
                state,
                initialState
            )
        }

        default: {
            return state;
        }

    }
}

export const isLoading = (state: State): boolean => state.loading;
export const isReported = (state: State): boolean => state.reported;
export const getErrors = (state: State): ErrorsDto => state.errors;
export const getUser = (state: State): BasicUserDto => state.user;
export const getModalEvent = (state: State): ModalEvent => state.modalEvent;
