<div class="container-fluid sub-header" yfLayoutClass>
    <div class="container">
        <h1 class="white-text h4 center-sm-down" translate>privacy-policy.title</h1>
    </div>
</div>

<div class="container" yfLayoutClass>
    <div class="card">
        <div class="card-block">
            <p translate>privacy-policy.intro</p>

            <div class="section-header">
                <span translate>privacy-policy.collected-data.title</span>
            </div>

            <p translate>
                privacy-policy.collected-data.body1 <br/>
                privacy-policy.collected-data.body2 <br/>
                privacy-policy.collected-data.body3 <br/>
                privacy-policy.collected-data.body4 <br/>
                privacy-policy.collected-data.body5
            </p>

            <div class="section-header">
                <span translate>privacy-policy.data-usage.title</span>
            </div>

            <p translate>
                privacy-policy.data-usage.body1
            </p>

            <div class="row">
                <div class="col-12">
                    <ul>
                        <li translate>privacy-policy.data-usage.list.entry1</li>
                        <li translate>privacy-policy.data-usage.list.entry2</li>
                        <li translate>privacy-policy.data-usage.list.entry3</li>
                        <li translate>privacy-policy.data-usage.list.entry4</li>
                        <li translate>privacy-policy.data-usage.list.entry5</li>
                    </ul>
                </div>
            </div>

            <div class="section-header">
                <span translate>privacy-policy.data-duration.title</span>
            </div>

            <p translate>
                privacy-policy.data-duration.body1 <br/>
            </p>

            <div class="section-header">
                <span translate>privacy-policy.data-right.title</span>
            </div>

            <p translate>
                privacy-policy.data-right.body1 <br/>
            </p>

        </div>
    </div>
</div>