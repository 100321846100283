import {AdminReviewDto, RequestStatus, ReviewRatingDto, ReviewStatus} from '../models';

export class ReviewUtils {

    private static reviewRatings: ReviewRatingDto[] = [
        ReviewUtils.build(4, 'Parfait'),
        ReviewUtils.build(3, 'Très bien'),
        ReviewUtils.build(2, 'Bien'),
        ReviewUtils.build(1, 'Décevant'),
        ReviewUtils.build(0, 'A éviter')
    ];

    static getReviewRatings(): ReviewRatingDto[] {
        return ReviewUtils.reviewRatings;
    }

    static build(value: number, text: string): ReviewRatingDto {
        let rr = new ReviewRatingDto();
        rr.text = text;
        rr.value = value;

        return rr;
    }

    static isStatusPending(review: AdminReviewDto) {
        return ReviewUtils.hasStatus(review, ReviewStatus.PENDING);
    }

    static hasStatus(review: AdminReviewDto, status: ReviewStatus): boolean {
        return review && review.status === status;
    }
}
