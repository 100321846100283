import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    Page,
    Pageable,
    PromotionResultDto,
    SearchCriteriaDto,
    UserReportDto,
    UserResultDto,
    UserViewDto
} from '../../core/models';
import {HttpUtil} from '../../core/utils';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private static WORKERS_ONE = 'api/v1/workers/{userUid}';
    private static USER_PHONE = 'api/v1/users/{userUid}/profile/phone';
    private static USER_VIEWS = 'api/v1/users/{userUid}/profile/views';
    private static SEARCH_WORKERS = 'api/v1/search/workers';
    private static REPORT_USERS = 'api/v1/reports/users';

    constructor(private httpClient: HttpClient) {
    }

    getWorker(userUid: string): Observable<UserViewDto> {
        return this.httpClient.get<UserViewDto>(HttpUtil.buildAndExpand(UserService.WORKERS_ONE, userUid));
    }

    getUserPhone(userUid: string): Observable<string> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", 'text/plain');

        return this.httpClient.get(HttpUtil.buildAndExpand(UserService.USER_PHONE, userUid), {
            headers: headers,
            observe: 'response',
            responseType: 'text'
        }).pipe(
            map((resp: HttpResponse<string>) => resp.body)
        );
    }

    addView(userUid: string): Observable<any> {
        return this.httpClient.post(HttpUtil.buildAndExpand(UserService.USER_VIEWS, userUid), null);
    }

    searchWorkers(searchCriteria: SearchCriteriaDto, pageable: Pageable): Observable<Page<UserResultDto>> {
        let requestOptions = HttpUtil.buildPageableOptions(pageable);
        return this.httpClient.post<Page<UserResultDto>>(UserService.SEARCH_WORKERS, searchCriteria, requestOptions);
    }

    searchAroundIPOrLastWorkers(pageable: Pageable): Observable<PromotionResultDto<UserResultDto>> {
        let requestOptions = HttpUtil.buildPageableOptions(pageable);
        return this.httpClient.get<PromotionResultDto<UserResultDto>>(UserService.SEARCH_WORKERS, requestOptions);
    }

    reportUser(userReport: UserReportDto): Observable<any> {
        return this.httpClient.post(UserService.REPORT_USERS, userReport);
    }
}
