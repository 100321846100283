var ContextUtils = /** @class */ (function () {
    function ContextUtils() {
    }
    ContextUtils.getOrigin = function () {
        return window.location.origin;
    };
    ContextUtils.getContextPath = function () {
        var origin = window.location.origin;
        var href = window.location.href;
        // return href.replace(origin + '/#/', '/');
        return href.replace(origin + '/', '/');
    };
    ContextUtils.isArabicContextPath = function () {
        return ContextUtils.isArabicUrl(ContextUtils.getContextPath());
    };
    ContextUtils.isArabicUrl = function (url) {
        return url === '/ar' || url.startsWith('/ar?') || url.startsWith('/ar/');
    };
    ContextUtils.isCurrentLang = function (url, locale) {
        return url === "/" + locale.shortcut || url.startsWith("/" + locale.shortcut + "?") || url.startsWith("/" + locale.shortcut + "/");
    };
    ContextUtils.updateLocaleLang = function (url, locale) {
        if (ContextUtils.isCurrentLang(url, locale)) {
            return url;
        }
        if (url === '/') {
            return "/" + locale.shortcut;
        }
        if (!url.startsWith('/')) {
            url = '/' + url;
        }
        return "/" + locale.shortcut + url;
    };
    ContextUtils.arabizeContextPath = function () {
        return ContextUtils.arabizeUrl(ContextUtils.getContextPath());
    };
    ContextUtils.frenchifyContextPath = function () {
        return ContextUtils.frenchifyUrl(ContextUtils.getContextPath());
    };
    ContextUtils.arabizeUrl = function (url) {
        if (ContextUtils.isArabicUrl(url)) {
            return url;
        }
        if (url === '/') {
            return '/ar';
        }
        if (!url.startsWith('/')) {
            url = '/' + url;
        }
        return '/ar' + url;
    };
    ContextUtils.frenchifyUrl = function (url) {
        if (!url || !ContextUtils.isArabicUrl(url)) {
            return url;
        }
        if (url === '/ar') {
            return '/';
        }
        var prefix = url.startsWith('/ar?') ? 'ar?' : 'ar/';
        return url.replace(prefix, '');
    };
    return ContextUtils;
}());
export { ContextUtils };
