import {noticeActions} from '../actions';
import {Notice} from '../../models';

export interface State {
    notices: Notice[]
}

export const initialState: State = {
    notices: []
};

export function reducer(state = initialState, action: noticeActions.Actions): State {

    switch (action.type) {

        case noticeActions.NoticeActionTypes.AddNotices: {
            let notifs = action.payload.filter(notice => {
                return !state.notices.find(n => n.type === notice.type);
            });

            return {
                notices: [...state.notices, ...notifs]
            }
        }

        case noticeActions.NoticeActionTypes.RemoveNotice: {
            let notifs = state.notices.filter(n => n.type !== action.payload.type);

            return {
                notices: [...notifs]
            }
        }

        case noticeActions.NoticeActionTypes.Reset: {
            return Object.assign(
                {},
                state,
                initialState
            )
        }

        default: {
            return state;
        }

    }
}

export const getNotices = (state: State): Notice[] => state.notices;
