import {Location} from '@angular/common';

import {Inject} from '@angular/core';
import {Routes} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {of} from 'rxjs';
import {Locale} from '../core/models';

const COOKIE_EXPIRY = 30; // 1 month

/**
 * Abstract class for parsing localization
 */
// @Injectable()
export class LocalizeParser {
    locales: Locale[];
    currentLang: string;
    routes: Routes;
    defaultLang: string;

    protected prefix: string;

    /**
     * Loader constructor
     * @param translateService
     * @param location
     * @param settings
     */
    constructor(@Inject(TranslateService) private translateService: TranslateService,
                @Inject(Location) private location: Location) {
    }

    /**
     * Initialize language and routes
     * @param routes
     * @returns {Promise<any>}
     */
    init(routes: Routes): Promise<any> {
        this.routes = routes;
        let children: Routes = [...this.routes];

        /** exclude certain routes */
        for (let i = children.length - 1; i >= 0; i--) {
            if (children[i].data && children[i].data['skipRouteLocalization']) {
                children.splice(i, 1);
            }
        }

        /** append children routes */
        if (children && children.length) {
            if (this.locales && this.locales.length > 1) {
                this.locales.forEach(l => {
                    this.routes.unshift({path: l.shortcut, children: children});
                });

            }
        }

        return of(this.routes).toPromise();
    }

    /**
     * Get language from url
     * @returns {string}
     * @private
     */
    // getLocationLang(url?: string): string {
    //     let queryParamSplit = (url || this.location.path()).split('?');
    //     let pathSlices: string[] = [];
    //     if (queryParamSplit.length > 0) {
    //         pathSlices = queryParamSplit[0].split('/');
    //     }
    //     if (pathSlices.length > 1 && this.locales.indexOf(pathSlices[1]) !== -1) {
    //         return pathSlices[1];
    //     }
    //     if (pathSlices.length && this.locales.indexOf(pathSlices[0]) !== -1) {
    //         return pathSlices[0];
    //     }
    //     return null;
    // }
}
