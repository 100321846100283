export var SearchActivityChooserActionTypes;
(function (SearchActivityChooserActionTypes) {
    SearchActivityChooserActionTypes["SelectActivity"] = "[SEARCH ACTIVITY CHOOSER] SELECT_ACTIVITY";
    SearchActivityChooserActionTypes["SelectCategory"] = "[SEARCH ACTIVITY CHOOSER] SELECT_CATEGORY";
    SearchActivityChooserActionTypes["SetActivities"] = "[SEARCH ACTIVITY CHOOSER] SET_ACTIVITIES";
    SearchActivityChooserActionTypes["SelectOrDeselectActivity"] = "[SEARCH ACTIVITY CHOOSER] SELECT_OR_DESELECT_ACTIVITY";
    SearchActivityChooserActionTypes["SetSelectedActivities"] = "[SEARCH ACTIVITY CHOOSER] SET_SELECTED_ACTIVITIES";
    SearchActivityChooserActionTypes["SetSelectedCategories"] = "[SEARCH ACTIVITY CHOOSER] SET_SELECTED_CATEGORIES";
    SearchActivityChooserActionTypes["Reset"] = "[SEARCH ACTIVITY CHOOSER] RESET";
    SearchActivityChooserActionTypes["Init"] = "[SEARCH ACTIVITY CHOOSER] INIT";
})(SearchActivityChooserActionTypes || (SearchActivityChooserActionTypes = {}));
var SelectActivityAction = /** @class */ (function () {
    function SelectActivityAction(payload) {
        this.payload = payload;
        this.type = SearchActivityChooserActionTypes.SelectActivity;
    }
    return SelectActivityAction;
}());
export { SelectActivityAction };
var SelectCategoryAction = /** @class */ (function () {
    function SelectCategoryAction(payload) {
        this.payload = payload;
        this.type = SearchActivityChooserActionTypes.SelectCategory;
    }
    return SelectCategoryAction;
}());
export { SelectCategoryAction };
var SelectOrDeselectActivityAction = /** @class */ (function () {
    function SelectOrDeselectActivityAction(payload) {
        this.payload = payload;
        this.type = SearchActivityChooserActionTypes.SelectOrDeselectActivity;
    }
    return SelectOrDeselectActivityAction;
}());
export { SelectOrDeselectActivityAction };
var SetActivitiesAction = /** @class */ (function () {
    function SetActivitiesAction(payload) {
        this.payload = payload;
        this.type = SearchActivityChooserActionTypes.SetActivities;
    }
    return SetActivitiesAction;
}());
export { SetActivitiesAction };
var SetSelectedActivitiesAction = /** @class */ (function () {
    function SetSelectedActivitiesAction(payload) {
        this.payload = payload;
        this.type = SearchActivityChooserActionTypes.SetSelectedActivities;
    }
    return SetSelectedActivitiesAction;
}());
export { SetSelectedActivitiesAction };
var SetSelectedCategoriesAction = /** @class */ (function () {
    function SetSelectedCategoriesAction(payload) {
        this.payload = payload;
        this.type = SearchActivityChooserActionTypes.SetSelectedCategories;
    }
    return SetSelectedCategoriesAction;
}());
export { SetSelectedCategoriesAction };
var InitAction = /** @class */ (function () {
    function InitAction() {
        this.type = SearchActivityChooserActionTypes.Init;
    }
    return InitAction;
}());
export { InitAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = SearchActivityChooserActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
