export var LoginModalActionTypes;
(function (LoginModalActionTypes) {
    LoginModalActionTypes["OpenModal"] = "[LOGIN MODAL] OPEN_MODAL";
    LoginModalActionTypes["CloseModal"] = "[LOGIN MODAL] CLOSE_MODAL";
})(LoginModalActionTypes || (LoginModalActionTypes = {}));
var OpenModalAction = /** @class */ (function () {
    function OpenModalAction() {
        this.type = LoginModalActionTypes.OpenModal;
    }
    return OpenModalAction;
}());
export { OpenModalAction };
var CloseModalAction = /** @class */ (function () {
    function CloseModalAction() {
        this.type = LoginModalActionTypes.CloseModal;
    }
    return CloseModalAction;
}());
export { CloseModalAction };
