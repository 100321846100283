import { ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { LightGalleryDirective } from '../../directives';
var GalleryComponent = /** @class */ (function () {
    function GalleryComponent(cdRef) {
        this.cdRef = cdRef;
        this.envUrl = 'medias/';
        this.emptyMessage = 'profile-view.achievements-empty';
    }
    GalleryComponent.prototype.ngOnChanges = function (changes) {
        var mediasChange = changes['medias'];
        if (mediasChange && !mediasChange.isFirstChange()) {
            this.cdRef.detectChanges();
            if (this.lightGalleryDirective) {
                this.lightGalleryDirective.refresh();
            }
        }
    };
    return GalleryComponent;
}());
export { GalleryComponent };
