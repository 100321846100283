import {HostListener, Input, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {BaseUserDto, UserAccountDto} from '../../core/models';
import * as loginModalActions from '../../core/store/actions/login.modal';
import * as fromRoot from '../../store';
import * as userActions from '../../users/store/actions/user.view';

// @Directive({
//     selector: '[appSendMessage]'
// })
export abstract class AbstractSendMessageDirective implements OnInit {

    currentUser$: Observable<UserAccountDto>;
    currentUser: UserAccountDto;

    @Input() appSendMessage: BaseUserDto;

    constructor(private store: Store<fromRoot.State>) {
        this.currentUser$ = this.store.pipe(select(fromRoot.getCurrentUser));
    }

    ngOnInit(): void {
        this.currentUser$.subscribe(current => {
            this.currentUser = current;
        });
    }

    @HostListener('click')
    sendMessage() {
        if (this.currentUser) {
            this.store.dispatch(new userActions.OpenSendMessageAction(this.appSendMessage));
        } else {
            this.store.dispatch(new loginModalActions.OpenModalAction());
        }
    }

    abstract doSendMessageAction();

}
