import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ErrorsDto, Pageable, PromotionResultType, UserResultDto} from '../../../core/models';
import {BrowserService} from '../../../core/services';
import {routeActions} from '../../../core/store/actions';
import {CommonUtils} from '../../../core/utils';

import * as fromRoot from '../../../store';
import {workersPromotionActions} from '../../store/actions';
import {PromotionWorkersHelper} from './helper/promotion-workers.helper';

@Component({
    moduleId: module.id,
    selector: 'app-promotion-workers',
    templateUrl: './promotion-workers.component.html',
    styleUrls: ['./promotion-workers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PromotionWorkersHelper]
})
export class PromotionWorkersComponent implements OnInit, OnDestroy {

    private subscription = new Subscription();

    @Input() row1: number;
    @Input() row2: number;

    title = '';
    titleKey = 'users-search.last-title';
    pageable: Pageable = new Pageable(5);

    loading$: Observable<boolean>;
    errors$: Observable<ErrorsDto>;

    city: string;
    workers: UserResultDto[];

    constructor(private store: Store<fromRoot.State>,
                private browserService: BrowserService,
                private translateService: TranslateService,
                private promotionWorkersHelper: PromotionWorkersHelper) {
        this.loading$ = this.store.pipe(select(fromRoot.isWorkersPromotionLoading));
        this.errors$ = this.store.pipe(select(fromRoot.getWorkersPromotionErrors));
    }

    ngOnInit() {
        this.store.dispatch(new workersPromotionActions.LoadPromotionWorkersAction(this.pageable));

        const promotionResultSub = this.store.pipe(
            select(fromRoot.getWorkersPromotionResult),
            filter(result => CommonUtils.notEmpty(result))
        ).subscribe(result => {
            this.city = result.city;
            this.workers = result.page.content;

            this.title = this.translateService.instant('users-search.last-title');

            if (result.type === PromotionResultType.ADDRESS) {
                this.titleKey = 'users-search.last-title-in-city';
                this.title = this.translateService.instant('users-search.last-title-in-city', {city: this.city});
            }

            this.promotionWorkersHelper.push(this.workers);
        });

        this.subscription.add(promotionResultSub);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    navigateToWorker(worker: UserResultDto) {
        this.store.dispatch(new routeActions.ToWorkerAction(worker.uid))
    }

    get dataItems() {
        return this.promotionWorkersHelper.dataItems;
    }

    getServicesText(services: any[]): string {
        if (services) {
            let length = services.length;
            let text = this.translateService.instant('global.propose') + ' ' + length;

            let suffix = ` ${this.translateService.instant('global.services')}`;
            if (length === 1) {
                suffix = ` ${this.translateService.instant('global.service')}`;
            }

            return text + suffix;
        }

        return '';
    }
}
