import {ModalEvent} from '../../models';

import {loginModalActions} from '../actions';

export interface State {
    event: ModalEvent
}

export const initialState: State = {
    event: ModalEvent.CLOSE
};

export function reducer(state = initialState, action: loginModalActions.Actions): State {

    switch (action.type) {

        case loginModalActions.LoginModalActionTypes.OpenModal: {
            return {
                event: ModalEvent.OPEN
            };
        }

        case loginModalActions.LoginModalActionTypes.CloseModal: {
            return {
                event: ModalEvent.CLOSE
            };
        }

        default: {
            return state;
        }
    }
}

export const getEvent = (state: State): ModalEvent => state.event;
