import {isPlatformBrowser} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {select, Store} from '@ngrx/store';

import * as ifvisible from 'ifvisible.js';
import {Observable} from 'rxjs';
import * as fromAccount from '../../../account/store';
import {Notice, UserAccountDto} from '../../../core/models';
import {NoticeService} from '../../../core/services';

import {authenticationActions} from '../../../core/store/actions';
import {UserAccountUtils} from '../../../core/utils';
import * as fromRoot from '../../../store';

declare var $: any;

@Component({
    moduleId: module.id,
    selector: 'app-navbar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent implements OnInit {

    authenticated$: Observable<boolean>;
    currentUser$: Observable<UserAccountDto>;
    unreadMessagesCount: number;
    notices: Notice[];

    UserAccountUtils = UserAccountUtils;

    authed: boolean;
    timeoutInterval: number;
    conversationCountIntervalId: any;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private store: Store<fromRoot.State>,
                private accountStore: Store<fromAccount.AccountState>,
                private noticeService: NoticeService,
                private cdRef: ChangeDetectorRef) {
        this.authenticated$ = this.store.pipe(select(fromRoot.isAuthenticated));
        this.currentUser$ = this.store.pipe(select(fromRoot.getCurrentUser));

        this.timeoutInterval = 60 * 1000;
    }

    ngOnInit() {

        // this.noticeService.ngOnInit();

        if (isPlatformBrowser(this.platformId)) {
            $('.button-collapse').sideNav();

            ifvisible.on('wakeup', () => {
                this.countConversation();
            });

            this.authenticated$
                .subscribe(authed => {
                    this.authed = authed;
                    if (authed) {
                        this.countConversation();
                        this.conversationCountIntervalId = setInterval(() => this.countConversation(), this.timeoutInterval);
                    } else {
                        this.destroyConversationCount();
                    }
                });
        }

        this.store.pipe(select(fromRoot.getNotices))
            .subscribe(notices => this.notices = notices);

        this.store.pipe(select(fromRoot.getUnreadConversationsCount))
            .subscribe(count => {
                this.unreadMessagesCount = count;
                this.cdRef.detectChanges();
            });
    }

    destroyConversationCount() {
        clearInterval(this.conversationCountIntervalId);
    }

    logout() {
        this.store.dispatch(new authenticationActions.LogOutAction());
    }

    countConversation() {

        if (!ifvisible.now() || !this.authed) {
            return;
        }

        this.store.dispatch(new authenticationActions.CountUnreadConversationsAction())
    }

}
