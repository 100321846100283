import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {authenticationActions, signUpActions} from '../actions';
import {ErrorsDto, Notification} from '../../models';
import {SignUpService, AuthService} from '../../services';

@Injectable()
export class SignUpEffects {

    @Effect()
    signUp = this.actions$.pipe(
        ofType<signUpActions.SignUpAction>(signUpActions.SignUpActionTypes.SignUp),
        map(action => action.payload),
        mergeMap(userRegistration =>
            this.signUpService.signUp(userRegistration).pipe(
                mergeMap(session => {
                    this.authService.setSession(session);

                    return [
                        new authenticationActions.AuthenticateSuccessAction(session.user),
                        // new signUpActions.SignUpSuccessAction(),
                        new signUpActions.SignUpStoreEmailAction(userRegistration.email)
                    ]
                }),
                catchError((errors: ErrorsDto) => of(new signUpActions.SignUpFailureAction(errors)))
            )
        )
    );

    @Effect()
    signUpResentEmailActivation = this.actions$.pipe(
        ofType<signUpActions.SignUpConfirmationResentEmailAction>(signUpActions.SignUpActionTypes.SignUpConfirmationResentEmail),
        map(action => action.payload),
        mergeMap(email =>
            this.signUpService.resendEmailConfirmation(email).pipe(
                map(() => {
                    let text = this.translateService.instant('sign-up.mail-success');
                    let notification = Notification.buildSuccessNotification(text + email);
                    return new signUpActions.SignUpConfirmationResentEmailSuccessAction(notification);
                }),
                catchError((errors: ErrorsDto) => of(new signUpActions.SignUpConfirmationResentEmailFailureAction(errors)))
            )
        )
    );

    constructor(private actions$: Actions,
                private translateService: TranslateService,
                private authService: AuthService,
                private signUpService: SignUpService) {

    }
}
