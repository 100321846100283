export var ActivityChooserManagerActionTypes;
(function (ActivityChooserManagerActionTypes) {
    ActivityChooserManagerActionTypes["SelectActivity"] = "[ACTIVITY CHOOSER MANAGER] SELECT_ACTIVITY";
    ActivityChooserManagerActionTypes["SelectCategory"] = "[ACTIVITY CHOOSER MANAGER] SELECT_CATEGORY";
    ActivityChooserManagerActionTypes["SetActivities"] = "[ACTIVITY CHOOSER MANAGER] SET_ACTIVITIES";
    ActivityChooserManagerActionTypes["SetCategories"] = "[ACTIVITY CHOOSER MANAGER] SET_CATEGORIES";
    ActivityChooserManagerActionTypes["SelectOrDeselectActivity"] = "[ACTIVITY CHOOSER MANAGER] SELECT_OR_DESELECT_ACTIVITY";
    ActivityChooserManagerActionTypes["SetSelectedActivities"] = "[ACTIVITY CHOOSER MANAGER] SET_SELECTED_ACTIVITIES";
    ActivityChooserManagerActionTypes["SetSelectedCategories"] = "[ACTIVITY CHOOSER MANAGER] SET_SELECTED_CATEGORIES";
    ActivityChooserManagerActionTypes["Reset"] = "[ACTIVITY CHOOSER MANAGER] RESET";
    ActivityChooserManagerActionTypes["Init"] = "[ACTIVITY CHOOSER MANAGER] INIT";
})(ActivityChooserManagerActionTypes || (ActivityChooserManagerActionTypes = {}));
var SelectActivityAction = /** @class */ (function () {
    function SelectActivityAction(payload) {
        this.payload = payload;
        this.type = ActivityChooserManagerActionTypes.SelectActivity;
    }
    return SelectActivityAction;
}());
export { SelectActivityAction };
var SelectCategoryAction = /** @class */ (function () {
    function SelectCategoryAction(payload) {
        this.payload = payload;
        this.type = ActivityChooserManagerActionTypes.SelectCategory;
    }
    return SelectCategoryAction;
}());
export { SelectCategoryAction };
var SelectOrDeselectActivityAction = /** @class */ (function () {
    function SelectOrDeselectActivityAction(payload) {
        this.payload = payload;
        this.type = ActivityChooserManagerActionTypes.SelectOrDeselectActivity;
    }
    return SelectOrDeselectActivityAction;
}());
export { SelectOrDeselectActivityAction };
var SetActivitiesAction = /** @class */ (function () {
    function SetActivitiesAction(payload) {
        this.payload = payload;
        this.type = ActivityChooserManagerActionTypes.SetActivities;
    }
    return SetActivitiesAction;
}());
export { SetActivitiesAction };
var SetCategoriesAction = /** @class */ (function () {
    function SetCategoriesAction(payload) {
        this.payload = payload;
        this.type = ActivityChooserManagerActionTypes.SetCategories;
    }
    return SetCategoriesAction;
}());
export { SetCategoriesAction };
var SetSelectedActivitiesAction = /** @class */ (function () {
    function SetSelectedActivitiesAction(payload) {
        this.payload = payload;
        this.type = ActivityChooserManagerActionTypes.SetSelectedActivities;
    }
    return SetSelectedActivitiesAction;
}());
export { SetSelectedActivitiesAction };
var SetSelectedCategoriesAction = /** @class */ (function () {
    function SetSelectedCategoriesAction(payload) {
        this.payload = payload;
        this.type = ActivityChooserManagerActionTypes.SetSelectedCategories;
    }
    return SetSelectedCategoriesAction;
}());
export { SetSelectedCategoriesAction };
var InitAction = /** @class */ (function () {
    function InitAction() {
        this.type = ActivityChooserManagerActionTypes.Init;
    }
    return InitAction;
}());
export { InitAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = ActivityChooserManagerActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
