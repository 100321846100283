import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UserResultDto} from '../../../../core/models';

@Component({
    selector: 'worker-card',
    templateUrl: './worker-card.component.html',
    styleUrls: ['./worker-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkerCardComponent {

    @Input() worker: UserResultDto;

    @Output() workerSelect = new EventEmitter<UserResultDto>();

}
