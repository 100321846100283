export interface Toast {
    key: string;
    message: string;
    type?: ToastType;
    duration?: number;
}

export class ToastImpl implements Toast {
    key: string;
    message: string;
    type? = ToastType.INFO;
    duration? = 4000;
}

export enum ToastType {

    INFO,

    SUCCESS,

    ERROR,

    WARNING
}
