import {Injectable} from '@angular/core';
import {IBrowserService, Notification, RequestContext, StorageKey} from '../models';

@Injectable({
    providedIn: 'root'
})
export abstract class BrowserService implements IBrowserService {

    abstract materialize();

    abstract materializeWithDelay(delay: number);

    abstract scrollToTop();

    abstract isPlatformBrowser();

    abstract isPlatformServer();

    abstract getServerRequestContext(): RequestContext;

    abstract setCookie(key: StorageKey, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean): void;

    abstract getCookie(key: StorageKey): string;

    abstract alertNotification(notif: Notification);

    abstract hideIndicator();

    abstract back();
}
