import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import {searchActivityChooserActions} from '../actions';

@Injectable()
export class ActivityEffects {

    @Effect()
    public selectSearchCategory = this.actions$.pipe(
        ofType(searchActivityChooserActions.SearchActivityChooserActionTypes.SelectCategory),
        map((action: searchActivityChooserActions.SelectCategoryAction) => action.payload),
        map(category => new searchActivityChooserActions.SetActivitiesAction(category.activities))
    );

    constructor(private actions$: Actions) {
    }

}
