export type CallbackType = "CLOSE" | "RESPONSE";

export const CallbackType = {
    CLOSE: <CallbackType>'CLOSE',
    RESPONSE: <CallbackType>'RESPONSE'
};

export interface ModalCallback<T> {
    payload: T,
    type: CallbackType
}

export class ModalCallback<T> implements ModalCallback<T> {

    constructor(public payload: T, public type = CallbackType.RESPONSE) {
    }

    isClose() {
        return this.type === CallbackType.CLOSE;
    }
}


