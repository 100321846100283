var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TranslateService } from '@ngx-translate/core';
import { ToastType } from '../models';
import { AbstractNotificationService } from './abstract-notification.service';
import { BrowserService } from './browser.service';
import * as i0 from "@angular/core";
import * as i1 from "./browser.service";
import * as i2 from "@ngx-translate/core";
var NotificationService = /** @class */ (function (_super) {
    __extends(NotificationService, _super);
    function NotificationService(browserService, translateService) {
        var _this = _super.call(this, translateService) || this;
        _this.browserService = browserService;
        _this.translateService = translateService;
        return _this;
    }
    NotificationService.prototype.displayToast = function (toast) {
        var className;
        switch (toast.type) {
            case ToastType.SUCCESS:
                className = 'success';
                break;
            case ToastType.WARNING:
                className = 'warning';
                break;
            case ToastType.ERROR:
                className = 'error';
                break;
        }
        var message;
        if (toast.message) {
            message = toast.message;
        }
        else {
            message = this.translateService.instant(toast.key);
        }
        if (this.browserService.isPlatformBrowser()) {
            Materialize.toast(message, toast.duration, className);
        }
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.BrowserService), i0.ɵɵinject(i2.TranslateService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}(AbstractNotificationService));
export { NotificationService };
