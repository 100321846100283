import {Injectable} from '@angular/core';
import {UserResultDto} from '../../../../core/models';

@Injectable()
export class PromotionWorkersHelper {

    push(reqs: UserResultDto[]) {
    }

    get dataItems() {
        return [];
    }
}
