import {ActivityDto} from '../models';

export class ActivityUtils {

    public static containsActivity(activities: ActivityDto[], activity: ActivityDto): boolean {
        return ActivityUtils.containsById(activities, activity.id);
    }

    public static containsById(activities: ActivityDto[], activityId: number): boolean {
        return ActivityUtils.getById(activities, activityId) != null;
    }

    public static getById(activities: ActivityDto[], activityId: number): ActivityDto {

        if (!activities) {
            return null;
        }

        let find = activities.find(c => c.id === activityId);

        if (!find) {
            return null;
        }

        return find;
    }

    public static remove(activities: ActivityDto[], activity: ActivityDto): ActivityDto[] {
        return ActivityUtils.removeById(activities, activity.id);
    }

    public static removeById(activities: ActivityDto[], activityId: number): ActivityDto[] {
        let fn = activities.filter(act => act.id !== activityId);
        return fn;
    }
}
