import {APP_INITIALIZER, Injectable, Injector, ModuleWithProviders, NgModule} from '@angular/core';
import {Locale} from '../core/models';
import {LocalizeParser} from './localize-router.parser';
import {Router, RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {LocalizeRouterConfig, RAW_ROUTES} from './localize-router.config';

@Injectable()
export class ParserInitializer {
    parser: LocalizeParser;
    routes: Routes;

    /**
     * CTOR
     * @param injector
     */
    constructor(private injector: Injector) {
    }

    /**
     * @returns {Promise<any>}
     */
    appInitializer(): Promise<any> {
        const res = this.parser.init(this.routes);

        res.then(() => {
            let router = this.injector.get(Router);
            router.resetConfig(this.parser.routes);
        });

        return res;
    }

    /**
     * @param parser
     * @param routes
     * @returns {()=>Promise<any>}
     */
    generateInitializer(parser: LocalizeParser, routes: Routes[]): () => Promise<any> {
        this.parser = parser;
        this.parser.locales = Locale.SUPPORTED_LOCALES;
        this.routes = routes.reduce((a, b) => a.concat(b));
        return this.appInitializer;
    }
}

/**
 * @param p
 * @param parser
 * @param routes
 * @returns {any}
 */
export function getAppInitializer(p: ParserInitializer, parser: LocalizeParser, routes: Routes[]): any {
    return p.generateInitializer(parser, routes).bind(p);
}

@NgModule({
    imports: [CommonModule, RouterModule, TranslateModule],
    declarations: [],
    exports: []
})
export class LocalizeRouterModule {

    static forRoot(routes: Routes, config: LocalizeRouterConfig = {}): ModuleWithProviders {
        return {
            ngModule: LocalizeRouterModule,
            providers: [
                {
                    provide: RAW_ROUTES,
                    multi: true,
                    useValue: routes
                },
                config.parser,
                ParserInitializer,
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    useFactory: getAppInitializer,
                    deps: [ParserInitializer, LocalizeParser, RAW_ROUTES]
                }
            ]
        };
    }

    static forChild(routes: Routes): ModuleWithProviders {
        return {
            ngModule: LocalizeRouterModule,
            providers: [
                {
                    provide: RAW_ROUTES,
                    multi: true,
                    useValue: routes
                }
            ]
        };
    }
}

/**
 * @param localizeRouterModule
 * @returns {string}
 */
export function provideForRootGuard(localizeRouterModule: LocalizeRouterModule): string {
    if (localizeRouterModule) {
        throw new Error(
            `LocalizeRouterModule.forRoot() called twice. Lazy loaded modules should use LocalizeRouterModule.forChild() instead.`);
    }
    return 'guarded';
}
