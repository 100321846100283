import {AccountActivationEffects} from './account.activation.effects';
import {ActivityEffects} from './activity.effects';
import {AuthenticationEffects} from './authentication.effects';
import {CategoryEffects} from './category.effects';
import {EmailVerificationEffects} from './email-verification.effects';
import {LocaleEffects} from './locale.effects';
import {PasswordRecoverEffects} from './password.recover.effects';
import {PasswordResetEffects} from './password.reset.effects';
import {ReviewEffects} from './review.effects';
import {RouteEffects} from './route.effects';
import {SendMessageEffects} from './send-message.effects';
import {ShareEffects} from './share.effects';
import {SignUpEffects} from './signup.effects';

export const CORE_EFFECTS: any[] = [
    AccountActivationEffects,
    AuthenticationEffects,
    PasswordRecoverEffects,
    PasswordResetEffects,
    RouteEffects,
    SignUpEffects,
    LocaleEffects,
    EmailVerificationEffects,
    SendMessageEffects,
    ActivityEffects,
    CategoryEffects,
    ReviewEffects,
    ShareEffects
];
