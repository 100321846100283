import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';

import {BasicUserDto, Notification, UserReportDto} from '../../../core/models';

@Component({
    moduleId: module.id,
    selector: 'app-report-user',
    templateUrl: './report-user.component.html',
    styleUrls: ['./report-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportUserComponent implements OnInit {

    @ViewChild('messageInput', {static: false}) messageInput: ElementRef;

    @Input() loading$: Observable<boolean>;
    @Input() notification: Notification;
    @Input() user: BasicUserDto;
    @Input() initListener$: Observable<any>;

    @Output() cancel = new EventEmitter();
    @Output() report = new EventEmitter<UserReportDto>();

    _userReport = new UserReportDto();

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (this.initListener$) {
            this.initListener$.subscribe(() => {
                this._userReport = new UserReportDto();
                this.cdRef.detectChanges();

                if (this.messageInput) {
                    this.messageInput.nativeElement.focus();
                }
            });
        }
    }

    reportUser(form: FormGroup) {
        if (form.invalid) {
            return;
        }

        this.doReport();
    }

    doReport() {
        this._userReport.userUid = this.user.uid;
        this.report.emit(this._userReport);
    }

}
