<div class="container" yfLayoutClass>
    <div class="row">
        <div class="col-md-4">
            <h2 class="city-name" translate>global.casablanca</h2>
            <ul>
                <li *ngFor="let lk of casaLinksKeys" translate>
                    <a [href]="'/workers?' + lk.link">{{lk.title}}</a>
                </li>
            </ul>
        </div>

        <div class="col-md-4">
            <h2 class="city-name" translate>global.rabat</h2>
            <ul>
                <li *ngFor="let lk of rabatLinksKeys" translate>
                    <a [href]="'/workers?' + lk.link">{{lk.title}}</a>
                </li>
            </ul>
        </div>

        <div class="col-md-4">
            <h2 class="city-name" translate>global.agadir</h2>
            <ul>
                <li *ngFor="let lk of agadirLinksKeys" translate>
                    <a [href]="'/workers?' + lk.link">{{lk.title}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>