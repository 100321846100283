import { MapsAPILoader } from '@agm/core';
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { Config } from '../../../config';
import { AddressUtils } from '../../core/utils';
var AddressAutoCompleteDirective = /** @class */ (function () {
    function AddressAutoCompleteDirective(mapsAPILoader, el) {
        this.mapsAPILoader = mapsAPILoader;
        this.el = el;
        this.selectAddress = new EventEmitter();
    }
    AddressAutoCompleteDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.mapsAPILoader.load().then(function () {
            var autoComplete = new google.maps.places.Autocomplete(_this.el.nativeElement, {
                types: ['geocode'],
                componentRestrictions: { country: Config.PLACES_COUNTRY_RESTRICTIONS }
            });
            autoComplete.addListener('place_changed', function () {
                var ad = AddressUtils.buildFromGooglePlaceResult(autoComplete.getPlace());
                _this.selectAddress.emit(ad);
            });
        });
    };
    return AddressAutoCompleteDirective;
}());
export { AddressAutoCompleteDirective };
