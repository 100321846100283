import {Action} from '@ngrx/store';
import {ErrorsDto, Page, Pageable, QuotationDto} from '../../../core/models';

export enum AccountQuotationActionTypes {
    LoadQuotations = '[Account Quotations] LoadQuotations',
    LoadQuotationsSuccess = '[Account Quotations] LoadQuotationsSuccess',
    LoadQuotationsFail = '[Account Quotations] LoadQuotationsFail',
}

export class LoadQuotations implements Action {
    readonly type = AccountQuotationActionTypes.LoadQuotations;

    constructor(public payload: Pageable) {
    }
}

export class LoadQuotationsSuccess implements Action {
    readonly type = AccountQuotationActionTypes.LoadQuotationsSuccess;

    constructor(public payload: Page<QuotationDto>) {
    }
}

export class LoadQuotationsFail implements Action {
    readonly type = AccountQuotationActionTypes.LoadQuotationsFail;

    constructor(public payload: ErrorsDto) {
    }
}

export type Actions = LoadQuotations
    | LoadQuotationsSuccess
    | LoadQuotationsFail;
