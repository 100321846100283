import {Directive, HostListener, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {BaseUserDto, SessionDto} from '../../core/models';

import * as fromRoot from '../../store';
import {userViewActions} from '../../users/store/actions';

@Directive({
    selector: '[appShowPhone]'
})
export class ShowPhoneDirective {

    currentUser: SessionDto;

    @Input() appShowPhone: BaseUserDto;

    constructor(private store: Store<fromRoot.State>) {
    }

    @HostListener('click')
    showPhone() {
        this.store.dispatch(new userViewActions.ShowPhoneAction(this.appShowPhone));
    }

}
