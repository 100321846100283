import {Action} from '@ngrx/store';
import {ErrorsDto, Notification, SessionDto, UserRegistrationDto} from '../../models';

export enum SignUpActionTypes {
    SignUp = '[Register] SIGN_UP',
    SignUpSuccess = '[Register] SIGN_UP_SUCCESS',
    SignUpFailure = '[Register] SIGN_UP_FAILURE',
    SignUpStoreEmail = '[Register] SIGN_UP_STORE_EMAIL',

    RESET = '[Register] RESET',

    SignUpConfirmation = '[Register confirmation] SIGN_UP',
    SignUpConfirmationSuccess = '[Register confirmation] SIGN_UP_SUCCESS',
    SignUpConfirmationFailure = '[Register confirmation] SIGN_UP_FAILURE',

    SignUpConfirmationResentEmail = '[Register confirmation] SUC_RESENT_EMAIL',
    SignUpConfirmationResentEmailSuccess = '[Register confirmation] SUC_RESENT_EMAIL_SUCCESS',
    SignUpConfirmationResentEmailFailure = '[Register confirmation] SUC_RESENT_EMAIL_FAILURE'
}

/**
 * Registration.
 */
export class SignUpAction implements Action {
    readonly type = SignUpActionTypes.SignUp;

    constructor(public payload: UserRegistrationDto) {
    }
}

export class SignUpSuccessAction implements Action {
    readonly type = SignUpActionTypes.SignUpSuccess;

    constructor(public payload: SessionDto) {
    }
}

export class SignUpFailureAction implements Action {
    readonly type = SignUpActionTypes.SignUpFailure;

    constructor(public payload: ErrorsDto) {
    }
}

export class SignUpStoreEmailAction implements Action {
    readonly type = SignUpActionTypes.SignUpStoreEmail;

    constructor(public payload: string) {
    }
}

/**
 * Sign up confirmation.
 */
export class SignUpConfirmationAction implements Action {
    readonly type = SignUpActionTypes.SignUpConfirmation;

    constructor(public payload: UserRegistrationDto) {
    }
}

export class SignUpConfirmationSuccessAction implements Action {
    readonly type = SignUpActionTypes.SignUpConfirmationSuccess;
}

export class SignUpConfirmationFailureAction implements Action {
    readonly type = SignUpActionTypes.SignUpConfirmationFailure;

    constructor(public payload: ErrorsDto) {
    }
}

/**
 * Email confirmation resent.
 */
export class SignUpConfirmationResentEmailAction implements Action {
    readonly type = SignUpActionTypes.SignUpConfirmationResentEmail;

    constructor(public payload: string) {
    }
}

export class SignUpConfirmationResentEmailSuccessAction implements Action {
    readonly type = SignUpActionTypes.SignUpConfirmationResentEmailSuccess;

    constructor(public payload: Notification) {
    }
}

export class SignUpConfirmationResentEmailFailureAction implements Action {
    readonly type = SignUpActionTypes.SignUpConfirmationResentEmailFailure;

    constructor(public payload: ErrorsDto) {
    }
}

/**
 * Reset
 */
export class ResetAction implements Action {
    readonly type = SignUpActionTypes.RESET;
}

export type Actions = SignUpAction
    | SignUpSuccessAction
    | SignUpFailureAction
    | SignUpStoreEmailAction
    | SignUpConfirmationAction
    | SignUpConfirmationSuccessAction
    | SignUpConfirmationFailureAction
    | SignUpConfirmationResentEmailAction
    | SignUpConfirmationResentEmailSuccessAction
    | SignUpConfirmationResentEmailFailureAction
    | ResetAction;
