<div [id]="modalId" class="modal" [ngClass]="modalClass">
    <div class="modal-header center" *ngIf="showHeader">
        <ng-content select="[modal-header]"></ng-content>
        <i class="material-icons close-modal-action" (click)="closeModal()" *ngIf="closable">close</i>
    </div>
    <div class="modal-content" [ngClass]="modalContentClass">
        <ng-content select="[modal-content]"></ng-content>
    </div>
    <div class="modal-footer" *ngIf="showFooter">
        <ng-content select="[modal-footer]"></ng-content>
    </div>
</div>