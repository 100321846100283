import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';

import {ActivityDto, CategoryDto} from '../../../core/models';
import {DeviceService} from '../../../core/services';
import {ActivityUtils, CategoryUtils} from '../../../core/utils';

@Component({
    moduleId: module.id,
    selector: 'app-activity-chooser',
    templateUrl: './service-chooser.component.html',
    styleUrls: ['./service-chooser.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityChooserComponent implements OnInit {

    @Input() categories: CategoryDto[];
    @Input() activities: ActivityDto[];
    @Input() classes: string;
    @Input() selectedCategories: CategoryDto[];
    @Input() selectedActivities: ActivityDto[];
    @Input() initListener: Observable<any>; // to init activity chooser.

    @Output() selectActivity = new EventEmitter<ActivityDto>();
    @Output() selectCategory = new EventEmitter<CategoryDto>();
    @Output() quit = new EventEmitter();

    // Display
    showCategories = true;
    showActivities = true;

    private timeOutRef;
    private timeOutDelay = 150; // ms

    constructor(private deviceService: DeviceService) {
    }

    ngOnInit() {
        this.init();

        if (this.initListener) {
            this.initListener.subscribe(() => this.init());
        }
    }

    isCategorySelected(cat: CategoryDto): boolean {
        return CategoryUtils.containsCategory(this.selectedCategories, cat);
    }

    isActivitySelected(act: ActivityDto): boolean {
        return ActivityUtils.containsActivity(this.selectedActivities, act);
    }

    onSelectCategory(cat: CategoryDto) {
        this.selectCategory.emit(cat);

        if (this.deviceService.isSmallDevice()) {
            this.showCategories = false;
            this.showActivities = true;
        }
    }

    mouseOverCategory(cat: CategoryDto) {

        clearTimeout(this.timeOutRef);

        if (this.deviceService.isSmallDevice()) {
            return;
        }


        this.timeOutRef = setTimeout(() => this.onSelectCategory(cat), this.timeOutDelay);
    }


    onSelectActivity(act: ActivityDto) {
        this.selectActivity.emit(act);
    }

    doGoBack() {

        if (this.showActivities) {
            this.showActivities = false;
            this.showCategories = true;

            return;
        }

        if (this.showCategories) {
            this.quit.emit();

            return;
        }

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        let windowWidth = event.target.innerWidth;

        if (windowWidth > this.deviceService.SM_MAX_WIDTH) {
            this.showCategories = true;
            this.showActivities = true;
        } else {
            this.smallDeviceInit();
        }
    }


    private init() {
        if (this.deviceService.isSmallDevice()) {
            this.smallDeviceInit();
        }

        this.activities = [];
    }

    private smallDeviceInit() {
        this.showCategories = true;
        this.showActivities = false;
    }


    mouseOverActivities() {
        clearTimeout(this.timeOutRef);
    }
}
