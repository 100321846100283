import { EventEmitter } from '@angular/core';
import { DisplayReviewDto } from '../../../../core/models';
var ReviewComponent = /** @class */ (function () {
    function ReviewComponent() {
        this.size = 'sm';
        this.deleteReview = new EventEmitter();
        this.updateReview = new EventEmitter();
    }
    return ReviewComponent;
}());
export { ReviewComponent };
