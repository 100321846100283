import {HttpClient} from '@angular/common/http';
import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2} from '@angular/core';
import {Config} from '../../../config';

const intlTelInput = require('intl-tel-input');
const utilsScript = 'assets/js/utils.js';

@Directive({
    selector: '[appIntlTel]'
})
export class IntlTelDirective implements OnInit {

    private iti: any;

    @Output() selectPhone = new EventEmitter<string>();

    constructor(private elRef: ElementRef,
                private httpClient: HttpClient) {

    }

    ngOnInit(): void {
    }

    @HostListener('keyup')
    onKeyUp() {
        if (this.iti) {
            if (this.iti.isValidNumber()) {
                this.selectPhone.emit(this.iti.getNumber());
            } else {
                // let error = this.iti.getValidationError();
            }
        }
    }

    @Input() set appIntlTel(enabled: boolean) {
        if (enabled) {
            this.iti = intlTelInput(this.elRef.nativeElement,
                {
                    utilsScript: utilsScript,
                    autoPlaceholder: 'off',
                    initialCountry: Config.INITIAL_COUNTRY,
                    preferredCountries: Config.PREFERRED_COUNTRIES,
                    onlyCountries: Config.ALLOWED_COUNTRIES
                    // initialCountry: "auto",
                    // geoIpLookup: (success, failure) => this.geoIpLookup(this.httpClient, success, failure)
                });
        } else {
            if (this.iti) {
                this.iti.destroy();
            }
        }

        this.elRef.nativeElement.focus();
    }

    geoIpLookup(httpClient: HttpClient, success: any, failure: any) {
        httpClient.get('https://api.ipapi.com/api/check').subscribe((resp: any) => {
            const countryCode = (resp && resp.countryCode) ? resp.countryCode : '';
            success(countryCode);
        })
    }
}
