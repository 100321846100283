import { ErrorDto, ErrorsDto, Notification, NotificationType } from '../models';
var ErrorUtils = /** @class */ (function () {
    function ErrorUtils() {
    }
    ErrorUtils.buildValidationErrorsDto = function () {
        var errors = new ErrorsDto();
        errors.code = '4000';
        errors.message = 'Erreur de validation';
        errors.errors = [];
        return errors;
    };
    ErrorUtils.buildFormValidationErrorsDto = function () {
        var errors = this.buildValidationErrorsDto();
        this.addError(errors, 'Veuillez corriger les champs en erreur');
        return errors;
    };
    ErrorUtils.buildErrorsDto = function (message) {
        var errors = new ErrorsDto();
        errors.message = message;
        return errors;
    };
    ErrorUtils.addError = function (errors, errorMsg) {
        var err = new ErrorDto();
        err.message = errorMsg;
        if (!errors.errors) {
            errors.errors = [];
        }
        errors.errors.push(err);
    };
    ErrorUtils.buildErrorDto = function (errorMsg) {
        var err = new ErrorDto();
        err.message = errorMsg;
        return err;
    };
    ErrorUtils.toNotification = function (errors) {
        if (!errors) {
            return null;
        }
        var notification = new Notification();
        notification.type = NotificationType.ERROR;
        notification.title = errors.message;
        notification.detail = errors.detail;
        if (errors.errors) {
            errors.errors.forEach(function (err) { return notification.addMessage(err.message); });
        }
        return notification;
    };
    ErrorUtils.toNotificationWithType = function (errors, notificationType) {
        var notif = this.toNotification(errors);
        notif.type = notificationType;
        return notif;
    };
    ErrorUtils.hasDetail = function (errors, detail) {
        if (!errors) {
            return false;
        }
        if (errors.detail === detail) {
            return true;
        }
        if (!errors.errors) {
            return false;
        }
        var found = errors.errors.find(function (e) { return e.detail === detail; });
        return found != null;
    };
    return ErrorUtils;
}());
export { ErrorUtils };
