var PasswordResetActionDto = /** @class */ (function () {
    function PasswordResetActionDto() {
    }
    PasswordResetActionDto.build = function (email, token, passwordReset) {
        var pr = new PasswordResetActionDto();
        pr.email = email;
        pr.token = token;
        pr.passwordReset = passwordReset;
        return pr;
    };
    return PasswordResetActionDto;
}());
export { PasswordResetActionDto };
