import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {ActivityDto, AddressDto, SearchCriteriaDto} from '../../../../core/models';
import {ActivityTranslatorService} from '../../../../core/services/activity-translator.service';
import {NotificationService, ActivityService} from '../../../../core/services';
import {activityChooserManagerActions} from '../../../../core/store/actions';
import * as fromRoot from '../../../../store';

@Component({
    selector: 'app-search-simple',
    templateUrl: './search-simple.component.html',
    styleUrls: ['./search-simple.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchSimpleComponent implements OnInit, OnDestroy {

    @Output() search = new EventEmitter<SearchCriteriaDto>();

    @ViewChild('searchInput', {static: false}) searchRef: ElementRef;

    _searchCriteria: SearchCriteriaDto;

    activityChooserListener$ = new Subject();
    hasError: boolean;
    _inputText: string;
    selectedActivityName: string;

    constructor(private activityTranslator: ActivityTranslatorService,
                private store: Store<fromRoot.State>,
                private notificationService: NotificationService,
                private activityService: ActivityService,
                private cdRef: ChangeDetectorRef) {
    }

    @Input()
    set searchCriteria(searchCriteria: SearchCriteriaDto) {
        this.init(searchCriteria);
    }

    get searchCriteria() {
        return this._searchCriteria;
    }

    ngOnInit() {
        this.init(this._searchCriteria);
    }

    ngOnDestroy(): void {
    }


    init(searchCriteria: SearchCriteriaDto) {
        if (!searchCriteria) {
            this._searchCriteria = new SearchCriteriaDto();
        } else {
            this._searchCriteria = searchCriteria;
            let activity = this._searchCriteria.activity;

            if (activity) {
                this.activityService.getActivityById(activity.id).subscribe(act => {
                    this.selectedActivityName = this.activityTranslator.translate(act);
                    this.cdRef.detectChanges();
                    this.store.dispatch(new activityChooserManagerActions.SetSelectedActivitiesAction([act]));
                });
            }
        }
    }

    submit(form: FormGroup) {

        if (form.invalid || this.hasError) {
            this.hasError = true;
            this.notificationService.displayErrorKey('errors.search-simple.form-invalid');
            return;
        }

        this.hasError = false;

        this.search.emit(this.searchCriteria);
    }

    addChange(value: string) {
        this._inputText = value ? value : null;
    }

    selectAddress(address: AddressDto) {
        this.searchCriteria.address = address;

        this._inputText = null;

        this.hasAddressError();
        this.cdRef.detectChanges();
    }

    clearAddress() {
        this.searchCriteria.address = null;
    }

    hasAddressError() {
        this.hasError = this.searchCriteria.address == null && this._inputText != null;
    }

    openActivityChooser() {
        this.activityChooserListener$.next();
    }

    selectActivity(activity: ActivityDto) {
        this.searchCriteria.activity = activity;
        this.selectedActivityName = this.activityTranslator.translate(activity);
        this.focusAddress();
    }

    focusAddress() {
        this.searchRef.nativeElement.focus();
    }
}
