/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./share-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@ngx-share/buttons/ngx-share-buttons.ngfactory";
import * as i3 from "@ngx-share/buttons";
import * as i4 from "@ngx-share/core";
import * as i5 from "../modal/modal.component.ngfactory";
import * as i6 from "../modal/modal.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "./share-modal.component";
import * as i10 from "@ngrx/store";
import * as i11 from "../../../../core/services/bc-utils.service";
var styles_ShareModalComponent = [i0.styles];
var RenderType_ShareModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShareModalComponent, data: {} });
export { RenderType_ShareModalComponent as RenderType_ShareModalComponent };
function View_ShareModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "share-buttons", [], null, null, null, i2.View_ShareButtons_0, i2.RenderType_ShareButtons)), i1.ɵdid(2, 245760, null, 0, i3.ShareButtons, [i4.ShareService], { url: [0, "url"], title: [1, "title"], description: [2, "description"], image: [3, "image"], showText: [4, "showText"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; var currVal_1 = _co.title; var currVal_2 = _co.description; var currVal_3 = _co.image; var currVal_4 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ShareModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "app-modal", [["modalClass", "share-modal"], ["modalId", "share-modal-id"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ModalComponent_0, i5.RenderType_ModalComponent)), i1.ɵdid(1, 180224, null, 0, i6.ModalComponent, [], { modalId: [0, "modalId"], modalClass: [1, "modalClass"], showHeader: [2, "showHeader"], modalEventListener: [3, "modalEventListener"] }, { close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 1, 6, "div", [["class", "row align-items-center center share-modal-content"], ["modal-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h1", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShareModalComponent_1)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "share-modal-id"; var currVal_1 = "share-modal"; var currVal_2 = false; var currVal_3 = _co.modalEvent$; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = ""; _ck(_v, 5, 0, currVal_4); var currVal_6 = _co.element; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.titleKey; _ck(_v, 6, 0, currVal_5); }); }
export function View_ShareModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-share-modal", [], null, null, null, View_ShareModalComponent_0, RenderType_ShareModalComponent)), i1.ɵdid(1, 114688, null, 0, i9.ShareModalComponent, [i10.Store, i7.TranslateService, i11.BcUtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShareModalComponentNgFactory = i1.ɵccf("app-share-modal", i9.ShareModalComponent, View_ShareModalComponent_Host_0, {}, {}, []);
export { ShareModalComponentNgFactory as ShareModalComponentNgFactory };
