<div class="row" yfLayoutClass>

    <div class="col-md-6  mb-2 mb-md-0">
        <div class="card">
            <div class="card-block col-12">
                <div class="col-12 center yf-section-header">
                    <h3 translate>how-to.search.title</h3>
                </div>

                <hr class="primary">

                <div class="col-12 text-center">
                    <div class="service-box">
                        <!--<h3 translate>how-to.search.head-1</h3>-->
                        <p class="text-muted" translate>how-to.search.text-1</p>
                    </div>
                </div>

                <!--<div class="col-12 text-center">-->
                    <!--<h3 class="text-uppercase" translate>global.or</h3>-->
                <!--</div>-->

                <!--<div class="col-12 text-center">-->
                    <!--<div class="service-box">-->
                        <!--<h3 translate>how-to.search.head-2</h3>-->
                        <!--<p class="text-muted" translate>how-to.search.text-2</p>-->
                    <!--</div>-->
                <!--</div>-->
            </div>
        </div>
    </div>

    <div class="col-md-6 mb-2 mb-md-0">
        <div class="card">
            <div class="card-block col-12">

                <div class="col-12 center yf-section-header" translate>
                    <h3 translate>how-to.propose.title</h3>
                </div>

                <hr class="primary">

                <div class="col-12 text-center">
                    <div class="service-box">
                        <!--<h3 translate>how-to.propose.head-1</h3>-->
                        <p class="text-muted" translate>how-to.propose.head-1</p>
                        <!--<p class="text-muted" translate>how-to.propose.text-1</p>-->
                    </div>
                </div>

                <!--<div class="col-12 text-center">-->
                    <!--<h3 class="text-uppercase" translate>global.or</h3>-->
                <!--</div>-->

                <!--<div class="col-12 text-center">-->
                    <!--<div class="service-box">-->
                        <!--<h3 translate>how-to.propose.head-2</h3>-->
                        <!--<p class="text-muted" translate>how-to.propose.text-2</p>-->
                    <!--</div>-->
                <!--</div>-->
            </div>
        </div>
    </div>

</div>