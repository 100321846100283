import { Store } from '@ngrx/store';
import { shareActions } from '../../core/store/actions';
var ShareDirective = /** @class */ (function () {
    function ShareDirective(store) {
        this.store = store;
    }
    ShareDirective.prototype.share = function () {
        this.store.dispatch(new shareActions.ShareAction(this.appShare));
    };
    return ShareDirective;
}());
export { ShareDirective };
