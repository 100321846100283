import {Action} from '@ngrx/store';
import {ActivityDto, AddressDto, DisplayMode, ErrorsDto, Notification, UserProfileDto} from '../../../core/models';

export enum AccountProfileActionTypes {
    LoadUserProfile = '[USER_PROFILE] LOAD_USER_PROFILE',
    LoadUserProfileSuccess = '[USER_PROFILE] LOAD_USER_PROFILE_SUCCESS',
    LoadUserProfileFail = '[USER_PROFILE] LOAD_USER_PROFILE_FAIL',

    UpdateUserProfile = '[USER_PROFILE] UPDATE_USER_PROFILE',
    UpdateUserProfileSuccess = '[USER_PROFILE] UPDATE_USER_PROFILE_SUCCESS',
    UpdateUserProfileFail = '[USER_PROFILE] UPDATE_USER_PROFILE_FAIL',

    SetShadowUserProfile = '[USER_PROFILE] SET_SHADOW_USER_PROFILE',
    ResetUserProfile = '[USER_PROFILE] RESET_USER_PROFILE',

    LoadUserProfileIfNull = '[USER_PROFILE] LOAD_USER_PROFILE_IF_NULL',

    // Personal information
    UpdatePictureFail = '[USER_PROFILE] UPDATE_PICTURE_FAIL',
    SetPicture = '[USER_PROFILE] SET_PICTURE',

    // Professional information
    SelectActivity = '[USER_PROFILE] SELECT_ACTIVITY',
    SetAddress = '[USER_PROFILE] SET_ADDRESS',

    // Portfolio
    AddOrDeleteMediaFromDeleteList = '[USER_PROFILE] ADD_OR_DELETE_MEDIA_FROM_DELETE_LIST',
    AddAllMediasToDeleteList = '[USER_PROFILE] ADD_ALL_MEDIAS_TO_DELETE_LIST',
    DeleteAllMediasFromDeleteList = '[USER_PROFILE] DELETE_ALL_MEDIAS_FROM_DELETE_LIST',

    AddMediasFail = '[USER_PROFILE] ADD_MEDIAS_FAIL',
    SetMedias = '[USER_PROFILE] SET_MEDIAS',

    ChangePortfolioDisplayMode = '[USER_PROFILE] CHANGE_PORTFOLIO_DISPLAY_MODE',
    CancelMediasDeletion = '[USER_PROFILE] CANCEL_MEDIAS_DELETION',

    DeleteMedias = '[USER_PROFILE] DELETE_MEDIAS',
    DeleteMediasSuccess = '[USER_PROFILE] DELETE_MEDIAS_SUCCESS',
    DeleteMediasFail = '[USER_PROFILE] DELETE_MEDIAS_FAIL',

    ResetUpdated = '[USER_PROFILE] RESET_UPDATED',
    ResetPortfolioErrors = '[USER_PROFILE] RESET_PORTFOLIO_ERRORS',
    SetPersonalInfosErrors = '[USER_PROFILE] SET_PERSONAL_INFOS_ERRORS',
    SetServicesNotification = '[USER_PROFILE] SET_SERVICES_NOTIFICATION',

    // Verifications
    SubmitPhoneVerification = '[USER_PROFILE] SubmitPhoneVerification',
    SubmitPhoneVerificationSuccess = '[USER_PROFILE] SubmitPhoneVerificationSuccess',
    SubmitPhoneVerificationFail = '[USER_PROFILE] SubmitPhoneVerificationFail',
    ResetPhoneVerification = '[USER_PROFILE] ResetPhoneVerification',
    VerifyPhoneNumber = '[USER_PROFILE] VerifyPhoneNumber',
    VerifyPhoneNumberSuccess = '[USER_PROFILE] VerifyPhoneNumberSuccess',
    VerifyPhoneNumberFail = '[USER_PROFILE] VerifyPhoneNumberFail',

    VerifyEmail = '[USER_PROFILE] VerifyEmail',
    VerifyEmailSuccess = '[USER_PROFILE] VerifyEmailSuccess',
    VerifyEmailFail = '[USER_PROFILE] VerifyEmailFail',
}

/***************************
 *      Profile
 ***************************/
export class LoadUserProfileIfNullAction implements Action {
    readonly type = AccountProfileActionTypes.LoadUserProfileIfNull;
}

export class LoadUserProfileAction implements Action {
    readonly type = AccountProfileActionTypes.LoadUserProfile;
}

export class LoadUserProfileSuccessAction implements Action {
    readonly type = AccountProfileActionTypes.LoadUserProfileSuccess;

    constructor(public payload: UserProfileDto) {
    }
}

export class LoadUserProfileFailAction implements Action {
    readonly type = AccountProfileActionTypes.LoadUserProfileFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class SetPersonalInfosErrorsAction implements Action {
    readonly type = AccountProfileActionTypes.SetPersonalInfosErrors;

    constructor(public payload: ErrorsDto) {
    }
}

export class UpdateUserProfileAction implements Action {
    readonly type = AccountProfileActionTypes.UpdateUserProfile;

    constructor(public payload: UserProfileDto) {
    }
}

export class UpdateUserProfileSuccessAction implements Action {
    readonly type = AccountProfileActionTypes.UpdateUserProfileSuccess;

    constructor(public payload: UserProfileDto) {
    }
}

export class UpdateUserProfileFailAction implements Action {
    readonly type = AccountProfileActionTypes.UpdateUserProfileFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class SetShadowUserProfileAction implements Action {
    readonly type = AccountProfileActionTypes.SetShadowUserProfile;
}

export class ResetUserProfileAction implements Action {
    readonly type = AccountProfileActionTypes.ResetUserProfile;
}

/***************************
 *   Personal information
 ***************************/
export class UpdatePictureFailAction implements Action {
    readonly type = AccountProfileActionTypes.UpdatePictureFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class SetPictureAction implements Action {
    readonly type = AccountProfileActionTypes.SetPicture;

    constructor(public payload: string) {
    }
}

/***************************
 * Professional information
 ***************************/

export class SelectActivityAction implements Action {
    readonly type = AccountProfileActionTypes.SelectActivity;

    constructor(public payload: ActivityDto) {
    }
}

export class SetAddressAction implements Action {
    readonly type = AccountProfileActionTypes.SetAddress;

    constructor(public payload: AddressDto) {
    }
}

/***************************
 * Portfolio
 ***************************/
export class AddMediasFailAction implements Action {
    readonly type = AccountProfileActionTypes.AddMediasFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class AddOrDeleteMediaFromDeleteListAction implements Action {
    readonly type = AccountProfileActionTypes.AddOrDeleteMediaFromDeleteList;

    constructor(public payload: string) {
    }
}

export class AddAllMediasToDeleteListAction implements Action {
    readonly type = AccountProfileActionTypes.AddAllMediasToDeleteList;
}

export class DeleteAllMediasFromDeleteListAction implements Action {
    readonly type = AccountProfileActionTypes.DeleteAllMediasFromDeleteList;
}

export class DeleteMediasAction implements Action {
    readonly type = AccountProfileActionTypes.DeleteMedias;
}

export class DeleteMediasSuccessAction implements Action {
    readonly type = AccountProfileActionTypes.DeleteMediasSuccess;
}

export class DeleteMediasFailAction implements Action {
    readonly type = AccountProfileActionTypes.DeleteMediasFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class ChangePortfolioDisplayModeAction implements Action {
    readonly type = AccountProfileActionTypes.ChangePortfolioDisplayMode;

    constructor(public payload: DisplayMode) {
    }
}

export class CancelMediasDeletionAction implements Action {
    readonly type = AccountProfileActionTypes.CancelMediasDeletion;
}

export class SetMediasAction implements Action {
    readonly type = AccountProfileActionTypes.SetMedias;

    constructor(public payload: string[]) {
    }
}

export class SetServicesNotificationAction implements Action {
    readonly type = AccountProfileActionTypes.SetServicesNotification;

    constructor(public payload: Notification) {
    }
}

export class ResetUpdatedAction implements Action {
    readonly type = AccountProfileActionTypes.ResetUpdated;
}

export class ResetPortfolioErrorsAction implements Action {
    readonly type = AccountProfileActionTypes.ResetPortfolioErrors;
}

/***************************
 * Verifications
 ***************************/
export class SubmitPhoneVerification implements Action {
    readonly type = AccountProfileActionTypes.SubmitPhoneVerification;
}

export class SubmitPhoneVerificationSuccess implements Action {
    readonly type = AccountProfileActionTypes.SubmitPhoneVerificationSuccess;
}

export class SubmitPhoneVerificationFail implements Action {
    readonly type = AccountProfileActionTypes.SubmitPhoneVerificationFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class ResetPhoneVerification implements Action {
    readonly type = AccountProfileActionTypes.ResetPhoneVerification;
}

export class VerifyPhoneNumberAction implements Action {
    readonly type = AccountProfileActionTypes.VerifyPhoneNumber;

    constructor(public payload: string) {
    }
}

export class VerifyPhoneNumberSuccessAction implements Action {
    readonly type = AccountProfileActionTypes.VerifyPhoneNumberSuccess;
}

export class VerifyPhoneNumberFailAction implements Action {
    readonly type = AccountProfileActionTypes.VerifyPhoneNumberFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class VerifyEmailAction implements Action {
    readonly type = AccountProfileActionTypes.VerifyEmail;
}

export class VerifyEmailSuccessAction implements Action {
    readonly type = AccountProfileActionTypes.VerifyEmailSuccess;
}

export class VerifyEmailFailAction implements Action {
    readonly type = AccountProfileActionTypes.VerifyEmailSuccess;

    constructor(public payload: ErrorsDto) {
    }
}

export type Actions = LoadUserProfileAction
    | LoadUserProfileSuccessAction
    | LoadUserProfileFailAction
    | UpdateUserProfileAction
    | UpdateUserProfileSuccessAction
    | UpdateUserProfileFailAction
    | UpdatePictureFailAction
    | AddMediasFailAction
    | SelectActivityAction
    | SetAddressAction
    | AddOrDeleteMediaFromDeleteListAction
    | AddAllMediasToDeleteListAction
    | DeleteMediasAction
    | DeleteMediasSuccessAction
    | DeleteMediasFailAction
    | DeleteAllMediasFromDeleteListAction
    | ChangePortfolioDisplayModeAction
    | CancelMediasDeletionAction
    | ResetUserProfileAction
    | SetMediasAction
    | SetPictureAction
    | SetShadowUserProfileAction
    | ResetUpdatedAction
    | SetServicesNotificationAction
    | SetPersonalInfosErrorsAction
    | LoadUserProfileIfNullAction
    | ResetPortfolioErrorsAction
    | SubmitPhoneVerification
    | SubmitPhoneVerificationSuccess
    | SubmitPhoneVerificationFail
    | ResetPhoneVerification
    | VerifyPhoneNumberAction
    | VerifyPhoneNumberSuccessAction
    | VerifyPhoneNumberFailAction
    | VerifyEmailAction
    | VerifyEmailSuccessAction
    | VerifyEmailFailAction
    ;
