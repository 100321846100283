import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { routeActions } from '../../store/actions';
import * as fromRoot from '../../../store';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var NotAuthedGuard = /** @class */ (function () {
    function NotAuthedGuard(store) {
        this.store = store;
    }
    NotAuthedGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.store.pipe(select(fromRoot.getCurrentUser), map(function (currentUser) {
            if (currentUser) {
                _this.store.dispatch(new routeActions.ToUserProfileAction());
                return false;
            }
            return true;
        }));
    };
    NotAuthedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotAuthedGuard_Factory() { return new NotAuthedGuard(i0.ɵɵinject(i1.Store)); }, token: NotAuthedGuard, providedIn: "root" });
    return NotAuthedGuard;
}());
export { NotAuthedGuard };
