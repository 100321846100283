import { HttpClient } from '@angular/common/http';
import { HttpUtil } from '../utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ReviewService = /** @class */ (function () {
    function ReviewService(httpClient) {
        this.httpClient = httpClient;
    }
    ReviewService.prototype.addReview = function (userUid, addReviewDto) {
        return this.httpClient.post(HttpUtil.buildAndExpand(ReviewService.PROFILE_REVIEWS, userUid), addReviewDto);
    };
    ReviewService.prototype.getReviews = function (userUid) {
        return this.httpClient.get(HttpUtil.buildAndExpand(ReviewService.PROFILE_REVIEWS, userUid));
    };
    ReviewService.prototype.reportReview = function (reviewReport) {
        return this.httpClient.post(ReviewService.REPORT_REVIEWS, reviewReport);
    };
    ReviewService.PROFILE_REVIEWS = 'api/v1/users/{userUid}/profile/reviews';
    ReviewService.REPORT_REVIEWS = 'api/v1/reports/reviews';
    ReviewService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReviewService_Factory() { return new ReviewService(i0.ɵɵinject(i1.HttpClient)); }, token: ReviewService, providedIn: "root" });
    return ReviewService;
}());
export { ReviewService };
