import { Store } from '@ngrx/store';
import { ReviewDto } from '../../core/models';
import { reportReviewActions } from '../../core/store/actions';
var ReportReviewDirective = /** @class */ (function () {
    function ReportReviewDirective(store) {
        this.store = store;
    }
    ReportReviewDirective.prototype.report = function () {
        this.store.dispatch(new reportReviewActions.OpenModalAction(this.appReportReview));
    };
    return ReportReviewDirective;
}());
export { ReportReviewDirective };
