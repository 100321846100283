import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AddReviewDto, DisplayReviewDto, ReviewReportDto} from '../models';
import {HttpUtil} from '../utils';

@Injectable({
    providedIn: 'root'
})
export class ReviewService {

    private static PROFILE_REVIEWS = 'api/v1/users/{userUid}/profile/reviews';
    private static REPORT_REVIEWS = 'api/v1/reports/reviews';

    constructor(private httpClient: HttpClient) {
    }

    addReview(userUid: string, addReviewDto: AddReviewDto): Observable<any> {
        return this.httpClient.post(HttpUtil.buildAndExpand(ReviewService.PROFILE_REVIEWS, userUid), addReviewDto);
    }

    getReviews(userUid: string): Observable<DisplayReviewDto[]> {
        return this.httpClient.get<DisplayReviewDto[]>(HttpUtil.buildAndExpand(ReviewService.PROFILE_REVIEWS, userUid));
    }

    reportReview(reviewReport: ReviewReportDto): Observable<any> {
        return this.httpClient.post(ReviewService.REPORT_REVIEWS, reviewReport);
    }

}
