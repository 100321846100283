import {ActionReducerMap, createSelector} from '@ngrx/store';

import * as fromActivateAccount from '../core/store/reducers/account.activate.reducer';
import * as fromAuthentication from '../core/store/reducers/authentication.reducer';
import * as fromEmailVerification from '../core/store/reducers/email-verification.reducer';
import * as fromLocale from '../core/store/reducers/locale.reducer';
import * as fromNotice from '../core/store/reducers/notice.reducer';
import * as fromPasswordRecover from '../core/store/reducers/password.recover.reducer';
import * as fromPasswordReset from '../core/store/reducers/password.reset.reducer';
import * as fromSignUp from '../core/store/reducers/signup.reducer';
import * as fromMessageModal from '../core/store/reducers/message.modal.reducer';
import * as fromSenderMessage from '../core/store/reducers/send-message.reducer';
import * as fromRequestsPromotion from '../promotions/store/reducers/request.promotion.reducer';
import * as fromWorkersPromotion from '../promotions/store/reducers/workers.promotion.reducer';
import * as fromReview from '../core/store/reducers/review.reducer';
import * as fromReportReview from '../core/store/reducers/review.report.reducer';
import * as fromActivityChooserManager from '../core/store/reducers/activity-chooser.manager.reducer';
import * as fromActivityChooserModal from '../core/store/reducers/activity-chooser.modal.reducer';
import * as fromCategory from '../core/store/reducers/category.reducer';
import * as fromSearchActivityChooser from '../core/store/reducers/search-activity-chooser.reducer';
import * as fromLoginModal from '../core/store/reducers/login.modal.reducer';
import * as fromPhoneModal from '../core/store/reducers/phone.modal.reducer';
import * as fromReportUser from '../users/store/reducers/user.report.reducer';
import * as fromSearch from '../users/store/reducers/user.search.reducer';
import * as fromShare from '../core/store/reducers/share.reducer';
import * as fromUserView from '../users/store/reducers/user.view.reducer';

export interface State {
    authentication: fromAuthentication.State;
    signUp: fromSignUp.State;
    userView: fromUserView.State;
    sendMessage: fromSenderMessage.State;
    messageModal: fromMessageModal.State;
    phoneModal: fromPhoneModal.State;
    activityChooserModal: fromActivityChooserModal.State;
    searchActivityChooser: fromSearchActivityChooser.State;
    activityChooserManager: fromActivityChooserManager.State;
    loginModal: fromLoginModal.State;
    search: fromSearch.State;
    category: fromCategory.State,
    review: fromReview.State,
    passwordRecover: fromPasswordRecover.State
    passwordReset: fromPasswordReset.State,
    requestsPromotion: fromRequestsPromotion.State,
    workersPromotion: fromWorkersPromotion.State,
    reportUser: fromReportUser.State,
    reportReview: fromReportReview.State,
    activateAccount: fromActivateAccount.State,
    emailVerification: fromEmailVerification.State
    locale: fromLocale.State,
    notices: fromNotice.State,
    shareModal: fromShare.State
}

export const reducers: ActionReducerMap<State> = {
    authentication: fromAuthentication.reducer,
    signUp: fromSignUp.reducer,
    userView: fromUserView.reducer,
    sendMessage: fromSenderMessage.reducer,
    messageModal: fromMessageModal.reducer,
    phoneModal: fromPhoneModal.reducer,
    search: fromSearch.reducer,
    activityChooserModal: fromActivityChooserModal.reducer,
    activityChooserManager: fromActivityChooserManager.reducer,
    searchActivityChooser: fromSearchActivityChooser.reducer,
    loginModal: fromLoginModal.reducer,
    category: fromCategory.reducer,
    review: fromReview.reducer,
    passwordRecover: fromPasswordRecover.reducer,
    passwordReset: fromPasswordReset.reducer,
    requestsPromotion: fromRequestsPromotion.reducer,
    workersPromotion: fromWorkersPromotion.reducer,
    reportUser: fromReportUser.reducer,
    reportReview: fromReportReview.reducer,
    activateAccount: fromActivateAccount.reducer,
    emailVerification: fromEmailVerification.reducer,
    locale: fromLocale.reducer,
    notices: fromNotice.reducer,
    shareModal: fromShare.reducer
};

export const getLocaleState = (state: State) => state.locale;
export const getLocale = createSelector(getLocaleState, fromLocale.getLocale);

// Authentication
export const getAuthenticationState = (state: State) => state.authentication;
export const getCurrentUser = createSelector(getAuthenticationState, fromAuthentication.getCurrentUser);
export const getCurrentUserProfile = createSelector(getAuthenticationState, fromAuthentication.getCurrentUserProfile);
export const isAuthLoading = createSelector(getAuthenticationState, fromAuthentication.isLoading);
export const isAuthenticated = createSelector(getAuthenticationState, fromAuthentication.isAuthenticated);
export const getAuthErrors = createSelector(getAuthenticationState, fromAuthentication.getErrors);
export const getUnreadConversationsCount = createSelector(getAuthenticationState, fromAuthentication.getUnreadConversationsCount);

/**
 * Account
 */

// Account activation
export const getActivateAccountState = (state: State): fromActivateAccount.State => state.activateAccount;
export const isAccountActivationLoading = createSelector(getActivateAccountState, fromActivateAccount.isLoading);
export const isAccountActivated = createSelector(getActivateAccountState, fromActivateAccount.isActivated);
export const getAccountActivationNotification = createSelector(getActivateAccountState, fromActivateAccount.getNotification);

// Account activation
export const getEmailVerificationState = (state: State): fromEmailVerification.State => state.emailVerification;
export const isEmailVerificationLoading = createSelector(getEmailVerificationState, fromEmailVerification.isLoading);
export const isEmailVerified = createSelector(getEmailVerificationState, fromEmailVerification.isVerified);
export const getEmailVerificationNotification = createSelector(getEmailVerificationState, fromEmailVerification.getNotification);

/**
 * Password reset
 */
export const getPasswordResetState = (state: State): fromPasswordReset.State => state.passwordReset;
export const isPasswordResetLoading = createSelector(getPasswordResetState, fromPasswordReset.isLoading);
export const getPasswordResetNotification = createSelector(getPasswordResetState, fromPasswordReset.getNotification);
export const isPasswordReseted = createSelector(getPasswordResetState, fromPasswordReset.isReseted);

// Password reset token validation
export const isPasswordResetTokenValidationLoading = createSelector(getPasswordResetState, fromPasswordReset.isTokenValidationLoading);
export const getResetPasswordTokenValidationErrors = createSelector(getPasswordResetState, fromPasswordReset.getTokenValidationErrors);
export const isPasswordResetTokenValid = createSelector(getPasswordResetState, fromPasswordReset.isTokenValid);

/**
 * Password recover
 */
export const getPasswordRecoverState = (state: State): fromPasswordRecover.State => state.passwordRecover;
export const isPasswordRecoverLoading = createSelector(getPasswordRecoverState, fromPasswordRecover.isLoading);
export const isPasswordRecoverSuccess = createSelector(getPasswordRecoverState, fromPasswordRecover.isSuccess);
export const getPasswordRecoverNotification = createSelector(getPasswordRecoverState, fromPasswordRecover.getNotification);

export const getNoticesState = (state: State): fromNotice.State => state.notices;
export const getNotices = createSelector(getNoticesState, fromNotice.getNotices);

/**
 * Sign Up
 */
export const getSignUpState = (state: State): fromSignUp.State => state.signUp;

// Registration
export const isSignUpRegistrationLoading = createSelector(getSignUpState, fromSignUp.isRegistrationLoading);
export const isSignUpRegistered = createSelector(getSignUpState, fromSignUp.isRegistered);
export const getSignUpRegistrationErrors = createSelector(getSignUpState, fromSignUp.getRegistrationErrors);
export const getSignUpRegisteredEmail = createSelector(getSignUpState, fromSignUp.getRegisteredEmail);

// email activation
export const isSignUpEmailActivationResentLoading = createSelector(getSignUpState, fromSignUp.isEmailActivationResentLoading);
export const getSignUpEmailActivationResentSuccessNotification = createSelector(getSignUpState, fromSignUp.getEmailActivationResentSuccessNotification);
export const getSignUpEmailActivationResentErrors = createSelector(getSignUpState, fromSignUp.getEmailActivationResentErrors);

/**
 * User view.
 */
export const getUserViewState = (state: State): fromUserView.State => state.userView;

// Profile
export const getUserViewProfile = createSelector(getUserViewState, fromUserView.getUserViewProfile);
export const isUserViewProfileLoading = createSelector(getUserViewState, fromUserView.isUserViewProfileLoading);
export const getUserViewProfileErrors = createSelector(getUserViewState, fromUserView.getUserViewProfileErrors);

// Phone
export const getUserProfilePhone = createSelector(getUserViewState, fromUserView.getPhone);
export const isPhoneLoading = createSelector(getUserViewState, fromUserView.isPhoneLoading);
export const getPhoneErrors = createSelector(getUserViewState, fromUserView.getPhoneErrors);


/**
 * Send Message
 */
export const getSendMessageState = (state: State): fromSenderMessage.State => state.sendMessage;
export const isSendMessageLoading = createSelector(getSendMessageState, fromSenderMessage.isSendMessageLoading);
export const getSendMessageErrors = createSelector(getSendMessageState, fromSenderMessage.getSendMessageErrors);
export const getSendMessageSender = createSelector(getSendMessageState, fromSenderMessage.getSender);
export const getSendMessageReceiver = createSelector(getSendMessageState, fromSenderMessage.getReceiver);
export const isMessageSent = createSelector(getSendMessageState, fromSenderMessage.isSent);

/**
 * Message Modal
 */
export const getMessageModalState = (state: State): fromMessageModal.State => state.messageModal;
export const getMessageModalEvent = createSelector(getMessageModalState, fromMessageModal.getEvent);

/**
 * Phone modal
 */
export const getPhoneModalState = (state: State): fromPhoneModal.State => state.phoneModal;
export const getPhoneModalEvent = createSelector(getPhoneModalState, fromPhoneModal.getEvent);

export const getShareModalState = (state: State): fromShare.State => state.shareModal;
export const getElementToShare = createSelector(getShareModalState, fromShare.getElement);

/**
 * Activity chooser Modal
 */
export const getActivityChooserModalState = (state: State): fromActivityChooserModal.State => state.activityChooserModal;
export const getActivityChooserModalEvent = createSelector(getActivityChooserModalState, fromActivityChooserModal.getEvent);

/**
 * Search activity chooser
 */
export const getSearchActivityChooserState = (state: State): fromSearchActivityChooser.State => state.searchActivityChooser;
export const getSearchActivityChooserActivities = createSelector(getSearchActivityChooserState, fromSearchActivityChooser.getActivities);
export const getSearchActivityChooserSelectedActivities = createSelector(getSearchActivityChooserState, fromSearchActivityChooser.getSelectedActivities);
export const getSearchActivityChooserSelectedCategories = createSelector(getSearchActivityChooserState, fromSearchActivityChooser.getSelectedCategories);

/**
 * Search activity chooser
 */
export const getActivityChooserManagerState = (state: State): fromActivityChooserManager.State => state.activityChooserManager;
export const getActivityChooserManagerCategories = createSelector(getActivityChooserManagerState, fromActivityChooserManager.getCategories);
export const getActivityChooserManagerActivities = createSelector(getActivityChooserManagerState, fromActivityChooserManager.getActivities);
export const getActivityChooserManagerSelectedActivities = createSelector(getActivityChooserManagerState, fromActivityChooserManager.getSelectedActivities);
export const getActivityChooserManagerSelectedCategories = createSelector(getActivityChooserManagerState, fromActivityChooserManager.getSelectedCategories);

/**
 * Search
 */
export const getSearchState = (state: State): fromSearch.State => state.search;
export const getSearchResultPage = createSelector(getSearchState, fromSearch.getResultPage);
export const getSearchTotalElements = createSelector(getSearchState, fromSearch.getTotalElements);
export const getSearchErrors = createSelector(getSearchState, fromSearch.getErrors);
export const isSearchLoading = createSelector(getSearchState, fromSearch.isLoading);
export const getUsersSearchCriteria = createSelector(getSearchState, fromSearch.getSearchCriteria);

/**
 * Category
 */
export const getCategoryState = (state: State): fromCategory.State => state.category;
export const getCategories = createSelector(getCategoryState, fromCategory.getCategories);
export const getCategoriesErrors = createSelector(getCategoryState, fromCategory.getErrors);
export const isCategoriesLoading = createSelector(getCategoryState, fromCategory.isLoading);

/**
 * Review
 */
export const getReviewState = (state: State): fromReview.State => state.review;
export const getReviews = createSelector(getReviewState, fromReview.getReviews);
export const isReviewAdded = createSelector(getReviewState, fromReview.isReviewAdded);
export const isReviewsLoading = createSelector(getReviewState, fromReview.isLoading);
export const getReviewsErrors = createSelector(getReviewState, fromReview.getErrors);
export const getReviewsUser = createSelector(getReviewState, fromReview.getUser);

/**
 * Login MODAL
 */
export const getLoginModalState = (state: State): fromLoginModal.State => state.loginModal;
export const getLoginModalEvent = createSelector(getLoginModalState, fromLoginModal.getEvent);

/**
 * PROMOTION
 */
export const getRequestsPromotionState = (state: State): fromRequestsPromotion.State => state.requestsPromotion;
export const isRequestsPromotionLoading = createSelector(getRequestsPromotionState, fromRequestsPromotion.isLoading);
export const getRequestsPromotionErrors = createSelector(getRequestsPromotionState, fromRequestsPromotion.getErrors);
export const getRequestsPromotionResult = createSelector(getRequestsPromotionState, fromRequestsPromotion.getResult);
export const getRequestsPromotionLoadingDate = createSelector(getRequestsPromotionState, fromRequestsPromotion.getLoadingDate);

/**
 * PROMOTION
 */
export const getWorkersPromotionState = (state: State): fromWorkersPromotion.State => state.workersPromotion;
export const isWorkersPromotionLoading = createSelector(getWorkersPromotionState, fromWorkersPromotion.isLoading);
export const getWorkersPromotionErrors = createSelector(getWorkersPromotionState, fromWorkersPromotion.getErrors);
export const getWorkersPromotionResult = createSelector(getWorkersPromotionState, fromWorkersPromotion.getResult);
export const getWorkersPromotionLoadingDate = createSelector(getWorkersPromotionState, fromWorkersPromotion.getLoadingDate);

/**
 * Report
 */
export const getReportUserState = (state: State): fromReportUser.State => state.reportUser;
export const isReportUserLoading = createSelector(getReportUserState, fromReportUser.isLoading);
export const isUserReported = createSelector(getReportUserState, fromReportUser.isReported);
export const getReportUserErrors = createSelector(getReportUserState, fromReportUser.getErrors);
export const getUserToReport = createSelector(getReportUserState, fromReportUser.getUser);
export const getReportUserModalEvent = createSelector(getReportUserState, fromReportUser.getModalEvent);

export const getReportReviewState = (state: State): fromReportReview.State => state.reportReview;
export const isReportReviewLoading = createSelector(getReportReviewState, fromReportReview.isLoading);
export const isReviewReported = createSelector(getReportReviewState, fromReportReview.isReported);
export const getReportReviewErrors = createSelector(getReportReviewState, fromReportReview.getErrors);
export const getReviewToReport = createSelector(getReportReviewState, fromReportReview.getReview);
export const getReportReviewModalEvent = createSelector(getReportReviewState, fromReportReview.getModalEvent);
