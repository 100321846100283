/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promotion-press.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./promotion-press.component";
var styles_PromotionPressComponent = [i0.styles];
var RenderType_PromotionPressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromotionPressComponent, data: {} });
export { RenderType_PromotionPressComponent as RenderType_PromotionPressComponent };
function View_PromotionPressComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["target", "_blank"]], [[1, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["height", "50"], ["width", "150"]], [[8, "src", 4], [8, "title", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.url; _ck(_v, 2, 0, currVal_0); var currVal_1 = ("/assets/images/press/" + _v.context.$implicit.icon); var currVal_2 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
export function View_PromotionPressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromotionPressComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.articles; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PromotionPressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promotion-press", [], null, null, null, View_PromotionPressComponent_0, RenderType_PromotionPressComponent)), i1.ɵdid(1, 49152, null, 0, i3.PromotionPressComponent, [], null, null)], null, null); }
var PromotionPressComponentNgFactory = i1.ɵccf("app-promotion-press", i3.PromotionPressComponent, View_PromotionPressComponent_Host_0, {}, {}, []);
export { PromotionPressComponentNgFactory as PromotionPressComponentNgFactory };
