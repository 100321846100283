export var PasswordRecoverActionTypes;
(function (PasswordRecoverActionTypes) {
    PasswordRecoverActionTypes["RecoverPassword"] = "[Password] RECOVER_PASSWORD";
    PasswordRecoverActionTypes["RecoverPasswordSuccess"] = "[Password] RECOVER_PASSWORD_SUCCESS";
    PasswordRecoverActionTypes["RecoverPasswordFail"] = "[Password] RECOVER_PASSWORD_FAIL";
    PasswordRecoverActionTypes["Reset"] = "[Password] RESET";
})(PasswordRecoverActionTypes || (PasswordRecoverActionTypes = {}));
var RecoverPasswordAction = /** @class */ (function () {
    function RecoverPasswordAction(payload) {
        this.payload = payload;
        this.type = PasswordRecoverActionTypes.RecoverPassword;
    }
    return RecoverPasswordAction;
}());
export { RecoverPasswordAction };
var RecoverPasswordSuccessAction = /** @class */ (function () {
    function RecoverPasswordSuccessAction(payload) {
        this.payload = payload;
        this.type = PasswordRecoverActionTypes.RecoverPasswordSuccess;
    }
    return RecoverPasswordSuccessAction;
}());
export { RecoverPasswordSuccessAction };
var RecoverPasswordFailAction = /** @class */ (function () {
    function RecoverPasswordFailAction(payload) {
        this.payload = payload;
        this.type = PasswordRecoverActionTypes.RecoverPasswordFail;
    }
    return RecoverPasswordFailAction;
}());
export { RecoverPasswordFailAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = PasswordRecoverActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
