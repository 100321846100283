import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import {routeActions} from '../../store/actions';

import * as fromRoot from '../../../store';

@Injectable({
    providedIn: 'root'
})
export class NotAuthedGuard implements CanActivate {

    constructor(private store: Store<fromRoot.State>) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.pipe(select(fromRoot.getCurrentUser),
            map(currentUser => {
                if (currentUser) {
                    this.store.dispatch(new routeActions.ToUserProfileAction());
                    return false;
                }

                return true;
            })
        );
    }

}

