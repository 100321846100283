export class RegistrationConfirmationParams {
    email: string;
    postSignUp: boolean;

    public static build(email: string, postSignUp: boolean): RegistrationConfirmationParams {
        let rcp = new RegistrationConfirmationParams;
        rcp.email = email;
        rcp.postSignUp = postSignUp;

        return rcp;
    }
}
