import { MapsAPILoader } from '@agm/core';
import { from as observableFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@agm/core/services/maps-api-loader/maps-api-loader";
var _includes = require('lodash/includes');
import { AddressDto } from '../../core/models';
var AddressWebService = /** @class */ (function () {
    function AddressWebService(mapsAPILoader) {
        this.mapsAPILoader = mapsAPILoader;
    }
    AddressWebService.prototype.init = function () {
        return observableFrom(this.mapsAPILoader.load());
    };
    AddressWebService.prototype.mapAddress = function (place) {
        return this.mapToAddressDto(place);
    };
    AddressWebService.prototype.autoComplete = function (text) {
        return null;
    };
    AddressWebService.prototype.calculateLatLngBounds = function (locations) {
        return this.init().pipe(map(function () {
            var latLngBounds = new google.maps.LatLngBounds();
            locations.forEach(function (loc) { return latLngBounds.extend(new google.maps.LatLng(loc.latitude, loc.longitude)); });
            return latLngBounds;
        }));
    };
    AddressWebService.prototype.mapToAddressDto = function (place) {
        if (!place) {
            return null;
        }
        var address = new AddressDto();
        address.placeId = place.place_id;
        address.formatted = place.formatted_address;
        address.location = {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
        };
        var addressComponents = place.address_components;
        address.country = this.getAddressComponentLongName(addressComponents, 'country');
        address.streetNumber = this.getAddressComponentLongName(addressComponents, 'street_number');
        address.route = this.getAddressComponentLongName(addressComponents, 'route');
        address.locality = this.getAddressComponentLongName(addressComponents, 'locality');
        address.postalCode = this.getAddressComponentLongName(addressComponents, 'postal_code');
        return address;
    };
    AddressWebService.prototype.getAddressComponentLongName = function (addressComponents, type) {
        var component = this.getAddressComponent(addressComponents, type);
        return component && component['long_name'];
    };
    AddressWebService.prototype.getAddressComponent = function (addressComponents, type) {
        return addressComponents.find(function (element) {
            return _includes(element.types, type);
        });
    };
    AddressWebService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AddressWebService_Factory() { return new AddressWebService(i0.ɵɵinject(i1.MapsAPILoader)); }, token: AddressWebService, providedIn: "root" });
    return AddressWebService;
}());
export { AddressWebService };
