/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./adsense.component";
import * as i3 from "../config/adsense.config";
var styles_AdsenseComponent = [];
var RenderType_AdsenseComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdsenseComponent, data: {} });
export { RenderType_AdsenseComponent as RenderType_AdsenseComponent };
export function View_AdsenseComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { ins: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["ins", 1]], null, 3, "ins", [["class", "adsbygoogle"]], [[1, "data-ad-client", 0], [1, "data-ad-slot", 0], [1, "data-ad-format", 0], [1, "data-ad-region", 0], [1, "data-layout", 0], [1, "data-adtest", 0], [1, "data-full-width-responsive", 0], [1, "data-layout-key", 0]], null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgStyleImpl, i1.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgStyle, [i1.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(4, { "display": 0, "width.px": 1, "height.px": 2 })], function (_ck, _v) { var _co = _v.component; var currVal_8 = _ck(_v, 4, 0, _co.display, _co.width, _co.height); _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.adClient; var currVal_1 = _co.adSlot; var currVal_2 = _co.adFormat; var currVal_3 = _co.adRegion; var currVal_4 = _co.layout; var currVal_5 = _co.adtest; var currVal_6 = _co.fullWidthResponsive; var currVal_7 = _co.layoutKey; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_AdsenseComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-adsense", [], null, null, null, View_AdsenseComponent_0, RenderType_AdsenseComponent)), i0.ɵdid(1, 4440064, null, 0, i2.AdsenseComponent, [i3.ADSENSE_TOKEN], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdsenseComponentNgFactory = i0.ɵccf("ng-adsense", i2.AdsenseComponent, View_AdsenseComponent_Host_0, { adClient: "adClient", adSlot: "adSlot", adFormat: "adFormat", adRegion: "adRegion", display: "display", width: "width", height: "height", layout: "layout", layoutKey: "layoutKey", pageLevelAds: "pageLevelAds", timeOutRetry: "timeOutRetry", fullWidthResponsive: "fullWidthResponsive", adtest: "adtest" }, {}, []);
export { AdsenseComponentNgFactory as AdsenseComponentNgFactory };
