import {signUpActions} from '../actions';
import {ErrorsDto, Notification} from '../../models';

export interface State {
    // Registration
    registrationLoading: boolean,
    registered: boolean,
    registeredEmail: string,
    registrationErrors: ErrorsDto,
    // Resent activation email
    emailActivationResentLoading: boolean,
    emailActivationSuccessNotification: Notification,
    emailActivationResentErrors: ErrorsDto,
}

export const initialState: State = {
    // Registration
    registrationLoading: false,
    registered: false,
    registeredEmail: null,
    registrationErrors: null,
    // Resent activation email
    emailActivationResentLoading: false,
    emailActivationSuccessNotification: null,
    emailActivationResentErrors: null
};

export function reducer(state = initialState, action: signUpActions.Actions): State {

    switch (action.type) {

        case signUpActions.SignUpActionTypes.SignUp: {
            return Object.assign(
                {},
                state,
                {registrationLoading: true}
            )
        }

        case signUpActions.SignUpActionTypes.SignUpSuccess: {
            return Object.assign(
                {},
                state,
                {registrationLoading: false, registered: true, registrationErrors: null}
            )
        }

        case signUpActions.SignUpActionTypes.SignUpFailure: {
            return Object.assign(
                {},
                state,
                {registrationLoading: false, registered: false, registrationErrors: action.payload}
            )
        }

        case signUpActions.SignUpActionTypes.SignUpStoreEmail: {
            return Object.assign(
                {},
                state,
                {registeredEmail: action.payload}
            )
        }

        case signUpActions.SignUpActionTypes.SignUpConfirmationResentEmail: {
            return Object.assign(
                {},
                state,
                {
                    emailActivationResentLoading: true,
                    emailActivationSuccessNotification: false,
                    emailActivationResentErrors: false
                }
            )
        }

        case signUpActions.SignUpActionTypes.SignUpConfirmationResentEmailSuccess: {
            return Object.assign(
                {},
                state,
                {
                    emailActivationResentLoading: false,
                    emailActivationSuccessNotification: action.payload,
                    emailActivationResentErrors: false
                }
            )
        }

        case signUpActions.SignUpActionTypes.SignUpConfirmationResentEmailFailure: {
            return Object.assign(
                {},
                state,
                {
                    emailActivationResentLoading: false,
                    emailActivationSuccessNotification: null,
                    emailActivationResentErrors: action.payload
                }
            )
        }

        case signUpActions.SignUpActionTypes.RESET: {
            return Object.assign(
                {},
                initialState
            )
        }

        default: {
            return state;
        }
    }
}

// Registration
export const getRegistrationErrors = (state: State): ErrorsDto => state.registrationErrors;
export const isRegistrationLoading = (state: State): boolean => state.registrationLoading;
export const isRegistered = (state: State): boolean => state.registered;
export const getRegisteredEmail = (state: State): string => state.registeredEmail;

// Email activation resent
export const isEmailActivationResentLoading = (state: State): boolean => state.emailActivationResentLoading;
export const getEmailActivationResentSuccessNotification = (state: State): Notification => state.emailActivationSuccessNotification;
export const getEmailActivationResentErrors = (state: State): ErrorsDto => state.emailActivationResentErrors;
