var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { MapsAPILoader } from '@agm/core';
import { DocumentRef } from '@agm/core/utils/browser-globals';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';
import { localeActions } from '../actions';
import { BrowserService } from '../../services';
var WebEffects = /** @class */ (function () {
    function WebEffects(actions$, _documentRef, browserService, mapsAPILoader) {
        var _this = this;
        this.actions$ = actions$;
        this._documentRef = _documentRef;
        this.browserService = browserService;
        this.mapsAPILoader = mapsAPILoader;
        this.reloadMapsConfig = this.actions$.pipe(ofType(localeActions.LocaleActionsTypes.SetLocale), map(function (action) { return action.payload; }), filter(function () { return _this.browserService.isPlatformBrowser(); }), tap(function (locale) {
            var loader = _this.mapsAPILoader;
            if (loader && loader._config) {
                // Set loader language to locale
                loader._config.language = locale.shortcut;
                // See https://github.com/SebastianM/angular-google-maps/blob/master/packages/core/services/maps-api-loader/lazy-maps-api-loader.ts
                // delete google maps object
                if (window.google && window.google.maps) {
                    window.google.maps = null;
                }
                // delete _scriptLoadingPromise
                loader._scriptLoadingPromise = null;
                // delete maps script
                var scriptOnPage = _this._documentRef.getNativeDocument().getElementById(loader._SCRIPT_ID);
                if (scriptOnPage) {
                    scriptOnPage.remove();
                }
            }
        }));
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], WebEffects.prototype, "reloadMapsConfig", void 0);
    return WebEffects;
}());
export { WebEffects };
