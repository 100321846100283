/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comment-ca-marche.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../modules/web/directives/layout-class.directive";
import * as i3 from "@ngrx/store";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../modules/promotions/components/web/promotion-how-to/promotion-how-to.component.ngfactory";
import * as i6 from "../../../modules/promotions/components/web/promotion-how-to/promotion-how-to.component";
import * as i7 from "./comment-ca-marche.component";
import * as i8 from "../../../modules/core/services/seo.service";
var styles_CommentCaMarcheComponent = [i0.styles];
var RenderType_CommentCaMarcheComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentCaMarcheComponent, data: {} });
export { RenderType_CommentCaMarcheComponent as RenderType_CommentCaMarcheComponent };
export function View_CommentCaMarcheComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container-fluid sub-header"], ["yfLayoutClass", ""]], null, null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.LayoutClassDirective, [i1.ElementRef, i1.Renderer2, i3.Store], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h1", [["class", "white-text h4 center-sm-down"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["footer.info.how-to"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "container"], ["yfLayoutClass", ""]], null, null, null, null, null)), i1.ɵdid(7, 81920, null, 0, i2.LayoutClassDirective, [i1.ElementRef, i1.Renderer2, i3.Store], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-promotion-how-to", [], null, null, null, i5.View_PromotionHowToComponent_0, i5.RenderType_PromotionHowToComponent)), i1.ɵdid(9, 49152, null, 0, i6.PromotionHowToComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = ""; _ck(_v, 4, 0, currVal_0); _ck(_v, 7, 0); }, null); }
export function View_CommentCaMarcheComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "yf-comment-ca-marche", [], null, null, null, View_CommentCaMarcheComponent_0, RenderType_CommentCaMarcheComponent)), i1.ɵdid(1, 49152, null, 0, i7.CommentCaMarcheComponent, [i8.SeoService], null, null)], null, null); }
var CommentCaMarcheComponentNgFactory = i1.ɵccf("yf-comment-ca-marche", i7.CommentCaMarcheComponent, View_CommentCaMarcheComponent_Host_0, {}, {}, []);
export { CommentCaMarcheComponentNgFactory as CommentCaMarcheComponentNgFactory };
