// angular
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {TranslateService} from '@ngx-translate/core';
// libs
import {Observable, of, throwError} from 'rxjs';
import {catchError, timeout} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ErrorsDto, Locale} from '../../core/models';
import {StorageKey} from '../../core/models/interfaces';


@Injectable()
export class BriCoolHttpInterceptor implements HttpInterceptor {

    private timeout = 30000;
    private request: any;
    private translateService: TranslateService;
    private transferState: TransferState;

    constructor(private injector: Injector) {
        this.request = injector.get(REQUEST, null);
        this.translateService = injector.get(TranslateService);
        this.transferState = injector.get(TransferState);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method === 'GET') {
            let stateKey = makeStateKey(request.url);
            const storedResponse: string = this.transferState.get(stateKey, null);

            if (storedResponse) {
                const response = new HttpResponse({body: storedResponse, status: 200});
                this.transferState.remove(stateKey);
                return of(response);
            }
        }

        let httpHeaders = request.headers
            .set('Content-Type', 'application/json')
            .set('Accept-Language', this.lang)
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
            .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

        if (!httpHeaders.has('Accept')) {
            httpHeaders = httpHeaders.set('Accept', 'application/json');
        }

        let token = null;
        let serverReq: any = this.injector.get(REQUEST, null);
        if (serverReq && serverReq.cookies) {
            token = serverReq.cookies[StorageKey.JWT];

            if (token) {
                httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`);
            }
        }

        request = request.clone({
            url: this.apiUrl + request.url,
            headers: httpHeaders
        });

        return next.handle(request).pipe(
            timeout(this.timeout),
            catchError(error => this.handleError(error))
        );
    }

    private get lang(): string {
        let currentLang = this.translateService.currentLang;

        if (!currentLang) {
            currentLang = Locale.FRENCH.shortcut;
        }

        return currentLang
    }

    private buildGlobalError(): ErrorsDto {
        let error = new ErrorsDto();
        error.code = '500';
        error.message = 'Une erreur est survenue';

        return error;
    }

    private get apiUrl() {
        let newUrl = environment.apiUrl;
        if (this.request) {
            newUrl = `${this.getRequestProtocol()}://${this.request.get('host')}/`;
        }

        return newUrl;
    }

    private getRequestProtocol() {
        let protocol = 'http';

        if (this.request && this.request.headers) {

            let host = this.request.headers['X-Forwarded-Proto'];

            if (!host) {
                host = this.request.get('host');
            }

            if (host && (host.startsWith('localhost') || host.startsWith('bc.dev'))) {
                protocol = 'http';
            }
        }

        return protocol;
    }

    handleError(error: any): Observable<any> {

        if (!environment.production) {
            console.error("Error happened :");
            console.log(JSON.stringify(error));
        }

        let errorsDto = this.buildGlobalError();
        if (!error.status) {
            return throwError(errorsDto);
        } else {
            try {
                let err = error.error;

                if (err && err.code) {
                    errorsDto = error.error;
                }
            } catch (e) {
                // ignore
            } finally {
                if (!errorsDto) {
                    errorsDto = this.buildGlobalError();
                }
            }
        }

        return throwError(errorsDto);
    }

}
