<footer class="page-footer mt-0" yfLayoutClass>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="d-block">
                    <h5 class="white-text bold" translate>footer.info.title</h5>
                    <ul>
                        <li>
                            <a class="grey-text text-lighten-3" [routerLink]="['/how-to']" translate>footer.info.how-to</a>
                        </li>
                        <!--<li><a class="grey-text text-lighten-3" href="#!">Charte de bonne conduite</a></li>-->
                    </ul>
                </div>
                <div class="d-block">
                    <h5 class="white-text" translate>footer.about.title</h5>
                    <ul>
                        <li>
                            <a class="grey-text text-lighten-3" [routerLink]="['/about']" translate>footer.about.who</a>
                        </li>
                        <!--<li><a class="grey-text text-lighten-3" href="#!">Nous contacter</a></li>-->
                    </ul>
                </div>
            </div>

            <div class="col-md-3">
                <h5 class="white-text" translate>footer.legal.title</h5>
                <ul>
                    <li>
                        <a class="grey-text text-lighten-3" [routerLink]="['/conditions-generales-vente']">
                            <span translate>footer.legal.cgu</span>
                        </a>
                    </li>
                    <li>
                        <a class="grey-text text-lighten-3" [routerLink]="['/privacy-policy']">
                            <span translate>footer.legal.privacy-policy</span>
                        </a>
                    </li>
                    <li>
                        <a class="grey-text text-lighten-3" [routerLink]="['/cookies-usage']" translate>
                            <span translate>footer.legal.cookies</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-md-3">
                <h5 class="white-text" translate>global.find-us</h5>
                <a href="https://twitter.com/bricoolOfficiel" target="_blank" class="d-inline-block" [title]="'global.social-media.twitter' | translate">
                    <i class="fa fa-twitter fa-2x text-white"></i>
                </a>

                <a href="https://www.facebook.com/bricoolOfficiel" target="_blank" class="d-inline-block ml-2" [title]="'global.social-media.facebook' | translate">
                    <i class="fa fa-facebook fa-2x text-white"></i>
                </a>

                <a href="https://www.youtube.com/channel/UCqjTR8lpJCeClFKvwZv3J9g" target="_blank" class="d-inline-block ml-2" [title]="'global.social-media.youtube' | translate">
                    <i class="fa fa-youtube-play fa-2x text-white"></i>
                </a>
            </div>
            <div class="col-md-3">
                <h5 class="white-text" translate>footer.languages.title</h5>
                <ul>
                    <li>
                        <yf-locale-selector></yf-locale-selector>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12 center">
                <ng-adsense></ng-adsense>
            </div>
        </div>
    </div>
    <div class="footer-copyright">
        <div class="container center">
            © 2019 Copyright
            <br>
            <span style="font-size: 0.8rem;">version: {{version}}</span>
        </div>
    </div>
</footer>
