import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import * as fromRoot from '../../../store';
import {authenticationActions, emailVerificationActions, routeActions} from '../actions';
import {StorageKey} from '../../models/interfaces';
import {EmailVerificationService, NotificationService, StorageService} from '../../services';
import {ErrorUtils} from '../../utils';

@Injectable()
export class EmailVerificationEffects {

    @Effect()
    verifyEmail = this.actions$.pipe(
        ofType<emailVerificationActions.VerifyEmailAction>(emailVerificationActions.EmailVerificationActionTypes.VerifyEmail),
        map(action => action.payload),
        mergeMap(emailVerification =>
            this.emailVerificationService.verifyEmail(emailVerification).pipe(
                map(() => new emailVerificationActions.VerifyEmailSuccessAction(this.notificationService.buildEmailVerifiedSuccessNotification())),
                catchError(errors => of(new emailVerificationActions.VerifyEmailFailAction(ErrorUtils.toNotification(errors))))
            )
        )
    );

    @Effect()
    activateAccountSuccess = this.actions$.pipe(
        ofType<emailVerificationActions.VerifyEmailSuccessAction>(emailVerificationActions.EmailVerificationActionTypes.VerifyEmailSuccess),
        withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUser))),
        map(([action, user]) => {

            if (isPlatformBrowser(this.platformId)) {
                let storageUser = this.storageService.getItem(StorageKey.USER);

                // TODO to ameliorate
                let enabledUser = Object.assign(storageUser, {enabled: true});
                this.storageService.setItem(StorageKey.USER, enabledUser);
            }

            if (user) {

                let redirectAction: Action = new routeActions.ToCompleteUserProfileAction();
                if (user.filled) {
                    redirectAction = new routeActions.ToUserProfileAction();
                }

                this.store.dispatch(new authenticationActions.TestUserAction());

                return redirectAction;

            } else {
                return new routeActions.ToSignInAction();
            }
        })
    );

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private emailVerificationService: EmailVerificationService,
                private notificationService: NotificationService,
                private actions$: Actions,
                private store: Store<fromRoot.State>,
                private storageService: StorageService) {
    }
}
