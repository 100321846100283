import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicUserDto, Notification, UserReportDto } from '../../../core/models';
var ReportUserComponent = /** @class */ (function () {
    function ReportUserComponent(cdRef) {
        this.cdRef = cdRef;
        this.cancel = new EventEmitter();
        this.report = new EventEmitter();
        this._userReport = new UserReportDto();
    }
    ReportUserComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.initListener$) {
            this.initListener$.subscribe(function () {
                _this._userReport = new UserReportDto();
                _this.cdRef.detectChanges();
                if (_this.messageInput) {
                    _this.messageInput.nativeElement.focus();
                }
            });
        }
    };
    ReportUserComponent.prototype.reportUser = function (form) {
        if (form.invalid) {
            return;
        }
        this.doReport();
    };
    ReportUserComponent.prototype.doReport = function () {
        this._userReport.userUid = this.user.uid;
        this.report.emit(this._userReport);
    };
    return ReportUserComponent;
}());
export { ReportUserComponent };
