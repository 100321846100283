import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';

import {ErrorsDto} from '../../models';
import {NotificationService, MessageService} from '../../services';
import * as fromRoot from '../../../store';
import {sendMessageActions} from '../actions';

@Injectable()
export class SendMessageEffects {

    @Effect()
    sendMessage = this.actions$.pipe(
        ofType(sendMessageActions.SendMessageActionTypes.SendMessage),
        map((action: sendMessageActions.SendMessageAction) => action.payload),
        withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUser))),
        mergeMap(([sendMessageDto, currentUser]) => {
                let currentUid = currentUser.uid;
                sendMessageDto.senderUid = currentUid;

                return this.messageService.sendMessage(currentUid, sendMessageDto).pipe(
                    map(() => new sendMessageActions.SendMessageSuccessAction()),
                    catchError((errors: ErrorsDto) => of(new sendMessageActions.SendMessageFailAction(errors)))
                )
            }
        )
    );

    @Effect({dispatch: false})
    sendMessageSuccess = this.actions$.pipe(
        ofType(sendMessageActions.SendMessageActionTypes.SendMessageSuccess),
        tap(() => {
            this.notificationService.displaySuccessKey('send-message.sent');
        }));

    constructor(private actions$: Actions,
                private messageService: MessageService,
                private store: Store<fromRoot.State>,
                private notificationService: NotificationService) {
    }
}
