import {Location} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {
    PasswordForgottenComponent,
    PasswordResetComponent,
    PrivacyPolicyComponent,
    SignInComponent,
} from './components';
import {AccountActivationComponent} from './components/account-activation/account-activation.component';
import {EmailVerificationComponent} from './components/email-verification/email-verification.component';
import {HomeComponent} from './components/home/home.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {AboutComponent} from './components/pages/about/about.component';
import {CgvComponent} from './components/pages/cgv/cgv.component';
import {CommentCaMarcheComponent} from './components/pages/comment-ca-marche/comment-ca-marche.component';
import {CookieUsageComponent} from './components/pages/cookie-usage/cookie-usage.component';
import {PostSocialSignInComponent} from './components/post-social-sign-in/post-social-sign-in.component';
import {SignUpConfirmComponent} from './components/sign-up/confirm/signup-confirm.component';
import {SignUpComponent} from './components/sign-up/signup.component';
import {AdminGuard, AuthGuard} from './modules/core/services/guards';
import {NotAuthedGuard} from './modules/core/services/guards/not-authed.guard';
import {LocalizeRouterModule} from './modules/localize-router/localize-router.module';
import {LocalizeParser} from './modules/localize-router/localize-router.parser';
import {ProfileViewComponent, UsersSearchComponent} from './modules/users/views';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    },
    {
        path: 'workers',
        children: [
            {
                path: '',
                component: UsersSearchComponent
            },
            {
                path: ':uid/profile',
                component: ProfileViewComponent
            }
        ]
    },
    {
        path: 'requests',
        loadChildren: './modules/requests/requests.module#RequestsModule',
    },
    {
        path: 'user',
        loadChildren: './modules/account/account.module#AccountModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        children: [
            {
                path: '',
                component: SignInComponent,
                pathMatch: 'full',
            },
            {
                path: 'post-social',
                component: PostSocialSignInComponent,
                pathMatch: 'full',
            }
        ]
    },
    {
        path: 'register',
        canActivate: [NotAuthedGuard],
        children: [
            {
                path: '',
                component: SignUpComponent,
                pathMatch: 'full',
            },
            {
                path: 'confirm',
                component: SignUpConfirmComponent
            }
        ]
    },
    {
        path: 'password/forgotten',
        component: PasswordForgottenComponent
    },
    {
        path: 'account/:email/password/:token',
        component: PasswordResetComponent
    },
    {
        path: 'account/:uid/activate/:token',
        component: AccountActivationComponent
    },
    {
        path: 'verifications/account/:uid/email/:token',
        component: EmailVerificationComponent
    },
    {
        path: '404',
        component: NotFoundComponent,
        data: {skipRouteLocalization: true}
    },
    {
        path: 'conditions-generales-vente',
        component: CgvComponent
    },
    {
        path: 'cookies-usage',
        component: CookieUsageComponent
    },
    {
        path: 'about',
        component: AboutComponent
    },
    {
        path: 'how-to',
        component: CommentCaMarcheComponent
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    },
    {
        path: 'admin',
        loadChildren: './modules/admin/admin.module#AdminModule',
        canActivate: [AuthGuard, AdminGuard],
    },
    {
        path: '**',
        redirectTo: '404'
    }
];

@NgModule({
    imports: [
        // RouterModule.forRoot([ // ROUTES HERE ], { initialNavigation: 'enabled' })
        RouterModule.forRoot(routes),
        LocalizeRouterModule.forRoot(routes, {
            parser: {
                provide: LocalizeParser,
                useFactory: localizeParser,
                deps: [TranslateService, Location]
            }
        })
    ],
    exports: [
        RouterModule,
        LocalizeRouterModule
    ]
})
export class AppRoutingModule {
}

export function localizeParser(translateService: TranslateService, location: Location) {
    return new LocalizeParser(translateService, location);
}
