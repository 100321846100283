// module
import {Injectable} from '@angular/core';
import {IStorage, StorageKey} from '../models';

@Injectable({
    providedIn: 'root'
})
export class ServerStorageService implements IStorage {

    constructor() {

    }

    setItem(key: StorageKey, value: any): void {

    }

    getItem(key: StorageKey): any {

    }

    removeItem(key: StorageKey): void {

    }
}
