<div class="container-fluid sub-header" yfLayoutClass>
    <div class="container">
        <h1 class="white-text h4" translate>{{titleKey}}</h1>
    </div>
</div>

<div class="container mt-4" yfLayoutClass>

    <div class="card">

        <div class="card-header center">
            <span class="text-white" translate>sign-up.confirm.email-confirm</span>
        </div>

        <div class="card-block center">

            <app-spinner *ngIf="loading$ | async"></app-spinner>

            <div class="row mb-0" *ngIf="postSignUp; else confirmInput">

                <div class="col-12 center-md-up">
                    <app-notification [notification]="notification$ | async"></app-notification>
                </div>

                <div class="col-12 center-md-up activation-message">
                    <h1 translate>sign-up.confirm.body</h1>
                    <span class="email">{{ email }}</span>
                </div>

                <div class="col-12 center-md-up mt-3">
                    <span style="font-size: 1.2rem; font-weight: 300" translate>sign-up.confirm.verify-spam</span>
                </div>
            </div>

            <ng-template #confirmInput>
                <div class="row justify-content-center">
                    <form name="form" class="col-md-8" (ngSubmit)="sendEmailConfirmation()" #confirmForm="ngForm">

                        <app-notification [notification]="notification$ | async"></app-notification>

                        <div class="input-field">
                            <input type="text" class="form-control" id="email" name="email"
                                   [(ngModel)]="email" required
                                   validate/>
                            <label for="email" translate>global.email</label>
                        </div>

                        <button [disabled]="confirmForm.invalid || (loading$ | async)" class="btn btn-primary col-12"
                                translate>
                            global.send
                        </button>
                    </form>
                </div>
            </ng-template>

        </div>

        <div class="card-footer" *ngIf="postSignUp">
            <div class="float-md-left" style="line-height: 36px" translate>sign-up.confirm.email-not-received</div>
            <button type="button" [disabled]="loading$ | async" class="btn-flat text-underline"
                    (click)="sendEmailConfirmation()" translate>
                global.resend <span class="hidden-sm-down" >{{'sign-up.confirm.confirmation' | translate}}</span>
            </button>
        </div>
    </div>
</div>