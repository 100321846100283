<div class="row review">
    <div class="col-md-2 center">
        <img [appUserImg]="review.user" alt="image"
             class="small-img img-thumbnail img-fluid img-responsive rounded-circle img-small center-block">
        <span>{{review.user.firstName}} {{review.user.lastName}}</span>
    </div>
    <div class="col-md-9 mt-2 mt-md-0">
        <div class="row">
            <div class="col-12 center-sm-down" [title]="review.rating + '/4'">
                <i [ngClass]="{'fa': true, 'fa-star': i <= review.rating, 'fa-star-o': i> review.rating}"
                   class="fa fa-star" aria-hidden="true" *ngFor="let i of [0,1,2,3,4]"></i>
            </div>
            <div class="col-12 center-sm-down">
                <span class="font-italic">{{review.text}}</span>
            </div>
            <div class="col-12 center-sm-down info-text mt-2 mt-md-0">
                <i class="fa fa-clock-o" aria-hidden="true"></i> {{review.date | yfDate}}
            </div>
        </div>
    </div>

    <div class="col-md-1 right-align hidden-sm-down">
        <a materialize="dropdown" class='dropdown-button right' data-constrainWidth="false"
           data-belowOrigin="true" [attr.data-activates]="'dropdown-'+review?.id">
            <i class="material-icons" title="actions">more_vert</i>
        </a>
    </div>

    <a materialize="dropdown" id="dd-review-sm" class='dropdown-button right hidden-md-up' data-constrainWidth="false"
       data-belowOrigin="true" [attr.data-activates]="'dropdown-sm-'+review?.id">
        <i class="material-icons action" title="actions">more_vert</i>
    </a>

    <ul [id]="'dropdown-' + review?.id" class='dropdown-content'>
        <!--<li *ngIf="isOwner">-->
            <!--<a (click)="updateReview.emit(review)">Modifier</a>-->
        <!--</li>-->
        <!--<li *ngIf="isOwner">-->
            <!--<a (click)="deleteReview.emit(review)">Supprimer</a>-->
        <!--</li>-->
        <!--<div class="divider"></div>-->
        <li>
            <a [appReportReview]="review" translate>global.report</a>
        </li>
    </ul>

    <ul [id]="'dropdown-sm-' + review?.id" class='dropdown-content'>
        <!--<li *ngIf="isOwner">-->
            <!--<a (click)="updateReview.emit(review)">Modifier</a>-->
        <!--</li>-->
        <!--<li *ngIf="isOwner">-->
            <!--<a (click)="deleteReview.emit(review)">Supprimer</a>-->
        <!--</li>-->
        <!--<div class="divider"></div>-->
        <li>
            <a [appReportReview]="review" translate>global.report</a>
        </li>
    </ul>
</div>