<app-modal modalId="login-modal"
           [modalEventListener]="modalEventListener$"
           modalClass="bottom-sheet-modal-height bottom-sheet"
           (close)="closeModal()"
           (ready)="init()">

    <h1 class="col my-0" modal-header>
        <span translate>global.authentication-required</span>
    </h1>

    <i class="material-icons action close-phone-modal" (click)="closeModal()">close</i>
    <app-login [notification]="notification$ | async"
               [loading$]="loading$"
               [initListener$]="initListener$"
               (login)="login($event)"
               modal-content>
    </app-login>

</app-modal>