export var UsersSearchActionTypes;
(function (UsersSearchActionTypes) {
    UsersSearchActionTypes["Search"] = "[SEARCH] SEARCH";
    UsersSearchActionTypes["SearchSuccess"] = "[SEARCH] SEARCH_SUCCESS";
    UsersSearchActionTypes["SearchFailure"] = "[SEARCH] SEARCH_FAILURE";
    UsersSearchActionTypes["Reset"] = "[SEARCH RESULT] RESET";
    UsersSearchActionTypes["SetSearchCriteriaAddress"] = "[SEARCH USERS] SET_SEARCH_CRITERIA_ADDRESS";
    UsersSearchActionTypes["SetSearchCriteriaService"] = "[SEARCH USERS] SET_SEARCH_CRITERIA_SERVICE";
    UsersSearchActionTypes["SearchAroundIpOrLast"] = "[SEARCH] SEARCH_AROUND_IP_OR_LAST";
    UsersSearchActionTypes["SearchAroundIpOrLastSuccess"] = "[SEARCH] SEARCH_AROUND_IP_OR_LAST_SUCCESS";
    UsersSearchActionTypes["SearchAroundIpOrLastFail"] = "[SEARCH] SEARCH_AROUND_IP_OR_LAST_FAIL";
})(UsersSearchActionTypes || (UsersSearchActionTypes = {}));
var SetSearchCriteriaAddressAction = /** @class */ (function () {
    function SetSearchCriteriaAddressAction(payload) {
        this.payload = payload;
        this.type = UsersSearchActionTypes.SetSearchCriteriaAddress;
    }
    return SetSearchCriteriaAddressAction;
}());
export { SetSearchCriteriaAddressAction };
var SetSearchCriteriaServiceAction = /** @class */ (function () {
    function SetSearchCriteriaServiceAction(payload) {
        this.payload = payload;
        this.type = UsersSearchActionTypes.SetSearchCriteriaService;
    }
    return SetSearchCriteriaServiceAction;
}());
export { SetSearchCriteriaServiceAction };
var SearchAction = /** @class */ (function () {
    function SearchAction(payload) {
        this.payload = payload;
        this.type = UsersSearchActionTypes.Search;
    }
    return SearchAction;
}());
export { SearchAction };
var SearchSuccessAction = /** @class */ (function () {
    function SearchSuccessAction(payload) {
        this.payload = payload;
        this.type = UsersSearchActionTypes.SearchSuccess;
    }
    return SearchSuccessAction;
}());
export { SearchSuccessAction };
var SearchFailureAction = /** @class */ (function () {
    function SearchFailureAction(payload) {
        this.payload = payload;
        this.type = UsersSearchActionTypes.SearchFailure;
    }
    return SearchFailureAction;
}());
export { SearchFailureAction };
// Around IP or last created.
var SearchAroundIPOrLastAction = /** @class */ (function () {
    function SearchAroundIPOrLastAction(payload) {
        this.payload = payload;
        this.type = UsersSearchActionTypes.SearchAroundIpOrLast;
    }
    return SearchAroundIPOrLastAction;
}());
export { SearchAroundIPOrLastAction };
var SearchAroundIPOrLastSuccessAction = /** @class */ (function () {
    function SearchAroundIPOrLastSuccessAction(payload) {
        this.payload = payload;
        this.type = UsersSearchActionTypes.SearchAroundIpOrLastSuccess;
    }
    return SearchAroundIPOrLastSuccessAction;
}());
export { SearchAroundIPOrLastSuccessAction };
var SearchAroundIPOrLastFailAction = /** @class */ (function () {
    function SearchAroundIPOrLastFailAction(payload) {
        this.payload = payload;
        this.type = UsersSearchActionTypes.SearchAroundIpOrLastFail;
    }
    return SearchAroundIPOrLastFailAction;
}());
export { SearchAroundIPOrLastFailAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = UsersSearchActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
