import { PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import * as fromRoot from '../../store';
var YFDatePipe = /** @class */ (function () {
    function YFDatePipe(store, translateService) {
        var _this = this;
        this.store = store;
        this.translateService = translateService;
        this.monthGetter = YFDatePipe.frMonthGetter;
        this.store.pipe(select(fromRoot.getLocale), filter(function (locale) { return locale != null; })).subscribe(function (locale) {
            _this.monthGetter = locale.rtl ? YFDatePipe.arMonthGetter : YFDatePipe.frMonthGetter;
        });
    }
    YFDatePipe.getFrMonthGetter = function () {
        var fn = function (m) { return YFDatePipe.monthNames[m]; };
        return fn;
    };
    YFDatePipe.getArMonthGetter = function () {
        var fn = function (m) { return YFDatePipe.arMonthNames[m]; };
        return fn;
    };
    YFDatePipe.prototype.transform = function (value, args) {
        if (!value) {
            return null;
        }
        var output = '';
        var today = new Date();
        var date = new Date(value);
        if (this.isSameDay(date, today)) {
            output = date.getHours() + ':' + this.twoDigitNumber(date.getMinutes());
        }
        else {
            if (this.isYesterday(date)) {
                output = this.translateService.instant('global.yesterday-at', {
                    hour: this.twoDigitNumber(date.getHours()),
                    minutes: this.twoDigitNumber(date.getMinutes())
                });
            }
            else {
                output = this.twoDigitNumber(date.getDate()) + " " + this.monthGetter(date.getMonth());
                if (!this.isSameYear(date, today)) {
                    output += " " + date.getFullYear();
                }
            }
        }
        return output;
    };
    YFDatePipe.prototype.isSameDay = function (date, anotherDate) {
        if (!this.isSameYear(date, anotherDate)) {
            return false;
        }
        if (date.getMonth() !== anotherDate.getMonth()) {
            return false;
        }
        return date.getDate() === anotherDate.getDate();
    };
    YFDatePipe.prototype.isSameYear = function (date, anotherDay) {
        return date.getFullYear() === anotherDay.getFullYear();
    };
    YFDatePipe.prototype.isYesterday = function (date) {
        var today = new Date();
        var yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        return this.isSameDay(date, yesterday);
    };
    YFDatePipe.prototype.twoDigitNumber = function (num) {
        return num < 10 ? '0' + num : '' + num;
    };
    YFDatePipe.monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    YFDatePipe.arMonthNames = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];
    YFDatePipe.frMonthGetter = YFDatePipe.getFrMonthGetter();
    YFDatePipe.arMonthGetter = YFDatePipe.getArMonthGetter();
    return YFDatePipe;
}());
export { YFDatePipe };
