import {Action} from '@ngrx/store';

export enum PhoneModalActionTypes {
    OpenPhoneModal = '[PHONE_MODAL] OPEN_PHONE_MODAL',
    ClosePhoneModal = '[PHONE_MODAL] CLOSE_PHONE_MODAL'
}

export class OpenPhoneModalAction implements Action {
    readonly type = PhoneModalActionTypes.OpenPhoneModal;
}

export class ClosePhoneModalAction implements Action {
    readonly type = PhoneModalActionTypes.ClosePhoneModal;
}

export type Actions = OpenPhoneModalAction
    | ClosePhoneModalAction;
