import { OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { BaseUserDto } from '../../core/models';
import * as loginModalActions from '../../core/store/actions/login.modal';
import * as fromRoot from '../../store';
import * as userActions from '../../users/store/actions/user.view';
// @Directive({
//     selector: '[appSendMessage]'
// })
var AbstractSendMessageDirective = /** @class */ (function () {
    function AbstractSendMessageDirective(store) {
        this.store = store;
        this.currentUser$ = this.store.pipe(select(fromRoot.getCurrentUser));
    }
    AbstractSendMessageDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.currentUser$.subscribe(function (current) {
            _this.currentUser = current;
        });
    };
    AbstractSendMessageDirective.prototype.sendMessage = function () {
        if (this.currentUser) {
            this.store.dispatch(new userActions.OpenSendMessageAction(this.appSendMessage));
        }
        else {
            this.store.dispatch(new loginModalActions.OpenModalAction());
        }
    };
    return AbstractSendMessageDirective;
}());
export { AbstractSendMessageDirective };
