import { HttpClient } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { getCategories } from '../../store';
import { CommonUtils, HttpUtil } from '../utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
var _flatMap = require('lodash/flatMap');
var ActivityService = /** @class */ (function () {
    function ActivityService(httpClient, store) {
        this.httpClient = httpClient;
        this.store = store;
    }
    ActivityService.prototype.getActivityById = function (id) {
        var _this = this;
        return this.store.pipe(select(getCategories), filter(function (c) { return CommonUtils.notEmpty(c); }), mergeMap(function (categories) {
            var activities = _flatMap(categories, function (c) { return c.activities; });
            var act = activities.find(function (a) { return a.id === id; });
            if (act) {
                return of(act);
            }
            return _this.httpClient.get(HttpUtil.buildAndExpand(ActivityService.ACTIVITY, id));
        }));
    };
    ActivityService.ACTIVITY = 'api/v1/activities/{activityId}';
    ActivityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivityService_Factory() { return new ActivityService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: ActivityService, providedIn: "root" });
    return ActivityService;
}());
export { ActivityService };
