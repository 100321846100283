import {Action} from '@ngrx/store';

export enum ShareModalActionTypes {
    OpenModal = '[SHARE_MODAL] OpenModal',
    CloseModal = '[SHARE_MODAL] CloseModal'
}

export class OpenModalAction implements Action {
    readonly type = ShareModalActionTypes.OpenModal;
}

export class CloseModalAction implements Action {
    readonly type = ShareModalActionTypes.CloseModal;
}

export type Actions = OpenModalAction
    | CloseModalAction;
