import {Action} from '@ngrx/store';
import {ErrorsDto, ReviewDto, ReviewReportDto} from '../../models';

export enum ReviewReportActionTypes {
    ReportReview = '[REPORT REVIEW] REPORT_REVIEW',
    ReportReviewSuccess = '[REPORT REVIEW] REPORT_REVIEW_SUCCESS',
    ReportReviewFail = '[REPORT REVIEW] REPORT_REVIEW_FAIL',

    OpenModal = '[REPORT REVIEW] OPEN_MODAL',
    CloseModal = '[REPORT REVIEW] CLOSE_MODAL',

    Reset = '[REPORT REVIEW] RESET'
}

export class ReportReviewAction implements Action {
    readonly type = ReviewReportActionTypes.ReportReview;

    constructor(public payload: ReviewReportDto) {
    }
}

export class ReportReviewSuccessAction implements Action {
    readonly type = ReviewReportActionTypes.ReportReviewSuccess;
}

export class ReportReviewFailAction implements Action {
    readonly type = ReviewReportActionTypes.ReportReviewFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class OpenModalAction implements Action {
    readonly type = ReviewReportActionTypes.OpenModal;

    constructor(public payload: ReviewDto) {
    }
}

export class CloseModalAction implements Action {
    readonly type = ReviewReportActionTypes.CloseModal;
}

export class ResetAction implements Action {
    readonly type = ReviewReportActionTypes.Reset;
}

export type Actions = ReportReviewAction
    | ReportReviewSuccessAction
    | ReportReviewFailAction
    | OpenModalAction
    | CloseModalAction
    | ResetAction;
