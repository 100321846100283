import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
var ActivityChooserModalComponent = /** @class */ (function () {
    function ActivityChooserModalComponent() {
        this.modalClass = 'bottom-sheet with-footer';
        this.showFooter = true;
        this.selectActivity = new EventEmitter();
        this.selectCategory = new EventEmitter();
        this.quit = new EventEmitter();
        this.validate = new EventEmitter();
    }
    return ActivityChooserModalComponent;
}());
export { ActivityChooserModalComponent };
