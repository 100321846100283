/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-social-sign-in.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../modules/shared/components/notification/notification.component.ngfactory";
import * as i3 from "../../modules/shared/components/notification/notification.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "../../modules/shared/components/spinner/spinner.component.ngfactory";
import * as i8 from "../../modules/shared/components/spinner/spinner.component";
import * as i9 from "../../modules/web/directives/layout-class.directive";
import * as i10 from "@ngrx/store";
import * as i11 from "./post-social-sign-in.component";
import * as i12 from "../../modules/core/services/auth.service";
import * as i13 from "../../modules/core/services/browser.service";
import * as i14 from "ngx-cookie-service";
var styles_PostSocialSignInComponent = [i0.styles];
var RenderType_PostSocialSignInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostSocialSignInComponent, data: {} });
export { RenderType_PostSocialSignInComponent as RenderType_PostSocialSignInComponent };
function View_PostSocialSignInComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "card center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "card-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-notification", [], null, null, null, i2.View_NotificationComponent_0, i2.RenderType_NotificationComponent)), i1.ɵdid(6, 49152, null, 0, i3.NotificationComponent, [], { notification: [0, "notification"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "a", [["routerLink", "/register"], ["routerLinkActive", "active"], ["translate", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), i1.ɵdid(10, 671744, [[2, 4]], 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(11, 1720320, null, 2, i5.RouterLinkActive, [i5.Router, i1.ElementRef, i1.Renderer2, [2, i5.RouterLink], [2, i5.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["nav-bar.sign-up"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notification; _ck(_v, 6, 0, currVal_0); var currVal_3 = ""; _ck(_v, 9, 0, currVal_3); var currVal_4 = "/register"; _ck(_v, 10, 0, currVal_4); var currVal_5 = "active"; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 10).target; var currVal_2 = i1.ɵnov(_v, 10).href; _ck(_v, 8, 0, currVal_1, currVal_2); }); }
function View_PostSocialSignInComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-spinner", [], null, null, null, i7.View_SpinnerComponent_0, i7.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i8.SpinnerComponent, [], null, null)], null, null); }
export function View_PostSocialSignInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"], ["yfLayoutClass", ""]], null, null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i9.LayoutClassDirective, [i1.ElementRef, i1.Renderer2, i10.Store], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostSocialSignInComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["spinner", 2]], null, 0, null, View_PostSocialSignInComponent_2))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.notification; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_PostSocialSignInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "yf-post-social-sign-in", [], null, null, null, View_PostSocialSignInComponent_0, RenderType_PostSocialSignInComponent)), i1.ɵdid(1, 245760, null, 0, i11.PostSocialSignInComponent, [i10.Store, i12.AuthService, i5.Router, i13.BrowserService, i14.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostSocialSignInComponentNgFactory = i1.ɵccf("yf-post-social-sign-in", i11.PostSocialSignInComponent, View_PostSocialSignInComponent_Host_0, {}, {}, []);
export { PostSocialSignInComponentNgFactory as PostSocialSignInComponentNgFactory };
