import { PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Locale } from '../../core/models';
import * as fromRoot from '../../store';
var CategoryTranslatorPipe = /** @class */ (function () {
    function CategoryTranslatorPipe(store) {
        var _this = this;
        this.store = store;
        this.nameGetter = CategoryTranslatorPipe.frNameGetter;
        this.store.pipe(select(fromRoot.getLocale), filter(function (locale) { return locale != null; }))
            .subscribe(function (locale) {
            if (!locale) {
                locale = Locale.ENGLISH;
            }
            switch (locale.shortcut.toLowerCase()) {
                case Locale.FRENCH.shortcut:
                    _this.nameGetter = CategoryTranslatorPipe.frNameGetter;
                    break;
                case Locale.ENGLISH.shortcut:
                    _this.nameGetter = CategoryTranslatorPipe.enNameGetter;
                    break;
                case Locale.ARABIC.shortcut:
                    _this.nameGetter = CategoryTranslatorPipe.arNameGetter;
                    break;
                case Locale.MOROCCAN.shortcut:
                    _this.nameGetter = CategoryTranslatorPipe.maNameGetter;
                    break;
            }
        });
    }
    CategoryTranslatorPipe.getArNameGetter = function () {
        var fn = function (category) { return category.arName; };
        return fn;
    };
    CategoryTranslatorPipe.getFrNameGetter = function () {
        var fn = function (category) { return category.name; };
        return fn;
    };
    CategoryTranslatorPipe.getEnNameGetter = function () {
        var fn = function (category) { return category.enName; };
        return fn;
    };
    CategoryTranslatorPipe.getMaNameGetter = function () {
        var fn = function (category) { return category.maName; };
        return fn;
    };
    CategoryTranslatorPipe.prototype.transform = function (value, args) {
        return this.nameGetter(value);
    };
    CategoryTranslatorPipe.arNameGetter = CategoryTranslatorPipe.getArNameGetter();
    CategoryTranslatorPipe.enNameGetter = CategoryTranslatorPipe.getEnNameGetter();
    CategoryTranslatorPipe.maNameGetter = CategoryTranslatorPipe.getMaNameGetter();
    CategoryTranslatorPipe.frNameGetter = CategoryTranslatorPipe.getFrNameGetter();
    return CategoryTranslatorPipe;
}());
export { CategoryTranslatorPipe };
