export var AccountProfileActionTypes;
(function (AccountProfileActionTypes) {
    AccountProfileActionTypes["LoadUserProfile"] = "[USER_PROFILE] LOAD_USER_PROFILE";
    AccountProfileActionTypes["LoadUserProfileSuccess"] = "[USER_PROFILE] LOAD_USER_PROFILE_SUCCESS";
    AccountProfileActionTypes["LoadUserProfileFail"] = "[USER_PROFILE] LOAD_USER_PROFILE_FAIL";
    AccountProfileActionTypes["UpdateUserProfile"] = "[USER_PROFILE] UPDATE_USER_PROFILE";
    AccountProfileActionTypes["UpdateUserProfileSuccess"] = "[USER_PROFILE] UPDATE_USER_PROFILE_SUCCESS";
    AccountProfileActionTypes["UpdateUserProfileFail"] = "[USER_PROFILE] UPDATE_USER_PROFILE_FAIL";
    AccountProfileActionTypes["SetShadowUserProfile"] = "[USER_PROFILE] SET_SHADOW_USER_PROFILE";
    AccountProfileActionTypes["ResetUserProfile"] = "[USER_PROFILE] RESET_USER_PROFILE";
    AccountProfileActionTypes["LoadUserProfileIfNull"] = "[USER_PROFILE] LOAD_USER_PROFILE_IF_NULL";
    // Personal information
    AccountProfileActionTypes["UpdatePictureFail"] = "[USER_PROFILE] UPDATE_PICTURE_FAIL";
    AccountProfileActionTypes["SetPicture"] = "[USER_PROFILE] SET_PICTURE";
    // Professional information
    AccountProfileActionTypes["SelectActivity"] = "[USER_PROFILE] SELECT_ACTIVITY";
    AccountProfileActionTypes["SetAddress"] = "[USER_PROFILE] SET_ADDRESS";
    // Portfolio
    AccountProfileActionTypes["AddOrDeleteMediaFromDeleteList"] = "[USER_PROFILE] ADD_OR_DELETE_MEDIA_FROM_DELETE_LIST";
    AccountProfileActionTypes["AddAllMediasToDeleteList"] = "[USER_PROFILE] ADD_ALL_MEDIAS_TO_DELETE_LIST";
    AccountProfileActionTypes["DeleteAllMediasFromDeleteList"] = "[USER_PROFILE] DELETE_ALL_MEDIAS_FROM_DELETE_LIST";
    AccountProfileActionTypes["AddMediasFail"] = "[USER_PROFILE] ADD_MEDIAS_FAIL";
    AccountProfileActionTypes["SetMedias"] = "[USER_PROFILE] SET_MEDIAS";
    AccountProfileActionTypes["ChangePortfolioDisplayMode"] = "[USER_PROFILE] CHANGE_PORTFOLIO_DISPLAY_MODE";
    AccountProfileActionTypes["CancelMediasDeletion"] = "[USER_PROFILE] CANCEL_MEDIAS_DELETION";
    AccountProfileActionTypes["DeleteMedias"] = "[USER_PROFILE] DELETE_MEDIAS";
    AccountProfileActionTypes["DeleteMediasSuccess"] = "[USER_PROFILE] DELETE_MEDIAS_SUCCESS";
    AccountProfileActionTypes["DeleteMediasFail"] = "[USER_PROFILE] DELETE_MEDIAS_FAIL";
    AccountProfileActionTypes["ResetUpdated"] = "[USER_PROFILE] RESET_UPDATED";
    AccountProfileActionTypes["ResetPortfolioErrors"] = "[USER_PROFILE] RESET_PORTFOLIO_ERRORS";
    AccountProfileActionTypes["SetPersonalInfosErrors"] = "[USER_PROFILE] SET_PERSONAL_INFOS_ERRORS";
    AccountProfileActionTypes["SetServicesNotification"] = "[USER_PROFILE] SET_SERVICES_NOTIFICATION";
    // Verifications
    AccountProfileActionTypes["SubmitPhoneVerification"] = "[USER_PROFILE] SubmitPhoneVerification";
    AccountProfileActionTypes["SubmitPhoneVerificationSuccess"] = "[USER_PROFILE] SubmitPhoneVerificationSuccess";
    AccountProfileActionTypes["SubmitPhoneVerificationFail"] = "[USER_PROFILE] SubmitPhoneVerificationFail";
    AccountProfileActionTypes["ResetPhoneVerification"] = "[USER_PROFILE] ResetPhoneVerification";
    AccountProfileActionTypes["VerifyPhoneNumber"] = "[USER_PROFILE] VerifyPhoneNumber";
    AccountProfileActionTypes["VerifyPhoneNumberSuccess"] = "[USER_PROFILE] VerifyPhoneNumberSuccess";
    AccountProfileActionTypes["VerifyPhoneNumberFail"] = "[USER_PROFILE] VerifyPhoneNumberFail";
    AccountProfileActionTypes["VerifyEmail"] = "[USER_PROFILE] VerifyEmail";
    AccountProfileActionTypes["VerifyEmailSuccess"] = "[USER_PROFILE] VerifyEmailSuccess";
    AccountProfileActionTypes["VerifyEmailFail"] = "[USER_PROFILE] VerifyEmailFail";
})(AccountProfileActionTypes || (AccountProfileActionTypes = {}));
/***************************
 *      Profile
 ***************************/
var LoadUserProfileIfNullAction = /** @class */ (function () {
    function LoadUserProfileIfNullAction() {
        this.type = AccountProfileActionTypes.LoadUserProfileIfNull;
    }
    return LoadUserProfileIfNullAction;
}());
export { LoadUserProfileIfNullAction };
var LoadUserProfileAction = /** @class */ (function () {
    function LoadUserProfileAction() {
        this.type = AccountProfileActionTypes.LoadUserProfile;
    }
    return LoadUserProfileAction;
}());
export { LoadUserProfileAction };
var LoadUserProfileSuccessAction = /** @class */ (function () {
    function LoadUserProfileSuccessAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.LoadUserProfileSuccess;
    }
    return LoadUserProfileSuccessAction;
}());
export { LoadUserProfileSuccessAction };
var LoadUserProfileFailAction = /** @class */ (function () {
    function LoadUserProfileFailAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.LoadUserProfileFail;
    }
    return LoadUserProfileFailAction;
}());
export { LoadUserProfileFailAction };
var SetPersonalInfosErrorsAction = /** @class */ (function () {
    function SetPersonalInfosErrorsAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.SetPersonalInfosErrors;
    }
    return SetPersonalInfosErrorsAction;
}());
export { SetPersonalInfosErrorsAction };
var UpdateUserProfileAction = /** @class */ (function () {
    function UpdateUserProfileAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.UpdateUserProfile;
    }
    return UpdateUserProfileAction;
}());
export { UpdateUserProfileAction };
var UpdateUserProfileSuccessAction = /** @class */ (function () {
    function UpdateUserProfileSuccessAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.UpdateUserProfileSuccess;
    }
    return UpdateUserProfileSuccessAction;
}());
export { UpdateUserProfileSuccessAction };
var UpdateUserProfileFailAction = /** @class */ (function () {
    function UpdateUserProfileFailAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.UpdateUserProfileFail;
    }
    return UpdateUserProfileFailAction;
}());
export { UpdateUserProfileFailAction };
var SetShadowUserProfileAction = /** @class */ (function () {
    function SetShadowUserProfileAction() {
        this.type = AccountProfileActionTypes.SetShadowUserProfile;
    }
    return SetShadowUserProfileAction;
}());
export { SetShadowUserProfileAction };
var ResetUserProfileAction = /** @class */ (function () {
    function ResetUserProfileAction() {
        this.type = AccountProfileActionTypes.ResetUserProfile;
    }
    return ResetUserProfileAction;
}());
export { ResetUserProfileAction };
/***************************
 *   Personal information
 ***************************/
var UpdatePictureFailAction = /** @class */ (function () {
    function UpdatePictureFailAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.UpdatePictureFail;
    }
    return UpdatePictureFailAction;
}());
export { UpdatePictureFailAction };
var SetPictureAction = /** @class */ (function () {
    function SetPictureAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.SetPicture;
    }
    return SetPictureAction;
}());
export { SetPictureAction };
/***************************
 * Professional information
 ***************************/
var SelectActivityAction = /** @class */ (function () {
    function SelectActivityAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.SelectActivity;
    }
    return SelectActivityAction;
}());
export { SelectActivityAction };
var SetAddressAction = /** @class */ (function () {
    function SetAddressAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.SetAddress;
    }
    return SetAddressAction;
}());
export { SetAddressAction };
/***************************
 * Portfolio
 ***************************/
var AddMediasFailAction = /** @class */ (function () {
    function AddMediasFailAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.AddMediasFail;
    }
    return AddMediasFailAction;
}());
export { AddMediasFailAction };
var AddOrDeleteMediaFromDeleteListAction = /** @class */ (function () {
    function AddOrDeleteMediaFromDeleteListAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.AddOrDeleteMediaFromDeleteList;
    }
    return AddOrDeleteMediaFromDeleteListAction;
}());
export { AddOrDeleteMediaFromDeleteListAction };
var AddAllMediasToDeleteListAction = /** @class */ (function () {
    function AddAllMediasToDeleteListAction() {
        this.type = AccountProfileActionTypes.AddAllMediasToDeleteList;
    }
    return AddAllMediasToDeleteListAction;
}());
export { AddAllMediasToDeleteListAction };
var DeleteAllMediasFromDeleteListAction = /** @class */ (function () {
    function DeleteAllMediasFromDeleteListAction() {
        this.type = AccountProfileActionTypes.DeleteAllMediasFromDeleteList;
    }
    return DeleteAllMediasFromDeleteListAction;
}());
export { DeleteAllMediasFromDeleteListAction };
var DeleteMediasAction = /** @class */ (function () {
    function DeleteMediasAction() {
        this.type = AccountProfileActionTypes.DeleteMedias;
    }
    return DeleteMediasAction;
}());
export { DeleteMediasAction };
var DeleteMediasSuccessAction = /** @class */ (function () {
    function DeleteMediasSuccessAction() {
        this.type = AccountProfileActionTypes.DeleteMediasSuccess;
    }
    return DeleteMediasSuccessAction;
}());
export { DeleteMediasSuccessAction };
var DeleteMediasFailAction = /** @class */ (function () {
    function DeleteMediasFailAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.DeleteMediasFail;
    }
    return DeleteMediasFailAction;
}());
export { DeleteMediasFailAction };
var ChangePortfolioDisplayModeAction = /** @class */ (function () {
    function ChangePortfolioDisplayModeAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.ChangePortfolioDisplayMode;
    }
    return ChangePortfolioDisplayModeAction;
}());
export { ChangePortfolioDisplayModeAction };
var CancelMediasDeletionAction = /** @class */ (function () {
    function CancelMediasDeletionAction() {
        this.type = AccountProfileActionTypes.CancelMediasDeletion;
    }
    return CancelMediasDeletionAction;
}());
export { CancelMediasDeletionAction };
var SetMediasAction = /** @class */ (function () {
    function SetMediasAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.SetMedias;
    }
    return SetMediasAction;
}());
export { SetMediasAction };
var SetServicesNotificationAction = /** @class */ (function () {
    function SetServicesNotificationAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.SetServicesNotification;
    }
    return SetServicesNotificationAction;
}());
export { SetServicesNotificationAction };
var ResetUpdatedAction = /** @class */ (function () {
    function ResetUpdatedAction() {
        this.type = AccountProfileActionTypes.ResetUpdated;
    }
    return ResetUpdatedAction;
}());
export { ResetUpdatedAction };
var ResetPortfolioErrorsAction = /** @class */ (function () {
    function ResetPortfolioErrorsAction() {
        this.type = AccountProfileActionTypes.ResetPortfolioErrors;
    }
    return ResetPortfolioErrorsAction;
}());
export { ResetPortfolioErrorsAction };
/***************************
 * Verifications
 ***************************/
var SubmitPhoneVerification = /** @class */ (function () {
    function SubmitPhoneVerification() {
        this.type = AccountProfileActionTypes.SubmitPhoneVerification;
    }
    return SubmitPhoneVerification;
}());
export { SubmitPhoneVerification };
var SubmitPhoneVerificationSuccess = /** @class */ (function () {
    function SubmitPhoneVerificationSuccess() {
        this.type = AccountProfileActionTypes.SubmitPhoneVerificationSuccess;
    }
    return SubmitPhoneVerificationSuccess;
}());
export { SubmitPhoneVerificationSuccess };
var SubmitPhoneVerificationFail = /** @class */ (function () {
    function SubmitPhoneVerificationFail(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.SubmitPhoneVerificationFail;
    }
    return SubmitPhoneVerificationFail;
}());
export { SubmitPhoneVerificationFail };
var ResetPhoneVerification = /** @class */ (function () {
    function ResetPhoneVerification() {
        this.type = AccountProfileActionTypes.ResetPhoneVerification;
    }
    return ResetPhoneVerification;
}());
export { ResetPhoneVerification };
var VerifyPhoneNumberAction = /** @class */ (function () {
    function VerifyPhoneNumberAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.VerifyPhoneNumber;
    }
    return VerifyPhoneNumberAction;
}());
export { VerifyPhoneNumberAction };
var VerifyPhoneNumberSuccessAction = /** @class */ (function () {
    function VerifyPhoneNumberSuccessAction() {
        this.type = AccountProfileActionTypes.VerifyPhoneNumberSuccess;
    }
    return VerifyPhoneNumberSuccessAction;
}());
export { VerifyPhoneNumberSuccessAction };
var VerifyPhoneNumberFailAction = /** @class */ (function () {
    function VerifyPhoneNumberFailAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.VerifyPhoneNumberFail;
    }
    return VerifyPhoneNumberFailAction;
}());
export { VerifyPhoneNumberFailAction };
var VerifyEmailAction = /** @class */ (function () {
    function VerifyEmailAction() {
        this.type = AccountProfileActionTypes.VerifyEmail;
    }
    return VerifyEmailAction;
}());
export { VerifyEmailAction };
var VerifyEmailSuccessAction = /** @class */ (function () {
    function VerifyEmailSuccessAction() {
        this.type = AccountProfileActionTypes.VerifyEmailSuccess;
    }
    return VerifyEmailSuccessAction;
}());
export { VerifyEmailSuccessAction };
var VerifyEmailFailAction = /** @class */ (function () {
    function VerifyEmailFailAction(payload) {
        this.payload = payload;
        this.type = AccountProfileActionTypes.VerifyEmailSuccess;
    }
    return VerifyEmailFailAction;
}());
export { VerifyEmailFailAction };
