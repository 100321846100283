import {Action} from '@ngrx/store';
import {ActivityDto, CategoryDto} from '../../../core/models';

export enum ProfileServicesChooserActionTypes {
    SelectActivity = '[USER ACTIVITY CHOOSER] SELECT_ACTIVITY',
    SelectCategory = '[USER ACTIVITY CHOOSER] SELECT_CATEGORY',

    SetActivities = '[USER ACTIVITY CHOOSER] SET_ACTIVITIES',

    SelectOrDeselectActivity = '[USER ACTIVITY CHOOSER] SELECT_OR_DESELECT_ACTIVITY',

    SetSelectedActivities = '[USER ACTIVITY CHOOSER] SET_SELECTED_ACTIVITIES',
    SetSelectedCategories = '[USER ACTIVITY CHOOSER] SET_SELECTED_CATEGORIES',

    Reset = '[USER ACTIVITY CHOOSER] RESET',
    Init = '[USER ACTIVITY CHOOSER] INIT'
}


export class SelectActivityAction implements Action {
    readonly type = ProfileServicesChooserActionTypes.SelectActivity;

    constructor(public payload: ActivityDto) {
    }
}

export class SelectCategoryAction implements Action {
    readonly type = ProfileServicesChooserActionTypes.SelectCategory;

    constructor(public payload: CategoryDto) {
    }
}


export class SelectOrDeselectActivityAction implements Action {
    readonly type = ProfileServicesChooserActionTypes.SelectOrDeselectActivity;

    constructor(public payload: ActivityDto) {
    }
}

export class SetActivitiesAction implements Action {
    readonly type = ProfileServicesChooserActionTypes.SetActivities;

    constructor(public payload: ActivityDto[]) {
    }
}

export class SetSelectedActivitiesAction implements Action {
    readonly type = ProfileServicesChooserActionTypes.SetSelectedActivities;

    constructor(public payload: ActivityDto[]) {
    }
}

export class SetSelectedCategoriesAction implements Action {
    readonly type = ProfileServicesChooserActionTypes.SetSelectedCategories;

    constructor(public payload: CategoryDto[]) {
    }
}

export class InitAction implements Action {
    readonly type = ProfileServicesChooserActionTypes.Init;
}

export class ResetAction implements Action {
    readonly type = ProfileServicesChooserActionTypes.Reset;
}

export type Actions = SelectActivityAction
    | SelectCategoryAction
    | SelectOrDeselectActivityAction
    | SetActivitiesAction
    | SetSelectedActivitiesAction
    | SetSelectedCategoriesAction
    | InitAction
    | ResetAction;
