import { Component, OnInit } from '@angular/core';
import {SeoService} from '../../../modules/core/services';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private seo: SeoService) {
    seo.setMeta('privacy-policy');
    seo.setRobots(true);
  }

  ngOnInit() {
  }

}
