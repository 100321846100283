import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {

    @Input() width = 50;
    @Input() height = 50;

}
