<div class="container-fluid login-header" yfLayoutClass>
    <div class="container">
        <div class="right-align center-sm-down subscribe">
            <span class="white-text" translate>sign-in.no-account</span>
            <button type="button" class="btn btn-default mt-2 mt-md-0" routerLink="/register" translate>
                sign-in.sign-up
            </button>
        </div>
    </div>

</div>

<div>
    <div class="ads-ctr-left mt-2">
        <ng-adsense></ng-adsense>
    </div>
    <div class="ads-ctr-right mt-2">
        <ng-adsense></ng-adsense>
    </div>
    <div class="container" yfLayoutClass>

        <div class="row mb-0 center">
            <div class="col center-sm-down">
                <h1 translate>global.sign-in</h1>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8">

                <div class="card">

                    <div class="card-header center">
                        <span class="text-white" translate>sign-in.with-social-media</span>
                    </div>

                    <div class="card-block row mb-0 justify-content-center">

                        <form ngNoForm action="/signin/facebook" method="post" class="col-lg-8 center">
                            <app-notification [notification]="socialNotification"></app-notification>
                            <input type="hidden" name="scope"
                                   value="email,public_profile,user_location"/>

                            <button type="submit" class="waves-effect waves-light btn social facebook" dir="ltr">
                                <i class="fa fa-facebook"></i> <span class="hidden-sm-down"
                                                                     translate>sign-in.with </span>Facebook
                            </button>

                        </form>
                    </div>

                </div>

                <div class="center">
                    <h2 class="h5 my-4" translate>global.or</h2>
                </div>

                <div class="card">

                    <div class="card-header center">
                        <span class="text-white" translate>sign-in.with-email</span>
                    </div>

                    <div class="card-block">

                        <div class="row justify-content-center">
                            <form name="form" class="col-md-8" (ngSubmit)="submit(userLogin)" [formGroup]="userLogin">

                                <app-notification [notification]="notification$ | async">
                                    <div class="row mb-0 mt-2"
                                         *ngIf="(notification$ | async)?.detail === 'user.disabled'">
                                        <span class="col" translate>errors.sign-in.account-disabled</span>
                                    </div>
                                </app-notification>

<!--                                <div class="input-field">-->
<!--                                    <input type="text" id="email" name="login"-->
<!--                                           class="form-control" formControlName="login"/>-->
<!--                                    <label for="email" translate>global.login</label>-->
<!--                                </div>-->

<!--                                <app-control-messages [control]="userLogin" controlName="login" forLabel="email">-->
<!--                                </app-control-messages>-->

                                <app-login-input [id]="'login'"
                                                 [labelKey]="'global.login'"
                                                 [parentFormGroup]="userLogin"
                                                 [controlName]="'login'"
                                                 [placeholder]="'global.login-placeholder' | translate">
                                </app-login-input>

                                <app-control-messages [control]="userLogin"
                                                      keyPrefix="errors.custom"
                                                      controlName="login" forLabel="email">
                                </app-control-messages>

                                <div class="input-field">
                                    <input type="password" id="pass" name="password"
                                           class="form-control" formControlName="password"/>
                                    <label for="pass" translate>global.password</label>
                                </div>

                                <app-control-messages [control]="userLogin" controlName="password"
                                                      forLabel="pass"></app-control-messages>

                                <button [disabled]="userLogin.invalid || (loading$ | async)"
                                        class="btn btn-primary col-12 mt-4" translate>global.sign-in
                                </button>

                                <div class=" align-content-end mt-2">
                                    <a routerLink="/password/forgotten" translate>global.is-password-forgotten</a>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

                <div class="center">
            <span class="font-italic" translate>sign-in.accept-terms
                <a [routerLink]="['/conditions-generales-vente']" translate>sign-in.cgu</a>
            </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 center">
                <ng-adsense></ng-adsense>
            </div>
        </div>
    </div>
</div>