import {Injectable, ViewContainerRef} from '@angular/core';
import {Params} from '@angular/router';
import {Observable} from 'rxjs';
import {ModalCallback, SearchCriteriaDto} from '../../../modules/core/models';

@Injectable()
export class HomeHelper {

    constructor() {

    }

    hideActionBar() {
    }

    showServiceDialog(vcRef: ViewContainerRef): Observable<ModalCallback<SearchCriteriaDto>> {
        return new Observable();
    }

    navigate(params: Params) {
    }
}
