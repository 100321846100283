import { OnInit, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Pageable } from '../../modules/core/models';
import { SeoService } from '../../modules/core/services';
import { routeActions } from '../../modules/core/store/actions';
import { UserParamUtils } from '../../modules/users/utils';
import { HomeHelper } from './helper/home.helper';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(store, seo, vcRef, homeHelper) {
        this.store = store;
        this.seo = seo;
        this.vcRef = vcRef;
        this.homeHelper = homeHelper;
        seo.setMeta('home');
        seo.setRobots(true);
    }
    HomeComponent.prototype.search = function (searchCriteria) {
        this.store.dispatch(new routeActions.ToSearchWorkersAction({ searchCriteria: searchCriteria }));
    };
    HomeComponent.prototype.ngOnInit = function () {
        this.homeHelper.hideActionBar();
    };
    HomeComponent.prototype.showServiceDialog = function () {
        var _this = this;
        this.homeHelper.showServiceDialog(this.vcRef).subscribe(function (callback) {
            if (callback && callback.isClose()) {
                return;
            }
            var searchCriteria = callback.payload;
            var pageable = new Pageable();
            var params = UserParamUtils.toParams(searchCriteria, pageable);
            _this.homeHelper.navigate(params);
        });
    };
    return HomeComponent;
}());
export { HomeComponent };
