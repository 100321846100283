import { HttpUtil } from './http.utils';
import { Pageable } from '../models';
var _cloneDeep = require('lodash/cloneDeep');
var _isEmpty = require('lodash/isEmpty');
var _isEqual = require('lodash/isEqual');
var CommonUtils = /** @class */ (function () {
    function CommonUtils() {
    }
    CommonUtils.cloneDeep = function (object) {
        return _cloneDeep(object);
    };
    CommonUtils.arrayEquals = function (arr1, arr2) {
        if (arr1 == null) {
            return arr2 == null;
        }
        if (arr2 == null) {
            return false;
        }
        return arr1.length === arr2.length && _isEqual(arr1.sort(), arr2.sort());
    };
    CommonUtils.fillPageable = function (params, pageable) {
        if (!pageable) {
            pageable = new Pageable();
        }
        if (!params) {
            return pageable;
        }
        var pageParam = params['p'];
        pageable.page = pageParam && +pageParam > 0 ? +pageParam : 1;
        var size = params['size'];
        if (CommonUtils.notEmpty(size)) {
            pageable.size = +size;
        }
        pageable.sort = HttpUtil.stringToSortArray(params['sort']);
        return pageable;
    };
    CommonUtils.pageableToParams = function (pageable) {
        var params = [];
        params['p'] = pageable.page;
        if (pageable.sort && pageable.sort.length) {
            params['sort'] = HttpUtil.sortArrayToString(pageable.sort);
        }
        return params;
    };
    CommonUtils.concatParams = function (par1, oar2) {
        if (!par1) {
            return oar2;
        }
        Object.keys(oar2).forEach(function (key) { return par1[key] = oar2[key]; });
        return par1;
    };
    CommonUtils.isEmpty = function (value) {
        return _isEmpty(value);
    };
    CommonUtils.notEmpty = function (object) {
        return !CommonUtils.isEmpty(object);
    };
    CommonUtils.toBoolean = function (value) {
        return value === 'true';
    };
    CommonUtils.pluralize = function (length, text) {
        if (length !== 1) {
            return text.concat('s');
        }
        return text;
    };
    CommonUtils.twoDigitNumber = function (num) {
        return num < 10 ? '0' + num : '' + num;
    };
    return CommonUtils;
}());
export { CommonUtils };
