import { ActivityDto, AddressDto, Pageable, SearchCriteriaDto } from '../../core/models';
import { CommonUtils, HttpUtil } from '../../core/utils';
import * as i0 from "@angular/core";
var WorkerSearchCriteriaService = /** @class */ (function () {
    function WorkerSearchCriteriaService() {
    }
    WorkerSearchCriteriaService.prototype.buildFromRequest = function (request) {
        if (!request) {
            return null;
        }
        var sc = new SearchCriteriaDto();
        sc.address = request.address;
        sc.activity = request.activity;
        return sc;
    };
    WorkerSearchCriteriaService.prototype.buildFromParams = function (params) {
        var searchCriteriaDto = this.buildFromParamsPartial(params);
        if (searchCriteriaDto && (searchCriteriaDto.activity || searchCriteriaDto.address)) {
            return searchCriteriaDto;
        }
        return null;
    };
    WorkerSearchCriteriaService.prototype.buildFromParamsPartial = function (params) {
        if (!params) {
            return null;
        }
        var latitude = +params['lat'];
        var longitude = +params['lng'];
        var activityId = +params['activityId'];
        var sc;
        if (activityId) {
            sc = new SearchCriteriaDto();
            sc.activity = new ActivityDto();
            sc.activity.id = activityId;
            sc.activity.name = params['activityName'];
        }
        if (latitude && longitude) {
            if (!sc) {
                sc = new SearchCriteriaDto();
            }
            var address = new AddressDto();
            address.location = {
                latitude: latitude,
                longitude: longitude
            };
            address.formatted = params['address'];
            sc.address = address;
        }
        if (sc) {
            var radius = params['radius'];
            if (!radius) {
                radius = WorkerSearchCriteriaService.DEFAULT_SEARCH_RADIUS;
            }
            sc.radius = radius;
        }
        return sc;
    };
    WorkerSearchCriteriaService.prototype.convertToParams = function (sc, pageable) {
        var params = this.convertSearchCriteriaToParams(sc);
        if (pageable) {
            CommonUtils.concatParams(params, this.convertPageableToParams(pageable));
        }
        return params;
    };
    WorkerSearchCriteriaService.prototype.convertSearchCriteriaToParams = function (searchCriteria) {
        var params = [];
        if (!searchCriteria) {
            return params;
        }
        if (searchCriteria.address && searchCriteria.address.location) {
            params['lat'] = searchCriteria.address.location.latitude;
            params['lng'] = searchCriteria.address.location.longitude;
            params['address'] = searchCriteria.address.formatted;
        }
        if (searchCriteria.activity) {
            params['activityId'] = searchCriteria.activity.id;
            params['activityName'] = searchCriteria.activity.name;
        }
        if (searchCriteria.radius) {
            params['radius'] = searchCriteria.radius;
        }
        return params;
    };
    WorkerSearchCriteriaService.prototype.fillPageable = function (params, pageable) {
        pageable.page = +params['p'] ? +params['p'] - 1 : 0;
        pageable.sort = HttpUtil.stringToSortArray(params['sort']);
        return pageable;
    };
    WorkerSearchCriteriaService.prototype.buildPageable = function (params) {
        return this.fillPageable(params, new Pageable());
    };
    WorkerSearchCriteriaService.prototype.convertPageableToParams = function (pageable) {
        var params = [];
        params['p'] = pageable.page;
        if (pageable.sort && pageable.sort.length) {
            params['sort'] = HttpUtil.sortArrayToString(pageable.sort);
        }
        return params;
    };
    WorkerSearchCriteriaService.DEFAULT_SEARCH_RADIUS = 100;
    WorkerSearchCriteriaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkerSearchCriteriaService_Factory() { return new WorkerSearchCriteriaService(); }, token: WorkerSearchCriteriaService, providedIn: "root" });
    return WorkerSearchCriteriaService;
}());
export { WorkerSearchCriteriaService };
