import {Sort} from './page.model';

export class UISort {
    sort: Sort;
    text: string;

    constructor(sort: Sort, text: string) {
        this.sort = sort;
        this.text = text;
    }
}
