import { OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
var FooterComponent = /** @class */ (function () {
    function FooterComponent() {
    }
    FooterComponent.prototype.ngOnInit = function () {
        this.version = environment.version;
    };
    return FooterComponent;
}());
export { FooterComponent };
