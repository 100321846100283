import {Action} from '@ngrx/store';
import {Locale} from '../../models';

export enum LocaleActionsTypes {
    SetLocale = '[LOCALE] SET_LOCALE',
    UpdateLocale = '[LOCALE] UPDATE_LOCALE',
    TestLocale = '[LOCALE] TEST_LOCALE'
}


export class SetLocaleAction implements Action {
    readonly type = LocaleActionsTypes.SetLocale;

    constructor(public payload: Locale) {
    }
}

export class UpdateLocaleAction implements Action {
    readonly type = LocaleActionsTypes.UpdateLocale;

    constructor(public payload: Locale) {
    }
}

export class TestLocaleAction implements Action {
    readonly type = LocaleActionsTypes.TestLocale;
}

export type Actions = SetLocaleAction | UpdateLocaleAction | TestLocaleAction;
