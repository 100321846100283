import {BasicUserDto, DisplayReviewDto, ErrorsDto, ReviewRatingDto} from '../../models';

import {reviewActions} from '../actions';

export interface State {
    user: BasicUserDto,
    reviewRatings: ReviewRatingDto[];
    reviews: DisplayReviewDto[];
    reviewAdded: boolean;
    loading: boolean;
    errors: ErrorsDto;
}

export const initialState: State = {
    user: null,
    reviewRatings: [],
    reviews: [],
    reviewAdded: false,
    loading: false,
    errors: null
};

export function reducer(state = initialState, action: reviewActions.Actions): State {

    switch (action.type) {

        case reviewActions.ReviewActionTypes.LoadReviewRatings: {
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    errors: null
                }
            )
        }

        case reviewActions.ReviewActionTypes.LoadReviewRatingsSuccess: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    reviewAdded: true,
                    reviewRatings: action.payload
                }
            )
        }

        case reviewActions.ReviewActionTypes.LoadReviewRatingsFail: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: action.payload,
                    reviewRatings: []
                }
            )
        }

        case reviewActions.ReviewActionTypes.LoadUserReviews: {
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    errors: null
                }
            )
        }

        case reviewActions.ReviewActionTypes.LoadUserReviewsSuccess: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: null,
                    reviews: action.payload
                }
            )
        }

        case reviewActions.ReviewActionTypes.LoadUserReviewsFail: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: action.payload,
                    reviews: []
                }
            )
        }

        case reviewActions.ReviewActionTypes.AddReview: {
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    reviewAdded: false
                }
            )
        }

        case reviewActions.ReviewActionTypes.AddReviewSuccess: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    reviewAdded: true,
                    errors: null
                }
            )
        }

        case reviewActions.ReviewActionTypes.AddReviewFail: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    reviewAdded: false,
                    errors: action.payload
                }
            )
        }

        case reviewActions.ReviewActionTypes.SetUser: {
            state.user = action.payload;

            return state;
        }

        case reviewActions.ReviewActionTypes.ResetIsReviewAdded: {
            return Object.assign(
                {},
                state,
                {
                    reviewAdded: false
                }
            )
        }

        default: {
            return state;
        }
    }
}

export const getReviewRatings = (state: State): ReviewRatingDto[] => state.reviewRatings;
export const getReviews = (state: State): DisplayReviewDto[] => state.reviews;
export const isReviewAdded = (state: State): boolean => state.reviewAdded;
export const isLoading = (state: State): boolean => state.loading;
export const getErrors = (state: State): ErrorsDto => state.errors;
export const getUser = (state: State): BasicUserDto => state.user;
