import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Pageable, PromotionResultType } from '../../../core/models';
import { BrowserService } from '../../../core/services';
import { routeActions } from '../../../core/store/actions';
import { CommonUtils } from '../../../core/utils';
import * as fromRoot from '../../../store';
import { workersPromotionActions } from '../../store/actions';
import { PromotionWorkersHelper } from './helper/promotion-workers.helper';
var PromotionWorkersComponent = /** @class */ (function () {
    function PromotionWorkersComponent(store, browserService, translateService, promotionWorkersHelper) {
        this.store = store;
        this.browserService = browserService;
        this.translateService = translateService;
        this.promotionWorkersHelper = promotionWorkersHelper;
        this.subscription = new Subscription();
        this.title = '';
        this.titleKey = 'users-search.last-title';
        this.pageable = new Pageable(5);
        this.loading$ = this.store.pipe(select(fromRoot.isWorkersPromotionLoading));
        this.errors$ = this.store.pipe(select(fromRoot.getWorkersPromotionErrors));
    }
    PromotionWorkersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(new workersPromotionActions.LoadPromotionWorkersAction(this.pageable));
        var promotionResultSub = this.store.pipe(select(fromRoot.getWorkersPromotionResult), filter(function (result) { return CommonUtils.notEmpty(result); })).subscribe(function (result) {
            _this.city = result.city;
            _this.workers = result.page.content;
            _this.title = _this.translateService.instant('users-search.last-title');
            if (result.type === PromotionResultType.ADDRESS) {
                _this.titleKey = 'users-search.last-title-in-city';
                _this.title = _this.translateService.instant('users-search.last-title-in-city', { city: _this.city });
            }
            _this.promotionWorkersHelper.push(_this.workers);
        });
        this.subscription.add(promotionResultSub);
    };
    PromotionWorkersComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    PromotionWorkersComponent.prototype.navigateToWorker = function (worker) {
        this.store.dispatch(new routeActions.ToWorkerAction(worker.uid));
    };
    Object.defineProperty(PromotionWorkersComponent.prototype, "dataItems", {
        get: function () {
            return this.promotionWorkersHelper.dataItems;
        },
        enumerable: true,
        configurable: true
    });
    PromotionWorkersComponent.prototype.getServicesText = function (services) {
        if (services) {
            var length_1 = services.length;
            var text = this.translateService.instant('global.propose') + ' ' + length_1;
            var suffix = " " + this.translateService.instant('global.services');
            if (length_1 === 1) {
                suffix = " " + this.translateService.instant('global.service');
            }
            return text + suffix;
        }
        return '';
    };
    return PromotionWorkersComponent;
}());
export { PromotionWorkersComponent };
