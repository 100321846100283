import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {ActivityDto, AddressDto, Pageable, RequestDto, SearchCriteriaDto} from '../../core/models';
import {CommonUtils, HttpUtil} from '../../core/utils';

@Injectable({
    providedIn: 'root'
})
export class WorkerSearchCriteriaService {

    static DEFAULT_SEARCH_RADIUS = 100;

    constructor() {
    }

    buildFromRequest(request: RequestDto): SearchCriteriaDto {

        if (!request) {
            return null;
        }

        let sc = new SearchCriteriaDto();

        sc.address = request.address;
        sc.activity = request.activity;

        return sc;
    }

    buildFromParams(params: Params): SearchCriteriaDto {
        let searchCriteriaDto = this.buildFromParamsPartial(params);

        if (searchCriteriaDto && (searchCriteriaDto.activity || searchCriteriaDto.address)) {
            return searchCriteriaDto;
        }

        return null;
    }

    public buildFromParamsPartial(params: Params): SearchCriteriaDto {
        if (!params) {
            return null;
        }

        let latitude = +params['lat'];
        let longitude = +params['lng'];
        let activityId = +params['activityId'];

        let sc: SearchCriteriaDto;

        if (activityId) {
            sc = new SearchCriteriaDto();

            sc.activity = new ActivityDto();
            sc.activity.id = activityId;
            sc.activity.name = params['activityName'];
        }

        if (latitude && longitude) {

            if (!sc) {
                sc = new SearchCriteriaDto();
            }

            let address = new AddressDto();
            address.location = {
                latitude: latitude,
                longitude: longitude
            };
            address.formatted = params['address'];

            sc.address = address;
        }

        if (sc) {
            let radius = params['radius'];
            if (!radius) {
                radius = WorkerSearchCriteriaService.DEFAULT_SEARCH_RADIUS;
            }

            sc.radius = radius;
        }

        return sc;
    }

    public convertToParams(sc: SearchCriteriaDto, pageable: Pageable): Params {

        let params = this.convertSearchCriteriaToParams(sc);

        if (pageable) {
            CommonUtils.concatParams(params, this.convertPageableToParams(pageable));
        }

        return params;
    }

    public convertSearchCriteriaToParams(searchCriteria: SearchCriteriaDto): Params {

        let params = [];

        if (!searchCriteria) {
            return params;
        }

        if (searchCriteria.address && searchCriteria.address.location) {
            params['lat'] = searchCriteria.address.location.latitude;
            params['lng'] = searchCriteria.address.location.longitude;
            params['address'] = searchCriteria.address.formatted;
        }

        if (searchCriteria.activity) {
            params['activityId'] = searchCriteria.activity.id;
            params['activityName'] = searchCriteria.activity.name;
        }

        if (searchCriteria.radius) {
            params['radius'] = searchCriteria.radius;
        }

        return params;
    }

    public fillPageable(params: Params, pageable: Pageable): Pageable {
        pageable.page = +params['p'] ? +params['p'] - 1 : 0;
        pageable.sort = HttpUtil.stringToSortArray(params['sort']);

        return pageable;
    }

    public buildPageable(params: Params): Pageable {
        return this.fillPageable(params, new Pageable());
    }

    public convertPageableToParams(pageable: Pageable): Params {

        let params: Params = [];
        params['p'] = pageable.page;

        if (pageable.sort && pageable.sort.length) {
            params['sort'] = HttpUtil.sortArrayToString(pageable.sort);
        }

        return params;
    }

}
