import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as passwordResetActions from '../../modules/core/store/actions/password.reset.action';
import { SeoService } from '../../modules/core/services';
import { ErrorUtils } from '../../modules/core/utils/error.util';
import { BcValidators } from '../../modules/core/validators/bc.validators';
import { PasswordResetActionDto } from '../../modules/core/models/password-reset-action.dto';
import { PasswordTokenValidationDto } from '../../modules/core/models/password-token-validation.dto';
import * as fromRoot from '../../modules/store';
var PasswordResetComponent = /** @class */ (function () {
    function PasswordResetComponent(store, route, fb, seo) {
        this.store = store;
        this.route = route;
        this.fb = fb;
        this.seo = seo;
        this.passwordReset = fb.group({
            newPassword: ['', Validators.compose([Validators.required, BcValidators.password])],
            newPasswordConfirmation: ['', Validators.compose([Validators.required, BcValidators.password])]
        });
        this.passwordResetLoading$ = this.store.pipe(select(fromRoot.isPasswordResetLoading));
        this.passwordResetNotification$ = this.store.pipe(select(fromRoot.getPasswordResetNotification));
        this.isPasswordReseted$ = this.store.pipe(select(fromRoot.isPasswordReseted));
        this.isTokenValid$ = this.store.pipe(select(fromRoot.isPasswordResetTokenValid));
        this.tokenValidationLoading$ = this.store.pipe(select(fromRoot.isPasswordResetTokenValidationLoading));
        this.tokenValidationNotification$ = this.store.pipe(select(fromRoot.getResetPasswordTokenValidationErrors), map(ErrorUtils.toNotification));
        seo.setMeta('password-reset');
        seo.setRobots(true);
    }
    PasswordResetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.email = params.email;
            _this.token = params.token;
            var passwordTokenValidationDto = PasswordTokenValidationDto.build(_this.email, _this.token);
            _this.store.dispatch(new passwordResetActions.ValidateResetPasswordTokenAction(passwordTokenValidationDto));
        });
    };
    PasswordResetComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new passwordResetActions.ResetStateAction());
    };
    PasswordResetComponent.prototype.submit = function (form) {
        if (form.invalid) {
            return;
        }
        this.resetPassword();
    };
    PasswordResetComponent.prototype.resetPassword = function () {
        var passwordResetActionDto = PasswordResetActionDto.build(this.email, this.token, this.passwordReset.value);
        this.store.dispatch(new passwordResetActions.ResetPasswordAction(passwordResetActionDto));
    };
    Object.defineProperty(PasswordResetComponent.prototype, "newPassword", {
        get: function () {
            return this.passwordReset.get('newPassword');
        },
        enumerable: true,
        configurable: true
    });
    return PasswordResetComponent;
}());
export { PasswordResetComponent };
