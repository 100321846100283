import { ModuleWithProviders } from '@angular/core';
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    CoreModule.forRoot = function (configuredProviders) {
        return {
            ngModule: CoreModule,
            providers: configuredProviders
        };
    };
    return CoreModule;
}());
export { CoreModule };
