import {Action} from '@ngrx/store';
import {ActivityDto, AddressDto, Notification, RequestDto} from '../../../core/models';

export enum RequestEditActionTypes {
    LoadRequest = '[UPDATE REQUEST] LOAD_REQUEST',
    LoadRequestSuccess = '[UPDATE REQUEST] LOAD_REQUEST_SUCCESS',
    LoadRequestFail = '[UPDATE REQUEST] LOAD_REQUEST_FAIL',

    UpdateRequest = '[UPDATE REQUEST] UPDATE_REQUEST',
    UpdateRequestSuccess = '[UPDATE REQUEST] UPDATE_REQUEST_SUCCESS',
    UpdateRequestFail = '[UPDATE REQUEST] UPDATE_REQUEST_FAIL',
    ResetUpdated = '[REQUEST] RESET_UPDATED',

    DeleteRequest = '[REQUEST] DELETE_REQUEST',
    DeleteRequestSuccess = '[REQUEST] DELETE_REQUEST_SUCCESS',
    DeleteRequestFail = '[REQUEST] DELETE_REQUEST_FAIL',
    ResetDeleted = '[REQUEST] RESET_DELETED',

    CloseRequest = '[REQUEST] CLOSE_REQUEST',
    CloseRequestSuccess = '[REQUEST] CLOSE_REQUEST_SUCCESS',
    CloseRequestFail = '[REQUEST] CLOSE_REQUEST_FAIL',
    ResetClosed = '[REQUEST] RESET_CLOSED',

    SetSelectedRequest = '[UPDATE REQUEST] SET_SELECTED_REQUEST',
    SetActivity = '[UPDATE REQUEST] SET_ACTIVITY',
    SetAddress = '[UPDATE REQUEST] SET_ADDRESS',

    SetNotification = '[UPDATE REQUEST] SET_NOTIFICATION',
    Reset = '[UPDATE REQUEST] RESET',
    ResetRequest = '[UPDATE REQUEST] RESET_REQUEST'
}


export class ResetUpdatedAction implements Action {
    readonly type = RequestEditActionTypes.ResetUpdated;
}

export class ResetRequestAction implements Action {
    readonly type = RequestEditActionTypes.ResetRequest;
}

export class UpdateRequestAction implements Action {
    readonly type = RequestEditActionTypes.UpdateRequest;

    constructor(public payload: RequestDto) {
    }
}

export class LoadRequestAction implements Action {
    readonly type = RequestEditActionTypes.LoadRequest;

    constructor(public payload: number) {
    }
}

export class LoadRequestSuccessAction implements Action {
    readonly type = RequestEditActionTypes.LoadRequestSuccess;

    constructor(public payload: RequestDto) {
    }
}

export class SetActivityAction implements Action {
    readonly type = RequestEditActionTypes.SetActivity;

    constructor(public payload: ActivityDto) {
    }
}

export class SetAddressAction implements Action {
    readonly type = RequestEditActionTypes.SetAddress;

    constructor(public payload: AddressDto) {
    }
}


export class LoadRequestFailAction implements Action {
    readonly type = RequestEditActionTypes.LoadRequestFail;

    constructor(public payload: Notification) {
    }
}

export class UpdateRequestSuccessAction implements Action {
    readonly type = RequestEditActionTypes.UpdateRequestSuccess;
}

export class UpdateRequestFailAction implements Action {
    readonly type = RequestEditActionTypes.UpdateRequestFail;

    constructor(public payload: Notification) {
    }
}

export class SetSelectedRequestAction implements Action {
    readonly type = RequestEditActionTypes.SetSelectedRequest;

    constructor(public payload: RequestDto) {
    }
}

export class ResetAction implements Action {
    readonly type = RequestEditActionTypes.Reset;
}

export class SetNotificationAction implements Action {
    readonly type = RequestEditActionTypes.SetNotification;

    constructor(public payload: Notification) {
    }
}


export class DeleteRequestAction implements Action {
    readonly type = RequestEditActionTypes.DeleteRequest;

    constructor(public payload: number) {
    }
}

export class DeleteRequestSuccessAction implements Action {
    readonly type = RequestEditActionTypes.DeleteRequestSuccess;
}

export class DeleteRequestFailAction implements Action {
    readonly type = RequestEditActionTypes.DeleteRequestFail;

    constructor(public payload: Notification) {
    }
}

export class CloseRequestAction implements Action {
    readonly type = RequestEditActionTypes.CloseRequest;

    constructor(public payload: number) {
    }
}

export class CloseRequestSuccessAction implements Action {
    readonly type = RequestEditActionTypes.CloseRequestSuccess;
}

export class CloseRequestFailAction implements Action {
    readonly type = RequestEditActionTypes.CloseRequestFail;

    constructor(public payload: Notification) {
    }
}

export class ResetDeletedAction implements Action {
    readonly type = RequestEditActionTypes.ResetDeleted;
}

export class ResetClosedAction implements Action {
    readonly type = RequestEditActionTypes.ResetClosed;
}

export type Actions = UpdateRequestAction
    | UpdateRequestSuccessAction
    | UpdateRequestFailAction
    | LoadRequestAction
    | LoadRequestSuccessAction
    | LoadRequestFailAction
    | SetSelectedRequestAction
    | SetNotificationAction
    | ResetAction
    | DeleteRequestAction
    | DeleteRequestSuccessAction
    | DeleteRequestFailAction
    | CloseRequestAction
    | CloseRequestSuccessAction
    | CloseRequestFailAction
    | ResetDeletedAction
    | ResetClosedAction
    | SetAddressAction
    | SetActivityAction
    | ResetRequestAction
    | ResetUpdatedAction
    ;
