import {Component} from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

}
