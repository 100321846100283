import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../../../../config';
import { BasicUserDto, Notification, SendMessageDto } from '../../../core/models';
var SendMessageComponent = /** @class */ (function () {
    function SendMessageComponent(cdRef) {
        this.cdRef = cdRef;
        this.cancel = new EventEmitter();
        this.send = new EventEmitter();
        this.sendMessage = new SendMessageDto();
    }
    SendMessageComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.init$) {
            this.init$.subscribe(function () {
                _this.sendMessage.message = '';
                _this.cdRef.detectChanges();
                if (_this.messageInput) {
                    if (Config.IS_WEB()) {
                        _this.messageInput.nativeElement.focus();
                    }
                }
            });
        }
    };
    SendMessageComponent.prototype.doCancel = function () {
        this.cancel.emit();
    };
    SendMessageComponent.prototype.doSubmit = function () {
        // this.sendMessage.senderUid = this.sender.uid;
        this.sendMessage.receiverUid = this.receiver.uid;
        this.send.emit(this.sendMessage);
    };
    return SendMessageComponent;
}());
export { SendMessageComponent };
