import { SeoService } from '../../../modules/core/services';
var CookieUsageComponent = /** @class */ (function () {
    function CookieUsageComponent(seo) {
        this.seo = seo;
        seo.setMeta('cookies-usage');
        seo.setRobots(true);
    }
    return CookieUsageComponent;
}());
export { CookieUsageComponent };
