import {Pipe, PipeTransform} from '@angular/core';
import {ActivityDto} from '../../core/models';
import {ActivityTranslatorService} from '../../core/services/activity-translator.service';

@Pipe({
    name: 'activityTranslator'
})
export class ActivityTranslatorPipe implements PipeTransform {

    constructor(private activityTranslator: ActivityTranslatorService) {
    }

    transform(value: ActivityDto | Array<ActivityDto>, args?: any): any {
        if (!value) {
            return null;
        }

        if (value instanceof Array) {
            return this.activityTranslator.translateArray(value);
        } else {
            return this.activityTranslator.translate(value);
        }

    }

}
