// angular
import { HttpResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
// libs
import { of, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorsDto, Locale } from '../../core/models';
import { StorageKey } from '../../core/models/interfaces';
var BriCoolHttpInterceptor = /** @class */ (function () {
    function BriCoolHttpInterceptor(injector) {
        this.injector = injector;
        this.timeout = 30000;
        this.request = injector.get(REQUEST, null);
        this.translateService = injector.get(TranslateService);
        this.transferState = injector.get(TransferState);
    }
    BriCoolHttpInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        if (request.method === 'GET') {
            var stateKey = makeStateKey(request.url);
            var storedResponse = this.transferState.get(stateKey, null);
            if (storedResponse) {
                var response = new HttpResponse({ body: storedResponse, status: 200 });
                this.transferState.remove(stateKey);
                return of(response);
            }
        }
        var httpHeaders = request.headers
            .set('Content-Type', 'application/json')
            .set('Accept-Language', this.lang)
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
            .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
        if (!httpHeaders.has('Accept')) {
            httpHeaders = httpHeaders.set('Accept', 'application/json');
        }
        var token = null;
        var serverReq = this.injector.get(REQUEST, null);
        if (serverReq && serverReq.cookies) {
            token = serverReq.cookies[StorageKey.JWT];
            if (token) {
                httpHeaders = httpHeaders.set('Authorization', "Bearer " + token);
            }
        }
        request = request.clone({
            url: this.apiUrl + request.url,
            headers: httpHeaders
        });
        return next.handle(request).pipe(timeout(this.timeout), catchError(function (error) { return _this.handleError(error); }));
    };
    Object.defineProperty(BriCoolHttpInterceptor.prototype, "lang", {
        get: function () {
            var currentLang = this.translateService.currentLang;
            if (!currentLang) {
                currentLang = Locale.FRENCH.shortcut;
            }
            return currentLang;
        },
        enumerable: true,
        configurable: true
    });
    BriCoolHttpInterceptor.prototype.buildGlobalError = function () {
        var error = new ErrorsDto();
        error.code = '500';
        error.message = 'Une erreur est survenue';
        return error;
    };
    Object.defineProperty(BriCoolHttpInterceptor.prototype, "apiUrl", {
        get: function () {
            var newUrl = environment.apiUrl;
            if (this.request) {
                newUrl = this.getRequestProtocol() + "://" + this.request.get('host') + "/";
            }
            return newUrl;
        },
        enumerable: true,
        configurable: true
    });
    BriCoolHttpInterceptor.prototype.getRequestProtocol = function () {
        var protocol = 'http';
        if (this.request && this.request.headers) {
            var host = this.request.headers['X-Forwarded-Proto'];
            if (!host) {
                host = this.request.get('host');
            }
            if (host && (host.startsWith('localhost') || host.startsWith('bc.dev'))) {
                protocol = 'http';
            }
        }
        return protocol;
    };
    BriCoolHttpInterceptor.prototype.handleError = function (error) {
        if (!environment.production) {
            console.error("Error happened :");
            console.log(JSON.stringify(error));
        }
        var errorsDto = this.buildGlobalError();
        if (!error.status) {
            return throwError(errorsDto);
        }
        else {
            try {
                var err = error.error;
                if (err && err.code) {
                    errorsDto = error.error;
                }
            }
            catch (e) {
                // ignore
            }
            finally {
                if (!errorsDto) {
                    errorsDto = this.buildGlobalError();
                }
            }
        }
        return throwError(errorsDto);
    };
    return BriCoolHttpInterceptor;
}());
export { BriCoolHttpInterceptor };
