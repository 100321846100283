import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as passwordRecoverActions from '../../modules/core/store/actions/password.recover.action';
import { BrowserService, ParamsService, SeoService } from '../../modules/core/services';
import { BcValidators } from '../../modules/core/validators';
import * as fromRoot from '../../modules/store';
var PasswordForgottenComponent = /** @class */ (function () {
    function PasswordForgottenComponent(store, route, paramsService, fb, browserService, seo) {
        this.store = store;
        this.route = route;
        this.paramsService = paramsService;
        this.fb = fb;
        this.browserService = browserService;
        this.seo = seo;
        this.loading$ = this.store.pipe(select(fromRoot.isPasswordRecoverLoading));
        this.success$ = this.store.pipe(select(fromRoot.isPasswordRecoverSuccess));
        this.notification$ = this.store.pipe(select(fromRoot.getPasswordRecoverNotification));
        this.email = fb.control('', Validators.compose([Validators.required, BcValidators.login]));
        seo.setMeta('password-forgotten');
        seo.setRobots(true);
    }
    PasswordForgottenComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.email.setValue(_this.paramsService.buildPasswordForgottenFormParams(params));
            _this.browserService.materialize();
        });
        this.browserService.materialize();
    };
    PasswordForgottenComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new passwordRecoverActions.ResetAction());
    };
    PasswordForgottenComponent.prototype.submit = function (form) {
        if (form.invalid) {
            return;
        }
        this.recover();
    };
    PasswordForgottenComponent.prototype.recover = function () {
        this.store.dispatch(new passwordRecoverActions.RecoverPasswordAction(this.email.value));
    };
    return PasswordForgottenComponent;
}());
export { PasswordForgottenComponent };
