import { ElementRef, OnInit, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as fromRoot from '../../store';
var LayoutClassDirective = /** @class */ (function () {
    function LayoutClassDirective(elRef, renderer, store) {
        this.elRef = elRef;
        this.renderer = renderer;
        this.store = store;
    }
    LayoutClassDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.store.pipe(select(fromRoot.getLocale), filter(function (loc) { return loc != null; })).subscribe(function (locale) {
            if (locale.rtl) {
                _this.renderer.addClass(_this.elRef.nativeElement, 'rtl');
            }
            else {
                _this.renderer.removeClass(_this.elRef.nativeElement, 'rtl');
            }
        });
    };
    return LayoutClassDirective;
}());
export { LayoutClassDirective };
