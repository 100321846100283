/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./review-report-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../modal/modal.component.ngfactory";
import * as i3 from "../modal/modal.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../shared/components/reviews/web/review-report/review-report.component.ngfactory";
import * as i6 from "../../../../shared/components/reviews/web/review-report/review-report.component";
import * as i7 from "@angular/common";
import * as i8 from "./review-report-modal.component";
import * as i9 from "@ngrx/store";
var styles_ReportReviewModalComponent = [i0.styles];
var RenderType_ReportReviewModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportReviewModalComponent, data: {} });
export { RenderType_ReportReviewModalComponent as RenderType_ReportReviewModalComponent };
export function View_ReportReviewModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "app-modal", [["modalClass", "bottom-sheet-modal-height bottom-sheet"], ["modalId", "report-review"]], null, [[null, "ready"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ready" === en)) {
        var pd_0 = (_co.initListener$.next() !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.closeModal() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ModalComponent_0, i2.RenderType_ModalComponent)), i1.ɵdid(1, 180224, null, 0, i3.ModalComponent, [], { modalId: [0, "modalId"], modalClass: [1, "modalClass"], modalEventListener: [2, "modalEventListener"] }, { ready: "ready", close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "h1", [["class", "col px-0 my-0"], ["modal-header", ""], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["reports.reviews.title"])), (_l()(), i1.ɵeld(5, 0, null, 1, 3, "app-report-review", [["modal-content", ""]], null, [[null, "cancel"], [null, "report"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancel" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } if (("report" === en)) {
        var pd_1 = (_co.report($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_ReportReviewComponent_0, i5.RenderType_ReportReviewComponent)), i1.ɵdid(6, 114688, null, 0, i6.ReportReviewComponent, [], { loading$: [0, "loading$"], notification: [1, "notification"], review: [2, "review"], initListener$: [3, "initListener$"] }, { cancel: "cancel", report: "report" }), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "report-review"; var currVal_1 = "bottom-sheet-modal-height bottom-sheet"; var currVal_2 = _co.modalEvent$; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = ""; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.loading$; var currVal_5 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 7).transform(_co.notification$)); var currVal_6 = i1.ɵunv(_v, 6, 2, i1.ɵnov(_v, 8).transform(_co.reviewToReport$)); var currVal_7 = _co.initListener$; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ReportReviewModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-report-review-modal", [], null, null, null, View_ReportReviewModalComponent_0, RenderType_ReportReviewModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.ReportReviewModalComponent, [i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportReviewModalComponentNgFactory = i1.ɵccf("app-report-review-modal", i8.ReportReviewModalComponent, View_ReportReviewModalComponent_Host_0, {}, {}, []);
export { ReportReviewModalComponentNgFactory as ReportReviewModalComponentNgFactory };
