<div class="container-fluid sub-header" yfLayoutClass>
    <div class="container">
        <h1 class="white-text h4 center-sm-down" translate>cgu.title</h1>
    </div>
</div>

<div class="container" yfLayoutClass>
    <div class="card">
        <div class="card-block">
            <p translate>cgu.intro</p>

            <div class="section-header">
                <span translate>global.object</span>
            </div>
            <p translate>
                cgu.object.body1 <br/>
                cgu.object.body2 <br/>
                cgu.object.body3
            </p>

            <div class="section-header">
                <span translate>cgu.usage.title</span>
            </div>
            <p translate>cgu.usage.body</p>

            <div class="section-header">
                <span translate>cgu.description.title</span>
            </div>
            <p translate>
                cgu.description.body1<br/>

                cgu.description.body2
            </p>

            <div class="section-header">
                <span translate>global.services</span>
            </div>
            <p translate>cgu.services.body</p>

            <div class="section-header">
                <span translate>cgu.responsibility.title</span>
            </div>
            <p translate>
                cgu.responsibility.body1<br/>
                cgu.responsibility.body2<br/>
                cgu.responsibility.body3<br/>
                cgu.responsibility.body4
            </p>

            <div class="row">
                <div class="col-12">
                    <ul>
                        <li translate>cgu.responsibility.list.entry1</li>
                        <li translate>cgu.responsibility.list.entry2</li>
                        <li translate>cgu.responsibility.list.entry3</li>
                        <li translate>cgu.responsibility.list.entry4</li>
                        <li translate>cgu.responsibility.list.entry5</li>
                    </ul>
                </div>
            </div>


            <div class="section-header">
                <span translate>cgu.content.title</span>
            </div>
            <p translate>
                cgu.content.body1<br/>
                cgu.content.body2
            </p>

            <div class="row mb-0">
                <div class="col-12">
                    <ul>
                        <li translate>cgu.content.list.entry1</li>
                        <li translate>cgu.content.list.entry2</li>
                        <li translate>cgu.content.list.entry3</li>
                        <li translate>cgu.content.list.entry4</li>
                    </ul>
                </div>
            </div>

            <p translate>cgu.content.body3</p>

            <div class="section-header">
                <span translate>cgu.access.title</span>
            </div>
            <p translate>
                cgu.access.body1<br/>
                cgu.access.body2<br/>
                cgu.access.body3
            </p>

            <div class="section-header">
                <span translate>cgu.privacy.title</span>
            </div>
            <p translate>cgu.privacy.body</p>

            <div class="section-header">
                <span translate>cgu.cookies.title</span>
            </div>
            <p translate>cgu.cookies.body</p>

            <div class="row mb-0">
                <div class="col-12">
                    <ul>
                        <li translate>cgu.cookies.list.entry1</li>
                        <li translate>cgu.cookies.list.entry2</li>
                        <li translate>cgu.cookies.list.entry3</li>
                        <li translate>cgu.cookies.list.entry4</li>
                    </ul>
                </div>
            </div>
            <div class="section-header">
                <span translate>cgu.legal-references.title</span>
            </div>

            <p translate>cgu.legal-references.body</p>

            <div class="row mb-0">
                <div class="col-12">
                    <ul>
                        <li translate>cgu.legal-references.list.entry1</li>
                        <li translate>cgu.legal-references.list.entry2</li>
                        <li translate>cgu.legal-references.list.entry3</li>
                        <li translate>cgu.legal-references.list.entry4</li>
                        <li translate>cgu.legal-references.list.entry5</li>
                        <li translate>cgu.legal-references.list.entry6</li>
                        <li translate>cgu.legal-references.list.entry7</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>

</div>
