import {Action} from '@ngrx/store';
import {ActivityDto, CategoryDto} from '../../models';

export enum SearchActivityChooserActionTypes {
    SelectActivity = '[SEARCH ACTIVITY CHOOSER] SELECT_ACTIVITY',
    SelectCategory = '[SEARCH ACTIVITY CHOOSER] SELECT_CATEGORY',

    SetActivities = '[SEARCH ACTIVITY CHOOSER] SET_ACTIVITIES',

    SelectOrDeselectActivity = '[SEARCH ACTIVITY CHOOSER] SELECT_OR_DESELECT_ACTIVITY',

    SetSelectedActivities = '[SEARCH ACTIVITY CHOOSER] SET_SELECTED_ACTIVITIES',
    SetSelectedCategories = '[SEARCH ACTIVITY CHOOSER] SET_SELECTED_CATEGORIES',

    Reset = '[SEARCH ACTIVITY CHOOSER] RESET',
    Init = '[SEARCH ACTIVITY CHOOSER] INIT'
}


export class SelectActivityAction implements Action {
    readonly type = SearchActivityChooserActionTypes.SelectActivity;

    constructor(public payload: ActivityDto) {
    }
}

export class SelectCategoryAction implements Action {
    readonly type = SearchActivityChooserActionTypes.SelectCategory;

    constructor(public payload: CategoryDto) {
    }
}

export class SelectOrDeselectActivityAction implements Action {
    readonly type = SearchActivityChooserActionTypes.SelectOrDeselectActivity;

    constructor(public payload: ActivityDto) {
    }
}

export class SetActivitiesAction implements Action {
    readonly type = SearchActivityChooserActionTypes.SetActivities;

    constructor(public payload: ActivityDto[]) {
    }
}

export class SetSelectedActivitiesAction implements Action {
    readonly type = SearchActivityChooserActionTypes.SetSelectedActivities;

    constructor(public payload: ActivityDto[]) {
    }
}

export class SetSelectedCategoriesAction implements Action {
    readonly type = SearchActivityChooserActionTypes.SetSelectedCategories;

    constructor(public payload: CategoryDto[]) {
    }
}

export class InitAction implements Action {
    readonly type = SearchActivityChooserActionTypes.Init;
}

export class ResetAction implements Action {
    readonly type = SearchActivityChooserActionTypes.Reset;
}

export type Actions = SelectActivityAction
    | SelectCategoryAction
    | SelectOrDeselectActivityAction
    | SetActivitiesAction
    | SetSelectedActivitiesAction
    | SetSelectedCategoriesAction
    | InitAction
    | ResetAction;
