import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
var QuickSearchLinksComponent = /** @class */ (function () {
    function QuickSearchLinksComponent(translateService) {
        this.translateService = translateService;
        this.casaLinksKeys = [];
        this.agadirLinksKeys = [];
        this.rabatLinksKeys = [];
    }
    QuickSearchLinksComponent.prototype.ngOnInit = function () {
        this.casaLinksKeys = this.translateService.instant('quick-search-links.casablanca');
        this.agadirLinksKeys = this.translateService.instant('quick-search-links.agadir');
        this.rabatLinksKeys = this.translateService.instant('quick-search-links.rabat');
    };
    return QuickSearchLinksComponent;
}());
export { QuickSearchLinksComponent };
