import { ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
var LoginInputComponent = /** @class */ (function () {
    function LoginInputComponent() {
        this.required = true;
        this.isPhoneInput = true;
    }
    Object.defineProperty(LoginInputComponent.prototype, "parentFormGroup", {
        set: function (formGroup) {
            var _this = this;
            this._parentFormGroup = formGroup;
            this._parentFormGroup.get(this.controlName).valueChanges.subscribe(function (value) {
                _this.updateFormType(value);
            });
        },
        enumerable: true,
        configurable: true
    });
    LoginInputComponent.prototype.updateFormType = function (value) {
        this.isPhoneInput = !(value && value.indexOf('@') !== -1);
        // this.inputRef.nativeElement.focus();
    };
    LoginInputComponent.prototype.setValue = function (_value) {
        this._parentFormGroup.get(this.controlName).setValue(_value);
    };
    return LoginInputComponent;
}());
export { LoginInputComponent };
