import { HttpParams } from '@angular/common/http';
// app
import { Sort, SortDirection } from '../models';
var HttpUtil = /** @class */ (function () {
    function HttpUtil() {
    }
    HttpUtil.parseUrl = function (url) {
        // for angular
        if (url.indexOf('/#/') > -1) {
            url = url.replace('/#/', '/');
        }
        var reURLInformation = new RegExp([
            '^(https?:)//',
            '(([^:/?#]*)(?::([0-9]+))?)',
            '(/{0,1}[^?#]*)',
            '(\\?[^#]*|)',
            '(#.*|)$' // hash
        ].join(''));
        var match = url.match(reURLInformation);
        return match && {
            url: url,
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7]
        };
    };
    HttpUtil.buildAndExpand = function (uri) {
        var uriVariables = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            uriVariables[_i - 1] = arguments[_i];
        }
        if (uri == null) {
            return null;
        }
        if (uri.indexOf('{') === -1) {
            return uri;
        }
        var found = this.NAMES_PATTERN.exec(uri);
        var index = 0;
        while (found) {
            if (index >= uriVariables.length) {
                throw new TypeError('Cannot build and expand uri. URI[' + uri + '] and uriVariables[' + uriVariables.join(',') + ']');
            }
            uri = uri.replace(found[0], uriVariables[index]);
            found = this.NAMES_PATTERN.exec(uri);
            index++;
        }
        return uri;
    };
    HttpUtil.appendQueryParam = function (options, param, value) {
        if (!param || !value) {
            return null;
        }
        if (!options.params) {
            options.params = new HttpParams();
        }
        options.params = options.params.append(param, value.toString());
        return options;
    };
    HttpUtil.appendQueryParams = function (options, params) {
        if (!params || !options) {
            return null;
        }
        if (!options.params) {
            options.params = new HttpParams();
        }
        Object.keys(params).forEach(function (k) {
            if (params[k]) {
                options.params = options.params.append(k, params[k].toString());
            }
        });
        return options;
    };
    HttpUtil.sortArrayToString = function (sort) {
        if (!sort || !sort.length) {
            return null;
        }
        var sortString = HttpUtil.sortToString(sort[0]);
        for (var i = 1; i < sort.length; ++i) {
            sortString += '&' + HttpUtil.sortToString(sort[i]);
        }
        return sortString;
    };
    HttpUtil.sortToString = function (sort) {
        return sort.property + ',' + sort.direction;
    };
    HttpUtil.stringToSortArray = function (value) {
        if (!value) {
            return [];
        }
        var sorts = value.split('&');
        if (!sorts || !sorts.length) {
            return [];
        }
        return sorts.map(this.stringToSort);
    };
    HttpUtil.stringToSort = function (value) {
        var strings = value.split(',');
        var prop = strings[0];
        var direction = strings[1] === SortDirection.ASC ? SortDirection.ASC : SortDirection.DESC;
        return new Sort(direction, prop);
    };
    HttpUtil.buildPageableOptions = function (pageable) {
        var _this = this;
        var requestOptions = {};
        var page = pageable.page > 0 ? pageable.page - 1 : 0;
        HttpUtil.appendQueryParam(requestOptions, 'page', page); // 0-based
        HttpUtil.appendQueryParam(requestOptions, 'size', pageable.size);
        if (pageable.sort) {
            pageable.sort.forEach(function (s) { return HttpUtil.appendQueryParam(requestOptions, 'sort', _this.sortToString(s)); });
        }
        return requestOptions;
    };
    HttpUtil.NAMES_PATTERN = new RegExp('\\{([^/]+?)\\}');
    return HttpUtil;
}());
export { HttpUtil };
