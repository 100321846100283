import {Action} from '@ngrx/store';
import {ErrorsDto} from '../../../core/models';

export enum AccountVerificationActionTypes {
    SubmitPhoneVerification = '[ACCOUNT_VERIFICATION] SubmitPhoneVerification',
    SubmitPhoneVerificationSuccess = '[ACCOUNT_VERIFICATION] SubmitPhoneVerificationSuccess',
    SubmitPhoneVerificationFail = '[ACCOUNT_VERIFICATION] SubmitPhoneVerificationFail',
    ResetPhoneVerification = '[ACCOUNT_VERIFICATION] ResetPhoneVerification',
    VerifyPhoneNumber = '[ACCOUNT_VERIFICATION] VerifyPhoneNumber',
    VerifyPhoneNumberSuccess = '[ACCOUNT_VERIFICATION] VerifyPhoneNumberSuccess',
    VerifyPhoneNumberFail = '[ACCOUNT_VERIFICATION] VerifyPhoneNumberFail',

    VerifyEmail = '[ACCOUNT_VERIFICATION] VerifyEmail',
    VerifyEmailSuccess = '[ACCOUNT_VERIFICATION] VerifyEmailSuccess',
    VerifyEmailFail = '[ACCOUNT_VERIFICATION] VerifyEmailFail',
    ResetEmailVerification = '[ACCOUNT_VERIFICATION] ResetEmailVerification'
}

export class SubmitPhoneVerification implements Action {
    readonly type = AccountVerificationActionTypes.SubmitPhoneVerification;
}

export class SubmitPhoneVerificationSuccess implements Action {
    readonly type = AccountVerificationActionTypes.SubmitPhoneVerificationSuccess;
}

export class SubmitPhoneVerificationFail implements Action {
    readonly type = AccountVerificationActionTypes.SubmitPhoneVerificationFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class ResetPhoneVerification implements Action {
    readonly type = AccountVerificationActionTypes.ResetPhoneVerification;
}

export class VerifyPhoneNumberAction implements Action {
    readonly type = AccountVerificationActionTypes.VerifyPhoneNumber;

    constructor(public payload: string) {
    }
}

export class VerifyPhoneNumberSuccessAction implements Action {
    readonly type = AccountVerificationActionTypes.VerifyPhoneNumberSuccess;
}

export class VerifyPhoneNumberFailAction implements Action {
    readonly type = AccountVerificationActionTypes.VerifyPhoneNumberFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class VerifyEmailAction implements Action {
    readonly type = AccountVerificationActionTypes.VerifyEmail;
}

export class VerifyEmailSuccessAction implements Action {
    readonly type = AccountVerificationActionTypes.VerifyEmailSuccess;
}

export class VerifyEmailFailAction implements Action {
    readonly type = AccountVerificationActionTypes.VerifyEmailFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class ResetEmailVerification implements Action {
    readonly type = AccountVerificationActionTypes.ResetEmailVerification;
}

export type Actions = SubmitPhoneVerification
    | SubmitPhoneVerificationSuccess
    | SubmitPhoneVerificationFail
    | ResetPhoneVerification
    | VerifyPhoneNumberAction
    | VerifyPhoneNumberSuccessAction
    | VerifyPhoneNumberFailAction
    | VerifyEmailAction
    | VerifyEmailSuccessAction
    | VerifyEmailFailAction
    | ResetEmailVerification
    ;
