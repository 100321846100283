import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Page} from '../../../core/models';

@Component({
    moduleId: module.id,
    selector: 'app-mobile-pagination',
    templateUrl: './mobile-pagination.component.html',
    styleUrls: ['./mobile-pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilePaginationComponent {

    @Input() page: Page<any>;
    @Input() previousLabel = 'Précédent';
    @Input() nextLabel: 'Suivant';

    @Output() selectPage = new EventEmitter<number>();

    loadPage(pageNum: number) {
        this.selectPage.emit(pageNum);
    }

}
