import { OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { ErrorUtils } from '../../../../core/utils';
import * as fromRoot from '../../../../store';
import * as messageModalActions from '../../../../core/store/actions/message.modal.action';
import * as sendMessageActions from '../../../../core/store/actions/send-message.action';
var SendMessageModalComponent = /** @class */ (function () {
    function SendMessageModalComponent(store) {
        this.store = store;
        this.sendMessageInitListener$ = new Subject();
        this.messageModalEvent$ = this.store.pipe(select(fromRoot.getMessageModalEvent));
        this.sender$ = this.store.pipe(select(fromRoot.getSendMessageSender));
        this.receiver$ = this.store.pipe(select(fromRoot.getSendMessageReceiver));
        this.loading$ = this.store.pipe(select(fromRoot.isSendMessageLoading));
        this.notification$ = this.store.pipe(select(fromRoot.getSendMessageErrors), map(ErrorUtils.toNotification));
    }
    SendMessageModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading$.pipe(withLatestFrom(this.notification$))
            .subscribe(function (_a) {
            var loading = _a[0], errors = _a[1];
            if (_this.sending && !loading && !errors) {
                _this.closeModal();
                _this.sending = false;
            }
        });
    };
    SendMessageModalComponent.prototype.send = function (sendMessageDto) {
        this.sending = true;
        this.store.dispatch(new sendMessageActions.SendMessageAction(sendMessageDto));
    };
    SendMessageModalComponent.prototype.closeModal = function () {
        this.dispatchCloseModalAction();
    };
    SendMessageModalComponent.prototype.ready = function () {
        this.sendMessageInitListener$.next();
    };
    SendMessageModalComponent.prototype.dispatchCloseModalAction = function () {
        this.store.dispatch(new messageModalActions.CloseMessageModalAction());
    };
    return SendMessageModalComponent;
}());
export { SendMessageModalComponent };
