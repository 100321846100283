
export interface MaterializeAutoCompleteOptions {
    data: any;
    limit: number;
    minLength: number;
}

export class MaterializeAutoCompleteOptionsImpl implements MaterializeAutoCompleteOptions {
    data: any;
    minLength = 1;
    limit = Infinity;

}

