import { ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
var HomeHelper = /** @class */ (function () {
    function HomeHelper() {
    }
    HomeHelper.prototype.hideActionBar = function () {
    };
    HomeHelper.prototype.showServiceDialog = function (vcRef) {
        return new Observable();
    };
    HomeHelper.prototype.navigate = function (params) {
    };
    return HomeHelper;
}());
export { HomeHelper };
