import {ProfileDto, RequestDto} from '../../models';

import {shareActions} from '../actions';

export interface State {
    element: ProfileDto | RequestDto
}

export const initialState: State = {
    element: null
};

export function reducer(state = initialState, action: shareActions.Actions): State {

    switch (action.type) {

        case shareActions.ShareActionsTypes.Share: {
            return Object.assign(
                {},
                state,
                {
                    element: action.payload
                }
            )
        }

        case shareActions.ShareActionsTypes.Clear: {
            return Object.assign(
                {},
                state,
                {
                    element: null
                }
            )
        }

        default:
            return state;
    }
}

export const getElement = (state: State): ProfileDto | RequestDto => state.element;
