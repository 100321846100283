import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SearchCriteriaDto } from '../../../../core/models';
import { BrowserService } from '../../../../core/services';
import * as fromRoot from '../../../../store';
var PersonSearchfacetsComponent = /** @class */ (function () {
    function PersonSearchfacetsComponent(store, browserService, cdRef) {
        this.store = store;
        this.browserService = browserService;
        this.cdRef = cdRef;
        this.categories = [];
        this.filter = new EventEmitter();
        this.categories$ = this.store.pipe(select(fromRoot.getCategories));
    }
    PersonSearchfacetsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.browserService.materialize();
        if (!this.searchCriteria) {
            this.searchCriteria = new SearchCriteriaDto();
            this.searchCriteria.radius = 100;
        }
        this.categories$.subscribe(function (cs) { return _this.categories = cs; });
    };
    PersonSearchfacetsComponent.prototype.selectService = function (act) {
        this.searchCriteria.activity = act;
        this.cdRef.detectChanges();
    };
    PersonSearchfacetsComponent.prototype.apply = function (form) {
        if (form.invalid) {
            return;
        }
        this.filter.emit(this.searchCriteria);
    };
    PersonSearchfacetsComponent.prototype.reInit = function () {
        this.searchCriteria.radius = null;
        this.filter.emit(this.searchCriteria);
    };
    PersonSearchfacetsComponent.prototype.deleteService = function () {
        this.searchCriteria.activity = null;
    };
    return PersonSearchfacetsComponent;
}());
export { PersonSearchfacetsComponent };
