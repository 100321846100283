import {ErrorDto, ErrorsDto, Notification, NotificationType} from '../models';

export class ErrorUtils {

    public static buildValidationErrorsDto(): ErrorsDto {
        let errors = new ErrorsDto();
        errors.code = '4000';
        errors.message = 'Erreur de validation';
        errors.errors = [];

        return errors;
    }

    public static buildFormValidationErrorsDto(): ErrorsDto {
        let errors = this.buildValidationErrorsDto();
        this.addError(errors, 'Veuillez corriger les champs en erreur');

        return errors;
    }

    public static buildErrorsDto(message: string): ErrorsDto {
        let errors = new ErrorsDto();
        errors.message = message;

        return errors;
    }

    public static addError(errors: ErrorsDto, errorMsg: string) {
        let err = new ErrorDto();
        err.message = errorMsg;

        if (!errors.errors) {
            errors.errors = [];
        }

        errors.errors.push(err);
    }

    public static buildErrorDto(errorMsg: string): ErrorDto {
        let err = new ErrorDto();
        err.message = errorMsg;

        return err;
    }

    public static toNotification(errors: ErrorsDto): Notification {
        if (!errors) {
            return null;
        }

        let notification = new Notification();
        notification.type = NotificationType.ERROR;
        notification.title = errors.message;
        notification.detail = errors.detail;

        if (errors.errors) {
            errors.errors.forEach(err => notification.addMessage(err.message));
        }

        return notification;
    }

    public static toNotificationWithType(errors: ErrorsDto, notificationType: NotificationType): Notification {
        let notif = this.toNotification(errors);
        notif.type = notificationType;

        return notif;
    }

    public static hasDetail(errors: ErrorsDto, detail: string): boolean {
        if (!errors) {
            return false;
        }

        if (errors.detail === detail) {
            return true;
        }

        if (!errors.errors) {
            return false;
        }

        let found = errors.errors.find(e => e.detail === detail);

        return found != null;
    }

}
