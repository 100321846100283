import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {
    BasicUserDto,
    CategoryDto,
    DisplayReviewDto,
    Notification,
    UserResultDto,
    UserViewDto
} from '../../../core/models';
import {SeoService} from '../../../core/services';
import {OpenGraphService} from '../../../core/services/open-graph.service';
import {CommonUtils, ErrorUtils, UserProfileUtils, CategoryUtils} from '../../../core/utils';
import * as reviewActions from '../../../core/store/actions/review.action';

import * as fromRoot from '../../../store';
import * as userActions from '../../store/actions/user.view';
import {ProfileViewHelper} from './helper/profile-view.helper';

@Component({
    moduleId: module.id,
    templateUrl: './profile-view.component.html',
    styleUrls: ['./profile-view.component.scss'],
    providers: [ProfileViewHelper]
})
export class ProfileViewComponent implements OnInit, OnDestroy {

    private subscription = new Subscription();

    titleKey = 'global.profile';
    param = {};

    loading$: Observable<boolean>;
    notification$: Observable<Notification>;
    userProfile$: Observable<UserViewDto>;

    userProfile: UserViewDto;

    reviews$: Observable<DisplayReviewDto[]>;
    userReviewsNotification$: Observable<Notification>;

    currentUser$: Observable<BasicUserDto>;
    currentUser: BasicUserDto;
    userUid: string;
    UserProfileUtils = UserProfileUtils;
    profileCategories: CategoryDto[];

    route: ActivatedRoute;
    translateService: TranslateService;
    seo: SeoService;
    ogService: OpenGraphService;
    profileViewHelper: ProfileViewHelper;

    constructor(protected store: Store<fromRoot.State>,
                private injector: Injector) {

        this.route = this.injector.get(ActivatedRoute);
        this.translateService = this.injector.get(TranslateService);
        this.seo = this.injector.get(SeoService);
        this.ogService = this.injector.get(OpenGraphService);
        this.profileViewHelper = this.injector.get(ProfileViewHelper);

        this.currentUser$ = this.store.pipe(select(fromRoot.getCurrentUser));
        this.loading$ = this.store.pipe(select(fromRoot.isUserViewProfileLoading));
        this.notification$ = this.store.pipe(
            select(fromRoot.getUserViewProfileErrors),
            map(ErrorUtils.toNotification)
        );
        this.userReviewsNotification$ = this.store.pipe(
            select(fromRoot.getReviewsErrors),
            map(ErrorUtils.toNotification)
        );
        this.userProfile$ = this.store.pipe(select(fromRoot.getUserViewProfile));
        this.reviews$ = this.store.pipe(select(fromRoot.getReviews));

        this.seo.setRobots(true);
    }

    ngOnInit(): void {
        this.profileViewHelper.hideActionBar();

        this.route.params.subscribe((params: { uid: string }) => {
            if (params.uid) {
                this.userUid = params.uid;
                this.store.dispatch(new userActions.LoadUserViewProfileAction(this.userUid));
                this.loadUserReviews();

                this.store.dispatch(new userActions.AddUserProfileViewAction(params.uid));
            }
        });

        let upSubscription = this.userProfile$.pipe(
            filter(up => CommonUtils.notEmpty(up))
        ).subscribe(up => {
            this.userProfile = up;
            this.titleKey = 'global.profile-of';
            this.param = {name: up.firstName};
            this.profileCategories = CategoryUtils.toCategoriesTree(up.activities);

            this.seo.setUserViewMeta(up);
        });

        let cuSubscription = this.currentUser$.subscribe(cu => this.currentUser = cu);

        this.subscription.add(upSubscription);
        this.subscription.add(cuSubscription);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    showMoreActions() {
        this.profileViewHelper.showMoreActions(this.userProfile);
    }

    loadUserReviews() {
        this.store.dispatch(new reviewActions.LoadUserReviewsAction(this.userUid));
    }

    getPercentage() {
        return this.UserProfileUtils.calculatePercentage(this.userProfile);
    }

    getFullName() {
        return this.userProfile.firstName + ' ' + this.userProfile.lastName;
    }

    getUserReviews(user: UserResultDto) {
        if (!user || !user.reviewsAverage) {
            return 0;
        }

        return user.reviewsAverage + 1;
    }
}
