import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';

import {ActivityDto, CategoryDto, ModalEvent} from '../../../../core/models';

@Component({
    selector: 'app-activity-chooser-modal',
    templateUrl: './service-chooser-modal.component.html',
    styleUrls: ['./service-chooser-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityChooserModalComponent {

    @Input() modalId: string;
    @Input() modalClass = 'bottom-sheet with-footer';
    @Input() title: string;
    @Input() modalEventListener: Observable<ModalEvent>;
    @Input() showFooter = true;

    @Input() initListener: Observable<any>;
    @Input() categories: CategoryDto[];
    @Input() activities: ActivityDto[];
    @Input() selectedCategories: CategoryDto[];
    @Input() selectedActivities: ActivityDto[];

    @Output() selectActivity = new EventEmitter<ActivityDto>();
    @Output() selectCategory = new EventEmitter<CategoryDto>();
    @Output() quit = new EventEmitter();
    @Output() validate = new EventEmitter();

}
