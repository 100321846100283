import {Action} from '@ngrx/store';
import {
    ActivityDto,
    AddressDto,
    ErrorsDto,
    PromotionResultDto,
    SearchCriteriaDto,
    UserResultDto,
    Page, Pageable
} from '../../../core/models';

export enum UsersSearchActionTypes {
    Search = '[SEARCH] SEARCH',
    SearchSuccess = '[SEARCH] SEARCH_SUCCESS',
    SearchFailure = '[SEARCH] SEARCH_FAILURE',

    Reset = '[SEARCH RESULT] RESET',

    SetSearchCriteriaAddress = '[SEARCH USERS] SET_SEARCH_CRITERIA_ADDRESS',
    SetSearchCriteriaService = '[SEARCH USERS] SET_SEARCH_CRITERIA_SERVICE',

    SearchAroundIpOrLast = '[SEARCH] SEARCH_AROUND_IP_OR_LAST',
    SearchAroundIpOrLastSuccess = '[SEARCH] SEARCH_AROUND_IP_OR_LAST_SUCCESS',
    SearchAroundIpOrLastFail = '[SEARCH] SEARCH_AROUND_IP_OR_LAST_FAIL'
}


export class SetSearchCriteriaAddressAction implements Action {
    readonly type = UsersSearchActionTypes.SetSearchCriteriaAddress;

    constructor(public payload: AddressDto) {
    }
}

export class SetSearchCriteriaServiceAction implements Action {
    readonly type = UsersSearchActionTypes.SetSearchCriteriaService;

    constructor(public payload: ActivityDto) {
    }
}

export class SearchAction implements Action {
    readonly type = UsersSearchActionTypes.Search;

    constructor(public payload: { searchCriteria: SearchCriteriaDto, pageable: Pageable }) {
    }
}

export class SearchSuccessAction implements Action {
    readonly type = UsersSearchActionTypes.SearchSuccess;

    constructor(public payload: Page<UserResultDto>) {
    }
}

export class SearchFailureAction implements Action {
    readonly type = UsersSearchActionTypes.SearchFailure;

    constructor(public payload: ErrorsDto) {
    }
}

// Around IP or last created.

export class SearchAroundIPOrLastAction implements Action {
    readonly type = UsersSearchActionTypes.SearchAroundIpOrLast;

    constructor(public payload: Pageable) {
    }
}

export class SearchAroundIPOrLastSuccessAction implements Action {
    readonly type = UsersSearchActionTypes.SearchAroundIpOrLastSuccess;

    constructor(public payload: PromotionResultDto<UserResultDto>) {
    }
}

export class SearchAroundIPOrLastFailAction implements Action {
    readonly type = UsersSearchActionTypes.SearchAroundIpOrLastFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class ResetAction implements Action {
    readonly type = UsersSearchActionTypes.Reset;
}

export type Actions = SearchAction
    | SearchSuccessAction
    | SearchFailureAction
    | SearchAroundIPOrLastAction
    | SearchAroundIPOrLastSuccessAction
    | SearchAroundIPOrLastFailAction
    | ResetAction
    | SetSearchCriteriaAddressAction
    | SetSearchCriteriaServiceAction
    ;
