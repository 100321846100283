import { HttpClient } from '@angular/common/http';
import { HttpUtil } from '../utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MessageService = /** @class */ (function () {
    function MessageService(httpClient) {
        this.httpClient = httpClient;
    }
    MessageService.prototype.sendMessage = function (userUid, message) {
        return this.httpClient.post(HttpUtil.buildAndExpand('api/v1/users/{userUid}/conversations', userUid), message);
    };
    MessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageService_Factory() { return new MessageService(i0.ɵɵinject(i1.HttpClient)); }, token: MessageService, providedIn: "root" });
    return MessageService;
}());
export { MessageService };
