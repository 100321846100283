import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as fromRoot from '../../../store';
import { routeActions } from '../../store/actions';
import { RouteService } from '../index';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../route.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(store, routeService) {
        this.store = store;
        this.routeService = routeService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.store.pipe(select(fromRoot.getCurrentUser), map(function (currentUser) {
            if (currentUser) {
                return true;
            }
            else {
                _this.routeService.redirectUrl = state.url;
                _this.store.dispatch(new routeActions.ToSignInAction());
                return false;
            }
        }));
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.RouteService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
