<app-activity-chooser-modal modalId="activity-chooser"
                            modalClass="bottom-sheet-modal-height bottom-sheet"
                            [title]="'services-chooser.modal.title'| translate"
                            [showFooter]="false"
                            [modalEventListener]="activityModalEventListener$"
                            [initListener]="activityChooserInitListener$"
                            [categories]="categories$ | async"
                            [activities]="activities$ | async"
                            [selectedCategories]="selectedCategories$ | async"
                            [selectedActivities]="selectedActivities$ | async"
                            (selectActivity)="selectActivity($event)"
                            (selectCategory)="selectCategory($event)"
                            (quit)="closeModal()">
</app-activity-chooser-modal>