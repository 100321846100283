import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NotificationService, SeoService } from '../../modules/core/services';
import { authenticationActions } from '../../modules/core/store/actions';
import * as routeActions from '../../modules/core/store/actions/route.action';
import * as signUpActions from '../../modules/core/store/actions/signup.action';
import { CommonUtils, ErrorUtils } from '../../modules/core/utils';
import { BcValidators } from '../../modules/core/validators';
import * as fromRoot from '../../modules/store/';
import { SignUpHelper } from './helper/sign-up.helper';
var SignUpComponent = /** @class */ (function () {
    function SignUpComponent(store, route, notificationService, fb, seo, signUpHelper) {
        var _this = this;
        this.store = store;
        this.route = route;
        this.notificationService = notificationService;
        this.fb = fb;
        this.seo = seo;
        this.signUpHelper = signUpHelper;
        this.subscription = new Subscription();
        this.userRegistration = this.fb.group({
            acceptTermsOfService: [false, Validators.pattern('true')],
            email: ['', Validators.compose([Validators.required, BcValidators.login])],
            password: ['', Validators.compose([Validators.required, BcValidators.password])],
        });
        this.loading$ = this.store.pipe(select(fromRoot.isSignUpRegistrationLoading));
        this.registered$ = this.store.pipe(select(fromRoot.isSignUpRegistered));
        this.notification$ = this.store.pipe(select(fromRoot.getSignUpRegistrationErrors), map(function (errors) {
            _this.mailAlreadyTaken = ErrorUtils.hasDetail(errors, 'user.login.already-exists');
            return ErrorUtils.toNotification(errors);
        }));
        this.seo.setMeta('sign-up');
        this.seo.setRobots(true);
    }
    SignUpComponent.prototype.ngOnInit = function () {
        // this.registered$
        //     .filter(r => r)
        //     .subscribe(() => {
        //         let data = RegistrationConfirmationParams.build(this.userRegistration.email, true);
        //         this.store.dispatch(new routeActions.ToConfirmRegistrationAction(data));
        //     });
        var _this = this;
        this.route.queryParams.pipe(filter(function (params) { return params.error; }))
            .subscribe(function () {
            _this.socialNotification = _this.notificationService.buildGlobalErrorNotification();
        });
        var sub = this.notification$.pipe(filter(CommonUtils.notEmpty))
            .subscribe(function (notification) {
            _this.alertNotification(notification);
        });
        this.subscription.add(sub);
    };
    SignUpComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new signUpActions.ResetAction());
        this.subscription.unsubscribe();
    };
    SignUpComponent.prototype.onSubmit = function () {
        this.store.dispatch(new signUpActions.SignUpAction(this.userRegistration.value));
    };
    SignUpComponent.prototype.toPasswordForgotten = function () {
        this.store.dispatch(new routeActions.ToPasswordForgotten(this.getEmail()));
    };
    Object.defineProperty(SignUpComponent.prototype, "getEmail", {
        get: function () {
            return this.userRegistration.get('email').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignUpComponent.prototype, "acceptTermsOfService", {
        get: function () {
            return this.userRegistration.get('acceptTermsOfService').value;
        },
        enumerable: true,
        configurable: true
    });
    SignUpComponent.prototype.signUpWithFB = function (eventData) {
        if (eventData.error) {
            this.alertNotification(this.notificationService.buildGlobalErrorNotification());
        }
        else {
            this.store.dispatch(new authenticationActions.SocialAuthenticateAction(eventData.loginResponse.token));
        }
    };
    SignUpComponent.prototype.acceptTermsChange = function (args) {
        if (!args || !args.object) {
            return false;
        }
        var box = args.object;
        this.userRegistration.get('acceptTermsOfService').setValue(box.checked);
    };
    SignUpComponent.prototype.alertNotification = function (notif) {
        this.signUpHelper.alertNotification(notif);
    };
    return SignUpComponent;
}());
export { SignUpComponent };
