import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AccountActivationDto} from '../models';
import {HttpUtil} from '../utils';

@Injectable({
    providedIn: 'root'
})
export class AccountActivationService {

    constructor(private httpClient: HttpClient) {
    }

    public activateAccount(userActivationDto: AccountActivationDto): Observable<any> {
        let url = HttpUtil.buildAndExpand('api/v1/account/{userUid}/activate/{token}', userActivationDto.userUid, userActivationDto.token);
        return this.httpClient.post(url, null);
    }
}
