import { EventEmitter, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseUserDto, Notification } from '../../../../../core/models';
import { NotificationService } from '../../../../../core/services';
import { loginModalActions, reviewActions } from '../../../../../core/store/actions';
import * as fromRoot from '../../../../../store';
var ReviewManagerComponent = /** @class */ (function () {
    function ReviewManagerComponent(store, notificationService) {
        this.store = store;
        this.notificationService = notificationService;
        this.displayAddReviewForm = false;
        this.loadReviews = new EventEmitter();
        this.currentUser$ = this.store.pipe(select(fromRoot.getCurrentUser));
    }
    ReviewManagerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentUser$.subscribe(function (current) {
            _this.currentUser = current;
        });
        this.store.pipe(select(fromRoot.isReviewAdded))
            .subscribe(function (added) {
            if (added) {
                _this.loadReviews.emit(_this.targetUser);
                _this.notification = _this.notificationService.buildReviewAddedSuccess();
                _this.displayAddReviewForm = false;
            }
        });
    };
    ReviewManagerComponent.prototype.showAddReviewForm = function () {
        if (this.currentUser) {
            this.displayAddReviewForm = true;
        }
        else {
            this.store.dispatch(new loginModalActions.OpenModalAction());
        }
    };
    ReviewManagerComponent.prototype.addReview = function (review) {
        this.store.dispatch(new reviewActions.AddReviewAction(review));
    };
    ReviewManagerComponent.prototype.deleteReview = function (review) {
    };
    ReviewManagerComponent.prototype.updateReview = function (review) {
    };
    ReviewManagerComponent.prototype.cancelReview = function () {
        this.displayAddReviewForm = false;
    };
    return ReviewManagerComponent;
}());
export { ReviewManagerComponent };
