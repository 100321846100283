export interface IStorage {
  setItem(key: StorageKey, value: any): void;
  getItem(key: StorageKey): any;
  removeItem(key: StorageKey): void;
}

export type StorageKey = 'token' | 'user' | 'expires_at' | 'locale' | 'intro' | 'JWT';

export const StorageKey = {
    TOKEN: <StorageKey>'token',
    USER: <StorageKey>'user',
    EXPIRES_AT: <StorageKey>'expires_at',
    LOCALE: <StorageKey>'locale',
    LANG: <StorageKey>'lang',
    INTRO: <StorageKey>'intro',
    JWT: <StorageKey>'JWT',
};
