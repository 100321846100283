import {Params} from '@angular/router';
import {Config} from '../../../config';
import {ActivityDto, AddressDto, Pageable, SearchCriteriaDto} from '../../core/models';
import {CommonUtils} from '../../core/utils';

export class UserParamUtils {

    static toParams(searchCriteria: SearchCriteriaDto, pageable?: Pageable): Params {
        let params = [];

        if (pageable) {
            let pageableToParams = CommonUtils.pageableToParams(pageable);
            CommonUtils.concatParams(params, pageableToParams);
        }

        if (!searchCriteria) {
            return CommonUtils.pageableToParams(pageable);
        }

        if (searchCriteria.address && searchCriteria.address.location) {
            params['lat'] = searchCriteria.address.location.latitude;
            params['lng'] = searchCriteria.address.location.longitude;
            params['address'] = searchCriteria.address.formatted;
        }

        if (searchCriteria.activity) {
            params['activityId'] = searchCriteria.activity.id;
            params['activityName'] = searchCriteria.activity.name;
        }

        if (searchCriteria.radius) {
            params['radius'] = searchCriteria.radius;
        }

        return params;
    }

    static paramsToSearchCriteria(params: Params): SearchCriteriaDto {
        if (!params) {
            return null;
        }

        let latitude = +params['lat'];
        let longitude = +params['lng'];
        let activityId = +params['activityId'];

        let sc: SearchCriteriaDto;

        if (activityId) {
            sc = new SearchCriteriaDto();

            sc.activity = new ActivityDto();
            sc.activity.id = activityId;
            sc.activity.name = params['activityName'];
        }

        if (latitude && longitude) {

            if (!sc) {
                sc = new SearchCriteriaDto();
            }

            let address = new AddressDto();
            address.location = {
                latitude: latitude,
                longitude: longitude
            };
            address.formatted = params['address'];

            sc.address = address;
        }

        if (sc) {
            sc.radius = params['radius'] ? +params['radius'] : Config.ENVIRONMENT().RADIUS;
        }

        return sc;
    }
}
