import {Action} from '@ngrx/store';
import {Locale} from '../../models';
import {CategoryDto, ErrorsDto} from '../../models';

export enum CategoryActionTypes {
    LoadCategories = '[CATEGORIES] LOAD_CATEGORIES',
    LoadCategoriesIfNull = '[CATEGORIES] LOAD_CATEGORIES_IF_NULL',
    LoadCategoriesSuccess = '[CATEGORIES] LOAD_CATEGORIES_SUCCESS',
    LoadCategoriesFail = '[CATEGORIES] LOAD_CATEGORIES_FAIL',

    SwitchLang = '[CATEGORIES] SWITCH_LANG',
}

export class SwitchLangAction implements Action {
    readonly type = CategoryActionTypes.SwitchLang;

    constructor(public payload: Locale) {
    }
}

export class LoadCategoriesAction implements Action {
    readonly type = CategoryActionTypes.LoadCategories;
}

export class LoadCategoriesIfNullAction implements Action {
    readonly type = CategoryActionTypes.LoadCategoriesIfNull;
}

export class LoadCategoriesSuccessAction implements Action {
    readonly type = CategoryActionTypes.LoadCategoriesSuccess;

    constructor(public payload: CategoryDto[]) {
    }
}

export class LoadCategoriesFailAction implements Action {
    readonly type = CategoryActionTypes.LoadCategoriesFail;

    constructor(public payload: ErrorsDto) {
    }
}

export type Actions = LoadCategoriesAction
    | SwitchLangAction
    | LoadCategoriesSuccessAction
    | LoadCategoriesFailAction
    | LoadCategoriesIfNullAction;
