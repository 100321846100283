export var RouteActionTypes;
(function (RouteActionTypes) {
    RouteActionTypes["ToHome"] = "[ROUTER] TO_HOME";
    RouteActionTypes["ToSignIn"] = "[ROUTER] TO_SIGN_IN";
    RouteActionTypes["ToUserProfile"] = "[ROUTER] TO_USER_PROFILE";
    RouteActionTypes["ToCompleteUserProfile"] = "[ROUTER] TO_COMPLETE_USER_PROFILE";
    RouteActionTypes["ToConfirmRegistration"] = "[ROUTER] TO_CONFIRM_REGISTRATION";
    RouteActionTypes["ToPasswordForgotten"] = "[ROUTER] TO_PASSWORD_FORGOTTEN";
    RouteActionTypes["ToWorker"] = "[ROUTER] TO_WORKER";
    RouteActionTypes["ToRequest"] = "[ROUTER] TO_REQUEST";
    RouteActionTypes["ToUpdateRequest"] = "[ROUTER] TO_UPDATE_REQUEST";
    RouteActionTypes["ToRequests"] = "[ROUTER] TO_REQUESTS";
    RouteActionTypes["ToSearchWorkers"] = "[ROUTER] TO_SEARCH_WORKERS";
    RouteActionTypes["ToPageNotFound"] = "[ROUTER] TO_PAGE_NOT_FOUND";
    RouteActionTypes["ToForbidden"] = "[ROUTER] TO_FORBIDDEN";
    RouteActionTypes["ToNoticeTarget"] = "[ROUTER] ToNoticeTarget";
    RouteActionTypes["ToUrl"] = "[ROUTER] TO_URL";
})(RouteActionTypes || (RouteActionTypes = {}));
var ToUrlAction = /** @class */ (function () {
    function ToUrlAction(payload) {
        this.payload = payload;
        this.type = RouteActionTypes.ToUrl;
    }
    return ToUrlAction;
}());
export { ToUrlAction };
var ToHomeAction = /** @class */ (function () {
    function ToHomeAction() {
        this.type = RouteActionTypes.ToHome;
    }
    return ToHomeAction;
}());
export { ToHomeAction };
var ToSignInAction = /** @class */ (function () {
    function ToSignInAction() {
        this.type = RouteActionTypes.ToSignIn;
    }
    return ToSignInAction;
}());
export { ToSignInAction };
var ToConfirmRegistrationAction = /** @class */ (function () {
    function ToConfirmRegistrationAction(payload) {
        this.payload = payload;
        this.type = RouteActionTypes.ToConfirmRegistration;
    }
    return ToConfirmRegistrationAction;
}());
export { ToConfirmRegistrationAction };
var ToUserProfileAction = /** @class */ (function () {
    function ToUserProfileAction() {
        this.type = RouteActionTypes.ToUserProfile;
    }
    return ToUserProfileAction;
}());
export { ToUserProfileAction };
var ToCompleteUserProfileAction = /** @class */ (function () {
    function ToCompleteUserProfileAction() {
        this.type = RouteActionTypes.ToCompleteUserProfile;
    }
    return ToCompleteUserProfileAction;
}());
export { ToCompleteUserProfileAction };
var ToPasswordForgotten = /** @class */ (function () {
    function ToPasswordForgotten(payload) {
        this.payload = payload;
        this.type = RouteActionTypes.ToPasswordForgotten;
    }
    return ToPasswordForgotten;
}());
export { ToPasswordForgotten };
var ToWorkerAction = /** @class */ (function () {
    function ToWorkerAction(payload) {
        this.payload = payload;
        this.type = RouteActionTypes.ToWorker;
    }
    return ToWorkerAction;
}());
export { ToWorkerAction };
var ToRequestAction = /** @class */ (function () {
    function ToRequestAction(payload) {
        this.payload = payload;
        this.type = RouteActionTypes.ToRequest;
    }
    return ToRequestAction;
}());
export { ToRequestAction };
var ToUpdateRequestAction = /** @class */ (function () {
    function ToUpdateRequestAction(payload) {
        this.payload = payload;
        this.type = RouteActionTypes.ToUpdateRequest;
    }
    return ToUpdateRequestAction;
}());
export { ToUpdateRequestAction };
var ToRequestsAction = /** @class */ (function () {
    function ToRequestsAction() {
        this.type = RouteActionTypes.ToRequests;
    }
    return ToRequestsAction;
}());
export { ToRequestsAction };
var ToPageNotFoundAction = /** @class */ (function () {
    function ToPageNotFoundAction() {
        this.type = RouteActionTypes.ToPageNotFound;
    }
    return ToPageNotFoundAction;
}());
export { ToPageNotFoundAction };
var ToForbiddenAction = /** @class */ (function () {
    function ToForbiddenAction() {
        this.type = RouteActionTypes.ToForbidden;
    }
    return ToForbiddenAction;
}());
export { ToForbiddenAction };
var ToNoticeTargetAction = /** @class */ (function () {
    function ToNoticeTargetAction(payload) {
        this.payload = payload;
        this.type = RouteActionTypes.ToNoticeTarget;
    }
    return ToNoticeTargetAction;
}());
export { ToNoticeTargetAction };
var ToSearchWorkersAction = /** @class */ (function () {
    function ToSearchWorkersAction(payload) {
        this.payload = payload;
        this.type = RouteActionTypes.ToSearchWorkers;
    }
    return ToSearchWorkersAction;
}());
export { ToSearchWorkersAction };
