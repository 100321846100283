export var SignUpActionTypes;
(function (SignUpActionTypes) {
    SignUpActionTypes["SignUp"] = "[Register] SIGN_UP";
    SignUpActionTypes["SignUpSuccess"] = "[Register] SIGN_UP_SUCCESS";
    SignUpActionTypes["SignUpFailure"] = "[Register] SIGN_UP_FAILURE";
    SignUpActionTypes["SignUpStoreEmail"] = "[Register] SIGN_UP_STORE_EMAIL";
    SignUpActionTypes["RESET"] = "[Register] RESET";
    SignUpActionTypes["SignUpConfirmation"] = "[Register confirmation] SIGN_UP";
    SignUpActionTypes["SignUpConfirmationSuccess"] = "[Register confirmation] SIGN_UP_SUCCESS";
    SignUpActionTypes["SignUpConfirmationFailure"] = "[Register confirmation] SIGN_UP_FAILURE";
    SignUpActionTypes["SignUpConfirmationResentEmail"] = "[Register confirmation] SUC_RESENT_EMAIL";
    SignUpActionTypes["SignUpConfirmationResentEmailSuccess"] = "[Register confirmation] SUC_RESENT_EMAIL_SUCCESS";
    SignUpActionTypes["SignUpConfirmationResentEmailFailure"] = "[Register confirmation] SUC_RESENT_EMAIL_FAILURE";
})(SignUpActionTypes || (SignUpActionTypes = {}));
/**
 * Registration.
 */
var SignUpAction = /** @class */ (function () {
    function SignUpAction(payload) {
        this.payload = payload;
        this.type = SignUpActionTypes.SignUp;
    }
    return SignUpAction;
}());
export { SignUpAction };
var SignUpSuccessAction = /** @class */ (function () {
    function SignUpSuccessAction(payload) {
        this.payload = payload;
        this.type = SignUpActionTypes.SignUpSuccess;
    }
    return SignUpSuccessAction;
}());
export { SignUpSuccessAction };
var SignUpFailureAction = /** @class */ (function () {
    function SignUpFailureAction(payload) {
        this.payload = payload;
        this.type = SignUpActionTypes.SignUpFailure;
    }
    return SignUpFailureAction;
}());
export { SignUpFailureAction };
var SignUpStoreEmailAction = /** @class */ (function () {
    function SignUpStoreEmailAction(payload) {
        this.payload = payload;
        this.type = SignUpActionTypes.SignUpStoreEmail;
    }
    return SignUpStoreEmailAction;
}());
export { SignUpStoreEmailAction };
/**
 * Sign up confirmation.
 */
var SignUpConfirmationAction = /** @class */ (function () {
    function SignUpConfirmationAction(payload) {
        this.payload = payload;
        this.type = SignUpActionTypes.SignUpConfirmation;
    }
    return SignUpConfirmationAction;
}());
export { SignUpConfirmationAction };
var SignUpConfirmationSuccessAction = /** @class */ (function () {
    function SignUpConfirmationSuccessAction() {
        this.type = SignUpActionTypes.SignUpConfirmationSuccess;
    }
    return SignUpConfirmationSuccessAction;
}());
export { SignUpConfirmationSuccessAction };
var SignUpConfirmationFailureAction = /** @class */ (function () {
    function SignUpConfirmationFailureAction(payload) {
        this.payload = payload;
        this.type = SignUpActionTypes.SignUpConfirmationFailure;
    }
    return SignUpConfirmationFailureAction;
}());
export { SignUpConfirmationFailureAction };
/**
 * Email confirmation resent.
 */
var SignUpConfirmationResentEmailAction = /** @class */ (function () {
    function SignUpConfirmationResentEmailAction(payload) {
        this.payload = payload;
        this.type = SignUpActionTypes.SignUpConfirmationResentEmail;
    }
    return SignUpConfirmationResentEmailAction;
}());
export { SignUpConfirmationResentEmailAction };
var SignUpConfirmationResentEmailSuccessAction = /** @class */ (function () {
    function SignUpConfirmationResentEmailSuccessAction(payload) {
        this.payload = payload;
        this.type = SignUpActionTypes.SignUpConfirmationResentEmailSuccess;
    }
    return SignUpConfirmationResentEmailSuccessAction;
}());
export { SignUpConfirmationResentEmailSuccessAction };
var SignUpConfirmationResentEmailFailureAction = /** @class */ (function () {
    function SignUpConfirmationResentEmailFailureAction(payload) {
        this.payload = payload;
        this.type = SignUpActionTypes.SignUpConfirmationResentEmailFailure;
    }
    return SignUpConfirmationResentEmailFailureAction;
}());
export { SignUpConfirmationResentEmailFailureAction };
/**
 * Reset
 */
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = SignUpActionTypes.RESET;
    }
    return ResetAction;
}());
export { ResetAction };
