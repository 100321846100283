var Locale = /** @class */ (function () {
    function Locale(name, shortcut, icon, mobileIcon, rtl) {
        this.name = name;
        this.shortcut = shortcut;
        this.icon = icon;
        this.mobileIcon = mobileIcon;
        this.rtl = rtl;
    }
    Locale.isArabic = function (locale) {
        return locale.name === Locale.ARABIC.name;
    };
    Locale.getLocaleByShortcut = function (shortcut) {
        if (!shortcut) {
            return null;
        }
        return this.SUPPORTED_LOCALES.find(function (lc) { return lc.shortcut === shortcut; });
    };
    Locale.prototype.equals = function (loc) {
        return loc && this.name === loc.name;
    };
    Locale.FRENCH = new Locale('Français', 'fr', 'french_flag.png', 'french_flag');
    Locale.ARABIC = new Locale('العربية', 'ar', 'arabe_flag.png', 'arabe_flag', true);
    Locale.MOROCCAN = new Locale('المغربية', 'ar_ma', 'morocco_flag.png', 'morocco_flag', true);
    Locale.ENGLISH = new Locale('English', 'en', 'english_flag.png', 'english_flag');
    Locale.SUPPORTED_LOCALES = [Locale.ENGLISH, Locale.FRENCH, Locale.ARABIC, Locale.MOROCCAN];
    return Locale;
}());
export { Locale };
