import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';
import * as fromRoot from '../../store';
import {getCategories} from '../../store';
import {ActivityDto} from '../models';
import {CommonUtils, HttpUtil} from '../utils';

const _flatMap = require('lodash/flatMap');

@Injectable({
    providedIn: 'root'
})
export class ActivityService {

    private static ACTIVITY = 'api/v1/activities/{activityId}';

    constructor(private httpClient: HttpClient,
                private store: Store<fromRoot.State>) {
    }

    public getActivityById(id: number): Observable<ActivityDto> {
        return this.store.pipe(
            select(getCategories),
            filter(c => CommonUtils.notEmpty(c)),
            mergeMap(categories => {

                    const activities = _flatMap(categories, (c) => c.activities);

                    let act = activities.find(a => a.id === id);

                    if (act) {
                        return of(act);
                    }

                    return this.httpClient.get(HttpUtil.buildAndExpand(ActivityService.ACTIVITY, id))
                }
            ));
    }

}
