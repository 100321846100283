import {Component} from '@angular/core';
import {SeoService} from '../../../modules/core/services';

@Component({
    moduleId: module.id,
    selector: 'yf-cgv',
    templateUrl: './cgv.component.html',
    styleUrls: ['./cgv.component.scss']
})
export class CgvComponent {

    constructor(private seo: SeoService) {
        seo.setMeta('cgv');
        seo.setRobots(true);
    }

}
