import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {passwordRecoverActions} from '../actions';
import {ErrorsDto} from '../../models';
import {NotificationService, PasswordService} from '../../services';
import {ErrorUtils} from '../../utils';

@Injectable()
export class PasswordRecoverEffects {

    @Effect()
    recoverPassword: Observable<Action> = this.actions$.pipe(
        ofType<passwordRecoverActions.RecoverPasswordAction>(passwordRecoverActions.PasswordRecoverActionTypes.RecoverPassword),
        map((action: passwordRecoverActions.RecoverPasswordAction) => action.payload),
        mergeMap(email =>
            this.passwordService.recoverPasswordByEmail(email).pipe(
                map(() => new passwordRecoverActions.RecoverPasswordSuccessAction(this.notificationService.buildResetPasswordSuccessNotification())),
                catchError((errors: ErrorsDto) => of(new passwordRecoverActions.RecoverPasswordFailAction(ErrorUtils.toNotification(errors))))
            )
        )
    );

    constructor(private passwordService: PasswordService,
                private notificationService: NotificationService,
                private actions$: Actions) {
    }
}
