<app-modal modalId="locale-modal"
           [modalEventListener]="modalEventListener$"
           [showHeader]="false"
           [showFooter]="false"
           [dismissible]="false">

    <h1 class="col my-0 px-0" modal-header>Sélectionnez votre langue préférée</h1>

    <div style="height: 100%" modal-content>
        <div class="container" style="height: 100%">
            <div class="center locale-modal-title">
                <span>Select your preferred language</span>
                <span>Sélectionnez votre langue préférée</span>
                <span>إختر لغتك المفضلة</span>
            </div>

            <div class="row mt-4">
                <div class="col-3 center lang-item" style="cursor: pointer" *ngFor="let loc of locales"
                     (click)="selectLocale(loc)">
                    <img [src]="'assets/images/flag/' + loc.icon"
                         class="img-thumbnail rounded-circle center-block locale-img">
                    <br/>
                    <span class="center mt-5"
                          style="font-weight: 500; font-size: 1.1rem; vertical-align: middle;">{{loc.name}}</span>
                </div>
            </div>
        </div>
    </div>

</app-modal>
