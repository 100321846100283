import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {CommonUtils} from '../../../core/utils';

import * as fromRoot from '../../../store';
import {requestsPromotionActions, workersPromotionActions} from '../actions';
import {PromotionService} from '../../services';

@Injectable()
export class PromotionEffects {

    @Effect()
    loadRequestsPromotionIfNotLoaded = this.actions$.pipe(
        ofType<requestsPromotionActions.LoadPromotionRequestsIfNotLoadedAction>(requestsPromotionActions.RequestPromotionActionTypes.LoadPromotionRequestsIfNotLoaded),
        map(action => action.payload),
        withLatestFrom(this.store.pipe(select(fromRoot.getRequestsPromotionLoadingDate)), this.store.pipe(select(fromRoot.getRequestsPromotionResult))),
        mergeMap(([pageable, loadingDate, promoResult]) => {

            if (CommonUtils.notEmpty(promoResult) && !this.arePromotionsExpired(loadingDate)) {
                return [];
            }

            return [new requestsPromotionActions.LoadPromotionRequestsAction(pageable)]
        })
    );

    @Effect()
    loadRequestsPromotion = this.actions$.pipe(
        ofType<requestsPromotionActions.LoadPromotionRequestsAction>(requestsPromotionActions.RequestPromotionActionTypes.LoadPromotionRequests),
        map(action => action.payload),
        mergeMap(pageable =>
            this.promoService.searchRequestsAroundIPOrLast(pageable).pipe(
                map(page => new requestsPromotionActions.LoadPromotionRequestsSuccessAction(page)),
                catchError(errors => of(new requestsPromotionActions.LoadPromotionRequestsFailAction(errors)))
            )
        )
    );

    @Effect()
    loadWorkersPromotionIfNotLoaded = this.actions$.pipe(
        ofType(workersPromotionActions.WorkersPromotionActionTypes.LoadPromotionWorkersIfNotLoaded),
        map((action: workersPromotionActions.LoadPromotionWorkersIfNotLoadedAction) => action.payload),
        withLatestFrom(this.store.pipe(select(fromRoot.getWorkersPromotionLoadingDate))),
        mergeMap(([pageable, loadingDate]) => {

                if (!this.arePromotionsExpired(loadingDate)) {
                    return [];
                }

                return [new workersPromotionActions.LoadPromotionWorkersAction(pageable)]
            }
        ));

    @Effect()
    loadWorkersPromotion = this.actions$.pipe(
        ofType<workersPromotionActions.LoadPromotionWorkersAction>(workersPromotionActions.WorkersPromotionActionTypes.LoadPromotionWorkers),
        map(action => action.payload),
        mergeMap(pageable =>
            this.promoService.searchWorkersAroundIPOrLast(pageable).pipe(
                map(page => new workersPromotionActions.LoadPromotionWorkersSuccessAction(page)),
                catchError(errors => of(new workersPromotionActions.LoadPromotionWorkersFailAction(errors)))
            )
        )
    );

    private arePromotionsExpired(loadingDate: Date): boolean {
        if (!loadingDate) {
            return true;
        }

        let MS_PER_MINUTE = 60000;
        let date = new Date(new Date().getTime() + 5 * MS_PER_MINUTE);

        return loadingDate > date;
    }

    constructor(private actions$: Actions,
                private store: Store<fromRoot.State>,
                private promoService: PromotionService) {
    }

}
