import { ElementRef } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Gender } from '../../../core/models';
var UserImgDirective = /** @class */ (function () {
    function UserImgDirective(el, request) {
        this.el = el;
        this.request = request;
        this.defaultImage = 'assets/images/default-user.jpg';
    }
    Object.defineProperty(UserImgDirective.prototype, "appUserImg", {
        set: function (input) {
            this._userImg = input;
            var img = this.defaultImage;
            var picture;
            if (this.instanceOfBasicUserDto(input)) {
                picture = input.picture;
                this.gender = input.gender;
            }
            else {
                picture = input;
            }
            if (picture) {
                var apiUrl = environment.apiUrl;
                if (this.request) {
                    apiUrl = environment.backendUrl;
                }
                img = apiUrl + 'medias/' + picture;
            }
            else {
                if (this.gender) {
                    switch (this.gender) {
                        case Gender.WOMAN: {
                            img = 'assets/images/user_f.png';
                            break;
                        }
                    }
                }
            }
            this.el.nativeElement.src = img;
        },
        enumerable: true,
        configurable: true
    });
    UserImgDirective.prototype.updateUrl = function () {
        var img = this.defaultImage;
        if (this.instanceOfBasicUserDto(this._userImg)) {
            if (this.gender) {
                switch (this.gender) {
                    case Gender.WOMAN: {
                        img = 'assets/images/user_f.png';
                        break;
                    }
                }
            }
        }
        this.el.nativeElement.src = img;
    };
    UserImgDirective.prototype.instanceOfBasicUserDto = function (object) {
        return object && object.picture !== undefined;
    };
    UserImgDirective.prototype.getRequestProtocol = function () {
        var protocol = 'https';
        var host = this.request.get('host');
        if (host && host.startsWith('localhost')) {
            protocol = 'http';
        }
        // if (this.request && this.request.headers) {
        //     protocol = this.request.protocol;
        //
        //     let proto = this.request.headers['X-Forwarded-Proto'];
        //
        //     if (proto) {
        //         protocol = proto;
        //     }
        // }
        return protocol;
    };
    return UserImgDirective;
}());
export { UserImgDirective };
