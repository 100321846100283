import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    moduleId: module.id,
    selector: 'app-login-input',
    templateUrl: './login-input.component.html',
    styleUrls: ['./login-input.component.scss']
})
export class LoginInputComponent {

    @ViewChild('loginInput', {static: false}) inputRef: ElementRef;

    @Input() initialValue: string;
    @Input() required = true;
    @Input() id: string;
    @Input() labelKey: string;
    @Input() controlName: string;
    @Input() placeholder: string;

    _parentFormGroup: FormGroup;
    isPhoneInput = true;

    @Input() set parentFormGroup(formGroup: FormGroup) {
        this._parentFormGroup = formGroup;

        this._parentFormGroup.get(this.controlName).valueChanges.subscribe(value => {
            this.updateFormType(value);
        });
    }

    updateFormType(value: string) {
        this.isPhoneInput = !(value && value.indexOf('@') !== -1);
        // this.inputRef.nativeElement.focus();
    }

    setValue(_value: string) {
        this._parentFormGroup.get(this.controlName).setValue(_value);
    }

}
