import { PipeTransform } from '@angular/core';
import { ActivityTranslatorService } from '../../core/services/activity-translator.service';
var ActivityTranslatorPipe = /** @class */ (function () {
    function ActivityTranslatorPipe(activityTranslator) {
        this.activityTranslator = activityTranslator;
    }
    ActivityTranslatorPipe.prototype.transform = function (value, args) {
        if (!value) {
            return null;
        }
        if (value instanceof Array) {
            return this.activityTranslator.translateArray(value);
        }
        else {
            return this.activityTranslator.translate(value);
        }
    };
    return ActivityTranslatorPipe;
}());
export { ActivityTranslatorPipe };
