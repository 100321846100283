import {AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';
import {AgmSnazzyInfoWindowModule} from '@agm/snazzy-info-window';
import {isPlatformServer} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
// core
import {APP_INITIALIZER, Injector, NgModule, PLATFORM_ID} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule, BrowserTransferStateModule, makeStateKey, TransferState} from '@angular/platform-browser';
import {EffectsModule} from '@ngrx/effects';
import {ActionReducer, MetaReducer, Store, StoreModule} from '@ngrx/store';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {NgProgressModule} from '@ngx-progressbar/core';
import {NgProgressHttpModule} from '@ngx-progressbar/http';
import {ShareButtonsModule} from '@ngx-share/buttons';
import {ShareButtonsOptions} from '@ngx-share/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MaterializeModule} from 'angular2-materialize';
// vendors
import {FileUploadModule} from 'ng2-file-upload';
import {CustomFormsModule} from 'ng2-validation';
import {CookieService} from 'ngx-cookie-service';
import {NgxPaginationModule} from 'ngx-pagination';
import {AppRoutingModule} from './app-routing.module';
// app
import {AppComponent} from './app.component';
import {APP_COMPONENTS} from './components';
import {Config} from './config';
import {environment} from './environments/environment';
import {AnalyticsService} from './modules/core/services/analytics.service';
import {TestUserAction} from './modules/core/store/actions/authentication.action';
import * as categoryActions from './modules/core/store/actions/category.action';
import {CORE_EFFECTS} from './modules/core/store/effects';
import {WebEffects} from './modules/core/store/effects/web.effects';
import {PromotionsModule} from './modules/promotions/promotions.module';
import {PROMOTIONS_EFFECTS} from './modules/promotions/store/effects';

import * as fromRootStore from './modules/store';
import {USERS_EFFECTS} from './modules/users/store/effects';
import {UsersModule} from './modules/users/users.module';
import {BriCoolHttpInterceptor} from './modules/web/services';
import {AnalyticsWebService} from './modules/web/services/analytics-web.service';
import {TranslateServerLoader} from './modules/web/services/translate-server-loader.service';
import {WebModule} from './modules/web/web.module';

const _metaReducers: MetaReducer<fromRootStore.State, any>[] = [stateSetter];

export const metaReducers = _metaReducers;
export const NGRX_STATE = makeStateKey('NGRX_STATE');

const effects = [
    ...PROMOTIONS_EFFECTS,
    ...CORE_EFFECTS,
    ...USERS_EFFECTS,
    WebEffects
];

const customOptions: ShareButtonsOptions = {
    include: ['facebook', 'whatsapp', 'twitter', 'google', 'email', 'sms', 'copy'],
    theme: 'modern-dark',
    gaTracking: true,
    autoSetMeta: false,
    twitterAccount: 'bricoolOfficiel'
};

Config.PLATFORM_TARGET = Config.PLATFORMS.WEB;

@NgModule({
    declarations: [AppComponent, ...APP_COMPONENTS],
    imports: [
        // core
        BrowserModule.withServerTransition({appId: 'bricool-app'}),
        // PrebootModule.withConfig({ appRoot: 'app-root', replay: false }),
        FormsModule,
        ReactiveFormsModule,
        BrowserTransferStateModule,
        TransferHttpCacheModule,
        HttpClientModule,
        // vendors
        ShareButtonsModule.forRoot({options: customOptions}),
        MaterializeModule,
        // HttpClientXsrfModule.withOptions({cookieName: 'XSRF-TOKEN', headerName: 'XSRF-TOKEN'}),
        NgxPaginationModule,
        // NgsRevealModule.forRoot(),
        NgProgressModule.forRoot(),
        NgProgressHttpModule,
        CustomFormsModule,
        FileUploadModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAy7Uho5y7SQZZmuSLhqnJ4_F_SBNb4An8',
            libraries: ['places'],
            language: localStorage && localStorage.getItem('locale') ? localStorage.getItem('locale') : 'fr'
        }),
        AgmSnazzyInfoWindowModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: exportTranslateStaticLoader,
                deps: [HttpClient]
            }
        }),
        // store configuration
        StoreModule.forRoot(fromRootStore.reducers, {metaReducers}),
        EffectsModule.forRoot(effects),
        // app
        WebModule,
        UsersModule,
        PromotionsModule,
        AppRoutingModule
    ],
    providers: [
        GoogleMapsAPIWrapper,
        CookieService,
        {provide: AnalyticsService, useClass: AnalyticsWebService},
        {provide: HTTP_INTERCEPTORS, useClass: BriCoolHttpInterceptor, deps: [Injector], multi: true},
        {provide: APP_INITIALIZER, useFactory: initApplication, deps: [Store, PLATFORM_ID, Injector], multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    public constructor(private readonly transferState: TransferState,
                       private readonly store: Store<fromRootStore.State>) {
        const isBrowser = this.transferState.hasKey<any>(NGRX_STATE);

        if (isBrowser) {
            this.onBrowser();
        } else {
            this.onServer();
        }
    }

    onServer() {

        this.transferState.onSerialize(NGRX_STATE, () => {
            let state;
            this.store.subscribe((saveState: any) => {
                state = saveState;
            }).unsubscribe();

            return state;
        });
    }

    onBrowser() {
        const state = this.transferState.get<any>(NGRX_STATE, null);
        this.transferState.remove(NGRX_STATE);
        this.store.dispatch({type: 'SET_ROOT_STATE', payload: state});
    }
}

/**
 * Application initialiazer. Retrieve context from server.
 * @param store Ngrx store.
 */
export function initApplication(store: Store<fromRootStore.State>, platformId: Object): Function {
    const fn = () => new Promise(resolve => {

        store.dispatch(new categoryActions.LoadCategoriesAction());

        if (isPlatformServer(platformId)) {
            store.dispatch(new TestUserAction());
        } else if (!environment.production) {
            store.dispatch(new TestUserAction());
        }


        // if (isPlatformBrowser(platformId)) {
        //     const dom = ɵgetDOM();
        //     const styles: any[] = Array.prototype.slice.apply(dom.querySelectorAll(document, `style[ng-transition]`));
        //     styles.forEach(el => {
        //         // Remove ng-transition attribute to prevent Angular appInitializerFactory
        //         // to remove server styles before preboot complete
        //         el.removeAttribute('ng-transition');
        //     });
        //     document.addEventListener('PrebootComplete', () => {
        //         // After preboot complete, remove the server scripts
        //         setTimeout(() => styles.forEach(el => dom.remove(el)));
        //     });
        // }

        return resolve(true);
    });

    return fn;
}

export function exportTranslateStaticLoader(http: HttpClient) {
    // return new TranslateHttpLoader(http);
    return new TranslateServerLoader();
}

// Export for AoT
export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
    const fn = function (state: any, action: any) {
        if (action.type === 'SET_ROOT_STATE') {
            return action.payload;
        }
        return reducer(state, action);
    };

    return fn;
}
