import {HttpParams} from '@angular/common/http';
// app
import {Pageable, Sort, SortDirection, HttpOptions, URL} from '../models';

export class HttpUtil {

    private static NAMES_PATTERN = new RegExp('\\{([^/]+?)\\}');

    static parseUrl(url: string): URL {

        // for angular
        if (url.indexOf('/#/') > -1) {
            url = url.replace('/#/', '/');
        }

        let reURLInformation = new RegExp([
            '^(https?:)//', // protocol
            '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
            '(/{0,1}[^?#]*)', // pathname
            '(\\?[^#]*|)', // search
            '(#.*|)$' // hash
        ].join(''));
        let match = url.match(reURLInformation);
        return match && {
            url: url,
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7]
        }
    }

    static buildAndExpand(uri: string, ...uriVariables: any[]): string {
        if (uri == null) {
            return null;
        }
        if (uri.indexOf('{') === -1) {
            return uri;
        }

        let found = this.NAMES_PATTERN.exec(uri);
        let index = 0;
        while (found) {

            if (index >= uriVariables.length) {
                throw new TypeError('Cannot build and expand uri. URI[' + uri + '] and uriVariables[' + uriVariables.join(',') + ']');
            }

            uri = uri.replace(found[0], uriVariables[index]);
            found = this.NAMES_PATTERN.exec(uri);
            index++;
        }

        return uri;
    }

    static appendQueryParam(options: HttpOptions, param: string, value: any): HttpOptions {
        if (!param || !value) {
            return null;
        }

        if (!options.params) {
            options.params = new HttpParams();
        }

        options.params = options.params.append(param, value.toString());

        return options;
    }

    static appendQueryParams(options: HttpOptions, params: Object): HttpOptions {

        if (!params || !options) {
            return null;
        }

        if (!options.params) {
            options.params = new HttpParams();
        }

        Object.keys(params).forEach(k => {
            if (params[k]) {
                options.params = options.params.append(k, params[k].toString());
            }
        });

        return options;
    }

    static sortArrayToString(sort: Sort[]): string {

        if (!sort || !sort.length) {
            return null;
        }

        let sortString = HttpUtil.sortToString(sort[0]);

        for (let i = 1; i < sort.length; ++i) {
            sortString += '&' + HttpUtil.sortToString(sort[i]);
        }

        return sortString;
    }

    static sortToString(sort: Sort): string {
        return sort.property + ',' + sort.direction;
    }

    static stringToSortArray(value: string): Sort[] {

        if (!value) {
            return [];
        }

        let sorts: string[] = value.split('&');

        if (!sorts || !sorts.length) {
            return []
        }

        return sorts.map(this.stringToSort);
    }

    static stringToSort(value: string): Sort {
        let strings = value.split(',');

        let prop = strings[0];
        let direction: SortDirection = strings[1] === SortDirection.ASC ? SortDirection.ASC : SortDirection.DESC;

        return new Sort(direction, prop);
    }

    static buildPageableOptions(pageable: Pageable): HttpOptions {
        const requestOptions = {};
        const page = pageable.page > 0 ? pageable.page - 1 : 0;

        HttpUtil.appendQueryParam(requestOptions, 'page', page); // 0-based
        HttpUtil.appendQueryParam(requestOptions, 'size', pageable.size);

        if (pageable.sort) {
            pageable.sort.forEach(s => HttpUtil.appendQueryParam(requestOptions, 'sort', this.sortToString(s)));
        }

        return requestOptions;
    }
}
