import { OnInit } from '@angular/core';
import { CategoryUtils } from '../../../core/utils/category.util';
var ActivityListComponent = /** @class */ (function () {
    function ActivityListComponent() {
        this.colClass = 'col-md-4';
    }
    ActivityListComponent.prototype.ngOnInit = function () {
        this.categories = CategoryUtils.toCategoriesTree(this.activities);
    };
    return ActivityListComponent;
}());
export { ActivityListComponent };
