import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import {ActivityDto, CategoryDto, SearchCriteriaDto} from '../../../../core/models';
import {BrowserService} from '../../../../core/services';
import * as fromRoot from '../../../../store';

@Component({
    selector: 'app-person-searchfacets',
    templateUrl: './person-searchfacets.component.html',
    styleUrls: ['./person-searchfacets.component.scss']
})
export class PersonSearchfacetsComponent implements OnInit {

    categories$: Observable<CategoryDto[]>;
    categories: CategoryDto[] = [];

    @Input() searchCriteria: SearchCriteriaDto;

    @Output() filter = new EventEmitter<SearchCriteriaDto>();

    constructor(private store: Store<fromRoot.State>,
                private browserService: BrowserService,
                private cdRef: ChangeDetectorRef) {
        this.categories$ = this.store.pipe(select(fromRoot.getCategories));
    }

    ngOnInit() {
        this.browserService.materialize();

        if (!this.searchCriteria) {
            this.searchCriteria = new SearchCriteriaDto();
            this.searchCriteria.radius = 100;
        }

        this.categories$.subscribe(cs => this.categories = cs);
    }

    selectService(act: ActivityDto) {
        this.searchCriteria.activity = act;
        this.cdRef.detectChanges();
    }

    apply(form: FormGroup) {
        if (form.invalid) {
            return;
        }

        this.filter.emit(this.searchCriteria);
    }

    reInit() {
        this.searchCriteria.radius = null;

        this.filter.emit(this.searchCriteria);
    }

    deleteService() {
        this.searchCriteria.activity = null;
    }
}
