import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {SessionDto, UserRegistrationDto} from '../models';
import {HttpUtil} from '../utils';
import {ValidationService} from './validation.service';

@Injectable({
    providedIn: 'root'
})
export class SignUpService {

    email: string;

    constructor(private httpClient: HttpClient,
                private validationService: ValidationService) {
    }

    public signUp(userRegistration: UserRegistrationDto): Observable<SessionDto> {
        let errorsDto = this.validationService.validateUserRegistrationForm(userRegistration);

        if (errorsDto) {
            return observableThrowError(errorsDto);
        }

        return this.httpClient.post<SessionDto>('api/v1/users', userRegistration);
    }

    resendEmailConfirmation(email: string): Observable<any> {
        let errorsDto = this.validationService.validateEmail(email);
        if (errorsDto !== null) {
            return observableThrowError(errorsDto);
        }

        let url = HttpUtil.buildAndExpand('api/v1/account/{email}/send/activation', email);
        return this.httpClient.post(url, null)
    }

}
