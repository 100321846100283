var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NotificationService, ReviewService } from '../../services';
import * as fromRoot from '../../../store';
import { reportReviewActions, reviewActions } from '../actions';
var ReviewEffects = /** @class */ (function () {
    function ReviewEffects(actions$, store$, reviewService, notificationService) {
        var _this = this;
        this.actions$ = actions$;
        this.store$ = store$;
        this.reviewService = reviewService;
        this.notificationService = notificationService;
        this.reportReview = this.actions$.pipe(ofType(reportReviewActions.ReviewReportActionTypes.ReportReview), map(function (action) { return action.payload; }), mergeMap(function (reviewReport) {
            return _this.reviewService.reportReview(reviewReport).pipe(map(function () {
                _this.notificationService.displaySuccessKey('reports.thanks');
                return new reportReviewActions.ReportReviewSuccessAction();
            }), catchError(function (errors) { return of(new reportReviewActions.ReportReviewFailAction(errors)); }));
        }));
        this.addReview = this.actions$.pipe(ofType(reviewActions.ReviewActionTypes.AddReview), map(function (action) { return action.payload; }), withLatestFrom(this.store$.select(fromRoot.getCurrentUser)), mergeMap(function (_a) {
            var addReviewDto = _a[0], currentUser = _a[1];
            return _this.reviewService.addReview(currentUser.uid, addReviewDto).pipe(map(function () { return new reviewActions.AddReviewSuccessAction(); }), catchError(function (errors) { return of(new reviewActions.AddReviewFailAction(errors)); }));
        }));
        this.loadUserReviews = this.actions$.pipe(ofType(reviewActions.ReviewActionTypes.LoadUserReviews), map(function (action) { return action.payload; }), mergeMap(function (userUid) {
            return _this.reviewService.getReviews(userUid).pipe(map(function (displayReviews) { return new reviewActions.LoadUserReviewsSuccessAction(displayReviews); }), catchError(function (errors) { return of(new reviewActions.LoadUserReviewsFailAction(errors)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ReviewEffects.prototype, "reportReview", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ReviewEffects.prototype, "addReview", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ReviewEffects.prototype, "loadUserReviews", void 0);
    return ReviewEffects;
}());
export { ReviewEffects };
