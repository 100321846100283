import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Notification, Toast, ToastImpl, ToastType, INotificationService} from '../models';

@Injectable({
    providedIn: 'root'
})
export abstract class AbstractNotificationService implements INotificationService {

    constructor(public translateService: TranslateService) {
    }

    abstract displayToast(toast: Toast);

    displayKey(key: string, type: ToastType) {
        let toast = new ToastImpl();
        toast.key = key;
        toast.type = type;

        this.displayToast(toast);
    }

    displayErrorKey(key: string) {
        this.displayKey(key, ToastType.ERROR);
    }

    displayInfoKey(key: string) {
        this.displayKey(key, ToastType.INFO);
    }

    displaySuccessKey(key: string) {
        this.displayKey(key, ToastType.SUCCESS);
    }

    displayMessage(message: string, type: ToastType) {

        let toast = new ToastImpl();
        toast.message = message;
        toast.type = type;

        this.displayToast(toast);
    }

    displayInfoMessage(message: string) {
        this.displayMessage(message, ToastType.INFO);
    }

    displayWarningMessage(message: string) {
        this.displayMessage(message, ToastType.WARNING);
    }

    displaySuccessMessage(message: string) {
        this.displayMessage(message, ToastType.SUCCESS);
    }

    displayErrorMessage(message: string) {
        this.displayMessage(message, ToastType.ERROR);
    }

    buildGlobalErrorNotification(): Notification {
        return Notification.buildErrorNotification(this.translateService.instant('errors.global'));
    }

    buildValidationErrorNotification(): Notification {
        return Notification.buildErrorNotification(this.translateService.instant('errors.validation'));
    }

    buildPasswordChangeSuccessNotification(): Notification {
        let notification = Notification.buildSuccessNotification();
        notification.title = this.translateService.instant('global.password-change-success');
        notification.addMessage(this.translateService.instant('global.password-change-success-info'));

        return notification
    }

    buildSocialAuthFailNotification(): Notification {
        let notification = Notification.buildErrorNotification();
        notification.title = this.translateService.instant('errors.account.social.fail');

        return notification
    }

    buildReviewAddedSuccess(): Notification {
        return Notification.buildSuccessNotification(this.translateService.instant('reviews.add-successful'));
    }

    buildResetPasswordSuccessNotification(): Notification {
        return Notification.buildInfoNotification(this.translateService.instant('reset-password.email-sent'));
    }

    buildAccountActivationSuccessNotification(): Notification {
        return Notification.buildSuccessNotification(this.translateService.instant('account-activation.activated'));
    }

    buildEmailVerifiedSuccessNotification(): Notification {
        return Notification.buildSuccessNotification(this.translateService.instant('verifications.email.verified'));
    }

    buildPasswordResetSuccessNotification(): Notification {
        return Notification.buildSuccessNotification(this.translateService.instant('global.password-change-success-full'));
    }

    buildUserNotEligibleForSearchNotification(): Notification {
        return Notification.buildInfoNotification(this.translateService.instant('account.services.incomplet'));
    }


}
