import {reportReviewActions} from '../actions';
import {ErrorsDto, ModalEvent, ReviewDto} from '../../models';

export interface State {
    loading: boolean,
    errors: ErrorsDto,
    reported: boolean,
    review: ReviewDto,
    modalEvent: ModalEvent,
}

export const initialState: State = {
    loading: false,
    errors: null,
    reported: false,
    review: null,
    modalEvent: ModalEvent.CLOSE
};

export function reducer(state = initialState, action: reportReviewActions.Actions): State {

    switch (action.type) {

        case reportReviewActions.ReviewReportActionTypes.ReportReview: {
            return Object.assign(
                {},
                state,
                {
                    loading: true,
                    errors: null,
                    reported: false
                }
            )
        }

        case reportReviewActions.ReviewReportActionTypes.ReportReviewSuccess: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    reported: true,
                    errors: null
                }
            )
        }

        case reportReviewActions.ReviewReportActionTypes.ReportReviewFail: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: action.payload,
                    reported: false
                }
            )
        }

        case reportReviewActions.ReviewReportActionTypes.OpenModal: {
            return Object.assign(
                {},
                state,
                {
                    loading: false,
                    errors: null,
                    reported: false,
                    modalEvent: ModalEvent.OPEN,
                    review: action.payload
                }
            )
        }

        case reportReviewActions.ReviewReportActionTypes.CloseModal: {
            return Object.assign(
                {},
                state,
                {
                    modalEvent: ModalEvent.CLOSE,
                }
            )
        }

        case reportReviewActions.ReviewReportActionTypes.Reset: {
            return Object.assign(
                {},
                state,
                initialState
            )
        }

        default: {
            return state;
        }

    }
}

export const isLoading = (state: State): boolean => state.loading;
export const isReported = (state: State): boolean => state.reported;
export const getErrors = (state: State): ErrorsDto => state.errors;
export const getReview = (state: State): ReviewDto => state.review;
export const getModalEvent = (state: State): ModalEvent => state.modalEvent;
