
export type IntroStatus = "POSTPONED" | "FINISHED" | "IGNORED";

export const IntroStatus = {
    POSTPONED: <IntroStatus>"POSTPONED",
    FINISHED: <IntroStatus>"FINISHED",
    IGNORED: <IntroStatus>"IGNORED",
};

export class IntroModel {
    version = 'v1';
    status: IntroStatus;
    date: Date;
}

