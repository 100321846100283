/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promotion-workers.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/directives/web/user-img.directive";
import * as i3 from "@nguniversal/express-engine/tokens";
import * as i4 from "../../../shared/components/chips/chips.component.ngfactory";
import * as i5 from "../../../shared/components/chips/chips.component";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../web/directives/dir.directive";
import * as i8 from "@ngrx/store";
import * as i9 from "@angular/common";
import * as i10 from "../../../shared/pipes/activity-translator.pipe";
import * as i11 from "../../../core/services/activity-translator.service";
import * as i12 from "./helper/promotion-workers.helper";
import * as i13 from "./promotion-workers.component";
import * as i14 from "../../../core/services/browser.service";
var styles_PromotionWorkersComponent = [i0.styles];
var RenderType_PromotionWorkersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromotionWorkersComponent, data: {} });
export { RenderType_PromotionWorkersComponent as RenderType_PromotionWorkersComponent };
function View_PromotionWorkersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "li", [["class", "worker-promo-item left card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToWorker(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "card-block py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row center px-1 mb-0"], ["style", "height: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-12 mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "img", [["alt", "image"], ["class", "img-thumbnail rounded-circle home-user-pic"]], null, [[null, "error"]], function (_v, en, $event) { var ad = true; if (("error" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).updateUrl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.UserImgDirective, [i1.ElementRef, [2, i3.REQUEST]], { appUserImg: [0, "appUserImg"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "r-user-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "col-12 r-address"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "col-12 mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "app-chips", [], null, null, null, i4.View_ChipsComponent_0, i4.RenderType_ChipsComponent)), i1.ɵdid(13, 114688, null, 0, i5.ChipsComponent, [], { data: [0, "data"], fromString: [1, "fromString"], limitDisplaySize: [2, "limitDisplaySize"], displayOverflow: [3, "displayOverflow"] }, null), i1.ɵppd(14, 1)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 5, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.activities)); var currVal_4 = true; var currVal_5 = 2; var currVal_6 = true; _ck(_v, 13, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.firstName; _ck(_v, 8, 0, currVal_1); var currVal_2 = ((_v.context.$implicit.address == null) ? null : _v.context.$implicit.address.formatted); _ck(_v, 10, 0, currVal_2); }); }
function View_PromotionWorkersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h1", [["class", "h3 center text-white my-4"], ["translate", ""], ["yfDir", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), i1.ɵdid(3, 81920, null, 0, i7.DirDirective, [i1.ElementRef, i1.Renderer2, i8.Store], null, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ul", [["class", "worker-promo"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromotionWorkersComponent_3)), i1.ɵdid(9, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); _ck(_v, 3, 0); var currVal_2 = _co.workers; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.titleKey; _ck(_v, 4, 0, currVal_1); }); }
function View_PromotionWorkersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromotionWorkersComponent_2)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workers; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PromotionWorkersComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "valign-wrapper"], ["style", "height: 200px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "preloader-wrapper big active center-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "spinner-layer"], ["style", "border-color: white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "circle-clipper left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "gap-patch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "circle-clipper right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "circle"]], null, null, null, null, null))], null, null); }
export function View_PromotionWorkersComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i10.ActivityTranslatorPipe, [i11.ActivityTranslatorService]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PromotionWorkersComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["spinner", 2]], null, 0, null, View_PromotionWorkersComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.loading$)); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_PromotionWorkersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-promotion-workers", [], null, null, null, View_PromotionWorkersComponent_0, RenderType_PromotionWorkersComponent)), i1.ɵprd(512, null, i12.PromotionWorkersHelper, i12.PromotionWorkersHelper, []), i1.ɵdid(2, 245760, null, 0, i13.PromotionWorkersComponent, [i8.Store, i14.BrowserService, i6.TranslateService, i12.PromotionWorkersHelper], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PromotionWorkersComponentNgFactory = i1.ɵccf("app-promotion-workers", i13.PromotionWorkersComponent, View_PromotionWorkersComponent_Host_0, { row1: "row1", row2: "row2" }, {}, []);
export { PromotionWorkersComponentNgFactory as PromotionWorkersComponentNgFactory };
