<app-modal modalId="user-phone-displayer-id"
           modalClass="user-phone-displayer"
           (close)="dispatchModalClose()"
           [modalEventListener]="modalEventListener$"
           [showHeader]="false"
           [showFooter]="(phone$ | async)">

    <div class="center phone-view-body" modal-content>

        <div *ngIf="!(loading$ | async)">
            <h2 class="mb-0">{{(phone$ | async)}}</h2>
        </div>

        <app-spinner *ngIf="loading$ | async"></app-spinner>
    </div>

    <div class="show-user-phone-footer" modal-footer>
        <a [href]="'tel:'+(phone$ | async)" (click)="dispatchModalClose()" *ngIf="isMobileDevice" translate>
            global.call
        </a>

        <a (click)="dispatchModalClose()" *ngIf="!isMobileDevice" translate>global.close</a>
    </div>

</app-modal>