export var ActivityChooserActionTypes;
(function (ActivityChooserActionTypes) {
    ActivityChooserActionTypes["OpenActivityChooserModal"] = "[ACTIVITY CHOOSER MODAL] OPEN_ACTIVITY_CHOOSER_MODAL";
    ActivityChooserActionTypes["CloseActivityChooserModal"] = "[ACTIVITY CHOOSER MODAL] CLOSE_ACTIVITY_CHOOSER_MODAL";
})(ActivityChooserActionTypes || (ActivityChooserActionTypes = {}));
var OpenActivityChooserModalAction = /** @class */ (function () {
    function OpenActivityChooserModalAction() {
        this.type = ActivityChooserActionTypes.OpenActivityChooserModal;
    }
    return OpenActivityChooserModalAction;
}());
export { OpenActivityChooserModalAction };
var CloseActivityChooserModalAction = /** @class */ (function () {
    function CloseActivityChooserModalAction() {
        this.type = ActivityChooserActionTypes.CloseActivityChooserModal;
    }
    return CloseActivityChooserModalAction;
}());
export { CloseActivityChooserModalAction };
