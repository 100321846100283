var RangeUtils = /** @class */ (function () {
    function RangeUtils() {
    }
    RangeUtils.buildRange = function (integerRange) {
        var min = 0;
        var max = 1000;
        if (integerRange) {
            var rMin = integerRange.min;
            if (rMin) {
                min = rMin;
            }
            var rMax = integerRange.max;
            if (rMax) {
                max = rMax;
            }
        }
        return [min, max];
    };
    return RangeUtils;
}());
export { RangeUtils };
