export class PasswordTokenValidationDto {
    email: string;
    token: string;

    public static build(email: string, token: string): PasswordTokenValidationDto {
        let pt = new PasswordTokenValidationDto();
        pt.email = email;
        pt.token = token;

        return pt;
    }
}
