import {Action} from '@ngrx/store';
import {ChangePasswordDto, ErrorsDto, Notification} from '../../../core/models';

export enum AccountPasswordChangeActionTypes {
    ChangePassword = '[PASSWORD CHANGE] CHANGE_PASSWORD',
    ChangePasswordSuccess = '[PASSWORD CHANGE] CHANGE_PASSWORD_SUCCESS',
    ChangePasswordFail = '[PASSWORD CHANGE] CHANGE_PASSWORD_FAIL',

    Reset = '[PASSWORD CHANGE] RESET'
}

export class ChangePasswordAction implements Action {
    readonly type = AccountPasswordChangeActionTypes.ChangePassword;

    constructor(public payload: ChangePasswordDto) {
    }
}

export class ChangePasswordSuccessAction implements Action {
    readonly type = AccountPasswordChangeActionTypes.ChangePasswordSuccess;

    constructor(public payload: Notification) {
    }
}

export class ChangePasswordFailAction implements Action {
    readonly type = AccountPasswordChangeActionTypes.ChangePasswordFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class ResetAction implements Action {
    readonly type = AccountPasswordChangeActionTypes.Reset;
}

export type Actions = ChangePasswordAction
    | ChangePasswordSuccessAction
    | ChangePasswordFailAction
    | ResetAction
    ;
