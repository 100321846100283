import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {CategoryDto} from '../../../core/models';

@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryListComponent {

    @Input() categories: CategoryDto[];
    @Input() colClass = 'col-md-4';

}
