import { environment } from '../../../environments/environment';
import { SeoService } from '../../../modules/core/services';
var AboutComponent = /** @class */ (function () {
    function AboutComponent(seo) {
        this.seo = seo;
        this.appVersion = environment.version;
        seo.setMeta('about');
        seo.setRobots(true);
    }
    return AboutComponent;
}());
export { AboutComponent };
