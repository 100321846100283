import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-promotion-how-to',
    templateUrl: './promotion-how-to.component.html',
    styleUrls: ['./promotion-how-to.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionHowToComponent {

}
