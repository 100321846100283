var AccountActivationDto = /** @class */ (function () {
    function AccountActivationDto() {
    }
    return AccountActivationDto;
}());
export { AccountActivationDto };
var EmailVerificationDto = /** @class */ (function () {
    function EmailVerificationDto() {
    }
    return EmailVerificationDto;
}());
export { EmailVerificationDto };
