import {Pipe, PipeTransform} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {filter} from 'rxjs/operators';
import {CategoryDto, Locale} from '../../core/models';

import * as fromRoot from '../../store';

@Pipe({
    name: 'categoryTranslator'
})
export class CategoryTranslatorPipe implements PipeTransform {

    static arNameGetter = CategoryTranslatorPipe.getArNameGetter();
    static enNameGetter = CategoryTranslatorPipe.getEnNameGetter();
    static maNameGetter = CategoryTranslatorPipe.getMaNameGetter();
    static frNameGetter = CategoryTranslatorPipe.getFrNameGetter();

    nameGetter = CategoryTranslatorPipe.frNameGetter;

    private static getArNameGetter() {
        let fn = (category: CategoryDto) => category.arName;
        return fn;
    }

    private static getFrNameGetter() {
        let fn = (category: CategoryDto) => category.name;
        return fn;
    }

    private static getEnNameGetter() {
        const fn = (category: CategoryDto) => category.enName;
        return fn;
    }

    private static getMaNameGetter() {
        const fn = (category: CategoryDto) => category.maName;
        return fn;
    }

    constructor(private store: Store<fromRoot.State>) {
        this.store.pipe(select(fromRoot.getLocale), filter(locale => locale != null))
            .subscribe(locale => {
                if (!locale) {
                    locale = Locale.ENGLISH;
                }

                switch (locale.shortcut.toLowerCase()) {
                    case Locale.FRENCH.shortcut:
                        this.nameGetter = CategoryTranslatorPipe.frNameGetter;
                        break;
                    case Locale.ENGLISH.shortcut:
                        this.nameGetter = CategoryTranslatorPipe.enNameGetter;
                        break;
                    case Locale.ARABIC.shortcut:
                        this.nameGetter = CategoryTranslatorPipe.arNameGetter;
                        break;
                    case Locale.MOROCCAN.shortcut:
                        this.nameGetter = CategoryTranslatorPipe.maNameGetter;
                        break;
                }
            })
    }

    transform(value: CategoryDto, args?: any): any {
        return this.nameGetter(value);
    }

}
