import { AfterViewInit, OnDestroy, OnInit, } from '@angular/core';
import { AdsenseConfig } from '../config/adsense.config';
var AdsenseComponent = /** @class */ (function () {
    function AdsenseComponent(config) {
        this.config = config;
        /** can be manually set if you need all ads on a page to have same id page-xxx */
        this.adRegion = 'page-' + Math.floor(Math.random() * 10000) + 1;
    }
    AdsenseComponent.prototype.ngOnInit = function () {
        var config = this.config;
        function use(source, defaultValue) {
            return config && source !== undefined ? source : defaultValue;
        }
        this.adClient = use(this.adClient, config.adClient);
        this.adSlot = use(this.adSlot, config.adSlot);
        this.adFormat = use(this.adFormat, config.adFormat || 'auto');
        this.display = use(this.display, config.display || 'block');
        this.width = use(this.width, config.width);
        this.height = use(this.height, config.height);
        this.layout = use(this.layout, config.layout);
        this.layoutKey = use(this.layoutKey, config.layoutKey);
        this.pageLevelAds = use(this.pageLevelAds, config.pageLevelAds);
        this.timeOutRetry = use(this.timeOutRetry, config.timeOutRetry || 200);
        this.adtest = use(this.adtest, config.adtest);
        this.fullWidthResponsive = use(this.fullWidthResponsive, config.fullWidthResponsive);
    };
    AdsenseComponent.prototype.ngOnDestroy = function () {
        if (this.ins) {
            var iframe = this.ins.nativeElement.querySelector('iframe');
            if (iframe && iframe.contentWindow) {
                iframe.src = 'about:blank';
                iframe.remove();
            }
        }
    };
    /**
     * attempts to push the ad twice. Usually if one doesn't work the other
     * will depeding on if the browser has the adsense code cached and
     * if its the first page to be loaded
     */
    AdsenseComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var res = this.push();
        if (res instanceof TypeError) {
            setTimeout(function () { return _this.push(); }, this.timeOutRetry);
        }
    };
    AdsenseComponent.prototype.push = function () {
        var p = {};
        if (this.pageLevelAds) {
            p.google_ad_client = this.adClient;
            p.enable_page_level_ads = true;
        }
        try {
            var adsbygoogle = window['adsbygoogle'];
            adsbygoogle.push(p);
            return true;
        }
        catch (e) {
            return e;
        }
    };
    return AdsenseComponent;
}());
export { AdsenseComponent };
