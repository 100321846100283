import { EventEmitter, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { authenticationActions, loginModalActions } from '../../../../core/store/actions';
import { ErrorUtils } from '../../../../core/utils';
import * as fromRoot from '../../../../store';
var LoginModalComponent = /** @class */ (function () {
    function LoginModalComponent(store) {
        this.store = store;
        this.initListener$ = new EventEmitter();
        this.modalEventListener$ = this.store.pipe(select(fromRoot.getLoginModalEvent));
        this.loading$ = this.store.pipe(select(fromRoot.isAuthLoading));
        this.authenticated$ = this.store.pipe(select(fromRoot.isAuthenticated));
        this.notification$ = this.store.pipe(select(fromRoot.getAuthErrors), map(ErrorUtils.toNotification));
    }
    LoginModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authenticated$.subscribe(function (authenticated) {
            if (authenticated) {
                _this.closeModal();
            }
        });
    };
    LoginModalComponent.prototype.init = function () {
        this.initListener$.next();
    };
    LoginModalComponent.prototype.login = function (userLoginDto) {
        this.store.dispatch(new authenticationActions.AuthenticateAction(userLoginDto));
    };
    LoginModalComponent.prototype.closeModal = function () {
        this.store.dispatch(new loginModalActions.CloseModalAction());
    };
    return LoginModalComponent;
}());
export { LoginModalComponent };
