import {Action} from '@ngrx/store';
import {ConversationDto, ErrorsDto, Page, Pageable} from '../../../core/models';

export enum AccountConversationsActionTypes {
    LoadConversations = '[Account Conversation] LOAD_CONVERSATIONS',
    LoadConversationsSuccess = '[Account Conversation] LOAD_CONVERSATIONS_SUCCESS',
    LoadConversationsFail = '[Account Conversation] LOAD_CONVERSATIONS_FAIL',

    LoadConversation = '[Account Conversation] LOAD_CONVERSATION',
    LoadConversationSuccess = '[Account Conversation] LOAD_CONVERSATION_SUCCESS',
    LoadConversationFail = '[Account Conversation] LOAD_CONVERSATION_FAIL',

    MarkAsRead = '[Account Conversation] MARK_AS_READ',
    MarkAsReadSuccess = '[Account Conversation] MARK_AS_READ_SUCCESS',
    SetSelectedConversationId = '[Account Conversation] SET_SELECTED_CONVERSATION_ID',

    Reset = '[Account Conversation] reset'
}

export class LoadConversations implements Action {
    readonly type = AccountConversationsActionTypes.LoadConversations;

    constructor(public payload: Pageable) {
    }
}

export class LoadConversationsSuccess implements Action {
    readonly type = AccountConversationsActionTypes.LoadConversationsSuccess;

    constructor(public payload: Page<ConversationDto>) {
    }
}

export class LoadConversationsFail implements Action {
    readonly type = AccountConversationsActionTypes.LoadConversationsFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class LoadConversation implements Action {
    readonly type = AccountConversationsActionTypes.LoadConversation;

    constructor(public payload: number) {
    }
}

export class LoadConversationSuccess implements Action {
    readonly type = AccountConversationsActionTypes.LoadConversationSuccess;

    constructor(public payload: ConversationDto) {
    }
}

export class LoadConversationFail implements Action {
    readonly type = AccountConversationsActionTypes.LoadConversationFail;

    constructor(public payload: ErrorsDto) {
    }
}

/**
 * Read messages.
 */
export class MarkAsRead implements Action {
    readonly type = AccountConversationsActionTypes.MarkAsRead;

    constructor(public payload: number) {
    }
}

export class MarkAsReadSuccess implements Action {
    readonly type = AccountConversationsActionTypes.MarkAsReadSuccess;
}

export class SetSelectedConversationId implements Action {
    readonly type = AccountConversationsActionTypes.SetSelectedConversationId;

    constructor(public payload: number) {
    }
}

export class ResetAction implements Action {
    readonly type = AccountConversationsActionTypes.Reset;
}

export type Actions
    = LoadConversations
    | LoadConversationsSuccess
    | LoadConversationsFail
    | LoadConversation
    | LoadConversationSuccess
    | LoadConversationFail
    | MarkAsRead
    | SetSelectedConversationId
    | ResetAction
    ;
