var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { isPlatformBrowser } from '@angular/common';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from '../../../store';
import { activateAccountActions, authenticationActions, routeActions } from '../actions';
import { StorageKey } from '../../models/interfaces';
import { AccountActivationService, NotificationService, StorageService } from '../../services';
import { ErrorUtils } from '../../utils';
var AccountActivationEffects = /** @class */ (function () {
    function AccountActivationEffects(platformId, accountActivationService, notificationService, actions$, store, storageService) {
        var _this = this;
        this.platformId = platformId;
        this.accountActivationService = accountActivationService;
        this.notificationService = notificationService;
        this.actions$ = actions$;
        this.store = store;
        this.storageService = storageService;
        this.activateAccount = this.actions$.pipe(ofType(activateAccountActions.AccountActivationActionTypes.Activate), map(function (action) { return action.payload; }), mergeMap(function (userActivation) {
            return _this.accountActivationService.activateAccount(userActivation).pipe(map(function () { return new activateAccountActions.ActivateSuccessAction(_this.notificationService.buildAccountActivationSuccessNotification()); }), catchError(function (errors) { return of(new activateAccountActions.ActivateFailAction(ErrorUtils.toNotification(errors))); }));
        }));
        this.activateAccountSuccess = this.actions$.pipe(ofType(activateAccountActions.AccountActivationActionTypes.ActivateSuccess), withLatestFrom(this.store.pipe(select(fromRoot.getCurrentUser))), map(function (_a) {
            var action = _a[0], user = _a[1];
            if (isPlatformBrowser(_this.platformId)) {
                var storageUser = _this.storageService.getItem(StorageKey.USER);
                // TODO to ameliorate
                var enabledUser = Object.assign(storageUser, { enabled: true });
                _this.storageService.setItem(StorageKey.USER, enabledUser);
            }
            if (user) {
                var redirectAction = new routeActions.ToCompleteUserProfileAction();
                if (user.filled) {
                    redirectAction = new routeActions.ToUserProfileAction();
                }
                _this.store.dispatch(new authenticationActions.TestUserAction());
                return redirectAction;
            }
            else {
                return new routeActions.ToSignInAction();
            }
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AccountActivationEffects.prototype, "activateAccount", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AccountActivationEffects.prototype, "activateAccountSuccess", void 0);
    return AccountActivationEffects;
}());
export { AccountActivationEffects };
