import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError as observableThrowError} from 'rxjs';

import {ErrorsDto, PasswordResetDto} from '../models';
import {HttpUtil} from '../utils';
import {ValidationService} from './validation.service';

@Injectable({
    providedIn: 'root'
})
export class PasswordService {

    constructor(private validationService: ValidationService,
                private httpClient: HttpClient) {
    }

    public resetPassword(email: string, token: string, passwordReset: PasswordResetDto): Observable<any> {
        let errorsDto: ErrorsDto = this.validationService.validatePasswordResetDto(passwordReset);

        if (errorsDto) {
            return observableThrowError(errorsDto);
        }

        let url = HttpUtil.buildAndExpand('api/v1/account/{email}/password/{token}', email, token);
        return this.httpClient.post(url, passwordReset);
    }

    public isPasswordResetTokenValid(email: string, token: string): Observable<boolean> {
        let url = HttpUtil.buildAndExpand('api/v1/account/{email}/password/{token}', email, token);
        return this.httpClient.get<boolean>(url);
    }

    public recoverPasswordByEmail(email: string): Observable<any> {
        let url = HttpUtil.buildAndExpand('api/v1/account/{email}/password/recover', email);
        return this.httpClient.post(url, null);
    }
}
