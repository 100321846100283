export var AccountReviewsActionTypes;
(function (AccountReviewsActionTypes) {
    AccountReviewsActionTypes["LoadUserReviews"] = "[ACCOUNT] LOAD_USER_REVIEWS";
    AccountReviewsActionTypes["LoadUserReviewsSuccess"] = "[ACCOUNT] LOAD_USER_REVIEWS_SUCCESS";
    AccountReviewsActionTypes["LoadUserReviewsFail"] = "[ACCOUNT] LOAD_USER_REVIEWS_FAIL";
    AccountReviewsActionTypes["LoadUserSentReviews"] = "[ACCOUNT] LOAD_USER_SENT_REVIEWS";
    AccountReviewsActionTypes["LoadUserSentReviewsSuccess"] = "[ACCOUNT] LOAD_USER_SENT_REVIEWS_SUCCESS";
    AccountReviewsActionTypes["LoadUserSentReviewsFail"] = "[ACCOUNT] LOAD_USER_SENT_REVIEWS_FAIL";
    AccountReviewsActionTypes["Reset"] = "[ACCOUNT] RESET";
})(AccountReviewsActionTypes || (AccountReviewsActionTypes = {}));
var LoadUserReviewsAction = /** @class */ (function () {
    function LoadUserReviewsAction(payload) {
        this.payload = payload;
        this.type = AccountReviewsActionTypes.LoadUserReviews;
    }
    return LoadUserReviewsAction;
}());
export { LoadUserReviewsAction };
var LoadUserReviewsSuccessAction = /** @class */ (function () {
    function LoadUserReviewsSuccessAction(payload) {
        this.payload = payload;
        this.type = AccountReviewsActionTypes.LoadUserReviewsSuccess;
    }
    return LoadUserReviewsSuccessAction;
}());
export { LoadUserReviewsSuccessAction };
var LoadUserReviewsFailAction = /** @class */ (function () {
    function LoadUserReviewsFailAction(payload) {
        this.payload = payload;
        this.type = AccountReviewsActionTypes.LoadUserReviewsFail;
    }
    return LoadUserReviewsFailAction;
}());
export { LoadUserReviewsFailAction };
var LoadUserSentReviewsAction = /** @class */ (function () {
    function LoadUserSentReviewsAction(payload) {
        this.payload = payload;
        this.type = AccountReviewsActionTypes.LoadUserSentReviews;
    }
    return LoadUserSentReviewsAction;
}());
export { LoadUserSentReviewsAction };
var LoadUserSentReviewsSuccessAction = /** @class */ (function () {
    function LoadUserSentReviewsSuccessAction(payload) {
        this.payload = payload;
        this.type = AccountReviewsActionTypes.LoadUserSentReviewsSuccess;
    }
    return LoadUserSentReviewsSuccessAction;
}());
export { LoadUserSentReviewsSuccessAction };
var LoadUserSentReviewsFailAction = /** @class */ (function () {
    function LoadUserSentReviewsFailAction(payload) {
        this.payload = payload;
        this.type = AccountReviewsActionTypes.LoadUserSentReviewsFail;
    }
    return LoadUserSentReviewsFailAction;
}());
export { LoadUserSentReviewsFailAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = AccountReviewsActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
