var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { routeActions } from '../actions';
import { NoticeType } from '../../models';
import { ParamsService, RouteService } from '../../services';
var RouteEffects = /** @class */ (function () {
    function RouteEffects(actions$, paramsService, routeService) {
        var _this = this;
        this.actions$ = actions$;
        this.paramsService = paramsService;
        this.routeService = routeService;
        this.toHome = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToHome), tap(function () {
            _this.routeService.toHome();
        }));
        this.toUrl = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToUrl), map(function (action) { return action.payload; }), tap(function (url) {
            _this.routeService.toUrl(url);
        }));
        this.toUserProfile = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToUserProfile), tap(function () {
            _this.routeService.toUserProfilePage();
        }));
        this.toWorker = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToWorker), map(function (action) { return action.payload; }), tap(function (uid) {
            _this.routeService.toWorker(uid);
        }));
        this.toCompleteUserProfile = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToCompleteUserProfile), tap(function () {
            _this.routeService.toCompleteUserProfile();
        }));
        this.toSignIn = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToSignIn), tap(function () {
            _this.routeService.toSignInPage();
        }));
        this.toConfirmRegistration = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToConfirmRegistration), map(function (action) { return action.payload; }), tap(function (payload) {
            var navigationExtras = {
                queryParams: _this.paramsService.buildRegistrationConfirmationParams(payload)
            };
            _this.routeService.toEmailConfirmation(navigationExtras);
        }));
        this.toPasswordForgotten = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToPasswordForgotten), map(function (action) { return action.payload; }), tap(function (email) {
            var navigationExtras;
            if (email) {
                navigationExtras = {
                    queryParams: _this.paramsService.buildPasswordForgottenParams(email)
                };
            }
            _this.routeService.toPasswordResetPage(navigationExtras);
        }));
        this.toRequest = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToRequest), map(function (action) { return action.payload; }), tap(function (requestId) {
            _this.routeService.toRequest(requestId);
        }));
        this.toUpdateRequest = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToUpdateRequest), map(function (action) { return action.payload; }), tap(function (requestId) {
            _this.routeService.toUpdateRequest(requestId);
        }));
        this.toRequests = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToRequests), tap(function () {
            _this.routeService.toRequests();
        }));
        this.toNotFound = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToPageNotFound), tap(function () {
            _this.routeService.toNotFound();
        }));
        this.toForbidden = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToForbidden), tap(function () {
            _this.routeService.toForbidden();
        }));
        this.toNoticeTarget = this.actions$.pipe(ofType(routeActions.RouteActionTypes.ToNoticeTarget), map(function (action) { return action.payload; }), tap(function (notice) {
            switch (notice.type) {
                case NoticeType.MESSAGE:
                    _this.routeService.toConversations();
                    break;
                case NoticeType.VERIFICATIONS:
                    _this.routeService.toProfileVerificationsPage();
                    break;
                case NoticeType.PROFILE:
                    _this.routeService.toUserProfilePage();
                    break;
            }
        }));
    }
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toHome", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toUrl", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toUserProfile", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toWorker", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toCompleteUserProfile", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toSignIn", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toConfirmRegistration", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toPasswordForgotten", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toRequest", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toUpdateRequest", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toRequests", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toNotFound", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toForbidden", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], RouteEffects.prototype, "toNoticeTarget", void 0);
    return RouteEffects;
}());
export { RouteEffects };
