import {ProfileViewComponent} from './profile-view/profile-view.component';
import {UsersSearchComponent} from './users-search/users-search.component';

export const USER_VIEWS: any[] = [
    UsersSearchComponent,
    ProfileViewComponent
];

export {
    UsersSearchComponent,
    ProfileViewComponent
}
