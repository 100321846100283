<div class="demo-gallery" *ngIf="medias?.length; else noMedias">

    <div appLightGallery class="list-unstyled justified-gallery">
        <a *ngFor="let media of medias" [href]="envUrl + media" class="item">
            <img class="img-responsive" [src]="envUrl + media">
            <div class="demo-gallery-poster">
                <img src="assets/images/zoom.png">
            </div>
        </a>
    </div>

</div>

<ng-template #noMedias>
    <span class="no-medias-message" translate>{{emptyMessage}}</span>
</ng-template>