import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {Store} from '@ngrx/store';
import {Pageable, SearchCriteriaDto} from '../../modules/core/models';
import {SeoService} from '../../modules/core/services';
import {routeActions} from '../../modules/core/store/actions';
import * as fromRoot from '../../modules/store/';
import {UserParamUtils} from '../../modules/users/utils';
import {HomeHelper} from './helper/home.helper';

@Component({
    moduleId: module.id,
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [HomeHelper]
})
export class HomeComponent implements OnInit {

    constructor(private store: Store<fromRoot.State>,
                private seo: SeoService,
                private vcRef: ViewContainerRef,
                private homeHelper: HomeHelper) {
        seo.setMeta('home');
        seo.setRobots(true);
    }

    search(searchCriteria: SearchCriteriaDto) {
        this.store.dispatch(new routeActions.ToSearchWorkersAction({searchCriteria: searchCriteria}));
    }

    ngOnInit(): void {
        this.homeHelper.hideActionBar();
    }

    showServiceDialog() {
        this.homeHelper.showServiceDialog(this.vcRef).subscribe(callback => {
            if (callback && callback.isClose()) {
                return;
            }

            const searchCriteria = callback.payload;
            const pageable = new Pageable();

            let params = UserParamUtils.toParams(searchCriteria, pageable);
            this.homeHelper.navigate(params);
        })
    }
}
