import {Action} from '@ngrx/store';

export enum MessageModalActionTypes {
     OpenMessageModal = '[MESSAGE_MODAL] OPEN_MESSAGE_MODAL',
     CloseMessageModal = '[MESSAGE_MODAL] CLOSE_MESSAGE_MODAL',
}

/**
 * Show send message modal.
 */
export class OpenMessageModalAction implements Action {
    readonly type = MessageModalActionTypes.OpenMessageModal;
}

export class CloseMessageModalAction implements Action {
    readonly type = MessageModalActionTypes.CloseMessageModal;
}

export type Actions = OpenMessageModalAction
    | CloseMessageModalAction;
