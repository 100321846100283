import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {defer, Observable, of} from 'rxjs';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {profileServicesChooserActions} from '../../../account/store/actions';
import {CommonUtils} from '../../utils';
import * as fromRoot from '../../../store';

import {categoryActions, searchActivityChooserActions} from '../actions';
import {CategoryService} from '../../services/category.service';

@Injectable()
export class CategoryEffects {

    @Effect()
    loadCategories: Observable<Action> = this.actions$.pipe(
        ofType(categoryActions.CategoryActionTypes.LoadCategories),
        mergeMap(() => {
                return this.categoryService.getCategories().pipe(
                    map(categories => new categoryActions.LoadCategoriesSuccessAction(categories)),
                    catchError(errors => of(new categoryActions.LoadCategoriesFailAction(errors)))
                )
            }
        )
    );

    @Effect()
    loadCategoriesIfNull: Observable<Action> = this.actions$.pipe(
        ofType(categoryActions.CategoryActionTypes.LoadCategoriesIfNull),
        withLatestFrom(this.store$.pipe(select(fromRoot.getCategories))),
        mergeMap(([action, categories]) => {

                if (CommonUtils.isEmpty(categories)) {
                    return [new categoryActions.LoadCategoriesAction()];
                }

                return [];
            }
        )
    );

    @Effect()
    selectSearchCategory: Observable<Action> = this.actions$.pipe(
        ofType(searchActivityChooserActions.SearchActivityChooserActionTypes.SelectCategory),
        map((action: searchActivityChooserActions.SelectCategoryAction) => action.payload),
        map(category => new searchActivityChooserActions.SetActivitiesAction(category.activities))
    );

    @Effect()
    selectUserCategory: Observable<Action> = this.actions$.pipe(
        ofType(profileServicesChooserActions.ProfileServicesChooserActionTypes.SelectCategory),
        map((action: profileServicesChooserActions.SelectCategoryAction) => action.payload),
        map(category => new profileServicesChooserActions.SetActivitiesAction(category.activities))
    );


    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     return of(new categoryActions.LoadCategoriesAction());
    // });

    constructor(private actions$: Actions,
                private categoryService: CategoryService,
                private store$: Store<fromRoot.State>) {
    }

}
