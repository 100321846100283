export var MessageModalActionTypes;
(function (MessageModalActionTypes) {
    MessageModalActionTypes["OpenMessageModal"] = "[MESSAGE_MODAL] OPEN_MESSAGE_MODAL";
    MessageModalActionTypes["CloseMessageModal"] = "[MESSAGE_MODAL] CLOSE_MESSAGE_MODAL";
})(MessageModalActionTypes || (MessageModalActionTypes = {}));
/**
 * Show send message modal.
 */
var OpenMessageModalAction = /** @class */ (function () {
    function OpenMessageModalAction() {
        this.type = MessageModalActionTypes.OpenMessageModal;
    }
    return OpenMessageModalAction;
}());
export { OpenMessageModalAction };
var CloseMessageModalAction = /** @class */ (function () {
    function CloseMessageModalAction() {
        this.type = MessageModalActionTypes.CloseMessageModal;
    }
    return CloseMessageModalAction;
}());
export { CloseMessageModalAction };
