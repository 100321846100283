import { ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Pageable } from '../../../../core/models';
var UsersSearchHelper = /** @class */ (function () {
    function UsersSearchHelper() {
    }
    UsersSearchHelper.prototype.initPageable = function () {
        return new Pageable();
    };
    UsersSearchHelper.prototype.clearRoute = function () {
    };
    UsersSearchHelper.prototype.initWorkers$ = function () {
    };
    UsersSearchHelper.prototype.pushPage = function (page) {
    };
    UsersSearchHelper.prototype.setLoadOnDemandeToAuto = function () {
    };
    Object.defineProperty(UsersSearchHelper.prototype, "dataItems", {
        get: function () {
            return this.workers$;
        },
        enumerable: true,
        configurable: true
    });
    UsersSearchHelper.prototype.lastPageLoaded = function (args) {
    };
    UsersSearchHelper.prototype.setArgsReturnValue = function () {
    };
    UsersSearchHelper.prototype.showServiceDialog = function (vcRef) {
        return new Observable();
    };
    return UsersSearchHelper;
}());
export { UsersSearchHelper };
