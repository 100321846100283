import { EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceService } from '../../../core/services';
import { ActivityUtils, CategoryUtils } from '../../../core/utils';
var ActivityChooserComponent = /** @class */ (function () {
    function ActivityChooserComponent(deviceService) {
        this.deviceService = deviceService;
        this.selectActivity = new EventEmitter();
        this.selectCategory = new EventEmitter();
        this.quit = new EventEmitter();
        // Display
        this.showCategories = true;
        this.showActivities = true;
        this.timeOutDelay = 150; // ms
    }
    ActivityChooserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.init();
        if (this.initListener) {
            this.initListener.subscribe(function () { return _this.init(); });
        }
    };
    ActivityChooserComponent.prototype.isCategorySelected = function (cat) {
        return CategoryUtils.containsCategory(this.selectedCategories, cat);
    };
    ActivityChooserComponent.prototype.isActivitySelected = function (act) {
        return ActivityUtils.containsActivity(this.selectedActivities, act);
    };
    ActivityChooserComponent.prototype.onSelectCategory = function (cat) {
        this.selectCategory.emit(cat);
        if (this.deviceService.isSmallDevice()) {
            this.showCategories = false;
            this.showActivities = true;
        }
    };
    ActivityChooserComponent.prototype.mouseOverCategory = function (cat) {
        var _this = this;
        clearTimeout(this.timeOutRef);
        if (this.deviceService.isSmallDevice()) {
            return;
        }
        this.timeOutRef = setTimeout(function () { return _this.onSelectCategory(cat); }, this.timeOutDelay);
    };
    ActivityChooserComponent.prototype.onSelectActivity = function (act) {
        this.selectActivity.emit(act);
    };
    ActivityChooserComponent.prototype.doGoBack = function () {
        if (this.showActivities) {
            this.showActivities = false;
            this.showCategories = true;
            return;
        }
        if (this.showCategories) {
            this.quit.emit();
            return;
        }
    };
    ActivityChooserComponent.prototype.onResize = function (event) {
        var windowWidth = event.target.innerWidth;
        if (windowWidth > this.deviceService.SM_MAX_WIDTH) {
            this.showCategories = true;
            this.showActivities = true;
        }
        else {
            this.smallDeviceInit();
        }
    };
    ActivityChooserComponent.prototype.init = function () {
        if (this.deviceService.isSmallDevice()) {
            this.smallDeviceInit();
        }
        this.activities = [];
    };
    ActivityChooserComponent.prototype.smallDeviceInit = function () {
        this.showCategories = true;
        this.showActivities = false;
    };
    ActivityChooserComponent.prototype.mouseOverActivities = function () {
        clearTimeout(this.timeOutRef);
    };
    return ActivityChooserComponent;
}());
export { ActivityChooserComponent };
