import { UserRole } from '../models';
var UserAccountUtils = /** @class */ (function () {
    function UserAccountUtils() {
    }
    UserAccountUtils.hasRole = function (userAccount, role) {
        if (!userAccount || !userAccount.roles) {
            return false;
        }
        var found = userAccount.roles.find(function (r) { return r.name === role; });
        return found != null;
    };
    UserAccountUtils.isUserAccountAdmin = function (userAccount) {
        return UserAccountUtils.hasRole(userAccount, UserRole.ROLE_ADMIN);
    };
    UserAccountUtils.isUserAccountManager = function (userAccount) {
        if (UserAccountUtils.isUserAccountAdmin(userAccount)) {
            return true;
        }
        return UserAccountUtils.hasRole(userAccount, UserRole.ROLE_MANAGER);
    };
    UserAccountUtils.isUserAccountVerifier = function (userAccount) {
        if (UserAccountUtils.isUserAccountManager(userAccount)) {
            return true;
        }
        return UserAccountUtils.hasRole(userAccount, UserRole.ROLE_VERIFIER);
    };
    UserAccountUtils.isSessionAdmin = function (session) {
        if (!session) {
            return false;
        }
        return UserAccountUtils.isUserAccountAdmin(session.user);
    };
    UserAccountUtils.isSameUserAccountByUid = function (userAccount, uid) {
        if (!userAccount) {
            return false;
        }
        return userAccount.uid === uid;
    };
    UserAccountUtils.isSameUser = function (user, otherUser) {
        if (!otherUser || !user) {
            return false;
        }
        return UserAccountUtils.isSameUserAccountByUid(user, otherUser.uid);
    };
    return UserAccountUtils;
}());
export { UserAccountUtils };
