import {Action} from '@ngrx/store';

export enum LoginModalActionTypes {
    OpenModal = '[LOGIN MODAL] OPEN_MODAL',
    CloseModal = '[LOGIN MODAL] CLOSE_MODAL'
}

export class OpenModalAction implements Action {
    readonly type = LoginModalActionTypes.OpenModal;
}

export class CloseModalAction implements Action {
    readonly type = LoginModalActionTypes.CloseModal;
}

export type Actions = OpenModalAction
    | CloseModalAction;
