import { EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification, UserLoginFormDto } from '../../../../core/models';
var LoginComponent = /** @class */ (function () {
    function LoginComponent() {
        this.login = new EventEmitter();
        this.userLogin = new UserLoginFormDto();
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initListener$.subscribe(function () {
            _this.userLogin.login = null;
            _this.userLogin.password = null;
        });
    };
    LoginComponent.prototype.submit = function (form) {
        if (!form.valid) {
            return;
        }
        this.login.emit(this.userLogin);
    };
    return LoginComponent;
}());
export { LoginComponent };
