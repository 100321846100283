import {AddressDto} from '../models';

const _includes = require('lodash/includes');
import {Config} from '../../../config';

export class AddressUtils {

    static buildFromGooglePlaceResult(place: any): AddressDto {

        if (!place) {
            return null;
        }

        let address = new AddressDto();
        address.placeId = place.place_id;
        address.formatted = place.formatted_address;

        // FIXME

        if (Config.IS_WEB()) {
            address.location = {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
            };
        } else {
            address.location = {
                latitude: place.geometry.location.lat,
                longitude: place.geometry.location.lng
            };

        }


        let addressComponents = place.address_components;

        address.country = this.getAddressComponentLongName(addressComponents, 'country');
        address.streetNumber = this.getAddressComponentLongName(addressComponents, 'street_number');
        address.route = this.getAddressComponentLongName(addressComponents, 'route');

        let locality = place.vicinity;
        if (!locality) {
            locality = this.getAddressComponentLongName(addressComponents, 'locality')
        }

        address.locality = locality;
        address.postalCode = this.getAddressComponentLongName(addressComponents, 'postal_code');

        return address;
    }

    private static getAddressComponentLongName(addressComponents: Array<any>, type: string): string {
        let component = AddressUtils.getAddressComponent(addressComponents, type);

        if (component && component['long_name']) {
            return component['long_name'];
        }

        return null;
    }

    private static getAddressComponent(addressComponents: Array<any>, type: string) {
        let fn = addressComponents.find((element) => {
            return _includes(element.types, type);
        });
        return fn;
    }

}
