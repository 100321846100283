import {ErrorsDto, SearchCriteriaDto, UserResultDto, Page} from '../../../core/models';

import {userSearchActions} from '../actions';

export interface State {
    result: Page<UserResultDto>;
    totalElements: number;
    loading: boolean;
    errors: ErrorsDto;
    searchCriteria: SearchCriteriaDto;
}

export const initialState: State = {
    result: null,
    totalElements: null,
    loading: false,
    errors: null,
    searchCriteria: null
};

export function reducer(state = initialState, action: userSearchActions.Actions): State {

    switch (action.type) {

        case userSearchActions.UsersSearchActionTypes.Search:
        case userSearchActions.UsersSearchActionTypes.SearchAroundIpOrLast: {
            return Object.assign(
                {},
                initialState,
                {loading: true}
            )
        }

        case userSearchActions.UsersSearchActionTypes.SearchSuccess: {
            return Object.assign(
                {},
                state,
                {
                    result: action.payload,
                    totalElements: action.payload.totalElements,
                    loading: false,
                    errors: null
                }
            );
        }

        case userSearchActions.UsersSearchActionTypes.SearchAroundIpOrLastSuccess: {
            return Object.assign(
                {},
                state,
                {
                    result: action.payload.page,
                    totalElements: action.payload.page.totalElements,
                    loading: false,
                    errors: null
                }
            );
        }

        case userSearchActions.UsersSearchActionTypes.SearchFailure:
        case userSearchActions.UsersSearchActionTypes.SearchAroundIpOrLastFail: {
            return Object.assign(
                {},
                initialState,
                {
                    loading: false,
                    errors: action.payload
                }
            )
        }

        case userSearchActions.UsersSearchActionTypes.SetSearchCriteriaAddress: {
            let searchCriteria = Object.assign({}, state.searchCriteria);

            if (!searchCriteria) {
                searchCriteria = new SearchCriteriaDto();
            }

            searchCriteria.address = action.payload;

            state.searchCriteria = searchCriteria;

            return state;
        }

        case userSearchActions.UsersSearchActionTypes.SetSearchCriteriaService: {
            let searchCriteria = Object.assign({}, state.searchCriteria);

            if (!searchCriteria) {
                searchCriteria = new SearchCriteriaDto();
            }

            searchCriteria.activity = action.payload;

            state.searchCriteria = searchCriteria;

            return state;
        }

        case userSearchActions.UsersSearchActionTypes.Reset : {
            return Object.assign(
                {},
                initialState
            )
        }

        default: {
            return state;
        }
    }
}

export const getResultPage = (state: State): Page<UserResultDto> => state.result;
export const getTotalElements = (state: State): number => state.totalElements;
export const isLoading = (state: State): boolean => state.loading;
export const getErrors = (state: State): ErrorsDto => state.errors;
export const getSearchCriteria = (state: State): SearchCriteriaDto => state.searchCriteria;
