<div class="container-fluid sub-header" yfLayoutClass>
    <div class="container">
        <h1 translate>reset-password.title</h1>
    </div>
</div>

<div class="container mt-4" yfLayoutClass>

    <div class="card">

        <div class="card-header center">
            <span class="text-white" translate>reset-password.title</span>
        </div>

        <div class="card-block center">

            <div class="row mb-0 justify-content-center">

                <app-spinner *ngIf="tokenValidationLoading$ | async; else loaded"></app-spinner>

                <ng-template #loaded>
                    <form materialize class="col-md-8 col-lg-6" (ngSubmit)="submit(passwordReset)"
                          [formGroup]="passwordReset"
                          *ngIf="isTokenValid$ | async; else tokenInvalid">

                        <app-notification [notification]="passwordResetNotification$ | async"></app-notification>

                        <button type="button" class="btn btn-default"
                                routerLink="/login"
                                *ngIf="isPasswordReseted$ | async; else formInputs" translate>
                            reset-password.go-to-sign-up
                        </button>

                        <ng-template #formInputs>
                            <div class="input-field">
                                <input type="password" id="password" name="password"
                                       class="form-control" formControlName="newPassword">
                                <label for="password" translate>account.password-modal.new-password</label>
                            </div>

                            <app-control-messages [control]="passwordReset" controlName="newPassword" keyPrefix="errors.password-reset" forLabel="password"></app-control-messages>

                            <div class="input-field">
                                <input type="password" id="passwordConfirmation" name="passwordConfirmation"
                                       class="form-control" formControlName="newPasswordConfirmation">
                                <label for="passwordConfirmation"
                                       translate>account.password-modal.new-password-confirm</label>
                            </div>

                            <app-control-messages [control]="passwordReset" controlName="newPasswordConfirmation" keyPrefix="errors.password-reset" forLabel="passwordConfirmation"></app-control-messages>

                            <button type="submit" class="btn btn-default col-12 mt-4"
                                    [disabled]="passwordReset.invalid || (passwordResetLoading$ | async)" translate>
                                reset-password.reset
                            </button>
                        </ng-template>
                    </form>

                    <ng-template #tokenInvalid>
                        <span translate>reset-password.invalid-link</span>
                    </ng-template>
                </ng-template>

            </div>
        </div>
    </div>
</div>

