import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as authenticationActions from '../../modules/core/store/actions/authentication.action';
import * as routeActions from '../../modules/core/store/actions/route.action';
import { NotificationService, RouteService, SeoService } from '../../modules/core/services';
import { CommonUtils, ErrorUtils } from '../../modules/core/utils';
import { BcValidators } from '../../modules/core/validators';
import * as fromRoot from '../../modules/store';
import { SignInHelper } from './helper/sign-in.helper';
var SignInComponent = /** @class */ (function () {
    function SignInComponent(store, route, routeService, notificationService, fb, seo, signInHelper) {
        this.store = store;
        this.route = route;
        this.routeService = routeService;
        this.notificationService = notificationService;
        this.fb = fb;
        this.seo = seo;
        this.signInHelper = signInHelper;
        this.subscription = new Subscription();
        this.loading$ = this.store.pipe(select(fromRoot.isAuthLoading));
        this.authenticated$ = this.store.pipe(select(fromRoot.isAuthenticated));
        this.notification$ = this.store.pipe(select(fromRoot.getAuthErrors), map(ErrorUtils.toNotification));
        this.userLogin = this.fb.group({
            login: ['', Validators.compose([Validators.required, BcValidators.login])],
            password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(25)])]
        });
        this.seo.setMeta('sign-in');
        this.seo.setRobots(true);
    }
    SignInComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.socialNotification = null;
        this.route.queryParams.pipe(filter(function (params) { return params.error; }))
            .subscribe(function () {
            setTimeout(function () { return _this.socialNotification = _this.notificationService.buildSocialAuthFailNotification(); }, 300);
        });
        var sub = this.notification$.pipe(filter(CommonUtils.notEmpty))
            .subscribe(function (notification) {
            _this.alertNotification(notification);
        });
        this.subscription.add(sub);
    };
    SignInComponent.prototype.ngOnDestroy = function () {
        this.routeService.redirectUrl = null;
        this.subscription.unsubscribe();
    };
    SignInComponent.prototype.submit = function (form) {
        if (form.invalid) {
            this.notificationService.buildValidationErrorNotification();
            return;
        }
        this.redirect = true;
        this.store.dispatch(new authenticationActions.AuthenticateAction(this.userLogin.value));
    };
    SignInComponent.prototype.toRegisterConfirm = function () {
        var data = {
            email: this.userLogin.value.login,
            postSignUp: false
        };
        this.store.dispatch(new routeActions.ToConfirmRegistrationAction(data));
    };
    SignInComponent.prototype.onLogin = function (eventData) {
        if (eventData.error) {
            this.alertNotification(this.notificationService.buildGlobalErrorNotification());
        }
        else {
            this.store.dispatch(new authenticationActions.SocialAuthenticateAction(eventData.loginResponse.token));
        }
    };
    SignInComponent.prototype.alertNotification = function (notif) {
        this.signInHelper.alertNotification(notif);
    };
    return SignInComponent;
}());
export { SignInComponent };
