<app-modal modalId="share-modal-id"
           modalClass="share-modal"
           (close)="close()"
           [modalEventListener]="modalEvent$"
           [showHeader]="false">

    <div class="row align-items-center center share-modal-content" modal-content>

        <div class="col-12">
            <h1 translate>{{titleKey}}</h1>
        </div>

        <div class="col-12" *ngIf="element">
            <share-buttons [showText]="true"
                           [url]="url"
                           [title]="title"
                           [description]="description"
                           [image]="image">
            </share-buttons>
        </div>
    </div>

</app-modal>