<div class="row" [ngsRevealSet]="{reset:true}" [ngsInterval]="100" [ngsSelector]="'.sr-icons'" yfLayoutClass>

    <div class="col-md-4 text-center">
        <div class="service-box">
            <i class="large material-icons">recent_actors</i>
            <h3 translate>services-promo.variety.title</h3>
            <p class="text-muted" translate>services-promo.variety.text</p>
        </div>
    </div>

    <div class="col-md-4 text-center">
        <div class="service-box">
            <i class="large material-icons">person_pin_circle</i>
            <h3 translate>services-promo.target.title</h3>
            <p class="text-muted" translate>services-promo.target.text</p>
        </div>
    </div>

    <div class="col-md-4 text-center">
        <div class="service-box">
            <i class="large material-icons">access_alarm</i>
            <h3 translate>services-promo.time.title</h3>
            <p class="text-muted" translate>services-promo.time.text</p>
        </div>
    </div>

</div>

<div class="row mt-md-5">
    <div class="col-md-4 text-center">
        <div class="service-box">
            <i class="large material-icons">dashboard</i>
            <h3 translate>services-promo.personal-account.title</h3>
            <p class="text-muted" translate>services-promo.personal-account.text</p>
        </div>
    </div>

    <div class="col-md-4 text-center">
        <div class="service-box">
            <i class="large material-icons">security</i>
            <h3 translate>services-promo.security.title</h3>
            <p class="text-muted" translate>services-promo.security.text</p>
        </div>
    </div>

    <div class="col-md-4 text-center">
        <div class="service-box">
            <i class="large material-icons">equalizer</i>
            <h3 translate>services-promo.optimize.title</h3>
            <p class="text-muted" translate>services-promo.optimize.text</p>
        </div>
    </div>

</div>