<form materialize #filterForm="ngForm" (ngSubmit)="apply(filterForm)">

    <ul class="collapsible service-filter" data-collapsible="accordion" materialize="collapsible">
        <li>
            <div class="collapsible-header active" translate><i class="material-icons">build</i>global.service</div>
            <div class="collapsible-body">

                <div class="chip" *ngIf="searchCriteria.activity">
                    {{searchCriteria.activity | activityTranslator}}
                    <i class="close material-icons" (click)="deleteService()">close</i>
                </div>

                <ul class="service-filer-list">
                    <li *ngFor="let cat of categories">
                        <ul>
                            <li class="cat-name">{{cat | categoryTranslator}}</li>
                            <li *ngFor="let act of cat.activities"
                                class="act-name filter-item" [class.selected]="act.id === searchCriteria?.activity?.id"
                                (click)="selectService(act)">
                                {{act | activityTranslator}}
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </li>
    </ul>

    <ul class="collapsible radius-filter" data-collapsible="accordion" materialize="collapsible">
        <li>
            <div class="collapsible-header active" translate><i class="material-icons" translate>adjust</i>global.radius</div>
            <div class="collapsible-body">

                <div class="row mb-0">
                    <div class="col-12">
                        <nouislider name="radius"
                                    [connect]="[true, false]"
                                    [min]="0"
                                    [max]="100"
                                    [step]="5"
                                    [(ngModel)]="searchCriteria.radius"
                                    [tooltips]="[ true ]">
                        </nouislider>
                    </div>
                </div>
                <!--<div class="input-field">-->
                <!--<input type="text" id="radius"-->
                <!--[(ngModel)]="searchCriteria.radius"-->
                <!--name="radius"-->
                <!--#radius="ngModel"-->
                <!--min="1"-->
                <!--max="100">-->
                <!--<label for="radius">Rayon de recherche en KM</label>-->
                <!--</div>-->
                <!--<div *ngIf="radius.invalid" class="input-error">-->
                <!--<div *ngIf="radius.errors.max">-->
                <!--<label class="invalid" for="radius">-->
                <!--Le rayon de recherche doit être inférieur à 100 KM.-->
                <!--</label>-->
                <!--</div>-->
                <!--<div *ngIf="radius.errors.min">-->
                <!--<label class="invalid" for="radius">-->
                <!--Le rayon de recherche doit être supérieur ou égal à 1 KM.-->
                <!--</label>-->
                <!--</div>-->
                <!--</div>-->
            </div>
        </li>
    </ul>

    <div class="row">
        <div class="col-12">
            <button class="btn btn-primary w-100" translate>global.apply</button>
        </div>
        <div class="col-12 mt-2">
            <button type="button" (click)="reInit()" class="btn-flat w-100" translate>global.reset</button>
        </div>
    </div>

</form>

