<div class="container-fluid sub-header" yfLayoutClass>
    <div class="container p-1">
        <div class="row mb-0">
            <h1 class="h4" translate>reset-password.title</h1>
        </div>
    </div>
</div>

<div class="container mt-4" yfLayoutClass>

    <div class="row justify-content-center">
        <div class="col-md-8">

            <div class="card">

                <div class="card-header center">
                    <span class="text-white" translate>reset-password.set-email</span>
                </div>

                <div class="card-block">

                    <div class="row justify-content-center">
                        <form materialize (ngSubmit)="submit(passwordForgottenForm)" class="col-md-8"
                              #passwordForgottenForm="ngForm">

                            <app-notification [notification]="notification$ | async"></app-notification>

                            <div class="input-field">
                                <input type="text" id="email" [formControl]="email" name="email">
                                <label for="email" translate>global.login</label>
                            </div>

                            <app-control-messages [control]="email" forLabel="email" keyPrefix="errors.login"></app-control-messages>

                            <button type="submit"
                                    class="btn btn-default col-12 mt-2"
                                    [disabled]="passwordForgottenForm.invalid || (loading$ | async)">
                                    <span class="hidden-md-up" translate>
                                        <i class="fa fa-paper-plane"
                                           aria-hidden="true"></i> global.send
                                    </span>
                                <span class="hidden-sm-down" translate>reset-password.reset</span>
                            </button>

                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
