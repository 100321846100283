/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-chooser-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../modal/modal.component.ngfactory";
import * as i4 from "../modal/modal.component";
import * as i5 from "../../../../shared/components/service-chooser/service-chooser.component.ngfactory";
import * as i6 from "../../../../shared/components/service-chooser/service-chooser.component";
import * as i7 from "../../../../core/services/device.service";
import * as i8 from "@angular/common";
import * as i9 from "./service-chooser-modal.component";
var styles_ActivityChooserModalComponent = [i0.styles];
var RenderType_ActivityChooserModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivityChooserModalComponent, data: {} });
export { RenderType_ActivityChooserModalComponent as RenderType_ActivityChooserModalComponent };
function View_ActivityChooserModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["modal-footer", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-primary right"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.validate.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["global.validate"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn-flat right"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.quit.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["global.close"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = ""; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ActivityChooserModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-modal", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.quit.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ModalComponent_0, i3.RenderType_ModalComponent)), i1.ɵdid(1, 180224, null, 0, i4.ModalComponent, [], { modalId: [0, "modalId"], modalClass: [1, "modalClass"], showFooter: [2, "showFooter"], modalEventListener: [3, "modalEventListener"] }, { close: "close" }), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h1", [["class", "col my-0"], ["modal-header", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, 1, 1, "app-activity-chooser", [["modal-content", ""]], null, [[null, "selectActivity"], [null, "selectCategory"], [null, "quit"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectActivity" === en)) {
        var pd_1 = (_co.selectActivity.emit($event) !== false);
        ad = (pd_1 && ad);
    } if (("selectCategory" === en)) {
        var pd_2 = (_co.selectCategory.emit($event) !== false);
        ad = (pd_2 && ad);
    } if (("quit" === en)) {
        var pd_3 = (_co.quit.emit($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_ActivityChooserComponent_0, i5.RenderType_ActivityChooserComponent)), i1.ɵdid(5, 114688, null, 0, i6.ActivityChooserComponent, [i7.DeviceService], { categories: [0, "categories"], activities: [1, "activities"], selectedCategories: [2, "selectedCategories"], selectedActivities: [3, "selectedActivities"], initListener: [4, "initListener"] }, { selectActivity: "selectActivity", selectCategory: "selectCategory", quit: "quit" }), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_ActivityChooserModalComponent_1)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalId; var currVal_1 = _co.modalClass; var currVal_2 = _co.showFooter; var currVal_3 = _co.modalEventListener; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.categories; var currVal_6 = _co.activities; var currVal_7 = _co.selectedCategories; var currVal_8 = _co.selectedActivities; var currVal_9 = _co.initListener; _ck(_v, 5, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.showFooter; _ck(_v, 7, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.title; _ck(_v, 3, 0, currVal_4); }); }
export function View_ActivityChooserModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activity-chooser-modal", [], null, null, null, View_ActivityChooserModalComponent_0, RenderType_ActivityChooserModalComponent)), i1.ɵdid(1, 49152, null, 0, i9.ActivityChooserModalComponent, [], null, null)], null, null); }
var ActivityChooserModalComponentNgFactory = i1.ɵccf("app-activity-chooser-modal", i9.ActivityChooserModalComponent, View_ActivityChooserModalComponent_Host_0, { modalId: "modalId", modalClass: "modalClass", title: "title", modalEventListener: "modalEventListener", showFooter: "showFooter", initListener: "initListener", categories: "categories", activities: "activities", selectedCategories: "selectedCategories", selectedActivities: "selectedActivities" }, { selectActivity: "selectActivity", selectCategory: "selectCategory", quit: "quit", validate: "validate" }, []);
export { ActivityChooserModalComponentNgFactory as ActivityChooserModalComponentNgFactory };
