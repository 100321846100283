import { ReviewRatingDto, ReviewStatus } from '../models';
var ReviewUtils = /** @class */ (function () {
    function ReviewUtils() {
    }
    ReviewUtils.getReviewRatings = function () {
        return ReviewUtils.reviewRatings;
    };
    ReviewUtils.build = function (value, text) {
        var rr = new ReviewRatingDto();
        rr.text = text;
        rr.value = value;
        return rr;
    };
    ReviewUtils.isStatusPending = function (review) {
        return ReviewUtils.hasStatus(review, ReviewStatus.PENDING);
    };
    ReviewUtils.hasStatus = function (review, status) {
        return review && review.status === status;
    };
    ReviewUtils.reviewRatings = [
        ReviewUtils.build(4, 'Parfait'),
        ReviewUtils.build(3, 'Très bien'),
        ReviewUtils.build(2, 'Bien'),
        ReviewUtils.build(1, 'Décevant'),
        ReviewUtils.build(0, 'A éviter')
    ];
    return ReviewUtils;
}());
export { ReviewUtils };
