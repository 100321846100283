import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {LightGalleryDirective} from '../../directives';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnChanges {

    @Input() medias: string[];
    @Input() envUrl = 'medias/';
    @Input() emptyMessage = 'profile-view.achievements-empty';

    @ViewChild(LightGalleryDirective, {static: false}) lightGalleryDirective: LightGalleryDirective;

    constructor(private cdRef: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {

        let mediasChange = changes['medias'];
        if (mediasChange && !mediasChange.isFirstChange()) {

            this.cdRef.detectChanges();
            if (this.lightGalleryDirective) {
                this.lightGalleryDirective.refresh();
            }
        }

    }
}
