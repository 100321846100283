import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as fromRoot from '../../store';
import { Locale } from '../models';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var ActivityTranslatorService = /** @class */ (function () {
    function ActivityTranslatorService(store) {
        var _this = this;
        this.store = store;
        this.nameGetter = ActivityTranslatorService.frNameGetter;
        this.store.pipe(select(fromRoot.getLocale), filter(function (locale) { return locale != null; }))
            .subscribe(function (locale) {
            if (!locale) {
                locale = Locale.ENGLISH;
            }
            switch (locale.shortcut.toLowerCase()) {
                case Locale.FRENCH.shortcut:
                    _this.nameGetter = ActivityTranslatorService.frNameGetter;
                    break;
                case Locale.ENGLISH.shortcut:
                    _this.nameGetter = ActivityTranslatorService.enNameGetter;
                    break;
                case Locale.ARABIC.shortcut:
                    _this.nameGetter = ActivityTranslatorService.arNameGetter;
                    break;
                case Locale.MOROCCAN.shortcut:
                    _this.nameGetter = ActivityTranslatorService.maNameGetter;
                    break;
            }
        });
    }
    ActivityTranslatorService.getArNameGetter = function () {
        var fn = function (activity) { return activity.arName; };
        return fn;
    };
    ActivityTranslatorService.getFrNameGetter = function () {
        var fn = function (activity) { return activity.name; };
        return fn;
    };
    ActivityTranslatorService.getEnNameGetter = function () {
        var fn = function (activity) { return activity.enName; };
        return fn;
    };
    ActivityTranslatorService.getMaNameGetter = function () {
        var fn = function (activity) { return activity.maName; };
        return fn;
    };
    ActivityTranslatorService.prototype.translate = function (value) {
        return this.nameGetter(value);
    };
    ActivityTranslatorService.prototype.translateArray = function (value) {
        var _this = this;
        return value.map(function (act) { return _this.translate(act); });
    };
    ActivityTranslatorService.prototype.getDescription = function (activities) {
        var description = '';
        if (activities) {
            var length_1 = activities.length;
            description = this.getName(activities[0]);
            for (var i = 1; i < 3; ++i) {
                if (i < length_1) {
                    description += ", " + this.getName(activities[i]);
                }
            }
            if (length_1 > 3) {
                description += length_1 - 3 + ' autres services';
            }
        }
        return description;
    };
    ActivityTranslatorService.prototype.getName = function (act) {
        return this.nameGetter(act);
    };
    ActivityTranslatorService.arNameGetter = ActivityTranslatorService.getArNameGetter();
    ActivityTranslatorService.enNameGetter = ActivityTranslatorService.getEnNameGetter();
    ActivityTranslatorService.maNameGetter = ActivityTranslatorService.getMaNameGetter();
    ActivityTranslatorService.frNameGetter = ActivityTranslatorService.getFrNameGetter();
    ActivityTranslatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivityTranslatorService_Factory() { return new ActivityTranslatorService(i0.ɵɵinject(i1.Store)); }, token: ActivityTranslatorService, providedIn: "root" });
    return ActivityTranslatorService;
}());
export { ActivityTranslatorService };
