import {ConversationDto, MessageDto, MessageReceivingStatus} from '../models';

export class ConversationUtils {

    public static hasConversationUnreadMessages(conversation: ConversationDto): boolean {
        return ConversationUtils.hasConversationMessagesStatus(conversation, MessageReceivingStatus.UNREAD);
    }

    public static hasConversationMessagesStatus(conversation: ConversationDto, receivingStatus: MessageReceivingStatus): boolean {

        if (!conversation) {
            return false;
        }

        return ConversationUtils.hasMessagesReceivingStatus(conversation.messages, receivingStatus);
    }

    public static hasMessagesReceivingStatus(messages: MessageDto[], receivingStatus: MessageReceivingStatus): boolean {

        if (!messages) {
            return false;
        }

        return messages.find(m => !m.sender && m.receivingStatus === receivingStatus) != null;
    }

}
