import {Directive, ElementRef, OnInit} from '@angular/core';
import * as justifiedGallery from 'justifiedGallery/dist/js/jquery.justifiedGallery.js';
import {BrowserService} from '../../core/services';

declare var jQuery: any;
declare var window: any;

// window.$.justifiedGallery = require('justifiedGallery/dist/js/jquery.justifiedGallery.js');

require('lightgallery');
require('lg-thumbnail');

@Directive({
    selector: '[appLightGallery]',
})
export class LightGalleryDirective implements OnInit {

    gallery;

    constructor(private el: ElementRef,
                private browserService: BrowserService) {
    }

    ngOnInit(): void {

        if (this.browserService.isPlatformBrowser()) {
            this.gallery = (<any>jQuery(this.el.nativeElement));
            window.$.justifiedGallery = justifiedGallery;
            jQuery(document).ready(() => this.buildGallery());
        }
    }

    refresh() {
        if (!this.gallery) {
            return;
        }

        this.gallery.justifiedGallery('destroy');

        if (this.gallery.data('lightGallery')) {
            this.gallery.data('lightGallery').destroy(true);
        }

        this.buildGallery();
    }

    private buildGallery() {
        this.gallery.justifiedGallery();
        this.gallery.lightGallery({
            thumbnail: true,
            selector: '.item'
        });
    }

}
