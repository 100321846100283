import { CommonUtils } from './common.util';
import { FlatServiceDto, ServiceType } from '../models/flat-service';
var CategoryUtils = /** @class */ (function () {
    function CategoryUtils() {
    }
    CategoryUtils.containsCategory = function (categories, category) {
        if (!category) {
            return false;
        }
        return this.containsById(categories, category.id);
    };
    CategoryUtils.containsById = function (categories, categoryId) {
        return this.getById(categories, categoryId) != null;
    };
    CategoryUtils.getById = function (categories, categoryId) {
        if (!categories) {
            return null;
        }
        var find = categories.find(function (c) { return c.id === categoryId; });
        if (!find) {
            return null;
        }
        return find;
    };
    CategoryUtils.getCategoriesOfActivities = function (activities) {
        var categories = [];
        if (!activities) {
            return categories;
        }
        activities.forEach(function (act) {
            var cat = act.category;
            if (!CategoryUtils.containsCategory(categories, cat)) {
                categories.push(cat);
            }
        });
        return categories;
    };
    CategoryUtils.toCategoriesTree = function (activities) {
        var _this = this;
        var categories = [];
        if (!activities) {
            return categories;
        }
        activities.forEach(function (act) {
            var category = CommonUtils.cloneDeep(act.category);
            var cat = _this.getById(categories, category.id);
            if (!cat) {
                cat = category;
                categories.push(cat);
            }
            if (!cat.activities) {
                cat.activities = [];
            }
            cat.activities.push(act);
        });
        return categories;
    };
    CategoryUtils.toFlatServices = function (categories) {
        var services = [];
        categories.forEach(function (category) {
            var flatService = new FlatServiceDto();
            flatService.id = category.id;
            flatService.name = category.name;
            flatService.type = ServiceType.CATEGORY;
            services.push(flatService);
            category.activities.forEach(function (act) {
                var fs = new FlatServiceDto();
                fs.id = act.id;
                fs.name = act.name;
                fs.type = ServiceType.ACTIVITY;
                services.push(fs);
            });
        });
        return services;
    };
    return CategoryUtils;
}());
export { CategoryUtils };
