import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as ifvisible from 'ifvisible.js';
import { NoticeService } from '../../../core/services';
import { authenticationActions } from '../../../core/store/actions';
import { UserAccountUtils } from '../../../core/utils';
import * as fromRoot from '../../../store';
var NavBarComponent = /** @class */ (function () {
    function NavBarComponent(platformId, store, accountStore, noticeService, cdRef) {
        this.platformId = platformId;
        this.store = store;
        this.accountStore = accountStore;
        this.noticeService = noticeService;
        this.cdRef = cdRef;
        this.UserAccountUtils = UserAccountUtils;
        this.authenticated$ = this.store.pipe(select(fromRoot.isAuthenticated));
        this.currentUser$ = this.store.pipe(select(fromRoot.getCurrentUser));
        this.timeoutInterval = 60 * 1000;
    }
    NavBarComponent.prototype.ngOnInit = function () {
        // this.noticeService.ngOnInit();
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            $('.button-collapse').sideNav();
            ifvisible.on('wakeup', function () {
                _this.countConversation();
            });
            this.authenticated$
                .subscribe(function (authed) {
                _this.authed = authed;
                if (authed) {
                    _this.countConversation();
                    _this.conversationCountIntervalId = setInterval(function () { return _this.countConversation(); }, _this.timeoutInterval);
                }
                else {
                    _this.destroyConversationCount();
                }
            });
        }
        this.store.pipe(select(fromRoot.getNotices))
            .subscribe(function (notices) { return _this.notices = notices; });
        this.store.pipe(select(fromRoot.getUnreadConversationsCount))
            .subscribe(function (count) {
            _this.unreadMessagesCount = count;
            _this.cdRef.detectChanges();
        });
    };
    NavBarComponent.prototype.destroyConversationCount = function () {
        clearInterval(this.conversationCountIntervalId);
    };
    NavBarComponent.prototype.logout = function () {
        this.store.dispatch(new authenticationActions.LogOutAction());
    };
    NavBarComponent.prototype.countConversation = function () {
        if (!ifvisible.now() || !this.authed) {
            return;
        }
        this.store.dispatch(new authenticationActions.CountUnreadConversationsAction());
    };
    return NavBarComponent;
}());
export { NavBarComponent };
