import { VerificationStatus } from '../models';
var VerificationUtils = /** @class */ (function () {
    function VerificationUtils() {
    }
    VerificationUtils.isStatusPending = function (status) {
        return VerificationUtils.isStatusEquals(status, VerificationStatus.PENDING);
    };
    VerificationUtils.isStatusNone = function (status) {
        return VerificationUtils.isStatusEquals(status, VerificationStatus.NONE);
    };
    VerificationUtils.isStatusRejected = function (status) {
        return VerificationUtils.isStatusEquals(status, VerificationStatus.REJECTED);
    };
    VerificationUtils.isStatusApproved = function (status) {
        return VerificationUtils.isStatusEquals(status, VerificationStatus.APPROVED);
    };
    VerificationUtils.isStatusEquals = function (status, expected) {
        return status === expected;
    };
    return VerificationUtils;
}());
export { VerificationUtils };
