import * as translationAr from 'assets/i18n/ar.json';
import * as translationArMa from 'assets/i18n/ar_ma.json';
import * as translationEN from 'assets/i18n/en.json';
import * as translationFr from 'assets/i18n/fr.json';
import { of } from 'rxjs';
var TRANSLATIONS = {
    ar: translationAr,
    fr: translationFr,
    en: translationEN,
    ar_ma: translationArMa
};
var TranslateServerLoader = /** @class */ (function () {
    function TranslateServerLoader() {
    }
    TranslateServerLoader.prototype.getTranslation = function (lang) {
        var translation = TRANSLATIONS[lang];
        return of(translation ? translation.default : {});
    };
    return TranslateServerLoader;
}());
export { TranslateServerLoader };
