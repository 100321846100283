import {Directive, HostListener, Input} from '@angular/core';
import {Store} from '@ngrx/store';

import {BasicUserDto} from '../../core/models/rest-api.model';
import * as fromRoot from '../../store';
import {reportUserActions} from '../store/actions';

@Directive({
    selector: '[appReportUser]'
})
export class ReportUserDirective {

    @Input() appReportUser: BasicUserDto;

    constructor(private store: Store<fromRoot.State>) {
    }

    @HostListener('click')
    report() {
        this.store.dispatch(new reportUserActions.OpenModalAction(this.appReportUser));
    }
}
