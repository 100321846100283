export var ReviewReportActionTypes;
(function (ReviewReportActionTypes) {
    ReviewReportActionTypes["ReportReview"] = "[REPORT REVIEW] REPORT_REVIEW";
    ReviewReportActionTypes["ReportReviewSuccess"] = "[REPORT REVIEW] REPORT_REVIEW_SUCCESS";
    ReviewReportActionTypes["ReportReviewFail"] = "[REPORT REVIEW] REPORT_REVIEW_FAIL";
    ReviewReportActionTypes["OpenModal"] = "[REPORT REVIEW] OPEN_MODAL";
    ReviewReportActionTypes["CloseModal"] = "[REPORT REVIEW] CLOSE_MODAL";
    ReviewReportActionTypes["Reset"] = "[REPORT REVIEW] RESET";
})(ReviewReportActionTypes || (ReviewReportActionTypes = {}));
var ReportReviewAction = /** @class */ (function () {
    function ReportReviewAction(payload) {
        this.payload = payload;
        this.type = ReviewReportActionTypes.ReportReview;
    }
    return ReportReviewAction;
}());
export { ReportReviewAction };
var ReportReviewSuccessAction = /** @class */ (function () {
    function ReportReviewSuccessAction() {
        this.type = ReviewReportActionTypes.ReportReviewSuccess;
    }
    return ReportReviewSuccessAction;
}());
export { ReportReviewSuccessAction };
var ReportReviewFailAction = /** @class */ (function () {
    function ReportReviewFailAction(payload) {
        this.payload = payload;
        this.type = ReviewReportActionTypes.ReportReviewFail;
    }
    return ReportReviewFailAction;
}());
export { ReportReviewFailAction };
var OpenModalAction = /** @class */ (function () {
    function OpenModalAction(payload) {
        this.payload = payload;
        this.type = ReviewReportActionTypes.OpenModal;
    }
    return OpenModalAction;
}());
export { OpenModalAction };
var CloseModalAction = /** @class */ (function () {
    function CloseModalAction() {
        this.type = ReviewReportActionTypes.CloseModal;
    }
    return CloseModalAction;
}());
export { CloseModalAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = ReviewReportActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
