import {ModalEvent} from '../../models';
import {messageModalActions} from '../actions';

export interface State {
    event: ModalEvent
}

export const initialState: State = {
    event: ModalEvent.CLOSE,
};

export function reducer(state = initialState, action: messageModalActions.Actions): State {

    switch (action.type) {

        case messageModalActions.MessageModalActionTypes.OpenMessageModal: {
            return {
                event: ModalEvent.OPEN
            };
        }

        case messageModalActions.MessageModalActionTypes.CloseMessageModal: {
            return {
                event: ModalEvent.CLOSE
            };
        }

        default: {
            return state;
        }
    }
}

export const getEvent = (state: State): ModalEvent => state.event;

