import {Injectable, ViewContainerRef} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {Locale} from '../modules/core/models';
import {localeActions} from '../modules/core/store/actions';
import * as fromRoot from '../modules/store';

@Injectable()
export class AppHelper {

    constructor(private store: Store<fromRoot.State>,
                private cookieService: CookieService,
                private translateService: TranslateService) {

    }

    checkAuthentication() {
    }

    checkIntro(vcRef: ViewContainerRef) {
    }

    checkLang() {
    }

    // https://github.com/ddfreiling/tns-ng-deeplink/tree/master/app
    handleRouting(link: string) {
    }

    removeLifecycleEventListeners() {
    }

    addLifecycleEventlisteners() {
    }

    listenToAppHealthStatus() {
    }

    checkConnectivity() {
    }

    listenOnRouteToURL() {
    }

    notifyNewMessage() {
    }

    triggerStopMonitoring() {
    }

    initClientSide() {
        let lang = this.cookieService.get('lang');

        if (this.translateService.currentLang !== lang) {
            this.translateService.use(lang);
        }

        let locale = Locale.FRENCH;

        if (lang) {
            let localeByShortcut = Locale.getLocaleByShortcut(lang);
            if (localeByShortcut) {
                locale = localeByShortcut;
            }
        }

        this.store.dispatch(new localeActions.SetLocaleAction(locale));
    }

    showInitIntro(vcRef: ViewContainerRef) {

    }
}
