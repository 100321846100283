var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ActivityDto = /** @class */ (function () {
    function ActivityDto() {
    }
    return ActivityDto;
}());
export { ActivityDto };
var ReviewDto = /** @class */ (function () {
    function ReviewDto() {
    }
    return ReviewDto;
}());
export { ReviewDto };
var AddReviewDto = /** @class */ (function (_super) {
    __extends(AddReviewDto, _super);
    function AddReviewDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AddReviewDto;
}(ReviewDto));
export { AddReviewDto };
var AddressDto = /** @class */ (function () {
    function AddressDto() {
    }
    return AddressDto;
}());
export { AddressDto };
var AddressDtoBuilder = /** @class */ (function () {
    function AddressDtoBuilder() {
    }
    return AddressDtoBuilder;
}());
export { AddressDtoBuilder };
var ProfileDto = /** @class */ (function () {
    function ProfileDto() {
    }
    return ProfileDto;
}());
export { ProfileDto };
var AdminProfileDto = /** @class */ (function (_super) {
    __extends(AdminProfileDto, _super);
    function AdminProfileDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AdminProfileDto;
}(ProfileDto));
export { AdminProfileDto };
var RequestDto = /** @class */ (function () {
    function RequestDto() {
    }
    return RequestDto;
}());
export { RequestDto };
var AdminRequestDto = /** @class */ (function (_super) {
    __extends(AdminRequestDto, _super);
    function AdminRequestDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AdminRequestDto;
}(RequestDto));
export { AdminRequestDto };
var AdminRequestSearchCriteria = /** @class */ (function () {
    function AdminRequestSearchCriteria() {
    }
    return AdminRequestSearchCriteria;
}());
export { AdminRequestSearchCriteria };
var DisplayReviewDto = /** @class */ (function (_super) {
    __extends(DisplayReviewDto, _super);
    function DisplayReviewDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DisplayReviewDto;
}(ReviewDto));
export { DisplayReviewDto };
var AdminReviewDto = /** @class */ (function (_super) {
    __extends(AdminReviewDto, _super);
    function AdminReviewDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AdminReviewDto;
}(DisplayReviewDto));
export { AdminReviewDto };
var AdminUserSearchCriteria = /** @class */ (function () {
    function AdminUserSearchCriteria() {
    }
    return AdminUserSearchCriteria;
}());
export { AdminUserSearchCriteria };
var BaseUserDto = /** @class */ (function () {
    function BaseUserDto() {
    }
    return BaseUserDto;
}());
export { BaseUserDto };
var BasicUserDto = /** @class */ (function (_super) {
    __extends(BasicUserDto, _super);
    function BasicUserDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return BasicUserDto;
}(BaseUserDto));
export { BasicUserDto };
var BonusDto = /** @class */ (function () {
    function BonusDto() {
    }
    return BonusDto;
}());
export { BonusDto };
var CategoryDto = /** @class */ (function () {
    function CategoryDto() {
    }
    return CategoryDto;
}());
export { CategoryDto };
var ChangePasswordDto = /** @class */ (function () {
    function ChangePasswordDto() {
    }
    return ChangePasswordDto;
}());
export { ChangePasswordDto };
var ChangePasswordDtoBuilder = /** @class */ (function () {
    function ChangePasswordDtoBuilder() {
    }
    return ChangePasswordDtoBuilder;
}());
export { ChangePasswordDtoBuilder };
var Client = /** @class */ (function () {
    function Client() {
    }
    return Client;
}());
export { Client };
var ConversationDto = /** @class */ (function () {
    function ConversationDto() {
    }
    return ConversationDto;
}());
export { ConversationDto };
var DisplayRequestDto = /** @class */ (function (_super) {
    __extends(DisplayRequestDto, _super);
    function DisplayRequestDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return DisplayRequestDto;
}(RequestDto));
export { DisplayRequestDto };
var Enterprise = /** @class */ (function () {
    function Enterprise() {
    }
    return Enterprise;
}());
export { Enterprise };
var ErrorDto = /** @class */ (function () {
    function ErrorDto() {
    }
    return ErrorDto;
}());
export { ErrorDto };
var ErrorsDto = /** @class */ (function () {
    function ErrorsDto() {
    }
    return ErrorsDto;
}());
export { ErrorsDto };
var IntegerRange = /** @class */ (function () {
    function IntegerRange() {
    }
    return IntegerRange;
}());
export { IntegerRange };
var Location = /** @class */ (function () {
    function Location() {
    }
    return Location;
}());
export { Location };
var MessageDto = /** @class */ (function () {
    function MessageDto() {
    }
    return MessageDto;
}());
export { MessageDto };
var NewUserProfileDto = /** @class */ (function (_super) {
    __extends(NewUserProfileDto, _super);
    function NewUserProfileDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return NewUserProfileDto;
}(BaseUserDto));
export { NewUserProfileDto };
var OfferDto = /** @class */ (function () {
    function OfferDto() {
    }
    return OfferDto;
}());
export { OfferDto };
var Order = /** @class */ (function () {
    function Order() {
    }
    return Order;
}());
export { Order };
var PasswordResetDto = /** @class */ (function () {
    function PasswordResetDto() {
    }
    return PasswordResetDto;
}());
export { PasswordResetDto };
var ProfileVerificationStatusDto = /** @class */ (function () {
    function ProfileVerificationStatusDto() {
    }
    return ProfileVerificationStatusDto;
}());
export { ProfileVerificationStatusDto };
var ProfileVerificationDto = /** @class */ (function (_super) {
    __extends(ProfileVerificationDto, _super);
    function ProfileVerificationDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ProfileVerificationDto;
}(ProfileVerificationStatusDto));
export { ProfileVerificationDto };
var PromotionDisplayRequestDto = /** @class */ (function () {
    function PromotionDisplayRequestDto() {
    }
    return PromotionDisplayRequestDto;
}());
export { PromotionDisplayRequestDto };
var PromotionResultDto = /** @class */ (function () {
    function PromotionResultDto() {
    }
    return PromotionResultDto;
}());
export { PromotionResultDto };
var QuotationDto = /** @class */ (function () {
    function QuotationDto() {
    }
    return QuotationDto;
}());
export { QuotationDto };
var QuotationGroupDto = /** @class */ (function () {
    function QuotationGroupDto() {
    }
    return QuotationGroupDto;
}());
export { QuotationGroupDto };
var QuotationRowDto = /** @class */ (function () {
    function QuotationRowDto() {
    }
    return QuotationRowDto;
}());
export { QuotationRowDto };
var ReportDto = /** @class */ (function () {
    function ReportDto() {
    }
    return ReportDto;
}());
export { ReportDto };
var RequestContext = /** @class */ (function () {
    function RequestContext() {
    }
    return RequestContext;
}());
export { RequestContext };
var RequestContextBuilder = /** @class */ (function () {
    function RequestContextBuilder() {
    }
    return RequestContextBuilder;
}());
export { RequestContextBuilder };
var RequestReportDto = /** @class */ (function (_super) {
    __extends(RequestReportDto, _super);
    function RequestReportDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RequestReportDto;
}(ReportDto));
export { RequestReportDto };
var RequestSearchCriteria = /** @class */ (function () {
    function RequestSearchCriteria() {
    }
    return RequestSearchCriteria;
}());
export { RequestSearchCriteria };
var RequestVerificationReportDto = /** @class */ (function () {
    function RequestVerificationReportDto() {
    }
    return RequestVerificationReportDto;
}());
export { RequestVerificationReportDto };
var ReviewRatingDto = /** @class */ (function () {
    function ReviewRatingDto() {
    }
    return ReviewRatingDto;
}());
export { ReviewRatingDto };
var ReviewReportDto = /** @class */ (function (_super) {
    __extends(ReviewReportDto, _super);
    function ReviewReportDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ReviewReportDto;
}(ReportDto));
export { ReviewReportDto };
var ReviewVerificationReportDto = /** @class */ (function () {
    function ReviewVerificationReportDto() {
    }
    return ReviewVerificationReportDto;
}());
export { ReviewVerificationReportDto };
var RoleDto = /** @class */ (function () {
    function RoleDto() {
    }
    return RoleDto;
}());
export { RoleDto };
var SearchCriteriaDto = /** @class */ (function () {
    function SearchCriteriaDto() {
    }
    return SearchCriteriaDto;
}());
export { SearchCriteriaDto };
var SendMessageDto = /** @class */ (function () {
    function SendMessageDto() {
    }
    return SendMessageDto;
}());
export { SendMessageDto };
var SessionDto = /** @class */ (function () {
    function SessionDto() {
    }
    return SessionDto;
}());
export { SessionDto };
var Sort = /** @class */ (function () {
    function Sort() {
    }
    return Sort;
}());
export { Sort };
var UserAccountDto = /** @class */ (function (_super) {
    __extends(UserAccountDto, _super);
    function UserAccountDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserAccountDto;
}(BasicUserDto));
export { UserAccountDto };
var UserLoginFormDto = /** @class */ (function () {
    function UserLoginFormDto() {
    }
    return UserLoginFormDto;
}());
export { UserLoginFormDto };
var UserProfileDto = /** @class */ (function (_super) {
    __extends(UserProfileDto, _super);
    function UserProfileDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserProfileDto;
}(BaseUserDto));
export { UserProfileDto };
var UserRegistrationDto = /** @class */ (function () {
    function UserRegistrationDto() {
    }
    return UserRegistrationDto;
}());
export { UserRegistrationDto };
var UserRegistrationDtoBuilder = /** @class */ (function () {
    function UserRegistrationDtoBuilder() {
    }
    return UserRegistrationDtoBuilder;
}());
export { UserRegistrationDtoBuilder };
var UserReportDto = /** @class */ (function (_super) {
    __extends(UserReportDto, _super);
    function UserReportDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserReportDto;
}(ReportDto));
export { UserReportDto };
var UserResultDto = /** @class */ (function (_super) {
    __extends(UserResultDto, _super);
    function UserResultDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserResultDto;
}(BasicUserDto));
export { UserResultDto };
var UserResultVerificationDto = /** @class */ (function () {
    function UserResultVerificationDto() {
    }
    return UserResultVerificationDto;
}());
export { UserResultVerificationDto };
var UserTypeUpdateDto = /** @class */ (function () {
    function UserTypeUpdateDto() {
    }
    return UserTypeUpdateDto;
}());
export { UserTypeUpdateDto };
var UserViewDto = /** @class */ (function (_super) {
    __extends(UserViewDto, _super);
    function UserViewDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserViewDto;
}(BasicUserDto));
export { UserViewDto };
var VerificationReportDto = /** @class */ (function () {
    function VerificationReportDto() {
    }
    return VerificationReportDto;
}());
export { VerificationReportDto };
var ViewDto = /** @class */ (function () {
    function ViewDto() {
    }
    return ViewDto;
}());
export { ViewDto };
var WorkerDto = /** @class */ (function (_super) {
    __extends(WorkerDto, _super);
    function WorkerDto() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return WorkerDto;
}(UserProfileDto));
export { WorkerDto };
// Added by 'EnumConstantsExtension' extension
export var BudgetType = {
    PER_HOUR: "PER_HOUR",
    PER_DAY: "PER_DAY",
    GLOBAL: "GLOBAL",
    ON_QUOTATION: "ON_QUOTATION",
};
export var Civility = {
    M: "M",
    MS: "MS",
};
export var Direction = {
    ASC: "ASC",
    DESC: "DESC",
};
export var Gender = {
    MAN: "MAN",
    WOMAN: "WOMAN",
};
export var MessageReceivingStatus = {
    READ: "READ",
    UNREAD: "UNREAD",
};
export var MessageSendingStatus = {
    DISTRIBUTED: "DISTRIBUTED",
    FAILED: "FAILED",
};
export var NullHandling = {
    NATIVE: "NATIVE",
    NULLS_FIRST: "NULLS_FIRST",
    NULLS_LAST: "NULLS_LAST",
};
export var OfferStatus = {
    CLOSED: "CLOSED",
    PENDING: "PENDING",
    ACCEPTED: "ACCEPTED",
    REFUSED: "REFUSED",
};
export var PromotionResultType = {
    LAST: "LAST",
    ADDRESS: "ADDRESS",
};
export var QuotationRowType = {
    TITLE: "TITLE",
    CONTENT: "CONTENT",
    SUBTOTAL: "SUBTOTAL",
};
export var QuotationType = {
    WITH_TVA: "WITH_TVA",
    WITHOUT_TVA: "WITHOUT_TVA",
    WORKER: "WORKER",
};
export var RejectionType = {
    CUSTOM: "CUSTOM",
    NOT_PERSONAL_PICTURE: "NOT_PERSONAL_PICTURE",
    NAME_INVALID: "NAME_INVALID",
    PERSONAL_INFO_NOT_MATCHING_ID_CARD: "PERSONAL_INFO_NOT_MATCHING_ID_CARD",
    PERSONAL_INFO_NOT_MATCHING_WORKER_CARD: "PERSONAL_INFO_NOT_MATCHING_WORKER_CARD",
    DOCUMENT_NOT_VISIBLE: "DOCUMENT_NOT_VISIBLE",
    DOCUMENT_INVALID: "DOCUMENT_INVALID",
};
export var ReportStatus = {
    PENDING: "PENDING",
    CANCELLED: "CANCELLED",
    CLOSED: "CLOSED",
    PROCESSED: "PROCESSED",
};
export var RequestRejectionType = {
    CUSTOM: "CUSTOM",
    DUPLICATED: "DUPLICATED",
    NOT_DETAILED: "NOT_DETAILED",
    NOT_A_REQUEST: "NOT_A_REQUEST",
};
export var RequestStatus = {
    OPENED: "OPENED",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
    CLOSED: "CLOSED",
};
export var ReviewRejectionType = {
    CUSTOM: "CUSTOM",
    DUPLICATED: "DUPLICATED",
    INAPPROPRIATE: "INAPPROPRIATE",
};
export var ReviewStatus = {
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    PENDING: "PENDING",
};
export var SearchType = {
    AROUND_POINT: "AROUND_POINT",
    IN_AREA: "IN_AREA",
    IN_CITY: "IN_CITY",
};
export var SocialMediaProvider = {
    FACEBOOK: "FACEBOOK",
    TWITTER: "TWITTER",
    GOOGLE: "GOOGLE",
    LINKEDIN: "LINKEDIN",
    LOCAL: "LOCAL",
};
export var Unit = {
    M2: "M2",
    M: "M",
    g: "g",
};
export var UserProvider = {
    LOCAL: "LOCAL",
    SOCIAL: "SOCIAL",
};
export var UserRole = {
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_MANAGER: "ROLE_MANAGER",
    ROLE_USER: "ROLE_USER",
    ROLE_VERIFIER: "ROLE_VERIFIER",
};
export var UserType = {
    BASIC: "BASIC",
    PROFESSIONAL: "PROFESSIONAL",
};
export var VerificationStatus = {
    NONE: "NONE",
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
};
export var VerificationType = {
    PICTURE: "PICTURE",
    PROFILE: "PROFILE",
    ID_CARD: "ID_CARD",
    WORKER_CARD: "WORKER_CARD",
};
