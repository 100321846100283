import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HttpUtil } from '../../core/utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(httpClient) {
        this.httpClient = httpClient;
    }
    UserService.prototype.getWorker = function (userUid) {
        return this.httpClient.get(HttpUtil.buildAndExpand(UserService.WORKERS_ONE, userUid));
    };
    UserService.prototype.getUserPhone = function (userUid) {
        var headers = new HttpHeaders();
        headers = headers.set("Accept", 'text/plain');
        return this.httpClient.get(HttpUtil.buildAndExpand(UserService.USER_PHONE, userUid), {
            headers: headers,
            observe: 'response',
            responseType: 'text'
        }).pipe(map(function (resp) { return resp.body; }));
    };
    UserService.prototype.addView = function (userUid) {
        return this.httpClient.post(HttpUtil.buildAndExpand(UserService.USER_VIEWS, userUid), null);
    };
    UserService.prototype.searchWorkers = function (searchCriteria, pageable) {
        var requestOptions = HttpUtil.buildPageableOptions(pageable);
        return this.httpClient.post(UserService.SEARCH_WORKERS, searchCriteria, requestOptions);
    };
    UserService.prototype.searchAroundIPOrLastWorkers = function (pageable) {
        var requestOptions = HttpUtil.buildPageableOptions(pageable);
        return this.httpClient.get(UserService.SEARCH_WORKERS, requestOptions);
    };
    UserService.prototype.reportUser = function (userReport) {
        return this.httpClient.post(UserService.REPORT_USERS, userReport);
    };
    UserService.WORKERS_ONE = 'api/v1/workers/{userUid}';
    UserService.USER_PHONE = 'api/v1/users/{userUid}/profile/phone';
    UserService.USER_VIEWS = 'api/v1/users/{userUid}/profile/views';
    UserService.SEARCH_WORKERS = 'api/v1/search/workers';
    UserService.REPORT_USERS = 'api/v1/reports/users';
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
