import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {EmailVerificationDto} from '../models';
import {HttpUtil} from '../utils';

@Injectable({
    providedIn: 'root'
})
export class EmailVerificationService {

    constructor(private httpClient: HttpClient) {
    }

    verifyEmail(emailVerification: EmailVerificationDto): Observable<any> {
        let url = HttpUtil.buildAndExpand('api/v1/users/{userUid}/verifications/email/{token}', emailVerification.userUid, emailVerification.token);
        return this.httpClient.post(url, null);
    }
}
