import { ValidationService } from '../services';
var BcValidators = /** @class */ (function () {
    function BcValidators() {
    }
    BcValidators.login = function (control) {
        var login = control.value;
        if (!ValidationService.isLoginValid(login)) {
            return {
                invalid: {
                    value: login
                }
            };
        }
        return null;
    };
    BcValidators.password = function (control) {
        var pwd = control.value;
        if (!ValidationService.isPasswordValid(pwd)) {
            return {
                invalid: {
                    value: pwd
                }
            };
        }
        return null;
    };
    return BcValidators;
}());
export { BcValidators };
