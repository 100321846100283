import { HttpClient } from '@angular/common/http';
import { HttpUtil } from '../utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EmailVerificationService = /** @class */ (function () {
    function EmailVerificationService(httpClient) {
        this.httpClient = httpClient;
    }
    EmailVerificationService.prototype.verifyEmail = function (emailVerification) {
        var url = HttpUtil.buildAndExpand('api/v1/users/{userUid}/verifications/email/{token}', emailVerification.userUid, emailVerification.token);
        return this.httpClient.post(url, null);
    };
    EmailVerificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmailVerificationService_Factory() { return new EmailVerificationService(i0.ɵɵinject(i1.HttpClient)); }, token: EmailVerificationService, providedIn: "root" });
    return EmailVerificationService;
}());
export { EmailVerificationService };
