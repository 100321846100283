export var CategoryActionTypes;
(function (CategoryActionTypes) {
    CategoryActionTypes["LoadCategories"] = "[CATEGORIES] LOAD_CATEGORIES";
    CategoryActionTypes["LoadCategoriesIfNull"] = "[CATEGORIES] LOAD_CATEGORIES_IF_NULL";
    CategoryActionTypes["LoadCategoriesSuccess"] = "[CATEGORIES] LOAD_CATEGORIES_SUCCESS";
    CategoryActionTypes["LoadCategoriesFail"] = "[CATEGORIES] LOAD_CATEGORIES_FAIL";
    CategoryActionTypes["SwitchLang"] = "[CATEGORIES] SWITCH_LANG";
})(CategoryActionTypes || (CategoryActionTypes = {}));
var SwitchLangAction = /** @class */ (function () {
    function SwitchLangAction(payload) {
        this.payload = payload;
        this.type = CategoryActionTypes.SwitchLang;
    }
    return SwitchLangAction;
}());
export { SwitchLangAction };
var LoadCategoriesAction = /** @class */ (function () {
    function LoadCategoriesAction() {
        this.type = CategoryActionTypes.LoadCategories;
    }
    return LoadCategoriesAction;
}());
export { LoadCategoriesAction };
var LoadCategoriesIfNullAction = /** @class */ (function () {
    function LoadCategoriesIfNullAction() {
        this.type = CategoryActionTypes.LoadCategoriesIfNull;
    }
    return LoadCategoriesIfNullAction;
}());
export { LoadCategoriesIfNullAction };
var LoadCategoriesSuccessAction = /** @class */ (function () {
    function LoadCategoriesSuccessAction(payload) {
        this.payload = payload;
        this.type = CategoryActionTypes.LoadCategoriesSuccess;
    }
    return LoadCategoriesSuccessAction;
}());
export { LoadCategoriesSuccessAction };
var LoadCategoriesFailAction = /** @class */ (function () {
    function LoadCategoriesFailAction(payload) {
        this.payload = payload;
        this.type = CategoryActionTypes.LoadCategoriesFail;
    }
    return LoadCategoriesFailAction;
}());
export { LoadCategoriesFailAction };
