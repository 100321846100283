var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Config } from '../../../../config';
import { categoryActions } from '../../../core/store/actions';
import { StorageKey } from '../../models/interfaces';
import { BrowserService, StorageService } from '../../services';
import { localeActions } from '../actions';
var LocaleEffects = /** @class */ (function () {
    function LocaleEffects(platformId, router, storageService, actions$, browserService, translateService) {
        var _this = this;
        this.platformId = platformId;
        this.router = router;
        this.storageService = storageService;
        this.actions$ = actions$;
        this.browserService = browserService;
        this.translateService = translateService;
        this.localeSelected = this.actions$.pipe(ofType(localeActions.LocaleActionsTypes.UpdateLocale), map(function (action) { return action.payload; }), map(function (selectedLocale) {
            var date = new Date();
            date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
            _this.storageService.setItem(StorageKey.LOCALE, selectedLocale.shortcut);
            _this.browserService.setCookie(StorageKey.LANG, selectedLocale.shortcut, date, '/');
            _this.translateService.use(selectedLocale.shortcut).subscribe(function () {
                if (Config.IS_WEB()) {
                    // TODO redirect to same context
                    // let redirect = Locale.isArabic(selectedLocale) ? ContextUtils.arabizeContextPath() : ContextUtils.frenchifyContextPath();
                    var redirect = "/" + selectedLocale.shortcut;
                    _this.router.navigateByUrl(redirect);
                }
                else {
                    var currentUrl_1 = _this.router.url;
                    _this.router.navigateByUrl('/locale-change')
                        .then(function () { return _this.router.navigateByUrl(currentUrl_1); });
                }
            });
            return new localeActions.SetLocaleAction(selectedLocale);
        }));
        this.setLocale = this.actions$.pipe(ofType(localeActions.LocaleActionsTypes.SetLocale), map(function (action) { return action.payload; }), map(function (payload) {
            return new categoryActions.SwitchLangAction(payload);
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocaleEffects.prototype, "localeSelected", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], LocaleEffects.prototype, "setLocale", void 0);
    return LocaleEffects;
}());
export { LocaleEffects };
