import { SeoService } from '../../../modules/core/services';
var CgvComponent = /** @class */ (function () {
    function CgvComponent(seo) {
        this.seo = seo;
        seo.setMeta('cgv');
        seo.setRobots(true);
    }
    return CgvComponent;
}());
export { CgvComponent };
