import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserTransferStateModule} from '@angular/platform-browser';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {WebModule} from '../web/web.module';

import {PROMOTIONS_COMPONENTS} from './components';

@NgModule({
    imports: [
        BrowserTransferStateModule,
        TransferHttpCacheModule,
        WebModule,
    ],
    providers: [],
    declarations: [
        ...PROMOTIONS_COMPONENTS
    ],
    schemas: [
        NO_ERRORS_SCHEMA,
        CUSTOM_ELEMENTS_SCHEMA,
    ],
    exports: [
        ...PROMOTIONS_COMPONENTS
    ]
})
export class PromotionsModule {
}
