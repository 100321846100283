import { BrowserService } from './browser.service';
import * as i0 from "@angular/core";
import * as i1 from "./browser.service";
var StorageService = /** @class */ (function () {
    function StorageService(browserService) {
        this.browserService = browserService;
    }
    StorageService.prototype.setItem = function (key, value) {
        if (this.browserService.isPlatformBrowser()) {
            localStorage.setItem('' + key, value === null ? null : JSON.stringify(value));
        }
    };
    StorageService.prototype.getItem = function (key) {
        var value = null;
        if (this.browserService.isPlatformBrowser()) {
            value = localStorage.getItem('' + key);
        }
        return value === null ? null : JSON.parse(value);
    };
    StorageService.prototype.removeItem = function (key) {
        if (this.browserService.isPlatformBrowser()) {
            localStorage.removeItem('' + key);
        }
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(i0.ɵɵinject(i1.BrowserService)); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
