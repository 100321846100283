/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-chooser-manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../modals/service-chooser-modal/service-chooser-modal.component.ngfactory";
import * as i3 from "../modals/service-chooser-modal/service-chooser-modal.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./service-chooser-manager.component";
import * as i7 from "@ngrx/store";
var styles_ActivityChooserManagerComponent = [i0.styles];
var RenderType_ActivityChooserManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivityChooserManagerComponent, data: {} });
export { RenderType_ActivityChooserManagerComponent as RenderType_ActivityChooserManagerComponent };
export function View_ActivityChooserManagerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "app-activity-chooser-modal", [["modalClass", "bottom-sheet-modal-height bottom-sheet"], ["modalId", "activity-chooser"]], null, [[null, "selectActivity"], [null, "selectCategory"], [null, "quit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectActivity" === en)) {
        var pd_0 = (_co.selectActivity($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectCategory" === en)) {
        var pd_1 = (_co.selectCategory($event) !== false);
        ad = (pd_1 && ad);
    } if (("quit" === en)) {
        var pd_2 = (_co.closeModal() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ActivityChooserModalComponent_0, i2.RenderType_ActivityChooserModalComponent)), i1.ɵdid(1, 49152, null, 0, i3.ActivityChooserModalComponent, [], { modalId: [0, "modalId"], modalClass: [1, "modalClass"], title: [2, "title"], modalEventListener: [3, "modalEventListener"], showFooter: [4, "showFooter"], initListener: [5, "initListener"], categories: [6, "categories"], activities: [7, "activities"], selectedCategories: [8, "selectedCategories"], selectedActivities: [9, "selectedActivities"] }, { selectActivity: "selectActivity", selectCategory: "selectCategory", quit: "quit" }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "activity-chooser"; var currVal_1 = "bottom-sheet-modal-height bottom-sheet"; var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform("services-chooser.modal.title")); var currVal_3 = _co.activityModalEventListener$; var currVal_4 = false; var currVal_5 = _co.activityChooserInitListener$; var currVal_6 = i1.ɵunv(_v, 1, 6, i1.ɵnov(_v, 3).transform(_co.categories$)); var currVal_7 = i1.ɵunv(_v, 1, 7, i1.ɵnov(_v, 4).transform(_co.activities$)); var currVal_8 = i1.ɵunv(_v, 1, 8, i1.ɵnov(_v, 5).transform(_co.selectedCategories$)); var currVal_9 = i1.ɵunv(_v, 1, 9, i1.ɵnov(_v, 6).transform(_co.selectedActivities$)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_ActivityChooserManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activity-chooser-manager", [], null, null, null, View_ActivityChooserManagerComponent_0, RenderType_ActivityChooserManagerComponent)), i1.ɵdid(1, 114688, null, 0, i6.ActivityChooserManagerComponent, [i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivityChooserManagerComponentNgFactory = i1.ɵccf("app-activity-chooser-manager", i6.ActivityChooserManagerComponent, View_ActivityChooserManagerComponent_Host_0, { multiple: "multiple", resetOnInit: "resetOnInit", openEventListener$: "openEventListener$", showFooter: "showFooter" }, { activitySelected: "activitySelected", categorySelected: "categorySelected" }, []);
export { ActivityChooserManagerComponentNgFactory as ActivityChooserManagerComponentNgFactory };
