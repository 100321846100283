var ActivityUtils = /** @class */ (function () {
    function ActivityUtils() {
    }
    ActivityUtils.containsActivity = function (activities, activity) {
        return ActivityUtils.containsById(activities, activity.id);
    };
    ActivityUtils.containsById = function (activities, activityId) {
        return ActivityUtils.getById(activities, activityId) != null;
    };
    ActivityUtils.getById = function (activities, activityId) {
        if (!activities) {
            return null;
        }
        var find = activities.find(function (c) { return c.id === activityId; });
        if (!find) {
            return null;
        }
        return find;
    };
    ActivityUtils.remove = function (activities, activity) {
        return ActivityUtils.removeById(activities, activity.id);
    };
    ActivityUtils.removeById = function (activities, activityId) {
        var fn = activities.filter(function (act) { return act.id !== activityId; });
        return fn;
    };
    return ActivityUtils;
}());
export { ActivityUtils };
