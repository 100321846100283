import {Component} from '@angular/core';
import {SeoService} from '../../../modules/core/services';

@Component({
    selector: 'yf-comment-ca-marche',
    templateUrl: './comment-ca-marche.component.html',
    styleUrls: ['./comment-ca-marche.component.scss']
})
export class CommentCaMarcheComponent {

    constructor(private seo: SeoService) {
        seo.setMeta('how-to');
        seo.setRobots(true);
    }

}
