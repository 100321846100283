export class Locale {

    static FRENCH = new Locale('Français', 'fr', 'french_flag.png', 'french_flag');
    static ARABIC = new Locale('العربية', 'ar', 'arabe_flag.png', 'arabe_flag', true);
    static MOROCCAN = new Locale('المغربية', 'ar_ma', 'morocco_flag.png', 'morocco_flag', true);
    static ENGLISH = new Locale('English', 'en', 'english_flag.png', 'english_flag');

    static SUPPORTED_LOCALES: Locale[] = [Locale.ENGLISH, Locale.FRENCH, Locale.ARABIC, Locale.MOROCCAN];

    static isArabic(locale: Locale): boolean {
        return locale.name === Locale.ARABIC.name;
    }

    static getLocaleByShortcut(shortcut: string): Locale {
        if (!shortcut) {
            return null;
        }

        return this.SUPPORTED_LOCALES.find(lc => lc.shortcut === shortcut);
    }

    constructor(public name: string, public shortcut: string, public icon, public mobileIcon, public rtl?: boolean) {
    }

    equals(loc: Locale): boolean {
        return loc && this.name === loc.name;
    }


}
