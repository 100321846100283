import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject} from 'rxjs';
import {Config} from '../../../../../config';

import {localeActions} from '../../../../core/store/actions';
import {StorageKey} from '../../../../core/models/interfaces/istorage';
import {Locale, ModalEvent} from '../../../../core/models';
import {BrowserService} from '../../../../core/services';
import {StorageService} from '../../../../core/services/storage.service';
import * as fromRoot from '../../../../store';

@Component({
    selector: 'yf-locale-modal',
    templateUrl: './locale-modal.component.html',
    styleUrls: ['./locale-modal.component.scss']
})
export class LocaleModalComponent implements OnInit {

    modalEventListener$ = new BehaviorSubject<ModalEvent>(ModalEvent.CLOSE);

    locales = Locale.SUPPORTED_LOCALES;

    constructor(private store: Store<fromRoot.State>,
                private browserService: BrowserService,
                private storageService: StorageService) {
    }

    ngOnInit() {
        if (this.browserService.isPlatformBrowser()) {

            let lang;

            if (Config.IS_WEB()) {
                lang = this.browserService.getCookie(StorageKey.LANG);
            } else {
                lang = this.storageService.getItem(StorageKey.LOCALE);
            }

            let locale = Locale.getLocaleByShortcut(lang);

            if (!locale) {
                this.modalEventListener$.next(ModalEvent.OPEN);
            }
        }
    }

    selectLocale(loc: Locale) {
        this.modalEventListener$.next(ModalEvent.CLOSE);
        this.store.dispatch(new localeActions.UpdateLocaleAction(loc));
    }

}
