import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {CookieService} from 'ngx-cookie-service';
import {Subscription} from 'rxjs';
import * as authenticationActions from '../../modules/core/store/actions/authentication.action';
import * as routeActions from '../../modules/core/store/actions/route.action';
import {Notification, SessionDto} from '../../modules/core/models/index';
import {BrowserService} from '../../modules/core/services';
import {AuthService} from '../../modules/core/services/auth.service';

import * as fromRoot from '../../modules/store';

@Component({
    selector: 'yf-post-social-sign-in',
    templateUrl: './post-social-sign-in.component.html',
    styleUrls: ['./post-social-sign-in.component.scss']
})
export class PostSocialSignInComponent implements OnInit, OnDestroy {

    private subscription = new Subscription();

    private POST_SIGN_IN_COOKIE = 'JWT-TOKEN';
    private POST_SIGN_IN_COOKIE_EXP_DATE = 'JWT-TOKEN-EXP-DATE';

    notification: Notification;

    constructor(private store: Store<fromRoot.State>,
                private authService: AuthService,
                private router: Router,
                private browserService: BrowserService,
                private cookieService: CookieService) {
    }

    ngOnInit() {
        if (this.browserService.isPlatformBrowser()) {
            let authSub = this.authService.getCurrent()
                .subscribe(userAccount => {
                    let session = new SessionDto();
                    session.user = userAccount;

                    this.store.dispatch(new authenticationActions.AuthenticateSuccessAction(userAccount));

                    if (!userAccount.gender || !userAccount.filled) {
                        this.store.dispatch(new routeActions.ToCompleteUserProfileAction());
                    } else {
                        this.store.dispatch(new routeActions.ToUserProfileAction());
                    }

                    this.cookieService.delete(this.POST_SIGN_IN_COOKIE);
                    this.cookieService.delete(this.POST_SIGN_IN_COOKIE_EXP_DATE);
                }, (e) => {
                    this.router.navigate(['login'], {queryParams: {error: true}})
                });

            this.subscription.add(authSub);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
