import {Pipe, PipeTransform} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {Locale} from '../../core/models';

import * as fromRoot from '../../store';

@Pipe({
    name: 'yfDate'
})
export class YFDatePipe implements PipeTransform {

    static monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    static arMonthNames = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];

    static frMonthGetter = YFDatePipe.getFrMonthGetter();
    static arMonthGetter = YFDatePipe.getArMonthGetter();

    monthGetter = YFDatePipe.frMonthGetter;

    private static getFrMonthGetter() {
        let fn = (m: number) => YFDatePipe.monthNames[m];
        return fn;
    }

    private static getArMonthGetter() {
        let fn = (m: number) => YFDatePipe.arMonthNames[m];
        return fn;
    }

    constructor(private store: Store<fromRoot.State>,
                private translateService: TranslateService) {
        this.store.pipe(
            select(fromRoot.getLocale),
            filter(locale => locale != null)
        ).subscribe(locale => {
            this.monthGetter = locale.rtl ? YFDatePipe.arMonthGetter : YFDatePipe.frMonthGetter;
        })
    }

    transform(value: string, args?: any): any {
        if (!value) {
            return null;
        }

        let output = '';
        let today = new Date();
        let date = new Date(value);

        if (this.isSameDay(date, today)) {
            output = date.getHours() + ':' + this.twoDigitNumber(date.getMinutes());
        } else {

            if (this.isYesterday(date)) {
                output = this.translateService.instant('global.yesterday-at', {
                    hour: this.twoDigitNumber(date.getHours()),
                    minutes: this.twoDigitNumber(date.getMinutes())
                });
            } else {
                output = `${this.twoDigitNumber(date.getDate())} ${this.monthGetter(date.getMonth())}`;
                if (!this.isSameYear(date, today)) {
                    output += ` ${date.getFullYear()}`;
                }
            }

        }

        return output;
    }

    isSameDay(date: Date, anotherDate: Date): boolean {

        if (!this.isSameYear(date, anotherDate)) {
            return false;
        }

        if (date.getMonth() !== anotherDate.getMonth()) {
            return false;
        }

        return date.getDate() === anotherDate.getDate();
    }

    isSameYear(date: Date, anotherDay: Date): boolean {
        return date.getFullYear() === anotherDay.getFullYear();
    }

    isYesterday(date: Date): boolean {
        let today = new Date();

        let yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        return this.isSameDay(date, yesterday);
    }

    private twoDigitNumber(num) {
        return num < 10 ? '0' + num : '' + num;
    }

}
