<div class="row justify-content-center" *ngIf="review" yfLayoutClass>
    <div class="col-md-8">
        <form (ngSubmit)="reportReview(reportForm)" #reportForm="ngForm" materialize>

            <app-notification [notification]="notification"></app-notification>

            <div class="row">
                <div class="col-12" translate>reports.please-signal-reasons</div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="input-field">
                        <textarea type="text" name="message" id="message" #messageInput
                                  [(ngModel)]="_reviewReport.message"
                                  [placeholder]="'reports.reason-placeholder' | translate"
                                  class="materialize-textarea"
                                  required>
                        </textarea>
                        <label for="message" translate>reports.reason</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 center">
                    <button type="button" class="btn btn-cancel mb-3 mb-md-0 mr-md-1 w-100-sm-down"
                            (click)="cancel.emit()" translate>
                        global.cancel
                    </button>

                    <button class="btn btn-default w-100-sm-down" [disabled]="loading$ | async" translate>
                        global.report
                    </button>
                </div>
            </div>

        </form>
    </div>
</div>