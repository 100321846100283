export var AccountActivationActionTypes;
(function (AccountActivationActionTypes) {
    AccountActivationActionTypes["Activate"] = "[Account] ACTIVATE";
    AccountActivationActionTypes["ActivateSuccess"] = "[Account] ACTIVATE_SUCCESS";
    AccountActivationActionTypes["ActivateFail"] = "[Account] ACTIVATE_FAIL";
    AccountActivationActionTypes["Reset"] = "[Account] RESET_ACTIVATE";
})(AccountActivationActionTypes || (AccountActivationActionTypes = {}));
var ActivateAction = /** @class */ (function () {
    function ActivateAction(payload) {
        this.payload = payload;
        this.type = AccountActivationActionTypes.Activate;
    }
    return ActivateAction;
}());
export { ActivateAction };
var ActivateSuccessAction = /** @class */ (function () {
    function ActivateSuccessAction(payload) {
        this.payload = payload;
        this.type = AccountActivationActionTypes.ActivateSuccess;
    }
    return ActivateSuccessAction;
}());
export { ActivateSuccessAction };
var ActivateFailAction = /** @class */ (function () {
    function ActivateFailAction(payload) {
        this.payload = payload;
        this.type = AccountActivationActionTypes.ActivateFail;
    }
    return ActivateFailAction;
}());
export { ActivateFailAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = AccountActivationActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
