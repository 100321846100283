export class Pageable {
    public page = 1;
    public size = 10;
    public sort: Sort[] = [];

    constructor(size: number = 10, ...sort: Sort[]) {
        this.size = size;
        this.sort = sort;
    }
}

export type SortDirection = 'asc' | 'desc';
export const SortDirection = {
    DESC: <SortDirection>'desc',
    ASC: <SortDirection>'asc',
};

export class Sort {
    public direction: SortDirection;
    public property: string;

    constructor(direction: SortDirection, property: string) {
        this.direction = direction;
        this.property = property;
    }
}

export class Page<T> {
    totalPages: number;
    totalElements: number;
    last: boolean;
    size: number;
    number: number;
    sort: any;
    first: boolean;
    numberOfElements: number;
    content: T[] = [];


}
