import {authenticationActions} from '../actions';
import {ErrorsDto, UserAccountDto, UserProfileDto} from '../../models';
import {CommonUtils} from '../../utils';

export interface State {
    authenticated: boolean;
    loading: boolean;
    user: UserAccountDto;
    userProfile: UserProfileDto,
    errors: ErrorsDto;
    unreadConversationsCount: number;
    loaded: boolean;
}

export const initialState: State = {
    authenticated: false,
    loading: false,
    user: null,
    userProfile: null,
    errors: null,
    unreadConversationsCount: null,
    loaded: false
};

const types = authenticationActions.AuthenticationActionTypes;

export function reducer(state = initialState, action: authenticationActions.Actions): State {

    switch (action.type) {

        case types.Authenticate:
        case types.SocialAuthenticate: {
            return {
                ...state,
                authenticated: false,
                loading: true,
                errors: null
            }
        }

        case types.AuthenticateSuccess:
        case types.SocialAuthenticateSuccess: {
            return {
                ...state,
                authenticated: true,
                loading: false,
                user: action.payload,
                errors: null,
                unreadConversationsCount: null
            }
        }

        case types.AuthenticateFailure:
        case types.SocialAuthenticateFailure: {
            return {
                ...state,
                errors: action.payload,
                loading: false,
                authenticated: false,
                user: null,
            }
        }

        case types.AuthenticationReset: {
            return Object.assign(
                {},
                initialState);
        }

        case types.SetErrors: {
            return {
                ...state,
                errors: action.payload,
            };
        }

        case types.SetUser:
        case types.TestSessionSuccess: {
            return {
                ...state,
                authenticated: true,
                loading: false,
                user: action.payload,
                errors: null,
                loaded: true
            };
        }

        case types.SetLoaded: {
            return {
                ...state,
                loading: false,
                loaded: true
            };
        }

        case types.SetPicture: {
            let user = CommonUtils.cloneDeep(state.user);
            user.picture = action.payload;

            return {
                ...state,
                user: user,
            };
        }

        case types.LoadUserProfileSuccess: {
            return {
                ...state,
                userProfile: action.payload,
            };
        }

        /**
         * Count Unread Messages
         */
        case types.CountUnreadConversationsSuccess: {
            return {
                ...state,
                unreadConversationsCount: action.payload
            }
        }

        default: {
            return state;
        }
    }
}

export const getCurrentUser = (state: State): UserAccountDto => state.user;
export const getCurrentUserProfile = (state: State): UserProfileDto => state.userProfile;
export const getErrors = (state: State): ErrorsDto => state.errors;
export const isLoading = (state: State): boolean => state.loading;
export const isAuthenticated = (state: State): boolean => state.authenticated;
export const getUnreadConversationsCount = (state: State): number => state.unreadConversationsCount;
