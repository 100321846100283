export var AccountPasswordChangeActionTypes;
(function (AccountPasswordChangeActionTypes) {
    AccountPasswordChangeActionTypes["ChangePassword"] = "[PASSWORD CHANGE] CHANGE_PASSWORD";
    AccountPasswordChangeActionTypes["ChangePasswordSuccess"] = "[PASSWORD CHANGE] CHANGE_PASSWORD_SUCCESS";
    AccountPasswordChangeActionTypes["ChangePasswordFail"] = "[PASSWORD CHANGE] CHANGE_PASSWORD_FAIL";
    AccountPasswordChangeActionTypes["Reset"] = "[PASSWORD CHANGE] RESET";
})(AccountPasswordChangeActionTypes || (AccountPasswordChangeActionTypes = {}));
var ChangePasswordAction = /** @class */ (function () {
    function ChangePasswordAction(payload) {
        this.payload = payload;
        this.type = AccountPasswordChangeActionTypes.ChangePassword;
    }
    return ChangePasswordAction;
}());
export { ChangePasswordAction };
var ChangePasswordSuccessAction = /** @class */ (function () {
    function ChangePasswordSuccessAction(payload) {
        this.payload = payload;
        this.type = AccountPasswordChangeActionTypes.ChangePasswordSuccess;
    }
    return ChangePasswordSuccessAction;
}());
export { ChangePasswordSuccessAction };
var ChangePasswordFailAction = /** @class */ (function () {
    function ChangePasswordFailAction(payload) {
        this.payload = payload;
        this.type = AccountPasswordChangeActionTypes.ChangePasswordFail;
    }
    return ChangePasswordFailAction;
}());
export { ChangePasswordFailAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = AccountPasswordChangeActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
