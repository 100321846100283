import { Pageable, RegistrationConfirmationParams } from '../models';
import { CommonUtils, HttpUtil } from '../utils';
import * as i0 from "@angular/core";
var ParamsService = /** @class */ (function () {
    function ParamsService() {
    }
    ParamsService.prototype.fillPageable = function (params, pageable) {
        pageable.page = +params[ParamsService.PAGE_NUM_PARAM] ? +params[ParamsService.PAGE_NUM_PARAM] - 1 : 0;
        pageable.sort = HttpUtil.stringToSortArray(params[ParamsService.SORT_PARAM]);
        return pageable;
    };
    ParamsService.prototype.buildPageable = function (params) {
        return this.fillPageable(params, new Pageable());
    };
    ParamsService.prototype.buildRegistrationConfirmationParams = function (data) {
        var _a;
        return _a = {},
            _a[ParamsService.EMAIL_PARAM] = data.email,
            _a[ParamsService.POST_SIGN_UP_PARAM] = data.postSignUp,
            _a;
    };
    ParamsService.prototype.buildRegistrationConfirmationFromParams = function (params) {
        var email = params[ParamsService.EMAIL_PARAM];
        var postSignUp = CommonUtils.toBoolean(params[ParamsService.POST_SIGN_UP_PARAM]);
        return RegistrationConfirmationParams.build(email, postSignUp);
    };
    ParamsService.prototype.convertPageableToParams = function (pageable) {
        var params = [];
        params[ParamsService.PAGE_NUM_PARAM] = pageable.page;
        if (pageable.sort && pageable.sort.length) {
            params[ParamsService.SORT_PARAM] = HttpUtil.sortArrayToString(pageable.sort);
        }
        return params;
    };
    ParamsService.prototype.convertSearchCriteriaToParams = function (searchCriteria) {
        var params = [];
        if (searchCriteria.address && searchCriteria.address.location) {
            params[ParamsService.LATITUDE_PARAM] = searchCriteria.address.location.latitude;
            params[ParamsService.LONGITUDE_PARAM] = searchCriteria.address.location.longitude;
            params[ParamsService.ADDRESS_PARAM] = searchCriteria.address.formatted;
        }
        if (searchCriteria.activity) {
            params[ParamsService.ACTIVITY_ID_PARAM] = searchCriteria.activity.id;
            params[ParamsService.ACTIVITY_NAME_PARAM] = searchCriteria.activity.name;
        }
        if (searchCriteria.radius) {
            params[ParamsService.RADIUS_PARAM] = searchCriteria.radius;
        }
        return params;
    };
    ParamsService.prototype.buildPasswordForgottenParams = function (email) {
        var _a;
        return _a = {},
            _a[ParamsService.EMAIL_PARAM] = email,
            _a;
    };
    ParamsService.prototype.buildPasswordForgottenFormParams = function (params) {
        return params[ParamsService.EMAIL_PARAM];
    };
    ParamsService.prototype.convertRequestSearchCriteriaToParams = function (searchCriteria) {
        var params = [];
        if (searchCriteria.address && searchCriteria.address.location) {
            params[ParamsService.LATITUDE_PARAM] = searchCriteria.address.location.latitude;
            params[ParamsService.LONGITUDE_PARAM] = searchCriteria.address.location.longitude;
            params[ParamsService.ADDRESS_PARAM] = searchCriteria.address.formatted;
        }
        if (searchCriteria.activity) {
            params[ParamsService.ACTIVITY_ID_PARAM] = searchCriteria.activity.id;
            params[ParamsService.ACTIVITY_NAME_PARAM] = searchCriteria.activity.name;
        }
        if (searchCriteria.budgetType) {
            params[ParamsService.BUDGET_TYPE_PARAM] = searchCriteria.budgetType;
        }
        if (searchCriteria.budgetRange) {
            params[ParamsService.BUDGET_MIN_PARAM] = searchCriteria.budgetRange.min;
            params[ParamsService.BUDGET_MAX_PARAM] = searchCriteria.budgetRange.max;
        }
        if (searchCriteria.radius) {
            params[ParamsService.RADIUS_PARAM] = searchCriteria.radius;
        }
        return params;
    };
    ParamsService.prototype.convertPageableRequestSearchCriteriaToParams = function (searchCriteria, pageable) {
        var params = this.convertRequestSearchCriteriaToParams(searchCriteria);
        var pageableParams = this.convertPageableToParams(pageable);
        CommonUtils.concatParams(params, pageableParams);
        return params;
    };
    ParamsService.PAGE_NUM_PARAM = 'p';
    ParamsService.SORT_PARAM = 'sort';
    ParamsService.EMAIL_PARAM = 'email';
    ParamsService.POST_SIGN_UP_PARAM = 'postSignUp';
    ParamsService.LATITUDE_PARAM = 'lat';
    ParamsService.LONGITUDE_PARAM = 'lng';
    ParamsService.ACTIVITY_ID_PARAM = 'activityId';
    ParamsService.ACTIVITY_NAME_PARAM = 'activityName';
    ParamsService.ADDRESS_PARAM = 'address';
    ParamsService.RADIUS_PARAM = 'radius';
    ParamsService.BUDGET_TYPE_PARAM = 'budgetType';
    ParamsService.BUDGET_MIN_PARAM = 'budgetMin';
    ParamsService.BUDGET_MAX_PARAM = 'budgetMax';
    ParamsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParamsService_Factory() { return new ParamsService(); }, token: ParamsService, providedIn: "root" });
    return ParamsService;
}());
export { ParamsService };
