import {MaterializeAutoCompleteOptions} from './materialize-autocomplete-options';

export interface MaterializeChipsOptions {
    data: any;
    placeholder: string;
    secondaryPlaceholder?: string;
    autoCompleteOptions?: MaterializeAutoCompleteOptions;
}

export class MaterializeChipsOptionsImpl implements MaterializeChipsOptions {
    data: any;
    placeholder: string;
}


