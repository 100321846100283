import {NotificationType} from './notification-type';

export class Notification {
    type: NotificationType;
    title: string;
    detail: string;
    messages: string[] = [];

    public static buildSuccessNotification(title = ''): Notification {
        return Notification.buildNotification(NotificationType.SUCCESS, title);
    }

    public static buildInfoNotification(title = ''): Notification {
        return Notification.buildNotification(NotificationType.INFO, title);
    }

    public static buildErrorNotification(title = ''): Notification {
        return Notification.buildNotification(NotificationType.ERROR, title);
    }

    public static buildNotification(type: NotificationType, title = ''): Notification {
        let notif = new Notification();
        notif.type = type;
        notif.title = title;

        return notif;
    }

    addMessage(message: string) {
        this.messages.push(message);
    }

}
