export var EmailVerificationActionTypes;
(function (EmailVerificationActionTypes) {
    EmailVerificationActionTypes["VerifyEmail"] = "[EmailVerification] VerifyEmail";
    EmailVerificationActionTypes["VerifyEmailSuccess"] = "[EmailVerification] VerifyEmailSuccess";
    EmailVerificationActionTypes["VerifyEmailFail"] = "[EmailVerification] VerifyEmailFail";
    EmailVerificationActionTypes["Reset"] = "[EmailVerification] Reset";
})(EmailVerificationActionTypes || (EmailVerificationActionTypes = {}));
var VerifyEmailAction = /** @class */ (function () {
    function VerifyEmailAction(payload) {
        this.payload = payload;
        this.type = EmailVerificationActionTypes.VerifyEmail;
    }
    return VerifyEmailAction;
}());
export { VerifyEmailAction };
var VerifyEmailSuccessAction = /** @class */ (function () {
    function VerifyEmailSuccessAction(payload) {
        this.payload = payload;
        this.type = EmailVerificationActionTypes.VerifyEmailSuccess;
    }
    return VerifyEmailSuccessAction;
}());
export { VerifyEmailSuccessAction };
var VerifyEmailFailAction = /** @class */ (function () {
    function VerifyEmailFailAction(payload) {
        this.payload = payload;
        this.type = EmailVerificationActionTypes.VerifyEmailFail;
    }
    return VerifyEmailFailAction;
}());
export { VerifyEmailFailAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = EmailVerificationActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
