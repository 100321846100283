var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { passwordRecoverActions } from '../actions';
import { NotificationService, PasswordService } from '../../services';
import { ErrorUtils } from '../../utils';
var PasswordRecoverEffects = /** @class */ (function () {
    function PasswordRecoverEffects(passwordService, notificationService, actions$) {
        var _this = this;
        this.passwordService = passwordService;
        this.notificationService = notificationService;
        this.actions$ = actions$;
        this.recoverPassword = this.actions$.pipe(ofType(passwordRecoverActions.PasswordRecoverActionTypes.RecoverPassword), map(function (action) { return action.payload; }), mergeMap(function (email) {
            return _this.passwordService.recoverPasswordByEmail(email).pipe(map(function () { return new passwordRecoverActions.RecoverPasswordSuccessAction(_this.notificationService.buildResetPasswordSuccessNotification()); }), catchError(function (errors) { return of(new passwordRecoverActions.RecoverPasswordFailAction(ErrorUtils.toNotification(errors))); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Observable)
    ], PasswordRecoverEffects.prototype, "recoverPassword", void 0);
    return PasswordRecoverEffects;
}());
export { PasswordRecoverEffects };
