import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {DisplayReviewDto, SessionDto} from '../../../../core/models';
import {UserAccountUtils} from '../../../../core/utils';

@Component({
    moduleId: module.id,
    selector: 'app-review-list',
    templateUrl: './review-list.component.html',
    styleUrls: ['./review-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewListComponent {

    UserAccountUtils = UserAccountUtils;

    @Input() reviews: DisplayReviewDto[];
    @Input() currentSession$: Observable<SessionDto>;
    @Input() emptyMessage = 'global.no-review';
    @Input() orientation = 'horizontal';

    @Output() deleteReview = new EventEmitter<DisplayReviewDto>();
    @Output() updateReview = new EventEmitter<DisplayReviewDto>();

}
