var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { authenticationActions, signUpActions } from '../actions';
import { Notification } from '../../models';
import { SignUpService, AuthService } from '../../services';
var SignUpEffects = /** @class */ (function () {
    function SignUpEffects(actions$, translateService, authService, signUpService) {
        var _this = this;
        this.actions$ = actions$;
        this.translateService = translateService;
        this.authService = authService;
        this.signUpService = signUpService;
        this.signUp = this.actions$.pipe(ofType(signUpActions.SignUpActionTypes.SignUp), map(function (action) { return action.payload; }), mergeMap(function (userRegistration) {
            return _this.signUpService.signUp(userRegistration).pipe(mergeMap(function (session) {
                _this.authService.setSession(session);
                return [
                    new authenticationActions.AuthenticateSuccessAction(session.user),
                    // new signUpActions.SignUpSuccessAction(),
                    new signUpActions.SignUpStoreEmailAction(userRegistration.email)
                ];
            }), catchError(function (errors) { return of(new signUpActions.SignUpFailureAction(errors)); }));
        }));
        this.signUpResentEmailActivation = this.actions$.pipe(ofType(signUpActions.SignUpActionTypes.SignUpConfirmationResentEmail), map(function (action) { return action.payload; }), mergeMap(function (email) {
            return _this.signUpService.resendEmailConfirmation(email).pipe(map(function () {
                var text = _this.translateService.instant('sign-up.mail-success');
                var notification = Notification.buildSuccessNotification(text + email);
                return new signUpActions.SignUpConfirmationResentEmailSuccessAction(notification);
            }), catchError(function (errors) { return of(new signUpActions.SignUpConfirmationResentEmailFailureAction(errors)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SignUpEffects.prototype, "signUp", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SignUpEffects.prototype, "signUpResentEmailActivation", void 0);
    return SignUpEffects;
}());
export { SignUpEffects };
