import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import * as fromRoot from '../../../store';
import {authenticationActions, routeActions} from '../../store/actions';
import {AuthService, RouteService} from '../index';
import {UserAccountUtils} from '../../utils';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {

    constructor(private store: Store<fromRoot.State>,
                private authService: AuthService,
                private routeService: RouteService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.pipe(
            select(fromRoot.getCurrentUser),
            map(currentUser => {

                if (!currentUser && !this.authService.isAuthenticated()) {
                    this.store.dispatch(new routeActions.ToSignInAction());
                    return false;
                }

                let storeUser = false;
                let user = currentUser;
                if (!user) {
                    storeUser = true;
                    user = this.authService.getUser();
                }

                if (!UserAccountUtils.isUserAccountVerifier(user)) {
                    this.routeService.toNotFound();
                    return false;
                }

                if (storeUser) {
                    this.store.dispatch(new authenticationActions.SetUserAction(user));
                }

                return true;
            })
        );
    }
}
