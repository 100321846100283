import { ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Locale } from '../modules/core/models';
import { localeActions } from '../modules/core/store/actions';
var AppHelper = /** @class */ (function () {
    function AppHelper(store, cookieService, translateService) {
        this.store = store;
        this.cookieService = cookieService;
        this.translateService = translateService;
    }
    AppHelper.prototype.checkAuthentication = function () {
    };
    AppHelper.prototype.checkIntro = function (vcRef) {
    };
    AppHelper.prototype.checkLang = function () {
    };
    // https://github.com/ddfreiling/tns-ng-deeplink/tree/master/app
    AppHelper.prototype.handleRouting = function (link) {
    };
    AppHelper.prototype.removeLifecycleEventListeners = function () {
    };
    AppHelper.prototype.addLifecycleEventlisteners = function () {
    };
    AppHelper.prototype.listenToAppHealthStatus = function () {
    };
    AppHelper.prototype.checkConnectivity = function () {
    };
    AppHelper.prototype.listenOnRouteToURL = function () {
    };
    AppHelper.prototype.notifyNewMessage = function () {
    };
    AppHelper.prototype.triggerStopMonitoring = function () {
    };
    AppHelper.prototype.initClientSide = function () {
        var lang = this.cookieService.get('lang');
        if (this.translateService.currentLang !== lang) {
            this.translateService.use(lang);
        }
        var locale = Locale.FRENCH;
        if (lang) {
            var localeByShortcut = Locale.getLocaleByShortcut(lang);
            if (localeByShortcut) {
                locale = localeByShortcut;
            }
        }
        this.store.dispatch(new localeActions.SetLocaleAction(locale));
    };
    AppHelper.prototype.showInitIntro = function (vcRef) {
    };
    return AppHelper;
}());
export { AppHelper };
