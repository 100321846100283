import {BudgetType, UIBudgetType} from '../models';

export class BudgetTypeUtils {

    private static budgetTypes: UIBudgetType[] = [
        new UIBudgetType(BudgetType.PER_HOUR, 'Budget par heure', 'par heure', '/heure', 'الميزانية للساعة'),
        new UIBudgetType(BudgetType.PER_DAY, 'Budget par jour', 'par jour', '/jour', 'الميزانية لليوم'),
        new UIBudgetType(BudgetType.GLOBAL, 'Budget total', 'total', 'total', 'الميزانية اﻹجمالية'),
        new UIBudgetType(BudgetType.ON_QUOTATION, 'Sur devis', 'sur devis', 'sur devis', 'ثمن اﻷشغال')
    ];

    public static getUIBudgetTypes(): UIBudgetType[] {
        return BudgetTypeUtils.budgetTypes;
    }

    public static getBudgetTextByType(type: BudgetType): string {
        let found = BudgetTypeUtils.findUIBudgetByType(type);

        if (!found) {
            throw new TypeError('BudgetType est inconnu');
        }

        return found.text;
    }

    public static getBudgetMediumTextByType(type: BudgetType): string {
        let found = BudgetTypeUtils.findUIBudgetByType(type);

        if (!found) {
            throw new TypeError('BudgetType est inconnu');
        }

        return found.mediumText;
    }

    public static getBudgetSmTextByType(type: BudgetType): string {
        let found = BudgetTypeUtils.findUIBudgetByType(type);

        if (!found) {
            throw new TypeError('BudgetType est inconnu');
        }

        return found.smallText;
    }

    private static findUIBudgetByType(type: BudgetType): UIBudgetType {
        let fn = BudgetTypeUtils.budgetTypes.find(bt => bt.type === type);
        return fn;
    }

    public static isBudgetTypeEquals(budgetType: BudgetType, type: BudgetType): boolean {
        return budgetType && type === budgetType;
    }

    public static isBudgetTypeOnQuotation(budgetType: BudgetType): boolean {
        return BudgetTypeUtils.isBudgetTypeEquals(budgetType, BudgetType.ON_QUOTATION);
    }

}
