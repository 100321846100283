<app-modal modalId="report-user-modal"
           [modalEventListener]="messageModalEvent$"
           modalClass="bottom-sheet-modal-height bottom-sheet"
           (ready)="initListener$.next()"
           (close)="closeModal()">

    <h1 class="col px-0 my-0" modal-header>{{'global.report' | translate}} {{(userToReport$ | async)?.profile?.firstName}} {{(userToReport$ | async)?.profile?.lastName}}</h1>

    <app-report-user [user]="userToReport$ | async"
                     [loading$]="loading$"
                     [notification]="notification$ | async"
                     [initListener$]="initListener$"
                     (cancel)="cancel()"
                     (report)="report($event)"
                     modal-content>
    </app-report-user>
</app-modal>