import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {resetPasswordActions} from '../actions';
import {ErrorsDto} from '../../models';
import {NotificationService, PasswordService} from '../../services';
import {ErrorUtils} from '../../utils';

@Injectable()
export class PasswordResetEffects {

    /**
     * Token validation
     */
    @Effect()
    validateToken: Observable<Action> = this.actions$.pipe(
        ofType<resetPasswordActions.ValidateResetPasswordTokenAction>(resetPasswordActions.PasswordResetActionTypes.ResetPasswordValidateToken),
        map((action: resetPasswordActions.ValidateResetPasswordTokenAction) => action.payload),
        mergeMap(data =>
            this.passwordResetService.isPasswordResetTokenValid(data.email, data.token).pipe(
                map(() => new resetPasswordActions.ValidateResetPasswordTokenSuccessAction()),
                catchError(errors => of(new resetPasswordActions.ValidateResetPasswordTokenFailAction(errors)))
            )
        )
    );

    /**
     * Reset Password
     */
    @Effect()
    resetPassword: Observable<Action> = this.actions$.pipe(
        ofType<resetPasswordActions.ResetPasswordAction>(resetPasswordActions.PasswordResetActionTypes.ResetPassword),
        map((action: resetPasswordActions.ResetPasswordAction) => action.payload),
        mergeMap(data =>
            this.passwordResetService.resetPassword(data.email, data.token, data.passwordReset).pipe(
                map(() => new resetPasswordActions.ResetPasswordSuccessAction(this.notificationService.buildPasswordResetSuccessNotification())),
                catchError((errors: ErrorsDto) => of(new resetPasswordActions.ResetPasswordFailAction(ErrorUtils.toNotification(errors))))
            )
        )
    );

    constructor(private passwordResetService: PasswordService,
                private notificationService: NotificationService,
                private actions$: Actions) {
    }
}
