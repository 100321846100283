import {Action} from '@ngrx/store';
import {AddReviewDto, BasicUserDto, DisplayReviewDto, ErrorsDto, ReviewRatingDto} from '../../models';

export enum ReviewActionTypes {
    LoadReviewRatings = '[REVIEW] LOAD_REVIEW_RATINGS',
    LoadReviewRatingsSuccess = '[REVIEW] LOAD_REVIEW_RATINGS_SUCCESS',
    LoadReviewRatingsFail = '[REVIEW] LOAD_REVIEW_RATINGS_FAIL',

    AddReview = '[REVIEW] ADD_REVIEW',
    AddReviewSuccess = '[REVIEW] ADD_REVIEW_SUCCESS',
    AddReviewFail = '[REVIEW] ADD_REVIEW_FAIL',

    LoadUserReviews = '[REVIEW] LOAD_USER_REVIEWS',
    LoadUserReviewsSuccess = '[REVIEW] LOAD_USER_REVIEWS_SUCCESS',
    LoadUserReviewsFail = '[REVIEW] LOAD_USER_REVIEWS_FAIL',

    SetUser = '[REVIEW] SET_USER',
    ResetIsReviewAdded = '[REVIEW] RESET_IS_REVIEW_ADDED'
}


export class ResetIsReviewAddedAction implements Action {
    readonly type = ReviewActionTypes.ResetIsReviewAdded;
}

export class LoadReviewRatingsAction implements Action {
    readonly type = ReviewActionTypes.LoadReviewRatings;
}

export class LoadReviewRatingsSuccessAction implements Action {
    readonly type = ReviewActionTypes.LoadReviewRatingsSuccess;

    constructor(public payload: ReviewRatingDto[]) {
    }
}

export class LoadReviewRatingsFailAction implements Action {
    readonly type = ReviewActionTypes.LoadReviewRatingsFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class AddReviewAction implements Action {
    readonly type = ReviewActionTypes.AddReview;

    constructor(public payload: AddReviewDto) {
    }
}

export class AddReviewSuccessAction implements Action {
    readonly type = ReviewActionTypes.AddReviewSuccess;
}

export class AddReviewFailAction implements Action {
    readonly type = ReviewActionTypes.AddReviewFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class LoadUserReviewsAction implements Action {
    readonly type = ReviewActionTypes.LoadUserReviews;

    constructor(public payload: string) {
    }
}

export class LoadUserReviewsSuccessAction implements Action {
    readonly type = ReviewActionTypes.LoadUserReviewsSuccess;

    constructor(public payload: DisplayReviewDto[]) {
    }
}

export class LoadUserReviewsFailAction implements Action {
    readonly type = ReviewActionTypes.LoadUserReviewsFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class SetUserAction implements Action {
    readonly type = ReviewActionTypes.SetUser;

    constructor(public payload: BasicUserDto) {
    }
}

export type Actions = LoadReviewRatingsAction
    | LoadReviewRatingsSuccessAction
    | LoadReviewRatingsFailAction
    | AddReviewAction
    | AddReviewSuccessAction
    | AddReviewFailAction
    | LoadUserReviewsAction
    | LoadUserReviewsSuccessAction
    | LoadUserReviewsFailAction
    | SetUserAction
    | ResetIsReviewAddedAction
    ;
