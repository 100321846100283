import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Notification} from '../../modules/core/models';
import {NotificationService, SeoService} from '../../modules/core/services';
import {authenticationActions} from '../../modules/core/store/actions';

import * as routeActions from '../../modules/core/store/actions/route.action';
import * as signUpActions from '../../modules/core/store/actions/signup.action';
import {CommonUtils, ErrorUtils} from '../../modules/core/utils';
import {BcValidators} from '../../modules/core/validators';
import * as fromRoot from '../../modules/store/';
import {SignUpHelper} from './helper/sign-up.helper';

@Component({
    moduleId: module.id,
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    providers: [SignUpHelper]
})
export class SignUpComponent implements OnInit, OnDestroy {

    private subscription = new Subscription();

    userRegistration: FormGroup;

    loading$: Observable<boolean>;
    registered$: Observable<boolean>;
    notification$: Observable<Notification>;
    socialNotification: Notification;

    mailAlreadyTaken: boolean;


    constructor(protected store: Store<fromRoot.State>,
                private route: ActivatedRoute,
                private notificationService: NotificationService,
                private fb: FormBuilder,
                private seo: SeoService,
                private signUpHelper: SignUpHelper) {

        this.userRegistration = this.fb.group({
            acceptTermsOfService: [false, Validators.pattern('true')],
            email: ['', Validators.compose([Validators.required, BcValidators.login])],
            password: ['', Validators.compose([Validators.required, BcValidators.password])],
        });

        this.loading$ = this.store.pipe(select(fromRoot.isSignUpRegistrationLoading));
        this.registered$ = this.store.pipe(select(fromRoot.isSignUpRegistered));
        this.notification$ = this.store.pipe(
            select(fromRoot.getSignUpRegistrationErrors),
            map(errors => {
                this.mailAlreadyTaken = ErrorUtils.hasDetail(errors, 'user.login.already-exists');
                return ErrorUtils.toNotification(errors);
            })
        );

        this.seo.setMeta('sign-up');
        this.seo.setRobots(true);
    }

    ngOnInit(): void {
        // this.registered$
        //     .filter(r => r)
        //     .subscribe(() => {
        //         let data = RegistrationConfirmationParams.build(this.userRegistration.email, true);
        //         this.store.dispatch(new routeActions.ToConfirmRegistrationAction(data));
        //     });

        this.route.queryParams.pipe(
            filter(params => params.error))
            .subscribe(() => {
                this.socialNotification = this.notificationService.buildGlobalErrorNotification();
            });

        let sub = this.notification$.pipe(filter(CommonUtils.notEmpty))
            .subscribe(notification => {
                this.alertNotification(notification);
            });

        this.subscription.add(sub);
    }

    ngOnDestroy(): void {
        this.store.dispatch(new signUpActions.ResetAction());
        this.subscription.unsubscribe();
    }

    onSubmit() {
        this.store.dispatch(new signUpActions.SignUpAction(this.userRegistration.value));
    }

    toPasswordForgotten() {
        this.store.dispatch(new routeActions.ToPasswordForgotten(this.getEmail()));
    }

    get getEmail() {
        return this.userRegistration.get('email').value;
    }

    get acceptTermsOfService(): boolean {
        return this.userRegistration.get('acceptTermsOfService').value;
    }

    signUpWithFB(eventData: any) {
        if (eventData.error) {
            this.alertNotification(this.notificationService.buildGlobalErrorNotification());
        } else {
            this.store.dispatch(new authenticationActions.SocialAuthenticateAction(eventData.loginResponse.token));
        }
    }

    acceptTermsChange(args: any) {
        if (!args || !args.object) {
            return false;
        }

        let box = args.object;
        this.userRegistration.get('acceptTermsOfService').setValue(box.checked);
    }

    alertNotification(notif: Notification) {
        this.signUpHelper.alertNotification(notif);
    }
}
