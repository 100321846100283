import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { activateAccountActions, routeActions } from '../../modules/core/store/actions';
import { AccountActivationDto } from '../../modules/core/models';
import { SeoService } from '../../modules/core/services';
import * as fromRoot from '../../modules/store';
var AccountActivationComponent = /** @class */ (function () {
    function AccountActivationComponent(store, route, seo) {
        this.store = store;
        this.route = route;
        this.seo = seo;
        this.subscription = new Subscription();
        this.notification$ = this.store.pipe(select(fromRoot.getAccountActivationNotification));
        this.loading$ = this.store.pipe(select(fromRoot.isAccountActivationLoading));
        this.accountActivated$ = this.store.pipe(select(fromRoot.isAccountActivated));
        seo.setMeta('account-activation');
        seo.setRobots(false);
    }
    AccountActivationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            var accountActivation = new AccountActivationDto();
            accountActivation.userUid = params.uid;
            accountActivation.token = params.token;
            _this.store.dispatch(new activateAccountActions.ActivateAction(accountActivation));
        });
        var activatedSub = this.accountActivated$
            .pipe(filter(function (a) { return a; }))
            .subscribe(function () { return setTimeout(function () { return _this.store.dispatch(new routeActions.ToSignInAction()); }, 3000); });
        this.subscription.add(activatedSub);
    };
    AccountActivationComponent.prototype.ngOnDestroy = function () {
        this.store.dispatch(new activateAccountActions.ResetAction());
        this.subscription.unsubscribe();
    };
    return AccountActivationComponent;
}());
export { AccountActivationComponent };
