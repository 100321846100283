import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ListMode} from '../../../core/models';

@Component({
    moduleId: module.id,
    selector: 'app-list-mode',
    templateUrl: './list-mode.component.html',
    styleUrls: ['./list-mode.component.scss'],
})
export class ListModeComponent {

    ListMode = ListMode;

    listModes: UIListMode[] = [
        ListModeComponent.buildListMode('global.list-mode.list', ListMode.LIST, 'list'),
        ListModeComponent.buildListMode('global.list-mode.map', ListMode.MAP, 'place')
    ];

    @Input() mode = ListMode;
    @Input() showList = true;
    @Input() showMap = true;

    @Output() select = new EventEmitter<ListMode>();

    public static buildListMode(text: string, mode: ListMode, icon: string): UIListMode {
        return {
            text: text,
            mode: mode,
            icon: icon
        }
    }

}

interface UIListMode {
    text: string,
    mode: ListMode,
    icon: string
}

