import { OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ErrorUtils } from '../../../../core/utils';
import * as fromRoot from '../../../../store';
import { reportUserActions } from '../../../store/actions';
var ReportUserModalComponent = /** @class */ (function () {
    function ReportUserModalComponent(store) {
        this.store = store;
        this.initListener$ = new Subject();
        this.userToReport$ = this.store.pipe(select(fromRoot.getUserToReport));
        this.messageModalEvent$ = this.store.pipe(select(fromRoot.getReportUserModalEvent));
        this.loading$ = this.store.pipe(select(fromRoot.isReportUserLoading));
        this.isUserReported$ = this.store.pipe(select(fromRoot.isUserReported));
        this.notification$ = this.store.pipe(select(fromRoot.getReportUserErrors), map(ErrorUtils.toNotification));
    }
    ReportUserModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isUserReported$.pipe(filter(function (reported) { return reported; }))
            .subscribe(function () {
            _this.closeModal();
        });
    };
    ReportUserModalComponent.prototype.cancel = function () {
        this.store.dispatch(new reportUserActions.ResetAction());
        this.closeModal();
    };
    ReportUserModalComponent.prototype.report = function (userReport) {
        this.store.dispatch(new reportUserActions.ReportUserAction(userReport));
    };
    ReportUserModalComponent.prototype.closeModal = function () {
        this.store.dispatch(new reportUserActions.CloseModalAction());
    };
    return ReportUserModalComponent;
}());
export { ReportUserModalComponent };
