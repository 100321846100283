import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {Config} from '../../../../config';
import {categoryActions} from '../../../core/store/actions';
import {StorageKey} from '../../models/interfaces';
import {BrowserService, StorageService} from '../../services';
import {localeActions} from '../actions';

@Injectable()
export class LocaleEffects {

    @Effect()
    localeSelected: Observable<Action> = this.actions$.pipe(
        ofType<localeActions.UpdateLocaleAction>(localeActions.LocaleActionsTypes.UpdateLocale),
        map((action: localeActions.UpdateLocaleAction) => action.payload),
        map(selectedLocale => {
            const date = new Date();
            date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));

            this.storageService.setItem(StorageKey.LOCALE, selectedLocale.shortcut);
            this.browserService.setCookie(StorageKey.LANG, selectedLocale.shortcut, date, '/');

            this.translateService.use(selectedLocale.shortcut).subscribe(() => {
                if (Config.IS_WEB()) {
                    // TODO redirect to same context
                    // let redirect = Locale.isArabic(selectedLocale) ? ContextUtils.arabizeContextPath() : ContextUtils.frenchifyContextPath();
                    let redirect = `/${selectedLocale.shortcut}`;
                    this.router.navigateByUrl(redirect);
                } else {
                    let currentUrl = this.router.url;
                    this.router.navigateByUrl('/locale-change')
                        .then(() => this.router.navigateByUrl(currentUrl));
                }
            });

            return new localeActions.SetLocaleAction(selectedLocale);
        }),
    );

    @Effect()
    setLocale: Observable<Action> = this.actions$.pipe(
        ofType<localeActions.SetLocaleAction>(localeActions.LocaleActionsTypes.SetLocale),
        map((action: localeActions.SetLocaleAction) => action.payload),
        map(payload => {
            return new categoryActions.SwitchLangAction(payload);
        })
    );

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private router: Router,
                private storageService: StorageService,
                private actions$: Actions,
                private browserService: BrowserService,
                private translateService: TranslateService) {
    }

}
