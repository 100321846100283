import {CategoryDto, ErrorsDto} from '../../models';

import {categoryActions} from '../actions';

export interface State {
    categories: CategoryDto[],
    errors: ErrorsDto,
    loading: boolean
}

export const initialState: State = {
    categories: [],
    errors: null,
    loading: false
};

export function reducer(state = initialState, action: categoryActions.Actions): State {

    switch (action.type) {

        case categoryActions.CategoryActionTypes.LoadCategories: {
            return {
                loading: true,
                errors: null,
                categories: []
            }
        }

        case categoryActions.CategoryActionTypes.SwitchLang: {
            return {
                loading: true,
                errors: null,
                categories: state.categories
            }
        }

        case categoryActions.CategoryActionTypes.LoadCategoriesSuccess: {
            return {
                loading: false,
                errors: null,
                categories: action.payload
            }
        }

        case categoryActions.CategoryActionTypes.LoadCategoriesFail: {
            return {
                loading: false,
                errors: action.payload,
                categories: []
            }
        }

        default: {
            return state;
        }
    }
}

export const getCategories = (state: State): CategoryDto[] => state.categories;
export const getErrors = (state: State): ErrorsDto => state.errors;
export const isLoading = (state: State): boolean => state.loading;
