import {ActivityDto, CategoryDto} from '../models';
import {CommonUtils} from './common.util';
import {FlatServiceDto, ServiceType} from '../models/flat-service';

export class CategoryUtils {

    public static containsCategory(categories: CategoryDto[], category: CategoryDto): boolean {
        if (!category) {
            return false;
        }

        return this.containsById(categories, category.id);
    }

    public static containsById(categories: CategoryDto[], categoryId: number): boolean {
        return this.getById(categories, categoryId) != null;
    }

    public static getById(categories: CategoryDto[], categoryId: number): CategoryDto {

        if (!categories) {
            return null;
        }

        let find = categories.find(c => c.id === categoryId);

        if (!find) {
            return null;
        }

        return find;
    }

    public static getCategoriesOfActivities(activities: ActivityDto[]): CategoryDto[] {

        let categories = [];

        if (!activities) {
            return categories;
        }

        activities.forEach(act => {
            let cat = act.category;

            if (!CategoryUtils.containsCategory(categories, cat)) {
                categories.push(cat);
            }
        });

        return categories;
    }

    public static toCategoriesTree(activities: ActivityDto[]): CategoryDto[] {

        let categories = [];

        if (!activities) {
            return categories;
        }

        activities.forEach(act => {

            let category = CommonUtils.cloneDeep(act.category);

            let cat = this.getById(categories, category.id);

            if (!cat) {
                cat = category;
                categories.push(cat);
            }

            if (!cat.activities) {
                cat.activities = [];
            }

            cat.activities.push(act);
        });

        return categories;
    }

    static toFlatServices(categories: CategoryDto[]): FlatServiceDto[] {

        let services: FlatServiceDto[] = [];

        categories.forEach(category => {
            let flatService = new FlatServiceDto();
            flatService.id = category.id;
            flatService.name = category.name;
            flatService.type = ServiceType.CATEGORY;

            services.push(flatService);

            category.activities.forEach(act => {
                let fs = new FlatServiceDto();
                fs.id = act.id;
                fs.name = act.name;
                fs.type = ServiceType.ACTIVITY;

                services.push(fs)
            });

        });

        return services;
    }

}
