var Config = /** @class */ (function () {
    function Config() {
    }
    // convenient platform checks
    Config.IS_WEB = function () {
        return Config.PLATFORM_TARGET === Config.PLATFORMS.WEB;
    };
    Config.IS_MOBILE_NATIVE = function () {
        return Config.PLATFORM_TARGET === Config.PLATFORMS.MOBILE_NATIVE;
    };
    Config.IS_MOBILE_HYBRID = function () {
        return Config.PLATFORM_TARGET === Config.PLATFORMS.MOBILE_HYBRID;
    };
    Config.IS_DESKTOP = function () {
        return Config.PLATFORM_TARGET === Config.PLATFORMS.DESKTOP;
    };
    Config.ENVIRONMENT = function () {
        return {
            API: 'http://10.0.2.2:8081',
            RADIUS: 100
        };
    };
    // supported languages
    Config.GET_SUPPORTED_LANGUAGES = function () {
        return [
            { code: 'en', title: 'English' },
            { code: 'es', title: 'Spanish' },
            { code: 'fr', title: 'Français' },
            { code: 'ru', title: 'Russian' },
            { code: 'bg', title: 'Bulgarian' }
        ];
    };
    Config.IS_DEBUG_MODE = function () {
        for (var key in Config.DEBUG) {
            if (Config.DEBUG[key]) {
                // if any level is on, debug mode is on
                return true;
            }
        }
        return false;
    };
    // reset debug defaults
    Config.RESET = function () {
        for (var key in Config.DEBUG) {
            Config.DEBUG[key] = false;
        }
    };
    Config.PLACES_COUNTRY_RESTRICTIONS = []; // ['ma', 'EH'];
    Config.ALLOWED_COUNTRIES = ['AE', 'BH', 'DZ', 'EG', 'IQ', 'IR', 'JO', 'KW', 'LB', 'LY', 'MA', 'OM', 'PK', 'PS', 'QA', 'SA', 'SD', 'SN', 'SS', 'SY', 'TN', 'YE'];
    Config.PREFERRED_COUNTRIES = ['MA'];
    Config.INITIAL_COUNTRY = 'MA';
    Config.INITIAL_DIAL_CODE = '+212';
    Config.DEBUG = {
        LEVEL_1: false,
        LEVEL_2: false,
        LEVEL_3: false,
        LEVEL_4: false // .log + all the above
    };
    // supported platforms
    Config.PLATFORMS = {
        WEB: 'web',
        MOBILE_NATIVE: 'mobile_native',
        MOBILE_HYBRID: 'mobile_hybrid',
        DESKTOP: 'desktop'
    };
    // current target (defaults to web)
    Config.PLATFORM_TARGET = Config.PLATFORMS.WEB;
    return Config;
}());
export { Config };
