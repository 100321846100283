import { EventEmitter, OnInit } from '@angular/core';
import { ChipImpl } from '../../../core/models';
var ChipsComponent = /** @class */ (function () {
    function ChipsComponent() {
        this.closable = false;
        this.fromString = false;
        this.chipSelect = new EventEmitter();
        this.chipClose = new EventEmitter();
        this.hasOverflow = false;
    }
    ChipsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.fromString) {
            if (this.data) {
                this.chips = [];
                if (this.data instanceof Array) {
                    this.data.forEach(function (d) {
                        var chip = new ChipImpl();
                        chip.id = d;
                        chip.value = d;
                        chip.tag = d;
                        _this.chips.push(chip);
                    });
                }
                else {
                    var chip = new ChipImpl();
                    chip.id = this.data;
                    chip.value = this.data;
                    chip.tag = this.data;
                    this.chips.push(chip);
                }
            }
        }
        else {
            if (this.data instanceof Array) {
                this.chips = this.data;
            }
            else {
                this.chips = [this.data];
            }
        }
        this.originalLength = this.chips.length;
        if (this.limitDisplaySize) {
            if (this.chips.length > this.limitDisplaySize) {
                this.chips = this.chips.slice(0, this.limitDisplaySize);
                this.hasOverflow = true;
            }
            // this.chips = this.chips.slice(0, this.limitDisplaySize);
        }
    };
    ChipsComponent.prototype.onChipSelect = function (chip, event) {
        this.chipSelect.emit(chip);
        event.stopPropagation();
    };
    ChipsComponent.prototype.onChipClose = function (chip) {
        this.chipClose.emit(chip);
    };
    return ChipsComponent;
}());
export { ChipsComponent };
