var ControlMessagesComponent = /** @class */ (function () {
    function ControlMessagesComponent() {
        this.keyPrefix = 'errors';
    }
    Object.defineProperty(ControlMessagesComponent.prototype, "messages", {
        get: function () {
            var keys = [];
            if (this.control.hasOwnProperty('controls')) {
                if (this.control && this.controlName && this.control.get(this.controlName)) {
                    var prefix = this.keyPrefix + "." + this.controlName;
                    var ks = this.handleAbstractControl(this.control.get(this.controlName), prefix);
                    if (ks && ks.length > 0) {
                        keys = ks.slice();
                    }
                }
            }
            else {
                keys = this.handleAbstractControl(this.control, this.keyPrefix);
            }
            return keys;
        },
        enumerable: true,
        configurable: true
    });
    ControlMessagesComponent.prototype.handleAbstractControl = function (ac, prefix) {
        var keys = [];
        var cnErrors = ac.errors;
        for (var propertyName in cnErrors) {
            if (cnErrors.hasOwnProperty(propertyName) && ac.touched) {
                var errorKey = this.keyErrorKey(propertyName);
                if (!errorKey) {
                    errorKey = prefix + "." + propertyName;
                }
                keys.push(errorKey);
            }
        }
        return keys;
    };
    ControlMessagesComponent.prototype.keyErrorKey = function (error) {
        if (!this.config) {
            return null;
        }
        var conf = this.config.find(function (c) { return c.error === error; });
        return conf ? conf.key : null;
    };
    return ControlMessagesComponent;
}());
export { ControlMessagesComponent };
