import {AccountActivationComponent} from './account-activation/account-activation.component';
import {EmailVerificationComponent} from './email-verification/email-verification.component';
import {HomeComponent} from './home/home.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {AboutComponent} from './pages/about/about.component';
import {CgvComponent} from './pages/cgv/cgv.component';
import {CommentCaMarcheComponent} from './pages/comment-ca-marche/comment-ca-marche.component';
import {CookieUsageComponent} from './pages/cookie-usage/cookie-usage.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {PasswordForgottenComponent} from './password-forgotten/password-forgotten.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {PostSocialSignInComponent} from './post-social-sign-in/post-social-sign-in.component';
import {SignInComponent} from './sign-in/sign-in.component';
import {SignUpConfirmComponent} from './sign-up/confirm/signup-confirm.component';
import {SignUpComponent} from './sign-up/signup.component';

export const APP_COMPONENTS: any[] = [
    SignInComponent,
    PasswordForgottenComponent,
    PasswordResetComponent,
    SignUpComponent,
    HomeComponent,
    AccountActivationComponent,
    SignUpConfirmComponent,
    NotFoundComponent,
    CgvComponent,
    CookieUsageComponent,
    AboutComponent,
    PostSocialSignInComponent,
    CommentCaMarcheComponent,
    EmailVerificationComponent,
    PrivacyPolicyComponent
];

export {
    SignInComponent,
    PasswordForgottenComponent,
    PasswordResetComponent,
    SignUpComponent,
    HomeComponent,
    AccountActivationComponent,
    SignUpConfirmComponent,
    NotFoundComponent,
    CgvComponent,
    CookieUsageComponent,
    AboutComponent,
    PostSocialSignInComponent,
    CommentCaMarcheComponent,
    EmailVerificationComponent,
    PrivacyPolicyComponent
}
