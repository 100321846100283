import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {CategoryDto} from '../models';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    private static CATEGORIES = 'api/v1/categories';

    constructor(private httpClient: HttpClient) {
    }

    getCategories(): Observable<CategoryDto[]> {
        return this.httpClient.get<CategoryDto[]>(CategoryService.CATEGORIES);
    }

}
