export var RequestEditActionTypes;
(function (RequestEditActionTypes) {
    RequestEditActionTypes["LoadRequest"] = "[UPDATE REQUEST] LOAD_REQUEST";
    RequestEditActionTypes["LoadRequestSuccess"] = "[UPDATE REQUEST] LOAD_REQUEST_SUCCESS";
    RequestEditActionTypes["LoadRequestFail"] = "[UPDATE REQUEST] LOAD_REQUEST_FAIL";
    RequestEditActionTypes["UpdateRequest"] = "[UPDATE REQUEST] UPDATE_REQUEST";
    RequestEditActionTypes["UpdateRequestSuccess"] = "[UPDATE REQUEST] UPDATE_REQUEST_SUCCESS";
    RequestEditActionTypes["UpdateRequestFail"] = "[UPDATE REQUEST] UPDATE_REQUEST_FAIL";
    RequestEditActionTypes["ResetUpdated"] = "[REQUEST] RESET_UPDATED";
    RequestEditActionTypes["DeleteRequest"] = "[REQUEST] DELETE_REQUEST";
    RequestEditActionTypes["DeleteRequestSuccess"] = "[REQUEST] DELETE_REQUEST_SUCCESS";
    RequestEditActionTypes["DeleteRequestFail"] = "[REQUEST] DELETE_REQUEST_FAIL";
    RequestEditActionTypes["ResetDeleted"] = "[REQUEST] RESET_DELETED";
    RequestEditActionTypes["CloseRequest"] = "[REQUEST] CLOSE_REQUEST";
    RequestEditActionTypes["CloseRequestSuccess"] = "[REQUEST] CLOSE_REQUEST_SUCCESS";
    RequestEditActionTypes["CloseRequestFail"] = "[REQUEST] CLOSE_REQUEST_FAIL";
    RequestEditActionTypes["ResetClosed"] = "[REQUEST] RESET_CLOSED";
    RequestEditActionTypes["SetSelectedRequest"] = "[UPDATE REQUEST] SET_SELECTED_REQUEST";
    RequestEditActionTypes["SetActivity"] = "[UPDATE REQUEST] SET_ACTIVITY";
    RequestEditActionTypes["SetAddress"] = "[UPDATE REQUEST] SET_ADDRESS";
    RequestEditActionTypes["SetNotification"] = "[UPDATE REQUEST] SET_NOTIFICATION";
    RequestEditActionTypes["Reset"] = "[UPDATE REQUEST] RESET";
    RequestEditActionTypes["ResetRequest"] = "[UPDATE REQUEST] RESET_REQUEST";
})(RequestEditActionTypes || (RequestEditActionTypes = {}));
var ResetUpdatedAction = /** @class */ (function () {
    function ResetUpdatedAction() {
        this.type = RequestEditActionTypes.ResetUpdated;
    }
    return ResetUpdatedAction;
}());
export { ResetUpdatedAction };
var ResetRequestAction = /** @class */ (function () {
    function ResetRequestAction() {
        this.type = RequestEditActionTypes.ResetRequest;
    }
    return ResetRequestAction;
}());
export { ResetRequestAction };
var UpdateRequestAction = /** @class */ (function () {
    function UpdateRequestAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.UpdateRequest;
    }
    return UpdateRequestAction;
}());
export { UpdateRequestAction };
var LoadRequestAction = /** @class */ (function () {
    function LoadRequestAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.LoadRequest;
    }
    return LoadRequestAction;
}());
export { LoadRequestAction };
var LoadRequestSuccessAction = /** @class */ (function () {
    function LoadRequestSuccessAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.LoadRequestSuccess;
    }
    return LoadRequestSuccessAction;
}());
export { LoadRequestSuccessAction };
var SetActivityAction = /** @class */ (function () {
    function SetActivityAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.SetActivity;
    }
    return SetActivityAction;
}());
export { SetActivityAction };
var SetAddressAction = /** @class */ (function () {
    function SetAddressAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.SetAddress;
    }
    return SetAddressAction;
}());
export { SetAddressAction };
var LoadRequestFailAction = /** @class */ (function () {
    function LoadRequestFailAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.LoadRequestFail;
    }
    return LoadRequestFailAction;
}());
export { LoadRequestFailAction };
var UpdateRequestSuccessAction = /** @class */ (function () {
    function UpdateRequestSuccessAction() {
        this.type = RequestEditActionTypes.UpdateRequestSuccess;
    }
    return UpdateRequestSuccessAction;
}());
export { UpdateRequestSuccessAction };
var UpdateRequestFailAction = /** @class */ (function () {
    function UpdateRequestFailAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.UpdateRequestFail;
    }
    return UpdateRequestFailAction;
}());
export { UpdateRequestFailAction };
var SetSelectedRequestAction = /** @class */ (function () {
    function SetSelectedRequestAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.SetSelectedRequest;
    }
    return SetSelectedRequestAction;
}());
export { SetSelectedRequestAction };
var ResetAction = /** @class */ (function () {
    function ResetAction() {
        this.type = RequestEditActionTypes.Reset;
    }
    return ResetAction;
}());
export { ResetAction };
var SetNotificationAction = /** @class */ (function () {
    function SetNotificationAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.SetNotification;
    }
    return SetNotificationAction;
}());
export { SetNotificationAction };
var DeleteRequestAction = /** @class */ (function () {
    function DeleteRequestAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.DeleteRequest;
    }
    return DeleteRequestAction;
}());
export { DeleteRequestAction };
var DeleteRequestSuccessAction = /** @class */ (function () {
    function DeleteRequestSuccessAction() {
        this.type = RequestEditActionTypes.DeleteRequestSuccess;
    }
    return DeleteRequestSuccessAction;
}());
export { DeleteRequestSuccessAction };
var DeleteRequestFailAction = /** @class */ (function () {
    function DeleteRequestFailAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.DeleteRequestFail;
    }
    return DeleteRequestFailAction;
}());
export { DeleteRequestFailAction };
var CloseRequestAction = /** @class */ (function () {
    function CloseRequestAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.CloseRequest;
    }
    return CloseRequestAction;
}());
export { CloseRequestAction };
var CloseRequestSuccessAction = /** @class */ (function () {
    function CloseRequestSuccessAction() {
        this.type = RequestEditActionTypes.CloseRequestSuccess;
    }
    return CloseRequestSuccessAction;
}());
export { CloseRequestSuccessAction };
var CloseRequestFailAction = /** @class */ (function () {
    function CloseRequestFailAction(payload) {
        this.payload = payload;
        this.type = RequestEditActionTypes.CloseRequestFail;
    }
    return CloseRequestFailAction;
}());
export { CloseRequestFailAction };
var ResetDeletedAction = /** @class */ (function () {
    function ResetDeletedAction() {
        this.type = RequestEditActionTypes.ResetDeleted;
    }
    return ResetDeletedAction;
}());
export { ResetDeletedAction };
var ResetClosedAction = /** @class */ (function () {
    function ResetClosedAction() {
        this.type = RequestEditActionTypes.ResetClosed;
    }
    return ResetClosedAction;
}());
export { ResetClosedAction };
