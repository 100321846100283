import {Injectable} from '@angular/core';
import {NavigationExtras} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {ErrorsDto} from '../../../core/models';
import {NotificationService, RouteService} from '../../../core/services';

import {routeActions} from '../../../core/store/actions';
import {sendMessageActions} from '../../../core/store/actions';
import {messageModalActions, phoneModalActions} from '../../../core/store/actions';
import * as fromRoot from '../../../store';
import {reportUserActions, userSearchActions, userViewActions} from '../actions';

import {UserService, WorkerSearchCriteriaService} from '../../services';

@Injectable()
export class UserEffects {

    @Effect()
    searchWorkers = this.actions$.pipe(
        ofType(userSearchActions.UsersSearchActionTypes.Search),
        map((action: userSearchActions.SearchAction) => action.payload),
        mergeMap(searchCriteriaWrapper =>
            this.userService.searchWorkers(searchCriteriaWrapper.searchCriteria, searchCriteriaWrapper.pageable).pipe(
                map(page => new userSearchActions.SearchSuccessAction(page)),
                catchError(errors => of(new userSearchActions.SearchFailureAction(errors)))
            )
        )
    );

    @Effect()
    searchAroundIPOrLastWorkers = this.actions$.pipe(
        ofType(userSearchActions.UsersSearchActionTypes.SearchAroundIpOrLast),
        map((action: userSearchActions.SearchAroundIPOrLastAction) => action.payload),
        mergeMap(pageable =>
            this.userService.searchAroundIPOrLastWorkers(pageable).pipe(
                map(page => new userSearchActions.SearchAroundIPOrLastSuccessAction(page)),
                catchError(errors => of(new userSearchActions.SearchAroundIPOrLastFailAction(errors)))
            )
        )
    );

    @Effect()
    reportUser = this.actions$.pipe(
        ofType(reportUserActions.UserReportActionTypes.ReportUser),
        map((action: reportUserActions.ReportUserAction) => action.payload),
        mergeMap(userReport =>
            this.userService.reportUser(userReport).pipe(
                map(() => {
                        this.notificationService.displaySuccessKey('reports.thanks');

                        return new reportUserActions.ReportUserSuccessAction();
                    }
                ),
                catchError((errors: ErrorsDto) => of(new reportUserActions.ReportUserFailAction(errors)))
            )
        )
    );

    @Effect()
    loadUserProfile = this.actions$.pipe(
        ofType(userViewActions.UserViewActionTypes.LoadUserViewProfile),
        map((action: userViewActions.LoadUserViewProfileAction) => action.payload),
        mergeMap(uid =>
            this.userService.getWorker(uid).pipe(
                map(profile => new userViewActions.LoadUserViewProfileSuccessAction(profile)),
                catchError(errors => of(new userViewActions.LoadUserViewProfileFailureAction(errors)))
            )
        )
    );

    @Effect()
    openSendMessage = this.actions$.pipe(
        ofType(userViewActions.UserViewActionTypes.OpenSendMessage),
        map((action: userViewActions.OpenSendMessageAction) => action.payload),
        withLatestFrom(
            this.store$.select(fromRoot.getCurrentUser),
            (receiver, currentUser) => ({receiver, currentUser})),
        mergeMap(both =>
            [
                new sendMessageActions.SetSenderAction(both.currentUser ? both.currentUser : null),
                new sendMessageActions.SetReceiverAction(both.receiver),
                new messageModalActions.OpenMessageModalAction()
            ]
        )
    );

    @Effect()
    showPhone = this.actions$.pipe(
        ofType(userViewActions.UserViewActionTypes.ShowPhone),
        map((action: userViewActions.ShowPhoneAction) => action.payload),
        mergeMap(user => {
            return [
                new phoneModalActions.OpenPhoneModalAction(),
                new userViewActions.LoadUserProfilePhoneAction(user.uid)
            ]
        })
    );


    @Effect()
    loadPhone = this.actions$.pipe(
        ofType(userViewActions.UserViewActionTypes.LoadUserViewProfilePhone),
        map((action: userViewActions.LoadUserProfilePhoneAction) => action.payload),
        mergeMap(uid =>
            this.userService.getUserPhone(uid).pipe(
                map(phone => new userViewActions.LoadUserProfilePhoneSuccessAction(phone)),
                catchError(errors => of(new userViewActions.LoadUserProfilePhoneFailureAction(errors)))
            )
        )
    );

    @Effect({dispatch: false})
    addUserProfileView = this.actions$.pipe(
        ofType(userViewActions.UserViewActionTypes.AddUserProfileView),
        map((action: userViewActions.AddUserProfileViewAction) => action.payload),
        mergeMap(uid =>
            this.userService.addView(uid).pipe(
                map(() => null),
                catchError(errors => of())
            )
        )
    );

    @Effect({dispatch: false})
    toSearchWorkers = this.actions$.pipe(
        ofType(routeActions.RouteActionTypes.ToSearchWorkers),
        map((action: routeActions.ToSearchWorkersAction) => action.payload),
        mergeMap(payload => {

            let queryParams = this.workerSCService.convertToParams(payload.searchCriteria, payload.pageable);

            let navigationExtras: NavigationExtras = {
                queryParams: queryParams,
            };

            this.routeService.toSearchPage(navigationExtras);

            return of();
        })
    );

    constructor(private actions$: Actions,
                private userService: UserService,
                private store$: Store<fromRoot.State>,
                private workerSCService: WorkerSearchCriteriaService,
                private routeService: RouteService,
                private notificationService: NotificationService) {
    }
}
