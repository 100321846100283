import {Action} from '@ngrx/store';
import {Notice, Pageable, RegistrationConfirmationParams, SearchCriteriaDto} from '../../models';

export enum RouteActionTypes {
    ToHome = '[ROUTER] TO_HOME',
    ToSignIn = '[ROUTER] TO_SIGN_IN',
    ToUserProfile = '[ROUTER] TO_USER_PROFILE',
    ToCompleteUserProfile = '[ROUTER] TO_COMPLETE_USER_PROFILE',
    ToConfirmRegistration = '[ROUTER] TO_CONFIRM_REGISTRATION',
    ToPasswordForgotten = '[ROUTER] TO_PASSWORD_FORGOTTEN',
    ToWorker = '[ROUTER] TO_WORKER',
    ToRequest = '[ROUTER] TO_REQUEST',
    ToUpdateRequest = '[ROUTER] TO_UPDATE_REQUEST',
    ToRequests = '[ROUTER] TO_REQUESTS',
    ToSearchWorkers = '[ROUTER] TO_SEARCH_WORKERS',
    ToPageNotFound = '[ROUTER] TO_PAGE_NOT_FOUND',
    ToForbidden = '[ROUTER] TO_FORBIDDEN',
    ToNoticeTarget = '[ROUTER] ToNoticeTarget',
    ToUrl = '[ROUTER] TO_URL'
}


export class ToUrlAction implements Action {
    readonly type = RouteActionTypes.ToUrl;

    constructor(public payload: string) {
    }
}

export class ToHomeAction implements Action {
    readonly type = RouteActionTypes.ToHome;
}

export class ToSignInAction implements Action {
    readonly type = RouteActionTypes.ToSignIn;
}

export class ToConfirmRegistrationAction implements Action {
    readonly type = RouteActionTypes.ToConfirmRegistration;

    constructor(public payload: RegistrationConfirmationParams) {
    }
}

export class ToUserProfileAction implements Action {
    readonly type = RouteActionTypes.ToUserProfile;
}

export class ToCompleteUserProfileAction implements Action {
    readonly type = RouteActionTypes.ToCompleteUserProfile;
}

export class ToPasswordForgotten implements Action {
    readonly type = RouteActionTypes.ToPasswordForgotten;

    constructor(public payload?: string) {
    }
}

export class ToWorkerAction implements Action {
    readonly type = RouteActionTypes.ToWorker;

    constructor(public payload?: string) {
    }
}

export class ToRequestAction implements Action {
    readonly type = RouteActionTypes.ToRequest;

    constructor(public payload: number) {
    }
}

export class ToUpdateRequestAction implements Action {
    readonly type = RouteActionTypes.ToUpdateRequest;

    constructor(public payload: number) {
    }
}

export class ToRequestsAction implements Action {
    readonly type = RouteActionTypes.ToRequests;
}

export class ToPageNotFoundAction implements Action {
    readonly type = RouteActionTypes.ToPageNotFound;
}

export class ToForbiddenAction implements Action {
    readonly type = RouteActionTypes.ToForbidden;
}

export class ToNoticeTargetAction implements Action {
    readonly type = RouteActionTypes.ToNoticeTarget;

    constructor(public payload: Notice) {
    }
}

export class ToSearchWorkersAction implements Action {
    readonly type = RouteActionTypes.ToSearchWorkers;

    constructor(public payload: { searchCriteria: SearchCriteriaDto, pageable?: Pageable }) {
    }
}

export type Actions = ToHomeAction
    | ToPasswordForgotten
    | ToSignInAction
    | ToCompleteUserProfileAction
    | ToUserProfileAction
    | ToUpdateRequestAction
    | ToRequestAction
    | ToRequestsAction
    | ToWorkerAction
    | ToSearchWorkersAction
    | ToPageNotFoundAction
    | ToForbiddenAction
    | ToUrlAction
    | ToNoticeTargetAction
    ;
