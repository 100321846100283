import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'app-user-reviews-average',
    templateUrl: './user-reviews-average.component.html',
    styleUrls: ['./user-reviews-average.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserReviewsAverageComponent {

    @Input() average: number;

    getStarClass(index: number): string {
        let clazz;

        let num = index - this.average;
        if (num >= 1) {
            clazz = 'fa-star-o';
        } else if (num <= 0) {
            clazz = 'fa-star';
        } else {
            clazz = 'fa-star-half-o';
        }

        return clazz;
    }
}
