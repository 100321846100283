<div class="row" *ngIf="detailed; else iconsOnly">
    <div class="col-md-6">
        <i class="fa fa-address-book verif-icon mr-2" [ngClass]="[verification.idCardVerified ?  'verified' : 'not-verified']">
        </i>
        <span class="verif-text" [ngClass]="{'verified' : verification.idCardVerified}" translate>
            {{verification.idCardVerified ? 'global.id-card-verified' : 'global.id-card-not-verified'}}
        </span>
    </div>

    <div class="col-md-6">
        <i class="fa fa-id-card verif-icon mr-2" [ngClass]="[verification.workerCardVerified ?  'verified' : 'not-verified']">
        </i>
        <span class="verif-text" [ngClass]="{'verified' : verification.workerCardVerified}" translate>
            {{verification.workerCardVerified ? 'global.worker-card-verified' : 'global.worker-card-not-verified'}}
        </span>
    </div>

    <div class="col-md-6">
        <i class="fa fa-at verif-icon mr-2" [ngClass]="[verification['emailVerified'] ?  'verified' : 'not-verified']">
        </i>
        <span class="verif-text" [ngClass]="{'verified' : verification.emailVerified}" translate>
            {{verification.emailVerified ? 'global.email-verified' : 'global.email-not-verified'}}
        </span>
    </div>

    <!--<div class="col-md-6">-->
        <!--<i class="material-icons mr-2" [ngClass]="[verification.phoneVerified ?  'verified' : 'not-verified']">-->
            <!--phone-->
        <!--</i>-->
        <!--<span class="verif-text" [ngClass]="{'verified' : verification.phoneVerified}" translate>-->
            <!--{{verification.phoneVerified ? 'global.phone-verified' : 'global.phone-not-verified'}}-->
        <!--</span>-->
    <!--</div>-->
</div>
<ng-template #iconsOnly>
    <i class="fa fa-address-book verif-icon d-inline-block" aria-hidden="true"
       [ngClass]="[verification.idCardVerified ?  'verified' : 'not-verified']"
       [title]="(verification.idCardVerified ? 'global.id-card-verified' : 'global.id-card-not-verified') | translate">
    </i>
    <i class="fa fa-id-card verif-icon d-inline-block mx-2" aria-hidden="true"
       [ngClass]="[verification.workerCardVerified ?  'verified' : 'not-verified']"
       [title]="(verification.workerCardVerified ? 'global.worker-card-verified' : 'global.worker-card-not-verified') | translate">
    </i>
    <i class="fa fa-at verif-icon d-inline-block" aria-hidden="true"
       [ngClass]="[verification.emailVerified ?  'verified' : 'not-verified']"
       [title]="(verification.emailVerified ? 'global.email-verified' : 'global.email-not-verified') | translate">
    </i>
    <!--<i class="material-icons d-inline-block"-->
       <!--[ngClass]="[verification.phoneVerified ?  'verified' : 'not-verified']"-->
       <!--[title]="(verification.phoneVerified ? 'global.phone-verified' : 'global.phone-not-verified') | translate">-->
        <!--phone-->
    <!--</i>-->
</ng-template>
