var UploaderConfig = /** @class */ (function () {
    function UploaderConfig(url, autoUpload, allowedMimeType, allowedFileType, maxFileSize) {
        if (autoUpload === void 0) { autoUpload = true; }
        this.url = url;
        this.autoUpload = autoUpload;
        this.allowedMimeType = allowedMimeType;
        this.allowedFileType = allowedFileType;
        this.maxFileSize = maxFileSize;
    }
    return UploaderConfig;
}());
export { UploaderConfig };
