var RegistrationConfirmationParams = /** @class */ (function () {
    function RegistrationConfirmationParams() {
    }
    RegistrationConfirmationParams.build = function (email, postSignUp) {
        var rcp = new RegistrationConfirmationParams;
        rcp.email = email;
        rcp.postSignUp = postSignUp;
        return rcp;
    };
    return RegistrationConfirmationParams;
}());
export { RegistrationConfirmationParams };
