export var IntroStatus = {
    POSTPONED: "POSTPONED",
    FINISHED: "FINISHED",
    IGNORED: "IGNORED",
};
var IntroModel = /** @class */ (function () {
    function IntroModel() {
        this.version = 'v1';
    }
    return IntroModel;
}());
export { IntroModel };
