import {Action} from '@ngrx/store';

import {ProfileDto, RequestDto} from '../../models';

export enum ShareActionsTypes {
    Share = '[SHARE] Share',
    Clear = '[SHARE] Clear',
}

export class ShareAction implements Action {
    readonly type = ShareActionsTypes.Share;

    constructor(public payload: ProfileDto | RequestDto) {
    }
}

export class ClearAction implements Action {
    readonly type = ShareActionsTypes.Clear;
}

export type Actions = ShareAction
    | ClearAction;
