import {Injectable} from '@angular/core';
import {IAnalyticsService} from '../../core/models/interfaces';

declare var ga: any;
declare var dataLayer: any;
declare var window: any;

@Injectable({
    providedIn: 'root'
})
export class AnalyticsWebService implements IAnalyticsService {

    pageTrack(path: string): void {
        if (typeof ga !== 'undefined' && ga) {
            // dataLayer.push({
            //     'event': 'pageview',
            //     'url': path
            // });
            // ga('send', 'pageview', path);
            ga.getAll().forEach(a => a.send('pageview', path));
        }
    }

    eventTrack(action: string, properties: any): void {
        // dataLayer.push({
        //         'service': 'pageview',
        //         'url': path
        //     });

        // dataLayer.push(Object.assign({ event: properties.event || 'interaction', target: properties.category || 'Event', action: action, label: properties.label, value: properties.value, interactionType: properties.noninteraction }, properties.gtmCustom));
    }

    trackService(serviceName: string) {
        // window.dataLayer.push({'formLocation': 'footer', 'event': 'selectService'});
    }

    exceptionTrack(properties: any): void {
    }

    setUsername(userId: string): void {
    }


}
