import { OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Config } from '../../../../config';
import { ListMode, ModalEvent, Pageable } from '../../../core/models';
import { ActivityService, AddressService, BrowserService, RouteService, SeoService } from '../../../core/services';
import { CommonUtils, ErrorUtils } from '../../../core/utils';
import * as fromRoot from '../../../store';
import * as searchActions from '../../store/actions/user.search';
import { UserParamUtils } from '../../utils';
import { UsersSearchHelper } from './helper/users-search.helper';
var UsersSearchComponent = /** @class */ (function () {
    function UsersSearchComponent(store, userSearchHelper, route, router, addressService, activityService, routeService, translateService, seo, browserService, vcRef) {
        this.store = store;
        this.userSearchHelper = userSearchHelper;
        this.route = route;
        this.router = router;
        this.addressService = addressService;
        this.activityService = activityService;
        this.routeService = routeService;
        this.translateService = translateService;
        this.seo = seo;
        this.browserService = browserService;
        this.vcRef = vcRef;
        this.subscription = new Subscription();
        this.listMode = ListMode.LIST;
        this.ListMode = ListMode;
        this.filterModalEventListener$ = new BehaviorSubject(ModalEvent.CLOSE);
        this._loading = true;
        this.page$ = this.store.pipe(select(fromRoot.getSearchResultPage));
        this.loading$ = this.store.pipe(select(fromRoot.isSearchLoading));
        this.notification$ = this.store.pipe(select(fromRoot.getSearchErrors), map(ErrorUtils.toNotification));
        this.seo.setRobots(true);
        this.pageable = userSearchHelper.initPageable();
    }
    UsersSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSearchHelper.clearRoute();
        this.userSearchHelper.initWorkers$();
        this.route.queryParams.subscribe(function (params) {
            _this.initSearchCriteria(params);
            _this.load();
        });
        var pageSub = this.page$.pipe(filter(function (p) { return CommonUtils.notEmpty(p); }))
            .subscribe(function (page) {
            _this.initPage(page);
            if (Config.IS_MOBILE_NATIVE()) {
                _this.userSearchHelper.pushPage(page);
                _this._loading = false;
            }
        });
        var notifSub = this.notification$.pipe(filter(CommonUtils.notEmpty))
            .subscribe(function () {
            _this._loading = false;
        });
        this.subscription.add(pageSub);
        this.subscription.add(notifSub);
        this.subscription.add(this.filterModalEventListener$);
    };
    UsersSearchComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
        this.store.dispatch(new searchActions.ResetAction());
    };
    UsersSearchComponent.prototype.initSearchCriteria = function (params) {
        var _this = this;
        this.searchCriteria = UserParamUtils.paramsToSearchCriteria(params);
        this.pageable = CommonUtils.fillPageable(params, this.pageable);
        if (CommonUtils.notEmpty(this.searchCriteria)) {
            this.userSearchHelper.initWorkers$();
            this.userSearchHelper.setLoadOnDemandeToAuto();
            if (this.searchCriteria.activity) {
                this.activityService.getActivityById(this.searchCriteria.activity.id).subscribe(function (ac) { return _this.searchCriteria.activity = ac; });
            }
        }
    };
    Object.defineProperty(UsersSearchComponent.prototype, "dataItems", {
        get: function () {
            return this.userSearchHelper.dataItems;
        },
        enumerable: true,
        configurable: true
    });
    UsersSearchComponent.prototype.loadMoreData = function (args) {
        if (!this.currentPage.last) {
            this.pageable.page++;
            this.load();
        }
        else {
            this.userSearchHelper.lastPageLoaded(args);
        }
        this.userSearchHelper.setArgsReturnValue();
    };
    UsersSearchComponent.prototype.showServiceDialog = function () {
        var _this = this;
        this.userSearchHelper.showServiceDialog(this.vcRef).subscribe(function (callback) {
            if (callback && callback.isClose()) {
                return;
            }
            _this.searchCriteria = callback.payload;
            _this.pageable = new Pageable();
            _this._loading = true;
            _this.userSearchHelper.initWorkers$();
            _this.userSearchHelper.setLoadOnDemandeToAuto();
            if (_this.searchCriteria.activity) {
                _this.activityService.getActivityById(_this.searchCriteria.activity.id).subscribe(function (ac) { return _this.searchCriteria.activity = ac; });
            }
            _this.load();
        });
    };
    UsersSearchComponent.prototype.initPage = function (page) {
        this.currentPage = page;
        this.totalElements = page.totalElements;
        this.titleKey = this.getTitleKey();
        if (Config.IS_WEB()) {
            this.setSeo(this.totalElements);
            this.setLatLngBounds();
            this.hasResult = this.currentPage.content ? this.currentPage.content.length > 0 : false;
        }
    };
    UsersSearchComponent.prototype.load = function () {
        if (this.searchCriteria) {
            this.store.dispatch(new searchActions.SearchAction({
                searchCriteria: this.searchCriteria,
                pageable: this.pageable
            }));
        }
        else {
            this.store.dispatch(new searchActions.SearchAroundIPOrLastAction(this.pageable));
        }
    };
    UsersSearchComponent.prototype.navigateToWorker = function (userUid) {
        this.routeService.toWorker(userUid);
    };
    UsersSearchComponent.prototype.search = function (searchCriteria) {
        if (this.filterModalEventListener$.getValue() === ModalEvent.OPEN) {
            this.filterModalEventListener$.next(ModalEvent.CLOSE);
        }
        this.navigate(searchCriteria);
    };
    UsersSearchComponent.prototype.loadPage = function (pageNumber) {
        this.pageable.page = pageNumber;
        this.navigate(this.searchCriteria, this.pageable);
    };
    UsersSearchComponent.prototype.navigate = function (criteria, pageable) {
        var navigationExtras = {
            queryParams: UserParamUtils.toParams(criteria, pageable),
            relativeTo: this.route
        };
        this.router.navigate([], navigationExtras);
    };
    UsersSearchComponent.prototype.selectListMode = function (mode) {
        this.listMode = mode;
    };
    UsersSearchComponent.prototype.doOpenFilterModal = function () {
        this.filterModalEventListener$.next(ModalEvent.OPEN);
    };
    UsersSearchComponent.prototype.doCloseFilterModal = function () {
        this.filterModalEventListener$.next(ModalEvent.CLOSE);
    };
    UsersSearchComponent.prototype.setLatLngBounds = function () {
        var _this = this;
        if (!Config.IS_WEB() && !this.browserService.isPlatformBrowser()) {
            return;
        }
        if (!this.currentPage || !this.currentPage.content) {
            return;
        }
        var locations = this.currentPage.content
            .filter(function (user) { return CommonUtils.notEmpty(user.address); })
            .map(function (user) { return user.address.location; });
        this.addressService.calculateLatLngBounds(locations).subscribe(function (latLng) { return _this.latLngBounds = latLng; });
    };
    UsersSearchComponent.prototype.distanceText = function (distance) {
        return this.translateService.instant('global.in-distance-of', { distance: distance });
    };
    UsersSearchComponent.prototype.getTitleKey = function () {
        if (!this.currentPage) {
            return '';
        }
        if (!this.searchCriteria) {
            return 'users-search.last-title';
        }
        return this.currentPage.totalElements === 1 ? 'users-search.around-title' : 'users-search.around-title-plural';
    };
    UsersSearchComponent.prototype.getFullName = function (user) {
        return user.firstName + ' ' + user.lastName;
    };
    UsersSearchComponent.prototype.getUserReviews = function (user) {
        if (!user || !user.reviewsAverage) {
            return 0;
        }
        return user.reviewsAverage + 1;
    };
    // TODO add open graph tags
    UsersSearchComponent.prototype.setSeo = function (resultCount) {
        if (this.searchCriteria && this.searchCriteria.activity && this.searchCriteria.address) {
            this.seo.setMeta('users-search-criteria', {
                count: resultCount,
                activity: this.searchCriteria.activity.frName,
                address: this.searchCriteria.address.formatted
            });
        }
        else {
            this.seo.setMeta('users-search');
        }
    };
    Object.defineProperty(UsersSearchComponent.prototype, "loading", {
        get: function () {
            return this._loading;
        },
        enumerable: true,
        configurable: true
    });
    return UsersSearchComponent;
}());
export { UsersSearchComponent };
