export var PhoneModalActionTypes;
(function (PhoneModalActionTypes) {
    PhoneModalActionTypes["OpenPhoneModal"] = "[PHONE_MODAL] OPEN_PHONE_MODAL";
    PhoneModalActionTypes["ClosePhoneModal"] = "[PHONE_MODAL] CLOSE_PHONE_MODAL";
})(PhoneModalActionTypes || (PhoneModalActionTypes = {}));
var OpenPhoneModalAction = /** @class */ (function () {
    function OpenPhoneModalAction() {
        this.type = PhoneModalActionTypes.OpenPhoneModal;
    }
    return OpenPhoneModalAction;
}());
export { OpenPhoneModalAction };
var ClosePhoneModalAction = /** @class */ (function () {
    function ClosePhoneModalAction() {
        this.type = PhoneModalActionTypes.ClosePhoneModal;
    }
    return ClosePhoneModalAction;
}());
export { ClosePhoneModalAction };
