import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable, throwError as _throw} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Config} from '../../../config';
import {ErrorsDto, SessionDto, StorageKey, UserAccountDto, UserLoginFormDto, UserProfileDto} from '../models';
import {HttpUtil} from '../utils';

import {StorageService} from './storage.service';
import {ValidationService} from './validation.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private storageService: StorageService,
                private validationService: ValidationService,
                private httpClient: HttpClient) {
    }

    authenticate(userLoginForm: UserLoginFormDto): Observable<SessionDto> {
        let errorsDto: ErrorsDto = this.validationService.validateUserLoginForm(userLoginForm);

        if (errorsDto) {
            return _throw(errorsDto);
        }

        return this.httpClient.post<SessionDto>('api/v1/login', userLoginForm, {withCredentials: true}).pipe(
            tap(session => this.setSession(session))
        );
    }

    socialAuthentication(accessToken: string): Observable<SessionDto> {
        let options = HttpUtil.appendQueryParam({}, 'access_token', accessToken);

        return this.httpClient.get<SessionDto>('m/signup', options).pipe(
            tap(session => this.setSession(session))
        );
    }

    logout() {
        return this.httpClient.post('api/v1/logout', null);
        // this.clearSession();
    }

    countUnreadConversations(userUid: string): Observable<number> {
        return this.httpClient.get<number>(HttpUtil.buildAndExpand('api/v1/users/{userUid}/conversations/unread/count', userUid));
    }

    getCurrent(): Observable<UserAccountDto> {
        return this.httpClient.get<UserAccountDto>('api/v1/current');
    }

    getCurrentUserProfile(userUid: string): Observable<UserProfileDto> {
        return this.httpClient.get<UserProfileDto>(HttpUtil.buildAndExpand('api/v1/users/{userUid}/profile', userUid));
    }

    setSession(session: SessionDto) {
        this.storageService.setItem(StorageKey.TOKEN, session.token);
        this.storageService.setItem(StorageKey.USER, JSON.stringify(session.user));
        this.storageService.setItem(StorageKey.EXPIRES_AT, JSON.stringify(session.expirationDate));
    }

    updateUserAccount(userAccount: UserAccountDto) {
        this.storageService.setItem(StorageKey.USER, JSON.stringify(userAccount));
    }

    clearUser() {
        if (this.isMobileOrPlatformBrowser()) {
            this.storageService.removeItem(StorageKey.USER);
        }
    }

    clearSession() {
        if (this.isMobileOrPlatformBrowser()) {
            this.storageService.removeItem(StorageKey.TOKEN);
            this.storageService.removeItem(StorageKey.USER);
            this.storageService.removeItem(StorageKey.EXPIRES_AT);
        }
    }

    isAuthenticated(): boolean {
        let expiresAt = this.getExpiresAt();

        if (!expiresAt && !this.getToken()) {
            return false;
        }

        return Date.now() < expiresAt.getTime();
    }

    getUser(): UserAccountDto {
        if (this.isMobileOrPlatformBrowser()) {
            return JSON.parse(this.storageService.getItem(StorageKey.USER));
        }

        return null;
    }

    getToken(): string {
        if (this.isMobileOrPlatformBrowser()) {
            return this.storageService.getItem(StorageKey.TOKEN);
        }

        return null;
    }

    setToken(token: string) {
        this.storageService.setItem(StorageKey.TOKEN, token);
    }

    private getExpiresAt(): Date {
        if (this.isMobileOrPlatformBrowser()) {
            return new Date(JSON.parse(this.storageService.getItem(StorageKey.EXPIRES_AT)));
        }

        return null;
    }

    private isMobileOrPlatformBrowser() {
        return Config.IS_MOBILE_NATIVE() || (Config.IS_WEB() && isPlatformBrowser(this.platformId));
    }
}
