import {Inject, Injectable, Optional} from '@angular/core';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {TranslateService} from '@ngx-translate/core';
import {ProfileDto, RequestDto, UserViewDto} from '../models';
import {ContextUtils, HttpUtil} from '../utils';

@Injectable({
    providedIn: 'root'
})
export class BcUtilsService {

    constructor(@Optional() @Inject(REQUEST) protected request: any,
                private translateService: TranslateService) {
    }

    getImageFullPath(picture: string) {
        return this.getOrigin() + HttpUtil.buildAndExpand('/medias/{media}', picture);
    }

    getProfileTitle(profile: ProfileDto): string {
        return this.translateService.instant('share.profile.title', {profile: profile});
    }

    getRequestTitle(request: RequestDto): string {
        return this.translateService.instant('share.request.title', {request: request});
    }

    getProfileUrl(profile: ProfileDto | UserViewDto): string {
        return this.getOrigin() + HttpUtil.buildAndExpand('/workers/{uid}/profile', profile.uid);
    }

    getRequestUrl(request: RequestDto): string {
        return this.getOrigin() + HttpUtil.buildAndExpand('/requests/{id}', request.id);
    }

    getOrigin() {
        if (this.request) {
            return `${this.getRequestProtocol()}://${this.request.get('host')}/`;
        }

        return ContextUtils.getOrigin();
    }

    private getRequestProtocol() {
        let protocol = 'https';
        // if (this.request && this.request.headers) {
        //     protocol = this.request.protocol;
        //
        //     let proto = this.request.headers['X-Forwarded-Proto'];
        //
        //     if (proto) {
        //         protocol = proto;
        //     }
        // }

        return protocol;
    }
}
