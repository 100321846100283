import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';

import {Notification, UserLoginFormDto} from '../../../../core/models';

@Component({
    moduleId: module.id,
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {

    @Input() notification: Notification;
    @Input() loading$: Observable<boolean>;
    @Input() initListener$: Observable<any>;

    @Output() login = new EventEmitter<UserLoginFormDto>();

    userLogin = new UserLoginFormDto();

    constructor() {
    }

    ngOnInit() {

        this.initListener$.subscribe(() => {
            this.userLogin.login = null;
            this.userLogin.password = null;
        });

    }

    submit(form: any) {
        if (!form.valid) {
            return
        }

        this.login.emit(this.userLogin);
    }

}
