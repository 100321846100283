export class Notice {
    id: number;
    priority: number;
    icon: string;
    type: NoticeType;

    static buildProfileIncomplete(): Notice {
        return Notice.buildNotif('fa-address-card', NoticeType.PROFILE, 2);
    }

    static buildNewMessage(): Notice {
        return Notice.buildNotif('fa-envelope', NoticeType.MESSAGE, 1);
    }

    static buildProfileNotVerified(): Notice {
        return Notice.buildNotif('fa-check-circle', NoticeType.VERIFICATIONS, 3);
    }

    private static buildNotif(icon: string, type: NoticeType, priority: number): Notice {
        let notif = new Notice();
        notif.icon = icon;
        notif.type = type;
        notif.priority = priority;

        return notif;
    }
}

export type NoticeType = 'MESSAGE' | 'VERIFICATIONS' | 'PROFILE';

export const NoticeType = {
    MESSAGE: <NoticeType>'MESSAGE',
    VERIFICATIONS: <NoticeType>'VERIFICATIONS',
    PROFILE: <NoticeType>'PROFILE',
};
