import { OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeviceService } from '../../../../core/services';
import { phoneModalActions } from '../../../../core/store/actions';
import * as fromRoot from '../../../../store';
var ShowPhoneModalComponent = /** @class */ (function () {
    function ShowPhoneModalComponent(store, deviceService) {
        this.store = store;
        this.deviceService = deviceService;
        this.isMobileDevice = false;
        this.phone$ = this.store.pipe(select(fromRoot.getUserProfilePhone));
        this.loading$ = this.store.pipe(select(fromRoot.isPhoneLoading));
        this.errors$ = this.store.pipe(select(fromRoot.getPhoneErrors));
        this.modalEventListener$ = this.store.pipe(select(fromRoot.getPhoneModalEvent));
    }
    ShowPhoneModalComponent.prototype.ngOnInit = function () {
        this.isMobileDevice = this.deviceService.isMobileDevice();
    };
    ShowPhoneModalComponent.prototype.dispatchModalClose = function () {
        this.store.dispatch(new phoneModalActions.ClosePhoneModalAction());
    };
    return ShowPhoneModalComponent;
}());
export { ShowPhoneModalComponent };
