<ins #ins class="adsbygoogle"
     [ngStyle]="{'display': display, 'width.px': width, 'height.px': height }"
     [attr.data-ad-client]="adClient"
     [attr.data-ad-slot]="adSlot"
     [attr.data-ad-format]="adFormat"
     [attr.data-ad-region]="adRegion"
     [attr.data-layout]="layout"
     [attr.data-adtest]="adtest"
     [attr.data-full-width-responsive]="fullWidthResponsive"
     [attr.data-layout-key]="layoutKey">
</ins>
