import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {

    transform(value: any, num: number): any {

        if (!value) {
            return null;
        }

        if (num !== 1) {
            value = value.concat('s');
        }

        return value;
    }

}
