import {Action} from '@ngrx/store';
import {ErrorsDto, QuotationDto} from '../../../core/models';

export enum QuotationEditActionTypes {
    Save = '[QuotationEdit] Save',
    SaveSuccess = '[QuotationEdit] SaveSuccess',
    SaveFail = '[QuotationEdit] SaveFail',
    Delete = '[QuotationEdit] Delete',
    DeleteSuccess = '[QuotationEdit] DeleteSuccess',
    DeleteFail = '[QuotationEdit] DeleteFail',
}

export class Save implements Action {
    readonly type = QuotationEditActionTypes.Save;

    constructor(public payload: QuotationDto) {
    }
}

export class SaveSuccess implements Action {
    readonly type = QuotationEditActionTypes.SaveSuccess;

    constructor(public payload: number) {
    }
}

export class SaveFail implements Action {
    readonly type = QuotationEditActionTypes.SaveFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class Delete implements Action {
    readonly type = QuotationEditActionTypes.Delete;

    constructor(public payload: QuotationDto) {
    }
}

export class DeleteSuccess implements Action {
    readonly type = QuotationEditActionTypes.DeleteSuccess;
}

export class DeleteFail implements Action {
    readonly type = QuotationEditActionTypes.DeleteFail;

    constructor(public payload: ErrorsDto) {
    }
}

export type Actions = Save
    | SaveSuccess
    | SaveFail
    | Delete
    | DeleteSuccess
    | DeleteFail
    ;
