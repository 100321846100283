import { NotificationType } from './notification-type';
var Notification = /** @class */ (function () {
    function Notification() {
        this.messages = [];
    }
    Notification.buildSuccessNotification = function (title) {
        if (title === void 0) { title = ''; }
        return Notification.buildNotification(NotificationType.SUCCESS, title);
    };
    Notification.buildInfoNotification = function (title) {
        if (title === void 0) { title = ''; }
        return Notification.buildNotification(NotificationType.INFO, title);
    };
    Notification.buildErrorNotification = function (title) {
        if (title === void 0) { title = ''; }
        return Notification.buildNotification(NotificationType.ERROR, title);
    };
    Notification.buildNotification = function (type, title) {
        if (title === void 0) { title = ''; }
        var notif = new Notification();
        notif.type = type;
        notif.title = title;
        return notif;
    };
    Notification.prototype.addMessage = function (message) {
        this.messages.push(message);
    };
    return Notification;
}());
export { Notification };
