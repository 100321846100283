import {ErrorsDto, PromotionResultDto, UserResultDto} from '../../../core/models';
import {workersPromotionActions} from '../actions';

export interface State {
    result: PromotionResultDto<UserResultDto>,
    loading: boolean,
    errors: ErrorsDto,
    loadingDate: Date
}

export const initialState: State = {
    result: null,
    loading: false,
    errors: null,
    loadingDate: null
};

export function reducer(state = initialState, action: workersPromotionActions.WorkersPromotionActions): State {

    switch (action.type) {

        case workersPromotionActions.WorkersPromotionActionTypes.LoadPromotionWorkers: {
            return Object.assign(
                {},
                initialState,
                {
                    loading: true,
                    errors: null,
                    loadingDate: null
                }
            )
        }

        case workersPromotionActions.WorkersPromotionActionTypes.LoadPromotionWorkersSuccess: {
            return Object.assign(
                {},
                initialState,
                {
                    loading: false,
                    result: action.payload,
                    loadingDate: new Date()
                }
            )
        }

        case workersPromotionActions.WorkersPromotionActionTypes.LoadPromotionWorkersFail: {
            return Object.assign(
                {},
                initialState,
                {
                    loading: false,
                    errors: action.payload
                }
            )
        }

        default: {
            return state;
        }
    }
}

export const isLoading = (state: State): boolean => state.loading;
export const getErrors = (state: State): ErrorsDto => state.errors;
export const getResult = (state: State): PromotionResultDto<UserResultDto> => state.result;
export const getLoadingDate = (state: State): Date => state.loadingDate;
