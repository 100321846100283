import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';

import {ErrorsDto} from '../../models';
import {NotificationService, ReviewService} from '../../services';
import * as fromRoot from '../../../store';
import {reportReviewActions, reviewActions} from '../actions';

@Injectable()
export class ReviewEffects {

    @Effect()
    public reportReview = this.actions$.pipe(
        ofType(reportReviewActions.ReviewReportActionTypes.ReportReview),
        map((action: reportReviewActions.ReportReviewAction) => action.payload),
        mergeMap(reviewReport =>
            this.reviewService.reportReview(reviewReport).pipe(
                map(() => {
                    this.notificationService.displaySuccessKey('reports.thanks');

                    return new reportReviewActions.ReportReviewSuccessAction()
                }),
                catchError((errors: ErrorsDto) => of(new reportReviewActions.ReportReviewFailAction(errors)))
            )
        )
    );

    @Effect()
    public addReview = this.actions$.pipe(
        ofType(reviewActions.ReviewActionTypes.AddReview),
        map((action: reviewActions.AddReviewAction) => action.payload),
        withLatestFrom(this.store$.select(fromRoot.getCurrentUser)),
        mergeMap(([addReviewDto, currentUser]) =>
            this.reviewService.addReview(currentUser.uid, addReviewDto).pipe(
                map(() => new reviewActions.AddReviewSuccessAction()),
                catchError(errors => of(new reviewActions.AddReviewFailAction(errors)))
            )
        )
    );

    @Effect()
    public loadUserReviews = this.actions$.pipe(
        ofType(reviewActions.ReviewActionTypes.LoadUserReviews),
        map((action: reviewActions.LoadUserReviewsAction) => action.payload),
        mergeMap(userUid =>
            this.reviewService.getReviews(userUid).pipe(
                map(displayReviews => new reviewActions.LoadUserReviewsSuccessAction(displayReviews)),
                catchError(errors => of(new reviewActions.LoadUserReviewsFailAction(errors)))
            )
        )
    );

    constructor(private actions$: Actions,
                private store$: Store<fromRoot.State>,
                private reviewService: ReviewService,
                private notificationService: NotificationService) {
    }

}
