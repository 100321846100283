<div class="navbar-fixed" yfLayoutClass>
    <nav class="white main-navbar">
        <div class="nav-wrapper container">
            <a routerLink="/" class="brand-logo">
                <img src="assets/images/logo.png" width="60" height="60" class="nav-logo hidden-sm-down" alt="logo"/>BriCool
            </a>
            <div class="right">
                <ul class="hidden-md-down">
                    <li class="mr-5">
                        <button class="btn basic-btn" data-intro="Hello" routerLink="/requests/new">
                            <span translate>global.new-req</span>
                        </button>
                    </li>
                    <li>
                        <a [routerLink]="['requests']" routerLinkActive="active" translate>nav-bar.requests</a>
                    </li>
                    <li>
                        <a routerLink="/workers" routerLinkActive="active" translate>nav-bar.workers</a>
                    </li>
                    <li *ngIf="!(authenticated$ | async)">
                        <a routerLink="/register" routerLinkActive="active" translate>nav-bar.sign-up</a>
                    </li>
                    <li *ngIf="!(authenticated$ | async)">
                        <a routerLink="/login" routerLinkActive="active" translate>nav-bar.sign-in</a>
                    </li>
                    <li>
                        <a materialize="dropdown" class='dropdown-button' data-constrainWidth="false"
                           data-belowOrigin="true" data-activates='navbar-notices'>
                            <!--<i class="material-icons">notices</i>-->
                            <i class="fa fa-bell-o" aria-hidden="true" style="font-size: 1.5rem; position: relative">
                                <div class="navbar-notices-count" *ngIf="notices?.length as noticesCount">
                                    <span>{{noticesCount}}</span>
                                </div>
                            </i>

                        </a>

                        <ul class="dropdown-content" id="navbar-notices">
                            <li class="notices-body">
                                <div class="notif-dialog-header">
                                    <span class="ndh-text" translate>global.notices</span>
                                    <div class="ndh-txt-icon">
                                        <i class="material-icons">clear</i>
                                    </div>
                                </div>
                                <app-notice-list [notices]="notices"></app-notice-list>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="authenticated$ | async">
                        <div class="navbar-profile">
                            <a materialize="dropdown" class='dropdown-button' data-constrainWidth="false"
                               data-belowOrigin="true" data-activates='navbar-user-info'>
                                <img [appUserImg]="currentUser$ | async"
                                     class="img-thumbnail img-sm circle"
                                     width="50"
                                     height="50"
                                     [alt]="'nav-bar.drop-down.img-alt' | translate">
                                <!--<div class="navbar-notif-count" *ngIf="unreadMessagesCount">-->
                                <!--<span>{{unreadMessagesCount}}</span>-->
                                <!--</div>-->
                            </a>

                        </div>

                        <ul class="dropdown-content" id="navbar-user-info">
                            <li>
                                <a routerLink="/user/profile" translate>
                                    <i class="material-icons">account_circle</i>nav-bar.drop-down.account
                                </a>
                            </li>
                            <li>
                                <a routerLink="/user/profile/services" translate>
                                    <i class="material-icons">work</i>account.menu.services
                                </a>
                            </li>
                            <li>
                                <a routerLink="/user/conversations" translate>
                                    <i class="material-icons">mail</i>nav-bar.drop-down.messages <span class="chip"
                                                                                                       *ngIf="unreadMessagesCount">{{unreadMessagesCount}}</span>
                                </a>
                            </li>

                            <li>
                                <a routerLink="/user/requests" translate>
                                    <i class="material-icons">description</i>nav-bar.drop-down.requests
                                </a>
                            </li>

                            <li>
                                <a routerLink="/user/quotations" translate>
                                    <i class="material-icons">description</i>nav-bar.drop-down.quotations
                                </a>
                            </li>

                            <li>
                                <a routerLink="/user/reviews" translate>
                                    <i class="material-icons">rate_review</i>nav-bar.drop-down.reviews
                                </a>
                            </li>

                            <li *ngIf="UserAccountUtils.isUserAccountManager(currentUser$ | async)">
                                <a routerLink="/admin" translate>
                                    <i class="material-icons">settings</i>nav-bar.drop-down.admin
                                </a>
                            </li>

                            <li class="divider"></li>
                            <li>
                                <a (click)="logout()" translate>nav-bar.drop-down.logout</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="hidden-lg-up right">
                <li>
                    <a materialize="dropdown" class='dropdown-button' data-constrainWidth="false"
                       data-belowOrigin="true" data-activates='navbar-sm-notices'>
                        <!--<i class="material-icons">notices</i>-->
                        <i class="fa fa-bell-o" aria-hidden="true" style="font-size: 1.5rem; position: relative">
                            <div class="navbar-notices-count" *ngIf="notices?.length as noticesCount">
                                <span>{{noticesCount}}</span>
                            </div>
                        </i>

                    </a>

                    <ul class="dropdown-content" id="navbar-sm-notices">
                        <li class="notices-body">
                            <div class="notif-dialog-header">
                                <span class="ndh-text" translate>global.notices</span>
                                <div class="ndh-txt-icon">
                                    <i class="material-icons">clear</i>
                                </div>
                            </div>
                            <app-notice-list [notices]="notices"></app-notice-list>
                        </li>
                    </ul>
                </li>
            </div>
            <a href="#" data-activates="slide-out" class="button-collapse" materialize="sideNav"
               [materializeParams]="[{edge: 'left', closeOnClick: true, draggable: true}]"><i class="material-icons">menu</i>
            </a>

        </div>
    </nav>
</div>

<ul id="slide-out" class="side-nav">

    <li *ngIf="authenticated$ | async">
        <div class="user-view">
            <div class="background">
                <img src="assets/images/office.jpg">
            </div>
            <img [appUserImg]="currentUser$ | async"
                 class="responsive-img img-thumbnail circle"
                 width="50"
                 height="50"
                 [alt]="'nav-bar.drop-down.img-alt' | translate">
            <span class="white-text name">{{(currentUser$ | async)?.firstName}} {{(currentUser$ | async)?.lastName}}</span>
            <span class="white-text email">{{(currentUser$ | async)?.email}}</span>
        </div>
    </li>

    <div *ngIf="authenticated$ | async">
        <li>
            <a routerLink="/user/profile" translate>
                <i class="material-icons">account_circle</i>nav-bar.drop-down.account
            </a>
        </li>

        <li>
            <a routerLink="/user/profile/services" translate>
                <i class="material-icons">work</i>account.menu.services
            </a>
        </li>

        <li>
            <a routerLink="/user/conversations" translate>
                <i class="material-icons">mail</i>nav-bar.drop-down.messages <span class="chip"
                                                                                   *ngIf="unreadMessagesCount">{{unreadMessagesCount}}</span>
            </a>
        </li>

        <li>
            <a routerLink="/user/requests" translate>
                <i class="material-icons">description</i>nav-bar.drop-down.requests
            </a>
        </li>

        <li>
            <a routerLink="/user/reviews" translate>
                <i class="material-icons">rate_review</i>nav-bar.drop-down.reviews
            </a>
        </li>

        <li *ngIf="UserAccountUtils.isUserAccountManager(currentUser$ | async)">
            <a routerLink="/admin" translate>
                <i class="material-icons">settings</i>nav-bar.drop-down.admin
            </a>
        </li>

        <div class="divider"></div>

    </div>

    <li>
        <a routerLink="/" routerLinkActive="active" class="waves-effect" translate>
            <i class="material-icons">home</i>nav-bar.home
        </a>
    </li>

    <li>
        <a routerLink="/requests/new" routerLinkActive="active" class="waves-effect" translate>
            <i class="material-icons">description</i>global.new-req
        </a>
    </li>

    <li>
        <a routerLink="/workers" routerLinkActive="active" class="waves-effect" translate>
            <i class="material-icons">search</i>nav-bar.workers
        </a>
    </li>

    <li>
        <a routerLink="/requests" routerLinkActive="active" class="waves-effect" translate>
            <i class="material-icons">search</i>nav-bar.requests
        </a>
    </li>

    <div class="divider"></div>

    <li *ngIf="!(authenticated$ | async)">
        <a routerLink="/register" routerLinkActive="active" translate>nav-bar.sign-up</a>
    </li>

    <li *ngIf="!(authenticated$ | async)">
        <a routerLink="/login" routerLinkActive="active" translate>nav-bar.sign-in</a>
    </li>

    <li *ngIf="authenticated$ | async">
        <a (click)="logout()" translate>nav-bar.drop-down.logout</a>
    </li>

</ul>
