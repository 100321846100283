var ToastImpl = /** @class */ (function () {
    function ToastImpl() {
        this.type = ToastType.INFO;
        this.duration = 4000;
    }
    return ToastImpl;
}());
export { ToastImpl };
export var ToastType;
(function (ToastType) {
    ToastType[ToastType["INFO"] = 0] = "INFO";
    ToastType[ToastType["SUCCESS"] = 1] = "SUCCESS";
    ToastType[ToastType["ERROR"] = 2] = "ERROR";
    ToastType[ToastType["WARNING"] = 3] = "WARNING";
})(ToastType || (ToastType = {}));
