export interface Chip {
    id: any;
    tag: string;
    value: string;
}

export class ChipImpl implements Chip {
    id: any;
    tag: string;
    value: string;
}

