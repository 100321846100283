<div class="lang-selector">
  <a materialize="dropdown" class='dropdown-button' data-constrainWidth="false"
     data-belowOrigin="true" data-activates='locales-dd'>
    <img [src]="'assets/images/flag/' + currentLocale?.icon" class="img-lang circle" alt="Langue">
  </a>
</div>

<ul class="dropdown-content" id="locales-dd">
  <li *ngFor="let loc of locales" class="p-2"  (click)="selectLocale(loc)">
    <img [src]="'assets/images/flag/' + loc.icon" class="img-lang circle d-inline-block">
    <span class="ml-1  d-inline-block">{{loc.name}}</span>
  </li>
</ul>