import {Action} from '@ngrx/store';
import {DisplayReviewDto, Notification} from '../../../core/models';

export enum AccountReviewsActionTypes {
    LoadUserReviews = '[ACCOUNT] LOAD_USER_REVIEWS',
    LoadUserReviewsSuccess = '[ACCOUNT] LOAD_USER_REVIEWS_SUCCESS',
    LoadUserReviewsFail = '[ACCOUNT] LOAD_USER_REVIEWS_FAIL',

    LoadUserSentReviews = '[ACCOUNT] LOAD_USER_SENT_REVIEWS',
    LoadUserSentReviewsSuccess = '[ACCOUNT] LOAD_USER_SENT_REVIEWS_SUCCESS',
    LoadUserSentReviewsFail = '[ACCOUNT] LOAD_USER_SENT_REVIEWS_FAIL',

    Reset = '[ACCOUNT] RESET'
}


export class LoadUserReviewsAction implements Action {
    readonly type = AccountReviewsActionTypes.LoadUserReviews;

    constructor(public payload: string) {
    }
}

export class LoadUserReviewsSuccessAction implements Action {
    readonly type = AccountReviewsActionTypes.LoadUserReviewsSuccess;

    constructor(public payload: DisplayReviewDto[]) {
    }
}

export class LoadUserReviewsFailAction implements Action {
    readonly type = AccountReviewsActionTypes.LoadUserReviewsFail;

    constructor(public payload: Notification) {
    }
}

export class LoadUserSentReviewsAction implements Action {
    readonly type = AccountReviewsActionTypes.LoadUserSentReviews;

    constructor(public payload: string) {
    }
}

export class LoadUserSentReviewsSuccessAction implements Action {
    readonly type = AccountReviewsActionTypes.LoadUserSentReviewsSuccess;

    constructor(public payload: DisplayReviewDto[]) {
    }
}

export class LoadUserSentReviewsFailAction implements Action {
    readonly type = AccountReviewsActionTypes.LoadUserSentReviewsFail;

    constructor(public payload: Notification) {
    }
}

export class ResetAction implements Action {
    readonly type = AccountReviewsActionTypes.Reset;
}

export type Actions = LoadUserReviewsAction
    | LoadUserReviewsSuccessAction
    | LoadUserReviewsFailAction
    | ResetAction
    | LoadUserSentReviewsAction
    | LoadUserSentReviewsSuccessAction
    | LoadUserSentReviewsFailAction
    ;
