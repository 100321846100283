export var ShareActionsTypes;
(function (ShareActionsTypes) {
    ShareActionsTypes["Share"] = "[SHARE] Share";
    ShareActionsTypes["Clear"] = "[SHARE] Clear";
})(ShareActionsTypes || (ShareActionsTypes = {}));
var ShareAction = /** @class */ (function () {
    function ShareAction(payload) {
        this.payload = payload;
        this.type = ShareActionsTypes.Share;
    }
    return ShareAction;
}());
export { ShareAction };
var ClearAction = /** @class */ (function () {
    function ClearAction() {
        this.type = ShareActionsTypes.Clear;
    }
    return ClearAction;
}());
export { ClearAction };
