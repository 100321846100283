import {Action} from '@ngrx/store';
import {ErrorsDto, UserAccountDto, UserLoginFormDto, UserProfileDto} from '../../models';

export enum AuthenticationActionTypes {
    Authenticate = '[Authentication] AUTHENTICATE',
    AuthenticateSuccess = '[Authentication] AUTHENTICATE_SUCCESS',
    AuthenticateFailure = '[Authentication] AUTHENTICATE_FAILURE',
    AuthenticationReset = '[Authentication] AUTHENTICATION_RESET',

    LoadUserProfile = '[Authentication] LoadUserProfile',
    LoadUserProfileSuccess = '[Authentication] LoadUserProfileSuccess',
    LoadUserProfileFail = '[Authentication] LoadUserProfileFail',

    SetUser = '[Authentication] SET_USER',

    TestUser = '[Authentication] TEST_USER',
    TestSessionSuccess = '[Authentication] TEST_USER_SUCCESS',

    SocialAuthenticate = '[Authentication] SOCIAL_AUTHENTICATE',
    SocialAuthenticateSuccess = '[Authentication] SOCIAL_AUTHENTICATE_SUCCESS',
    SocialAuthenticateFailure = '[Authentication] SOCIAL_AUTHENTICATE_FAILURE',

    CountUnreadConversations = '[Authentication] COUNT_UNREAD_CONVERSATIONS',
    CountUnreadConversationsSuccess = '[Authentication] COUNT_UNREAD_CONVERSATIONS_SUCCESS',
    CountUnreadConversationsFail = '[Authentication] COUNT_UNREAD_CONVERSATIONS_FAIL',

    SetErrors = '[Authentication] SET_ERRORS',
    SetLoaded = '[Authentication] SetLoaded',

    Logout = '[Authentication] LOGOUT',

    SetPicture = '[Authentication] SET_PICTURE'
}

export class LoadUserProfileAction implements Action {
    readonly type = AuthenticationActionTypes.LoadUserProfile;
}

export class SetLoaded implements Action {
    readonly type = AuthenticationActionTypes.SetLoaded;
}

export class LoadUserProfileSuccessAction implements Action {
    readonly type = AuthenticationActionTypes.LoadUserProfileSuccess;

    constructor(public payload: UserProfileDto) {

    }
}

export class LoadUserProfileFailAction implements Action {
    readonly type = AuthenticationActionTypes.LoadUserProfileFail;

    constructor(public payload: ErrorsDto) {

    }
}

export class SetErrorsAction implements Action {
    readonly type = AuthenticationActionTypes.SetErrors;

    constructor(public payload: ErrorsDto) {
    }
}

export class AuthenticateAction implements Action {
    readonly type = AuthenticationActionTypes.Authenticate;

    constructor(public payload: UserLoginFormDto) {
    }
}

export class AuthenticateSuccessAction implements Action {
    readonly type = AuthenticationActionTypes.AuthenticateSuccess;

    constructor(public payload: UserAccountDto) {
    }
}

export class AuthenticateFailureAction implements Action {
    readonly type = AuthenticationActionTypes.AuthenticateFailure;

    constructor(public payload: ErrorsDto) {
    }
}

export class SocialAuthenticateAction implements Action {
    readonly type = AuthenticationActionTypes.SocialAuthenticate;

    constructor(public payload: string) {
    }
}

export class SocialAuthenticateSuccessAction implements Action {
    readonly type = AuthenticationActionTypes.SocialAuthenticateSuccess;

    constructor(public payload: UserAccountDto) {
    }
}

export class SocialAuthenticateFailureAction implements Action {
    readonly type = AuthenticationActionTypes.SocialAuthenticateFailure;

    constructor(public payload: ErrorsDto) {
    }
}

export class LogOutAction implements Action {
    readonly type = AuthenticationActionTypes.Logout;
}

export class SetUserAction implements Action {
    readonly type = AuthenticationActionTypes.SetUser;

    constructor(public payload: UserAccountDto) {
    }
}

export class TestUserAction implements Action {
    readonly type = AuthenticationActionTypes.TestUser;
}

export class TestUserSuccessAction implements Action {
    readonly type = AuthenticationActionTypes.TestSessionSuccess;

    constructor(public payload: UserAccountDto) {
    }
}

export class ResetAuthenticationAction implements Action {
    readonly type = AuthenticationActionTypes.AuthenticationReset;
}

export class SetPictureAction implements Action {
    readonly type = AuthenticationActionTypes.SetPicture;

    constructor(public payload: string) {
    }
}

/**
 * Count unread conversations
 */
export class CountUnreadConversationsAction implements Action {
    readonly type = AuthenticationActionTypes.CountUnreadConversations;
}

export class CountUnreadConversationsSuccessAction implements Action {
    readonly type = AuthenticationActionTypes.CountUnreadConversationsSuccess;

    constructor(public payload: number) {
    }
}

export class CountUnreadConversationsFailAction implements Action {
    readonly type = AuthenticationActionTypes.CountUnreadConversationsFail;

    constructor(public payload: ErrorsDto) {
    }
}

export type Actions = AuthenticateAction
    | AuthenticateSuccessAction
    | AuthenticateFailureAction
    | TestUserAction
    | TestUserSuccessAction
    | SetUserAction
    | LogOutAction
    | ResetAuthenticationAction
    | SetPictureAction
    | SocialAuthenticateAction
    | SocialAuthenticateSuccessAction
    | SocialAuthenticateFailureAction
    | SetErrorsAction
    | CountUnreadConversationsAction
    | CountUnreadConversationsSuccessAction
    | CountUnreadConversationsFailAction
    | LoadUserProfileAction
    | LoadUserProfileSuccessAction
    | LoadUserProfileFailAction
    | SetLoaded
    ;
