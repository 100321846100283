export var LocaleActionsTypes;
(function (LocaleActionsTypes) {
    LocaleActionsTypes["SetLocale"] = "[LOCALE] SET_LOCALE";
    LocaleActionsTypes["UpdateLocale"] = "[LOCALE] UPDATE_LOCALE";
    LocaleActionsTypes["TestLocale"] = "[LOCALE] TEST_LOCALE";
})(LocaleActionsTypes || (LocaleActionsTypes = {}));
var SetLocaleAction = /** @class */ (function () {
    function SetLocaleAction(payload) {
        this.payload = payload;
        this.type = LocaleActionsTypes.SetLocale;
    }
    return SetLocaleAction;
}());
export { SetLocaleAction };
var UpdateLocaleAction = /** @class */ (function () {
    function UpdateLocaleAction(payload) {
        this.payload = payload;
        this.type = LocaleActionsTypes.UpdateLocale;
    }
    return UpdateLocaleAction;
}());
export { UpdateLocaleAction };
var TestLocaleAction = /** @class */ (function () {
    function TestLocaleAction() {
        this.type = LocaleActionsTypes.TestLocale;
    }
    return TestLocaleAction;
}());
export { TestLocaleAction };
