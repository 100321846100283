<ul class="ngx-pagination" role="navigation" aria-label="Pagination">

    <li class="pagination-previous" [class.disabled]="page.first">
       <span *ngIf="page.first" translate>global.previous</span>
        <a (click)="selectPage.emit(page.number)" *ngIf="!page.first" translate>
            global.previous
        </a>
    </li>

    <li class="current">
        <div>
            <span>{{page.number + 1}}/{{page.totalPages}}</span>
        </div>
    </li>

    <li class="pagination-next" [class.disabled]="page.last">
        <a (click)="selectPage.emit(page.number + 2)" *ngIf="!page.last" translate>
            global.next
        </a>
        <span *ngIf="page.last" translate>global.next</span>
    </li>
</ul>