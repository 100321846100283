/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./review-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../review/review.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../review/review.component";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./review-list.component";
var styles_ReviewListComponent = [i0.styles];
var RenderType_ReviewListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReviewListComponent, data: {} });
export { RenderType_ReviewListComponent as RenderType_ReviewListComponent };
function View_ReviewListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-review", [], null, [[null, "deleteReview"], [null, "updateReview"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deleteReview" === en)) {
        var pd_0 = (_co.deleteReview.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("updateReview" === en)) {
        var pd_1 = (_co.updateReview.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ReviewComponent_0, i2.RenderType_ReviewComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "last-review": 0 }), i1.ɵdid(4, 49152, null, 0, i4.ReviewComponent, [], { review: [0, "review"], isOwner: [1, "isOwner"] }, { deleteReview: "deleteReview", updateReview: "updateReview" }), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.last); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = _co.UserAccountUtils.isSameUser(i1.ɵunv(_v, 4, 1, i1.ɵnov(_v, 5).transform(_co.currentSession$)), _v.context.$implicit.user); _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_ReviewListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "reviews"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewListComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reviews; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ReviewListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.emptyMessage; _ck(_v, 2, 0, currVal_1); }); }
export function View_ReviewListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReviewListComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noReview", 2]], null, 0, null, View_ReviewListComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.reviews == null) ? null : _co.reviews.length); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ReviewListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-review-list", [], null, null, null, View_ReviewListComponent_0, RenderType_ReviewListComponent)), i1.ɵdid(1, 49152, null, 0, i6.ReviewListComponent, [], null, null)], null, null); }
var ReviewListComponentNgFactory = i1.ɵccf("app-review-list", i6.ReviewListComponent, View_ReviewListComponent_Host_0, { reviews: "reviews", currentSession$: "currentSession$", emptyMessage: "emptyMessage", orientation: "orientation" }, { deleteReview: "deleteReview", updateReview: "updateReview" }, []);
export { ReviewListComponentNgFactory as ReviewListComponentNgFactory };
