import {Action} from '@ngrx/store';
import {ErrorsDto, Notification, PasswordResetActionDto, PasswordTokenValidationDto} from '../../models';

export enum PasswordResetActionTypes {
    ResetPassword = '[Password] RESET_PASSWORD',
    ResetPasswordSuccess = '[Password] RESET_PASSWORD_SUCCESS',
    ResetPasswordFail = '[Password] RESET_PASSWORD_FAIL',

    ResetPasswordValidateToken = '[Password] RESET_PASSWORD_VALIDATE_TOKEN',
    ResetPasswordValidateTokenSuccess = '[Password] RESET_PASSWORD_VALIDATE_TOKEN_SUCCESS',
    ResetPasswordValidateTokenFail = '[Password] RESET_PASSWORD_VALIDATE_TOKEN_FAIL',

    ResetState = '[Password] RESET_STATE'
}

/**
 * reset password
 */
export class ResetPasswordAction implements Action {
    readonly type = PasswordResetActionTypes.ResetPassword;

    constructor(public payload: PasswordResetActionDto) {
    }
}

export class ResetPasswordSuccessAction implements Action {
    readonly type = PasswordResetActionTypes.ResetPasswordSuccess;

    constructor(public payload: Notification) {
    }
}

export class ResetPasswordFailAction implements Action {
    readonly type = PasswordResetActionTypes.ResetPasswordFail;

    constructor(public payload: Notification) {
    }
}

/**
 * reset password token validation
 */
export class ValidateResetPasswordTokenAction implements Action {
    readonly type = PasswordResetActionTypes.ResetPasswordValidateToken;

    constructor(public payload: PasswordTokenValidationDto) {
    }
}

export class ValidateResetPasswordTokenSuccessAction implements Action {
    readonly type = PasswordResetActionTypes.ResetPasswordValidateTokenSuccess;
}

export class ValidateResetPasswordTokenFailAction implements Action {
    readonly type = PasswordResetActionTypes.ResetPasswordValidateTokenFail;

    constructor(public payload: ErrorsDto) {
    }
}

export class ResetStateAction implements Action {
    readonly type = PasswordResetActionTypes.ResetState;
}

export type Actions = ResetPasswordAction
    | ResetPasswordSuccessAction
    | ResetPasswordFailAction
    | ValidateResetPasswordTokenAction
    | ValidateResetPasswordTokenSuccessAction
    | ValidateResetPasswordTokenFailAction
    | ResetStateAction;
