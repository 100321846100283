import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import * as passwordRecoverActions from '../../modules/core/store/actions/password.recover.action';
import {Notification} from '../../modules/core/models/index';
import {BrowserService, ParamsService, SeoService} from '../../modules/core/services';
import {BcValidators} from '../../modules/core/validators';
import * as fromRoot from '../../modules/store';

@Component({
    moduleId: module.id,
    selector: 'app-password-forgotten',
    templateUrl: './password-forgotten.component.html',
    styleUrls: ['./password-forgotten.component.scss']
})
export class PasswordForgottenComponent implements OnInit, OnDestroy {

    email: FormControl;
    loading$: Observable<boolean>;
    success$: Observable<boolean>;
    notification$: Observable<Notification>;

    constructor(private store: Store<fromRoot.State>,
                private route: ActivatedRoute,
                private paramsService: ParamsService,
                private fb: FormBuilder,
                private browserService: BrowserService,
                private seo: SeoService) {
        this.loading$ = this.store.pipe(select(fromRoot.isPasswordRecoverLoading));
        this.success$ = this.store.pipe(select(fromRoot.isPasswordRecoverSuccess));
        this.notification$ = this.store.pipe(select(fromRoot.getPasswordRecoverNotification));

        this.email = fb.control('', Validators.compose([Validators.required, BcValidators.login]));

        seo.setMeta('password-forgotten');
        seo.setRobots(true);
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.email.setValue(this.paramsService.buildPasswordForgottenFormParams(params));
            this.browserService.materialize();
        });

        this.browserService.materialize();
    }

    ngOnDestroy() {
        this.store.dispatch(new passwordRecoverActions.ResetAction());
    }

    submit(form: FormGroup) {
        if (form.invalid) {
            return;
        }

        this.recover();
    }

    recover() {
        this.store.dispatch(new passwordRecoverActions.RecoverPasswordAction(this.email.value));
    }
}
