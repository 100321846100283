import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
// app
import {CORE_PIPES} from './pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [],
    schemas: [
        NO_ERRORS_SCHEMA,
        CUSTOM_ELEMENTS_SCHEMA,
    ],
    declarations: [
        ...CORE_PIPES,
    ],
    exports: [
        ...CORE_PIPES,
    ]
})
export class CoreModule {

    static forRoot(configuredProviders: Array<any>): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            providers: configuredProviders
        };
    }

}
