import { SeoService } from '../../../modules/core/services';
var CommentCaMarcheComponent = /** @class */ (function () {
    function CommentCaMarcheComponent(seo) {
        this.seo = seo;
        seo.setMeta('how-to');
        seo.setRobots(true);
    }
    return CommentCaMarcheComponent;
}());
export { CommentCaMarcheComponent };
