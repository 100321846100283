import { TranslateService } from '@ngx-translate/core';
import { Notification, ToastImpl, ToastType } from '../models';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var AbstractNotificationService = /** @class */ (function () {
    function AbstractNotificationService(translateService) {
        this.translateService = translateService;
    }
    AbstractNotificationService.prototype.displayKey = function (key, type) {
        var toast = new ToastImpl();
        toast.key = key;
        toast.type = type;
        this.displayToast(toast);
    };
    AbstractNotificationService.prototype.displayErrorKey = function (key) {
        this.displayKey(key, ToastType.ERROR);
    };
    AbstractNotificationService.prototype.displayInfoKey = function (key) {
        this.displayKey(key, ToastType.INFO);
    };
    AbstractNotificationService.prototype.displaySuccessKey = function (key) {
        this.displayKey(key, ToastType.SUCCESS);
    };
    AbstractNotificationService.prototype.displayMessage = function (message, type) {
        var toast = new ToastImpl();
        toast.message = message;
        toast.type = type;
        this.displayToast(toast);
    };
    AbstractNotificationService.prototype.displayInfoMessage = function (message) {
        this.displayMessage(message, ToastType.INFO);
    };
    AbstractNotificationService.prototype.displayWarningMessage = function (message) {
        this.displayMessage(message, ToastType.WARNING);
    };
    AbstractNotificationService.prototype.displaySuccessMessage = function (message) {
        this.displayMessage(message, ToastType.SUCCESS);
    };
    AbstractNotificationService.prototype.displayErrorMessage = function (message) {
        this.displayMessage(message, ToastType.ERROR);
    };
    AbstractNotificationService.prototype.buildGlobalErrorNotification = function () {
        return Notification.buildErrorNotification(this.translateService.instant('errors.global'));
    };
    AbstractNotificationService.prototype.buildValidationErrorNotification = function () {
        return Notification.buildErrorNotification(this.translateService.instant('errors.validation'));
    };
    AbstractNotificationService.prototype.buildPasswordChangeSuccessNotification = function () {
        var notification = Notification.buildSuccessNotification();
        notification.title = this.translateService.instant('global.password-change-success');
        notification.addMessage(this.translateService.instant('global.password-change-success-info'));
        return notification;
    };
    AbstractNotificationService.prototype.buildSocialAuthFailNotification = function () {
        var notification = Notification.buildErrorNotification();
        notification.title = this.translateService.instant('errors.account.social.fail');
        return notification;
    };
    AbstractNotificationService.prototype.buildReviewAddedSuccess = function () {
        return Notification.buildSuccessNotification(this.translateService.instant('reviews.add-successful'));
    };
    AbstractNotificationService.prototype.buildResetPasswordSuccessNotification = function () {
        return Notification.buildInfoNotification(this.translateService.instant('reset-password.email-sent'));
    };
    AbstractNotificationService.prototype.buildAccountActivationSuccessNotification = function () {
        return Notification.buildSuccessNotification(this.translateService.instant('account-activation.activated'));
    };
    AbstractNotificationService.prototype.buildEmailVerifiedSuccessNotification = function () {
        return Notification.buildSuccessNotification(this.translateService.instant('verifications.email.verified'));
    };
    AbstractNotificationService.prototype.buildPasswordResetSuccessNotification = function () {
        return Notification.buildSuccessNotification(this.translateService.instant('global.password-change-success-full'));
    };
    AbstractNotificationService.prototype.buildUserNotEligibleForSearchNotification = function () {
        return Notification.buildInfoNotification(this.translateService.instant('account.services.incomplet'));
    };
    AbstractNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AbstractNotificationService_Factory() { return new AbstractNotificationService(i0.ɵɵinject(i1.TranslateService)); }, token: AbstractNotificationService, providedIn: "root" });
    return AbstractNotificationService;
}());
export { AbstractNotificationService };
