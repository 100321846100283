import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import * as authenticationActions from '../../modules/core/store/actions/authentication.action';
import * as routeActions from '../../modules/core/store/actions/route.action';
import {Notification, RegistrationConfirmationParams} from '../../modules/core/models';
import {NotificationService, RouteService, SeoService} from '../../modules/core/services';
import {CommonUtils, ErrorUtils} from '../../modules/core/utils';
import {BcValidators} from '../../modules/core/validators';
import * as fromRoot from '../../modules/store';
import {SignInHelper} from './helper/sign-in.helper';

@Component({
    moduleId: module.id,
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    providers: [SignInHelper]
})
export class SignInComponent implements OnInit, OnDestroy {

    private subscription = new Subscription();

    userLogin: FormGroup;
    loading$: Observable<boolean>;
    notification$: Observable<Notification>;
    socialNotification: Notification;

    private authenticated$: Observable<boolean>;
    private redirect: boolean;

    constructor(protected store: Store<fromRoot.State>,
                private route: ActivatedRoute,
                private routeService: RouteService,
                private notificationService: NotificationService,
                private fb: FormBuilder,
                private seo: SeoService,
                private signInHelper: SignInHelper) {

        this.loading$ = this.store.pipe(select(fromRoot.isAuthLoading));
        this.authenticated$ = this.store.pipe(select(fromRoot.isAuthenticated));
        this.notification$ = this.store.pipe(
            select(fromRoot.getAuthErrors),
            map(ErrorUtils.toNotification)
        );

        this.userLogin = this.fb.group({
            login: ['', Validators.compose([Validators.required, BcValidators.login])],
            password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(25)])]
        });

        this.seo.setMeta('sign-in');
        this.seo.setRobots(true);
    }

    ngOnInit() {
        this.socialNotification = null;

        this.route.queryParams.pipe(filter(params => params.error))
            .subscribe(() => {
                setTimeout(() => this.socialNotification = this.notificationService.buildSocialAuthFailNotification(), 300);
            });

        let sub = this.notification$.pipe(filter(CommonUtils.notEmpty))
            .subscribe(notification => {
                this.alertNotification(notification);
            });
        this.subscription.add(sub);
    }

    ngOnDestroy() {
        this.routeService.redirectUrl = null;
        this.subscription.unsubscribe();
    }

    submit(form: FormGroup) {
        if (form.invalid) {
            this.notificationService.buildValidationErrorNotification();
            return;
        }

        this.redirect = true;
        this.store.dispatch(new authenticationActions.AuthenticateAction(this.userLogin.value));
    }

    toRegisterConfirm() {
        let data: RegistrationConfirmationParams = {
            email: this.userLogin.value.login,
            postSignUp: false
        };

        this.store.dispatch(new routeActions.ToConfirmRegistrationAction(data));
    }

    onLogin(eventData: any) {
        if (eventData.error) {
            this.alertNotification(this.notificationService.buildGlobalErrorNotification());
        } else {
            this.store.dispatch(new authenticationActions.SocialAuthenticateAction(eventData.loginResponse.token));
        }
    }

    alertNotification(notif: Notification) {
        this.signInHelper.alertNotification(notif);
    }
}
