/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-chooser.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../pipes/category-translator.pipe";
import * as i5 from "@ngrx/store";
import * as i6 from "../../pipes/activity-translator.pipe";
import * as i7 from "../../../core/services/activity-translator.service";
import * as i8 from "./service-chooser.component";
import * as i9 from "../../../core/services/device.service";
var styles_ActivityChooserComponent = [i0.styles];
var RenderType_ActivityChooserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivityChooserComponent, data: {} });
export { RenderType_ActivityChooserComponent as RenderType_ActivityChooserComponent };
function View_ActivityChooserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "text-underline"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["global.back"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ActivityChooserComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "text-underline"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["global.close"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ActivityChooserComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "category row align-items-center mb-1 mx-0 p-1"]], null, [[null, "mouseover"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.mouseOverCategory(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onSelectCategory(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "category row align-items-center mb-1 mx-0 p-1": 0, "selected": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "col pl-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-right right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "category row align-items-center mb-1 mx-0 p-1"; var currVal_1 = _ck(_v, 3, 0, true, _co.isCategorySelected(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit)); _ck(_v, 5, 0, currVal_2); }); }
function View_ActivityChooserComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "col-sm-12 col-md-6 yf-categories"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "yf-section-header mb-1"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["global.categories"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityChooserComponent_4)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.categories; _ck(_v, 6, 0, currVal_1); }, null); }
function View_ActivityChooserComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "yf-section-header mb-1"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["global.services"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ActivityChooserComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "activity mb-1 p-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectActivity(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "i", [["aria-hidden", "true"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, "fa mr-1", (_co.isActivitySelected(_v.context.$implicit) ? "fa-check-circle" : "fa-circle-thin")); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit)); _ck(_v, 6, 0, currVal_1); }); }
function View_ActivityChooserComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col-sm-12 col-md-6 yf-services"]], null, [[null, "mouseover"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseover" === en)) {
        var pd_0 = (_co.mouseOverActivities() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityChooserComponent_6)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityChooserComponent_7)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activities.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.activities; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ActivityChooserComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i4.CategoryTranslatorPipe, [i5.Store]), i1.ɵpid(0, i6.ActivityTranslatorPipe, [i7.ActivityTranslatorService]), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "activity-chooser"], ["yfLayoutClass", ""]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "row hidden-md-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "col-sm-12 center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [["class", "back-btn a-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doGoBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityChooserComponent_1)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityChooserComponent_2)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityChooserComponent_3)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityChooserComponent_5)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "activity-chooser"; var currVal_1 = _co.classes; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.showActivities; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.showCategories; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.showCategories; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.showActivities; _ck(_v, 16, 0, currVal_5); }, null); }
export function View_ActivityChooserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activity-chooser", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ActivityChooserComponent_0, RenderType_ActivityChooserComponent)), i1.ɵdid(1, 114688, null, 0, i8.ActivityChooserComponent, [i9.DeviceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivityChooserComponentNgFactory = i1.ɵccf("app-activity-chooser", i8.ActivityChooserComponent, View_ActivityChooserComponent_Host_0, { categories: "categories", activities: "activities", classes: "classes", selectedCategories: "selectedCategories", selectedActivities: "selectedActivities", initListener: "initListener" }, { selectActivity: "selectActivity", selectCategory: "selectCategory", quit: "quit" }, []);
export { ActivityChooserComponentNgFactory as ActivityChooserComponentNgFactory };
