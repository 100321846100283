import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DisplayReviewDto} from '../../../../core/models';

@Component({
    moduleId: module.id,
    selector: 'app-review',
    templateUrl: './review.component.html',
    styleUrls: ['./review.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewComponent {
    @Input() review: DisplayReviewDto;
    @Input() isOwner: boolean;
    @Input() size = 'sm';

    @Output() deleteReview = new EventEmitter<DisplayReviewDto>();
    @Output() updateReview = new EventEmitter<DisplayReviewDto>();

}
