/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./category-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/pipes/category-translator.pipe";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../shared/pipes/activity-translator.pipe";
import * as i6 from "../../../core/services/activity-translator.service";
import * as i7 from "./category-list.component";
var styles_CategoryListComponent = [i0.styles];
var RenderType_CategoryListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoryListComponent, data: {} });
export { RenderType_CategoryListComponent as RenderType_CategoryListComponent };
function View_CategoryListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check-circle mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit)); _ck(_v, 3, 0, currVal_0); }); }
function View_CategoryListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "category yf-section-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoryListComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colClass; _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.context.$implicit.activities; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit)); _ck(_v, 4, 0, currVal_1); }); }
export function View_CategoryListComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.CategoryTranslatorPipe, [i4.Store]), i1.ɵpid(0, i5.ActivityTranslatorPipe, [i6.ActivityTranslatorService]), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row categories mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoryListComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.categories; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_CategoryListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-category-list", [], null, null, null, View_CategoryListComponent_0, RenderType_CategoryListComponent)), i1.ɵdid(1, 49152, null, 0, i7.CategoryListComponent, [], null, null)], null, null); }
var CategoryListComponentNgFactory = i1.ɵccf("app-category-list", i7.CategoryListComponent, View_CategoryListComponent_Host_0, { categories: "categories", colClass: "colClass" }, {}, []);
export { CategoryListComponentNgFactory as CategoryListComponentNgFactory };
