import { ElementRef, OnInit } from '@angular/core';
import * as justifiedGallery from 'justifiedGallery/dist/js/jquery.justifiedGallery.js';
import { BrowserService } from '../../core/services';
// window.$.justifiedGallery = require('justifiedGallery/dist/js/jquery.justifiedGallery.js');
require('lightgallery');
require('lg-thumbnail');
var LightGalleryDirective = /** @class */ (function () {
    function LightGalleryDirective(el, browserService) {
        this.el = el;
        this.browserService = browserService;
    }
    LightGalleryDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (this.browserService.isPlatformBrowser()) {
            this.gallery = jQuery(this.el.nativeElement);
            window.$.justifiedGallery = justifiedGallery;
            jQuery(document).ready(function () { return _this.buildGallery(); });
        }
    };
    LightGalleryDirective.prototype.refresh = function () {
        if (!this.gallery) {
            return;
        }
        this.gallery.justifiedGallery('destroy');
        if (this.gallery.data('lightGallery')) {
            this.gallery.data('lightGallery').destroy(true);
        }
        this.buildGallery();
    };
    LightGalleryDirective.prototype.buildGallery = function () {
        this.gallery.justifiedGallery();
        this.gallery.lightGallery({
            thumbnail: true,
            selector: '.item'
        });
    };
    return LightGalleryDirective;
}());
export { LightGalleryDirective };
