import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    moduleId: module.id,
    selector: 'app-quick-search-links',
    templateUrl: './quick-search-links.component.html',
    styleUrls: ['./quick-search-links.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickSearchLinksComponent implements OnInit {

    casaLinksKeys: {title: string, link: string}[] = [];
    agadirLinksKeys: {title: string, link: string}[] = [];
    rabatLinksKeys: {title: string, link: string}[] = [];

    constructor(private translateService: TranslateService) {

    }

    ngOnInit(): void {
        this.casaLinksKeys = this.translateService.instant('quick-search-links.casablanca');
        this.agadirLinksKeys = this.translateService.instant('quick-search-links.agadir');
        this.rabatLinksKeys = this.translateService.instant('quick-search-links.rabat');
    }


}
