import { ModuleWithProviders } from '@angular/core';
import { ADSENSE_TOKEN } from './config/adsense.config';
var AdsenseModule = /** @class */ (function () {
    function AdsenseModule() {
    }
    AdsenseModule.forRoot = function (config) {
        if (config === void 0) { config = {}; }
        return {
            ngModule: AdsenseModule,
            providers: [{ provide: ADSENSE_TOKEN, useValue: config }],
        };
    };
    return AdsenseModule;
}());
export { AdsenseModule };
