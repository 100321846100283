import {Action} from '@ngrx/store';
import {Notice} from '../../models';

export enum NoticeActionTypes {
    AddNotices = '[Notice] AddNotices',
    RemoveNotice = '[Notice] RemoveNotice',

    Reset = '[Notice] Reset'
}

export class AddNoticesAction implements Action {
    readonly type = NoticeActionTypes.AddNotices;

    constructor(public payload: Notice[]) {
    }
}

export class RemoveNoticeAction implements Action {
    readonly type = NoticeActionTypes.RemoveNotice;

    constructor(public payload: Notice) {
    }
}

export class ResetAction implements Action {
    readonly type = NoticeActionTypes.Reset;
}

export type Actions = AddNoticesAction
    | RemoveNoticeAction
    | ResetAction
    ;
