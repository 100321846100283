<div id="index-banner" class="home-banner">
    <div class="container main-container">
        <div class="col-12 center">
            <h1 class="home-title mt-0" style="font-weight: 500" translate>home.title</h1>
            <h1 class="h4 text-white" translate>home.subtitle</h1>
        </div>

        <div class="col-12 home-search">
            <app-search-simple (search)="search($event)"></app-search-simple>
        </div>

        <!--<div class="col-12 center">-->
        <!--<h1 class="h4 text-white" translate>home.creq-title</h1>-->
        <!--</div>-->

        <!--<div class="col-12 center mt-3">-->
        <!--<button class="btn white-btn" routerLink="/requests/new" translate>home.creq-btn</button>-->
        <!--</div>-->

        <div class="col-md-12" style="margin-top: 6vh;">

            <div class="divider text-white"></div>

            <div class="row">

                <div class="col-12 center" style="margin-top: 6vh;">
                    <h1 class="home-title text-white h2 mt-0" translate>home.worker-title</h1>
                    <h1 class="h4 text-white" translate>home.worker-subtitle</h1>
                </div>

                <div class="col-12 center my-2">
                    <button class="btn secondary-bg-color" routerLink="/register" translate>
                        nav-bar.sign-up
                    </button>
                </div>

                <div class="col-12 hidden-sm-down">
                    <app-promotion-workers></app-promotion-workers>
                </div>

                <!--<div class="col-12 hidden-sm-down">-->
                <!--<app-promotion-home-requests></app-promotion-home-requests>-->
                <!--</div>-->


                <!--<div class="col-12 center mb-2">-->
                <!--<button class="btn white-btn" (click)="navigateToRequests()" translate>-->
                <!--home.see-all-req-->
                <!--</button>-->
                <!--</div>-->

            </div>
        </div>
    </div>

</div>

<section id="worker-promotion" class="promotion">
    <div class="ads-ctr-left">
        <ng-adsense></ng-adsense>
    </div>
    <div class="ads-ctr-right">
        <ng-adsense></ng-adsense>
    </div>
    <div class="container">

        <div class="row">
            <div class="col-12 center promo-header mb-2">
                <h1 class="promo-title" translate>home.quick-search</h1>
                <hr class="primary">
            </div>
        </div>

        <app-quick-search-links></app-quick-search-links>

        <div class="row">
            <div class="col-12 center promo-header mb-2">
                <h1 class="promo-title" translate>home.how-to</h1>
                <hr class="primary">
            </div>
        </div>

        <app-promotion-how-to></app-promotion-how-to>
    </div>

</section>

<section id="services-promotion" class="promotion">
    <div class="container">

        <div class="row">
            <div class="col-12 center promo-header mb-2">
                <h1 class="promo-title" translate>services-promo.title</h1>
                <hr class="primary">
            </div>
        </div>

        <app-promotion-services></app-promotion-services>

        <div class="row">
            <div class="col-12">
                <ng-adsense></ng-adsense>
            </div>
        </div>

        <div class="row">
            <div class="col-12 center promo-header mb-2">
                <h1 class="promo-title" translate>home.bc-in-press</h1>
                <hr class="primary">
            </div>
        </div>

        <app-promotion-press></app-promotion-press>
    </div>
</section>

<!--<section id="cities-promotion" class="promotion">-->
<!--<div class="container">-->

<!--<div class="row">-->
<!--<div class="col-12 center promo-header mb-2">-->
<!--<h1 class="promo-title text-white">Ville</h1>-->
<!--<hr class="primary">-->
<!--</div>-->
<!--</div>-->

<!--<app-promotion-cities></app-promotion-cities>-->
<!--</div>-->
<!--</section>-->

<!--<section id="activities-promotion" class="promotion">-->
<!--<div class="container">-->

<!--<div class="row">-->
<!--<div class="col-12 center promo-header mb-2">-->
<!--<h1 class="promo-title">Activités</h1>-->
<!--<hr class="primary">-->
<!--</div>-->
<!--</div>-->

<!--<app-promotion-activities [categories]="categories$ | async"></app-promotion-activities>-->
<!--</div>-->
<!--</section>-->