import { Store } from '@ngrx/store';
import { BaseUserDto } from '../../core/models';
import { userViewActions } from '../../users/store/actions';
var ShowPhoneDirective = /** @class */ (function () {
    function ShowPhoneDirective(store) {
        this.store = store;
    }
    ShowPhoneDirective.prototype.showPhone = function () {
        this.store.dispatch(new userViewActions.ShowPhoneAction(this.appShowPhone));
    };
    return ShowPhoneDirective;
}());
export { ShowPhoneDirective };
