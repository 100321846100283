import { TranslateService } from '@ngx-translate/core';
import { ContextUtils, HttpUtil } from '../utils';
import * as i0 from "@angular/core";
import * as i1 from "@nguniversal/express-engine/tokens";
import * as i2 from "@ngx-translate/core";
var BcUtilsService = /** @class */ (function () {
    function BcUtilsService(request, translateService) {
        this.request = request;
        this.translateService = translateService;
    }
    BcUtilsService.prototype.getImageFullPath = function (picture) {
        return this.getOrigin() + HttpUtil.buildAndExpand('/medias/{media}', picture);
    };
    BcUtilsService.prototype.getProfileTitle = function (profile) {
        return this.translateService.instant('share.profile.title', { profile: profile });
    };
    BcUtilsService.prototype.getRequestTitle = function (request) {
        return this.translateService.instant('share.request.title', { request: request });
    };
    BcUtilsService.prototype.getProfileUrl = function (profile) {
        return this.getOrigin() + HttpUtil.buildAndExpand('/workers/{uid}/profile', profile.uid);
    };
    BcUtilsService.prototype.getRequestUrl = function (request) {
        return this.getOrigin() + HttpUtil.buildAndExpand('/requests/{id}', request.id);
    };
    BcUtilsService.prototype.getOrigin = function () {
        if (this.request) {
            return this.getRequestProtocol() + "://" + this.request.get('host') + "/";
        }
        return ContextUtils.getOrigin();
    };
    BcUtilsService.prototype.getRequestProtocol = function () {
        var protocol = 'https';
        // if (this.request && this.request.headers) {
        //     protocol = this.request.protocol;
        //
        //     let proto = this.request.headers['X-Forwarded-Proto'];
        //
        //     if (proto) {
        //         protocol = proto;
        //     }
        // }
        return protocol;
    };
    BcUtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BcUtilsService_Factory() { return new BcUtilsService(i0.ɵɵinject(i1.REQUEST, 8), i0.ɵɵinject(i2.TranslateService)); }, token: BcUtilsService, providedIn: "root" });
    return BcUtilsService;
}());
export { BcUtilsService };
