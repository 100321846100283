import {library} from '@fortawesome/fontawesome-svg-core';

import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faFacebookMessenger} from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import {faGooglePlusG} from '@fortawesome/free-brands-svg-icons/faGooglePlusG';
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faCommentAlt} from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
import {faLink} from '@fortawesome/free-solid-svg-icons/faLink';

const icons = [
    faFacebookF, faTwitter, faGooglePlusG, faWhatsapp, faFacebookMessenger, faEnvelope, faLink, faCommentAlt, faCheck
];

library.add(...icons);
