import { OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModalEvent } from '../../../../core/models';
import { BcUtilsService } from '../../../../core/services/bc-utils.service';
import { shareActions } from '../../../../core/store/actions';
import * as fromRoot from '../../../../store';
var ShareModalComponent = /** @class */ (function () {
    function ShareModalComponent(store, translateService, utilsService) {
        this.store = store;
        this.translateService = translateService;
        this.utilsService = utilsService;
        this.modalEvent$ = new BehaviorSubject(ModalEvent.CLOSE);
    }
    ShareModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.pipe(select(fromRoot.getElementToShare), filter(function (element) { return element != null; }))
            .subscribe(function (element) {
            _this.element = element;
            _this.setModalTitle();
            _this.modalEvent$.next(ModalEvent.OPEN);
        });
    };
    ShareModalComponent.prototype.close = function () {
        this.modalEvent$.next(ModalEvent.CLOSE);
        this.store.dispatch(new shareActions.ClearAction());
    };
    Object.defineProperty(ShareModalComponent.prototype, "url", {
        get: function () {
            if (this.instanceOfRequestDto(this.element)) {
                this.utilsService.getRequestUrl(this.element);
            }
            return this.utilsService.getProfileUrl(this.element);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShareModalComponent.prototype, "title", {
        get: function () {
            if (this.instanceOfRequestDto(this.element)) {
                return this.utilsService.getRequestTitle(this.element);
            }
            return this.utilsService.getProfileTitle(this.element);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShareModalComponent.prototype, "description", {
        get: function () {
            return this.translateService.instant('share.profile.description');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShareModalComponent.prototype, "image", {
        get: function () {
            if (this.instanceOfRequestDto(this.element)) {
                return null;
            }
            this.utilsService.getImageFullPath(this.element.picture);
        },
        enumerable: true,
        configurable: true
    });
    ShareModalComponent.prototype.setModalTitle = function () {
        if (this.instanceOfRequestDto(this.element)) {
            this.titleKey = 'share.request.modal-title';
        }
        else {
            this.titleKey = 'share.profile.modal-title';
        }
    };
    ShareModalComponent.prototype.instanceOfRequestDto = function (object) {
        return object && object.budgetType !== undefined;
    };
    return ShareModalComponent;
}());
export { ShareModalComponent };
