export var CallbackType = {
    CLOSE: 'CLOSE',
    RESPONSE: 'RESPONSE'
};
var ModalCallback = /** @class */ (function () {
    function ModalCallback(payload, type) {
        if (type === void 0) { type = CallbackType.RESPONSE; }
        this.payload = payload;
        this.type = type;
    }
    ModalCallback.prototype.isClose = function () {
        return this.type === CallbackType.CLOSE;
    };
    return ModalCallback;
}());
export { ModalCallback };
