import {Action} from '@ngrx/store';
import {ErrorsDto, Pageable, PromotionResultDto, UserResultDto} from '../../../core/models';

export enum WorkersPromotionActionTypes {
    LoadPromotionWorkers = '[WORKERS PROMOTION] LOAD_WORKERS_AROUND',
    LoadPromotionWorkersSuccess = '[WORKERS PROMOTION] LOAD_PROMOTION_WORKERS_SUCCESS',
    LoadPromotionWorkersFail = '[WORKERS PROMOTION] LOAD_PROMOTION_WORKERS_FAIL',

    LoadPromotionWorkersIfNotLoaded = '[WORKERS PROMOTION] LOAD_PROMOTION_WORKERS_IF_NOT_LOADED'
}

export class LoadPromotionWorkersIfNotLoadedAction implements Action {
    readonly type = WorkersPromotionActionTypes.LoadPromotionWorkersIfNotLoaded;

    constructor(public payload: Pageable) {
    }
}

export class LoadPromotionWorkersAction implements Action {
    readonly type = WorkersPromotionActionTypes.LoadPromotionWorkers;

    constructor(public payload: Pageable) {
    }
}

export class LoadPromotionWorkersSuccessAction implements Action {
    readonly type = WorkersPromotionActionTypes.LoadPromotionWorkersSuccess;

    constructor(public payload: PromotionResultDto<UserResultDto>) {
    }
}

export class LoadPromotionWorkersFailAction implements Action {
    readonly type = WorkersPromotionActionTypes.LoadPromotionWorkersFail;

    constructor(public payload: ErrorsDto) {
    }
}

export type WorkersPromotionActions = LoadPromotionWorkersAction
    | LoadPromotionWorkersSuccessAction
    | LoadPromotionWorkersFailAction
    | LoadPromotionWorkersIfNotLoadedAction
    ;
